import * as React from "react";
import { classNames } from "src/common/classNames";
import styles from "./Bom2Cell.module.less";

/* eslint react/require-default-props: "off" */

interface PropsBom2Cell {
  left?: boolean;
  top?: boolean;
  className?: string;
  minWidth: number | undefined;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Bom2Cell: React.FC<PropsBom2Cell> = (props) => {
  const { left, top, className, style, children, minWidth } = props;
  return (
    <div
      className={classNames([
        styles.cell,
        [left, styles.left],
        [top, styles.top],
        className,
      ])}
      style={{ ...style, minWidth }}
    >
      {children}
    </div>
  );
};
