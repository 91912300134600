import * as React from "react";
import {
  FormFieldsBlock,
  PropsFormFieldsBlock,
} from "src/components/Forms/FormFieldsBlock";
import { DrawItems } from "src/components/DrawItems";
import {
  BlockContext,
  createNestContext,
  FormBlockDef,
  FormBlockItem,
  RenderResult,
} from "../FormWithBlocks.types";

/* eslint react/jsx-props-no-spreading: "off" */

export const blockFields = (
  key: string,
  props: PropsFormFieldsBlock,
  items: (FormBlockItem | undefined)[],
  isNest?: boolean,
): FormBlockDef => ({
  key,
  items,
  title: props.title,
  render(prevId: string, ctx: BlockContext): RenderResult {
    return (
      <FormFieldsBlock key={key} {...props} noPad title={this.title}>
        <DrawItems
          items={items}
          ctx={isNest ? createNestContext(ctx, key) : ctx}
        />
      </FormFieldsBlock>
    );
  },
});
