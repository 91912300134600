import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { ZSampleSettings } from "../../SampleTypes";
import { SampleObjType } from "../SampleSettingsStore";

interface PropsSampleSection {
  store: FormObjectSectionsStore<SampleObjType>;
}

export const SampleSection: React.FC<PropsSampleSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZSampleSettings, SampleObjType>
      store={store}
      objKey={["objSample"]}
      objType="sample"
      objLabel="Sample object"
      title="Sample"
      attrs={[
        {
          attrKey: ["sampleSampleRequestAttrId"],
          attrLabel: "Sample request",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sampleNameAttrId"],
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["sampleQuantityAttrId"],
          attrLabel: "Quantity",
          attrType: AttrTypeName.int,
        },
        {
          attrKey: ["sampleSizeAttrId"],
          attrLabel: "Size",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sampleSizeVersionAttrId"],
          attrLabel: "Size version",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: ["sampleRequestTypeAttrId"],
          attrLabel: "Request type",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: ["sampleReviewDateAttrId"],
          attrLabel: "Preview date",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: ["sampleRequestStatusAttrId"],
          attrLabel: "Request status",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: ["sampleCommentsAttrId"],
          attrLabel: "Comments",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["sampleMcPointsAttrId"],
          attrLabel: "MC points",
          attrType: AttrTypeName.childEntities,
        },
        {
          attrKey: ["sampleSampleCommentsAttrId"],
          attrLabel: "Sample comments",
          attrType: AttrTypeName.childEntities,
        },
      ]}
    />
  ),
);
