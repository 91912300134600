import * as React from "react";
import { Button, Form, Input } from "antd";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { MultiLangFields } from "src/components/MultiLangFields";
import { RoleSelect } from "src/components/RoleSelect";
import { ruleArrayMinLength } from "src/common/validationRules/ruleArrayMinLength";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { createDasboardItem, DashboardTabStore } from "../DashboardTabStore";
import { ZDashboardGroup } from "../DasboardTypes";
import styles from "./DashboardFields.module.less";

const fieldName = (key: keyof ZDashboardGroup) => key;

interface PropsDashboardFields {
  store: DashboardTabStore;
}

export const DashboardFields: React.FC<PropsDashboardFields> = ({ store }) => (
  <div className={styles.fieldsBox}>
    <div>
      <Form.Item
        name={fieldName("name")}
        label="Название группы"
        rules={[trimRequired()]}
      >
        <Input allowClear />
      </Form.Item>
      <MultiLangFields basePath={[fieldName("nameLoc")]} />
    </div>
    <Form.Item
      name={fieldName("roleIds")}
      label="Доступно для ролей"
      rules={[{ required: true }]}
    >
      <RoleSelect roleGroups={store.roleGroups} />
    </Form.Item>
    <h2>Список отчётов</h2>
    <Form.List name={fieldName("items")} rules={[ruleArrayMinLength(1)]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <div key={field.key}>
              <div className={styles.itemHeader}>
                <span>Отчет {field.name + 1}</span>
                <Button
                  type="text"
                  onClick={() => remove(index)}
                  icon={<DeleteOutlined />}
                />
              </div>
              <Form.Item
                {...field}
                name={[field.name, "url"]}
                label="Cсылка для построения отчёта"
                rules={[trimRequired()]}
              >
                <Input.TextArea allowClear />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, "height"]}
                label="Высота"
                style={{ width: 150 }}
              >
                <Input allowClear />
              </Form.Item>
            </div>
          ))}
          <Button
            style={{ width: "100%" }}
            type="default"
            icon={<PlusCircleOutlined />}
            onClick={() => add(createDasboardItem())}
          >
            Добавить отчет
          </Button>
        </>
      )}
    </Form.List>
  </div>
);
