import React, { useMemo } from "react";
import { Button, Drawer, Flex, Space, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { CopyOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { TableStore } from "src/components/tables/TableStore";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";
import { onError } from "src/common/onError";
import { DropdownChildButton } from "src/components/DropdownChildButton";
import { CopyInStore } from "./CopyInStore";
import styles from "./CopyIn.module.less";
import { ZEntityFiltersChild } from "../../ChildEntitiesStore";

interface PropsCopyIn {
  tableStore: TableStore<ZEntityRow, ZEntityFiltersChild>;
  onLoad?: (flag: boolean) => void;
  entityId: number;
  parentObjId: number;
}

export const CopyIn: React.FC<PropsCopyIn> = observer(
  ({ tableStore, entityId, onLoad, parentObjId }) => {
    const copyInStore = useMemo(
      () => new CopyInStore(entityId, tableStore, parentObjId),
      [],
    );
    const { isDrawerOpen, loading, pageStore } = copyInStore;

    const onClick = async () => {
      try {
        onLoad?.(true);
        if (!copyInStore.pageStore) await copyInStore.init();
        copyInStore.setIsDrawerOpen(true);
      } catch (error) {
        onError(error);
      } finally {
        onLoad?.(false);
      }
    };

    return (
      <>
        <DropdownChildButton
          icon={<CopyOutlined />}
          disabled={tableStore.selected.length === 0}
          onClick={onClick}
        >
          {t("Copy to")}
        </DropdownChildButton>
        {pageStore && (
          <Drawer
            width="75vw"
            open={isDrawerOpen}
            onClose={() => copyInStore.onClose()}
            className={styles.drawer}
            title={
              <Flex gap={12} align="center">
                {t("Copy to another instance from current instance")}
                <Tooltip
                  title={t(
                    "Select the instance to which you want to copy the previously selected instances of the linked object with all relationships",
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Flex>
            }
            footer={
              <Space>
                <Button
                  loading={loading}
                  disabled={!pageStore.tableStore?.selected.length}
                  type="primary"
                  onClick={() => copyInStore.save()}
                >
                  {t("Copy")}
                </Button>
                <Button onClick={() => copyInStore.onClose()}>
                  {t("Cancel")}
                </Button>
              </Space>
            }
          >
            <EntityFiltersTable store={pageStore} />
          </Drawer>
        )}
      </>
    );
  },
);
