import { TimePicker, TimePickerProps } from "antd";
import { Dayjs } from "dayjs";
import * as React from "react";
import { ed2UtcDate, utcDate2Ed } from "src/common/formats";

/**
 * Компонент выбора времени, который использует строковые значения.
 */
export type PropsEntityTimePicker = Omit<
  TimePickerProps,
  "onChange" | "value"
> & {
  value?: string[] | null;
  onChange?(newValue: string[] | null | undefined): void;
};

export const EntityTimePicker: React.FC<PropsEntityTimePicker> = (props) => {
  const { value, onChange, ...other } = props;
  const format = typeof other.format === "string" ? other.format : "HH:mm:ss";
  const timeValue = utcDate2Ed(value?.[0], format);
  const onChangeInternal = (dt: Dayjs | null) =>
    onChange?.(ed2UtcDate(dt, format));
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <TimePicker
      style={{ width: "100%" }}
      value={timeValue}
      onChange={onChangeInternal}
      {...other}
    />
  );
};

EntityTimePicker.defaultProps = {
  value: undefined,
  onChange: undefined,
};
