// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Cell-Bom2Cell-module__cell--SaL5K {
  padding: 4px 8px;
  border-right: thin solid rgba(0, 0, 0, 0.12);
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Cell-Bom2Cell-module__cell--SaL5K > * {
  width: 100%;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Cell-Bom2Cell-module__left--bkzU7 {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Cell-Bom2Cell-module__top--BvNim {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/Bom2Table/Bom2Cell/Bom2Cell.module.less"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,4CAAA;EACA,6CAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AACI;EAAO,WAAA;AAEX;AAAA;EACI,2CAAA;AAEJ;AAAA;EACI,0CAAA;AAEJ","sourcesContent":["@border: thin solid rgba(0, 0, 0, 0.12);\n.cell {\n    padding: 4px 8px;\n    border-right: @border;\n    border-bottom: @border;\n    display: flex;\n    align-items: center;\n    & > * {width: 100%;}\n}\n.left {\n    border-left: @border;\n}\n.top {\n    border-top: @border;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Cell-Bom2Cell-module__cell--SaL5K`,
	"left": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Cell-Bom2Cell-module__left--bkzU7`,
	"top": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Cell-Bom2Cell-module__top--BvNim`
};
export default ___CSS_LOADER_EXPORT___;
