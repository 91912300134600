import { ZModelAttr } from "../../ZModel";
import { ModelEditorStore } from "../ModelEditorStore";
import { Command, generateCmdKey } from "./Command";

export class CmdAttrChange implements Command {
  readonly key: number;

  constructor(
    private store: ModelEditorStore,
    private index: number,
    private attr: ZModelAttr,
  ) {
    this.key = generateCmdKey();
  }

  readonly title = "Изменение свойств атрибута";

  createUndo(): Command {
    const oldAttr = this.store.model?.attrs[this.index];
    if (!oldAttr) throw Error(`Некорректный индекс атрибута`);
    return new CmdAttrChange(this.store, this.index, oldAttr);
  }

  exec(): void {
    const { model } = this.store;
    if (!model) throw Error("Отсутствует модель");
    model.attrs[this.index] = this.attr;
    this.store.setCurAttrIndex(this.index);
  }
}
