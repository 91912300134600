import { z } from "zod";

// Формат материала для таблицы добавления позиции при нажатии кнопки [(+) Материал]

const zBom2Material = z.object({
  id: z.number(),
  materialType: z.string(),
  article: z.string(),
  name: z.string(),
  supplierName: z.string(),
  image: z.string(),
});
export type ZBom2Material = z.infer<typeof zBom2Material>;

export const zBom2MaterialResponse = z.object({
  content: zBom2Material.array(),
  totalElements: z.number(),
});

export type FilterBom2Material = {
  query?: string;
};
