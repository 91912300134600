import { PageUrl } from "src/routes/PageUrl";
import { makeUrl } from "src/routes/makeUrl";

export const breadItem = <T extends {} = {}>(
  title: string,
  url?: PageUrl,
  params?: T,
) => ({
  title,
  href: url ? makeUrl(url, params) : undefined,
});
