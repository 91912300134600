// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__tableLayout--GiIy0 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__tableHeader--drh7Z {
  border-bottom: 1px solid #e1e3e6;
  display: flex;
  justify-content: flex-end;
}
.src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__pagination--J8LTJ {
  padding: 8px;
  display: flex;
  justify-content: center;
}
.src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__tableBox--ZDhFk {
  overflow: auto;
  flex: 1;
}
.src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__spinBox--qACDT {
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__emptyContent--zbq4I {
  padding: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AsyncTableView/AsyncTableView.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AACF;AACA;EACE,gCAAA;EACA,aAAA;EACA,yBAAA;AACF;AACA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;AACF;AACA;EACE,cAAA;EACA,OAAA;AACF;AACA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,OAAA;AACF;AACA;EACE,aAAA;AACF","sourcesContent":[".tableLayout {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n}\n.tableHeader {\n  border-bottom: 1px solid #e1e3e6;\n  display: flex;\n  justify-content: flex-end;\n}\n.pagination {\n  padding: 8px;\n  display: flex;\n  justify-content: center;\n}\n.tableBox {\n  overflow: auto;\n  flex: 1;\n}\n.spinBox {\n  padding: 30px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n}\n.emptyContent {\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableLayout": `src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__tableLayout--GiIy0`,
	"tableHeader": `src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__tableHeader--drh7Z`,
	"pagination": `src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__pagination--J8LTJ`,
	"tableBox": `src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__tableBox--ZDhFk`,
	"spinBox": `src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__spinBox--qACDT`,
	"emptyContent": `src-pages-EntityFiltersPage-AsyncTableView-AsyncTableView-module__emptyContent--zbq4I`
};
export default ___CSS_LOADER_EXPORT___;
