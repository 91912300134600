import * as React from "react";
import { t } from "i18next";
import { Form, Input } from "antd";
import { ModalPlm } from "src/components/ModalPlm";
import { DatePickerString } from "src/components/DatePickerString";
import { AColumn } from "src/components/tables/AsyncTable";
import { SelectFromTableSingle } from "src/components/SelectFromTableSingle";
import { FnLoad } from "src/components/tables/TableStore";
import { filterMonoSearch } from "src/components/tables/FiltersForm/filterMonoSearch";
import { ZOption } from "src/types/ZOption";
import { onError } from "src/common/onError";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { SelectRecordFromRef } from "../../SelectRecordFromRef";
import {
  loadSampleColors,
  loadSampleSuppliers,
  SampleDictionaryType,
} from "../../../apiSample";
import {
  EdSampleRequest,
  ZSampleColor,
  ZSampleRecord,
  ZSampleRequest,
  ZSampleSupplier,
} from "../../ZSampleRequest";
import styles from "./SampleRequestModal.module.less";
import { sampleDictionaryLoader } from "../../sampleDictionaryLoader";

/* eslint react/no-unstable-nested-components: "off" */

interface PropsSampleRequestModal {
  open: boolean;
  initialValues: ZSampleRequest | undefined;
  close(): void;
  modelObjectId: number | string;
  modelId: number | string;
  submit: (values: EdSampleRequest) => Promise<void>;
}

// ------- supplier

type FilterSuplliers = {
  query?: string;
};

const makeSupplierOption = (row: ZSampleSupplier) => ({
  label: row.shortName || row.name,
  value: String(row.id),
});

const suppliersLoader =
  (modelObjectId: number | string): FnLoad<ZSampleSupplier, FilterSuplliers> =>
  async ({ page, pageSize, filters }) => {
    const { content, totalElements } = await loadSampleSuppliers({
      modelObjectId,
      page,
      size: pageSize,
      query: filters?.query,
    });
    return {
      rows: content,
      totalItems: totalElements,
    };
  };

// ------ color

type FilterColors = {
  query?: string;
};
const makeColorOption = (row: ZSampleColor): ZOption => ({
  label: row.article ?? String(row.id),
  value: String(row.id),
});
const colorsLoader =
  (
    modelObjectId: number | string,
    modelId: number | string,
  ): FnLoad<ZSampleColor, FilterColors> =>
  async ({ page, pageSize, filters }) => {
    const { content, totalElements } = await loadSampleColors({
      modelObjectId,
      modelId,
      page,
      size: pageSize,
      query: filters?.query,
    });
    return {
      rows: content,
      totalItems: totalElements,
    };
  };

const fname = (key: keyof ZSampleRequest) => key;

export const SampleRequestModal: React.FC<PropsSampleRequestModal> = (
  props,
) => {
  const { open, close, modelObjectId, modelId, submit, initialValues } = props;
  const [saving, setSaving] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (open) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
    }
  }, [open]);
  const supplierColumns = React.useMemo(
    () =>
      [
        { key: "name", title: "Name", dataIndex: "name" },
        { key: "shortName", title: "Short name", dataIndex: "shortName" },
        { key: "country", title: "Country", dataIndex: "country" },
        { key: "address", title: "Address", dataIndex: "address" },
      ] as AColumn<ZSampleSupplier>[],
    [],
  );
  const colorColumns = React.useMemo(
    () =>
      [
        { key: "article", title: "Article", dataIndex: "article" },
        // Сейчас информации о картинке больше нет. Но было обсуждение. С высокой вероятностью её вернут.
        // {
        //   key: "image",
        //   title: "Image",
        //   render: (_, { image }) =>
        //     image && (
        //       <img
        //         src={imgSrc(image)}
        //         alt=""
        //         style={{ maxWidth: 64, maxHeight: 64 }}
        //       />
        //     ),
        // },
      ] as AColumn<ZSampleColor>[],
    [],
  );
  const filters = {
    query: filterMonoSearch(),
  };
  const onSubmit = (values: EdSampleRequest) => {
    setSaving(true);
    submit(values)
      .then(close)
      .finally(() => {
        setSaving(false);
      })
      .catch(onError);
  };
  return (
    <ModalPlm
      open={open}
      onCancel={close}
      okText={initialValues ? t("Edit") : t("Add")}
      okButtonProps={{ htmlType: "submit", loading: saving }}
      title={initialValues ? "Request for samples" : "New request for samples"}
      width={1000}
      centered
      modalRender={(content) => (
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          {content}
        </Form>
      )}
    >
      <div className={styles.formBody}>
        <Form.Item
          name={fname("nameRequest")}
          label="Request name"
          rules={[trimRequired()]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item name={fname("numberRequest")} label="Request number">
          <Input allowClear />
        </Form.Item>
        <Form.Item name={fname("supplier")} label="Supplier">
          <SelectFromTableSingle<ZSampleSupplier, FilterSuplliers>
            rowKey="id"
            title={t("Supplier")}
            makeOption={makeSupplierOption}
            load={suppliersLoader(modelObjectId)}
            columns={supplierColumns}
            filterItems={filters}
          />
        </Form.Item>
        <Form.Item name={fname("color")} label="Color">
          <SelectFromTableSingle<ZSampleColor, FilterColors>
            rowKey="id"
            title={t("Color")}
            makeOption={makeColorOption}
            load={colorsLoader(modelObjectId, modelId)}
            columns={colorColumns}
            filterItems={filters}
          />
        </Form.Item>
        <Form.Item name={fname("sendDate")} label="Send date">
          <DatePickerString style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name={fname("receiveDate")} label="Receive date">
          <DatePickerString style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={fname("comment")}
          label="Comment"
          style={{ gridColumn: "span 2" }}
        >
          <Input.TextArea allowClear />
        </Form.Item>
        <Form.Item name={fname("typeRequest")} label="Type">
          <SelectRecordFromRef<ZSampleRecord>
            valueKey="id"
            loader={sampleDictionaryLoader(
              modelObjectId,
              SampleDictionaryType.sampleRequestType,
            )}
            makeLabel={({ value }) => value}
            allowClear
          />
        </Form.Item>
        <Form.Item name={fname("state")} label="State">
          <SelectRecordFromRef<ZSampleRecord>
            valueKey="id"
            loader={sampleDictionaryLoader(
              modelObjectId,
              SampleDictionaryType.fitStatus,
            )}
            makeLabel={({ value }) => value}
            allowClear
          />
        </Form.Item>
      </div>
    </ModalPlm>
  );
};
