import * as React from "react";
import { Modal, Button, Form } from "antd";
import {
  FormBlockDef,
  FormWithBlockStore,
  FormWithBlocks,
} from "src/components/FormWithBlocks";
import { blockFields } from "src/components/FormWithBlocks/blockTypes";
import { stdInput } from "src/components/FormWithBlocks/items";
import { ZAttrType } from "src/types/ZCatAttr";
import { cvtEditToSubmit, EditParams } from "./AttrFields";
import { CategoryAttributesStore } from "../../CategoryAttributesStore";
import { attrFieldsWithType } from "./attrFieldsWithType";

interface PropsAddAttrModal {
  open: boolean;
  close(): void;
  attrStore: CategoryAttributesStore;
}

const formName = "AddAttr";

export const AddAttrModal: React.FC<PropsAddAttrModal> = (
  props: PropsAddAttrModal,
) => {
  const { open, close, attrStore } = props;
  const [formStore] = React.useState(new FormWithBlockStore());
  const [type, setType] = React.useState<ZAttrType | undefined>(undefined);
  const [form] = Form.useForm<EditParams>();
  React.useEffect(() => {
    if (open) {
      form.setFieldValue("scale", 1);
      form.resetFields();
    }
  }, [open]);
  const submit = async (values: EditParams) => {
    await attrStore.onAdd(cvtEditToSubmit(values, attrStore.curNode!.data.id));
    return values;
  };
  const block: FormBlockDef = blockFields("root", { monoColumn: true }, [
    ...attrFieldsWithType(type, attrStore, "add"),
    stdInput("path", "path", { rules: [{ required: true }] }),
  ]);
  const onValuesChange = (changed: Partial<EditParams>) => {
    if (changed.type) {
      setType(changed.type);
    }
  };
  return (
    <Modal
      open={open}
      onCancel={close}
      footer={null}
      title="Добавление атрибута"
      destroyOnClose
    >
      <FormWithBlocks<EditParams>
        name={formName}
        form={form}
        store={formStore}
        block={block}
        submit={submit}
        onSuccess={close}
        onValuesChange={onValuesChange}
        formSize="middle"
        buttonsSize="middle"
        submitText="Применить"
        extraButtons={<Button onClick={close}>Отмена</Button>}
      />
    </Modal>
  );
};
