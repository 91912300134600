// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-tables-TableFacade-TableFacade-module__filersRow--zgf_I {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  padding: 16px;
  background: #F7F7F7;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/TableFacade/TableFacade.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,+BAAA;EACA,SAAA;EAEA,aAAA;EACA,mBAAA;EACA,kBAAA;AAAF","sourcesContent":[".filersRow {\n  display: grid;\n  grid-template-columns: 1fr auto;\n  gap: 16px;\n\n  padding: 16px;\n  background: #F7F7F7;\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filersRow": `src-components-tables-TableFacade-TableFacade-module__filersRow--zgf_I`
};
export default ___CSS_LOADER_EXPORT___;
