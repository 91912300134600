/* eslint-disable react/require-default-props */
import { Select } from "antd";
import * as React from "react";
import { filterOptionByName } from "src/common/filterOptionByName";
import { ZRolesGroup } from "src/pages/ManagementPage/Obj2Tab/roles/roleTypes";

interface PropsRoleSelect {
  roleGroups: ZRolesGroup[];
  value?: number[];
  onChange?(value?: number[]): void;
  single?: boolean;
}

type RoleOption = {
  label: React.ReactNode;
  title: React.ReactNode;
  options: {
    label: React.ReactNode;
    value: number;
  }[];
};

export const RoleSelect: React.FC<PropsRoleSelect> = ({
  roleGroups,
  value,
  onChange,
  single,
}) => {
  const options: RoleOption[] = React.useMemo(
    () =>
      roleGroups.map(({ groupName, roles }) => ({
        title: groupName,
        label: groupName,
        options: roles.map(({ roleName, roleId }) => ({
          label: roleName,
          value: roleId,
        })),
      })),
    [roleGroups],
  );

  return (
    <Select
      value={value}
      onChange={(val) => {
        onChange?.(val);
      }}
      allowClear
      mode={single ? undefined : "multiple"}
      options={options}
      showSearch
      filterOption={filterOptionByName}
      placeholder="Выбрать"
    />
  );
};
