import {
  BlockContext,
  RenderResult,
  FormBlockDef,
} from "../FormWithBlocks.types";

export const renderTitled = (
  block: FormBlockDef,
  useTitle: boolean,
  prevId: string,
  ctx: BlockContext,
): RenderResult => {
  const newBlock = useTitle ? block : { ...block, title: undefined };
  return newBlock.render(prevId, ctx);
};
