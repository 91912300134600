import * as React from "react";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { Spin } from "antd";
import { ImageCarousel } from "src/components/ImageCarousel";
import { AttrImageCarouselProps } from "src/common/attrEdit/components";
import { MChartEntityStore } from "../../MChartEntityStore";

interface PropsMcImageInternal {
  store: MChartEntityStore;
}

export const McImageInternal: React.FC<PropsMcImageInternal> = observer(
  ({ store }) => {
    const [wait, setWait] = React.useState(false);
    const [images, setImages] = React.useState<string[]>([]);
    const [carouselProps, setCarouselProps] =
      React.useState<AttrImageCarouselProps>({});
    const loadImages = () => {
      setWait(true);
      store
        .getImages()
        .then(({ images: newImages, props }) => {
          setImages(newImages ?? []);
          setCarouselProps(props);
        })
        .finally(() => setWait(false))
        .catch(onError);
    };
    React.useEffect(() => {
      loadImages();
    }, []);

    const onChange = (newValue: string[]) => {
      setWait(true);
      store
        .saveImages(newValue)
        .then((newImages) => {
          setImages(newImages);
        })
        .finally(() => setWait(false))
        .catch(onError);
    };

    return (
      <Spin spinning={wait}>
        <ImageCarousel
          {...carouselProps}
          disabled={wait}
          value={images}
          onChange={onChange}
        />
      </Spin>
    );
  },
);
