import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import { DrawTab } from "./DrawTab";
import {
  ZSchedSettingsGroup,
  ZSchedulingSettings,
} from "../../SchedulingTypes";
import { SchedulingObjType } from "../SchedulingSettingsStore";

interface PropsGroupFields {
  tabKey: keyof ZSchedulingSettings;
  objType: SchedulingObjType;
  store: FormObjectSectionsStore<SchedulingObjType>;
}

export const GroupFields: React.FC<PropsGroupFields> = observer(
  ({ tabKey, objType, store }) => {
    const isGroupTab =
      tabKey === "groupSettings" || tabKey === "groupTemplateSettings";
    if (!isGroupTab) return null;
    const fieldName = (
      name: keyof ZSchedSettingsGroup,
    ): [typeof tabKey, keyof ZSchedSettingsGroup] => [tabKey, name];
    return (
      <DrawTab>
        <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
          store={store}
          objKey={fieldName("templateObjectId")}
          objType={objType}
          objLabel="Объект для формирования шаблона группы задач"
          title="Список обязательных позиций"
          attrs={[
            {
              attrKey: fieldName("nameAttributeId"),
              attrLabel: "Укажите атрибут названия группы задач",
              attrType: AttrTypeName.dictSingle,
            },
            {
              attrKey: fieldName("relationPlanTemplateAttributeId"),
              attrLabel: "Укажите атрибут принадлежности к шаблону плана",
              attrType: AttrTypeName.object,
            },
            {
              attrKey: fieldName("taskListAttributeId"),
              attrLabel:
                "Укажите атрибут списка задач, включенных в группу задач",
              attrType: AttrTypeName.childEntities,
            },
          ]}
        />
      </DrawTab>
    );
  },
);
