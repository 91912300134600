import { z } from "zod";
import dayjs from "dayjs";
import { FnLoad, SortKey } from "src/components/tables/TableStore";
import { delay } from "src/common/delay";
import { ZOption } from "src/types/ZOption";

/* eslint no-plusplus: "off" */

export const zTestStatus = z.enum([
  "begin",
  "inProgress",
  "done",
  "hold",
  "rejected",
]);
export type ZTestStatus = z.infer<typeof zTestStatus>;

export const zTestRecord = z.object({
  id: z.number(),
  name: z.string(),
  status: zTestStatus,
  expirationDate: z
    .string()
    .regex(/^\d{4}-\d\d-\d\d$/)
    .optional(),
});

export const zTestRecordNew = zTestRecord.omit({ id: true });
export type ZTestRecordNew = z.infer<typeof zTestRecordNew>;

export type ZTestRecord = z.infer<typeof zTestRecord>;

export type TestTableFilter = {
  nameBegin?: string;
  statuses?: ZTestStatus[];
};

// ------------

const statusNames: Record<ZTestStatus, string> = {
  begin: "Начало",
  inProgress: "В процессе",
  done: "Готово",
  hold: "Остановлено",
  rejected: "Отклонено",
};

const volwes = "aoiue";
const consonants = "bcdfghjklmnprstvwxyz";
const genLetter = (letters: string) =>
  letters[Math.floor(Math.random() * letters.length)]!;
const genWord = (min: number, max: number, pEnd: number) => {
  const n = Math.floor((max - min) * Math.random()) + min;
  const end = Math.random() < pEnd;
  let res = "";
  for (let i = 0; i < n; i++) {
    res += genLetter(consonants);
    res += genLetter(volwes);
  }
  if (end) res += genLetter(consonants);
  return res;
};
const cap = (name: string): string =>
  name.slice(0, 1).toUpperCase() + name.slice(1);

const table: ZTestRecord[] = [];
const statuses: ZTestStatus[] = [
  "begin",
  "done",
  "done",
  "rejected",
  "hold",
  "inProgress",
  "inProgress",
  "inProgress",
];

export const initTestTable = async () => {
  if (table.length > 0) return;
  for (let i = 0; i < 500; i++)
    table.push({
      id: i + 1,
      name: cap(genWord(2, 6, 0.5)),
      status: statuses[Math.floor(Math.random() * statuses.length)]!,
      expirationDate:
        Math.random() < 0.6
          ? dayjs()
              .add(Math.random() * 72 + 48, "hour")
              .format("YYYY-MM-DD")
          : undefined,
    });
};

const makeCmp = (field: keyof ZTestRecord, sortOrder: SortKey) => {
  const k = sortOrder === "descend" ? -1 : 1;
  return (a: ZTestRecord, b: ZTestRecord) => {
    const vA = a[field] ?? "";
    const vB = b[field] ?? "";
    if (vA < vB) return -k;
    if (vB < vA) return k;
    return 0;
  };
};

const timeout = async () => {
  await delay(Math.random() * 2000 + 500);
};

export const testTableLoader: FnLoad<ZTestRecord, TestTableFilter> = async (
  params,
) => {
  await timeout();
  const { sort, sortOrder, page, pageSize, filters } = params;
  let tmp: ZTestRecord[] = [...table];
  const nameBegin = filters?.nameBegin?.toLowerCase();
  if (nameBegin) {
    tmp = tmp.filter(({ name }) => name.toLowerCase().startsWith(nameBegin));
  }
  const statusesList = filters?.statuses;
  if (statusesList?.length) {
    const statusesSet = new Set(statusesList);
    tmp = tmp.filter(({ status }) => statusesSet.has(status));
  }
  if (sort === "id" || sort === "name" || sort === "status") {
    tmp.sort(makeCmp(sort, sortOrder || "ascend"));
  }
  const start = page * pageSize;
  return {
    rows: tmp.slice(start, start + pageSize),
    totalItems: tmp.length,
  };
};

export const getRecordById = async (targetId: string) => {
  initTestTable();
  await timeout();
  const numId = +targetId;
  const result = table.find(({ id }) => id === numId);
  if (!result) throw Error(`Не найдена запись с id="${targetId}"`);
  return result;
};

export const getStatusesDict = async (): Promise<
  Record<ZTestStatus, string>
> => {
  await timeout();
  return statusNames;
};

export const updateRecord = async (
  record: ZTestRecord,
): Promise<ZTestRecord> => {
  initTestTable();
  await timeout();
  zTestRecord.parse(record);
  const index = table.findIndex(({ id }) => record.id === id);
  if (index < 0) throw Error(`Не найдена запись с id=${record.id}`);
  table[index] = { ...record };
  return { ...record };
};

export const createRecord = async (
  srcRecord: ZTestRecordNew,
): Promise<ZTestRecord> => {
  initTestTable();
  await timeout();
  zTestRecordNew.parse(srcRecord);
  const newId = table.reduce((max, { id }) => Math.max(max, id), 0) + 1;
  const dstRecord: ZTestRecord = { ...srcRecord, id: newId };
  table.push(dstRecord);
  return { ...dstRecord };
};

export const makeStatusOptions = (
  dict: Record<ZTestStatus, string>,
): ZOption[] =>
  Object.entries(dict).map(([value, label]) => ({ value, label }));
