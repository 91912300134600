import { Flex, Switch } from "antd";
import * as React from "react";

interface PropsMcImageSwitcher {
  isVisible: boolean;
  toggle(): void;
}

export const McImageSwitcher: React.FC<PropsMcImageSwitcher> = ({
  isVisible,
  toggle,
}) => (
  <Flex align="center" gap={6}>
    <Switch value={isVisible} onChange={toggle} />
    View image
  </Flex>
);
