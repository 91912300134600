// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ModalVertFixed-ModalVertFixed-module__modal--zOS1U {
  overflow-y: auto;
}
.src-components-ModalVertFixed-ModalVertFixed-module__modal--zOS1U .ant-modal-content {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.src-components-ModalVertFixed-ModalVertFixed-module__modal--zOS1U .ant-modal-body {
  flex: 1;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalVertFixed/ModalVertFixed.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAFA;EAGQ,YAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AAER;AARA;EASQ,OAAA;EACA,gBAAA;AAER","sourcesContent":[".modal {\n    overflow-y: auto;\n    :global(.ant-modal-content) {\n        height: 100%;\n        overflow-y: hidden;\n        display: flex;\n        flex-direction: column;\n    }\n    :global(.ant-modal-body) {\n        flex: 1;\n        overflow-y: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `src-components-ModalVertFixed-ModalVertFixed-module__modal--zOS1U`
};
export default ___CSS_LOADER_EXPORT___;
