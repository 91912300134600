import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Popover } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { EntityFiltersPageStore } from "../EntityFiltersPageStore";
import styles from "./AttsTree.module.less";

type PropsAttsTreeSelectedPath = {
  store: EntityFiltersPageStore;
  objectId: number;
};

export const AttsTreeSelectedPath: React.FC<PropsAttsTreeSelectedPath> =
  observer(({ store }) => {
    const {
      attrsTreeStore,
      attrsTreeStore: { treeStdStore },
    } = store;

    const handleReset = async () => {
      store.attrsTreeStore.setExpandedKeys([]);
      store.attrsTreeStore.setSelectedKeys([]);
      store.saveEntitySearchState();
      store.reloadWithActualFilters();
    };

    // reverse without mutating cuz of mobx
    const list = [...attrsTreeStore.selectedKeys].reverse();
    const lastIndex = attrsTreeStore.selectedKeys.length - 1;
    const empty = list.length === 0;
    const path = list.map((item, index) => {
      const node = treeStdStore.findNode(item);
      const title =
        typeof node?.title === "function" ? node.title(node) : node?.title;
      const lastElement = index === lastIndex;
      return (
        <span key={item}>
          {title}
          {lastElement ? "" : "/"}
        </span>
      );
    });
    if (path.length === 0) return null;
    return (
      <div className={styles.attsTreePathBox}>
        <Popover content={path} trigger="hover">
          <div className={styles.attsTreePathSection}>{path}</div>
        </Popover>
        {!empty && (
          <Button type="text" onClick={handleReset} icon={<CloseOutlined />} />
        )}
      </div>
    );
  });
