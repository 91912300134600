import React from "react";
import { SelectFromReference } from "src/components/SelectFromReference";
import { makeFieldOptions } from "src/common/makeFieldOptions";
import { SelectProps } from "antd";
import { loadFilterAttributes } from "./loadFilterAttributes";

type PropsSelectFilter = SelectProps & {
  attributeId: number;
};

export const SelectFilter: React.FC<PropsSelectFilter> = ({
  attributeId,
  ...props
}) => (
  <SelectFromReference
    loader={async () => {
      const res = await loadFilterAttributes(attributeId);
      return makeFieldOptions(res);
    }}
    {...props}
    optionFilterProp="filterProp"
    showSearch
  />
);
