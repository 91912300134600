import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { SheetStore } from "src/components/Sheet/SheetStore";
import { groupChecked, GroupChecked, isChecked } from "src/common/groupChecked";
import { onError } from "src/common/onError";
import { ZSampleData } from "../ZSampleData";
import {
  createSampleData,
  deleteSampleData,
  loadSampleData,
  saveSampleData,
} from "../../apiSample";
import { NewSampleData } from "./SamplePointsTypes";
import { SubsampleStore } from "./Subsample";

/* eslint no-param-reassign: "off" */
/* eslint class-methods-use-this: "off" */

export class SamplePointsStore {
  constructor() {
    makeAutoObservable(this);
  }

  modelId: number | string = "";

  setModelId(modelId: number | string) {
    this.modelId = modelId;
  }

  async init(requestId: number | string) {
    this.selected.clear();
    this.clearSubsamples();
    this.setSampleRequestId(requestId);
    await this.reload();
  }

  async reload() {
    try {
      this.setData({ status: "wait" });
      const result = await loadSampleData(this.sampleRequestId);
      this.setData({ status: "ready", result });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  sheetStore = new SheetStore();

  sampleRequestId: number | string = "";

  setSampleRequestId(requestId: number | string) {
    this.sampleRequestId = requestId;
  }

  data: RemoteData<ZSampleData[]> = { status: "none" };

  setData(newData: RemoteData<ZSampleData[]>) {
    this.data = newData;
  }

  selected = new Set<number | string>();

  toggleRow(id: number | string) {
    if (this.selected.has(id)) {
      this.selected.delete(id);
    } else {
      this.selected.add(id);
    }
  }

  get allSelected(): GroupChecked {
    const { data, selected } = this;
    if (data.status !== "ready") return "none";
    return groupChecked(data.result, ({ id }) => selected.has(id)) ?? "none";
  }

  toggleAll() {
    const { data, selected } = this;
    if (data.status === "ready") {
      if (isChecked(this.allSelected)) {
        selected.clear();
      } else {
        data.result.forEach(({ id }) => selected.add(id));
      }
    }
  }

  get canAdd(): boolean {
    return this.data.status === "ready";
  }

  async createSample(values: NewSampleData) {
    await createSampleData(this.sampleRequestId, values);
    this.reload(); // не ждём
  }

  async saveCellValue<TField extends keyof ZSampleData>(
    field: TField,
    oldRow: ZSampleData,
    value: ZSampleData[TField],
  ) {
    return this.saveCell(field, {
      ...oldRow,
      [field]: value,
    });
  }

  async saveCell(field: string, newRow: ZSampleData) {
    await saveSampleData(
      {
        sampleRequestId: this.sampleRequestId,
        sampleId: newRow.id,
        target: field,
      },
      newRow,
    );
    return newRow;
  }

  updateSelectCell(
    field: "requestStatus" | "requestType",
    oldRow: ZSampleData,
    newRow: ZSampleData,
  ) {
    oldRow[field].id = newRow[field]?.id;
    oldRow[field].name = newRow[field]?.name;
  }

  updateCell<TField extends keyof ZSampleData>(
    field: TField,
    oldRow: ZSampleData,
    newRow: ZSampleData,
  ) {
    oldRow[field] = newRow[field];
  }

  // ----- subsamples
  subsamples: Record<number | string, SubsampleStore> = {};

  clearSubsamples() {
    this.subsamples = {};
  }

  toggleSubsample(id: number | string) {
    if (id in this.subsamples) {
      delete this.subsamples[id];
    } else {
      this.subsamples[id] = new SubsampleStore(
        id,
        this.sampleRequestId,
        this.modelId,
      );
    }
  }

  // ---- delete
  deleting = false;

  setDeleting(flag: boolean) {
    this.deleting = flag;
  }

  get canDelete(): boolean {
    return this.selected.size > 0 && !this.deleting;
  }

  deleteSamples() {
    this.setDeleting(true);
    const ids = Array.from(this.selected);
    deleteSampleData(this.sampleRequestId, ids)
      .then(() => {
        this.selected.clear();
        ids.forEach((id) => delete this.subsamples[id]);
        this.reload();
      })
      .finally(() => this.setDeleting(false))
      .catch(onError);
  }
}
