import { z } from "zod";

export const zFontWeight = z.enum(["400", "700"]);
export type ZFontWeight = z.infer<typeof zFontWeight>;
export const zTextAlign = z.enum(["left", "right", "center"]);
export type ZTextAlign = z.infer<typeof zTextAlign>;

export const zAttrViewStyles = z.object({
  fontWeight: zFontWeight.optional(),
  textAlign: zTextAlign.optional(),
});
export type ZAttrViewStyles = z.infer<typeof zAttrViewStyles>;
export type AttrViewStylesNames = keyof z.infer<typeof zAttrViewStyles>;
