// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-AuthChecker-AuthChecker-module__vertBlock--P6mTa {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
}
.src-components-AuthChecker-AuthChecker-module__wait--MqSfC {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AuthChecker/AuthChecker.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;AAAF","sourcesContent":[".vertBlock {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 16px 24px;\n}\n\n.wait {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  padding: 16px 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertBlock": `src-components-AuthChecker-AuthChecker-module__vertBlock--P6mTa`,
	"wait": `src-components-AuthChecker-AuthChecker-module__wait--MqSfC`
};
export default ___CSS_LOADER_EXPORT___;
