import React from "react";
import {
  ZRCAttachemntImg,
  zRCAttachemntImg,
  ZRCAttachemntMsg,
  zRCAttachemntMsg,
  ZRCAttachemntBase,
  zRCAttachemntBase,
  ZRCAttachment,
} from "src/businessServices/services/bsChat/rocketChat/rocketChat.types";
import { AnyZodObject, z } from "zod";
import { BaseAttachment } from "./components/BaseAttachment";
import { ImgAttachment } from "./components/ImgAttachment";
import { ChatMsg } from "../ChatMsg/ChatMsg";
import styles from "./attachment.module.less";

export type AttachmentRenderParams = {
  createMsg: (text: string) => React.ReactNode;
};

type TypeTester = {
  tester: AnyZodObject | z.ZodType;
  render: (
    attachment: ZRCAttachment,
    params: AttachmentRenderParams,
  ) => React.ReactNode;
};

export const zRCAttachemntTesters: TypeTester[] = [
  {
    tester: zRCAttachemntImg,
    render: (attachment) => (
      <ImgAttachment data={attachment as ZRCAttachemntImg} />
    ),
  },
  {
    tester: zRCAttachemntBase,
    render: (attachment) => (
      <BaseAttachment data={attachment as ZRCAttachemntBase} />
    ),
  },
  {
    tester: zRCAttachemntMsg,
    render: (attachment, params) => {
      const casted = attachment as ZRCAttachemntMsg;
      const { text, attachments } = casted;
      return (
        <ChatMsg
          msgText={text}
          attachments={
            attachments?.map((a, i) => ({
              ...a,
              key: `${casted.author_name}:${i}`,
            })) || []
          }
          className={styles.reply}
          renderParams={params}
        />
      );
    },
  },
];

export const defineAttachment = (
  attachment: ZRCAttachment,
  params: AttachmentRenderParams,
) => {
  const data = zRCAttachemntTesters.map(({ render, tester }) => {
    try {
      tester.parse(attachment);
      return render(attachment, params);
    } catch (e) {
      return null;
    }
  });
  return data.filter((cmp) => !!cmp)[0];
};
