/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const SupportIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
    width="24"
    height="24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM13.9966 7.9375C13.9966 8.76593 13.325 9.4375 12.4966 9.4375C11.6682 9.4375 10.9966 8.76593 10.9966 7.9375C10.9966 7.10907 11.6682 6.4375 12.4966 6.4375C13.325 6.4375 13.9966 7.10907 13.9966 7.9375ZM14.1567 16.5146V17.0752C13.7056 17.2529 13.3455 17.3874 13.0767 17.4785C12.8078 17.5742 12.4956 17.6221 12.1401 17.6221C11.5933 17.6221 11.1672 17.4899 10.8618 17.2256C10.561 16.9567 10.4106 16.6172 10.4106 16.207C10.4106 16.0475 10.422 15.8857 10.4448 15.7217C10.4676 15.5531 10.5041 15.3639 10.5542 15.1543L11.1147 13.1582C11.1649 12.9668 11.2059 12.7868 11.2378 12.6182C11.2743 12.445 11.2925 12.2878 11.2925 12.1465C11.2925 11.8913 11.2401 11.7135 11.1353 11.6133C11.0304 11.513 10.8299 11.4629 10.5337 11.4629C10.3879 11.4629 10.2375 11.4857 10.0825 11.5312C9.93213 11.5768 9.84326 11.6133 9.84326 11.6133V11.0459C10.2124 10.8955 10.5656 10.7679 10.9028 10.6631C11.2401 10.5537 11.5591 10.499 11.8599 10.499C12.4022 10.499 12.8192 10.6312 13.1108 10.8955C13.4071 11.1553 13.5552 11.4948 13.5552 11.9141C13.5552 12.0007 13.5438 12.1533 13.521 12.3721C13.5028 12.5908 13.4663 12.7913 13.4116 12.9736L12.8511 14.9629C12.8055 15.1224 12.7645 15.3047 12.728 15.5098C12.6916 15.7148 12.6733 15.8698 12.6733 15.9746C12.6733 16.2389 12.7326 16.4189 12.8511 16.5146C12.9696 16.6104 13.1746 16.6582 13.4663 16.6582C13.603 16.6582 13.758 16.6354 13.9312 16.5898C14.1043 16.5397 14.1567 16.5146 14.1567 16.5146Z"
      fill="#343A41"
    />
  </svg>
);
/**
 * используется в левом меню для пункта "справочник категорий" в активном состонии
 */
export const SupportIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={SupportIconStdSvg} {...props} />;
