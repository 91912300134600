import * as React from "react";
import { observer } from "mobx-react-lite";
import { ModelEditorStore } from "../../ModelEditorStore";
import { Point } from "../../../math";

interface PropsPoint {
  p: Point;
}

const r = 10;
const DrawPoint: React.FC<PropsPoint> = ({ p }: PropsPoint) => (
  <path
    className="new-point"
    d={`M${p.x - r} ${p.y}L${p.x} ${p.y - r}L${p.x + r} ${p.y}L${p.x} ${p.y + r} z`}
  />
);

interface PropsDrawNewPoints {
  store: ModelEditorStore;
}

export const DrawNewPoints: React.FC<PropsDrawNewPoints> = observer(
  ({ store }: PropsDrawNewPoints) => {
    const { point1, point2, state } = store;
    if (state !== "newPoint1" && state !== "newPoint2") return null;
    return (
      <>
        {!!point1 && <DrawPoint p={point1} />}
        {!!point2 && <DrawPoint p={point2} />}
      </>
    );
  },
);
