// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-MultiLangFields-MultiLangFields-module__fieldsBox--xQZeZ {
  border-radius: 4px;
  border: thin solid #D9D9D9;
  display: grid;
  grid-template-columns: auto 1fr;
}
.src-components-MultiLangFields-MultiLangFields-module__label--UWLjH {
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: rgba(0, 0, 0, 0.55);
  border-right: thin solid rgba(0, 0, 0, 0.06);
}
.src-components-MultiLangFields-MultiLangFields-module__inputBox--r1vFK {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.src-components-MultiLangFields-MultiLangFields-module__row--vvzY9 {
  border-bottom: thin solid rgba(0, 0, 0, 0.06);
}
`, "",{"version":3,"sources":["webpack://./src/components/MultiLangFields/MultiLangFields.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,0BAAA;EACA,aAAA;EACA,+BAAA;AACJ;AAIA;EACI,YAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;EACA,4CAAA;AAFJ;AAIA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AAFJ;AAIA;EACI,6CAAA;AAFJ","sourcesContent":[".fieldsBox {\n    border-radius: 4px;\n    border: thin solid #D9D9D9;\n    display: grid;\n    grid-template-columns: auto 1fr;\n}\n\n@internalBorder: thin solid rgba(0, 0, 0, 0.06);\n\n.label {\n    padding: 8px;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%;\n    color: rgba(0, 0, 0, 0.55);\n    border-right: @internalBorder;\n}\n.inputBox {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.row {\n    border-bottom: @internalBorder;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsBox": `src-components-MultiLangFields-MultiLangFields-module__fieldsBox--xQZeZ`,
	"label": `src-components-MultiLangFields-MultiLangFields-module__label--UWLjH`,
	"inputBox": `src-components-MultiLangFields-MultiLangFields-module__inputBox--r1vFK`,
	"row": `src-components-MultiLangFields-MultiLangFields-module__row--vvzY9`
};
export default ___CSS_LOADER_EXPORT___;
