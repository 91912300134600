import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { loadEntity } from "src/pages/EntityCardPage/apiEntityCard";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { TableStore } from "src/components/tables/TableStore";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";
import { flattenItems } from "src/common/flattenItems";
import { loadChildAttributes } from "src/pages/EntityFiltersPage/EntityList/EntityCopyButton/apiEntityCopy";
import {
  ChildEntitiesStore,
  ZEntityFiltersChild,
} from "../../ChildEntitiesStore";
import { copyEntities } from "../copyEntities";
import { ChildEntsActionType } from "../../ChildEntities.types";

export class CopyFromStore {
  constructor(
    public readonly entityId: number,
    public readonly chilEntTableStore: TableStore<
      ZEntityRow,
      ZEntityFiltersChild
    >,
    public readonly parentObjId: number,
    private onLoad?: (flag: boolean) => void,
  ) {
    makeAutoObservable(this);
  }

  objectId: number | null = null;

  setObjectId(id: number) {
    this.objectId = id;
  }

  childAttributes: number[] = [];

  setChildAttributes(attrs: number[]) {
    this.childAttributes = attrs;
  }

  loading: boolean = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  isDrawerOpen: boolean = false;

  setIsDrawerOpen(flag: boolean) {
    this.isDrawerOpen = flag;
  }

  pageStore: EntityFiltersPageStore | null = null;

  forCopyStore = new ChildEntitiesStore(new Set([ChildEntsActionType.select]));

  async init() {
    try {
      const [{ objectId }, childAttrs] = await Promise.all([
        loadEntity(this.entityId),
        loadChildAttributes(this.parentObjId),
      ]);
      this.setObjectId(objectId);
      this.setChildAttributes(
        flattenItems(childAttrs, "linkedAttributes")?.map(
          ({ attributeId }) => attributeId,
        ) ?? [],
      );
      this.pageStore = new EntityFiltersPageStore({
        actions: new Set([]),
        objectId,
        selectionSettings: { selectionType: "radio" },
      });
    } catch (error) {
      onError(error);
    }
  }

  async onClick() {
    try {
      const { pageStore } = this;
      this.onLoad?.(true);
      if (!pageStore) await this.init();
      this.setIsDrawerOpen(true);
    } catch (error) {
      onError(error);
    } finally {
      this.onLoad?.(false);
    }
  }

  onClose() {
    this.setIsDrawerOpen(false);
    this.pageStore?.tableStore?.setSelected([]);
  }

  async save() {
    try {
      const listForCopy = this.forCopyStore.tableStore?.selected.map(
        ({ id }) => id,
      );
      if (!listForCopy) return;
      this.setLoading(true);
      await copyEntities(listForCopy, this.childAttributes, this.entityId);
      this.onClose();
      await this.chilEntTableStore.reload();
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }
}
