import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { ZAttribute, zAttribute } from "src/types/ZAttribute";

export const loadFilterAttributes = async (
  attributeId: number,
): Promise<ZAttribute[]> => {
  const response = await rest.get(apiObjUrl("/attributes/filter-attributes"), {
    params: { attributeId },
  });
  return zAttribute.array().parse(response.data);
};
