import React, { useMemo } from "react";
import { PropsPersonSelect } from "src/components/PersonSelect";
import { Tag } from "antd";
import { makeAutoObservable } from "mobx";
import { getIdLabels } from "src/references/getIdNames";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { onError } from "src/common/onError";
import { observer } from "mobx-react-lite";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { makeDictionary } from "src/common/makeDictionary";
import { loadPersonsOptions } from "src/components/PersonSelect/loadPersonsOptions";
import { SelectFromReference } from "src/components/SelectFromReference";
import { getCurrentUserInfo } from "src/appStore";
import { getEditorInfo } from "src/common/attrEdit";
import { getComponentEditor } from "src/pages/EntityCardPage/blockBuilder/createItem2";
import { chatGetRequiredUsersIds } from "./getRequiredUserIds";

const makeStore = () => {
  const store = makeAutoObservable({
    typesMap: {} as Record<number, string>,
    setTypesMap(list: Record<number, string>) {
      this.typesMap = list;
    },
    async init() {
      getIdLabels("attrType", "attrType")
        .then((data) => this.setTypesMap(makeDictNameById(data)))
        .catch(onError);
    },
  });
  store.init();
  return store;
};

type PropsPersonSelectComplex = Omit<PropsPersonSelect, "attr"> & {
  cardData: EntityCardData;
};

export const PersonSelectComplex: React.FC<PropsPersonSelectComplex> = observer(
  ({ cardData, ...props }) => {
    const store = useMemo(() => makeStore(), []);
    const { entity, attributes } = cardData;

    const userListAttsObj = attributes.filter(
      ({ valueType }) => store.typesMap[valueType] === AttrTypeName.usersList,
    );

    const attsMap = makeDictionary(attributes, ({ id }) => id);
    const userListAttsEnt = entity?.attributeValues.filter(
      ({ attributeId }) => {
        const attr = attsMap[attributeId];
        if (!attr) return false;
        return store.typesMap[attr.valueType] === AttrTypeName.usersList;
      },
    );

    /**
     * множество id пользователей, которые на данный момент выбраны в экземпляре
     */
    const uniqSelected = Array.from(
      new Map(
        userListAttsEnt
          ?.map((item) => item.values)
          .flat()
          .filter(Boolean)
          .map((v) => [v, v]),
      ).values(),
    ) as string[];

    let firstAttr = userListAttsObj[0];
    const isDisabled = (id: string) => id === getCurrentUserInfo().id;
    const personsOptionsLoader = useMemo(
      () => async () =>
        !firstAttr
          ? []
          : loadPersonsOptions({ ...firstAttr, roleIds: undefined }, [
              ...uniqSelected,
              ...chatGetRequiredUsersIds(cardData),
            ]).then((data) =>
              data
                .map((opt) => ({
                  ...opt,
                  disabled: isDisabled(String(opt.value)),
                }))
                .sort((a, b) => Number(b.disabled) - Number(a.disabled)),
            ),
      [firstAttr],
    );

    if (!firstAttr)
      return (
        <Tag color="red">
          Объект не содержит атрибута с типом &ldquo;список пользователей&rdquo;
        </Tag>
      );
    /**
     * По договоренности с Максом, тут след. стратегия:
     * собитается комплесный атрибут из всех атрибутов сущности с типом "userList",
     * со всеми настройками и уникальными значениями, чтобы разом отобразить его в одном селекте пользователей
     */
    const complexViewAtts = userListAttsObj.reduce(
      (acc, curr) => {
        const editorInfo = getEditorInfo(curr.viewStyles);
        const component = getComponentEditor(editorInfo, store.typesMap, curr);
        if (component?.editor !== "PersonSelect") return acc;
        const viewAtts = component?.rolesViewAtts;
        return { ...viewAtts, ...acc };
      },
      {} as Record<string, string[]>,
    );

    /**
     * этот ужас приходится делать из-за особенности требований.
     * На карточке есть множество атрибутов с типом "список пользователей",
     * Настройки отображения у всех атрибутов могут быть разные, но тут
     * нам нужно иметь возможность отображения любого пользователя из любого атрибута
     * централизованно, для этого мы собираем все данные в один монструозный атрибут
     */
    const editorInfo = getEditorInfo(firstAttr.viewStyles);
    const component = getComponentEditor(editorInfo, store.typesMap, firstAttr);
    if (!editorInfo || component?.editor !== "PersonSelect") return null;
    component.rolesViewAtts = complexViewAtts;
    editorInfo.component = component;
    firstAttr = { ...firstAttr, viewStyles: JSON.stringify(editorInfo) };

    return (
      <SelectFromReference
        {...props}
        loader={personsOptionsLoader}
        mode="multiple"
        maxTagCount="responsive"
      />
    );
  },
);
