// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AttsTree-AttsTree-module__box--zJryz {
  overflow-x: auto;
  padding: 10px 0;
}
.src-pages-EntityFiltersPage-AttsTree-AttsTree-module__attsTree--CDvRP .ant-tree-title {
  font-weight: 500;
}
.src-pages-EntityFiltersPage-AttsTree-AttsTree-module__activeNode--TYmDs {
  color: #4263eb;
}
.src-pages-EntityFiltersPage-AttsTree-AttsTree-module__attsTreePathBox--iRT4z {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.src-pages-EntityFiltersPage-AttsTree-AttsTree-module__attsTreePathSection--VDqkA {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  font-size: 14px;
  font-weight: 600;
  color: #343a41;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AttsTree/AttsTree.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;AACF;AAEA;EAEI,gBAAA;AADJ;AAKA;EACE,cAAA;AAHF;AAMA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAJF;AAOA;EACE,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AALF","sourcesContent":[".box {\n  overflow-x: auto;\n  padding: 10px 0;\n}\n\n.attsTree {\n  :global(.ant-tree-title) {\n    font-weight: 500;\n  }\n}\n\n.activeNode {\n  color: #4263eb;\n}\n\n.attsTreePathBox {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.attsTreePathSection {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: auto;\n  font-size: 14px;\n  font-weight: 600;\n  color: #343a41;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-EntityFiltersPage-AttsTree-AttsTree-module__box--zJryz`,
	"attsTree": `src-pages-EntityFiltersPage-AttsTree-AttsTree-module__attsTree--CDvRP`,
	"activeNode": `src-pages-EntityFiltersPage-AttsTree-AttsTree-module__activeNode--TYmDs`,
	"attsTreePathBox": `src-pages-EntityFiltersPage-AttsTree-AttsTree-module__attsTreePathBox--iRT4z`,
	"attsTreePathSection": `src-pages-EntityFiltersPage-AttsTree-AttsTree-module__attsTreePathSection--VDqkA`
};
export default ___CSS_LOADER_EXPORT___;
