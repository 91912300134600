import { t } from "i18next";
import dayjs, { Dayjs } from "dayjs";
import { ifDef } from "./ifDef";

export const dateViewFormat = () => t("DateFormat", "DD.MM.YYYY");
export const dateTimeViewFormat = () => t("DateTimeFormat", "DD.MM.YYYY HH:mm");
export const timeViewFormat = () => t("TimeFormat", "HH:mm:ss");
export const dateValueFormat = "YYYY-MM-DD";
export const formatDate2ValueFormat = (date: string): string => {
  const md = dayjs(date);
  return md.isValid() ? md.format(dateValueFormat) : "";
};

export const viewDate = (rawDate?: string | null, format?: string): string =>
  ifDef(rawDate, (str) => dayjs(str).format(format ?? dateViewFormat())) ?? "";

export const viewDateUtc = (
  rawDate?: string | null,
  format?: string,
): string =>
  rawDate
    ? dayjs
        .utc(rawDate)
        .local()
        .format(format ?? dateViewFormat())
    : "";

export const viewDateTimeUtc = (
  rawDate?: string | null,
  format?: string,
): string => {
  if (!rawDate) return "";
  const utcTime = dayjs.utc(rawDate);
  if (!utcTime.isValid()) return rawDate;
  // Считаем, что rawDate соответствует Лондонскому времени. Поэтому переводим его в локальное.
  return utcTime.local().format(format ?? dateTimeViewFormat());
};

export const viewTimeUtc = (
  rawDate?: string | null,
  format?: string,
): string => {
  if (!rawDate) return "";
  // В случае времени необходимо добавить форматирование на этапе получения utc
  const utcTime = dayjs.utc(rawDate, format ?? timeViewFormat());
  if (!utcTime.isValid()) return rawDate;
  return utcTime.local().format(format ?? timeViewFormat());
};

export const date2Ed = (date?: string | null, format?: string): Dayjs | null =>
  ifDef(date, (str) => dayjs(str, format)) ?? null;

export const ed2Date = (date?: Dayjs | null, format?: string): string[] =>
  ifDef(date, (it) => [it.format(format)]) ?? [];

export const utcDate2Ed = (
  date?: string | null,
  format?: string,
): Dayjs | null => {
  if (!date) return null;
  const utcTime = dayjs.utc(date, format);
  if (!utcTime.isValid()) return null;
  return utcTime.local();
};

export const ed2UtcDate = (date?: Dayjs | null, format?: string): string[] =>
  ifDef(date, (it) => [it.utc().format(format)]) ?? [];
