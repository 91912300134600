import * as React from "react";
import { Alert, Button, Form, Input, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ModalDelete } from "src/components/ModalDelete";
import { FieldsWithTools } from "src/pages/ManagementPage/FieldsWithTools";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { MultiLangFields } from "src/components/MultiLangFields";
import { t } from "i18next";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { loadTranslation } from "src/common/api/apiTranslation";
import { businessServicesDict } from "src/businessServices/allBusinessServices";
import { WidthLimitedFields } from "src/pages/ManagementPage/WidthLimitedFields";
import { FieldsWithTitle } from "src/pages/ManagementPage/FieldsWithTitle";
import { EdBusinessService } from "../ObjectForm2/EdBusinessService";
import { ObjectO2 } from "../../Obj2Nodes";
import { Obj2TabStore } from "../../Obj2TabStore";

interface PropsServSettingsForm {
  store: Obj2TabStore;
  objNode: ObjectO2;
  servInfo: ObjectServiceExt;
}

type EdServiseCommonInfo = Omit<EdBusinessService, "serviceId">;

const loadData = async (
  sevrInfo: ObjectServiceExt,
): Promise<EdServiseCommonInfo> => {
  const { name, serviceKey } = sevrInfo;
  const nameLoc = await loadTranslation(name);
  const bs = businessServicesDict[serviceKey];
  if (!bs) throw Error(`Не найден бизнес-сервис ${serviceKey}`);
  const bsValues = await bs.loadSettings(sevrInfo);
  return {
    name,
    nameLoc: nameLoc.translations,
    ...bsValues,
  };
};

const field = (key: keyof EdServiseCommonInfo) => key;

export const ServSettingsForm: React.FC<PropsServSettingsForm> = observer(
  (props) => {
    const { store, objNode, servInfo } = props;
    const [deleteConfirm, setDeleteConfirm] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [initialData, setInitialData] = React.useState<
      EdServiseCommonInfo | undefined
    >();
    const [dataLoading, setDataLoading] = React.useState(false);
    const [form] = Form.useForm<EdServiseCommonInfo>();
    React.useEffect(() => {
      setDataLoading(true);
      loadData(servInfo)
        .then((data) => {
          setInitialData(data);
          form.setFieldsValue(data);
        })
        .finally(() => setDataLoading(false))
        .catch(onError);
    }, [servInfo]);
    const doDelete = async () => {
      try {
        setDeleting(true);
        await store.deleteService(objNode, servInfo);
        setDeleteConfirm(false);
      } catch (e) {
        onError(e);
      } finally {
        setDeleting(false);
      }
    };
    const bsDef = businessServicesDict[servInfo.serviceKey];
    if (!bsDef)
      return (
        <Alert
          type="error"
          message={`Не найдено описание сервиса ${servInfo.serviceKey}`}
        />
      );
    return (
      <FieldsWithTools
        tools={
          <>
            <Button
              onClick={() => {
                setDeleteConfirm(true);
              }}
              icon={<DeleteOutlined />}
              danger
              id="deleteCurService"
            >
              Удалить бизнес-сервис
            </Button>
            <Button
              onClick={() => form.submit()}
              type="primary"
              loading={store.servUpdating}
            >
              {t("Save")}
            </Button>
          </>
        }
      >
        <Spin spinning={dataLoading}>
          <Form
            form={form}
            layout="vertical"
            initialValues={initialData}
            onFinish={(values) =>
              store.updateService(objNode, bsDef, servInfo, values)
            }
          >
            <WidthLimitedFields>
              <FieldsWithTitle title="Название бизнес-сервиса">
                <Form.Item
                  name={field("name")}
                  label="Название бизнес-сервиса"
                  rules={[trimRequired()]}
                >
                  <Input allowClear />
                </Form.Item>
                <MultiLangFields basePath={[field("nameLoc")]} />
              </FieldsWithTitle>
              <FieldsWithTitle title="Настройки структуры бизнес-сервиса">
                {bsDef.renderSettings(servInfo, store)}
              </FieldsWithTitle>
            </WidthLimitedFields>
          </Form>
        </Spin>
        <ModalDelete
          open={deleteConfirm}
          message="Удалить бизнес-сервис?"
          waiting={deleting}
          onCancel={() => setDeleteConfirm(false)}
          onOk={doDelete}
        />
      </FieldsWithTools>
    );
  },
);
