import * as React from "react";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import {
  CommonPersonNode,
  GroupPersonNode,
  RolePersonNode,
} from "./PersonNode";
import { ZRole, ZRolesGroup } from "../Obj2Tab/roles/roleTypes";

export const createPersonTree = (groups: ZRolesGroup[]): CommonPersonNode[] =>
  groups.map(createPersonGroupNode);

const createPersonGroupNode = (group: ZRolesGroup): GroupPersonNode => ({
  key: `group:${group.groupId}`,
  title: group.groupName,
  icon: <TeamOutlined />,
  isLeaf: group.roles.length === 0,
  type: "group",
  group,
  children: group.roles.map(createPersonRoleNode),
});

const createPersonRoleNode = (role: ZRole): RolePersonNode => ({
  key: `role:${role.roleId}`,
  title: role.roleName,
  icon: <UserOutlined />,
  isLeaf: true,
  type: "role",
  role,
});
