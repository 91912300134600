import * as React from "react";
import { PageLayout } from "src/components/PageLayout";
import { Alert } from "antd";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

export const NoMatch: React.FC = () => {
  const location = useLocation();
  return (
    <PageLayout header={t("Invalid page URL")}>
      <Alert
        type="warning"
        showIcon
        message={`No match for path ${location.pathname}`}
      />
    </PageLayout>
  );
};
