import * as React from "react";
import { CtrlResizable } from "src/components/tables/TableStore";
import { ColumnPoor } from "src/components/tables/ColumnPoor";
import { observer } from "mobx-react-lite";
import { VerticalAlignMiddleOutlined } from "@ant-design/icons";
import styles from "./ResizerStd.module.less";
import { getColumnWidth } from "../templates/getColumnWidths";

type PropsResizerStd = {
  column: ColumnPoor;
  store: CtrlResizable;
  containerRef?: React.RefObject<HTMLDivElement>;
};

export const ResizerStd: React.FC<PropsResizerStd> = observer((props) => {
  const { column, store, containerRef } = props;
  const [dragStartState, setDragStartState] = React.useState<[number, number]>([
    0, 0,
  ]);

  React.useEffect(() => {
    // Устанавливаем ширину только если она ещё не задана
    if (!store.columnWidths[column.key] && containerRef?.current) {
      store.setColumnWidth(column.key, containerRef.current.offsetWidth);
    }
  }, [containerRef?.current]);

  const handleDragStart = (e: React.DragEvent) => {
    const blankDragImg = document.createElement("span");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setDragImage(blankDragImg, 0, 0);
    setDragStartState([
      e.clientX,
      Number(getColumnWidth(store.columnWidths, column.key) || 0),
    ]);
  };

  const handleDrag = (key: string) => (event: React.DragEvent) => {
    if (event.clientX !== 0) {
      const [startX, colWStart] = dragStartState;
      const currentWidth = colWStart + (event.clientX - startX);
      store.setColumnWidth(key, currentWidth);
    }
  };

  return (
    store.resizableColumns && (
      <div
        className={styles.columnResizeHandle}
        role="presentation"
        draggable
        onDragStart={handleDragStart}
        onDrag={handleDrag(column.key)}
      >
        <VerticalAlignMiddleOutlined className={styles.icon} />
      </div>
    )
  );
});
