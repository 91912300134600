import React, { useMemo } from "react";
import { makeAutoObservable } from "mobx";
import { ZAttribute } from "src/types/ZAttribute";
import { makeDictionary } from "src/common/makeDictionary";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { onError } from "src/common/onError";
import { getTypesMap } from "src/references/getTypesMap";
import { Form } from "antd";
import { AttrTypeName } from "src/types/AttrType";
import { observer } from "mobx-react-lite";
import { edAttrField } from "src/pages/ManagementPage/Obj2Tab/EdAttribute";
import { gbName, makeFormName } from "./names";
import { ArticleTemplateInput } from "./ArticleTemplateInput";
import { ArticleAttrMetaForm } from "./ArticleAttrMetaForm";

const createStore = (objectId: number) => {
  const store = makeAutoObservable({
    attsDict: {} as Record<number, ZAttribute>,
    setAttsDict(dict: Record<number, ZAttribute>) {
      this.attsDict = dict;
    },
    attrTypesMap: {} as Record<number, string>,
    setAttrTypesMap(dict: Record<number, string>) {
      this.attrTypesMap = dict;
    },
    init() {
      loadObjectAttrinbutesAll(objectId)
        .then((d) => this.setAttsDict(makeDictionary(d, ({ id }) => id)))
        .catch(onError);
      getTypesMap()
        .then((data) => this.setAttrTypesMap(data))
        .catch(onError);
    },
  });
  store.init();
  return store;
};

type PropsAttrArticleTemplateForm = { objectId: number };
export const AttrArticleTemplateForm: React.FC<PropsAttrArticleTemplateForm> =
  observer(({ objectId }) => {
    const store = useMemo(() => createStore(objectId), []);
    const name = edAttrField.articleNumberInfo;
    const attributeIds = Form.useWatch<{ attributeId: number }[] | undefined>(
      makeFormName(name, "attributes"),
    );
    const attsGroup = Form.useWatch<number[] | undefined>(
      gbName(name, "attrIds"),
    );
    const selectedAtts = Array.from(
      new Map(
        [
          ...(attsGroup || []),
          ...(attributeIds || []).map(({ attributeId }) => attributeId),
        ].map((attrId) => [attrId, store.attsDict[attrId]]),
      ).values(),
    ).filter(Boolean) as ZAttribute[];
    const refLikeAtts = selectedAtts.filter(
      (attr) => store.attrTypesMap[attr.valueType] === AttrTypeName.object,
    );

    return (
      <>
        <ArticleTemplateInput
          store={store}
          objectId={objectId}
          baseName={name}
        />
        <ArticleAttrMetaForm
          store={store}
          attributes={refLikeAtts}
          baseName={name}
        />
      </>
    );
  });
