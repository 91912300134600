import { ModelEditorStore } from "../ModelEditorStore";
import { CmdAttrInsert } from "./CmdAttrInsert";
import { Command, generateCmdKey } from "./Command";

export class CmdAttrDelete implements Command {
  readonly key: number;

  constructor(
    private store: ModelEditorStore,
    private index: number,
  ) {
    this.key = generateCmdKey();
  }

  readonly title = "Удаление атрибута";

  createUndo(): Command {
    const attr = this.store.model?.attrs[this.index];
    if (!attr) throw Error(`Не найден атрибут с индексом ${this.index}`);
    return new CmdAttrInsert(this.store, attr, this.index, false);
  }

  exec(): void {
    this.store.model?.attrs.splice(this.index, 1);
  }
}
