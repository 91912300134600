// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlBox--vOaKJ {
  padding: 4px 11px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 4px;
  background: none;
  border: none;
  width: 100%;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlBox--vOaKJ:focus .src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlValue--qW6SA {
  outline: thin solid #00D;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlValue--qW6SA {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlValue--qW6SA:hover {
  outline: thin solid #DDD;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/PointSelectExt/PointSelectExt.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,QAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AACJ;AAAI;EAAuB,wBAAA;AAG3B;AADA;EACI,OAAA;EAIA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AANI;EACI,wBAAA;AAQR","sourcesContent":[".controlBox {\n    padding: 4px 11px;\n    display: flex;\n    flex-direction: row;\n    cursor: pointer;\n    gap: 4px;\n    background: none;\n    border: none;\n    width: 100%;\n    &:focus .controlValue {outline: thin solid #00D;}\n}\n.controlValue {\n    flex: 1;\n    &:hover {\n        outline: thin solid #DDD;\n    }\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlBox--vOaKJ`,
	"controlValue": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectExt-PointSelectExt-module__controlValue--qW6SA`
};
export default ___CSS_LOADER_EXPORT___;
