// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-EntityList-EntityCopyButton-EntityCopyButton-module__subTitle--TVwT3 {
  color: #0000008C;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/EntityList/EntityCopyButton/EntityCopyButton.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".subTitle {\n  color: #0000008C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subTitle": `src-pages-EntityFiltersPage-EntityList-EntityCopyButton-EntityCopyButton-module__subTitle--TVwT3`
};
export default ___CSS_LOADER_EXPORT___;
