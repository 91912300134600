/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const DragableIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => (
  <svg
    viewBox="0 0 6 12"
    {...props}
    width="6"
    height="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0H0V2H2V0ZM2 5H0V7H2V5ZM0 10H2V12H0V10ZM6 0H4V2H6V0ZM4 5H6V7H4V5ZM6 10H4V12H6V10Z"
    />
  </svg>
);
/**
 * используется в левом меню для пункта "справочник категорий" в активном состонии
 */
export const DragableIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={DragableIconStdSvg} {...props} />;
