import * as React from "react";
import { z } from "zod";
import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { ifDef } from "src/common/ifDef";
import { findNodeByKey } from "src/common/findNodeByKey";
import { ZObjectItem } from "src/types/ZObjectItem";
import { onError } from "src/common/onError";
import {
  FormBlockDef,
  FormBlockItem,
  FormWithBlockStore,
  blockItem,
} from "src/components/FormWithBlocks";
import { IdLabel, getIdLabels } from "src/references/getIdNames";
import { makeDictNameById } from "src/types/AttrType";
import { createEntityGroupBlock } from "src/pages/EntityCardPage/blockBuilder/buildMnemoGroup";
import { createItem2 } from "src/pages/EntityCardPage/blockBuilder/createItem2";
import { rest } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import { EntityInput } from "src/pages/EntityCardPage/fieldComponents/EntityInput";
import { initTypeIcons } from "../Obj2Tab/utils/typeIcons";
import { loadRoleGroups } from "../Obj2Tab/roles/rolesApi";
import { ZRolesGroup } from "../Obj2Tab/roles/roleTypes";
import { createPersonTree } from "./createPersonsTree";
import { CommonPersonNode } from "./PersonNode";
import { apiLoadRoles } from "../RolesTab/apiRolesTab";
import { PersonDetailsStore } from "./PersonDetailsList/PersonDetailsStore";
import { zPersonEntity } from "./ZPersonEntity";

type PersonTabData = {
  groups: ZRolesGroup[];
  objects: ZObjectItem[];
};

const zPersonFieldValue = z.string().array().nullable().optional();

const zPersonFields = z.record(z.string(), zPersonFieldValue);
type ZPersonFields = z.infer<typeof zPersonFields>;

export class PersonsTabStore {
  constructor() {
    makeAutoObservable(this);
  }

  data: RemoteData<PersonTabData> = { status: "none" };

  setData(newData: RemoteData<PersonTabData>) {
    this.data = newData;
  }

  get treeData(): CommonPersonNode[] {
    return this.data.status === "ready"
      ? createPersonTree(this.data.result.groups)
      : [];
  }

  // Справочник атрибутов

  attrTypesList: IdLabel[] = [];

  setAttrTypesList(list: IdLabel[]) {
    this.attrTypesList = list;
  }

  get attrTypesDict(): Record<number, string> {
    return makeDictNameById(this.attrTypesList);
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      if (this.attrTypesList.length === 0) {
        this.setAttrTypesList(await getIdLabels("attrType", "attrType"));
        initTypeIcons(this.attrTypesDict);
      }

      const [groups, objects] = await Promise.all([
        loadRoleGroups(),
        apiLoadRoles(),
      ]);
      this.setData({ status: "ready", result: { groups, objects } });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  currentPersonId: number | "new" | null = null;

  setCurrentPersonId(persId: PersonsTabStore["currentPersonId"]) {
    this.currentPersonId = persId;
  }

  selectedKeys: React.Key[] = [];

  setSelectedKeys(keys: React.Key[]) {
    this.selectedKeys = keys;
    this.setCurrentPersonId(null);
  }

  get curNode(): CommonPersonNode | undefined {
    return ifDef(this.selectedKeys[0], (key) =>
      findNodeByKey(key, this.treeData),
    );
  }

  get personDetailsStore(): PersonDetailsStore | null {
    const { curNode } = this;
    if (!curNode || curNode.type !== "role" || this.data.status !== "ready")
      return null;
    // К сожалению, все необходимые данные приходится комбинировать из двух разных запросов
    const roleObject = this.data.result.objects.find(
      ({ roleId }) => roleId === curNode.role.roleId,
    );
    if (!roleObject) {
      onError(Error("Не найдено соответствующего объекта, описывающего роль"));
      return null;
    }
    return new PersonDetailsStore(curNode.role, roleObject);
  }

  personData: RemoteData<ZPersonFields> = { status: "none" };

  setPersonData(data: RemoteData<ZPersonFields>) {
    this.personData = data;
  }

  onNewPerson() {
    this.setCurrentPersonId("new");
    this.setPersonData({
      status: "ready",
      result: {
        login: null,
      },
    });
  }

  async onPerson(id: number) {
    this.setCurrentPersonId(id);
    try {
      this.setPersonData({ status: "wait" });
      const resp = await rest.get(apiObjUrl(`/users/${id}`));
      const entity = zPersonEntity.parse(resp.data);
      const result: ZPersonFields = {
        login: [entity.userLogin],
      };
      entity.attributeValues.forEach(({ attributeId, values }) => {
        result[String(attributeId)] = values;
      });
      this.setPersonData({ status: "ready", result });
    } catch (error) {
      this.setPersonData({ status: "error", error });
    }
  }

  onCancelEdit() {
    this.setCurrentPersonId(null);
  }

  async onSubmit(formValues: unknown): Promise<ZPersonFields> {
    // Здесь не требуется try, т.к. обработка ошибок происходит в вызывающей функции
    const { currentPersonId, personDetailsStore } = this;
    if (!currentPersonId) throw Error("FE. Не указан пользователь");
    if (!personDetailsStore) throw Error("FE. Отсутствует стор пользвателей");
    const { login, ...fields } = zPersonFields.parse(formValues);
    const data = {
      objectId: personDetailsStore.roleObject.id,
      attributeValues: Object.entries(fields).map(([key, values]) => ({
        attributeId: +key,
        values,
      })),
    };
    const params = { login: login?.[0] };
    if (currentPersonId === "new") {
      // Создание нового пользователя
      const resp = await rest.post(apiObjUrl("/users"), data, { params });
      const newPers = zPersonEntity.parse(resp.data);
      this.onPerson(newPers.id); // здесь не требуется wait, т.к. это уже работает асинхронно
    } else {
      // Обновление
      await rest.put(apiObjUrl(`/users/${currentPersonId}`), data);
    }
    return fields;
  }

  get initialData(): ZPersonFields {
    return this.personData.status === "ready" ? this.personData.result : {};
  }

  get formBlock(): FormBlockDef | undefined {
    const { personDetailsStore } = this;
    if (!personDetailsStore) return undefined;

    const buildCtx = {
      canUpdate: true,
      typesMap: this.attrTypesDict,
    };
    const items: FormBlockItem[] = [
      // Специальное поле для логина
      blockItem(
        "login",
        "Логин",
        EntityInput,
        { allowClear: true, disabled: this.currentPersonId !== "new" },
        {
          rules: [
            { required: true },
            {
              pattern: /^.+@.+$/,
              message: "В качестве логина нужно указать email",
            },
          ],
        },
      ),
      // А дальше поля, генерируемые из атрибутов
      ...personDetailsStore.roleObject.attributes.map((a) =>
        createItem2(a, buildCtx),
      ),
    ];

    return createEntityGroupBlock("root", "", items, []);
  }

  formStore = new FormWithBlockStore();
}
