import * as React from "react";
import { MaskedInput } from "antd-mask-input";
import { MaskedInputProps } from "antd-mask-input/build/main/lib/MaskedInput";
import { ifDef } from "src/common/ifDef";

type PropsEntityMaskedInput = Omit<MaskedInputProps, "onChange" | "value"> & {
  value?: string[] | null;
  onChange?(newValue: string[] | null | undefined): void;
};

export const EntityMaskedInput: React.FC<PropsEntityMaskedInput> = (props) => {
  const { value, onChange, ...restProps } = props;
  return (
    <MaskedInput
      size="large"
      {...restProps}
      value={value?.[0]}
      onChange={(e) => onChange?.(ifDef(e.currentTarget.value, (it) => [it]))}
    />
  );
};

EntityMaskedInput.defaultProps = {
  value: undefined,
  onChange: undefined,
};
