import * as React from "react";
import { PageLayout } from "src/components/PageLayout";
import { observer } from "mobx-react-lite";
import { categoryRefStore } from "./categoryRefStore";
import { CategoryTree } from "./CateroryTree";
import { CategoryAttributes } from "./CategoryAttributes";
import styles from "./CategoryRefPage.module.less";

export const CategoryRefPage: React.FC = observer(() => {
  const store = categoryRefStore;
  React.useEffect(() => {
    store.init();
  }, []);

  return (
    <PageLayout pageTitle="Справочник категорий">
      <div className={styles.layout}>
        <CategoryTree store={store.treeStore} />
        <CategoryAttributes
          attrStore={store.attrStore}
          treeStore={store.treeStore}
        />
      </div>
    </PageLayout>
  );
});
