import { Tree } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PersonsTabStore } from "./PersonsTabStore";

interface PropsPersonsTree {
  store: PersonsTabStore;
}

export const PersonsTree: React.FC<PropsPersonsTree> = observer(({ store }) => (
  <Tree
    treeData={store.treeData}
    className="idPersonsTree"
    showIcon
    selectedKeys={store.selectedKeys}
    onSelect={(keys) => store.setSelectedKeys(keys)}
  />
));
