// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ChildEntities-SummaryFooter-SummaryFooter-module__tableFooter--fBLWi {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  position: sticky;
  bottom: 0;
  background-color: #fafafa;
  z-index: 2;
  width: 100%;
  min-width: fit-content;
}
.src-components-ChildEntities-SummaryFooter-SummaryFooter-module__footerItem--Gbe5z {
  position: relative;
  flex: 1;
  padding: 16px 24px;
  text-align: left;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  color: #000000D9;
  display: flex;
  align-items: center;
  min-width: 150px;
}
.src-components-ChildEntities-SummaryFooter-SummaryFooter-module__footerItemTitle--d1y3H {
  flex: none;
  display: block;
  width: 75px;
  color: #0000008C;
  min-width: unset;
}
.src-components-ChildEntities-SummaryFooter-SummaryFooter-module__columnFooterContent--rwM4o {
  flex-grow: 1;
  user-select: none;
}
.src-components-ChildEntities-SummaryFooter-SummaryFooter-module__footerItemTitle--d1y3H,
.src-components-ChildEntities-SummaryFooter-SummaryFooter-module__columnFooterContent--rwM4o {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChildEntities/SummaryFooter/SummaryFooter.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,gCAAA;EACA,gBAAA;EACA,SAAA;EACA,yBAAA;EACA,UAAA;EACA,WAAA;EACA,sBAAA;AACF;AAEA;EACE,kBAAA;EACA,OAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AAAF;AAGA;EACE,UAAA;EACA,cAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AADF;AAIA;EACE,YAAA;EACA,iBAAA;AAFF;AAKA;;EACE,gBAAA;EACA,mBAAA;EACA,uBAAA;AAFF","sourcesContent":[".tableFooter {\n  display: flex;\n  flex-direction: row;\n  border-top: 1px solid #e2e2e2;\n  border-bottom: 1px solid #e2e2e2;\n  position: sticky;\n  bottom: 0;\n  background-color: #fafafa;\n  z-index: 2;\n  width: 100%;\n  min-width: fit-content;\n}\n\n.footerItem {\n  position: relative;\n  flex: 1;\n  padding: 16px 24px;\n  text-align: left;\n  overflow: hidden;\n  font-size: 12px;\n  font-weight: 700;\n  color: #000000D9;\n  display: flex;\n  align-items: center;\n  min-width: 150px;\n}\n\n.footerItemTitle {\n  flex: none;\n  display: block;\n  width: 75px;\n  color: #0000008C;\n  min-width: unset;\n}\n\n.columnFooterContent {\n  flex-grow: 1;\n  user-select: none; // Предотвращает выделение текста при перетаскивании\n}\n\n.footerItemTitle, .columnFooterContent {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableFooter": `src-components-ChildEntities-SummaryFooter-SummaryFooter-module__tableFooter--fBLWi`,
	"footerItem": `src-components-ChildEntities-SummaryFooter-SummaryFooter-module__footerItem--Gbe5z`,
	"footerItemTitle": `src-components-ChildEntities-SummaryFooter-SummaryFooter-module__footerItemTitle--d1y3H`,
	"columnFooterContent": `src-components-ChildEntities-SummaryFooter-SummaryFooter-module__columnFooterContent--rwM4o`
};
export default ___CSS_LOADER_EXPORT___;
