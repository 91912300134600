/**
 * Запрос справочников в формате {id, name}
 */

import { t } from "i18next";
import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { ZIdName, zIdName } from "src/types/ZIdName";

export type IdNameRef = "attrType" | "groupType";

const cache: Partial<Record<IdNameRef, ZIdName[]>> = {};

export const getIdNames = async (reference: IdNameRef): Promise<ZIdName[]> => {
  // Нельзя делать глобально, т.к. может включиться проксирование урлов
  const refUrl: Readonly<Record<IdNameRef, string>> = {
    // Типы данных, которые возможны для атрибутов
    attrType: apiObjUrl("/attributes/value-types"),
    groupType: apiObjUrl("/groups/types"),
  };
  const cachedItem = cache[reference];
  if (cachedItem) return cachedItem;
  const response = await rest.get(refUrl[reference]);
  const newItem = zIdName.array().parse(response.data);
  cache[reference] = newItem;
  return newItem;
};

export const loaderIdNames = (reference: IdNameRef) => () =>
  getIdNames(reference);

export type IdLabel = ZIdName & { label: string };
export const idLabelFieldNames = { value: "id", label: "label" };

/**
 * Данные, локализованные через словарь
 */
export const getIdLabels = async (
  reference: IdNameRef,
  dictPart: string,
  sort?: boolean,
): Promise<IdLabel[]> => {
  const srcList: ZIdName[] = await getIdNames(reference);
  const labels = srcList.map((rec) => ({
    ...rec,
    label: t(`${dictPart}.${rec.name}`),
  }));
  if (sort !== false) {
    labels.sort((a, b) => cmp(a.label, b.label));
  }
  return labels;
};
const cmp = (a: string, b: string): number => {
  const va = a.toLowerCase();
  const vb = b.toLowerCase();
  if (va < vb) return -1;
  if (vb < va) return 1;
  return 0;
};

export const loaderIdLabels =
  (reference: IdNameRef, dictPart: string, sort?: boolean) => () =>
    getIdLabels(reference, dictPart, sort);
