/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const BellIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5414 1.86686C13.9624 2.51014 15.7915 4.63149 16.0508 7.21341C16.1725 8.42468 16.3153 9.67045 16.4713 10.7093C16.6415 11.843 17.2083 13.3108 17.7946 14.5944C18.5561 16.2613 17.4011 18.333 15.4629 18.333H11.249C11.249 18.7932 10.8759 19.1663 10.4157 19.1663H9.58235C9.12212 19.1663 8.74902 18.7932 8.74902 18.333H4.53519C2.59693 18.333 1.44189 16.2613 2.20342 14.5944C2.78978 13.3108 3.35654 11.843 3.52678 10.7093C3.68276 9.67045 3.82554 8.42468 3.94721 7.21341C4.20658 4.6315 6.03567 2.51014 8.45669 1.86686C8.70586 1.2602 9.30255 0.833008 9.99902 0.833008C10.6955 0.833008 11.2922 1.2602 11.5414 1.86686ZM9.99902 3.33301C7.73216 3.33301 5.83529 5.09283 5.60553 7.38C5.48273 8.60244 5.33681 9.87891 5.17497 10.9567C4.96784 12.3362 4.31486 13.9834 3.71939 15.2869C3.41788 15.9469 3.91513 16.6663 4.53519 16.6663H15.4629C16.0829 16.6663 16.5802 15.9469 16.2787 15.2869C15.6832 13.9834 15.0302 12.3362 14.8231 10.9567C14.6612 9.87891 14.5153 8.60244 14.3925 7.38C14.1628 5.09283 12.2659 3.33301 9.99902 3.33301Z"
      fill="black"
    />
  </svg>
);
/**
 * используется в левом меню для пункта "справочник категорий" в активном состонии
 */
export const BellIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={BellIconStdSvg} {...props} />;
