import { loadRefDict } from "src/common/loadRefDict";
import { ZAttribute } from "src/types/ZAttribute";
import { AttrLabelLoader } from "../types";
import { defAttrLabelTreeData } from "../AttrLabelTreeData";

export const labelLoaderDict: AttrLabelLoader = async (
  values: string[],
  attr: ZAttribute,
) => {
  if (!attr.referenceId) return null;
  const refOptList = await loadRefDict(attr.referenceId, { translate: true });
  const dictKeys = new Set(values);
  const labels = refOptList
    .filter((item) => dictKeys.has(String(item.id)))
    .filter(Boolean)
    .map((item) => item.value) as string[];
  return defAttrLabelTreeData(labels, attr);
};
