import React from "react";
import { classNames } from "src/common/classNames";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import styles from "./SimpleText.module.less";

type PropsSimpleText = {
  values: React.ReactNode[];
  viewInfo?: ZAttrViewInfo;
  className?: string;
};

export const SimpleText: React.FC<PropsSimpleText> = ({
  values,
  viewInfo,
  className,
}) => {
  const overflow = viewInfo?.appearance?.view?.overflow;
  return (
    <div
      style={viewInfo?.styles ?? undefined}
      className={classNames([className, styles[overflow || ""]])}
    >
      {values}
    </div>
  );
};

SimpleText.defaultProps = {
  viewInfo: undefined,
  className: undefined,
};
