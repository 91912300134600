import { Tag } from "antd";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { getEditorInfo } from "src/common/attrEdit";
import { getViewInfo, ZAttrViewInfo } from "src/common/attrView";
import { createItemView } from "src/common/attrView/createItemView/createItemView";
import { onError } from "src/common/onError";
import { getIdLabels } from "src/references/getIdNames";
import { makeDictNameById } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";

const makeStore = () => {
  const store = makeAutoObservable({
    typesMap: {} as Record<number, string>,
    setTypesMap(list: Record<number, string>) {
      this.typesMap = list;
    },
    async init() {
      getIdLabels("attrType", "attrType")
        .then((data) => this.setTypesMap(makeDictNameById(data)))
        .catch(onError);
    },
  });
  store.init();
  return store;
};

type PropsAttrLinkedListView = {
  values: string[];
  attrData: ZAttribute;
  linkedAttrData: ZAttribute;
};

export const AttrLinkedListView: React.FC<PropsAttrLinkedListView> = observer(
  ({ linkedAttrData, attrData, values }) => {
    const store = useMemo(() => makeStore(), []);
    const viewInfo = getViewInfo(linkedAttrData.viewType);
    const editorInfo = getEditorInfo(attrData.viewStyles);
    if (editorInfo?.component?.editor !== "LinkedValue")
      return <Tag color="red">config error</Tag>;
    const newViewInfo = {
      ...viewInfo,
      appearance: {
        ...viewInfo?.appearance,
        view: {
          overflow: "elipsis",
          separator: editorInfo?.component.separator,
        },
      },
    } as ZAttrViewInfo;

    return createItemView(linkedAttrData, store.typesMap, values, newViewInfo);
  },
);
