import * as React from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined, SisternodeOutlined } from "@ant-design/icons";
import { GroupType } from "src/types/ZGroup";
import { iconGroupDict, iconGroupMnemonic } from "../../utils/itemIcons";
import { Obj2TabStore } from "../../Obj2TabStore";

interface PropsAddGroupItem {
  store: Obj2TabStore;
  disabled?: boolean;
  buttonId?: string;
}

export const AddGroupItem: React.FC<PropsAddGroupItem> = (props) => {
  const { store, disabled, buttonId } = props;

  const menuProps: MenuProps = {
    items: [
      {
        key: "attr",
        label: "Добавить атрибут",
        icon: <SisternodeOutlined />,
        onClick: () => store.safeAddAttr(),
      },
      {
        type: "divider",
      },
      {
        key: "groups",
        type: "group",
        label: "Добавить группу атрибутов",
        children: [
          {
            key: "mnenoGroup",
            label: "Мнемоническую",
            icon: iconGroupMnemonic,
            onClick: () => store.safeAddGroup(GroupType.Mnemonic),
          },
          {
            key: "dictiGroup",
            label: "На основе справочника",
            icon: iconGroupDict,
            onClick: () => store.safeAddGroup(GroupType.ByDictionary),
          },
        ],
      },
    ],
  };

  return (
    <Dropdown menu={menuProps} disabled={disabled}>
      <Button id={buttonId}>
        <Space>
          Добавить
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

AddGroupItem.defaultProps = {
  disabled: false,
  buttonId: undefined,
};
