import React from "react";
import { SelectDictionaryStore } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectDictionary";
import { observer } from "mobx-react-lite";
import { Flex } from "antd";
import styles from "./SampleDictSection.module.less";
import { SampleDictSelect } from "./SampleDictSelect";

interface PropsSampleDictSection {
  store: SelectDictionaryStore;
}

export const SampleDictSection: React.FC<PropsSampleDictSection> = observer(
  ({ store }) => (
    <Flex gap={12} vertical>
      <div className={styles.dict}>Dictionaries</div>
      <div className={styles.box}>
        <SampleDictSelect
          store={store}
          name="dictSizeVersionId"
          label="Size version dictionary"
        />
        <SampleDictSelect
          store={store}
          name="dictRequestTypeId"
          label="Request type dictionary"
        />
        <SampleDictSelect
          store={store}
          name="dictRequestStatusId"
          label="Request status dictionary"
        />
        <SampleDictSelect
          store={store}
          name="dictFitStatusId"
          label="Fit status dictionary"
        />
        <SampleDictSelect
          store={store}
          name="dictSampleRequestTypeId"
          label="Sample request dictionary"
        />
      </div>
    </Flex>
  ),
);
