/**
 * Кнопка добавления чего-то важного.
 * Располагается в верхнем левом углу, над деревом.
 * Добавлено с целью стандартизации.
 */
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as React from "react";
import styles from "./AddButton.module.less";

interface PropsAddButton {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  id: string; // для автотестирования
}

export const AddButton: React.FC<PropsAddButton> = ({
  text,
  onClick,
  disabled,
  id,
}) => (
  <Button
    icon={<PlusCircleOutlined />}
    onClick={onClick}
    disabled={disabled}
    id={id}
    className={styles.addButton}
  >
    {text}
  </Button>
);

AddButton.defaultProps = {
  disabled: false,
};
