import React, { useEffect } from "react";
import { TabsProps, Spin, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { schedulingSettingsStore } from "./SchedulingSettingsStore";
import {
  TaskFields,
  TaskTemplateFields,
  GroupFields,
  PlanFields,
  PlanTemplateFields,
} from "./tabs";
import styles from "./SchedulingSettings.module.less";

interface PropsSchedulingSettings {
  typesMap: Record<number, string>;
}

export const SchedulingSettings: React.FC<PropsSchedulingSettings> = observer(
  ({ typesMap }) => {
    useEffect(() => {
      schedulingSettingsStore.init(typesMap);
    }, []);
    const sectionsStore = schedulingSettingsStore.formSectionsStore;
    const { loading } = sectionsStore;
    const items: TabsProps["items"] = [
      {
        key: "task",
        label: "Задача",
        children: <TaskFields store={sectionsStore} />,
        forceRender: true,
      },
      {
        key: "taskTemplate",
        label: "Шаблон задачи",
        children: <TaskTemplateFields store={sectionsStore} />,
        forceRender: true,
      },
      {
        key: "taskGroup",
        label: "Группа задач",
        children: (
          <GroupFields
            tabKey="groupSettings"
            objType="taskGroup"
            store={sectionsStore}
          />
        ),
        forceRender: true,
      },
      {
        key: "taskGroupTemplate",
        label: "Шаблон группы задач",
        children: (
          <GroupFields
            tabKey="groupTemplateSettings"
            objType="taskGroupTemplate"
            store={sectionsStore}
          />
        ),
        forceRender: true,
      },
      {
        key: "plan",
        label: "План",
        children: <PlanFields store={sectionsStore} />,
        forceRender: true,
      },
      {
        key: "planTemplate",
        label: "Шаблон плана",
        children: <PlanTemplateFields store={sectionsStore} />,
        forceRender: true,
      },
    ];

    return (
      <Spin spinning={loading}>
        <Tabs className={styles.tabs} items={items} />
      </Spin>
    );
  },
);
