import * as React from "react";
import { ColorPicker, Divider, Form, Select } from "antd";
import { AttrViewCompName, viewsByType } from "src/common/attrView";
import { ZOption } from "src/types/ZOption";
import { AttrTypeName } from "src/types/AttrType";
import { getAllowedViewStyleItems } from "src/common/attrView/viewFormItems/getAllowedViewStyleItems";
import { Separator } from "src/common/attrView/viewFormItems/Separator";
import { Overflow } from "src/common/attrView/viewFormItems/Overflow";
import { getInitialViewData } from "src/common/attrView/viewFormItems/getInitialViewData";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { TableOutlined } from "@ant-design/icons";
import { edAttrField } from "../../../../EdAttribute";
import { SelectAttributeFlat } from "../../SelectAttributeFlat/SelectAttributeFlat";
import styles from "../EditorInfo.module.less";
import { DictRefViewInfo } from "./DictRefViewInfo/DictRefViewInfo";
import { TableSettingsInfo } from "../TableSettingsInfo/TableSettingsInfo";
import { SelectAttributesRolesList } from "./SelectAttributesRolesList";
import { ObjectRefViewInfo } from "./ObjectRefViewInfo/ObjectRefViewInfo";
import { PersonCellViewInfo } from "./PersonCellViewInfo/PersonCellViewInfo";

interface PropsViewInfo {
  attrTypesDict: Record<number, string>;
  readonly currentObjectId: number | null;
}

const root = edAttrField.viewInfo;
const compField = (name: string) => [root, "component", name];
const stylesField = (name: string) => [root, "styles", name];
const appearanceField = (name: string) => [root, "appearance", name];
const viewField = compField("view");

export const ViewInfo: React.FC<PropsViewInfo> = (props) => {
  const { attrTypesDict, currentObjectId } = props;
  const form = Form.useFormInstance();
  const refId = Form.useWatch(edAttrField.referenceId);
  const roleIds = Form.useWatch(edAttrField.roleIds) || [];

  const curView: AttrViewCompName | undefined = Form.useWatch(viewField);
  const attrType = Form.useWatch(edAttrField.valueType);
  const typeName = (attrTypesDict[attrType] ??
    String(attrType)) as AttrTypeName;

  const componentNames: ZOption[] = React.useMemo(() => {
    const meta: string[] = viewsByType[typeName] ?? [];
    if (curView && !meta.includes(curView)) {
      form.resetFields(viewField);
    }
    return meta.map((value) => ({ value, label: value }));
  }, [attrType]);

  const isTag = curView === "Tag";
  const isPersonInfo = curView === "PersonCellInfo";
  const isViewOneOf = (names: AttrViewCompName[]) =>
    curView ? names.includes(curView) : false;

  const withLabelAtts = isViewOneOf(["ObectRef", "ChildEntitiesCellInfo"]);
  const canOverflow = isViewOneOf([
    "DictRef",
    "ObectRef",
    "SimpleText",
    "LinkStd",
    "FileList",
    "PersonCellInfo",
    "Tag",
    "LinkView",
  ]);
  const withSeparator = isViewOneOf([
    "ObectRef",
    "FileList",
    "LinkStd",
    "PersonCellInfo",
    "AttrLinked",
    "ChildEntitiesCellInfo",
  ]);

  const getAllowedInitialComponent = () =>
    viewsByType[typeName as AttrTypeName]?.[0];

  React.useEffect(() => {
    if (!curView) form.setFieldValue(viewField, getAllowedInitialComponent());
  }, [componentNames]);

  React.useEffect(() => {
    if (curView) {
      const initialViewData = getInitialViewData(curView);
      const initValues = (path: string[], value: unknown) => {
        if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null
        ) {
          Object.keys(value).forEach((key: string) => {
            initValues([...path, key], value[key as keyof object]);
          });
        } else {
          const formValue = form.getFieldValue(path);
          form.setFieldValue(path, formValue || value);
        }
      };
      initValues([edAttrField.viewInfo], initialViewData);
    }
  }, [curView]);

  return (
    <div className={styles.box}>
      <div className={styles.boxTitle}>
        <TableOutlined /> В списке экземпляров (таблица)
      </div>
      <div className={styles.fields}>
        {getAllowedViewStyleItems(curView, stylesField)}
        <Form.Item name={viewField} label="Компонент">
          <Select options={componentNames} />
        </Form.Item>
        {withLabelAtts && (
          <Form.Item
            label="Атрибуты для отображения"
            name={compField("labelAtts")}
            rules={[{ required: true }]}
          >
            <SelectAttributeFlat
              loader={loadObjectAttrinbutesAll}
              objectId={refId}
              mode="multiple"
              maxTagCount="responsive"
            />
          </Form.Item>
        )}
        {isPersonInfo && (
          <SelectAttributesRolesList
            rolesIds={roleIds}
            name={compField("rolesViewAtts")}
          />
        )}
        {isTag && (
          <Form.Item
            label="цвет тэга"
            name={compField("tagColor")}
            getValueFromEvent={(e) => `#${e.toHex()}`}
          >
            <ColorPicker format="hex" />
          </Form.Item>
        )}
        {withSeparator && (
          <Separator name={[...appearanceField("view"), "separator"]} />
        )}
        {canOverflow && (
          <Overflow name={[...appearanceField("view"), "overflow"]} />
        )}

        <DictRefViewInfo
          appearanceNameFn={appearanceField}
          stylesNameFn={compField}
          refId={refId}
          typeName={typeName}
          curView={curView}
        />

        <ObjectRefViewInfo curView={curView} stylesNameFn={compField} />

        <PersonCellViewInfo curView={curView} stylesNameFn={compField} />

        <Divider className={styles.divider}>Настройка колонок</Divider>

        <TableSettingsInfo currentObjectId={currentObjectId} />
      </div>
    </div>
  );
};
