import { makeAutoObservable } from "mobx";
import { makeFieldOptions } from "src/common/makeFieldOptions";
import { onError } from "src/common/onError";
import {
  loadObjectAttrinbutesAll,
  loadObjects,
} from "src/pages/ManagementPage/objectsApi";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectItem } from "src/types/ZObjectItem";

export class FormObjectSectionsStore<TObjType extends string> {
  constructor() {
    makeAutoObservable(this);
  }

  async init(attrTypesDict: Record<number, string>) {
    try {
      this.setLoading(true);
      this.setObjects(await loadObjects());
      this.setAttrTypesDict(attrTypesDict);
    } catch (e) {
      onError(e);
    } finally {
      this.setLoading(false);
    }
  }

  loading: boolean = false;

  setLoading(value: boolean) {
    this.loading = value;
  }

  objects: ZObjectItem[] = [];

  setObjects(list: ZObjectItem[]) {
    this.objects = list;
  }

  attrsMap: Partial<Record<TObjType, ZAttribute[]>> = {};

  setAttrs(key: TObjType, list: ZAttribute[]) {
    this.attrsMap[key] = list;
  }

  loadingAttrs = new Set<TObjType>();

  setLoadingAttrs(type: TObjType) {
    this.loadingAttrs.add(type);
  }

  unsetLoadingAttrs(type: TObjType) {
    this.loadingAttrs.delete(type);
  }

  attrTypesDict: Record<number, string> = {};

  setAttrTypesDict(dict: Record<number, string>) {
    this.attrTypesDict = dict;
  }

  makeOptionsByType(typeName: AttrTypeName, attrs: ZAttribute[]) {
    return makeFieldOptions(
      attrs.filter(
        ({ valueType }) => this.attrTypesDict[valueType] === typeName,
      ),
    );
  }

  async loadAttrs(key: TObjType, objId: number) {
    try {
      this.setLoadingAttrs(key);
      this.setAttrs(key, []);
      this.setAttrs(key, await loadObjectAttrinbutesAll(objId));
    } catch (e) {
      onError(e);
    } finally {
      this.unsetLoadingAttrs(key);
    }
  }
}
