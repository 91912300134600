import { FormInstance } from "antd";
import { makeAutoObservable } from "mobx";
import { DebounceCounter, debounce } from "src/common/debounce";
import { delay } from "src/common/delay";
import { onError } from "src/common/onError";

export type FormStatus =
  | "changed"
  | "error"
  | "invalid"
  | "new"
  | "ok"
  | "saving"
  | "validating";

export class ModelessFormStore {
  constructor(
    protected save: (values: unknown) => Promise<void>,
    protected onDataLoss?: () => void,
  ) {
    makeAutoObservable(this);
  }

  /**
   * Попытка обновления данных. Например, если форма связана со списком элементов.
   * @param values
   * @param onSuccess
   */
  safeLoad(
    values: unknown | null,
    onSuccess?: () => void,
    isNewItem?: boolean,
  ) {
    this.safeAction(() => {
      this.unsafeLoad(values, isNewItem);
      onSuccess?.();
    });
  }

  /**
   * Выполнить любое действие, чтобы оно было безопасно с точки зрения редактируемых данных
   * В случае несохранённых данных выводится окно подтверждения или отмены.
   * @param onSuccess
   */
  safeAction(onSuccess: () => void) {
    this.getSmartConfirmation()
      .then(onSuccess)
      .catch(() => delay(0));
  }

  /**
   * Безопасное действие для нескольких форм.
   * Например, страница имеет несколько табов с разными формами.
   * null для упрощения функций, которые собирают список сторов с условиями.
   * @param stores
   * @param onSuccess
   */
  static safeMultiAction(
    stores: (ModelessFormStore | null)[],
    onSuccess: (store?: ModelessFormStore) => void,
  ) {
    const unsaved = stores.find((store) => store?.isFormUnsaved);
    if (unsaved) {
      unsaved.safeAction(() => {
        onSuccess(unsaved);
      });
    } else {
      onSuccess();
    }
  }

  // --- internal members

  unsafeLoad(values: unknown | null, isNewItem?: boolean) {
    this.setInitialValues(values);
    this.initForm(isNewItem ? "new" : "ok");
  }

  initForm(newStatus?: FormStatus) {
    this.setFormStatus(newStatus ?? "ok");
    this.setFormWaitToSave(false);
    this.setFormError(null);
  }

  // получить задачу для подтверждения
  getSmartConfirmation(): Promise<void> {
    if (!this.isFormUnsaved) {
      return Promise.resolve();
    }
    if (this.confirmTask) return this.confirmTask;
    const task = new Promise<void>((resolve, reject) => {
      if (this.useExternalConfirm) {
        this.setConfirmResolver({ resolve, reject });
      }
      // Дефолтный вариант. На случай отсутствия компонента <ConfirmationTask />
      else if (
        // eslint-disable-next-line no-alert
        window.confirm(
          "Форма содержит несохранённые данные. Продолжить с потерей данных?",
        )
      ) {
        resolve();
      } else {
        reject();
      }
    })
      .then(() => {
        // Если происходит потеря данных, то нужно обновить форму
        this.initForm();
        this.setInitialValues(this.lastValidValues);
        this.onDataLoss?.();
      })
      .finally(() => {
        this.setConfirmResolver(null);
        this.setConfirmTask(null);
      });
    this.setConfirmTask(task);
    return task;
  }

  // Задача создаётся в случае необходимости перейти на редактирование другого элемента или на другую страницу.
  // На эту задачу подписывается код перехода. Он срабатывает в случае resolve промиса.
  confirmTask: Promise<void> | null = null;

  setConfirmTask(task: Promise<void> | null) {
    this.confirmTask = task;
  }

  // Обработчики подтверждения используются внешним компонентом, который выводит окно подтверждения потери данных.
  confirmResolver: PromiseHandlers | null = null;

  setConfirmResolver(resolver: PromiseHandlers | null) {
    this.confirmResolver = resolver;
  }

  useExternalConfirm = false;

  setUseExternalConfirm(flag: boolean) {
    this.useExternalConfirm = flag;
  }

  // Данные, поступившие при инициализации.
  // Отличаются от текущих данных, которые находятся в экземпляре формы.
  // Если использовать его как пропс формы initialValues, то ресет формы будет восстанавливать эти значения
  // Если же ресет должен очищать форму, то нужно использовать другие значения для пропса.
  initialValues: unknown | null = null;

  setInitialValues(values: unknown | null) {
    this.initialValues = values;
    this.setLastValidValues(values);
  }

  formStatus: FormStatus = "ok";

  setFormStatus(status: FormStatus) {
    this.formStatus = status;
  }

  get isFormBusy(): boolean {
    return this.formStatus === "validating" || this.formStatus === "saving";
  }

  get isFormUnsaved(): boolean {
    return this.formStatus !== "ok";
  }

  // Признак изменения данных формы во время сохранения (т.е. требуется повтор сохранения)
  formWaitToSave = false;

  setFormWaitToSave(flag: boolean) {
    this.formWaitToSave = flag;
  }

  formError: Error | null = null;

  setFormError(e: Error | null) {
    this.formError = e;
  }

  saveCounter: DebounceCounter = {};

  tryToSave(sourceValues: unknown, formInstance: FormInstance) {
    // Если форма занята, то установить флаг
    if (this.isFormBusy) {
      this.setFormWaitToSave(true);
      return;
    }
    const saving = async (valuesToSave: unknown) => {
      try {
        this.setFormError(null);
        this.setFormStatus("validating");
        await formInstance.validateFields();
        this.setFormStatus("saving");
        await this.save(valuesToSave);
        this.setFormStatus("ok");
        this.setLastValidValues(valuesToSave);
        if (this.formWaitToSave) {
          this.setFormWaitToSave(false);
          const actualValues = formInstance.getFieldsValue();
          saving(actualValues); // без await, т.к тут уже ждать не нужно
        }
      } catch (e) {
        this.setFormStatus(
          this.formStatus === "validating" ? "invalid" : "error",
        );
        this.setFormError(e);
      }
    };
    debounce(this.saveCounter, 400, () => {
      saving(sourceValues);
    });
  }

  submit(values: unknown) {
    this.setFormError(null);
    this.setFormStatus("saving");
    this.save(values)
      .then(() => {
        this.setFormStatus("ok");
      })
      .catch((e) => {
        onError(e);
        this.setFormStatus("error");
        // Сейчас сообщение об ошибке выдаётся через onError. Но если надо, его можно будет показать из formError
        this.setFormError(e);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  get canSubmit(): boolean {
    // return ModelessFormStore.submitStatuses.has(this.formStatus);
    return true;
  }

  // changed - штатная ситуация. Если внесены изменения, значит можно сохранять (если не пройдет валидация, то сохранения всё равно не будет)
  // new - теоретически новый объект можно сохранить сразу, если он заполнен подходящими значениями
  // error - Если сохранение завершилось с ошибкой, то пользователь должен иметь возможность повторно сохранить.
  // saving - в этом случае рисуется крутилка, что само по себе уже запрещает повторное нажатие сабмита. Зато кнопка не серая
  static submitStatuses = new Set<FormStatus>([
    "changed",
    "new",
    "error",
    "saving",
  ]);

  lastValidValues: unknown = null;

  setLastValidValues(values: unknown) {
    this.lastValidValues = values;
  }
}

interface PromiseHandlers {
  resolve(): void;
  reject(): void;
}
