// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Dict2Tab-AttrFields2-AttrFields2-module__twoCols--m5mZB {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}
.src-pages-ManagementPage-Dict2Tab-AttrFields2-AttrFields2-module__vert--eaYDa {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Dict2Tab/AttrFields2/AttrFields2.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ","sourcesContent":[".twoCols {\n    display: grid;\n    grid-template-columns: 2fr 1fr;\n    gap: 24px;\n}\n.vert {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"twoCols": `src-pages-ManagementPage-Dict2Tab-AttrFields2-AttrFields2-module__twoCols--m5mZB`,
	"vert": `src-pages-ManagementPage-Dict2Tab-AttrFields2-AttrFields2-module__vert--eaYDa`
};
export default ___CSS_LOADER_EXPORT___;
