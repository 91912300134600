import { ZPermissions, ZRolesGroup, ZTypeActions } from "./roleTypes";

export const createPermissions = (
  columns: ZTypeActions[],
  rows: ZRolesGroup[],
): ZPermissions =>
  rows.map((group) => ({
    groupId: group.groupId,
    roles: group.roles.map(({ roleId }) => ({
      roleId,
      actionIds: columns.flatMap(({ actions }) => actions).map(({ id }) => id),
    })),
  }));
