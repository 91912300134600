import { makeDictionary } from "src/common/makeDictionary";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import { AttrLabelTreeData, defAttrLabelTreeData } from "../AttrLabelTreeData";

const requestCache: Record<string, Promise<ZAttribute[]>> = {};

export const loadEntityAttsForShow = async (
  entity: ZEntity,
  attsIdsForShow: string[],
): Promise<AttrLabelTreeData[]> => {
  if (!requestCache[entity.objectId]) {
    requestCache[entity.objectId] = loadObjectAttrinbutesAll(
      String(entity.objectId),
    );
  }
  const objectAttsMap = makeDictionary(
    (await requestCache[entity.objectId]) || [],
    ({ id }) => id,
  );
  const entityAttsValsMap = entity.attributeValues.reduce(
    (acc, curr) => {
      acc[curr.attributeId] = curr.values || [];
      return acc;
    },
    {} as Record<number, string[]>,
  );
  const attsForShowData = attsIdsForShow.map((id): AttrLabelTreeData | null => {
    const attrObj = objectAttsMap[Number(id)];
    const valsEnt = entityAttsValsMap[Number(id)];
    const isDataConsistent = attrObj && valsEnt;
    return isDataConsistent
      ? defAttrLabelTreeData(valsEnt, attrObj, undefined, false)
      : null;
  });

  return attsForShowData.filter(Boolean) as AttrLabelTreeData[];
};
