import * as React from "react";
import i18 from "i18next";
import { Select } from "antd";
import { langList } from "src/lang/langList";
import { getGlobalParams } from "src/common/globalParams";
import { LogoIcon } from "src/components/MainFrame/LeftMenu/icons";
import styles from "./CommonOverlay.module.less";
import { makeRef } from "../makeRef";

interface PropsCommonOverlay {
  children?: React.ReactNode;
  title?: string;
}

export const CommonOverlay: React.FC<PropsCommonOverlay> = ({
  children,
  title,
}) => (
  <div className={styles.loginPage}>
    <div className={styles.formContainer}>
      <div className={styles.langDiv}>
        <Select
          className={styles.langSelect}
          defaultValue={i18.language}
          options={langList}
          onChange={(langOption) => {
            window.location.href = makeRef(window.location.href, langOption);
          }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <LogoIcon />
          <span className={styles.logoText}>{getGlobalParams().title}</span>
        </div>
        <div className={styles.title}>{title}</div>
        {children}
      </div>
    </div>
    <div className={styles.loginCopyrightLabel}>
      {getGlobalParams()?.copyright || ""}
    </div>
  </div>
);

CommonOverlay.defaultProps = {
  children: undefined,
  title: undefined,
};
