import * as React from "react";
import { PageLayout } from "src/components/PageLayout";
import { t } from "i18next";
import { LogoIcon } from "src/components/MainFrame/LeftMenu/icons";
import styles from "./HomePage.module.less";
import homeBackground from "./homeBackground.svg";

export const HomePage: React.FC = () => (
  <PageLayout pageTitle={t("Main title")}>
    <div className={styles.box}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <LogoIcon /> omnidata.plm
        </div>
        <div className={styles.title}>
          {t("Welcome to the Omnidata platform!")}
        </div>
        <div className={styles.descr}>
          {t("Select the desired menu item to start operation")}
        </div>
      </div>
      <div className={styles.background}>
        <img src={homeBackground} alt="" />
      </div>
    </div>
  </PageLayout>
);
