// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ModelessForm-ModelessForm-module__status--yZJ8B {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.src-components-ModelessForm-ModelessForm-module__formContent--YOqGt {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModelessForm/ModelessForm.module.less"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ","sourcesContent":[".status {\n    flex: 1;\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    align-items: center;\n}\n.formContent {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `src-components-ModelessForm-ModelessForm-module__status--yZJ8B`,
	"formContent": `src-components-ModelessForm-ModelessForm-module__formContent--YOqGt`
};
export default ___CSS_LOADER_EXPORT___;
