import React from "react";
import { AttrViewCompName } from "../zAttrViewInfo";
import { FontWeight } from "./FontWeight";
import { TextAlign } from "./TextAlign";
import { viewStylesByType } from "../viewStylesByType";
import { AttrViewStylesNames } from "../ZAttrViewStyles";

const dict: Record<AttrViewStylesNames, (name: string[]) => React.ReactNode> = {
  fontWeight: (name) => <FontWeight key="FontWeight" name={name} />,
  textAlign: (name) => <TextAlign key="TextAlign" name={name} />,
};

export const getAllowedViewStyleItems = (
  viewName: AttrViewCompName | undefined,
  itemNameFn: (name: string) => string[],
) => {
  if (!viewName) return null;
  const cmpNames = viewStylesByType[viewName];
  return cmpNames.map((name) => dict[name](itemNameFn(name)));
};
