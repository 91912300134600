import { RCFormData } from "./ChatFormStore";

export const splitMessageIntoChunks = (
  data: RCFormData,
  chunkSize: number,
): RCFormData[] => {
  const { description, file, message } = data;
  const safeMsg = message || "";
  const chunks: RCFormData[] = [];
  for (let i = 0; i < (safeMsg.length || 1); i += chunkSize) {
    chunks.push({
      message: safeMsg.slice(i, i + chunkSize),
    });
  }
  const last = chunks[chunks.length - 1];
  chunks[chunks.length - 1] = {
    message: last?.message || "",
    description,
    file,
  };
  return chunks;
};
