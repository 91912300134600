import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, InputRef, Modal, Segmented, Space } from "antd";
import { ModelEditorStore } from "../../ModelEditorStore";

interface PropsModalNewAttr {
  store: ModelEditorStore;
}

const attrTypes = [
  { label: "Прямая", value: "line" },
  { label: "Кривая", value: "curve" },
];

export const ModalNewAttr: React.FC<PropsModalNewAttr> = observer(
  ({ store }: PropsModalNewAttr) => {
    const onCancel = () => {
      store.setState("view");
      store.setPoint1(null);
      store.setPoint2(null);
    };
    const open = store.state === "newAttr";
    const ref1 = React.useRef<InputRef>(null);
    React.useEffect(() => {
      if (open) setTimeout(() => ref1.current?.focus(), 20);
    }, [open]);
    return (
      <Modal
        open={open}
        title="Создание нового атрибута"
        footer={null}
        destroyOnClose
        onCancel={onCancel}
      >
        <Form
          layout="vertical"
          initialValues={{ name: "", type: "line" }}
          onFinish={({ attrName, type }) => {
            store.createNewAttr(attrName, type === "curve");
          }}
        >
          <Form.Item
            name="attrName"
            label="Название"
            rules={[{ required: true }]}
          >
            <Input ref={ref1} allowClear />
          </Form.Item>
          <Form.Item name="type" label="Тип">
            <Segmented options={attrTypes} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Создать
              </Button>
              <Button htmlType="button" onClick={onCancel}>
                Отменить
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
