// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__msgGroup--VefXM {
  display: flex;
  column-gap: 8px;
  max-width: 100%;
}
.src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__msgGroupContent--kvWQa {
  padding-right: 10px;
}
.src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__msgGroupMsgList--kmExX {
  display: flex;
  row-gap: 10px;
  max-width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  margin-bottom: 30px;
}
.src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__userName--mBTmN {
  padding-left: 10px;
}
.src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__reply--lcWG0 {
  margin-left: -6px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsChat/ChatForm/ChatMsgList/ChatMsgGroup/ChatMsgGroup.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,eAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,aAAA;EACA,aAAA;EACA,eAAA;EACA,qBAAA;EACA,qBAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAIA;EACE,kBAAA;AAFF;AAKA;EACE,iBAAA;AAHF","sourcesContent":[".msgGroup {\n  display: flex;\n  column-gap: 8px;\n  max-width: 100%;\n}\n\n.msgGroupContent {\n  padding-right: 10px;\n}\n\n.msgGroupMsgList {\n  display: flex;\n  row-gap: 10px;\n  max-width: 100%;\n  word-wrap: break-word;\n  white-space: pre-wrap;\n  overflow-wrap: anywhere;\n  margin-bottom: 30px;\n}\n\n.userName {\n  padding-left: 10px;\n}\n\n.reply {\n  margin-left: -6px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msgGroup": `src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__msgGroup--VefXM`,
	"msgGroupContent": `src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__msgGroupContent--kvWQa`,
	"msgGroupMsgList": `src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__msgGroupMsgList--kmExX`,
	"userName": `src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__userName--mBTmN`,
	"reply": `src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsgGroup-module__reply--lcWG0`
};
export default ___CSS_LOADER_EXPORT___;
