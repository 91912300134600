import { ZAttrEditInfo, zAttrEditInfo } from "./ZAttrEditInfo";

export const getEditorInfo = (
  viewStyles: string | null | undefined,
): ZAttrEditInfo | undefined => {
  try {
    if (!viewStyles) return undefined;
    const json = JSON.parse(viewStyles);
    return zAttrEditInfo.parse(json);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return undefined;
  }
};
