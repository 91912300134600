/* eslint-disable react/require-default-props */
import * as React from "react";
import { Button, ButtonProps, Flex } from "antd";
import { t } from "i18next";

export interface PropsConfirmationActionsPlm {
  okText?: React.ReactNode;
  okButtonProps?: ButtonProps;
  cancelText?: React.ReactNode;
  cancelButtonProps?: ButtonProps;
}

export const ConfirmationActionsPlm: React.FC<PropsConfirmationActionsPlm> = (
  props,
) => {
  const {
    okText = t("OK"),
    cancelText = t("Cancel"),
    okButtonProps = {},
    cancelButtonProps = {},
  } = props;

  return (
    <Flex gap={8}>
      <Button {...cancelButtonProps}>{cancelText}</Button>
      <Button type="primary" {...okButtonProps}>
        {okText}
      </Button>
    </Flex>
  );
};
