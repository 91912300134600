import * as React from "react";
import { Alert, Form, Modal } from "antd";
import { createItem2 } from "src/pages/EntityCardPage/blockBuilder/createItem2";
import {
  ContextCommon,
  FormBlockItem,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { FormInstance } from "rc-field-form";
import { ZAttribute } from "src/types/ZAttribute";
import { DrawItems } from "src/components/DrawItems";
import { edit2attr } from "../../../EdAttribute";

interface PropsComponentPreview {
  open: boolean;
  close(): void;
  attrForm: FormInstance;
  typesMap: Record<number, string>;
}

export const ComponentPreview: React.FC<PropsComponentPreview> = (props) => {
  const { open, close, typesMap, attrForm } = props;
  const [store] = React.useState(new FormWithBlockStore());
  const ctx: ContextCommon = React.useMemo(
    () => ({ store, prevName: [] }),
    [store],
  );
  const formItem: FormBlockItem | null = React.useMemo(() => {
    try {
      const values = attrForm.getFieldsValue({ strict: false });
      const { attr: partAttr } = edit2attr(values);
      const attr: ZAttribute = {
        id: 1,
        name: "Атрибут",
        valueType: 1,
        ...partAttr,
      };
      return createItem2(attr, { typesMap, canUpdate: true });
    } catch (e) {
      return null;
    }
  }, [open]);
  return (
    <Modal
      open={open}
      onCancel={() => close()}
      title="Предварительный просмотр элемента"
      footer={null}
    >
      <Form layout="vertical">
        {formItem ? (
          <DrawItems items={[formItem]} ctx={ctx} />
        ) : (
          <Alert message="Не получилось сгенерировать просмотр" />
        )}
      </Form>
    </Modal>
  );
};
