// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__box--vp03C {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  background: white;
  box-shadow: 0px 2px 10px rgba(43, 43, 43, 0.11);
  border-radius: 4px;
}
.src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__box--vp03C h3.src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__title--qZBpb {
  padding: 0 24px;
}
.src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__itemInfo--w6siC {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  padding: 8px 24px;
}
.src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__itemInfo--w6siC:hover {
  background: #F0F0F0;
}
.src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__itemInfo--w6siC:nth-child(2) {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/FiltersForm/FilterSettings/FilterSettingsButton.module.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;EACA,+CAAA;EACA,kBAAA;AADJ;AANA;EAUQ,eAAA;AADR;AAKA;EACI,aAAA;EACA,WAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,iBAAA;AAHJ;AAII;EACE,mBAAA;AAFN;AAII;EAAgB,OAAA;AADpB","sourcesContent":["// возможно, имеет смысл объединиться с ColumnsSettings через создание общего меню-компонента\n// с использованием DropdownBox\n.box {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 16px 0;\n    background: white;\n    box-shadow: 0px 2px 10px rgba(43, 43, 43, 0.11);\n    border-radius: 4px;\n\n    h3.title {\n        padding: 0 24px;\n    }    \n}\n  \n.itemInfo {\n    display: flex;\n    width: 100%;\n    flex-direction: row;\n    gap: 8px;\n    cursor: pointer;\n    padding: 8px 24px;\n    &:hover {\n      background: #F0F0F0;\n    }\n    &:nth-child(2) {flex: 1}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__box--vp03C`,
	"title": `src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__title--qZBpb`,
	"itemInfo": `src-components-tables-FiltersForm-FilterSettings-FilterSettingsButton-module__itemInfo--w6siC`
};
export default ___CSS_LOADER_EXPORT___;
