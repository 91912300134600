// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ModelConstructorPage-ModelsList-ModelsList-module__common--Kvirv {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0.5em;
  width: 15vw;
  min-width: 200px;
  height: 100%;
  min-height: 100px;
}
.src-pages-ModelConstructorPage-ModelsList-ModelsList-module__tools--_HRsD {
  background-color: #AAA;
  padding: 0.2em;
  display: flex;
  flex-direction: row;
  gap: 0.2em;
}
.src-pages-ModelConstructorPage-ModelsList-ModelsList-module__list--scYAG {
  flex: 1;
}
.src-pages-ModelConstructorPage-ModelsList-ModelsList-module__list--scYAG a {
  display: block;
  padding: 0.2em 0.6em;
}
.src-pages-ModelConstructorPage-ModelsList-ModelsList-module__active--YBB9f {
  background: blue;
  color: white;
}
.src-pages-ModelConstructorPage-ModelsList-ModelsList-module__noData--WK0eu {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ModelConstructorPage/ModelsList/ModelsList.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;EAEA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AAAF;AAEA;EACE,sBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;AAAF;AAEA;EACE,OAAA;AAAF;AADA;EAGI,cAAA;EACA,oBAAA;AACJ;AAEA;EACE,gBAAA;EACA,YAAA;AAAF;AAEA;EACE,OAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AAAF","sourcesContent":[".common {\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n  padding: 0.5em;\n\n  width: 15vw;\n  min-width: 200px;\n  height: 100%;\n  min-height: 100px;\n}\n.tools {\n  background-color: #AAA;\n  padding: 0.2em;\n  display: flex;\n  flex-direction: row;\n  gap: 0.2em;\n}\n.list {\n  flex: 1;\n  a {\n    display: block;\n    padding: 0.2em 0.6em;\n  }\n}\n.active {\n  background: blue;\n  color: white;\n}\n.noData {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  align-items: center;\n  justify-content: center;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": `src-pages-ModelConstructorPage-ModelsList-ModelsList-module__common--Kvirv`,
	"tools": `src-pages-ModelConstructorPage-ModelsList-ModelsList-module__tools--_HRsD`,
	"list": `src-pages-ModelConstructorPage-ModelsList-ModelsList-module__list--scYAG`,
	"active": `src-pages-ModelConstructorPage-ModelsList-ModelsList-module__active--YBB9f`,
	"noData": `src-pages-ModelConstructorPage-ModelsList-ModelsList-module__noData--WK0eu`
};
export default ___CSS_LOADER_EXPORT___;
