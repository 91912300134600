import { z } from "zod";

export const zAttributeSum = z.object({
  attributeId: z.number(),
  sum: z.number(),
});
export type ZAttributeSum = z.infer<typeof zAttributeSum>;

export const enum ChildEntsActionType {
  create = "create",
  changeState = "changeState",
  copyIn = "copyIn",
  copyFrom = "copyFrom",
  select = "select",
  rowClick = "rowClick",
}
