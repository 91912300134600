import * as React from "react";
import { observer } from "mobx-react-lite";
import { MChartEntityStore } from "../../MChartEntityStore";
import { McImageInternal } from "./McImageInternal";
import styles from "./McImageView.module.less";

interface PropsMcImageView {
  store: MChartEntityStore;
}

export const McImageView: React.FC<PropsMcImageView> = observer(({ store }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const onDragStart: React.DragEventHandler = (e) => {
    store.setImgX0(e.clientX + (ref.current?.clientWidth ?? 0));
  };
  const onDrag: React.DragEventHandler = (e) => {
    store.setImgWidth(store.imgX0 - e.clientX);
  };
  return (
    <div
      ref={ref}
      className={styles.imgViewBox}
      style={{ width: store.imgWidth }}
    >
      <div
        className={styles.drag}
        draggable
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDrag}
      >
        <div className={styles.dragCenter} />
      </div>
      <div className={styles.body}>
        <McImageInternal store={store} />
      </div>
    </div>
  );
});
