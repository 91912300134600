import * as React from "react";
import { t } from "i18next";
import { Dayjs } from "dayjs";
import { DatePicker } from "antd";
import { PickerDateProps } from "antd/es/date-picker/generatePicker";
import { date2Ed, ed2Date, ed2UtcDate, utcDate2Ed } from "src/common/formats";

export type PropsEntityDatePicker = Omit<
  PickerDateProps<Dayjs>,
  "onChange" | "value"
> & {
  value?: string[] | null;
  onChange?(newValue?: string[] | null): void;
};

export const EntityDatePicker: React.FC<PropsEntityDatePicker> = (props) => {
  const { value, onChange, placeholder, ...restProps } = props;
  const { showTime } = restProps;
  // Формат не соответствует ISO по решению бэка
  const srcFormat = `YYYY-MM-DD${showTime ? " HH:mm:ss" : ""}`;
  const dstFormat = showTime
    ? t("DateTimeFormat") || "DD.MM.YYYY"
    : t("DateFormat") || "DD.MM.YYYY HH:mm";
  const dateValue = value?.[0];
  const formattedValue = showTime
    ? utcDate2Ed(dateValue, srcFormat)
    : date2Ed(dateValue, srcFormat);
  const handleChange = (dt: Dayjs | null) =>
    onChange?.(showTime ? ed2UtcDate(dt, srcFormat) : ed2Date(dt, srcFormat));
  return (
    <DatePicker
      allowClear
      style={{ width: "100%" }}
      format={dstFormat}
      placeholder={placeholder ?? ""}
      {...restProps}
      value={formattedValue}
      onChange={handleChange}
    />
  );
};

EntityDatePicker.defaultProps = {
  value: undefined,
  onChange: undefined,
};
