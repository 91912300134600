import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import * as React from "react";
import { ifDef } from "src/common/ifDef";

type PropsEntityTextArea = Omit<TextAreaProps, "onChange" | "value"> & {
  value?: string[] | null;
  onChange?(newValue: string[] | null | undefined): void;
};

export const EntityTextArea: React.FC<PropsEntityTextArea> = (props) => {
  const { value, onChange, ...restProps } = props;
  return (
    <Input.TextArea
      {...restProps}
      value={value?.[0]}
      onChange={(e) => onChange?.(ifDef(e.currentTarget.value, (it) => [it]))}
    />
  );
};

EntityTextArea.defaultProps = {
  value: undefined,
  onChange: undefined,
};
