/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ModelsIconSvg: React.FC<Partial<CustomIconComponentProps>> = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="20"
    height="20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4061 0.0861884C10.1476 -0.0287295 9.85243 -0.0287295 9.59386 0.0861884L0.593862 4.08619C0.232734 4.24669 0 4.60481 0 5C0 5.39519 0.232734 5.75331 0.593862 5.91381L9.59386 9.91381C9.85243 10.0287 10.1476 10.0287 10.4061 9.91381L19.4061 5.91381C19.7673 5.75331 20 5.39519 20 5C20 4.60481 19.7673 4.24669 19.4061 4.08619L10.4061 0.0861884ZM2.63165 8.18051C2.89021 8.06559 3.18536 8.06559 3.44392 8.18051L9.59386 10.9138C9.85243 11.0287 10.1476 11.0287 10.4061 10.9138L16.5561 8.18051C16.8146 8.06559 17.1098 8.06559 17.3684 8.18051L19.4061 9.08619C19.7673 9.24669 20 9.60481 20 10C20 10.3952 19.7673 10.7533 19.4061 10.9138L10.4061 14.9138C10.1476 15.0287 9.85243 15.0287 9.59386 14.9138L0.593862 10.9138C0.232734 10.7533 0 10.3952 0 10C0 9.60481 0.232734 9.24669 0.593862 9.08619L2.63165 8.18051ZM9.59386 15.9138L3.44392 13.1805C3.18536 13.0656 2.89021 13.0656 2.63165 13.1805L0.593862 14.0862C0.232734 14.2467 0 14.6048 0 15C0 15.3952 0.232734 15.7533 0.593862 15.9138L9.59386 19.9138C9.85243 20.0287 10.1476 20.0287 10.4061 19.9138L19.4061 15.9138C19.7673 15.7533 20 15.3952 20 15C20 14.6048 19.7673 14.2467 19.4061 14.0862L17.3684 13.1805C17.1098 13.0656 16.8146 13.0656 16.5561 13.1805L10.4061 15.9138C10.1476 16.0287 9.85243 16.0287 9.59386 15.9138Z"
    />
  </svg>
);

export const ModelsIcon: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={ModelsIconSvg} {...props} />;
