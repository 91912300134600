import * as React from "react";
import { Modal } from "antd";
import { TableStore } from "src/components/tables/TableStore";
import { singleLoader } from "src/components/tables/singleLoader";
import { AColumn } from "src/components/tables/AsyncTable";
import { TableFacade } from "src/components/tables/TableFacade";
import { ZOption } from "src/types/ZOption";
import { getFlatRefOptions, RefName } from "src/common/references/flatRefs";

export const createDictViewStore = (
  dictCode: RefName,
): TableStore<ZOption, {}> =>
  new TableStore<ZOption, {}>({
    rowKey: "value",
    fnLoad: singleLoader(
      async () => {
        const resp = await getFlatRefOptions(dictCode);
        return resp;
      },
      () => true,
    ),
  });

interface PropsDictionaryViewModal {
  dictCode: string;
  dictRef: ZOption[];
  store: TableStore<ZOption, {}>;
  close(): void;
}

const columns: AColumn<ZOption>[] = [
  {
    title: "Наименование",
    key: "label",
    dataIndex: "label",
    width: "70%",
  },
  {
    title: "Код",
    key: "key",
    dataIndex: "key",
    width: "30%",
  },
];

export const DictionaryViewModal: React.FC<PropsDictionaryViewModal> = (
  props: PropsDictionaryViewModal,
) => {
  const { store, close, dictCode, dictRef } = props;
  // const title = dictRef.find(({ key }) => key === dictCode)?.label ?? dictCode;
  const title =
    dictRef.find(({ value }) => value === dictCode)?.label ?? dictCode;
  return (
    <Modal
      open={!!store}
      onCancel={close}
      title={title}
      width={900}
      footer={null}
      centered
    >
      <div style={{ maxHeight: "70vh", overflow: "auto" }}>
        {!!store && (
          <TableFacade store={store} columns={columns} size="small" />
        )}
      </div>
    </Modal>
  );
};
