import React from "react";
import { AColumn } from "src/components/tables/AsyncTable";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { TableStore } from "src/components/tables/TableStore";
import { SettingOutlined, StarOutlined } from "@ant-design/icons";
import styles from "./ATVRowGrid.module.less";
import { TableSelectionCheckbox } from "../../AsyncTableView/tableSelection/TableSelectionCheckbox";
import { drawColumn } from "../../AsyncTableView/templates/drawColumn";

export type PropsATVRowGrid<TRow extends {}, TFilters extends {}> = {
  store: TableStore<TRow, TFilters>;
  columns: AColumn<TRow>[];
  row: TRow;
  rowKey: keyof TRow;
  onClick?: (row: TRow) => void;
};

export const ATVRowGrid = observer(
  <TRow extends {}, TFilters extends {}>({
    store,
    columns,
    row,
    rowKey,
    onClick,
  }: PropsATVRowGrid<TRow, TFilters>) => {
    const keyValue = row[rowKey];

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className={classNames([styles.item, [onClick, styles.clickable]])}
        key={String(rowKey)}
        onClick={() => {
          onClick?.(row);
        }}
      >
        <div className={styles.header}>
          <div
            className={classNames([styles.headerItem, styles.headerItemTitle])}
          >
            <TableSelectionCheckbox store={store} row={row} />
            {String(keyValue)}
          </div>
          <div className={styles.headerItem}>
            <StarOutlined />
          </div>
          <div className={styles.headerItem}>
            <SettingOutlined />
          </div>
        </div>
        {columns.map((column, colIndex) => drawColumn(row, column, colIndex))}
      </div>
    );
  },
);
