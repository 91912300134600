import * as React from "react";
import { observer } from "mobx-react-lite";
import { Empty } from "antd";
import { classNames } from "src/common/classNames";
import { DashboardTabStore } from "../DashboardTabStore";
import styles from "./DashboardsList.module.less";
import dasboardIcon from "./dashboardIcon.svg";

interface PropsDashboardsList {
  store: DashboardTabStore;
}

export const DashboardsList: React.FC<PropsDashboardsList> = observer(
  ({ store }) => {
    const { groups, curGroupKey } = store;
    if (groups.length === 0) {
      return <Empty />;
    }
    return (
      <div className={styles.listBox}>
        {groups.map(({ groupKey, name }) => (
          <button
            key={groupKey}
            type="button"
            className={classNames([
              styles.item,
              [groupKey === curGroupKey, styles.curItem],
            ])}
            onClick={() => store.safeSelect(groupKey)}
          >
            <img src={dasboardIcon} alt="" />
            <span className={styles.text}>{name || <i>Новая группа</i>}</span>
          </button>
        ))}
      </div>
    );
  },
);
