import { ZDictRef } from "../componentsDefs";
import { ZAttrComponentView, ZAttrViewInfo } from "../zAttrViewInfo";

const appearanceInitial: ZAttrViewInfo["appearance"] = {
  column: { group: { separator: "newLine" } },
  view: { overflow: "elipsis", separator: "newLine" },
};
const stylesInitial: ZAttrViewInfo["styles"] = {
  fontWeight: "400",
  textAlign: "left",
};

const textAppearanceStylesInintial = {
  appearance: appearanceInitial,
  styles: stylesInitial,
};

export const DictRefInitialComponent: Partial<ZDictRef> = { mode: "standart" };

const viewInitialData: Partial<
  Record<ZAttrComponentView["view"], Omit<ZAttrViewInfo, "ver">>
> = {
  DictRef: {
    component: DictRefInitialComponent as ZDictRef,
    ...textAppearanceStylesInintial,
  },
  ImgList: {
    appearance: appearanceInitial,
  },
};

export const getInitialViewData = (name: ZAttrComponentView["view"]) =>
  viewInitialData[name] || textAppearanceStylesInintial;
