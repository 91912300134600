import * as React from "react";
import { observer } from "mobx-react-lite";
import { Select } from "antd";
import { FilterFieldsDict } from "src/components/tables/FiltersForm";
import { viewDateTimeUtc } from "src/common/formats";
import { EditFilled } from "@ant-design/icons";
import { t } from "i18next";
import {
  AuditEntityStateFilters,
  ZAuditEntityState,
} from "./EntityStatesHistory.types";
import { TimelineFacade } from "../TimelineFacade/TimelineFacade";
import { EntityStatesHistoryStore } from "./EntityStatesHistoryStore";
import { RangePickerString } from "../RangePickerString";
import styles from "./EntityStatesHistory.module.less";

interface PropsEntityStatesHistory {
  store: EntityStatesHistoryStore;
}

export const EntityStatesHistory: React.FC<PropsEntityStatesHistory> = observer(
  ({ store }) => {
    React.useEffect(() => {
      store.init(store.entityId);
    }, []);

    const renderRow = (row: ZAuditEntityState): React.ReactNode => (
      <div className={styles.timelineChild}>
        <div className={styles.state}>
          {store.stateNames[row.stateId] ?? t("The state has been deleted")}
        </div>
        <div className={styles.changeInfo}>
          <span className={styles.timestamp}>
            {viewDateTimeUtc(row.timestamp)}
          </span>
          <span className={styles.fio}>
            <EditFilled />
            {row.fio || "N/A"}
          </span>
        </div>
      </div>
    );

    const filterItems: FilterFieldsDict<AuditEntityStateFilters> = {
      timestamp: {
        render: () => <RangePickerString />,
        itemProps: { label: t("Show for the period") },
        defaultValue: null,
      },
      userId: {
        render: () => (
          <Select
            allowClear
            placeholder={t("Select")}
            options={store.userFilterOptions ?? []}
          />
        ),
        itemProps: { label: t("User") },
        defaultValue: null,
      },
      stateId: {
        render: () => (
          <Select
            allowClear
            options={store.stateFilterOptions}
            placeholder={t("Select")}
          />
        ),
        itemProps: { label: t("State") },
        defaultValue: null,
      },
    };

    return (
      <TimelineFacade
        store={store.tableStore}
        renderRow={renderRow}
        filterItems={filterItems}
        title={t("ChangingTheStateOfAnInstance", { count: 1 })}
      />
    );
  },
);
