// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__bomPosBox--RUxEi {
  height: 100%;
  display: grid;
  grid-template-columns: 1px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header header" "nav main";
  column-gap: 8px;
  padding: 0 8px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__bomPosHeader--qhjT8 {
  grid-area: header;
  display: flex;
  padding: 4px 16px;
  gap: 8px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__leftTop--l4Meo {
  flex: 1;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__bomPosNav--eo_gg {
  grid-area: nav;
  overflow: auto;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__bomPosMain--vins1 {
  grid-area: main;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/Bom2Position.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,8BAAA;EACA,4BAAA;EACA,+CAAA;EAGA,eAAA;EACA,cAAA;AADJ;AAGA;EACI,iBAAA;EACA,aAAA;EACA,iBAAA;EACA,QAAA;AADJ;AAGA;EACI,OAAA;AADJ;AAGA;EACI,cAAA;EACA,cAAA;AADJ;AAGA;EACI,eAAA;EACA,cAAA;AADJ","sourcesContent":[".bomPosBox {\n    height: 100%;\n    display: grid;\n    grid-template-columns: 1px 1fr;\n    grid-template-rows: auto 1fr;\n    grid-template-areas: \n        \"header header\"\n        \"nav main\";\n    column-gap: 8px;\n    padding: 0 8px;\n}\n.bomPosHeader {\n    grid-area: header;\n    display: flex;\n    padding: 4px 16px;\n    gap: 8px;\n}\n.leftTop {\n    flex: 1;\n}\n.bomPosNav {\n    grid-area: nav;\n    overflow: auto;\n}\n.bomPosMain {\n    grid-area: main;\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bomPosBox": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__bomPosBox--RUxEi`,
	"bomPosHeader": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__bomPosHeader--qhjT8`,
	"leftTop": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__leftTop--l4Meo`,
	"bomPosNav": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__bomPosNav--eo_gg`,
	"bomPosMain": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Position-module__bomPosMain--vins1`
};
export default ___CSS_LOADER_EXPORT___;
