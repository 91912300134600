// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__container--TH6AX {
  overflow-x: auto;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y {
  border-collapse: collapse;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y td,
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y th {
  border: thin solid silver;
  text-align: center;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y th {
  padding: 8px 16px;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y th.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__groupName--G5bmc {
  text-align: left;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y th.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__roleName--h45ti {
  padding-left: 24px;
  text-align: left;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y td {
  padding: 0;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y .src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__group--cP_Vt,
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y .src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__groupName--G5bmc {
  background-color: #EEE;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y .src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__cell--uwWoU {
  width: 100%;
  height: 32px;
  border: none;
  cursor: pointer;
  background: none;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y .src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__cell--uwWoU:hover {
  background-color: bisque;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/roles/RolesEditor.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,yBAAA;AAAJ;AADA;;EAEY,yBAAA;EAA2B,kBAAA;AAIvC;AANA;EAIQ,iBAAA;AAKR;AAJQ;EACI,gBAAA;AAMZ;AAJQ;EACI,kBAAA;EACA,gBAAA;AAMZ;AAhBA;EAaQ,UAAA;AAMR;AAnBA;;EAeQ,sBAAA;AAQR;AAvBA;EAkBQ,WAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAQR;AAPQ;EACI,wBAAA;AASZ","sourcesContent":[".container {\n    overflow-x: auto;\n}\n\n.table {\n    border-collapse: collapse;\n    td, th {border: thin solid silver; text-align: center;}\n    th { \n        padding: 8px 16px;\n        &.groupName {\n            text-align: left;\n        }\n        &.roleName {\n            padding-left: 24px;\n            text-align: left;\n        }\n    }\n    td {padding: 0}\n    .group, .groupName {\n        background-color: #EEE;\n    }\n    .cell {\n        width: 100%;\n        height: 32px;\n        border: none;\n        cursor: pointer;\n        background: none;\n        color: red;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin: 0;\n        &:hover {\n            background-color: bisque;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__container--TH6AX`,
	"table": `src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__table--kC07Y`,
	"groupName": `src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__groupName--G5bmc`,
	"roleName": `src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__roleName--h45ti`,
	"group": `src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__group--cP_Vt`,
	"cell": `src-pages-ManagementPage-Obj2Tab-roles-RolesEditor-module__cell--uwWoU`
};
export default ___CSS_LOADER_EXPORT___;
