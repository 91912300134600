/**
 * Блок полей редактирования свойств компонента на карточке сущности.
 * Зависит от типа атрибута.
 * У разных типов есть свой набор возможных компонентов.
 */
import * as React from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import { AttrTypeName } from "src/types/AttrType";
import {
  AttrEditorCompName,
  ZAttrItemProps,
  editorsByType,
} from "src/common/attrEdit";
import DisabledContext from "antd/es/config-provider/DisabledContext";
import { ZOption } from "src/types/ZOption";
import { EditOutlined, EyeOutlined, FormOutlined } from "@ant-design/icons";
import { classNames } from "src/common/classNames";
import { FormInstance } from "rc-field-form";
import { getTooltipMode } from "src/components/ExtendedTooltip/getTooltipMode";
import { ZTooltipExtFields } from "src/components/ExtendedTooltip";
import { loadObjectAttrinbutesReduced } from "src/pages/ManagementPage/objectsApi";
import { validateFormula, ZFormulaArg } from "src/common/attrEdit/components";
import { ZAttribute } from "src/types/ZAttribute";
import { Separator } from "src/common/attrView/viewFormItems/Separator";
// import { LinkedAttrValueView } from "src/common/attrView/viewFormItems/LinkedAttrValueView";
import { edAttrField } from "../../../EdAttribute";
import { SelectAttributeFlat } from "../SelectAttributeFlat/SelectAttributeFlat";
import { ComponentPreview } from "./ComponentPreview";
import { RegexpInput } from "./RegexpInput/RegexpInput";
import { validatorRegexpInput } from "./RegexpInput";
import { TooltipExtEdit } from "./TooltipExtEdit/TooltipExtEdit";
import { dateCmpOptions } from "./dateCmpOptions";
import styles from "./EditorInfo.module.less";
import { RelativeCmpEdit } from "./RelativeCmpEdit";
import { SelectAttributesRolesList } from "./ViewInfo/SelectAttributesRolesList";
import { FormulaTemplateInput2 } from "./FormulaTemplateInput/FormulaTemplateInput2";
import { CopyByValueEdit } from "./CopyByValueEdit";
import { loadFormulaArguments } from "./FormulaTemplateInput/loadFormulaArguments";

interface PropsEditorInfo {
  attrTypesDict: Record<number, string>;
  disabled: boolean;
  objectId: number;
  curAttrId: number;
}

const root = edAttrField.editorInfo;
const compField = (name: string) => [root, "component", name];
const itemField = (name: keyof ZAttrItemProps) => [root, "itemProps", name];
const editorField = compField("editor");
const relativeCmpTypes = new Set<string>([
  AttrTypeName.date,
  AttrTypeName.dateTime,
  AttrTypeName.time,
  AttrTypeName.int,
  AttrTypeName.number,
]);
export const copyByValueTypes = new Set<string>([
  AttrTypeName.date,
  AttrTypeName.dateTime,
  AttrTypeName.time,
  AttrTypeName.number,
  AttrTypeName.int,
  AttrTypeName.image,
  AttrTypeName.dictSingle,
  AttrTypeName.externalLink,
  AttrTypeName.string,
  AttrTypeName.file,
  AttrTypeName.usersList,
]);

export const EditorInfo: React.FC<PropsEditorInfo> = ({
  attrTypesDict,
  disabled,
  objectId,
  curAttrId,
}) => {
  const [openPreview, setOpenPreview] = React.useState(false);
  const [tooltipExtOpen, setTooltipExtOpen] = React.useState(false);
  const form = Form.useFormInstance();
  const attrType = Form.useWatch(edAttrField.valueType);
  const multiple = Form.useWatch(compField("multiple"));
  const refId = Form.useWatch(edAttrField.referenceId);
  const roleIds = Form.useWatch(edAttrField.roleIds) || [];
  const tooltipExt = Form.useWatch(itemField("tooltipExt")) ?? {};
  const tooltipText = Form.useWatch(itemField("tooltip"));
  const isIterator = Form.useWatch(edAttrField.iterator);
  const typeName = attrTypesDict[attrType] ?? String(attrType);
  const [argList, setArgList] = React.useState<ZFormulaArg[]>([]);
  const curEditor: AttrEditorCompName | undefined = Form.useWatch(editorField);
  const curEditorIsOneOf = (names: AttrEditorCompName[]) =>
    curEditor === undefined ? false : names.includes(curEditor);
  const componentNames: ZOption[] = React.useMemo(() => {
    const editors: string[] = editorsByType[typeName as AttrTypeName] ?? [];
    if (curEditor && !editors.includes(curEditor)) {
      form.resetFields(editorField);
    }
    return editors.map((value) => ({ value, label: value }));
  }, [attrType]);

  const isMultiple = curEditorIsOneOf([
    "Uploader",
    "PersonSelect",
    "ImageCarousel",
  ]);
  const isMaxCount =
    (curEditor === "Select" && typeName === AttrTypeName.dictMulti) ||
    (curEditor === "Uploader" && multiple) ||
    (curEditor === "ImageCarousel" && multiple);
  const isMaxSize = curEditorIsOneOf(["Uploader", "ImageCarousel"]);
  const isMaxLength = curEditorIsOneOf(["Input", "TextArea"]);
  const isMinMax = curEditorIsOneOf(["InputNumber"]);
  const isPrecision =
    (curEditor === "InputNumber" && typeName === AttrTypeName.number) ||
    curEditor === "Formula";
  const isAccept = curEditorIsOneOf(["Uploader", "ImageCarousel"]);
  const isMaxImageHeight = curEditorIsOneOf(["ImageCarousel"]);
  const isAddon = curEditorIsOneOf([
    "Input",
    "InputNumber",
    "MaskedInput",
    "Formula",
  ]);
  const isObjectRef = curEditorIsOneOf(["ObectRefSelector", "ObjectRefTable"]);
  const isCreateButtonAvailable =
    curEditor === "ObectRefSelector" || curEditor === "ObjectRefTable";
  const isChildEntities = curEditorIsOneOf(["ChildEntities"]);
  const isMask = curEditorIsOneOf(["MaskedInput"]);
  const isMinMaxRows = curEditorIsOneOf(["TextArea"]);
  const isTodayRule =
    typeName === AttrTypeName.date || typeName === AttrTypeName.dateTime;
  const isRelativeCmpRule = relativeCmpTypes.has(typeName);
  const isCopyByValueRule = copyByValueTypes.has(typeName);
  const isPersonInfo = curEditorIsOneOf(["PersonSelect"]);
  const isFormula = curEditorIsOneOf(["Formula"]);
  const isLinkedValue = curEditorIsOneOf(["LinkedValue"]);
  const isEditorRequired =
    typeName === AttrTypeName.dictSingle || typeName === AttrTypeName.dictMulti;

  const init = async () => {
    if (isFormula)
      setArgList((await loadFormulaArguments(objectId)).availableAttributes);
  };
  React.useEffect(() => {
    init();
  }, [curEditor]);
  React.useEffect(() => {
    if (isIterator) form.setFieldValue(compField("multiple"), true);
  }, [isIterator]);

  const isCalculableAttr = (attr: ZAttribute) =>
    attrTypesDict[attr.valueType] === AttrTypeName.int ||
    attrTypesDict[attr.valueType] === AttrTypeName.number ||
    attrTypesDict[attr.valueType] === AttrTypeName.formula;

  return (
    <DisabledContext.Provider value={disabled}>
      <div className={classNames([styles.box, styles.editor])}>
        <div className={styles.boxTitle}>
          <FormOutlined /> На карточке экземпляра (форма)
        </div>
        <div className={styles.fields}>
          <Form.Item
            name={editorField}
            label="Компонент"
            rules={[{ required: isEditorRequired }]}
          >
            <Select allowClear options={componentNames} />
          </Form.Item>
          <Form.Item
            name={itemField("required")}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>Является обязательным для заполнения</Checkbox>
          </Form.Item>

          {isLinkedValue && (
            <>
              {/*  будет в доработке на след. спринт <LinkedAttrValueView name={compField("view")} /> */}
              <Separator name={compField("separator")} />
            </>
          )}
          {isFormula && (
            <Form.Item
              label="Формула"
              name={compField("formula")}
              rules={[validateFormula(objectId, argList)]}
            >
              <FormulaTemplateInput2
                name={compField("formula")}
                objectId={objectId}
              />
            </Form.Item>
          )}

          {isMask && (
            <Form.Item
              name={compField("mask")}
              label="Маска"
              rules={[{ required: true }]}
              tooltip={
                <a
                  href="https://imask.js.org/guide.html#masked-pattern"
                  target="_blank"
                  rel="noreferrer"
                >
                  Подробное описание правил
                </a>
              }
            >
              <Input allowClear />
            </Form.Item>
          )}
          {isMaxLength && (
            <Form.Item
              name={compField("maxLength")}
              label="Максимальное количество символов"
            >
              <InputNumber precision={0} />
            </Form.Item>
          )}
          {isMinMax && (
            <div className={styles.twoCols}>
              <Form.Item name={compField("min")} label="Минимальное значение">
                <InputNumber />
              </Form.Item>
              <Form.Item name={compField("max")} label="Максимальное значение">
                <InputNumber />
              </Form.Item>
            </div>
          )}
          {isMultiple && (
            <Form.Item
              name={compField("multiple")}
              valuePropName="checked"
              noStyle
            >
              {/* Можественный выбор обязателен для итератора */}
              <Checkbox disabled={isIterator}>Множественный выбор</Checkbox>
            </Form.Item>
          )}
          {isCreateButtonAvailable && (
            <Form.Item
              noStyle
              name={compField("isCreateButtonAvailable")}
              valuePropName="checked"
            >
              <Checkbox>Отобразить кнопку Создать</Checkbox>
            </Form.Item>
          )}
          {isMaxCount && (
            <Form.Item
              name={compField("maxCount")}
              label="Максимальное количество выбираемых элементов"
            >
              <InputNumber min={1} precision={0} />
            </Form.Item>
          )}
          {isMaxSize && (
            <Form.Item
              name={compField("maxSize")}
              label="Максимальный размер файлов"
            >
              <InputNumber min={1} precision={0} addonAfter="Мб" />
            </Form.Item>
          )}
          {isPrecision && (
            <Form.Item
              name={compField("precision")}
              label="Число знаков после запятой(точки)"
            >
              <InputNumber min={0} />
            </Form.Item>
          )}
          {isAccept && (
            <Form.Item
              name={compField("accept")}
              label="Фильтр на типы файлов"
              tooltip="Пример: .doc,.docx,application/msword"
            >
              <Input allowClear />
            </Form.Item>
          )}
          {isMaxImageHeight && (
            <Form.Item
              name={compField("imageHeight")}
              label="Высота изображения"
            >
              <InputNumber min={1} addonAfter="px" />
            </Form.Item>
          )}
          {isAddon && (
            <div className={styles.twoCols}>
              <Form.Item name={compField("addonBefore")} label="Префикс">
                <Input allowClear />
              </Form.Item>
              <Form.Item name={compField("addonAfter")} label="Суффикс">
                <Input allowClear />
              </Form.Item>
            </div>
          )}
          {isMinMaxRows && (
            <div className={styles.twoCols}>
              <Form.Item name={compField("minRows")} label="Мин. строк">
                <InputNumber min={1} precision={0} />
              </Form.Item>
              <Form.Item
                name={compField("maxRows")}
                label="Макс. строк"
                dependencies={[compField("minRows")]}
                rules={[
                  (formInst: FormInstance) => ({
                    validator: (_, value) => {
                      const minRows = formInst.getFieldValue(
                        compField("minRows"),
                      );
                      if (minRows && value && minRows > value)
                        return Promise.reject(
                          Error("не может быть меньше Мин. значения"),
                        );
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber min={1} precision={0} />
              </Form.Item>
            </div>
          )}
          {isObjectRef && (
            <>
              <Form.Item
                name={compField("multiple")}
                valuePropName="checked"
                noStyle
              >
                <Checkbox disabled={isIterator}>Множественный выбор</Checkbox>
              </Form.Item>
              <Form.Item
                label="Атрибуты для отображения"
                name={compField("labelAtts")}
                rules={[{ required: true }]}
              >
                <SelectAttributeFlat
                  loader={loadObjectAttrinbutesReduced}
                  objectId={refId}
                  mode="multiple"
                  maxTagCount="responsive"
                />
              </Form.Item>
            </>
          )}
          {isChildEntities && (
            <>
              <Form.Item
                label="Связанный атрибут"
                name={compField("attrId")}
                rules={[{ required: true }]}
              >
                <SelectAttributeFlat
                  objectId={refId}
                  loader={async (objId) => {
                    const attrListReduced =
                      await loadObjectAttrinbutesReduced(objId);
                    const attsObjRef = attrListReduced.filter(
                      (attr) =>
                        attrTypesDict[attr.valueType] === AttrTypeName.object,
                    );
                    return attsObjRef;
                  }}
                />
              </Form.Item>
              <Form.Item
                name={compField("editable")}
                valuePropName="checked"
                noStyle
              >
                <Checkbox>Редактировать атрибут в модальном окне</Checkbox>
              </Form.Item>
            </>
          )}
          {isPersonInfo && (
            <SelectAttributesRolesList
              rolesIds={roleIds}
              name={compField("rolesViewAtts")}
            />
          )}
          <Divider className={styles.divider}>Дополнительные параметры</Divider>
          <div className={styles.threeCols}>
            <Form.Item
              label="Колонки"
              name={itemField("colspan")}
              tooltip="Сколько колонок займёт компонент на карточке экземпляра"
            >
              <InputNumber min={1} max={3} />
            </Form.Item>
            <Form.Item
              label="Строки"
              name={itemField("rowspan")}
              tooltip="Сколько ячеек по вертикали займёт компонент на карточке экземпляра"
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name={itemField("newLine")}
              label="С новой строки"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </div>
          {typeName === AttrTypeName.string && (
            <Form.Item
              label="Шаблон для проверки значения"
              name={itemField("pattern")}
              rules={[validatorRegexpInput]}
            >
              <RegexpInput />
            </Form.Item>
          )}
          {isChildEntities && (
            <Form.Item
              label="Суммировать значения атрибутов"
              name={compField("summaryAttrs")}
              tooltip="Суммировать числовые значения атрибутов зависимых объектов"
            >
              <SelectAttributeFlat
                loader={async (objId) => {
                  const attrListReduced =
                    await loadObjectAttrinbutesReduced(objId);
                  const summaryAttrs = attrListReduced.filter(isCalculableAttr);
                  return summaryAttrs;
                }}
                objectId={refId}
                mode="multiple"
                maxTagCount="responsive"
              />
            </Form.Item>
          )}
          <Form.Item name={itemField("tooltipExt")} hidden>
            <ExtTooltipItem
              tooltipText={tooltipText}
              open={tooltipExtOpen}
              close={() => setTooltipExtOpen(false)}
            />
          </Form.Item>
          <Form.Item name={itemField("tooltip")} label="Всплывающая подсказка">
            <Input
              allowClear
              addonAfter={
                <Button
                  size="small"
                  type="text"
                  icon={
                    getTooltipMode({ tooltipExt }) === "ext" ? (
                      <FormOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  onClick={() => setTooltipExtOpen(true)}
                />
              }
            />
          </Form.Item>
          {isTodayRule && (
            <Form.Item
              name={itemField("todayRule")}
              label="Ограничение относительно сегодня"
            >
              <Select options={dateCmpOptions()} allowClear />
            </Form.Item>
          )}
          {isRelativeCmpRule && (
            <Form.Item
              name={itemField("relativeCmpRule")}
              label="Сравнение с другим полем"
            >
              <RelativeCmpEdit
                typeName={typeName}
                objectId={objectId}
                curAttrId={curAttrId}
                typesMap={attrTypesDict}
              />
            </Form.Item>
          )}
          {isCopyByValueRule && (
            <Form.Item
              name={itemField("copyByValueRule")}
              label="Копирование по значению"
            >
              <CopyByValueEdit
                objectId={objectId}
                curAttrId={curAttrId}
                valueType={attrType}
                typeName={typeName}
              />
            </Form.Item>
          )}
          <div>
            <Button
              className={styles.viewButton}
              icon={<EyeOutlined />}
              onClick={() => setOpenPreview(true)}
            >
              Просмотр
            </Button>
            <ComponentPreview
              open={openPreview}
              close={() => setOpenPreview(false)}
              typesMap={attrTypesDict}
              attrForm={form}
            />
          </div>
        </div>
      </div>
    </DisabledContext.Provider>
  );
};

interface PropsExtTooltipItem {
  open: boolean;
  close(): void;
  value?: ZTooltipExtFields;
  onChange?(newValue: ZTooltipExtFields | undefined): void;
  tooltipText: string | undefined;
}

const ExtTooltipItem: React.FC<PropsExtTooltipItem> = (props) => {
  const { open, close, value, onChange, tooltipText } = props;
  const form = Form.useFormInstance();
  return (
    <TooltipExtEdit
      open={open}
      onCancel={close}
      getInitialData={() => ({
        text: form.getFieldValue(itemField("tooltip")),
        ...(value ?? {}),
      })}
      onOk={(fields) => {
        onChange?.(fields);
        close();
        form.setFieldValue(itemField("tooltip"), fields.text);
      }}
      tooltipText={tooltipText}
    />
  );
};

ExtTooltipItem.defaultProps = {
  value: undefined,
  onChange: undefined,
};
