// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-MainFrame-Header-ExtNotoficationsPopup-NotificationText-NotificationText-module__text--nFxPg {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainFrame/Header/ExtNotoficationsPopup/NotificationText/NotificationText.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".text {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `src-components-MainFrame-Header-ExtNotoficationsPopup-NotificationText-NotificationText-module__text--nFxPg`
};
export default ___CSS_LOADER_EXPORT___;
