// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-Sheet-CellBox-module__cellBox--VEgT2 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.src-components-Sheet-CellBox-module__cellBox--VEgT2 > .ant-spin-nested-loading {
  height: 100%;
}
.src-components-Sheet-CellBox-module__cellBox--VEgT2 > .ant-spin-nested-loading > .ant-spin-container {
  height: 100%;
}
.src-components-Sheet-CellBox-module__cellError--qouit {
  color: red;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sheet/CellBox.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AAAI;EACI,YAAA;AAER;AADQ;EAAkC,YAAA;AAI1C;AAAA;EACI,UAAA;EACA,eAAA;AAEJ","sourcesContent":[".cellBox {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    & > :global(.ant-spin-nested-loading) {\n        height: 100%;\n        & > :global(.ant-spin-container) {height: 100%;}\n    }\n}\n\n.cellError {\n    color: red;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cellBox": `src-components-Sheet-CellBox-module__cellBox--VEgT2`,
	"cellError": `src-components-Sheet-CellBox-module__cellError--qouit`
};
export default ___CSS_LOADER_EXPORT___;
