// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-Obj2Content-Obj2Content-module__tabs--XG959 .ant-tabs-nav {
  margin: 0;
  padding: 0 24px;
}
.src-pages-ManagementPage-Obj2Tab-Obj2Content-Obj2Content-module__tabs--XG959 .ant-tabs-nav::before {
  border-bottom: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/Obj2Content/Obj2Content.module.less"],"names":[],"mappings":"AAAA;EAEI,SAAA;EACA,eAAA;AAAJ;AAEI;EACE,mBAAA;AAAN","sourcesContent":[".tabs {\n  :global(.ant-tabs-nav) {\n    margin: 0;\n    padding: 0 24px;\n\n    &::before {\n      border-bottom: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `src-pages-ManagementPage-Obj2Tab-Obj2Content-Obj2Content-module__tabs--XG959`
};
export default ___CSS_LOADER_EXPORT___;
