import { ZDictGroup } from "src/types/ZGroup";

export const newItemId = -1;

export const deleteNegativeItems = (groups: ZDictGroup[]) => {
  const i = groups.findIndex(({ id }) => id === newItemId);
  if (i >= 0) groups.splice(i, 1);
  groups.forEach(({ attributes }) => {
    if (attributes) {
      const pos = attributes.findIndex(({ id }) => id === newItemId);
      if (pos >= 0) attributes.splice(pos, 1);
    }
  });
};
