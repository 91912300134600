/* eslint-disable react/no-array-index-key */
import { Tag } from "antd";
import React, { Fragment } from "react";
import { ZPersonRow } from "src/types/ZPersonRow";
import { TreeStdStore } from "src/components/TreeStd";
import { TreeLikeData } from "src/types/TreeLikeData";
import { getMention, isMentionPart } from "./MentionList/mentionUtils";

export const createChatMsg = (
  msg: string,
  users: ZPersonRow[],
): React.ReactNode => {
  const isQuote = (str: string) => str.includes("[ ]");
  const getMsgFromQuote = (str: string) => str.split("\n").pop() || "";
  const actualMsg = isQuote(msg) ? getMsgFromQuote(msg) : msg;
  const userNames = users
    .map((u) => u.userLogin)
    .sort((a, b) => b.length - a.length);

  type TextNode = {
    isMention: boolean;
    content: string;
  };

  let incrementKey = 0;
  const treeStore = new TreeStdStore<TextNode>();
  treeStore.setTreeData([
    {
      content: actualMsg,
      isMention: false,
      key: incrementKey,
    },
  ]);

  userNames.forEach((name) => {
    treeStore.walk((node) => {
      if (!node.isMention && !node.children) {
        const mention = getMention(name);
        const fragments = node.content.split(new RegExp(`(${mention})`));
        if (fragments.length > 1) {
          const children: TreeLikeData<TextNode>[] = fragments.map((fr) => {
            incrementKey += 1;
            return {
              content: fr,
              isMention: isMentionPart(fr),
              key: incrementKey,
            };
          });
          treeStore.updateNode(node.key, { children });
        }
      }
    });
  });
  const msgFragments: React.ReactNode[] = [];
  treeStore.walk((node) => {
    if (!node.children) {
      msgFragments.push(
        node.isMention ? (
          <Tag key={node.key}>{node.content}</Tag>
        ) : (
          <Fragment key={node.key}>{node.content}</Fragment>
        ),
      );
    }
  });

  return <div>{msgFragments}</div>;
};
