import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Select } from "antd";
import { TableStore } from "src/components/tables/TableStore";
import { ScrollableTable } from "src/components/ScrollableTable";
import { AColumn } from "src/components/tables/AsyncTable";
import { idNameFieldNames, ZIdName } from "src/types/ZIdName";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import {
  FilterFieldsDict,
  filterItem,
} from "src/components/tables/FiltersForm";
import { filterMonoSearch } from "src/components/tables/FiltersForm/filterMonoSearch";
import { DrawerPlmWithConfirmation } from "src/components/DrawerPlmWithConfirmation";
import { KeepSelected } from "src/components/tables/KeepSelected";
import { PointOpt } from "../MChartTable/PointDict";
import styles from "./PointSelectModal.module.less";

export interface FilterMcPoints {
  objectId?: number;
  query?: string;
  categoryId?: number;
}

interface PropsPointSelectModal {
  title: string;
  tableStore: TableStore<PointOpt, FilterMcPoints> | null;
  categoryList: ZIdName[];
  success: (rows: PointOpt[]) => void;
  close: () => void;
  okText?: string;
}

export const PointSelectModal: React.FC<PropsPointSelectModal> = observer(
  (props) => {
    const { title, tableStore, categoryList, success, close, okText } = props;
    const filters: FilterFieldsDict<FilterMcPoints> = {
      query: filterMonoSearch(),
      categoryId: filterItem(
        Select,
        {
          placeholder: t("Category"),
          options: categoryList
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name)),
          fieldNames: idNameFieldNames,
          allowClear: true,
          showSearch: true,
          optionFilterProp: "name",
        },
        { style: { flex: 1 } },
      ),
    };

    const columns: AColumn<PointOpt>[] = React.useMemo(
      () => [
        { key: "label", dataIndex: "label", title: t("Point name") },
        { key: "desc", dataIndex: "desc", title: t("Description") },
        { key: "category", dataIndex: "category", title: t("Category") },
      ],
      [],
    );
    const multi = tableStore?.selectionSettings.selectionType === "checkbox";

    return (
      <DrawerPlmWithConfirmation
        drawerProps={{
          title,
          open: !!tableStore,
          onClose: close,
        }}
        confirmationProps={{
          okText,
          okButtonProps: {
            onClick: () => {
              success(tableStore?.selected ?? []);
              close();
            },
          },
          cancelButtonProps: {
            onClick: close,
          },
        }}
      >
        {tableStore && (
          <div className={styles.tableBox}>
            <KeepSelected
              store={tableStore}
              makeTag={({ label }) => label}
              placeholder={t(multi ? "Selected positions" : "Selected")}
            />
            <ScrollableTable
              store={tableStore}
              columns={columns}
              filterItems={filters}
              onRowClick={(row) => {
                selectOnRowClick(row, tableStore);
              }}
            />
          </div>
        )}
      </DrawerPlmWithConfirmation>
    );
  },
);
