import * as React from "react";
import { CellBoxNumber } from "src/components/Sheet/CellBoxNumber";
import { classNames } from "src/common/classNames";
import { ZMCPoint } from "src/businessServices/services/bsMeasurementChart/ZMeasurementChart";
import { observer } from "mobx-react-lite";
import { SubsampleStore } from "../../SubsampleStore";
import styles from "./SampleCellEdit.module.less";

interface PropsSampleCellEdit {
  store: SubsampleStore;
  row: ZMCPoint;
}

export const SampleCellEdit: React.FC<PropsSampleCellEdit> = observer(
  ({ store, row }) => {
    const pt = row.sizes[0]?.pointValues[0]?.sampleMcPoint?.[0];
    if (!pt) return null;

    const { ptSheet } = store;
    const cellKey = `sample-${row.id}`;
    const draft = ptSheet.cellValues[cellKey];
    const { tolMinus, tolPlus } = row;
    const verValue = row.sizes[0]?.pointValues[0]?.value;
    let isRed = false;
    if (
      draft &&
      typeof tolMinus === "number" &&
      typeof tolPlus === "number" &&
      typeof verValue === "number"
    ) {
      const numDraft = +draft;
      if (!Number.isNaN(numDraft)) {
        // eslint-disable-next-line no-console
        console.log(
          "numDraft",
          numDraft,
          "min",
          verValue - tolMinus,
          "max",
          verValue + tolPlus,
          "verVal",
          verValue,
          "-:",
          tolMinus,
          "+:",
          tolPlus,
        );
        isRed = numDraft < verValue - tolMinus || numDraft > verValue + tolPlus;
      }
    }

    return (
      <CellBoxNumber
        cellKey={cellKey}
        value={pt.value}
        store={ptSheet}
        numberProps={{
          controls: false,
          className: classNames([styles.fullWidth, [isRed, styles.redCell]]),
        }}
        save={async (value) => {
          await store.saveSample({
            ...pt,
            value: value ?? null,
          });
          return value;
        }}
        onSuccess={(value) => {
          pt.value = value ?? null;
        }}
      />
    );
  },
);
