import * as React from "react";
import { observer } from "mobx-react-lite";
import { ModelEditorStore } from "../ModelEditorStore";
import styles from "./MEdAttrList.module.less";

interface PropsMEdAttrList {
  store: ModelEditorStore;
}

export const MEdAttrList: React.FC<PropsMEdAttrList> = observer(
  ({ store }: PropsMEdAttrList) => {
    const { model } = store;
    if (!model) return null;
    return (
      <div className={styles.box}>
        <div className={styles.list}>
          {model.attrs.map((a, i) => (
            <button
              type="button"
              key={a.key}
              className={i === store.curAttrIndex ? styles.active : ""}
              onClick={(e) => {
                e.preventDefault();
                store.setCurAttrIndex(i);
              }}
            >
              {a.attrName}
            </button>
          ))}
        </div>
      </div>
    );
  },
);
