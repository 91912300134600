import { matchPath } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { ZMenuItem } from "./ZMenuItem";
import { makeMenuItemKey } from "./makeMenuItemKey";

export type ItemType = {
  key: string;
  pageUrl?: string;
};

const flattenItems = (
  items: ZMenuItem[] | null | undefined,
): ItemType[] | undefined =>
  items?.flatMap(({ link, elements, id }: ZMenuItem) => {
    const pageUrl = () => {
      switch (link.type) {
        case "internal":
          return makeUrl(link.url as PageUrl);
        case "object":
          return makeUrl(PageUrl.entities, { objectId: link.objectId });
        default:
          return "";
      }
    };
    if (!pageUrl) return flattenItems(elements) ?? [];
    const flatItem: ItemType = {
      key: makeMenuItemKey(id),
      pageUrl: pageUrl(),
    };
    const flattened = elements ? flattenItems(elements) ?? [] : [];
    return [flatItem, ...flattened];
  });

export const findActiveItem = (list: ZMenuItem[], url: string) =>
  flattenItems(list)?.find(({ pageUrl }) =>
    matchPath({ path: pageUrl ?? "" }, url),
  );
