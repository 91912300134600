import { zPersonRow, ZPersonRow } from "src/types/ZPersonRow";
import { rest } from "./rest";
import { apiAdminUrl } from "./apiUrl";

type ParamsLoadPersonList = {
  roleIds: number[];
  userIds?: string[];
};

export const apiLoadPersonList = async (
  params: ParamsLoadPersonList,
): Promise<ZPersonRow[]> => {
  const { roleIds, userIds } = params;
  const body = { roleIds, userIds };
  const resp = await rest.post(apiAdminUrl("/users/list"), body);
  return zPersonRow.array().parse(resp.data);
};
