import * as React from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import {
  AppstoreAddOutlined,
  DownOutlined,
  SisternodeOutlined,
} from "@ant-design/icons";
import { GroupType } from "src/types/ZGroup";
import { iconGroupDict, iconGroupMnemonic } from "../../utils/itemIcons";
import { Obj2TabStore } from "../../Obj2TabStore";
import { AddBusinessServiceModal } from "./AddBusinessServiceModal";
import { ObjectO2 } from "../../Obj2Nodes";

interface PropsAddObjectItem {
  store: Obj2TabStore;
  objNode: ObjectO2;
  disabled?: boolean;
  buttonId?: string;
}

export const AddObjectItem: React.FC<PropsAddObjectItem> = (props) => {
  const { store, objNode, disabled, buttonId } = props;
  const [openBSModal, setOpenBSModal] = React.useState(false);

  const menuProps: MenuProps = {
    items: [
      {
        key: "attr",
        label: "Добавить атрибут",
        icon: <SisternodeOutlined />,
        onClick: () => store.safeAddAttr(),
      },
      {
        type: "divider",
      },
      {
        key: "groups",
        type: "group",
        label: "Добавить группу атрибутов",
        children: [
          {
            key: "mnenoGroup",
            label: "Мнемоническую",
            icon: iconGroupMnemonic,
            onClick: () => store.safeAddGroup(GroupType.Mnemonic),
          },
          {
            key: "dictiGroup",
            label: "На основе справочника",
            icon: iconGroupDict,
            onClick: () => store.safeAddGroup(GroupType.ByDictionary),
          },
        ],
      },
      {
        type: "divider",
      },
      {
        key: "businessService",
        icon: <AppstoreAddOutlined />,
        label: "Добавить бизнес-сервис",
        onClick: () => setOpenBSModal(true),
      },
    ],
  };

  return (
    <>
      <Dropdown menu={menuProps} disabled={disabled}>
        <Button id={buttonId}>
          <Space>
            Добавить
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <AddBusinessServiceModal
        open={openBSModal}
        close={() => setOpenBSModal(false)}
        onSuccess={(values) => store.createService(objNode, values)}
      />
    </>
  );
};

AddObjectItem.defaultProps = {
  disabled: false,
  buttonId: undefined,
};
