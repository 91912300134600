import * as React from "react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { ZTooltipExtFields } from "./ZTooltipExtFields";
import { ExtendedTooltipInner } from "./ExtendedTooltipInner";

interface PropsExtendedTooltip {
  fields: ZTooltipExtFields;
  children?: React.ReactNode;
}

export const ExtendedTooltip: React.FC<PropsExtendedTooltip> = ({
  fields,
  children,
}) => (
  <Tooltip
    color="white"
    placement={fields.placement as TooltipPlacement}
    title={<ExtendedTooltipInner tooltipData={fields} />}
  >
    {children}
  </Tooltip>
);

ExtendedTooltip.defaultProps = {
  children: undefined,
};
