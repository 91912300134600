import { Point } from "../../math";
import { ModelEditorStore, PointIndex } from "../ModelEditorStore";
import { Command, generateCmdKey } from "./Command";

export class CmdMovePoint implements Command {
  readonly key: number;

  constructor(
    private store: ModelEditorStore,
    private attrIndex: number,
    private pointIndex: PointIndex,
    private begin: Point,
    private end: Point,
    private already: boolean,
  ) {
    this.key = generateCmdKey();
  }

  readonly title = "Перемещение точки";

  createUndo(): Command {
    return new CmdMovePoint(
      this.store,
      this.attrIndex,
      this.pointIndex,
      this.end,
      this.begin,
      false,
    );
  }

  exec() {
    if (this.already) return;
    this.store.updatePoint(this.attrIndex, this.pointIndex, this.end);
  }
}
