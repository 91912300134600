import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { ifDef } from "src/common/ifDef";
import i18next from "i18next";
import { ZTranslationMap } from "src/types/ZTranslation";
import { loadDashboards } from "../ManagementPage/DashboardTab/apiDashboards";
import {
  ZDashboardGroup,
  ZDashboardItem,
} from "../ManagementPage/DashboardTab/DasboardTypes";

export const dashboardsPageStore = makeAutoObservable({
  data: { status: "none" } as RemoteData<ZDashboardGroup[]>,
  setData(newData: RemoteData<ZDashboardGroup[]>) {
    this.data = newData;
  },
  get groups(): ZDashboardGroup[] {
    return this.data.status === "ready" ? this.data.result : [];
  },
  get tabs(): { key: string; label: string }[] {
    return this.groups.map(({ groupKey, name, nameLoc }) => ({
      key: String(groupKey),
      label: getName(name, nameLoc),
    }));
  },
  async init() {
    try {
      this.setData({ status: "wait" });
      const result = await loadDashboards();
      this.setCurTabKey(ifDef(result[0], (g) => String(g.groupKey)) ?? "");
      this.setData({ status: "ready", result });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  },
  curTabKey: "",
  setCurTabKey(key: string) {
    this.curTabKey = key;
  },
  get items(): ZDashboardItem[] {
    const { curTabKey } = this;
    const curGroup = this.groups.find(
      ({ groupKey }) => String(groupKey) === curTabKey,
    );
    return curGroup ? curGroup.items : [];
  },
});

export type DashboardsPageStore = typeof dashboardsPageStore;

const getName = (name: string, dict: ZTranslationMap): string =>
  dict[i18next.language] ?? name;
