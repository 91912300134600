import { makeAutoObservable } from "mobx";
import React from "react";
import { TreeLikeData } from "src/types/TreeLikeData";
import { findNodeByKey } from "./utils/findNodeByKey";
import { findPath as findPathFunc } from "./utils/findPath";
import { walk as walkFunc } from "./utils/walk";
import { updateNodeRecursively } from "./utils/updateNodeRecursively";

export class TreeStdStore<TData extends Object = {}> {
  treeData: TreeLikeData<TData>[] = [];

  setTreeData(data: TreeLikeData<TData>[]) {
    this.treeData = data;
  }

  updateNode(key: React.Key, data: Partial<TreeLikeData<TData>>) {
    this.setTreeData(updateNodeRecursively(this.treeData, key, data));
  }

  appendNode(key: React.Key, children: TreeLikeData<TData>[]) {
    this.updateNode(key, { children } as Partial<TreeLikeData<TData>>);
  }

  findNode(key: React.Key) {
    return findNodeByKey(key, this.treeData);
  }

  findPath(key: React.Key) {
    return findPathFunc(this.treeData, key);
  }

  walk(callback: (node: TreeLikeData<TData>) => void) {
    return walkFunc(callback, this.treeData);
  }

  constructor(initial?: TreeLikeData<TData>[]) {
    if (initial) this.setTreeData(initial);
    makeAutoObservable(this);
  }
}
