import { ZTreeCategoryNode } from "src/types/ZTreeCategoryNode";
import { CatNode } from "src/pages/CategoryRefPage/CateroryTree";
// import { CompanyId } from "src/common/CompanyWatcher";

type CompanyId = number;

// Есть необходимость сортировать элементы по названию.
// Потому что после внесения изменений повторное чтение меняет порядок следования.
// Например, после редактирования 10-й записи она может стать 2-й.
// И это дезориентирует.
// Да и вообще, в отсортированном списке проще искать что-то.

const createCatList = (
  rawList?: ZTreeCategoryNode[] | null,
): CatNode[] | undefined => {
  const list = rawList?.map(createCatNode);
  return list?.sort((a, b) => a.order - b.order);
};

const createCatNode = (n: ZTreeCategoryNode): CatNode => ({
  id: String(n.id),
  key: String(n.key),
  title: n.name,
  parent: n.parentId ? String(n.parentId) : null,
  isLeaf: !n.children?.length, // hasChildren может давать неверный результат. Например, остается true после удаления вложенных веток
  data: n,
  children: createCatList(n.children),
  order: n.order,
});

export const loadCategoriesTree = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  companyId: number,
): Promise<ZTreeCategoryNode[]> =>
  // TODO: заглушка
  [
    {
      id: 1,
      key: "1",
      name: "Категория-1",
      parentId: null,
      hasChildren: true,
      order: 1,
      children: [
        {
          id: 11,
          key: "11",
          name: "Кат-1-1",
          parentId: 1,
          hasChildren: false,
          order: 1,
        },
        {
          id: 12,
          key: "12",
          name: "Кат-1-2",
          parentId: 1,
          hasChildren: false,
          order: 2,
        },
      ],
    },
    {
      id: 2,
      key: "2",
      name: "Категория-2",
      parentId: null,
      hasChildren: false,
      order: 2,
    },
  ];
// const resp = await rest.get(
//   `/srm-service/api/category/full?companyId=${companyId}`
// );
// return ZTreeCategoryNode.array().parse(resp.data || []);
export type CategoriesDictType = Record<string, ZTreeCategoryNode>;

export const makeCategoriesDict = (
  tree: ZTreeCategoryNode[] | undefined,
  keyField: keyof ZTreeCategoryNode,
): CategoriesDictType => {
  const dict: CategoriesDictType = {};
  const readList = (list?: ZTreeCategoryNode[] | null) => {
    list?.forEach((cat) => {
      dict[String(cat[keyField])] = cat;
      readList(cat.children);
    });
  };
  readList(tree);
  return dict;
};

export const loadCategoriesDict = async (
  keyField: keyof ZTreeCategoryNode,
  companyId: number,
): Promise<CategoriesDictType> => {
  const tree = await loadCategoriesTree(companyId);
  return makeCategoriesDict(tree, keyField);
};

export const categoriesDictLoader =
  (keyField: keyof ZTreeCategoryNode) => async (companyId: CompanyId) => {
    const res = await loadCategoriesDict(keyField, Number(companyId));
    return res;
  };

export const categoryName = (
  keyField: string,
  categoriesDict: CategoriesDictType | null | undefined,
): string => categoriesDict?.[keyField]?.name ?? keyField;

export const categoryFromTree = (
  categoryKey: string,
  tree: ZTreeCategoryNode[],
): ZTreeCategoryNode | undefined => {
  let foundCat: ZTreeCategoryNode | undefined;
  const deepFind = (nodes: ZTreeCategoryNode[] | null | undefined): boolean =>
    !!nodes?.find((cat) => {
      const { key, children } = cat;
      if (key === categoryKey) {
        foundCat = cat;
        return true;
      }
      return deepFind(children);
    });
  deepFind(tree);
  return foundCat;
};

export const categoryFromTreeById = (
  categoryId: number,
  tree: ZTreeCategoryNode[],
): ZTreeCategoryNode | undefined => {
  let foundCat: ZTreeCategoryNode | undefined;
  const deepFind = (nodes: ZTreeCategoryNode[] | null | undefined): boolean =>
    !!nodes?.find((cat) => {
      const { id, children } = cat;
      if (id === categoryId) {
        foundCat = cat;
        return true;
      }
      return deepFind(children);
    });
  deepFind(tree);
  return foundCat;
};

export const categoryNameFromTree = (
  categoryKey: string,
  tree: ZTreeCategoryNode[],
): string => categoryFromTree(categoryKey, tree)?.name || categoryKey;

export const findCategoryId = (
  categoryCode: string,
  categoriesDict: CategoriesDictType | null | undefined,
): number | string => categoriesDict?.[categoryCode]?.id ?? categoryCode;

export const getCatNodesFull = async (companyId: number): Promise<CatNode[]> =>
  createCatList(await loadCategoriesTree(companyId)) || [];

export const categoriesLoader = async (
  companyId: CompanyId,
): Promise<ZTreeCategoryNode[]> => {
  const id = +companyId;
  return id ? loadCategoriesTree(id) : [];
};

/**
 * используется, чтобы найти цепочку наследования от корня к элементу
 * ВНИМАНИЕ - маппинг должен быть создан по тому же ключу, который передается
 * в качестве field, то есть если field = id категории, то маппинг должен быть по 'id'
 * @param dict маппинг категорий по определенному полю
 * @param field поле-ключ текущего элемента
 */
export const findCatHierarchy = (
  dict: CategoriesDictType,
  field: number | string,
): ZTreeCategoryNode[] | null => {
  let current = dict[field];
  if (current === undefined) return null;
  const hierarchy: ZTreeCategoryNode[] = [];
  while (current) {
    hierarchy.push(current);
    current = dict[current?.parentId ?? ""];
  }
  return hierarchy.reverse();
};
