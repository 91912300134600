import { z } from "zod";

// Attachment types

/**
 * Аттачмент может быть любой, к сожалению, я не нашел полного описания всех вариаций в документаци
 * Все выяснялось опытным путем, поэтому пока так.
 */
export const zRCAttachemntBase = z.object({
  title: z.string(),
  title_link: z.string(),
  format: z.string(),
});
export type ZRCAttachemntBase = z.infer<typeof zRCAttachemntBase>;

export const zRCAttachemntImg = z.object({
  image_preview: z.string(),
  image_type: z.string(),
  image_url: z.string(),
  title: z.string(),
});
export type ZRCAttachemntImg = z.infer<typeof zRCAttachemntImg>;

export const zRCAttachemntMsg: z.ZodType<{
  text: string;
  author_name: string;
  attachments?: ZRCAttachment[];
}> = z.lazy(() =>
  z.object({
    text: z.string(),
    author_name: z.string(),
    attachments: zRCAttachemnt.array().optional(),
  }),
);
export type ZRCAttachemntMsg = z.infer<typeof zRCAttachemntMsg>;

export const zRCAttachemnt = z.union([
  zRCAttachemntBase,
  zRCAttachemntImg,
  zRCAttachemntMsg,
]);
export type ZRCAttachment = z.infer<typeof zRCAttachemnt>;
/**
 * Schema defenition:
 * https://developer.rocket.chat/docs/user
 */
export const zRCUser = z.object({
  _id: z.string(),
  username: z.string(),
});

export type ZRCUser = z.infer<typeof zRCUser>;

/**
 * Schema defenition:
 * https://developer.rocket.chat/docs/room
 */
export const zRCRoom = z.object({
  _id: z.string(),
  name: z.string(),
  usersCount: z.number(),
  u: zRCUser,
});

export type ZRCRoom = z.infer<typeof zRCRoom>;

/**
 * Schema defenition:
 * https://developer.rocket.chat/apidocs/get-room-information
 */
export const zRCRoomInfoResp = z.object({
  room: zRCRoom,
  success: z.boolean(),
});

/**
 * Schema defenition:
 * https://developer.rocket.chat/docs/message
 * 
    Type	                Description
    r	                    Room name changed
    au	                    User added by
    ru	                    User removed by
    ul	                    User left
    ult	                    User left team
    uj	                    User joined channel
    ujt	                    User joined team
    ut	                    User joined conversation
    rm	                    Message removed
    added-user-to-team	    Added user to team
    removed-user-from-team	Removed user from team
    user-muted	            User muted by
 */
export const zRCMessage = z.object({
  _id: z.string(),
  rid: z.string(), // roomId
  msg: z.string(),
  t: z
    .union([
      z.literal("r"),
      z.literal("au"),
      z.literal("ru"),
      z.literal("ul"),
      z.literal("ult"),
      z.literal("uj"),
      z.literal("ujt"),
      z.literal("ut"),
      z.literal("rm"),
      z.literal("added-user-to-team"),
      z.literal("removed-user-from-team"),
      z.literal("user-muted"),
    ])
    .optional(),
  u: zRCUser.optional(),
  attachments: zRCAttachemnt.array().optional(),
});

export type ZRCMessage = z.infer<typeof zRCMessage>;

/**
 * Schema defenition:
 * https://developer.rocket.chat/apidocs/get-channel-messages
 */
export const zRCMessageResp = z.object({
  messages: zRCMessage.array(),
  count: z.number(),
  offset: z.number(),
  total: z.number(),
  success: z.boolean(),
});

export type ZRCMessageResp = z.infer<typeof zRCMessageResp>;

// Events
type RCSRoomMessagesEvent = {
  id: string;
  msg: "changed";
  collection: "stream-room-messages";
  fields: { eventName: string; args: ZRCMessage[] };
};

export type RCSChangeEvent = RCSRoomMessagesEvent;
