import * as React from "react";

type CommonNode = {
  key: React.Key;
  children?: CommonNode[];
};

export const findNode = <Node extends CommonNode>(
  test: (node: Node) => boolean,
  tree: Node[],
): Node | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of tree) {
    if (test(node)) return node;
    if (node.children) {
      const subNode = findNode(test, node.children as Node[]);
      if (subNode) return subNode as Node;
    }
  }
  return undefined;
};
