/* eslint-disable max-classes-per-file */
import { makeAutoObservable } from "mobx";
import { SimpleKey } from "src/types/SimpleKey";
import {
  CheckListOption,
  CheckListStore,
} from "../../../components/CheckList/CheckListStore";

export class CheckListWithOrderStore {
  checkListStore = new CheckListStore();

  constructor() {
    makeAutoObservable(this);
  }

  reset(): void {
    this.checkListStore.reset();
    this.setOrder([]);
    this.normalizeOrder();
  }

  clear(): void {
    this.checkListStore.clear();
    this.setOrder([]);
  }

  order: SimpleKey[] = [];

  setOrder(list: SimpleKey[]) {
    this.order = list;
  }

  get orderedSelected() {
    const selectedMap = this.checkListStore.selected.reduce(
      (acc, curr) => {
        acc[curr.value] = curr;
        return acc;
      },
      {} as Record<string, CheckListOption>,
    );

    return this.order
      .map((key) => selectedMap[key])
      .filter((item) => !!item) as CheckListOption[];
  }

  /**
   * Нормализация сортировки
   */
  normalizeOrder() {
    const orderSet = new Set(this.order);
    const head = this.order.filter((item) =>
      this.checkListStore.isSelected(item),
    );
    const tail = this.checkListStore.selectedKeys.filter(
      (key) => !orderSet.has(key),
    );
    this.setOrder([...head, ...tail]);
  }
}
