import { RetweetOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { getEditorInfo } from "src/common/attrEdit";
import { getViewInfo } from "src/common/attrView";
import { getComponentEditor } from "src/pages/EntityCardPage/blockBuilder/createItem2";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import React, { Fragment } from "react";
import { getEntityCachedRequest } from "../getEntityCachedRequest";
import { composeEntityLabel } from "./composeEntityLabel";
import { LabelComposeConfig } from "./getActualComposeView";

/**
 * Есть случаи, когда возникает циклическая зависимость при попытке отобразить
 * label атрибута. Если у объекта О1 есть атрибут a1 = ссылка на объект и он ссылается на
 * объект O2 с атрибутом а2, который в свою очередь ссылается на атрибут а1 объекта О1
 * В этом случае необходимо обработать эту зависимость, чтобы избежать бесконечной загрузки
 *
 * Для этого вводится pathRegister.
 * Принцип простой - если id атрибута попадается в нем больше одного раза, значит
 * мы имеем дело с рекурсией
 */
export const getObjectRefAttrLabel = async (
  entity: ZEntity,
  attribute: ZAttribute,
  entityIdList: string[],
  typeMap: Record<number, string>,
  // для отслеживания рекурсии
  pathRegister: number[],
  config?: LabelComposeConfig,
) => {
  if (pathRegister.includes(attribute.id))
    return [
      <Tag key={attribute.id} color="red">
        <RetweetOutlined
          title={`Рекурсивная зависимость по атрибуту "${attribute.name}"`}
        />
      </Tag>,
    ];
  pathRegister.push(attribute.id);
  const editorInfo = getEditorInfo(attribute.viewStyles);
  const component = getComponentEditor(editorInfo, typeMap, attribute);
  const viewInfo = getViewInfo(attribute.viewType);
  if (component?.editor === "ObectRefSelector" && viewInfo) {
    const nestedEntProm =
      entityIdList.map((id) => getEntityCachedRequest(Number(id))) || [];
    const nestedEntList = await Promise.all(nestedEntProm);
    const labelsProm = nestedEntList.map((e) =>
      composeEntityLabel(
        e,
        component.labelAtts || [],
        viewInfo,
        pathRegister,
        config,
      ),
    );
    const nestedLabels = await Promise.all(labelsProm);
    return nestedLabels.flat();
  }

  return [<Fragment key={entity.id}>{entity.id}</Fragment>];
};
