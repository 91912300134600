import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { blockFields } from "src/components/FormWithBlocks/blockTypes";
import {
  FormBlockDef,
  FormWithBlockStore,
  blockItem,
} from "src/components/FormWithBlocks";
import { Input, Select } from "antd";
import { ZOption } from "src/types/ZOption";
import { onError } from "src/common/onError";
import { stdDatePicker } from "src/components/FormWithBlocks/items/stdDatePicker";
import {
  ZTestRecord,
  createRecord,
  getRecordById,
  getStatusesDict,
  makeStatusOptions,
  updateRecord,
} from "../testTableApi";

export const testCardStore = makeAutoObservable({
  formStore: new FormWithBlockStore(),
  data: { status: "none" } as RemoteData<ZTestRecord>,
  setData(newData: RemoteData<ZTestRecord>) {
    this.data = newData;
  },

  cardId: "",
  setCardId(id?: string) {
    this.cardId = id || "";
  },
  get isNew(): boolean {
    return !this.cardId;
  },

  statusOptions: [] as ZOption[],
  setStatusOptions(options: ZOption[]) {
    this.statusOptions = options;
  },

  async init(id?: string) {
    try {
      this.setCardId(id);
      getStatusesDict()
        .then((dict) => this.setStatusOptions(makeStatusOptions(dict)))
        .catch(onError);
      if (this.isNew) {
        this.setData({ status: "ready", result: {} as ZTestRecord });
      } else {
        this.setData({ status: "wait" });
        const result = await getRecordById(id || "");
        this.setData({ status: "ready", result });
      }
    } catch (error) {
      this.setData({ status: "error", error });
    }
  },
  async save(values: ZTestRecord): Promise<ZTestRecord> {
    const prevValues = this.data.status === "ready" ? this.data.result : {};
    const record: ZTestRecord = {
      ...prevValues,
      ...values,
    };
    return updateRecord(record);
  },
  async create(values: ZTestRecord): Promise<ZTestRecord> {
    return createRecord(values);
  },
  get formBlocks(): FormBlockDef {
    return blockFields("root", {}, [
      blockItem(
        "name",
        "Наименование",
        Input,
        { allowClear: true },
        { rules: [{ required: true }] },
      ),
      blockItem(
        "status",
        "Статус",
        Select,
        {
          options: this.statusOptions,
          allowClear: true,
        },
        { rules: [{ required: true }] },
      ),
      stdDatePicker("expirationDate", "Дата окончания", {}),
    ]);
  },
  get pageHeader(): string {
    if (this.isNew) {
      return "Новая карточка";
    }
    if (this.data.status === "ready") {
      return `Карточка объекта "${this.data.result.name}"`;
    }
    return "Карточка объека";
  },
});
