/**
 * Пользователи системы.
 * Person потому что User - это тот пользователь, который запустил приложение.
 */

import { z } from "zod";
import { zEntity } from "./ZEntity";

/**
 * В нашей системе пользователи - это сущности, но в данном констексте к ним примешиваются некоторые
 * дополнительные поля
 */
export const zPersonRow = zEntity.and(
  z.object({
    /**
     *  В нашей системе у пользователя есть !2! id
     *  Первый генерится внутри системы, а второй, по сути,
     *  является внешним id из Keycloak. userId - это как раз и есть
     *  этот внешний id.
     *  */
    userId: z.string(),
    userLogin: z.string(),
    roleIds: z.number().array(),
  }),
);

export type ZPersonRow = z.infer<typeof zPersonRow>;
