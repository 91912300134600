import * as React from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { confirmDelete } from "src/components/ModalDelete";
import { t } from "i18next";
import styles from "./MCSubtitle.module.less";

interface PropsMCSubtitle {
  verName: string;
  onDelete?(): void;
}

export const MCSubtitle: React.FC<PropsMCSubtitle> = ({
  verName,
  onDelete,
}) => (
  <div className={styles.subtitle}>
    <span className={styles.text}>{verName}</span>
    {!!onDelete && (
      <div className={styles.button}>
        <Button
          icon={<DeleteOutlined />}
          danger
          size="small"
          type="text"
          onClick={() =>
            confirmDelete(t("Are you sure to delete version?"), onDelete)
          }
          title={t("Delete version")}
        />
      </div>
    )}
  </div>
);
MCSubtitle.defaultProps = {
  onDelete: undefined,
};
