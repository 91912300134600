import React from "react";
import { Flex } from "antd";
import { TaskSearch } from "./TaskSearch";
import { ColumnVisibilityButton } from "./ColumnVisibilityButton";
import { SchedulingControlStore } from "../../SchedulingControlStore";

interface PropsSchedulingToolbar {
  store: SchedulingControlStore;
}

export const SchedulingToolbar: React.FC<PropsSchedulingToolbar> = ({
  store,
}) => (
  <Flex gap={8} align="center">
    <TaskSearch store={store} />
    <ColumnVisibilityButton store={store} />
  </Flex>
);
