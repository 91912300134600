import React from "react";
import { t } from "i18next";
import { EntityDatePicker } from "src/pages/EntityCardPage/fieldComponents/EntityDatePicker";
import { Flex } from "antd";
import { observer } from "mobx-react-lite";
import { SchedulingControlStore } from "../../SchedulingControlStore";
import styles from "./StartDatePicker.module.less";

interface PropsStartDatePicker {
  store: SchedulingControlStore;
}

export const StartDatePicker: React.FC<PropsStartDatePicker> = observer(
  ({ store }) => {
    const { projectInfo, operationLoading, data } = store;
    const projectStartDate = projectInfo?.plannedStartDate;
    const disabled = operationLoading || data.status !== "ready";
    const handleChange = (value: string[] | null | undefined) => {
      const date = value?.[0];
      if (date) store.changeStartDate(date);
    };
    return (
      <Flex vertical gap={8}>
        <div className={styles.projectStartDate}>
          {t("Planned start date")}:
        </div>
        <div className={styles.datePicker}>
          <EntityDatePicker
            disabled={disabled}
            value={projectStartDate ? [projectStartDate] : null}
            onChange={handleChange}
            allowClear={false}
          />
        </div>
      </Flex>
    );
  },
);
