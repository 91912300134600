import { Button, Input, InputRef, Tooltip } from "antd";
import * as React from "react";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LikeOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { ifs } from "src/common/ifs";
import styles from "./RegexpInput.module.less";
import { safeStr2Regexp } from "./str2Regexp";

interface PropsRegexpInput {
  value?: string;
  onChange?(newValue?: string): void;
}

const suggest = [
  ["Email", /^.+@.+$/],
  ["Цифры", /^\d+$/],
  ["Русские буквы", /^[А-ЯЁ]+$/i],
] as const;

export const RegexpInput: React.FC<PropsRegexpInput> = ({
  value,
  onChange,
}) => {
  const ref1 = React.createRef<InputRef>();
  const ref2 = React.createRef<InputRef>();
  const [expanded, setExpanded] = React.useState(false);
  const [test, setTest] = React.useState("");
  const result: "fail" | "none" | "ok" = React.useMemo(() => {
    const rx = safeStr2Regexp(value);
    if (!value || !rx) return "none";
    return rx.test(test) ? "ok" : "fail";
  }, [value, test]);
  const resultIcon = ifs<React.ReactNode>(
    [
      [result === "ok", <LikeOutlined style={{ color: "green" }} />],
      [result === "fail", <WarningOutlined />],
    ],
    "-",
  );
  return (
    <div className={styles.box}>
      <div>
        <Input
          ref={ref1}
          value={value}
          size="middle"
          allowClear
          onChange={(e) => onChange?.(e.currentTarget.value)}
          suffix={
            <Tooltip title="Строка для тестирования">
              <Button
                size="small"
                shape="circle"
                type="text"
                icon={expanded ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={() => {
                  setExpanded((prev) => !prev);
                }}
              />
            </Tooltip>
          }
        />
      </div>
      <div className={styles.suggestBox}>
        {suggest.map(([msg, rx]) => (
          <Button
            key={msg}
            type="text"
            size="small"
            onClick={() => {
              onChange?.(rx.toString());
              ref1.current?.focus();
            }}
          >
            {msg}
          </Button>
        ))}
      </div>
      {expanded && (
        <div>
          <Input
            ref={ref2}
            size="small"
            status={result === "fail" ? "error" : ""}
            allowClear
            prefix={resultIcon}
            disabled={!value}
            value={test}
            addonAfter={result}
            onChange={(e) => setTest(e.currentTarget.value)}
          />
        </div>
      )}
    </div>
  );
};

RegexpInput.defaultProps = {
  value: undefined,
  onChange: undefined,
};
