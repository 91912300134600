import {
  EntCopyTreeNode,
  EntCopyTreeNodeType,
  ZEntCopyDataAttr,
  ZEntCopyDataBS,
  ZEntCopyDataResp,
} from "../types";

const makeCopyDataAttsTree = (
  hierarchy: ZEntCopyDataAttr[],
): EntCopyTreeNode[] =>
  hierarchy.map((item) => ({
    key: item.attributeId,
    data: item,
    type: EntCopyTreeNodeType.attribute,
    title: item.name,
    ...(item.linkedAttributes.length && {
      children: makeCopyDataAttsTree(item.linkedAttributes),
    }),
  }));

const makeCopyDataBSTree = (bsData: ZEntCopyDataBS[]): EntCopyTreeNode[] =>
  bsData.map((item) => ({
    key: item.name,
    data: item,
    type: EntCopyTreeNodeType.businessService,
    title: item.name,
  }));

export const makeCopyTreeMetaSetting = (
  data: ZEntCopyDataResp,
): EntCopyTreeNode[] => [
  ...makeCopyDataAttsTree(data.childAttributes),
  ...makeCopyDataBSTree(data.businessServices),
];
