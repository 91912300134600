// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityFormWithAnchor-EntityStates-EntityStates-module__box--KyhLD {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.src-pages-EntityCardPage-EntityFormWithAnchor-EntityStates-EntityStates-module__label--OLtuZ {
  color: rgba(0, 0, 0, 0.55);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 8px;
}
.src-pages-EntityCardPage-EntityFormWithAnchor-EntityStates-EntityStates-module__form--hcua4 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityFormWithAnchor/EntityStates/EntityStates.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACA;EACI,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n.label {\n    color: rgba(0, 0, 0, 0.55);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%;\n    margin-bottom: 8px;\n}\n.form {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-EntityCardPage-EntityFormWithAnchor-EntityStates-EntityStates-module__box--KyhLD`,
	"label": `src-pages-EntityCardPage-EntityFormWithAnchor-EntityStates-EntityStates-module__label--OLtuZ`,
	"form": `src-pages-EntityCardPage-EntityFormWithAnchor-EntityStates-EntityStates-module__form--hcua4`
};
export default ___CSS_LOADER_EXPORT___;
