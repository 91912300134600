// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-ValueForm2-ValueForm2-module__buttons--wQIoT {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/ValueForm2/ValueForm2.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,QAAA;EACA,WAAA;EACA,eAAA;AACF","sourcesContent":[".buttons {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  width: 100%;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `src-pages-ManagementPage-Obj2Tab-forms-ValueForm2-ValueForm2-module__buttons--wQIoT`
};
export default ___CSS_LOADER_EXPORT___;
