import * as React from "react";
import { Spin, Tag } from "antd";
import { observer } from "mobx-react-lite";
import {
  AttrLabelTreeData,
  useAttrLabels,
} from "src/common/attributes/composeAttrLabel2";
import { ZAttribute } from "src/types/ZAttribute";
import { makeAutoObservable } from "mobx";
import { getTypesMap } from "src/references/getTypesMap";
import { onError } from "src/common/onError";
import { AttrTypeName } from "src/types/AttrType";
import { FileOutlined } from "@ant-design/icons";
import { getUsers2LabelsDict } from "src/common/attributes/composeAttrLabel2/labelLoaders";
import { ZModePersonCell, ZPersonCellInfo } from "../../componentsDefs";
import styles from "./PersonCell.module.less";
import { getViewInfo } from "../../getViewInfo";
import { seedSeparators } from "../../viewFormItems/Separator";
import { composeLabelsWithEditConfig } from "../../createItemView/composeLabelWithConf";

export const createPersonCellInfoStore = () =>
  makeAutoObservable({
    wait: false,
    setWait(flag: boolean) {
      this.wait = flag;
    },
    typesMap: {} as Record<number, string>,
    setTypesMap(map: Record<number, string>) {
      this.typesMap = map;
    },
    async init() {
      this.setWait(true);
      getTypesMap()
        .then((data) => this.setTypesMap(data))
        .finally(() => this.setWait(false))
        .catch(onError);
    },
  });

const forReplace: Partial<Record<AttrTypeName, React.ReactNode>> = {
  [AttrTypeName.image]: <FileOutlined />,
};

const getLabelReplace = (
  attr: ZAttribute,
  typesMap: Record<number, string>,
  def: React.ReactNode,
) => {
  const type = typesMap[attr.valueType];
  if (!type) return def;
  return forReplace[type as AttrTypeName];
};

const composeLabel =
  (viewProps: ZPersonCellInfo, typesMap: Record<number, string>) =>
  (data: AttrLabelTreeData) => {
    const personLabelsDict = getUsers2LabelsDict(data);
    const withReplacers = Object.entries(personLabelsDict).reduce(
      (a, [key, val]) => ({
        ...a,
        [key]: val.map((ld) =>
          getLabelReplace(
            ld.attr,
            typesMap,
            composeLabelsWithEditConfig(ld.attr, ld.labels, typesMap),
          ),
        ),
      }),
      {} as Record<string, React.ReactNode[]>,
    );
    const grouped = Object.values(withReplacers).map((list) =>
      seedSeparators(
        list.map((item, i) => ({
          content: item,
          key: i,
        })),
        "comma",
      ),
    );
    return grouped.map((l, i) => ({
      content: createLabel(viewProps, l),
      key: i,
    }));
  };

const createLabel = (viewProps: ZPersonCellInfo, content: React.ReactNode) => {
  const lablelMap: Partial<Record<ZModePersonCell, React.ReactNode>> = {
    tag: (
      <Tag color={viewProps?.modeMeta?.tagColor} style={{ maxWidth: "100%" }}>
        {content}
      </Tag>
    ),
    standart: content,
  };
  const mode = viewProps?.mode;
  return mode ? lablelMap[mode] : lablelMap.standart;
};

interface PropsPersonCellInfo {
  values: string[];
  attr: ZAttribute;
}
export const PersonCellInfo: React.FC<PropsPersonCellInfo> = observer(
  ({ values, attr }) => {
    const viewInfo = getViewInfo(attr.viewType);
    if (!values?.length || viewInfo?.component?.view !== "PersonCellInfo")
      return null;
    const store = React.useMemo(() => createPersonCellInfoStore(), []);
    const [labels, wait] = useAttrLabels(
      composeLabel(viewInfo.component, store.typesMap),
      values,
      attr,
      undefined,
      [store.typesMap],
    );
    if (wait || store.wait) return <Spin spinning>...</Spin>;
    return (
      <div
        style={{ ...viewInfo?.styles }}
        className={styles[viewInfo?.appearance?.view?.overflow || ""]}
      >
        {labels}
      </div>
    );
  },
);
