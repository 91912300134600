import { DefaultOptionType } from "antd/es/select";
import { Select, SelectProps } from "antd/lib";
import React, { useEffect, useState } from "react";
import { filterOptionByName } from "src/common/filterOptionByName";
import { onError } from "src/common/onError";
import { ZAttribute } from "src/types/ZAttribute";

type PropsSelectAttributeFlat = SelectProps & {
  objectId: string;
  loader: (objectId: string) => Promise<ZAttribute[]>;
};

/**
 * @deprecated use SelectFromReference instead
 */
export const SelectAttributeFlat: React.FC<PropsSelectAttributeFlat> = ({
  objectId,
  loader,
  ...props
}) => {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  const init = async () => {
    if (!objectId) return;
    try {
      const atts = await loader(objectId);
      /**
       * было плохой идеей предполагать, что значение
       * всегда будет типа строки и делать String(attr.id)
       */
      setOptions(
        atts
          .map((attr) => ({ label: attr.name.trim(), value: String(attr.id) }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    init();
  }, [objectId]);

  return (
    <Select
      {...props}
      options={options}
      style={{ width: "100%" }}
      showSearch
      filterOption={filterOptionByName}
    />
  );
};
