import * as React from "react";
import { observer } from "mobx-react-lite";
import { Collapse } from "antd";
import { LoaderBox } from "src/components/LoaderBox";
import { AttrLevelPanel } from "./AttrLevelPanel";
import { CategoryAttributesStore } from "./CategoryAttributesStore";

const { Panel } = Collapse;

interface PropsAttrsListView {
  attrStore: CategoryAttributesStore;
}

export const AttrsListView: React.FC<PropsAttrsListView> = observer(
  ({ attrStore }: PropsAttrsListView) => {
    const revNodes = [...attrStore.nodes];
    revNodes.reverse();
    const drawItem = (nodeKey: string, editable: boolean) => {
      const rd = attrStore.attrBlocks[nodeKey];
      return rd ? (
        <LoaderBox
          remoteData={rd}
          size="default"
          drawReady={(attrs) => (
            <AttrLevelPanel
              attrs={attrs}
              typeLabels={attrStore.typeLabels}
              editable={editable}
              attrStore={attrStore}
            />
          )}
        />
      ) : null;
    };
    return (
      <Collapse
        expandIconPosition="end"
        activeKey={attrStore.activeKeys}
        onChange={(k) => {
          if (Array.isArray(k)) attrStore.setActiveKeys(k);
        }}
      >
        {revNodes.map((node, level) => (
          <Panel key={node.key} header={`Атрибуты L${level}: ${node.title}`}>
            {drawItem(node.key, level === revNodes.length - 1)}
          </Panel>
        ))}
      </Collapse>
    );
  },
);
