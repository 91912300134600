import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { loadRefDict } from "src/common/loadRefDict";
import { onError } from "src/common/onError";
import { SimpleText } from "../SimpleText/SimpleText";
import { ZTagModeDictRef, ZDictRef, zDictRef } from "../../componentsDefs";
import { seedSeparators } from "../../viewFormItems/Separator";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import styles from "./DictRef.module.less";
import { getViewInfo } from "../../getViewInfo";

type Option = { id: number; value: string | null };

const getTagsMultipleColor = (viewProps: ZDictRef | undefined, atrId: string) =>
  viewProps?.modeMeta?.tagsColorMap?.[atrId];

const getTagColor = (viewProps: ZDictRef | undefined) =>
  viewProps?.modeMeta?.tagColor;

const createMuliTag = (
  viewProps: ZDictRef | undefined,
  viewInfo: ZAttrViewInfo | undefined,
  options: Option[],
) => {
  const labelsWithSeparator = seedSeparators(
    options.map((opt) => ({
      content: (
        <Tag
          color={getTagsMultipleColor(viewProps, String(opt.id))}
          style={{ maxWidth: "100%" }}
        >
          <SimpleText values={[opt.value]} viewInfo={viewInfo} />
        </Tag>
      ),
      key: opt.id,
    })),
    viewInfo?.appearance?.view?.separator,
  );

  return (
    <div className={styles.box} style={viewInfo?.styles ?? undefined}>
      {labelsWithSeparator}
    </div>
  );
};

const createSimpleLable = (
  viewInfo: ZAttrViewInfo | undefined,
  options: Option[],
) => {
  const labelsWithSeparator = seedSeparators(
    options.map((opt) => ({ content: opt.value, key: opt.id })),
    viewInfo?.appearance?.view?.separator,
  );
  return (
    <SimpleText
      values={labelsWithSeparator}
      viewInfo={viewInfo}
      className={styles.box}
    />
  );
};

const createTag = (
  viewProps: ZDictRef | undefined,
  viewInfo: ZAttrViewInfo | undefined,
  options: Option[],
) => (
  <Tag color={getTagColor(viewProps)} style={{ maxWidth: "100%" }}>
    {createSimpleLable(viewInfo, options)}
  </Tag>
);

const createLabel = (attr: ZAttribute, options: Option[]) => {
  try {
    const viewInfo = getViewInfo(attr.viewType);
    const viewProps = zDictRef.parse(viewInfo?.component);
    const lablelMap: Record<ZTagModeDictRef, React.ReactNode> = {
      tagsMulti: createMuliTag(viewProps, viewInfo, options),
      tag: createTag(viewProps, viewInfo, options),
      standart: createSimpleLable(viewInfo, options),
    };
    const mode = viewProps?.mode;
    return mode ? lablelMap[mode] : lablelMap.standart;
  } catch (error) {
    onError(error);
    return null;
  }
};

type PropsDictRef2 = {
  attr: ZAttribute;
  values: string[];
};

export const DictRef2: React.FC<PropsDictRef2> = ({ attr, values }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const labels = createLabel(attr, options);
  useEffect(() => {
    if (attr.referenceId) {
      loadRefDict(attr.referenceId, { translate: true })
        .then((data) => {
          const dictKeys = new Set(values);
          setOptions(data.filter((item) => dictKeys.has(String(item.id))));
        })
        .catch(onError);
    }
  }, [values]);
  return labels;
};
