// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-NewMCEntity-module__box--uwIyn {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-NewMCEntity-module__content--AYD8C {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/NewMCEntity/NewMCEntity.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACA;EACI,OAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n.content {\n    flex: 1;\n    overflow: auto;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-NewMCEntity-module__box--uwIyn`,
	"content": `src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-NewMCEntity-module__content--AYD8C`
};
export default ___CSS_LOADER_EXPORT___;
