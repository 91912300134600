import { makeAutoObservable } from "mobx";
import { FnLoad, TableStore } from "src/components/tables/TableStore";
import { delay } from "src/common/delay";
import { ModelsFilter, ZModelRow } from "./ModelsTypes";

const fnLoad: FnLoad<ZModelRow, ModelsFilter> = async () => {
  await delay(2000);
  return {
    rows: [],
    totalItems: 0,
  };
};

export const modelsListPageStore = makeAutoObservable({
  tableStore: new TableStore<ZModelRow, ModelsFilter>({
    rowKey: "id",
    fnLoad,
    filterSettingsKey: "ModelsPage",
  }),
  async init() {
    await delay(100);
  },
});
