import { notification } from "antd";
import { makeAutoObservable } from "mobx";
import { ZModel, zModel } from "../ZModel";
import { ModelEditorStore } from "../ModelEditor";

type ListState = "add" | "view";
const modelsKey = "models";

export class ModelsListStore {
  constructor() {
    makeAutoObservable(this);
  }

  state: ListState = "view";

  setState(newState: ListState) {
    this.state = newState;
  }

  modelsList: ZModel[] = [];

  setModelsList(modelsList: ZModel[]) {
    this.modelsList = modelsList;
  }

  curModelIndex = 0;

  setCurModelIndex(i: number) {
    this.updateCurModel();
    this.curModelIndex = i;
  }

  get curModel(): ZModel | null {
    return this.modelsList[this.curModelIndex] ?? null;
  }

  modelStore: ModelEditorStore | null = null;

  setModelStore(st: ModelEditorStore | null) {
    this.modelStore = st;
  }

  updateCurModel() {
    if (this.modelStore) {
      const { model } = this.modelStore;
      if (model) {
        this.modelsList[this.curModelIndex] = model;
      }
    }
  }

  save() {
    try {
      this.updateCurModel();
      localStorage[modelsKey] = JSON.stringify(this.modelsList);
      notification.success({ message: "Выполнено сохранение списка моделей" });
    } catch (e) {
      notification.error({ message: e.message });
    }
  }

  createTestData() {
    this.setModelsList([
      {
        id: 1,
        name: "Тест 1",
        imgRef:
          "https://i.pinimg.com/736x/74/2b/c2/742bc2522d3fd2f5252680a50ecb3dd4.jpg",
        imgWidth: 659,
        imgHeight: 545,
        attrs: [
          { key: 1, attrName: "A1", xA: 30, yA: 78, xB: 30, yB: 290 },
          { key: 2, attrName: "XYZ", xA: 346, yA: 108, xB: 624, yB: 108 },
          {
            key: 3,
            attrName: "Curv",
            xA: 150,
            yA: 288,
            xB: 500,
            yB: 288,
            xC: 300,
            yC: 70,
          },
        ],
      },
      {
        id: 2,
        name: "Тест 2",
        imgRef:
          "https://mirhobby.space/uploads/posts/2021-08/1629724953_0-bazovaja-vykrojka-zhenskogo-nizhnego-belja.jpg",
        imgWidth: 807,
        imgHeight: 454,
        attrs: [],
      },
    ]);
  }

  init() {
    // Пока создать заглушку
    // const model: ZModel = {
    //   id: 1,
    //   name: "Тест 1",
    //   imgRef: "https://i.pinimg.com/736x/74/2b/c2/742bc2522d3fd2f5252680a50ecb3dd4.jpg",
    //   imgWidth: 659,
    //   imgHeight: 545,
    //   attrs: [
    //     {key: 1, attrName: "A1", xA: 30, yA: 78, xB: 30, yB: 290},
    //     {key: 2, attrName: "XYZ", xA: 346, yA: 108, xB: 624, yB: 108},
    //     {key: 3, attrName: "Curv", xA: 150, yA: 288, xB: 500, yB: 288, xC: 300, yC: 70},
    //   ],
    // }
    // this.setModelsList([model]);
    // this.setCurModel(model);
    try {
      const text = localStorage[modelsKey];
      if (text) {
        const json = JSON.parse(text);
        const models = zModel.array().parse(json);
        this.setModelsList(models);
      }
    } catch (e) {
      notification.error({ message: e.message });
    }
  }

  get canDelete(): boolean {
    return !!this.curModel;
  }

  deleteCurrent() {
    this.modelsList.splice(this.curModelIndex, 1);
  }

  generateNewModelId(): number {
    const maxId = this.modelsList.reduce((acc, m) => Math.max(acc, m.id), 0);
    return maxId + 1;
  }
}

export const modelsListStore = new ModelsListStore();
