import * as React from "react";
import { VerticalContent } from "src/components/VerticalContent";

interface PropsFieldsWithTitle {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
}

export const FieldsWithTitle: React.FC<PropsFieldsWithTitle> = ({
  children,
  title,
}) => (
  <VerticalContent gap={12}>
    <h3>{title}</h3>
    {children}
  </VerticalContent>
);
FieldsWithTitle.defaultProps = { children: null, title: "" };
