import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import { ZSchedulingSettings } from "../../../SchedulingTypes";
import { SchedulingObjType } from "../../SchedulingSettingsStore";

interface PropsTaskTiesSection {
  tabKey: keyof ZSchedulingSettings;
  objType: SchedulingObjType;
  store: FormObjectSectionsStore<SchedulingObjType>;
}

export const TaskTiesSection: React.FC<PropsTaskTiesSection> = observer(
  ({ tabKey, objType, store }) => {
    const isTaskSection =
      tabKey === "taskSettings" || tabKey === "taskTemplateSettings";
    if (!isTaskSection) return null;
    return (
      <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
        store={store}
        objKey={[tabKey, "relationObjectId"]}
        objType={objType}
        objLabel="Объект для формирования зависимости задач"
        title="Привязки"
        attrs={[
          {
            attrKey: [tabKey, "previousAttributeId"],
            attrLabel: "Укажите атрибут предыдущей задачи",
            attrType: AttrTypeName.object,
          },
          {
            attrKey: [tabKey, "currentAttributeId"],
            attrLabel: "Укажите атрибут текущей задачи",
            attrType: AttrTypeName.object,
          },
          {
            attrKey: [tabKey, "relationTypeAttributeId"],
            attrLabel: "Укажите атрибут типа привязки",
            attrType: AttrTypeName.dictSingle,
          },
          {
            attrKey: [tabKey, "deltaDaysAttributeId"],
            attrLabel: "Укажите атрибут кол-ва дней задержки/опережения",
            attrType: AttrTypeName.int,
          },
        ]}
      />
    );
  },
);
