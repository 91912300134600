import { TreeLikeData } from "src/types/TreeLikeData";
import React from "react";

export const updateNodeRecursively = <TData extends {}>(
  treeData: TreeLikeData<TData>[],
  key: React.Key,
  data: Partial<TreeLikeData<TData>>,
): TreeLikeData<TData>[] =>
  treeData.map((node) => {
    if (node.key === key) {
      // Update the current node with the new data
      return { ...node, ...data };
    }
    if (node.children && node.children.length > 0) {
      // Recursively update descendants if they exist
      return {
        ...node,
        children: updateNodeRecursively(node.children, key, data),
      };
    }
    return node;
  });
