/**
 * Это костыль связан с тем, что в ряде случаев нет никакой информации о состоянии экземпляра.
 * Например, когда только создаётся новый экземпляр.
 * И ничего лучше не придумали кроме как считать его равным 1.
 * В коде такое оставлять не желательно.
 * Поэтому завёрнуто в эту функцию.
 * @see http://jira.d4r.int/browse/LPLM-924
 * @param stateId
 * @returns
 */
export const getValidStateId = (stateId: number | null | undefined): number =>
  typeof stateId === "number" ? stateId : 1;
