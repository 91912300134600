import { ZAttribute } from "src/types/ZAttribute";
import { ZDictGroup } from "src/types/ZGroup";

type GroupAndAttr = {
  group: ZDictGroup;
  attr: ZAttribute;
  attrIndex: number;
};

export const findByAttrId = (
  attrId: number,
  groups: ZDictGroup[],
): GroupAndAttr | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const group of groups) {
    const attr = group.attributes?.find(({ id }) => id === attrId);
    if (attr)
      return {
        group,
        attr,
        attrIndex: group.attributes!.findIndex(({ id }) => id === attrId),
      };
  }
  return undefined;
};
