import { z } from "zod";

const zValue = z.string().nullable();
type ZValue = z.infer<typeof zValue>;

const zId = z.object({ id: z.number() });

const zBomPosField = z.union([
  z.object({ id: z.number(), image: zValue }),
  z.object({ id: z.number(), location: zValue }),
  z.object({ id: z.number(), size: zValue }),
  z.object({ id: z.number(), qualityDetailsManual: zValue }),
  z.object({ id: z.number(), comment: zValue }),
  z.object({ id: z.number(), amount: zValue }),
  z.object({ id: z.number(), supplierManualValue: zValue }),
  z.object({ id: z.number(), articleManualValue: zValue }),
]);

const zBomArticleField = z.union([
  z.object({ id: z.number(), name: zValue }),
  z.object({ id: z.number(), article: zValue }),
  z.object({ id: z.number() }), // приходят битые данные. Пришлось добавить. Иначе ошибка
]);

const zBomSupplierField = z.union([
  zId.and(z.object({ name: zValue })),
  zId.and(z.object({ country: zValue })),
  zId.and(z.object({ address: zValue })),
]);

const zMaterialCategory = z.object({
  dictId: z.number().nullable(),
  fieldId: z.number().nullable(),
  name: z.string().nullable(),
  options: z.object({ dictId: z.number(), name: z.string() }).array(),
});
export type ZMaterialCategory = z.infer<typeof zMaterialCategory>;

const zBomColorFields = z.union([
  z.object({ id: z.number(), image: zValue }),
  z.object({ id: z.number(), name: zValue }),
  z.object({ id: z.number(), article: zValue }),
]);

const zBomPosColorModelField = z.union([
  z.object({ id: z.number(), colorReference: zValue }),
  z.object({ id: z.number(), comment: zValue }),
  z.object({ id: z.number(), colorManual: zValue }),
]);

const zBomPositionColorModel = z.object({
  id: z.number().nullable(), // приходит null
  fields: zBomPosColorModelField.array(),
  color: z.object({
    id: z.number().nullable(),
    fields: zBomColorFields.array(),
  }),
});
type ZBomPositionColorModel = z.infer<typeof zBomPositionColorModel>;

type EdBomPositionColorModel = {
  id: number | null;
  colorReference: EdBomField;
  comment: EdBomField;
  colorManual: EdBomField;
  color: {
    id: number | null;
    image: EdBomField;
    name: EdBomField;
    article: EdBomField;
  };
};
const bomPositionColorModel2ed = (
  src: ZBomPositionColorModel,
): EdBomPositionColorModel => ({
  id: src.id,
  colorReference: findField("colorReference", src.fields, ""),
  comment: findField("comment", src.fields, ""),
  colorManual: findField("colorManual", src.fields, ""),
  color: {
    id: src.color.id,
    image: findField("image", src.color.fields, "color"),
    name: findField("name", src.color.fields, "color"),
    article: findField("article", src.color.fields, "color"),
  },
});

const zBomColorModel = z.object({
  id: z.number(),
  name: z.string(),
  bomPositionColorModel: zBomPositionColorModel,
});
export type ZBomColorModel = z.infer<typeof zBomColorModel>;
export type EdBomColorModel = {
  id: number;
  name: string;
  bomPositionColorModel: EdBomPositionColorModel;
};
const bomColorModel2ed = (src: ZBomColorModel): EdBomColorModel => ({
  id: src.id,
  name: src.name,
  bomPositionColorModel: bomPositionColorModel2ed(src.bomPositionColorModel),
});

const zBomPositionRow = z.object({
  id: z.number(),
  fields: zBomPosField.array(),
  materialCategory: zMaterialCategory,
  article: z.object({
    materialId: z.number().nullable(),
    fields: zBomArticleField.array(),
  }),
  supplier: z.object({
    id: z.number().nullable(),
    fields: zBomSupplierField.array(),
  }),
  colorModels: zBomColorModel.array(),
});
export type ZBomPositionRow = z.infer<typeof zBomPositionRow>;

export const zBomPosition = z.object({
  materialTypeId: z.number(),
  materialTypeName: z.string(),
  bomPositionRows: zBomPositionRow.array(),
});
export type ZBomPosition = z.infer<typeof zBomPosition>;

type EdBomField = { id: number; value: ZValue };
export type EdBomRow = {
  id: number;
  articleManualValue: EdBomField;
  supplierManualValue: EdBomField;
  qualityDetailsManual: EdBomField;
  location: EdBomField;
  image: EdBomField;
  size: EdBomField;
  amount: EdBomField;
  comment: EdBomField;
  article: {
    name: EdBomField;
    article: EdBomField;
  };
  supplier: {
    id: number | null;
    name: EdBomField;
    country: EdBomField;
    address: EdBomField;
  };
  materialCategory: ZMaterialCategory;
  colorModels: EdBomColorModel[];
};
export type EdBomPos = Pick<
  ZBomPosition,
  "materialTypeId" | "materialTypeName"
> & {
  rows: EdBomRow[];
};

const findField = <T extends {}>(
  key: string,
  list: T[],
  prefix: string,
): EdBomField => {
  const res = list.find((field) => key in field);
  if (!res)
    throw Error(`Invalid BOM position structire. Expected ${prefix}${key}`);
  return {
    // @ts-ignore
    id: res.id,
    // @ts-ignore
    value: res[key],
  };
};

const row2ed = (src: ZBomPositionRow, prefix: string): EdBomRow => ({
  id: src.id,
  articleManualValue: findField("articleManualValue", src.fields, prefix),
  supplierManualValue: findField("supplierManualValue", src.fields, prefix),
  qualityDetailsManual: findField("qualityDetailsManual", src.fields, prefix),
  location: findField("location", src.fields, prefix),
  image: findField("image", src.fields, prefix),
  size: findField("size", src.fields, prefix),
  amount: findField("amount", src.fields, prefix),
  comment: findField("comment", src.fields, prefix),
  article: {
    name: findField("name", src.article.fields, `${prefix}.article.`),
    article: findField("article", src.article.fields, `${prefix}.article.`),
  },
  supplier: {
    id: src.supplier.id,
    name: findField("name", src.supplier.fields, `${prefix}.name`),
    country: findField("country", src.supplier.fields, `${prefix}.country`),
    address: findField("address", src.supplier.fields, `${prefix}.address`),
  },
  materialCategory: src.materialCategory,
  colorModels: src.colorModels.map(bomColorModel2ed),
});

export const pos2ed = (
  { materialTypeId, materialTypeName, bomPositionRows }: ZBomPosition,
  prefix: string,
): EdBomPos => ({
  materialTypeId,
  materialTypeName,
  rows: bomPositionRows.map((row, i) => row2ed(row, `${prefix}${i}`)),
});
