import { z } from "zod";

export const zAttrMaskedInput = z.object({
  editor: z.literal("MaskedInput"),
  mask: z.string(),
  addonBefore: z.string().nullable().optional(),
  addonAfter: z.string().nullable().optional(),
});
export type ZAttrMaskedInput = z.infer<typeof zAttrMaskedInput>;

export const makeMaskedInputProps = (src: ZAttrMaskedInput) => ({
  mask: src.mask,
  addonBefore: src.addonBefore ?? undefined,
  addonAfter: src.addonAfter ?? undefined,
});
