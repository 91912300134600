import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import {
  ZMenuItem,
  zMenuItemLight,
} from "src/components/MainFrame/LeftMenu/ZMenuItem";
import { ModelessFormStore } from "src/components/ModelessForm";
import { loadMenu } from "src/components/MainFrame/LeftMenu/loadMenu";
import { rest } from "src/common/rest";
import { apiConfigUrl } from "src/common/apiUrl";
import * as React from "react";
import { findNodeByKey } from "src/common/findNodeByKey";
import { ZObjectItem } from "src/types/ZObjectItem";
import { onError } from "src/common/onError";
import { TreeProps } from "antd";
import {
  loadTranslation,
  saveTranslation,
} from "src/common/api/apiTranslation";
import { flattenItems } from "src/common/flattenItems";
import { createTreeData } from "./utils/createTreeData";
import { MenuTreeItem } from "./Menu2TreeItem";
import { loadObjects } from "../objectsApi";
import { generateNewId } from "./utils/generateNewId";
import { newItemId } from "../Obj2Tab/Obj2Nodes";
import { findMenuItemById } from "./utils/findMenuItemById";
import { findMenuItemOwnerById } from "./utils/findMenuItemOwnerById";
import { edit2MenuItem, EdMenuItem, menuItem2Edit } from "./EdMenuItem";
import { ZRolesGroup } from "../Obj2Tab/roles/roleTypes";
import { loadRoleGroups } from "../Obj2Tab/roles/rolesApi";

type EdRoles = Record<number, string>;

export class Menu2TabStore {
  constructor() {
    this.formStore = new ModelessFormStore(
      (values: unknown) => this.save(values),
      () => {
        this.killChanges();
      },
    );
    makeAutoObservable(this);
  }

  formStore: ModelessFormStore;

  data: RemoteData<ZMenuItem[]> = { status: "none" };

  setData(newData: RemoteData<ZMenuItem[]>) {
    this.data = newData;
  }

  objectData: ZObjectItem[] = [];

  setObjectData(newData: ZObjectItem[]) {
    this.objectData = newData;
  }

  get curNode(): MenuTreeItem | null {
    if (!this.curSelect) return null;
    return findNodeByKey(this.curSelect, this.treeData) ?? null;
  }

  get curItem(): ZMenuItem | null {
    const { data } = this;
    if (!this.curSelect) return null;
    return data.status === "ready"
      ? findMenuItemById(Number(this.curSelect), data.result) ?? null
      : null;
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      const result = await loadMenu();
      const objects = await loadObjects();
      this.setData({ status: "ready", result });
      this.setObjectData(objects);
      const expandedKeys = flattenItems(this.treeData, "children")?.flatMap(
        ({ children, key }) => (children ? [key] : []),
      );
      this.setExpanded(expandedKeys ?? []);
      if (this.roleGroups.length === 0) {
        this.setRoleGroups(await loadRoleGroups());
      }
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  async save(values: unknown) {
    const { data, curItem, curNode, isNew } = this;
    const { translation, menuItemLight } = edit2MenuItem(values as EdMenuItem);
    if (curItem && curNode) {
      const tasks: Promise<unknown>[] = [];

      const validValues = zMenuItemLight
        .omit({ id: true })
        .parse(menuItemLight);
      const { elements, id } = curItem;
      const combined = { elements, id, ...validValues };

      if (data.status === "ready") {
        const itemId = isNew ? generateNewId(data.result) : curItem.id;
        const combinedWithId = {
          ...combined,
          id: itemId,
        };
        this.spliceItem(combinedWithId);
        tasks.push(this.saveMenuData());

        if (translation) {
          tasks.push(
            saveTranslation(curItem.name, {
              value: menuItemLight.name ?? "",
              translations: translation,
            }),
          );
        }

        await Promise.all(tasks);
        this.unsafeSelect(itemId);
      }
    }
  }

  async saveMenuData() {
    const { data } = this;
    if (data.status === "ready") {
      await rest.post(apiConfigUrl("/menu"), data.result);
    }
  }

  spliceItem(insertItem?: ZMenuItem) {
    const { data, curNode, curItem } = this;
    if (data.status === "ready" && curNode && curItem) {
      const result = findMenuItemOwnerById(curItem?.id, data.result);
      if (result) {
        const { owner, index } = result;
        if (owner) {
          owner.elements?.splice(index, 1, ...(insertItem ? [insertItem] : []));
        } else {
          data.result.splice(index, 1, ...(insertItem ? [insertItem] : []));
        }
      }
      this.refreshTree();
    }
  }

  killChanges() {
    const { isNew } = this;
    if (isNew) {
      this.spliceItem();
    }
  }

  get selectedKeys(): React.Key[] {
    const { curSelect } = this;
    return curSelect ? [curSelect] : [];
  }

  get treeData(): MenuTreeItem[] {
    return this.data.status === "ready" ? createTreeData(this.data.result) : [];
  }

  get isNew(): boolean | null {
    const { curItem } = this;
    if (curItem) {
      return curItem.id === newItemId;
    }
    return null;
  }

  refreshTree() {
    if (this.data.status === "ready") {
      this.data.result = [...this.data.result];
    }
  }

  curSelect: React.Key | null = null;

  setCurSelect(key: React.Key | null) {
    this.curSelect = key;
  }

  safeSelect(key: React.Key | null) {
    this.formStore.safeAction(() => this.unsafeSelect(key));
  }

  async unsafeSelect(key: React.Key | null, isNewItem?: boolean) {
    this.setCurSelect(key);
    const { curItem } = this;
    if (curItem) {
      const translation = await loadTranslation(curItem.name);
      const result = menuItem2Edit(curItem, translation.translations);
      this.formStore.safeLoad(result, undefined, isNewItem);
    }
  }

  safeCreateMenuItem(isSub?: boolean) {
    this.formStore.safeAction(() => {
      const { data, curItem, curNode } = this;
      if (data.status === "ready") {
        const newItem: ZMenuItem = {
          id: newItemId,
          name: "",
          viewType: "bigGroup",
          link: { type: "text" },
          roleIds: Object.keys(this.rolesMap).map((rolId) => Number(rolId)),
        };
        if (isSub) {
          if (curItem && curNode) {
            curItem.elements = curItem.elements ?? [];
            curItem.elements.push(newItem);
            this.changeExpand(curNode.key, "add");
          }
        } else {
          data.result.push(newItem);
        }
        this.refreshTree();
        this.unsafeSelect(newItem.id, true);
      }
    });
  }

  // ----- roles

  roleGroups: ZRolesGroup[] = [];

  setRoleGroups(data: ZRolesGroup[]) {
    this.roleGroups = data;
  }

  get rolesMap(): EdRoles {
    const rolesMap: EdRoles = {};
    this.roleGroups
      .flatMap(({ roles }) => roles)
      .forEach(({ roleId, roleName }) => {
        rolesMap[roleId] = roleName;
      });
    return rolesMap;
  }

  // ----- delete

  deleteState: "none" | "show" | "work" = "none";

  setDeleteState(state: "none" | "show" | "work") {
    this.deleteState = state;
  }

  startDeleteCurNode() {
    this.setDeleteState("show");
  }

  async doDeleteCurNode() {
    try {
      const { data } = this;
      if (data.status === "ready") {
        this.setDeleteState("work");
        this.spliceItem();
        await this.saveMenuData();
      }
      this.setCurSelect(null);
      this.formStore.unsafeLoad(null);
      this.setDeleteState("none");
    } catch (e) {
      onError(e);
      this.setDeleteState("show");
    }
  }

  // ----- expanded

  expanded = new Set<React.Key>();

  setExpanded(list: React.Key[]) {
    this.expanded = new Set(list);
  }

  changeExpand(key: React.Key, action: "add" | "delete" | "toggle") {
    let realAction = action;
    if (action === "toggle") {
      realAction = this.expanded.has(key) ? "delete" : "add";
    }
    if (realAction === "delete") {
      this.expanded.delete(key);
    } else {
      this.expanded.add(key);
    }
  }

  get expandedKeys(): React.Key[] {
    return Array.from(this.expanded);
  }

  onExpand(keys: React.Key[]) {
    this.setExpanded(keys);
  }

  // ----- draggable

  dragNode: ZMenuItem | null = null;

  setDragNode(dragKey: React.Key) {
    const { data } = this;

    if (data.status === "ready") {
      const result = findMenuItemOwnerById(Number(dragKey), data.result);

      if (result) {
        const { owner, index } = result;
        if (owner) {
          this.dragNode = owner.elements?.splice(index, 1)[0] ?? null;
        } else {
          const [removedNode] = data.result.splice(index, 1);
          this.dragNode = removedNode ?? null;
        }
      }
    }
  }

  addDragNode(dropKey: React.Key, position: number) {
    const { data, dragNode } = this;
    const dropId = Number(dropKey);
    if (data.status === "ready") {
      const result = findMenuItemOwnerById(dropId, data.result);

      if (result && dragNode) {
        const { index, owner, item } = result;
        if (position === 0) {
          item.elements = item.elements || [];
          item.elements.unshift(dragNode);
          this.changeExpand(dropKey, "add");
        } else if (index !== -1) {
          const targetArray = owner?.elements || data.result;
          targetArray.splice(position === -1 ? index : index + 1, 0, dragNode);
        }
      }
    }
  }

  onDrop: TreeProps["onDrop"] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const { dropPosition } = info;
    const { dropToGap } = info;

    if (this.data.status === "ready") {
      this.setDragNode(dragKey);
      if (dropToGap) {
        this.addDragNode(dropKey, dropPosition);
      } else {
        this.addDragNode(dropKey, 0);
      }
      this.refreshTree();
      this.saveMenuData();
    }
  };
}
