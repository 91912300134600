import { TableResponse } from "src/components/tables/TableStore";
import { apiAuditUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import {
  AuditEntityStateFilters,
  ZAuditEntityState,
  zAuditEntityStateResponse,
  zAuditUserFilter,
  ZAuditUserFilter,
} from "./EntityStatesHistory.types";
import { ParamsLoadHistory, loadEntityHistory } from "../apiEntityHistory";

export const loadEntityStatesHistory = async (
  params: ParamsLoadHistory<AuditEntityStateFilters>,
): Promise<TableResponse<ZAuditEntityState>> =>
  loadEntityHistory("/entity-states", params, zAuditEntityStateResponse);

export const loadStatesUserFilter = async (
  id: number,
): Promise<ZAuditUserFilter[]> => {
  const resp = await rest.get(apiAuditUrl(`/entity-states-users/${id}`));
  return zAuditUserFilter.array().parse(resp.data);
};
