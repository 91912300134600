import {
  LeftOutlined,
  PictureOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, UploadFile, Spin } from "antd";
import React from "react";
import { classNames } from "src/common/classNames";
import styles from "./ImageCarouselScroll.module.less";

interface PropsImageCarouselScroll {
  fileList: UploadFile[];
  onImageSelect: (index: number) => void;
  currentImageIndex: number;
}

export const ImageCarouselScroll: React.FC<PropsImageCarouselScroll> = (
  props,
) => {
  const { fileList, currentImageIndex, onImageSelect } = props;

  const scrollRef = React.useRef<HTMLDivElement | null>(null);

  const scrollToImage = (index: number) => {
    const carousel = scrollRef.current;
    if (!carousel) return;
    const selectedImage = carousel.children[index] as HTMLElement;
    if (!selectedImage) return;
    // eslint-disable-next-line no-unsafe-optional-chaining
    const offset = selectedImage?.offsetLeft - carousel.offsetLeft;
    carousel.scrollTo({
      left: offset,
      behavior: "smooth",
    });
  };

  const handlePrevClick = () => {
    if (currentImageIndex > 0) {
      onImageSelect(currentImageIndex - 1);
      scrollToImage(currentImageIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentImageIndex < fileList.length - 1) {
      onImageSelect(currentImageIndex + 1);
      scrollToImage(currentImageIndex + 1);
    }
  };

  return (
    <div className={styles.imageScrollContainer}>
      <Button
        className={styles.controlButton}
        icon={<LeftOutlined />}
        onClick={handlePrevClick}
        disabled={currentImageIndex === 0}
      />
      <div className={styles.imageScrollWrapper}>
        <div className={styles.imageScroll} ref={scrollRef}>
          {fileList?.map(({ url, thumbUrl, status, uid }, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={uid}
              className={classNames([
                styles.imageItem,
                [currentImageIndex === index, styles.imageItemActive],
                [status === "error", styles.imageItemError],
              ])}
              onClick={() => onImageSelect(index)}
            >
              {status === "done" && <img src={url || thumbUrl} alt="" />}
              {status === "uploading" && <Spin />}
              {status === "error" && (
                <PictureOutlined className={styles.imageItemErrorIcon} />
              )}
            </div>
          ))}
        </div>
      </div>
      <Button
        className={styles.controlButton}
        icon={<RightOutlined />}
        onClick={handleNextClick}
        disabled={currentImageIndex === fileList.length - 1}
      />
    </div>
  );
};
