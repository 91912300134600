import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { MCObjType } from "../MChartSettingsStore";
import { ZMesChartSettings } from "../../ZMesChartSettings";

interface PropsSizeLinesSection {
  store: FormObjectSectionsStore<MCObjType>;
}

export const SizeLinesSection: React.FC<PropsSizeLinesSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZMesChartSettings, MCObjType>
      store={store}
      objKey={["sizeLinesObjectId"]}
      objType="SizeLines"
      objLabel="Size lines object"
      title="Size lines"
      attrs={[
        {
          attrKey: ["sizeLinesNameAttrId"],
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["sizeLinesSizeLineSizeAttrId"],
          attrLabel: "Size line sizes",
          attrType: AttrTypeName.childEntities,
        },
      ]}
    />
  ),
);
