import z from "zod";

export const zBsRegSettings = z.object({
  url: z.string().nullable(),
  roleId: z.number().nullable(),
  stateId: z.number().nullable(),
  successMessage: z.string().nullable(),
  isAutoCreate: z.boolean().nullable(), // Это поле больше не используется, но оставлено для совместимости.
  createUserRoleId: z.number().nullable(),
});

export type ZBsRegSettings = z.infer<typeof zBsRegSettings>;
