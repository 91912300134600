import * as React from "react";
import { t } from "i18next";
import { Button, Form, notification } from "antd";
import { EdCardValues } from "src/pages/EntityCardPage/apiEntityCard";
import { observer } from "mobx-react-lite";
import { DrawItems } from "src/components/DrawItems";
import { onError } from "src/common/onError";
import { UserAccountPageStore } from "../userAccountPageStore";
import styles from "./UserEdit.module.less";

interface PropsUserEdit {
  store: UserAccountPageStore;
}

export const UserEdit: React.FC<PropsUserEdit> = observer(({ store }) => {
  const [form] = Form.useForm();
  const { userRootBlock, formStore, initialData } = store;
  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [initialData]);
  if (!userRootBlock) return null;
  const onSubmit = async (values: EdCardValues) => {
    store
      .submitInfo(values)
      .then((ok) => {
        if (ok) notification.success({ message: t("Data updated") });
      })
      .catch(onError);
  };
  return (
    <Form layout="vertical" onFinish={onSubmit} form={form}>
      <div className={styles.block}>
        <DrawItems
          items={userRootBlock.items ?? []}
          ctx={{ store: formStore, prevName: [] }}
        />
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={formStore.saving}>
          {t("Save")}
        </Button>
      </Form.Item>
    </Form>
  );
});
