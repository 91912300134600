import React from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { Spin, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { AttrLabelTreeData } from "src/common/attributes/composeAttrLabel2/AttrLabelTreeData";
import { walk } from "src/components/TreeStd";
import { useAttrLabels } from "src/common/attributes/composeAttrLabel2/useAttrLabels";
import { AttrLabelBuilderCtx } from "src/common/attributes/composeAttrLabel2";
import { LabelDef } from "../../viewFormItems/Separator";

const createChildEntityLabel = (data: AttrLabelTreeData) => {
  const labels: LabelDef[] = [];
  walk(
    (node) => {
      if (node.children) return;
      if (node.recursion) {
        labels.push({
          content: (
            <Tag color="red">recursion on {node.attr.name} attribute</Tag>
          ),
          key: node.key,
        });
        return;
      }
      let increment = 1;
      labels.push(
        ...node.labels.map((content) => ({
          content,
          // eslint-disable-next-line no-plusplus
          key: `${node.key}_${increment++}`,
        })),
      );
    },
    [data],
  );
  return labels;
};

type PropsChildEntitiesCellInfo2 = {
  attribute: ZAttribute;
  ctx?: AttrLabelBuilderCtx;
};

export const ChildEntitiesCellInfo2: React.FC<PropsChildEntitiesCellInfo2> =
  observer(({ attribute, ctx }) => {
    const [labels, wait] = useAttrLabels(
      createChildEntityLabel,
      [],
      attribute,
      ctx,
    );
    if (wait) return <Spin spinning>...</Spin>;
    return labels;
  });

ChildEntitiesCellInfo2.defaultProps = {
  ctx: undefined,
};
