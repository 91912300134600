import { CSSProperties } from "react";

export const getColumnWidth = (
  columnWidths: Record<string, number | string>,
  key: string,
) => columnWidths[key];

export const getColumnWidthStyles = (
  columnWidths: Record<string, number | string>,
  key: string,
): CSSProperties => ({
  maxWidth: getColumnWidth(columnWidths, key),
  minWidth: getColumnWidth(columnWidths, key),
});
