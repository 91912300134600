// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__container--G2Oqc {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
  background-color: #FFFFFF;
  height: 100%;
  row-gap: 24px;
}
.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__timeline--Wp546 {
  padding: 5px 0;
  height: 100%;
  overflow-y: auto;
}
.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__filters--TX8Oa .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__filters--TX8Oa .ant-btn {
  display: none;
}
.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__filters--TX8Oa > div > div {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
}
.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__filters--TX8Oa .ant-form-item-control {
  width: 100%;
}
.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__pagination--OxmrP {
  padding: 16px 32px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #F5F5F5;
}
.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__header--D3upp {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__title--JoEyD {
  font-weight: 700;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityHistory/TimelineFacade/TimelineFacade.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;AACF;AAEA;EACE,cAAA;EACA,YAAA;EACA,gBAAA;AAAF;AAGA;EAEI,aAAA;EACA,sBAAA;EACA,kBAAA;AAFJ;AAFA;EAQI,aAAA;AAHJ;AAME;EACE,wBAAA;EACA,qCAAA;AAJJ;AATA;EAiBI,WAAA;AALJ;AASA;EACE,oBAAA;EACA,aAAA;EACA,uBAAA;EACA,6BAAA;AAPF;AAUA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AARF;AAWA;EACE,gBAAA;EACA,eAAA;AATF","sourcesContent":[".container {\n  overflow-y: hidden;\n  display: flex;\n  flex-direction: column;\n  padding: 20px 32px;\n  background-color: #FFFFFF;\n  height: 100%;\n  row-gap: 24px;\n}\n\n.timeline {\n  padding: 5px 0;\n  height: 100%;\n  overflow-y: auto;    \n}\n\n.filters {\n  :global(.ant-form-item-row) {\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n  }\n\n  :global(.ant-btn) {\n    display: none;\n  }\n\n  & > div > div {\n    display: grid !important;\n    grid-template-columns: repeat(3, 1fr);\n  }\n\n  :global(.ant-form-item-control) {\n    width: 100%;\n  }  \n}\n\n.pagination {\n  padding: 16px 32px 0;\n  display: flex;\n  justify-content: center;\n  border-top: 1px solid #F5F5F5;\n}\n\n.header {\n  display: flex;\n  flex-direction: column;\n  row-gap: 20px;\n}\n\n.title {\n  font-weight: 700;\n  font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__container--G2Oqc`,
	"timeline": `src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__timeline--Wp546`,
	"filters": `src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__filters--TX8Oa`,
	"pagination": `src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__pagination--OxmrP`,
	"header": `src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__header--D3upp`,
	"title": `src-pages-EntityCardPage-EntityHistory-TimelineFacade-TimelineFacade-module__title--JoEyD`
};
export default ___CSS_LOADER_EXPORT___;
