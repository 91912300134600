import * as React from "react";
import { z } from "zod";
import { Rule } from "rc-field-form/lib/interface";
import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";
import {
  ZTreeCategoryNode,
  zTreeCategoryNode,
} from "src/types/ZTreeCategoryNode";
import {
  getCatNodesFull,
  loadCategoriesTree,
} from "src/common/references/categories";
import { RemoteData } from "src/common/RemoteData";
import { EventDataNode } from "antd/lib/tree";
import { appStore } from "src/appStore";

export type CatNode = {
  id: string;
  key: string;
  parent: string | null;
  title: string;
  children?: CatNode[];
  isLeaf: boolean;
  icon?: React.ReactNode;
  data: ZTreeCategoryNode;
  disabled?: boolean;
  order: number;
};
export type CatNodeFindKey = "id" | "key";

export interface AddCatSubmitParams {
  parentId: number;
  name: string;
  key: string;
  selectable: boolean;
}

export type UpdateCatParams = {
  id: string;
  name: string;
  selectable: boolean;
  order: number;
};
export interface DeleteCatSubmitParams {
  newProdOwner?: string;
  newParentId?: string;
  node: CatNode;
}

export type InfoAboutDelete = {
  usedByCommodities: boolean;
};

export type NodesLoader = () => Promise<CatNode[]>;

export class TreeStore {
  constructor() {
    makeAutoObservable(this);
  }

  treeData: CatNode[] = [];

  setTreeData(data: CatNode[]) {
    this.treeData = data;
  }

  init() {
    this.setLoading(true);
    return getCatNodesFull(appStore.currentCompanyId)
      .then((nodes) => {
        this.setTreeData(nodes);
      })
      .catch(onError)
      .finally(() => this.setLoading(false));
  }

  reload() {
    const curKey = this.current?.key;
    return this.init().then(() => {
      if (curKey) {
        this.setCurrent(this.findNode(curKey, "key") ?? null);
      }
    });
  }

  findNode(value: string, key: CatNodeFindKey): CatNode | undefined {
    return findNode(this.treeData, value, key);
  }

  loading = false;

  setLoading(newValue: boolean) {
    this.loading = newValue;
  }

  current: CatNode | null = null;

  setCurrent(node: CatNode | null) {
    this.current = node;
  }

  get selectedKeys(): string[] {
    return this.current ? [this.current.key] : [];
  }

  expandedKeys: string[] = [];

  setExpandedKeys(keys: string[]) {
    this.expandedKeys = keys;
  }

  expandByKey(key: string, open: boolean) {
    const pos = this.expandedKeys.findIndex((item) => item === key);
    if (open && pos < 0) {
      this.setExpandedKeys([...this.expandedKeys, key]);
    } else if (!open && pos >= 0) {
      const keys = [...this.expandedKeys];
      keys.splice(pos, 1);
      this.setExpandedKeys(keys);
    }
  }

  onSelect(node: CatNode) {
    this.setCurrent(node);
  }

  onExpand(keys: string[]) {
    this.setExpandedKeys(keys);
  }

  get canAdd(): boolean {
    return !this.loading;
  }

  get canEdit(): boolean {
    return !!this.current && !this.loading;
  }

  get canDelete(): boolean {
    return !!this.current && !this.loading;
  }

  async addCategory(params: AddCatSubmitParams) {
    const resp = await rest.post("/srm-service/api/category", {
      ...params,
      order: 0,
    });
    const res = zTreeCategoryNode.parse(resp.data);
    await this.reload();
    const node = this.findNode(String(res.id), "id");
    if (node) {
      this.setCurrent(node);
      this.expandRecursive(node.key);
    }
  }

  needUpdateCurrentNode(newData: ZTreeCategoryNode): boolean {
    const { current } = this;
    return (
      !current ||
      newData.name !== current.data.name ||
      newData.selectable !== current.data.selectable ||
      newData.order !== current.data.order
    );
  }

  updateCurrentNode(newData: ZTreeCategoryNode) {
    const { current } = this;
    if (current) {
      const updatedNode = {
        ...current,
        title: newData.name,
        data: newData,
        order: newData.order,
      };
      const parent = current.parent
        ? this.findNode(current.parent, "id")
        : undefined;
      const nodes: CatNode[] = parent
        ? [...(parent.children || [])]
        : [...this.treeData];
      const pos = nodes.findIndex(({ key }) => key === updatedNode.key);
      if (pos < 0) {
        nodes.push(updatedNode);
      } else {
        nodes[pos] = updatedNode;
      }
      if (parent) {
        parent.children = nodes;
        this.setTreeData([...this.treeData]);
      } else {
        this.setTreeData(nodes);
      }
      this.setCurrent(updatedNode);
    }
  }

  async moveCurrentNode(parentId: string) {
    const { current } = this;
    if (current) {
      const updatedNode = { ...current };
      this.deleteNode(current);
      const newParent = this.findNode(parentId, "id");
      if (newParent) {
        await this.expandRecursive(newParent.key);
        const expParent = this.findNode(parentId, "id");
        if (expParent) {
          this.appendNode(expParent, updatedNode);
          this.setCurrent(updatedNode);
        }
      }
    }
  }

  async onDrop(
    srcNode: EventDataNode<CatNode>,
    dstNode: EventDataNode<CatNode>,
  ) {
    this.setLoading(true);
    try {
      const cat2Update = (cat: CatNode): UpdateCatParams => ({
        id: cat.id,
        order: cat.order,
        name: cat.data.name,
        selectable: !!cat.data.selectable,
      });
      const srcParentId = srcNode.data.parentId;
      // перемещать элемент можно только в пределах родителя
      const canDrop = srcParentId === dstNode.data.parentId;
      if (canDrop) {
        const list = srcParentId
          ? this.findNode(String(srcParentId), "id")?.children
          : this.treeData;
        if (!list) return;
        // для обработки случая, когда элемент был перемещен над первым в списке
        const dropOver = dstNode.dragOverGapTop;
        const getIndex = () => {
          if (dropOver) return 0;
          return list.findIndex((item) => item.id === dstNode.id);
        };
        const other = list.filter((item) => item.id !== srcNode.id);
        const insertIndex = getIndex();
        other.splice(insertIndex, 0, srcNode);
        await rest.put(
          "/srm-service/api/category",
          other.map((item, order) => cat2Update({ ...item, order })),
        );
        await this.reload();
      }
    } catch (e) {
      onError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async editCategory(newNode: ZTreeCategoryNode) {
    const { current } = this;
    if (current) {
      const needMove = newNode.parentId !== current.data.parentId;
      if (needMove) {
        await rest.put(
          `/srm-service/api/category/${newNode.id}/move?parentId=${newNode.parentId}`,
        );
        await this.moveCurrentNode(String(newNode.parentId));
      }
      if (this.needUpdateCurrentNode(newNode)) {
        const resp = await rest.put(`/srm-service/api/category/${newNode.id}`, {
          name: newNode.name,
          selectable: newNode.selectable,
          order: newNode.order,
        });
        const resNode = zTreeCategoryNode.parse(resp.data);
        this.updateCurrentNode(resNode);
      }
      this.reload();
    }
  }

  async expandRecursive(dstNodeKey?: string): Promise<void> {
    const parents = this.getAllParents(dstNodeKey);
    parents.reverse();
    const parentKeys = parents.map(({ key }) => key);
    const expSet = new Set<string>([...this.expandedKeys, ...parentKeys]);
    this.setExpandedKeys(Array.from(expSet));
  }

  /* eslint-disable no-param-reassign */

  async appendNode(parent: CatNode, newNode: CatNode) {
    newNode.parent = parent.key;
    newNode.data.parentId = parent.data.id;

    if (parent.children) {
      const children = [...parent.children];
      const pos = children.findIndex(({ key }) => key === newNode.key);
      if (pos < 0) {
        children.push(newNode);
      } else {
        children[pos] = newNode;
      }
      parent.children = children;
    } else {
      parent.children = [newNode];
    }
    parent.isLeaf = false;
    parent.data.hasChildren = true;
    this.setTreeData([...this.treeData]);
    this.expandByKey(parent.key, true);
    this.setCurrent(newNode);
  }

  deleteNode(node: CatNode) {
    const parent = !node.parent ? undefined : this.findNode(node.parent, "id");
    this.setCurrent(parent ?? null);
    if (parent && parent.children) {
      const pos = parent.children.findIndex(({ key }) => key === node.key);
      if (pos >= 0) {
        parent.children.splice(pos, 1);

        if (parent.children.length === 0) {
          parent.data.hasChildren = false;
          parent.isLeaf = true;
        }

        this.setTreeData([...this.treeData]);
      }
    } else {
      const pos = this.treeData.findIndex(({ key }) => key === node.key);
      if (pos >= 0) {
        const list = [...this.treeData];
        list.splice(pos, 1);
        this.setTreeData(list);
      }
    }
  }

  infoAboutDelete: RemoteData<InfoAboutDelete> = { status: "none" };

  setInfoAboutDelete(info: RemoteData<InfoAboutDelete>) {
    this.infoAboutDelete = info;
  }

  async loadDeleteInfo(node: CatNode) {
    this.setInfoAboutDelete({ status: "wait" });
    try {
      await loadCategoriesTree(appStore.currentCompanyId);
      const resp = await rest.get(`/srm-service/api/supplier-products/search`, {
        params: { page: 0, size: 10, categories: node.key },
      });
      const Schema = z.object({
        totalElements: z.number(),
      });
      const rawResult = Schema.parse(resp.data);
      const result: InfoAboutDelete = {
        usedByCommodities: rawResult.totalElements > 0,
      };

      this.setInfoAboutDelete({ status: "ready", result });
    } catch (error) {
      this.setInfoAboutDelete({ status: "error", error });
    }
  }

  async deleteCategory({
    node,
    newParentId,
    newProdOwner,
  }: DeleteCatSubmitParams): Promise<void> {
    const params: Record<string, string> = {};
    if (newProdOwner) params.newCategoryIdForProducts = newProdOwner;
    if (newParentId) params.newCategoryIdForChildren = newParentId;
    await rest.delete(`/srm-service/api/category/${node.id}`, { params });
    this.reload();
  }

  /**
   * Получить всех владельцев для указанного узла
   * @param key
   * @return Список узлов. Первым идет узел с указанным key. Последний - самый верхний узел в дереве
   */
  getAllParents(key: string | null | undefined): CatNode[] {
    const nodes: CatNode[] = [];
    let curKey: string | null | undefined = key;

    // первый поиск делаем по key, потому что в параметрах
    // приходит ключ категории
    let node = this.findNode(curKey || "", "key");
    if (node) {
      nodes.push(node);
      curKey = node.parent;
    }

    // далее в цикле ищем уже по id, потому что node.parent - это id
    while (curKey) {
      node = this.findNode(curKey, "id");
      if (!node) break;
      nodes.push(node);
      curKey = node.parent;
    }

    return nodes;
  }

  makeDeleteRule(curNode: CatNode): Rule {
    return {
      validator: (o, value) => {
        if (curNode.key === value) {
          return Promise.reject(
            Error(`Эта категория выбрана для удаления. Выберите другую.`),
          );
        }
        const parents = this.getAllParents(value);
        if (parents.find((node) => node.key === curNode.key)) {
          return Promise.reject(
            Error(`Нельзя выбрать категорию, которая вложена в удаляемую`),
          );
        }
        return Promise.resolve();
      },
    };
  }
}

export const updateTree = (
  oldNodes: CatNode[],
  dstKey: string,
  newNodes: CatNode[],
): CatNode[] =>
  oldNodes.map((node) => {
    if (node.key === dstKey)
      return {
        ...node,
        children: newNodes,
      };
    return node.children
      ? {
          ...node,
          children: updateTree(node.children, dstKey, newNodes),
        }
      : node;
  });

export const findNode = (
  nodes: CatNode[],
  value: string,
  key: CatNodeFindKey,
): CatNode | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of nodes) {
    if (node[key] === value) return node;
    if (node.children) {
      const res = findNode(node.children, value, key);
      if (res) return res;
    }
  }
  return undefined;
};
