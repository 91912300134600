import { rest } from "src/common/rest";
import { apiExtRegUrl } from "src/common/apiUrl";
import { zBsRegSettings, ZBsRegSettings } from "./bsRegistration.types";

export const bsRegSettingsUrl = (id: number) => apiExtRegUrl(`/settings/${id}`);

export const loadBsRegSettings = async (
  id: number,
): Promise<ZBsRegSettings> => {
  const resp = await rest.get(bsRegSettingsUrl(id));
  return zBsRegSettings.parse(resp.data);
};

export const saveBsRegSettings = async (
  id: number,
  settings: ZBsRegSettings,
) => {
  await rest.post(bsRegSettingsUrl(id), settings);
};
