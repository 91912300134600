// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-CheckList-CheckList-module__layout--eCcYm {
  max-height: 100%;
  overflow: auto;
}
.src-components-CheckList-CheckList-module__item--AgUgE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
}
.src-components-CheckList-CheckList-module__item--AgUgE:hover {
  background-color: #f7f7f7;
}
.src-components-CheckList-CheckList-module__itemActive--T2l26 {
  background-color: #ffeeeb;
}
`, "",{"version":3,"sources":["webpack://./src/components/CheckList/CheckList.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AAAF;AACE;EACE,yBAAA;AACJ;AAGA;EACE,yBAAA;AADF","sourcesContent":[".layout {\n  max-height: 100%;\n  overflow: auto;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 24px;\n  &:hover {\n    background-color: #f7f7f7;\n  }\n}\n\n.itemActive {\n  background-color: #ffeeeb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `src-components-CheckList-CheckList-module__layout--eCcYm`,
	"item": `src-components-CheckList-CheckList-module__item--AgUgE`,
	"itemActive": `src-components-CheckList-CheckList-module__itemActive--T2l26`
};
export default ___CSS_LOADER_EXPORT___;
