import { rest } from "src/common/rest";
import {
  ZEntity,
  zEntity,
  ZEntityField,
  zEntityField,
} from "src/types/ZEntity";
import {
  ZAttribute,
  ZAttrLinkedData,
  zAttrLinkedData,
} from "src/types/ZAttribute";
import { apiAdminUrl, apiObjUrl } from "src/common/apiUrl";
import { ZGroup } from "src/types/ZGroup";
import { optionalLangParams } from "src/lang/langHdr";
import { ZObjectItem, zObjectItem } from "../../types/ZObjectItem";
import {
  ZEntityAvailableState,
  zEntityAvailableState,
} from "./EntityPage.types";

export type EdValue = string[] | string | null | undefined;
// Поля формы в виде ассоциативного массива String(attributeId) => value
export type EdCardValues = Record<string, EdValue>;

export const makeFieldName = (attrId: number) => String(attrId);

const hardValue = (
  value: string[] | string | null | undefined,
): string[] | null => {
  if (Array.isArray(value)) return value;
  if (typeof value === "string") return [value];
  if (typeof value === "number" || typeof value === "boolean")
    return [String(value)];
  return null;
};

export const ed2entity = (
  ed: EdCardValues,
  entityId: number,
  objectId: number,
): ZEntity => ({
  id: entityId,
  objectId,
  attributeValues: Object.entries(ed)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => ({
      attributeId: +key,
      values: hardValue(value),
    })),
});

export const loadObject = async (
  objectId: number | string,
  options: {
    stateId?: number | null;
    translate?: boolean;
  } = {},
) => {
  const { stateId, translate } = options;
  let url = apiObjUrl(`/objects/${objectId}`);
  if (stateId) url += `/${stateId}`;
  const resp = await rest.get(url, optionalLangParams(translate));
  return zObjectItem.parse(resp.data);
};

export const getAttributesFromObject = (
  object: ZObjectItem,
): Record<number, ZAttribute> => {
  const attrMap: Record<number, ZAttribute> = {};
  const addAtributes = (attrs?: ZAttribute[] | null) =>
    attrs?.forEach((a) => {
      attrMap[a.id] = a;
    });
  addAtributes(object.attributes);
  const onGroup = (group: ZGroup) => {
    addAtributes(group.attributes);
    group.groups?.forEach(onGroup);
  };
  object.groups?.forEach(onGroup);
  return attrMap;
};

/**
 * Форматом для хранения значения поля на сервере является string[]
 * Раньше была попытка конвертировать в формат соответствующего компонента.
 * Но для этого нужно знать описание атрибута. А например атрибуты внутри зависимых групп подгружаются уже после создания формы.
 * Поэтому теперь форма хранит данные в таком же формате, как и сервер.
 * Но для каждого компонента нужен proxy, который конвертирует формат на лету. Они собраны в fieldComponents
 * @param entity
 * @returns
 */
export const entity2ed = (entity: ZEntity): EdCardValues =>
  entity.attributeValues.reduce(
    (acc, { attributeId, values }) => ({
      ...acc,
      [makeFieldName(attributeId)]: values,
    }),
    {},
  );

export const createEntity = async (entity: ZEntity): Promise<ZEntity> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...other } = entity;
  const resp = await rest.post(apiObjUrl("/entities"), other);
  return zEntity.parse(resp.data);
};

export const createEntitiesByIterator = async (
  entity: Omit<ZEntity, "id">,
): Promise<ZEntity[]> => {
  const resp = await rest.post(apiObjUrl("/entities/iterator"), entity);
  return zEntity.array().parse(resp.data);
};

export const loadEntity = async (
  entityId: number | string,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZEntity> => {
  const resp = await rest.get(
    apiObjUrl(`/entities/${entityId}`),
    optionalLangParams(options.translate),
  );
  return zEntity.parse(resp.data);
};

export const saveEntity = async (entity: ZEntity): Promise<ZEntity> => {
  const resp = await rest.put(apiObjUrl(`/entities/${entity.id}`), entity);
  return zEntity.parse(resp.data);
};

export const calculateEntityFormulas = async (
  entity: ZEntity,
): Promise<ZEntityField[]> => {
  const resp = await rest.post(apiObjUrl(`/formulas/calculate`), entity);
  return zEntityField.array().parse(resp.data);
};

export const validateEntityFormula = async (
  formula: string,
  objectId: number,
) =>
  rest.get(apiObjUrl(`/formulas/validate`), {
    params: { formula, objectId },
  });

export const deleteEntity = async (entityId: number) => {
  await rest.delete(apiObjUrl(`/entities/${entityId}`));
};

export const loadAvailableStates = async (
  objectId: number,
  stateId: number,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZEntityAvailableState[]> => {
  const resp = await rest.get(apiAdminUrl("/states/available"), {
    params: { objectId, stateId },
    ...optionalLangParams(options.translate),
  });
  return zEntityAvailableState.array().parse(resp.data);
};

export const callChangeEntityState = async (
  entityId: number,
  stateId: number,
) => {
  await rest.put(apiObjUrl(`/entities/${entityId}/state`), { stateId });
};

export const getEntityLinkedValues = async (
  data: Omit<ZAttrLinkedData, "attribute" | "values">[],
) => {
  const resp = await rest.post(apiObjUrl(`/entities/linked-values`), data);
  return zAttrLinkedData.array().parse(resp.data);
};
