import { Button, ButtonProps } from "antd";
import React from "react";
import styles from "./DropdownChildButton.module.less";

type PropsDropdownChildButton = Omit<ButtonProps, "className">;

export const DropdownChildButton: React.FC<PropsDropdownChildButton> = ({
  icon,
  ...props
}) => (
  <Button
    {...props}
    className={styles.button}
    icon={<div className={styles.icon}>{icon}</div>}
  />
);
