import * as React from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  LoadingOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { t } from "i18next";

export type McMoreToolsData = {
  copyToModel: {
    disabled: boolean;
    action(): void;
  };
  reset: {
    disabled: boolean;
    loading: boolean;
    action(): void;
  };
};

interface PropsMcMoreTools {
  data: McMoreToolsData;
}

export const McMoreTools: React.FC<PropsMcMoreTools> = ({ data }) => {
  const items: MenuProps["items"] = [
    {
      key: "copyToModel",
      icon: <CopyOutlined />,
      label: t("Copy to another model"),
      disabled: data.copyToModel.disabled,
      onClick: data.copyToModel.action,
    },
    {
      key: "reset",
      icon: data.reset.loading ? <LoadingOutlined /> : <DeleteOutlined />,
      danger: true,
      label: t("Reset"),
      disabled: data.reset.disabled,
      onClick: data.reset.action,
    },
  ];
  return (
    <Dropdown menu={{ items }}>
      <Button icon={<MoreOutlined />} />
    </Dropdown>
  );
};
