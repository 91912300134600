import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import {
  loadMesChartSettings,
  saveMesChartSettings,
} from "./apiMeasurementChart";
import { MChartSettings } from "./MChartSettings";
import { ZMesChartSettings, zMesChartSettings } from "./ZMesChartSettings";
import { MChartEntity } from "./MChartEntity";

export const bsMeasurementChart: BusinessServiceDescriptor = {
  key: "Measurement chart",
  viewer(info, { entity }) {
    if (!entity) return null;
    return <MChartEntity serviceObjectId={info.id} modelId={entity.id} />;
  },
  renderSettings(_info, store) {
    return <MChartSettings typesMap={store.attrTypesDict} />;
  },
  async loadSettings(info) {
    return loadMesChartSettings(info.id);
  },
  async saveSettings(info, values) {
    const data: ZMesChartSettings = {
      mcServiceId: info.id,
      objectId: info.objectId,
      ...zMesChartSettings
        .omit({ mcServiceId: true, objectId: true })
        .parse(values),
    };
    await saveMesChartSettings(info.id, data);
  },
};
