// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__form--x1aAQ {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow: hidden;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstPart--WTuGl {
  display: flex;
  gap: 12px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstLeft--PjA7l {
  flex: 1;
  display: flex;
  gap: 8px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstLeft--PjA7l .ant-form-item {
  flex: 1;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstRight--ZU37w {
  width: 300px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__textarea--zGnFX {
  resize: none !important;
  height: 130px !important;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__tableBox--jDYYi {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow: hidden;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__colorFormItem--ec5Df {
  flex: 1;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__colorFormItem--ec5Df .ant-form-item-row,
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__colorFormItem--ec5Df .ant-form-item-control-input,
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__colorFormItem--ec5Df .ant-form-item-control-input-content {
  height: 100%;
  overflow: hidden;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__colorFormItem--ec5Df .ant-form-item-row {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/Bom2Table/BomColorCell/BomColorModal.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACA;EACI,aAAA;EACA,SAAA;AACJ;AACA;EACI,OAAA;EACA,aAAA;EACA,QAAA;AACJ;AAJA;EAKQ,OAAA;AAER;AACA;EACI,YAAA;AACJ;AACA;EACI,uBAAA;EACA,wBAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACA;EACI,OAAA;AACJ;AAFA;;;EAMQ,YAAA;EACA,gBAAA;AACR;AARA;EAUQ,aAAA;EACA,sBAAA;AACR","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    height: 100%;\n    overflow: hidden;\n}\n.firstPart {\n    display: flex;\n    gap: 12px;\n}\n.firstLeft {\n    flex: 1;\n    display: flex;\n    gap: 8px;\n    :global(.ant-form-item) {\n        flex: 1;\n    }\n}\n.firstRight {\n    width: 300px;\n}\n.textarea {\n    resize: none !important;\n    height: 130px !important;\n}\n.tableBox {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    height: 100%;\n    overflow: hidden;\n}\n.colorFormItem {\n    flex: 1;\n    // Чтобы таблица заняла всю высоту нужно все контейнеры сделать 100% по высоте:\n    :global(.ant-form-item-row),\n    :global(.ant-form-item-control-input),\n    :global(.ant-form-item-control-input-content) {\n        height: 100%;\n        overflow: hidden;\n    }\n    :global(.ant-form-item-row) {\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__form--x1aAQ`,
	"firstPart": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstPart--WTuGl`,
	"firstLeft": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstLeft--PjA7l`,
	"firstRight": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__firstRight--ZU37w`,
	"textarea": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__textarea--zGnFX`,
	"tableBox": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__tableBox--jDYYi`,
	"colorFormItem": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomColorCell-BomColorModal-module__colorFormItem--ec5Df`
};
export default ___CSS_LOADER_EXPORT___;
