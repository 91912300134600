import { Flex } from "antd";
import * as React from "react";

interface PropsVerticalContent {
  children?: React.ReactNode | React.ReactNode[];
  gap?: number;
  maxWidth?: number;
}

export const VerticalContent: React.FC<PropsVerticalContent> = ({
  children,
  gap,
  maxWidth,
}) => (
  <Flex vertical gap={gap} style={{ maxWidth: `${maxWidth}px` }}>
    {children}
  </Flex>
);

VerticalContent.defaultProps = { gap: 24, maxWidth: undefined, children: null };
