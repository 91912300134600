import React from "react";
import { observer } from "mobx-react-lite";
import { Flex } from "antd";
import { LoaderBox } from "src/components/LoaderBox";
import { SchedulingControlStore } from "../SchedulingControlStore";
import { SchedulingTable } from "../SchedulingTable";
import { StartDatePicker } from "./StartDatePicker";
import styles from "./SchedListTab.module.less";

interface PropsSchedListTab {
  store: SchedulingControlStore;
}

export const SchedListTab: React.FC<PropsSchedListTab> = observer(
  ({ store }) => (
    <Flex vertical gap={16} className={styles.box}>
      <StartDatePicker store={store} />
      <LoaderBox
        remoteData={store.data}
        drawReady={() => <SchedulingTable store={store} />}
      />
    </Flex>
  ),
);
