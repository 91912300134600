import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { BomObjType } from "../Bom2SettingsStore";
import { ZBom2Settings, ZBom2SupplierSettings } from "../../Bom2Types";

interface PropsSupplierSection {
  store: FormObjectSectionsStore<BomObjType>;
}

const fieldName = (
  name: keyof ZBom2SupplierSettings,
): ["supplierAttributes", keyof ZBom2SupplierSettings] => [
  "supplierAttributes",
  name,
];

export const SupplierSection: React.FC<PropsSupplierSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZBom2Settings, BomObjType>
      store={store}
      objKey={["supplierObjectId"]}
      objType="supplier"
      objLabel="Supplier object"
      title="Supplier"
      attrs={[
        {
          attrKey: fieldName("nameId"),
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
      ]}
    />
  ),
);
