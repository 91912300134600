// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-attrView-components-LinkStd-LinkStd-module__breakLine--JiICw {
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
}
.src-common-attrView-components-LinkStd-LinkStd-module__elipsis--DJz1q {
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/common/attrView/components/LinkStd/LinkStd.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACA;EACE,uBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;AACF","sourcesContent":[".breakLine {\n  word-break: break-all;\n  white-space: normal;\n  overflow: hidden;\n}\n.elipsis {\n  text-overflow: ellipsis;\n  max-width: 100%;\n  white-space: nowrap;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakLine": `src-common-attrView-components-LinkStd-LinkStd-module__breakLine--JiICw`,
	"elipsis": `src-common-attrView-components-LinkStd-LinkStd-module__elipsis--DJz1q`
};
export default ___CSS_LOADER_EXPORT___;
