// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-TestPage-TimelineToolbar-TimelineToolbar-module__toolbarBox--UbRHQ {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 6px;
}
.src-pages-TestPage-TimelineToolbar-TimelineToolbar-module__dayWidth--xf1P7 {
  width: 120px;
  display: flex;
  flex-direction: column;
}
.src-pages-TestPage-TimelineToolbar-TimelineToolbar-module__dayWidth--xf1P7 > div {
  text-align: center;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/TestPage/TimelineToolbar/TimelineToolbar.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;AACJ;AACA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAAI;EAAS,kBAAA;EAAoB,eAAA;AAIjC","sourcesContent":[".toolbarBox {\n    display: flex;\n    flex-direction: row;\n    gap: 16px;\n    margin-bottom: 6px;\n}\n.dayWidth {\n    width: 120px;\n    display: flex;\n    flex-direction: column;\n    & > div {text-align: center; font-size: 12px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarBox": `src-pages-TestPage-TimelineToolbar-TimelineToolbar-module__toolbarBox--UbRHQ`,
	"dayWidth": `src-pages-TestPage-TimelineToolbar-TimelineToolbar-module__dayWidth--xf1P7`
};
export default ___CSS_LOADER_EXPORT___;
