import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { ZSampleSettings } from "../../SampleTypes";
import { SampleObjType } from "../SampleSettingsStore";

interface PropsColorSection {
  store: FormObjectSectionsStore<SampleObjType>;
}

export const ColorSection: React.FC<PropsColorSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZSampleSettings, SampleObjType>
      store={store}
      objKey={["objColor"]}
      objType="color"
      objLabel="Sample color object"
      title="Sample color"
      attrs={[
        {
          attrKey: ["colorColorArticleAttrId"],
          attrLabel: "Color Article",
          attrType: AttrTypeName.string,
        },
      ]}
    />
  ),
);
