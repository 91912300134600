import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { loadFileInfo } from "src/common/apiFiles";
import { onError } from "src/common/onError";

interface FileLinkLabelProps {
  value: string;
}

export const FileLinkLabel: React.FC<FileLinkLabelProps> = ({ value }) => {
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = React.useState<React.ReactNode | null>(null);

  const init = async () => {
    try {
      setLoading(true);
      const filename = (await loadFileInfo(value)).fileName;
      setLabel(filename);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return loading ? <Spin spinning={loading}>...</Spin> : label ?? value;
};
