import { onError } from "src/common/onError";
import { notification } from "antd";
import { getAuthRCToken, rcWsServerUrl } from "../apiChat";
import {
  makeAuthMsg,
  makeConnectMsg,
  makeJoinRoomMsg,
  makePongMsg,
  makeSubscribeRoomMessagesMsg,
} from "./RCSocket.messages";
import { RCEventListener } from "./interface";
import { RCSChangeEvent } from "./rocketChat.types";

type RCSMsgRespType = "changed" | "connected" | "ping" | "result";

type RCSResp = {
  msg: RCSMsgRespType;
  id?: string;
  result?: unknown;
};

export class RCSocket {
  rcSocket: WebSocket | null = null;

  roomIds: string[] = [];

  setRoomIds(list: string[]) {
    this.roomIds = list;
  }

  listeners: RCEventListener[] = [];

  setListener(list: RCEventListener[]) {
    this.listeners = list;
  }

  addListener(listener: RCEventListener) {
    this.setListener([...this.listeners, listener]);
  }

  removeListener(chatId: string) {
    this.setListener(this.listeners.filter((l) => l.chatId !== chatId));
  }

  async init(ids: string[]) {
    this.setRoomIds(ids);
    this.rcSocket = new WebSocket(rcWsServerUrl);
    this.rcSocket.onopen = () => {
      this.connect();
    };

    this.rcSocket.onmessage = (data: MessageEvent) => {
      try {
        const resp = JSON.parse(data.data) as RCSResp;
        switch (resp.msg) {
          case "ping":
            this.pong();
            break;
          case "connected":
            this.auth();
            break;
          case "result":
            this.result(resp);
            break;
          case "changed": {
            this.changed(resp as RCSChangeEvent);
            break;
          }
          default:
            break;
        }
      } catch (error) {
        onError(error);
      }
    };

    this.rcSocket.onerror = (err) => {
      notification.error({ message: "ошибка системы чатов" });
      // eslint-disable-next-line no-console
      console.error(err);
    };
  }

  result(data: RCSResp) {
    switch (data.id) {
      case "login":
        this.joinAllRooms();
        break;
      default:
        break;
    }
  }

  changed(data: RCSChangeEvent) {
    switch (data.collection) {
      case "stream-room-messages":
        this.listeners.forEach((r) => r.onNewMessage(data));
        break;
      default:
        break;
    }
  }

  connect() {
    this.rcSocket?.send(makeConnectMsg());
  }

  pong() {
    this.rcSocket?.send(makePongMsg());
  }

  async auth() {
    this.rcSocket?.send(makeAuthMsg(getAuthRCToken()));
  }

  joinAllRooms() {
    this.roomIds.forEach((id) => {
      this.joinRoom(id);
    });
  }

  joinRoom(roomId: string) {
    this.rcSocket?.send(makeJoinRoomMsg(roomId));
    this.rcSocket?.send(makeSubscribeRoomMessagesMsg(roomId));
  }

  close() {
    this.rcSocket?.close();
  }
}
