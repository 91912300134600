import { EdObjStates } from "../../EdObject";
import { ZPermissions } from "../roleTypes";

interface ParamsCreateCopyOnRoleMap {
  curGroupId: number;
  curRoleId: number;
  curPermissonsItem: ZPermissions;
  rolesMap: EdObjStates;
}

export const createCopyOnRoleMap = (
  params: ParamsCreateCopyOnRoleMap,
): EdObjStates | null => {
  const { curGroupId, curRoleId, curPermissonsItem, rolesMap } = params;
  const curActionIds = curPermissonsItem
    .find(({ groupId }) => groupId === curGroupId)
    ?.roles.find(({ roleId }) => roleId === curRoleId)?.actionIds;
  if (!curActionIds) return null;

  return Object.fromEntries(
    Object.entries(rolesMap).map(([key, states]) => [
      key,
      states.map(({ groupId, roles }) => ({
        groupId,
        roles: roles.map(({ roleId, actionIds }) => ({
          roleId,
          actionIds: roleId === curRoleId ? curActionIds : actionIds,
        })),
      })),
    ]),
  );
};
