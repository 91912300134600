// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-MainFrame-MainFrame-module__mainFrame--vKaOg {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 60px auto;
  grid-template-areas: "nav header header" "nav content content" "nav content content";
  height: 100vh;
}
.src-components-MainFrame-MainFrame-module__mainFrame--vKaOg header {
  grid-area: header;
  border-bottom: 1px solid #00000014;
}
.src-components-MainFrame-MainFrame-module__mainFrame--vKaOg main {
  grid-area: content;
  overflow: hidden;
}
.src-components-MainFrame-MainFrame-module__mainFrame--vKaOg nav {
  grid-area: nav;
  position: relative;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainFrame/MainFrame.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,+BAAA;EACA,6BAAA;EACA,oFAAA;EAIA,aAAA;AAJF;AAJA;EAWI,iBAAA;EACA,kCAAA;AAJJ;AARA;EAgBI,kBAAA;EAEA,gBAAA;AANJ;AAZA;EAsBI,cAAA;EACA,kBAAA;EACA,gBAAA;AAPJ","sourcesContent":["@import \"/src/variables.less\";\n\n.mainFrame {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  grid-template-rows: @headerHeight auto;\n  grid-template-areas:\n    \"nav header header\"\n    \"nav content content\"\n    \"nav content content\";\n  height: 100vh;\n\n  header {\n    grid-area: header;\n    border-bottom: 1px solid #00000014;\n  }\n\n  main {\n    grid-area: content;\n    // чтобы скролл появлялся только внутри рабочей области\n    overflow: hidden;\n  }\n\n  nav {\n    grid-area: nav;\n    position: relative;\n    overflow: hidden;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainFrame": `src-components-MainFrame-MainFrame-module__mainFrame--vKaOg`
};
export default ___CSS_LOADER_EXPORT___;
