import * as React from "react";
import { observer } from "mobx-react-lite";
import { Checkbox } from "antd";
import { isChecked } from "src/common/groupChecked";
import { ZSampleData } from "../../ZSampleData";
import { SamplePointsStore } from "../SamplePointsStore";

interface PropsSampleRowSelector {
  row: ZSampleData;
  store: SamplePointsStore;
}

export const SampleRowSelector: React.FC<PropsSampleRowSelector> = observer(
  ({ row, store }) => (
    <Checkbox
      checked={store.selected.has(row.id)}
      onChange={() => store.toggleRow(row.id)}
    />
  ),
);

interface PropsSampleAllSelector {
  store: SamplePointsStore;
}

export const SampleAllSelector: React.FC<PropsSampleAllSelector> = observer(
  ({ store }) => (
    <Checkbox
      checked={isChecked(store.allSelected)}
      indeterminate={store.allSelected === "partial"}
      onChange={() => store.toggleAll()}
    />
  ),
);
