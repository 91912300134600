import { ZAttribute } from "src/types/ZAttribute";
import { getViewInfo } from "src/common/attrView";
import { zPersonCellInfo } from "src/common/attrView/componentsDefs";
import { apiLoadPersonList } from "src/common/apiPerson";
import { loadRolesDictCached } from "src/common/apiRoles";
import { walk } from "src/components/TreeStd";
import {
  AttrLabelMetaTypeNames,
  AttrLabelTreeData,
  defAttrLabelTreeData,
  defUsersListAttrLabelTreeData,
} from "../AttrLabelTreeData";
import { AttrLabelLoader, AttrLabelLoaderParams } from "../types";
import { loadAttrLabelData } from "../loadAttrLabelData";
import { loadEntityAttsForShow } from "./utils";

export const labelLoaderUsersList: AttrLabelLoader = async (
  values: string[],
  attr: ZAttribute,
  params?: AttrLabelLoaderParams,
) => {
  const recursionRegister = params?.recursionRegister;
  if (!attr || !values) return null;
  if (recursionRegister?.has(attr.id))
    return defAttrLabelTreeData([], attr, undefined, true);
  const users = await apiLoadPersonList({ roleIds: [], userIds: values });
  const viewInfo = getViewInfo(attr.viewType);
  const viCmp = zPersonCellInfo.parse(viewInfo?.component);
  const attsIdsForShow = viCmp.rolesViewAtts || {};
  const rolesDict = await loadRolesDictCached();
  const attsResp = await Promise.all(
    users.map(async (user) => {
      const [roleId] = user.roleIds;
      const role = rolesDict[Number(roleId)];
      const res = await loadEntityAttsForShow(
        user,
        role ? attsIdsForShow[role.id] || [] : [],
      );
      return res.map((r) =>
        defUsersListAttrLabelTreeData(
          r.labels,
          r.attr,
          user.userId,
          r.children,
          r.recursion,
        ),
      );
    }),
  );
  const attsFlat = attsResp.flat();
  const deepAtts = await Promise.all(
    attsFlat.map(async (e) => {
      const res = await loadAttrLabelData(e.labels, e.attr, {
        recursionRegister: recursionRegister || new Set([attr.id]),
      });
      return { ...res, attrLabelMeta: e.attrLabelMeta };
    }),
  );
  return defAttrLabelTreeData(
    values,
    attr,
    deepAtts.filter(Boolean) as AttrLabelTreeData[],
  );
};

export const getUsers2LabelsDict = (data: AttrLabelTreeData) => {
  type UsersListLabelDef = {
    labels: string[];
    attr: ZAttribute;
  };
  const dataCopy = JSON.parse(JSON.stringify(data)) as AttrLabelTreeData;
  const getLeaves = (treeData: AttrLabelTreeData) => {
    const leaves: AttrLabelTreeData[] = [];
    walk(
      (node) => {
        if (!node.children) leaves.push(node);
      },
      [treeData],
    );
    return leaves;
  };
  const personLabelsDict: Record<string, UsersListLabelDef[]> = {};
  walk(
    (node) => {
      if (
        node.recursion ||
        node.attrLabelMeta?.type !== AttrLabelMetaTypeNames.usersList
      )
        return;
      if (!personLabelsDict[node.attrLabelMeta.userId])
        personLabelsDict[node.attrLabelMeta.userId] = [];
      const leaves = getLeaves(node);
      personLabelsDict[node.attrLabelMeta.userId]?.push(
        ...leaves.map(
          (e): UsersListLabelDef => ({ attr: e.attr, labels: e.labels }),
        ),
      );
      // eslint-disable-next-line no-param-reassign
      node.children = undefined;
    },
    [dataCopy],
  );

  return personLabelsDict;
};
