import * as React from "react";
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { PageUrl } from "./routes/PageUrl";
import { routes } from "./routes/routes";
import { NoMatch } from "./pages/NoMatch";
import { MainFrame } from "./components/MainFrame/MainFrame";
import { makeUrl } from "./routes/makeUrl";

const navigateDef = <Navigate to={makeUrl(PageUrl.home)} />;

// Нужно отрезать первый слэш
const langPath = (path: string) => (path[0] === "/" ? path.slice(1) : path);

export const createMainRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<MainFrame />}>
        {/* Редирект на дефолтную страницу */}
        <Route
          index
          element={<Navigate replace to={makeUrl(PageUrl.home)} />}
        />
        <Route path="/:lang" element={navigateDef} />
        <Route path="/:lang">
          {routes.map(([path, Component]) => (
            <Route key={path} path={langPath(path)} element={<Component />} />
          ))}
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>,
    ),
  );
