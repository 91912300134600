import { ZPermissions, ZRolesGroup, ZTypeActions } from "./roleTypes";

export type ActionsSet = Set<string>;

export const makeActionKey = (roleId: number, actionId: number): string =>
  `${roleId}:${actionId}`;

export const createActionsSet = (permissions: ZPermissions): ActionsSet => {
  const res = new Set<string>();
  permissions.forEach((group) => {
    group.roles.forEach(({ actionIds, roleId }) => {
      actionIds.forEach((actionId) => {
        res.add(makeActionKey(roleId, actionId));
      });
    });
  });
  return res;
};

export const fromActionsSet = (
  actionsSet: ActionsSet,
  columns: ZTypeActions[],
  rows: ZRolesGroup[],
): ZPermissions =>
  rows.map(({ groupId, roles }) => ({
    groupId,
    roles: roles.map(({ roleId }) => ({
      roleId,
      actionIds: columns
        .flatMap(({ actions }) => actions)
        .map(({ id }) => id)
        .filter((id) => actionsSet.has(makeActionKey(roleId, id))),
    })),
  }));
