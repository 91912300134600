// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingHeader-SchedulingHeader-module__title--onY6B {
  font-size: 20px;
  font-weight: 700;
  flex-grow: 1;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingHeader-SchedulingHeader-module__header--sXEjy {
  padding: 16px 24px 20px 24px;
  background: #FFF;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingControl/SchedulingHeader/SchedulingHeader.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;AACF;AAEA;EACE,4BAAA;EACA,gBAAA;EACA,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAAF","sourcesContent":[".title {\n  font-size: 20px;\n  font-weight: 700;\n  flex-grow: 1;\n}\n\n.header {\n  padding: 16px 24px 20px 24px;\n  background: #FFF;\n  border-top: 1px solid rgba(0, 0, 0, 0.06);\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingHeader-SchedulingHeader-module__title--onY6B`,
	"header": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingHeader-SchedulingHeader-module__header--sXEjy`
};
export default ___CSS_LOADER_EXPORT___;
