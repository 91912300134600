import React from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { ArticleAttrMetaCtrlFC } from "./getAttrArticleMetaFC";

export const defineMetaController = (
  Cmp: ArticleAttrMetaCtrlFC,
  attr: ZAttribute,
  value?: unknown,
  onChange?: (v: unknown) => void,
) => <Cmp attr={attr} value={value} onChange={onChange} />;
