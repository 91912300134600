// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__formListItem--DkB6m {
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 16px;
  gap: 12px;
}
.src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__formListItem--DkB6m .src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__header--UNiHV {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__formListItem--DkB6m .src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__header--UNiHV .src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__title--sIEmH {
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__formListItem--DkB6m .ant-row {
  display: grid !important;
  grid-template-columns: 190px 1fr;
  gap: 4px;
}
.src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__formListItem--DkB6m .ant-mentions textarea {
  resize: vertical;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/NotifyTemplates/NotifyTemplates.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,SAAA;AACJ;AALA;EAOQ,aAAA;EACA,mBAAA;EACA,QAAA;AACR;AAVA;EAYY,OAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACZ;AAjBA;EAqBQ,wBAAA;EACA,gCAAA;EACA,QAAA;AADR;AAtBA;EA4BY,gBAAA;AAHZ","sourcesContent":[".formListItem {\n    display: flex;\n    flex-direction: column;\n    padding: 8px 16px 16px 16px;\n    gap: 12px;\n\n    .header {\n        display: flex;\n        flex-direction: row;\n        gap: 8px;\n\n        .title {\n            flex: 1;\n            font-size: 14px;\n            font-style: normal;\n            font-weight: 500;\n            line-height: 160%;\n        }\n    }\n\n    :global(.ant-row) {\n        display: grid !important;\n        grid-template-columns: 190px 1fr;\n        gap: 4px;\n    }\n\n    :global(.ant-mentions) {\n        textarea {\n            resize: vertical;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formListItem": `src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__formListItem--DkB6m`,
	"header": `src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__header--UNiHV`,
	"title": `src-pages-ManagementPage-Obj2Tab-NotifyTemplates-NotifyTemplates-module__title--sIEmH`
};
export default ___CSS_LOADER_EXPORT___;
