/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ReportIconSvg: React.FC<Partial<CustomIconComponentProps>> = ({
  ...props
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.4443 1.6853C6.08879 0.58649 8.02219 0 10 0C10.5523 0 11 0.447715 11 1V9H19C19.5523 9 20 9.44771 20 10C20 11.9778 19.4135 13.9112 18.3147 15.5557C17.2159 17.2002 15.6541 18.4819 13.8268 19.2388C11.9996 19.9957 9.98891 20.1937 8.0491 19.8079C6.10929 19.422 4.32746 18.4696 2.92894 17.0711C1.53041 15.6725 0.578004 13.8907 0.192152 11.9509C-0.1937 10.0111 0.00433284 8.00043 0.761209 6.17317C1.51809 4.3459 2.79981 2.78412 4.4443 1.6853ZM13.9966 0.0713087C13.45 -0.00732211 13 0.447716 13 1V6C13 6.55229 13.4477 7 14 7L19 7C19.5523 7 20.0073 6.55005 19.9287 6.00339C19.8458 5.4269 19.691 4.86171 19.4672 4.32122C19.1154 3.47194 18.5998 2.70026 17.9497 2.05025C17.2997 1.40024 16.5281 0.884627 15.6788 0.532844C15.1383 0.308964 14.5731 0.15423 13.9966 0.0713087Z"
    />
  </svg>
);

export const ReportIcon: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={ReportIconSvg} {...props} />;
