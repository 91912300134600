import { z } from "zod";
import { zPermissionsList } from "./Permission";
import { zArticleNumberInfo } from "./ZArticleNumberInfo";
import { zSystemAttributeType } from "./ZSystemAttributeType";

export const zAttributeValue = z.object({
  id: z.number(),
  value: z.string().nullable(),
});
export type ZAttributeValue = z.infer<typeof zAttributeValue>;

export const zAttribute = z.object({
  id: z.number(),
  name: z.string(),
  valueType: z.number(),
  fieldKey: z.string().nullish(),
  referenceId: z.number().nullish(), // id справочника, если valueType - Справочник
  values: zAttributeValue.array().nullish(),
  valueId: z.number().nullish(), // Ссылка на значение справочника, если атрибут входит в Dictionary-группу
  roleIds: z.number().array().nullish(), // LPLM-665
  // Свойства компонента на карточке сущности. Название неудачное, но и этого пришлось неделю ждать.
  viewStyles: z.string().nullish(),
  viewType: z.string().nullish(),
  permissions: zPermissionsList.nullish(),
  iterator: z.boolean().optional(), // Если true, то будет создаваться несколько экземпляров для каждого из выбранных значений данного атрибута.
  clearByCopy: z.boolean().optional(), // Очищать/не очищать значение атрибута при копировании
  pathValue: z.number().array().nullish(), // нужен только для атрибута с типом AttrTypeName.linkedValue, для остальных = null, означает путь до присоединяемого атрибута
  parentAttributeId: z.number().nullish(), // ID атрибута связанного объекта, с которым происходит сравнение
  linkAttributeId: z.number().nullish(), // ID атрибута "Ссылка на другой объект" внутри объекта, со значением атрибута которого мы проводим сравнение
  withRestrictions: z.boolean().nullish(), // Копировать/не копировать ограничения по значению (актуально только при сравнении справочников)
  articleNumberInfo: zArticleNumberInfo.nullish(), // Актуально только для атрибута с типом AttrTypeName.article, хранит инормацию об артикле
  systemAttributeType: zSystemAttributeType.nullish(), // Актуально только для виртуальных атрибутов
  filter: z.number().nullish(), // Актуально только для атрибута с типом AttrTypeName.object, хранит инормацию об ограничениях списка экземпляров
});
export type ZAttribute = z.infer<typeof zAttribute>;

export const zAttrLinkedData = z.object({
  entityId: z.number(),
  linkAttributeId: z.number(),
  attribute: zAttribute,
  values: z.string().array(),
});
export type ZAttrLinkedData = z.infer<typeof zAttrLinkedData>;
