export type AnchorItem = {
  href: string;
  key: string;
  title: string;
};

export const anchorItem = (href: string, title: string): AnchorItem => ({
  href,
  key: `anchor-${href}`,
  title,
});
