import React from "react";
import styles from "./ATVContainerGrid.module.less";

export type PropsATVContainerGrid = {
  children: React.ReactNode;
};

export const ATVContainerGrid: React.FC<PropsATVContainerGrid> = ({
  children,
}) => <div className={styles.grid}>{children}</div>;
