import { makeAutoObservable } from "mobx";
import { EntityStatesHistoryStore } from "./EntityStatesHistory/EntityStatesHistoryStore";

export class EntityHistoryStore {
  constructor(
    public readonly entityId: number,
    public readonly stateNames: Record<number, string>,
    public readonly curStateName: string,
    public readonly entityName: string,
  ) {
    makeAutoObservable(this);
  }

  reloadHistory() {
    this.statesStore.tableStore.reload();
  }

  statesStore = new EntityStatesHistoryStore(this.entityId, this.stateNames);
}
