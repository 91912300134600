import { rest } from "src/common/rest";
import { EdBomPos, pos2ed, zBomPosition } from "./ZBom2Position";
import { urlBom2 } from "../../apiBom2";
import { zBom2MaterialResponse } from "./ZBom2Material";

export const loadBom2Positions = async (bomId: number): Promise<EdBomPos[]> => {
  const resp = await rest.get(urlBom2("/bom-position/get-all-by-bom-id"), {
    params: { bomId },
  });
  const posList = zBomPosition.array().parse(resp.data);
  return posList.map((pos, i) => pos2ed(pos, `[${i}]`));
};

export const saveBom2PositionFields = async (
  rowId: number,
  fields: { id: number; value: string | null }[],
) => {
  await rest.put(urlBom2("/bom-position/edit-fields"), {
    id: rowId,
    fields,
  });
};

export const loadBom2Materials = async (
  materialTypeId: number,
  page: number,
  size: number,
  query: string | undefined,
) => {
  const resp = await rest.get(
    urlBom2("/material/get-paginated-by-material-type-id"),
    {
      params: { materialTypeId, page, size, query },
    },
  );
  return zBom2MaterialResponse.parse(resp.data);
};

export const createBom2Position = async (
  bomId: number,
  materialIds: number[],
) => {
  await rest.post(urlBom2("/bom-position/create-bom-position"), undefined, {
    params: { bomId, materialIds },
    paramsSerializer: {
      indexes: null,
    },
  });
};

export const createEmptyBom2Position = async (
  bomId: number,
  materialTypeId: number,
) => {
  await rest.post(
    urlBom2("/bom-position/create-empty-bom-position"),
    undefined,
    {
      params: {
        bomId,
        materialTypeId,
      },
    },
  );
};

export const deleteBom2Positions = async (bomPositionIds: number[]) => {
  await rest.delete(urlBom2("/bom-position/delete-by-id"), {
    data: bomPositionIds,
  });
};
