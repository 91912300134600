import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { FormObjectSectionsStore } from "src/components/Forms/FormObjectSection";

export type SchedulingObjType =
  | "performer"
  | "plan"
  | "planTemplate"
  | "supplier"
  | "task"
  | "taskGroup"
  | "taskGroupTemplate"
  | "taskTemplate"
  | "taskTemplateTies"
  | "taskTies";

export const schedulingSettingsStore = makeAutoObservable({
  async init(attrTypesDict: Record<number, string>) {
    try {
      await this.formSectionsStore.init(attrTypesDict);
    } catch (e) {
      onError(e);
    }
  },
  formSectionsStore: new FormObjectSectionsStore<SchedulingObjType>(),
});

export type SchedulingSettingsStore = typeof schedulingSettingsStore;
