import { ZAttribute } from "src/types/ZAttribute";
import React from "react";
import { ZLightObject } from "../../Obj2Tab/Obj2Nodes";
import { ZRolesGroup } from "../../Obj2Tab/roles/roleTypes";

export const newItemId = -1;

interface BaseRoleNode {
  readonly key: React.Key;
  readonly title: React.ReactNode;
  icon: React.ReactNode;
  children?: CommonRoleNode[];
  isLeaf: boolean;
}

export const makeTopGroupRoleKey = (id: number) => `group:${id}`;

export interface TopGroupRoleNode extends BaseRoleNode {
  type: "roleGroup";
  group: ZRolesGroup;
}

export interface ObjRoleNode extends BaseRoleNode {
  type: "roleObject";
  obj: ZLightObject;
}

export interface AttrRoleNode extends BaseRoleNode {
  type: "attr";
  attr: ZAttribute;
  ownerId: number; // Это id роли, который используется в запросах (т.к. там неоднозначность с id и roleId)
}

export type CommonRoleNode = AttrRoleNode | ObjRoleNode | TopGroupRoleNode;
