// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AsyncTableView-tableSelection-TableSelection-module__selection--XrTTJ {
  z-index: 1;
  margin: 0;
}
.src-pages-EntityFiltersPage-AsyncTableView-tableSelection-TableSelection-module__radioSelection--RY3_i {
  position: relative;
}
.src-pages-EntityFiltersPage-AsyncTableView-tableSelection-TableSelection-module__radioCancel--Qx9t8 {
  position: absolute;
  top: 1px;
  left: -5px;
  transform: translateX(-100%);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AsyncTableView/tableSelection/TableSelection.module.less"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,SAAA;AACF;AAEA;EACE,kBAAA;AAAF;AAGA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,4BAAA;EACA,eAAA;AADF","sourcesContent":[".selection {\n  z-index: 1;\n  margin: 0;\n}\n\n.radioSelection {\n  position: relative;\n}\n\n.radioCancel {\n  position: absolute;\n  top: 1px;\n  left: -5px;\n  transform: translateX(-100%);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selection": `src-pages-EntityFiltersPage-AsyncTableView-tableSelection-TableSelection-module__selection--XrTTJ`,
	"radioSelection": `src-pages-EntityFiltersPage-AsyncTableView-tableSelection-TableSelection-module__radioSelection--RY3_i`,
	"radioCancel": `src-pages-EntityFiltersPage-AsyncTableView-tableSelection-TableSelection-module__radioCancel--Qx9t8`
};
export default ___CSS_LOADER_EXPORT___;
