import { ZEntityFiltersChild } from "./ChildEntitiesStore";

export const createTableParamsKey = (
  filters: ZEntityFiltersChild,
  total: number,
) =>
  JSON.stringify({
    filters,
    total,
  });
