/* eslint-disable react/require-default-props */
import * as React from "react";
import styles from "./FieldsWithTools.module.less";

interface PropsFieldsWithTools {
  tools: React.ReactNode;
  children: React.ReactNode;
}

export const FieldsWithTools: React.FC<PropsFieldsWithTools> = ({
  children,
  tools,
}) => (
  <div className={styles.frame}>
    <div className={styles.header}>{tools}</div>
    <div className={styles.form}>{children}</div>
  </div>
);
