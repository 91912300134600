import { TreeLikeData } from "src/types/TreeLikeData";
import { z } from "zod";

export interface ZEntCopyDataAttr {
  attributeId: number;
  name: string;
  linkedAttributes: ZEntCopyDataAttr[];
}
export const zEntCopyDataAttr: z.ZodType<ZEntCopyDataAttr> = z.lazy(() =>
  z.object({
    attributeId: z.number(),
    name: z.string(),
    linkedAttributes: z.array(zEntCopyDataAttr),
  }),
);

export const zEntCopyDataBS = z.object({
  name: z.string(),
  url: z.string(),
});
export type ZEntCopyDataBS = z.infer<typeof zEntCopyDataBS>;

export const zEntCopyDataResp = z.object({
  businessServices: zEntCopyDataBS.array(),
  childAttributes: zEntCopyDataAttr.array(),
});
export type ZEntCopyDataResp = z.infer<typeof zEntCopyDataResp>;

export enum EntCopyTreeNodeType {
  attribute = "attribute",
  businessService = "businessService",
}

type EntCopyTreeNodeAttr = {
  type: EntCopyTreeNodeType.attribute;
  data: ZEntCopyDataAttr;
};
type EntCopyTreeNodeBS = {
  type: EntCopyTreeNodeType.businessService;
  data: ZEntCopyDataBS;
};
export type EntCopyTreeNode = TreeLikeData<
  EntCopyTreeNodeAttr | EntCopyTreeNodeBS
>;

export const getEntCopyAttrNodes = (nodes: EntCopyTreeNode[]) =>
  nodes.filter(
    (n) => n.type === EntCopyTreeNodeType.attribute,
  ) as EntCopyTreeNodeAttr[];
export const getEntCopyBSNodes = (nodes: EntCopyTreeNode[]) =>
  nodes.filter(
    (n) => n.type === EntCopyTreeNodeType.businessService,
  ) as EntCopyTreeNodeBS[];
