// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-attrView-components-ChildEntitiesCellInfo-ChildEntitiesCellInfo-module__block--oaWld > * {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/common/attrView/components/ChildEntitiesCellInfo/ChildEntitiesCellInfo.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF","sourcesContent":[".block > * {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `src-common-attrView-components-ChildEntitiesCellInfo-ChildEntitiesCellInfo-module__block--oaWld`
};
export default ___CSS_LOADER_EXPORT___;
