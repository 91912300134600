import { TablePaginationConfig } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { CtrlPagination, CtrlSort } from "src/components/tables/TableStore";

export const onPagination = (
  store: CtrlPagination,
  p: TablePaginationConfig,
) => {
  if (p.current) store.setPage(p.current - 1);
  if (p.pageSize) store.setPageSize(p.pageSize);
};

export const onTableParamsChange = <TRow extends {}>(
  store: CtrlPagination & CtrlSort,
  pagination: TablePaginationConfig,
  sorter?: SorterResult<TRow> | SorterResult<TRow>[],
) => {
  onPagination(store, pagination);
  if (sorter) {
    if (!Array.isArray(sorter)) {
      store.setSorting(String(sorter.columnKey), sorter.order ?? undefined);
    }
  }
};
