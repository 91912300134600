/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ArrowIconSvg: React.FC<Partial<CustomIconComponentProps>> = (props) => (
  <svg
    viewBox="0 0 8 5"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="8"
    height="5"
  >
    <path
      d="M6.94373 0L4.00216 2.90503L1.06066 6.12696e-05L0 1.04755L4.00216 5L8.00439 1.04749L6.94373 0Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export const ArrowIcon: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={ArrowIconSvg} {...props} />;
