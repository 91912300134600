// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-entity-composeEntityLabel-innerViews-ImgListInner-ImgListInner-module__box--JzCj4 {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/common/entity/composeEntityLabel/innerViews/ImgListInner/ImgListInner.module.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF","sourcesContent":[".box {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-common-entity-composeEntityLabel-innerViews-ImgListInner-ImgListInner-module__box--JzCj4`
};
export default ___CSS_LOADER_EXPORT___;
