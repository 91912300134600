import { z } from "zod";

export const zLanguageProps = z.object({
  code: z.string(), // "zh"
  name: z.string(), // "Chinese"
  nativeName: z.string(), // "中文"
});

export type ZLanguageProps = z.infer<typeof zLanguageProps>;

export const getLangName = ({ name, nativeName }: ZLanguageProps): string =>
  name === nativeName ? name : `${nativeName} (${name})`;
