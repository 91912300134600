import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import {
  AsyncTableView,
  ATVTemplateToken,
} from "src/pages/EntityFiltersPage/AsyncTableView";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";
import { InputSearch } from "src/pages/EntityFiltersPage/EntityList/InputSearch";
import { Button, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { onError } from "src/common/onError";
import { ZChildEntities } from "src/common/attrEdit/components/ZChildEntities";
import { t } from "i18next";
import { classNames } from "src/common/classNames";
import { ColumnsSettings } from "../tables/ColumnsSettings";
import {
  bindEventOnStorage,
  createStorageEventAction,
  unbindEventOnStorage,
} from "../../common/storageEventUtils";
import { LoaderBox } from "../LoaderBox";
import { ChildEntitiesEditor } from "./ChildEntitiesEditor";
import { ModalVertFixed } from "../ModalVertFixed";
import styles from "./ChildEntities.module.less";
import { ChildEntitiesStore, ZEntityFiltersChild } from "./ChildEntitiesStore";
import { ChildEntitiesActions } from "./ChildEntitiesActions";
import { SummaryFooter } from "./SummaryFooter";
import { ChildEntsActionType } from "./ChildEntities.types";

type PropsChildEntities = {
  parentObjId: number;
  editorInfo: ZChildEntities | undefined;
  disabled?: boolean;
  entityId?: number;
  outerStore?: ChildEntitiesStore;
  disableResize?: boolean;
};

export const ChildEntities: React.FC<PropsChildEntities> = observer(
  ({
    parentObjId,
    editorInfo,
    disabled,
    entityId,
    outerStore,
    disableResize,
  }) => {
    const [isHeightLimit, setIsHeightLimit] = useState<boolean>(false);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const docHeight = document.documentElement.clientHeight;
    useEffect(() => {
      const element = tableRef?.current;
      if (!element || disableResize) return undefined;
      const resizeObserver = new ResizeObserver(() => {
        if (element.scrollHeight / docHeight > 0.6) {
          setIsHeightLimit(true);
        }
      });
      resizeObserver.observe(element);
      return () => {
        resizeObserver.disconnect();
      };
    }, [tableRef?.current]);

    const navigate = useNavigate();
    const store = useMemo(() => outerStore ?? new ChildEntitiesStore(), []);
    const init = async () => {
      if (!entityId) return;
      try {
        await store.init(
          parentObjId,
          entityId,
          Number(editorInfo?.attrId),
          editorInfo?.summaryAttrs,
        );
      } catch (error) {
        onError(error);
      }
    };

    const { tableStore, avalibleActionsSet } = store;

    const onRow = avalibleActionsSet.has(ChildEntsActionType.rowClick)
      ? (row: ZEntityRow) => {
          if (editorInfo?.editable) {
            store.openEdit(row.id);
          } else {
            navigate(makeUrl(PageUrl.entityCard, { id: row.id }));
          }
        }
      : undefined;

    useEffect(() => {
      init();
    }, [entityId]);

    useEffect(() => {
      const handleReloadTable = createStorageEventAction(
        "lastUpdatedObject",
        (e: StorageEvent) => {
          try {
            const objId = Number(JSON.parse(e.newValue || '""'));
            if (objId === parentObjId) {
              store.tableStore?.reload();
            }
          } catch (error) {
            onError(error);
          }
        },
      );
      bindEventOnStorage(handleReloadTable);
      return () => unbindEventOnStorage(handleReloadTable);
    }, []);

    if (!entityId || !editorInfo)
      return <div>{t("Display available only for created instances")}</div>;

    if (!tableStore) return <Spin spinning>{t("Loading")}</Spin>;

    const { subCardStore } = store;
    const useFooter = !!editorInfo.summaryAttrs?.length;
    const canCreate = avalibleActionsSet.has(ChildEntsActionType.create);
    const useSelection = avalibleActionsSet.has(ChildEntsActionType.select);
    const childEntsTemplateToken: ATVTemplateToken = {
      footer() {
        return <SummaryFooter store={store} />;
      },
    };

    const DrawEditor = observer(() => {
      if (!subCardStore) return null;
      return (
        <ChildEntitiesEditor
          store={subCardStore}
          submitText={store.submitText}
          onSuccess={() => {
            store.onUpdate();
          }}
          onDelete={() => {
            store.doDeleteSubEntity().catch(onError);
          }}
          deleting={store.deletingSubEntity}
        />
      );
    });

    return (
      <div className={styles.box}>
        <div className={styles.header}>
          <div className={styles.search}>
            <InputSearch store={tableStore} disabled={disabled} />
          </div>
          <div className={styles.toolbar}>
            <ColumnsSettings store={tableStore} />
            <ChildEntitiesActions
              store={store}
              entityId={entityId}
              disabled={disabled}
              editorInfo={editorInfo}
            />
            {canCreate && (
              <Button
                type="primary"
                size="middle"
                icon={<PlusCircleOutlined />}
                className={styles.openCreationButton}
                disabled={disabled}
                onClick={() => store.openCreation(editorInfo.attrId, entityId)}
              >
                {store.currObjName}
              </Button>
            )}
          </div>
        </div>

        <div
          className={classNames([
            styles.tableContainer,
            [isHeightLimit, styles.heightLimited],
          ])}
          ref={tableRef}
        >
          <AsyncTableView<ZEntityRow, ZEntityFiltersChild>
            store={tableStore}
            columns={tableStore.columns}
            onRowClick={onRow}
            useHeader
            usePagination
            useSelection={useSelection}
            useFooter={useFooter}
            templateToken={childEntsTemplateToken}
          />
        </div>

        <ModalVertFixed
          open={store.isPopup}
          onCancel={() => store.closeCreation()}
          width="65vw"
          footer={null}
        >
          {subCardStore && (
            <LoaderBox
              remoteData={subCardStore.info}
              drawReady={() => <DrawEditor />}
            />
          )}
        </ModalVertFixed>
      </div>
    );
  },
);
