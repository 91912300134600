import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Flex, Spin } from "antd";
import { sampleSettingsStore } from "./SampleSettingsStore";
import {
  CommentsSection,
  McPointsSection,
  RequestSection,
  SampleSection,
  SupplierSection,
} from "./sections";
import { SampleDictSection } from "./sections/SampleDictSection";
import { ColorSection } from "./sections/ColorSection";

interface PropsSampleSettings {
  typesMap: Record<number, string>;
}

export const SampleSettings: React.FC<PropsSampleSettings> = observer(
  ({ typesMap }) => {
    useEffect(() => {
      sampleSettingsStore.init(typesMap);
    }, []);
    const sectionsStore = sampleSettingsStore.formSectionsStore;
    const { loading } = sectionsStore;
    return (
      <Spin spinning={loading}>
        <Flex gap={12} vertical>
          <RequestSection store={sectionsStore} />
          <SampleSection store={sectionsStore} />
          <CommentsSection store={sectionsStore} />
          <McPointsSection store={sectionsStore} />
          <SupplierSection store={sectionsStore} />
          <ColorSection store={sectionsStore} />
          <SampleDictSection store={sampleSettingsStore.selectDict} />
        </Flex>
      </Spin>
    );
  },
);
