import * as React from "react";
import { Select } from "antd";
import styles from "./MCInfoItem.module.less";
import { NumOption } from "./MChartTable/PointDict";

type MCSelector = {
  options: NumOption[];
  value: number | undefined;
  onChange: (newValue: number) => void;
  loading?: boolean;
};

interface PropsMCInfoItem {
  label: string;
  value: MCSelector | string | undefined;
}

export const MCInfoItem: React.FC<PropsMCInfoItem> = ({
  label,
  value = "",
}) => (
  <div className={styles.infoItem}>
    {label}:
    <span>
      {typeof value === "object" ? (
        <Select
          options={value.options}
          style={{ width: "100%" }}
          size="small"
          value={value.value}
          onChange={value.onChange}
          loading={value.loading}
        />
      ) : (
        value
      )}
    </span>
  </div>
);
