import { z } from "zod";

export const zIdName = z.object({
  id: z.number(),
  name: z.string(),
});

export type ZIdName = z.infer<typeof zIdName>;

/**
 * Значение пропса fieldNames для компонента Select
 */
export const idNameFieldNames = { value: "id", label: "name" };
