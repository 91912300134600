import { z } from "zod";
import { apiFilesUrl } from "./apiUrl";
import { rest } from "./rest";

const zFileInfo = z.object({
  fileName: z.string(),
  size: z.number(),
});

export const loadFileInfo = async (filePath: string) => {
  const resp = await rest.get(apiFilesUrl("/info"), { params: { filePath } });
  return zFileInfo.parse(resp.data);
};
