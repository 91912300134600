// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplePoints-module__frame--ntEVC {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplePoints-module__tools--MWDnE {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplePoints-module__leftTool--t1ozI {
  flex: 1;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplePoints-module__content--XZWNv {
  padding-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SamplePoints/SamplePoints.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACA;EACI,OAAA;AACJ;AACA;EAKI,oBAAA;AAHJ","sourcesContent":[".frame {\n    height: 100%;\n    overflow: auto;\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n.tools {\n    display: flex;\n    flex-direction: row;\n    gap: 16px;\n}\n.leftTool {\n    flex: 1;\n}\n.content {\n    // Если нужно зафиксировать tools и пролистывать только content, то раскомментировать это\n    // flex: 1;\n    // overflow: auto;\n\n    padding-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplePoints-module__frame--ntEVC`,
	"tools": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplePoints-module__tools--MWDnE`,
	"leftTool": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplePoints-module__leftTool--t1ozI`,
	"content": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplePoints-module__content--XZWNv`
};
export default ___CSS_LOADER_EXPORT___;
