import * as React from "react";
import { CommonNodeO2 } from "../Obj2Nodes";
import { findNodeByKey } from "../../../../common/findNodeByKey";

export const findNodeOwnerObject = (
  key: React.Key,
  objects: CommonNodeO2[],
): CommonNodeO2 | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of objects) {
    const node = findNodeByKey(key, [obj]);
    if (node) return obj;
  }
  return undefined;
};
