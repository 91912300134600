// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-ArticleTemplateInput-ArticleTemplateInput-module__box--u42Yt {
  display: grid;
  row-gap: 15px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-ArticleTemplateInput-ArticleTemplateInput-module__row--sQdZO {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/AttrCommonFields/ArticleTemplateInput/ArticleTemplateInput.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;AACF;AACA;EACE,aAAA;EACA,SAAA;EACA,sCAAA;AACF","sourcesContent":[".box {\n  display: grid;\n  row-gap: 15px;\n}\n.row {\n  display: grid;\n  gap: 10px;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-ArticleTemplateInput-ArticleTemplateInput-module__box--u42Yt`,
	"row": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-ArticleTemplateInput-ArticleTemplateInput-module__row--sQdZO`
};
export default ___CSS_LOADER_EXPORT___;
