import { ZMenuItem } from "./ZMenuItem";
import { makeMenuItemKey } from "./makeMenuItemKey";

export const getOpenedMenuItems = (
  items: ZMenuItem[] | null | undefined,
): string[] | undefined =>
  items?.flatMap(({ elements, id }: ZMenuItem) => {
    const itemKey = elements ? [makeMenuItemKey(id)] : [];
    const childrenKeys = elements ? getOpenedMenuItems(elements) ?? [] : [];
    return [...itemKey, ...childrenKeys];
  });
