import { Flex } from "antd";
import * as React from "react";

interface PropsExtraTools {
  children?: React.ReactNode | React.ReactNode[];
}

export const ExtraTools: React.FC<PropsExtraTools> = ({ children }) => (
  <Flex align="center" wrap="wrap" gap={8}>
    {children}
  </Flex>
);
ExtraTools.defaultProps = { children: null };
