import { zBsRegSettings } from "src/businessServices/services/bsRegistration/bsRegistration.types";
import { z } from "zod";

export const zRegInfo = z.object({
  settingsId: z.number(),
  objectId: z.number(),
  settings: zBsRegSettings,
});

export type ZRegInfo = z.infer<typeof zRegInfo>;

export const zRegSuccessMessage = z.object({
  message: z.string(),
  success: z.boolean(),
});

export type ZRegSuccessMessage = z.infer<typeof zRegSuccessMessage>;
