import { apiChatUrl } from "src/common/apiUrl";
import { setToken } from "src/appStore";
import { z } from "zod";
import { rest } from "src/common/rest";
import { UploadFile } from "antd";
import { zChatInfo, ZChatInfo } from "./bsChat.types";
import { restChat } from "./restChat";
import { zRCRoomInfoResp } from "./rocketChat/rocketChat.types";

const zRCAuthData = z.object({
  authToken: z.string(),
  userId: z.string(),
  fileAccessToken: z.string(),
});
export type ZRCAuthData = z.infer<typeof zRCAuthData>;

export const zRCAuthResp = z.object({
  data: zRCAuthData,
  status: z.string(),
});

/**
 * для этого пока что нет никакого gateway, они просто настроят корсы,
 * поэтому обращаемся напрямую, а нужно что-то типа того :
 * Для dev стеда:
 * http://dev.plm.d4r.int/rocket-chat-server
 * ws://dev.plm.d4r.int/rocket-chat-server
 * Для стеда самоката:
 * http://samokat.pre-plm.omnidata.ru/rocket-chat-server
 * ws://samokat.pre-plm.omnidata.ru/rocket-chat-server
 */

export const rcUrl = (path: string) => `/rocket-chat-server${path}`;

export const rcWsServerUrl = rcUrl("/websocket");

const keyAuthChatToken = "rocketChatAuthToken";
const keyAuthChatUserId = "rocketChatAuthUserId";
const keyFileAccessToken = "rocketChatFileAccessToken";

export const setRCAuth = (data: ZRCAuthData) => {
  setToken(keyAuthChatToken, data.authToken);
  setToken(keyAuthChatUserId, data.userId);
  setToken(keyFileAccessToken, data.fileAccessToken);
};

export const clearRCAuth = () => {
  setToken(keyAuthChatToken, undefined);
  setToken(keyAuthChatUserId, undefined);
  setToken(keyFileAccessToken, undefined);
};

export const getAuthRCToken = (): string =>
  localStorage.getItem(keyAuthChatToken) || "";
export const getAuthRCUserId = (): string =>
  localStorage.getItem(keyAuthChatUserId) || "";
export const getFileAccessToken = (): string =>
  localStorage.getItem(keyFileAccessToken) || "";

export const loginChat = async () => {
  const resp = await rest.get(apiChatUrl("/login"));
  return zRCAuthData.parse(resp.data);
};

export const loadEntityChats = async (
  entityId: number,
): Promise<ZChatInfo[]> => {
  const resp = await rest.get(apiChatUrl(`/chats`), {
    params: { entityId },
  });
  return zChatInfo.array().parse(resp.data);
};

export const createEntityChat = async (
  entityId: number,
  name: string,
  userIds: string[],
) =>
  restChat.post(apiChatUrl(`/chats`), {
    name,
    userIds,
    entityId,
  });

export const updateEntityChat = async (
  chatId: string,
  name: string,
  userIds: string[],
) => {
  const resp = await restChat.put(apiChatUrl(`/chats/${chatId}`), {
    userIds,
    name,
  });
  return zChatInfo.parse(resp.data);
};

export const deleteEntityChat = async (chatId: string) =>
  restChat.delete(apiChatUrl(`/chats/${chatId}`));

export const getRoomInfo = async (roomId: string) => {
  const resp = await restChat.get(rcUrl(`/api/v1/rooms.info`), {
    params: { roomId },
  });
  const { room } = zRCRoomInfoResp.parse(resp.data);
  return room;
};

export const sendMessage = async (rid: string, msg: string) =>
  restChat.post(rcUrl(`/api/v1/chat.sendMessage`), {
    message: { rid, msg },
  });

/**
 * Загрузку файлов в rocket-chat можно произвести только таким образом :
 * 1 файл, сразу загружается в комнату, при этом на сервере автоматически создается объект-сообщение
 * текст сообщения можно указать
 */
export const sendMessageWithFile = async (
  roomId: string,
  file: UploadFile,
  msg: string,
) =>
  restChat.post(
    rcUrl(`/api/v1/rooms.upload/${roomId}`),
    { file, msg },
    { headers: { "Content-Type": "multipart/form-data" } },
  );
