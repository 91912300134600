import { z } from "zod";

export const zBom2PositionSettings = z.object({
  nameId: z.number(),
  materialTypeId: z.number(),
  materialLocationId: z.number(),
  materialSubtypeId: z.number(),
  imageId: z.number(),
  locationId: z.number(),
  trimSizeId: z.number(),
  amountId: z.number(),
  commentId: z.number(),
  qualityDetailsId: z.number(),
  linkToBomPositionColorModelsId: z.number(),
});
export type ZBom2PositionSettings = z.infer<typeof zBom2PositionSettings>;

export const zBom2SupplierSettings = z.object({
  nameId: z.number(),
});
export type ZBom2SupplierSettings = z.infer<typeof zBom2SupplierSettings>;

export const zBom2ColorSettings = z.object({
  linkToModelId: z.number(),
  colorModelArticleId: z.number(),
  linkToColorInternalId: z.number(),
});
export type ZBom2ColorSettings = z.infer<typeof zBom2ColorSettings>;

export const zBom2ColorModelSettings = z.object({
  linkToBomPositionId: z.number(),
  linkToColorModelId: z.number(),
  linkToColorId: z.number(),
  colorManualId: z.number(),
  colorReferenceId: z.number(),
});
export type ZBom2ColorModelSettings = z.infer<typeof zBom2ColorModelSettings>;

export const zBom2Settings = z.object({
  objectServiceId: z.number(),
  bomObjectId: z.number(),
  positionObjectId: z.number(),
  colorObjectId: z.number(),
  colorModelObjectId: z.number(),
  supplierObjectId: z.number(),
  materialObjectId: z.number(),
  positionAttributes: zBom2PositionSettings,
  supplierAttributes: zBom2SupplierSettings,
  colorAttributes: zBom2ColorSettings,
  bomPosColorModelAttributes: zBom2ColorModelSettings,
});
export type ZBom2Settings = z.infer<typeof zBom2Settings>;

// summary

export const zBom2SummaryRow = z.object({
  id: z.number(),
  name: z.string().nullable(),
  supplierId: z.number().nullable(),
  supplierName: z.string().nullable(),
});

export type ZBom2SummaryRow = z.infer<typeof zBom2SummaryRow>;

export const zBom2Supplier = z.object({
  id: z.number(),
  name: z.string().nullable(),
  country: z.string().nullable(),
  address: z.string().nullable(),
});
export type ZBom2Supplier = z.infer<typeof zBom2Supplier>;

export const zBom2SupplierResponse = z.object({
  content: zBom2Supplier.array(),
  totalElements: z.number(),
});
export type ZBom2SupplierResponse = z.infer<typeof zBom2SupplierResponse>;
