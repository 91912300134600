type StorageEventKey = "lastUpdatedObject";

export const createStorageEventAction =
  (key: StorageEventKey, action: (e: StorageEvent) => void) =>
  (e: StorageEvent) => {
    if (e.key === key) action(e);
  };

const emmitStorageEvent = (key: StorageEventKey, data: unknown) => {
  try {
    /**
     * Запись в хранилище происходит тлоько в случае, если старое и новое значения
     * различаются, поэтому если мы попробуем 2 раза записать одно и то же значение
     * событие не отправится, для этого сначала удаляем старое, а потом записываем новое
     */
    localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const emmitLastObjectUpdateSE = (data: unknown) =>
  emmitStorageEvent("lastUpdatedObject", data);

export const bindEventOnStorage = (handle: (e: StorageEvent) => void) => {
  window.addEventListener("storage", handle);
};
export const unbindEventOnStorage = (handle: (e: StorageEvent) => void) => {
  window.removeEventListener("storage", handle);
};
