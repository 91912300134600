import { useRef } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { ModelessFormStore } from "./ModelessFormStore";

/* eslint no-plusplus: "off" */

/**
 * React-хук, который выводит предупреждение в случае попытки перейти на другую страницу.
 * При условии использования React router.
 */
export const useModelessNavProtector = (
  stores: (ModelessFormStore | null)[],
) => {
  const navigate = useNavigate();
  const counter = useRef<number>(0);

  useBlocker(({ nextLocation }) => {
    if (counter.current === 0) {
      ModelessFormStore.safeMultiAction(stores, () => {
        counter.current++;
        navigate(nextLocation);
      });
      return true;
    }
    counter.current--;
    return false;
  });
};
