import * as React from "react";
import { observer } from "mobx-react-lite";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { urlBom2 } from "src/businessServices/services/bsBom2/apiBom2";
import { rest } from "src/common/rest";
import { EdBomRow } from "../../ZBom2Position";
import { Bom2PositionStore } from "../../Bom2PositionStore";
import { BomColorInfo, BomColorModal } from "./BomColorModal";
import { saveBom2PositionFields } from "../../apiBom2Position";
import styles from "./BomColorCell.module.less";

interface PropsBomColorCell {
  colorModelId: number;
  row: EdBomRow;
  store: Bom2PositionStore;
}

export const BomColorCell: React.FC<PropsBomColorCell> = observer((props) => {
  const [edit, setEdit] = React.useState(false);
  const { colorModelId, row, store } = props;
  const colorModel = row.colorModels.find(({ id }) => id === colorModelId);
  if (!colorModel) return null;
  const { bomPositionColorModel: cm } = colorModel;
  const { color } = cm;
  return (
    <>
      <button
        className={styles.box}
        type="button"
        onClick={() => setEdit(true)}
      >
        {!!cm.colorReference.value && (
          <div>
            <img
              src={imgSrc(cm.colorReference.value)}
              alt=""
              style={{ maxWidth: 64, maxHeight: 40 }}
            />
          </div>
        )}
        <div>{cm.colorManual.value}</div>
        <div>{cm.comment.value}</div>
        {!!color.image.value && (
          <div>
            <img
              src={imgSrc(color.image.value)}
              alt=""
              style={{ maxWidth: 64, maxHeight: 40 }}
            />
          </div>
        )}
        <div>{color.article.value}</div>
        <div>{color.name.value}</div>
      </button>
      <BomColorModal
        open={edit}
        close={() => setEdit(false)}
        initialData={colorModel}
        save={async (data: BomColorInfo) => {
          const colorReference = data.colorReference?.[0] ?? null;
          if (cm.id) {
            await saveBom2PositionFields(cm.id, [
              { id: cm.colorManual.id, value: data.colorManual },
              { id: cm.colorReference.id, value: colorReference },
              { id: cm.comment.id, value: data.comment },
            ]);
            await rest.post(
              urlBom2("/bom-position-color-model/assign-color"),
              undefined,
              {
                params: {
                  bomPositionColorModelId: colorModel.bomPositionColorModel.id,
                  colorId: data.color?.id,
                },
              },
            );
          } else {
            await rest.post(
              urlBom2("/bom-position-color-model/create"),
              {
                colorManual: data.colorManual,
                colorReference,
                comment: data.comment,
              },
              {
                params: {
                  bomPositionId: row.id,
                  colorModelId: colorModel.id,
                  colorId: data.color?.id ?? undefined,
                },
              },
            );
          }
          store.reload();
        }}
      />
    </>
  );
});
