// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 {
  border-radius: 4px;
  overflow: hidden;
  height: max-content;
  background-color: #FAFAFA;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__boxTitle--lMriO {
  padding: 16px 24px;
  font-weight: 700;
  background-color: #F0F0F0;
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__fields--pdNsq {
  padding: 12px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 .ant-form-item {
  margin-bottom: 0 !important;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__viewButton--vDRq7 {
  width: 100%;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__editor--yihi5 {
  background-color: #FFFFF0;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__editor--yihi5 > .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__boxTitle--lMriO {
  background-color: #f9e2a7;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__twoCols--HKBHk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__threeCols--TnZxY {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__threeCols--TnZxY .ant-form-item-control-input-content > div {
  width: 100%;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__divider--t_3Co {
  margin: 0 !important;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__divider--t_3Co .ant-divider-inner-text {
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/MetaInfo/EditorInfo.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;AALA;EAOQ,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACR;AAbA;EAeQ,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACR;AAnBA;EAsBQ,2BAAA;AAAR;AAtBA;EA0BQ,WAAA;AADR;AAIA;EAAS,yBAAA;AADT;AAEA;EAAqB,yBAAA;AACrB;AACA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AACA;EACI,aAAA;EACA,kCAAA;EACA,gBAAA;AACJ;AAJA;EAMQ,WAAA;AACR;AAGA;EACI,oBAAA;AADJ;AAAA;EAGQ,eAAA;AAAR","sourcesContent":[".box {\n    border-radius: 4px;\n    overflow: hidden;\n    height: max-content;\n    background-color: #FAFAFA;\n\n    .boxTitle {\n        padding: 16px 24px;\n        font-weight: 700;\n        background-color: #F0F0F0;\n        display: flex;\n        align-items: center;\n        column-gap: 12px;\n    }\n    .fields {\n        padding: 12px 24px 24px;\n        display: flex;\n        flex-direction: column;\n        gap: 16px;\n    }\n    // Убрать пустое место под элементом формы. Вместо него gap\n    :global(.ant-form-item) {\n        margin-bottom: 0 !important;\n    }\n\n    .viewButton {\n        width: 100%;\n    }\n}\n.editor {background-color: #FFFFF0;}\n.editor > .boxTitle {background-color: #f9e2a7;}\n\n.twoCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 16px;\n}\n.threeCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    column-gap: 16px;\n\n    :global(.ant-form-item-control-input-content) > div {\n        width: 100%;\n    }\n}\n\n.divider {\n    margin: 0 !important;\n    :global(.ant-divider-inner-text) {\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43`,
	"boxTitle": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__boxTitle--lMriO`,
	"fields": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__fields--pdNsq`,
	"viewButton": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__viewButton--vDRq7`,
	"editor": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__editor--yihi5`,
	"twoCols": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__twoCols--HKBHk`,
	"threeCols": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__threeCols--TnZxY`,
	"divider": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__divider--t_3Co`
};
export default ___CSS_LOADER_EXPORT___;
