import z from "zod";

export const zTechpackSettings = z.object({
  techpackObjectId: z.number().nullable(),
  techpackUrl: z.string().nullable(),
  articleAttributeId: z.number().optional().nullable(),
  supplierObjectId: z.number().optional().nullable(),
  supplierNameAttributeId: z.number().optional().nullable(),
});

export type ZTechpackSettings = z.infer<typeof zTechpackSettings>;
