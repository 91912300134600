import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { AttrArticleMetaObjRef } from "./AttrArticleMetaObjRef";

export type ArticleAttrMetaCtrlFC = React.FC<PropsArticleAttrMetaCtrl>;
type PropsArticleAttrMetaCtrl = {
  attr: ZAttribute;
  value?: unknown;
  onChange?: (v: unknown) => void;
};
const metaContollerMap: Partial<
  Record<AttrTypeName, React.FC<PropsArticleAttrMetaCtrl>>
> = {
  [AttrTypeName.object]: AttrArticleMetaObjRef,
};
export const getAttrArticleMetaFC = (type: AttrTypeName) =>
  metaContollerMap[type] ?? null;
