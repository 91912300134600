import * as React from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { Button, Empty, Spin, Tooltip } from "antd";
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { notificationsStore } from "src/common/notificationsStore";
import { classNames } from "src/common/classNames";
import { onError } from "src/common/onError";
import { t } from "i18next";
import { viewDateTimeUtc } from "src/common/formats";
import styles from "./ExtNotoficationsPopup.module.less";
import { NotificationText } from "./NotificationText";
/* eslint jsx-a11y/click-events-have-key-events: "off" */
/* eslint jsx-a11y/control-has-associated-label: "off" */
/* eslint jsx-a11y/no-static-element-interactions: "off" */

interface PropsExtNotoficationsPopup {
  open: boolean;
  close(): void;
}

export const ExtNotoficationsPopup: React.FC<PropsExtNotoficationsPopup> =
  observer((props) => {
    const { open, close } = props;
    const { unreaded, notificationsLoading, toReaded, isAllReaded } =
      notificationsStore;
    const onKey = (e: KeyboardEvent) => {
      if (e.code === "Escape") close();
    };
    React.useEffect(() => {
      if (!open) return undefined;
      window.addEventListener("keydown", onKey);
      return () => {
        window.removeEventListener("keydown", onKey);
        notificationsStore.saveReaded().catch(onError);
      };
    }, [open]);
    if (!open) return null;
    return (
      <>
        <div className={styles.mask} onClick={() => close()} />
        <div className={styles.panel}>
          <div className={styles.header}>
            <div className={styles.title}>{t("Notifications")}</div>
            {!isAllReaded && (
              <Button
                size="small"
                type="text"
                onClick={() => notificationsStore.addReadedAll()}
              >
                <div className={styles.allReaded}>
                  <div className={styles.readButton} />
                  <div>{t("Mark everything as read")}</div>
                </div>
              </Button>
            )}
            <Button
              type="text"
              icon={<ReloadOutlined />}
              onClick={() => notificationsStore.updateNotifications()}
              title={t("Refresh")}
              disabled={notificationsLoading}
            />
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={close}
              title={t("Close")}
            />
          </div>
          <div className={styles.listBox}>
            <Spin spinning={notificationsLoading}>
              {unreaded.length === 0 && <Empty />}
              {unreaded.map((it) => (
                <div key={it.id} className={styles.listItem}>
                  <div className={styles.itemLeftPart}>
                    <div className={styles.time}>
                      {timeFormat(it.eventTimestamp)}
                    </div>
                    <div className={styles.itemTitle}>{it.subject}</div>
                    <NotificationText text={it.text} />
                  </div>
                  <div className={styles.itemRightPart}>
                    <Readed
                      onClick={() => notificationsStore.addReaded(it)}
                      readed={toReaded.has(it.id)}
                    />
                  </div>
                </div>
              ))}
            </Spin>
          </div>
        </div>
      </>
    );
  });

const timeFormat = (srcTime: string): string => {
  const time = dayjs(srcTime);
  if (!time.isValid) return srcTime;
  // TODO: Взято с макета. Однако это может быть неправильно для других локалей
  return viewDateTimeUtc(srcTime, "DD.MM.YYYY • HH:mm");
};

interface PropsReaded {
  readed: boolean;
  onClick: () => void;
}

const Readed: React.FC<PropsReaded> = ({ onClick, readed }) => {
  if (readed) return null;
  return (
    <Tooltip
      title={t("Marked by reading")}
      placement="bottomRight"
      mouseEnterDelay={1}
    >
      <button
        type="button"
        className={classNames([
          styles.readButton,
          [readed, styles.invisibleButton],
        ])}
        onClick={onClick}
      />
    </Tooltip>
  );
};
