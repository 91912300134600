/**
 * Менеджер справочников. Версия 2.
 */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { LayoutWithSider, AddButton } from "src/components/LayoutWithSider";
import { ModelessForm } from "src/components/ModelessForm";
import { VerticalLayout } from "src/components/VerticalLayout";
import { ModalDelete } from "src/components/ModalDelete";
import { ZAttribute } from "src/types/ZAttribute";
import { GroupFields2, GroupFields2Buttons } from "./GroupFields2/GroupFields2";
import { GroupsTreeView } from "./GroupsTreeView";
import { Dict2TabStore } from "./Dict2TabStore";
import { AttrFields2, AttrFields2Buttons } from "./AttrFields2";

interface PropsDict2Tab {
  store: Dict2TabStore;
}

export const Dict2Tab: React.FC<PropsDict2Tab> = observer(({ store }) => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <LoaderBox
      remoteData={store.data}
      drawReady={() => <Inner store={store} />}
    />
  );
});

const Inner: React.FC<PropsDict2Tab> = observer(({ store }) => {
  const { currentItem } = store;
  return (
    <LayoutWithSider
      siderHeader={
        <AddButton
          onClick={() => store.safeCreateGroup()}
          id="addDictGroup"
          text="Добавить группу"
        />
      }
      siderContent={<GroupsTreeView store={store} />}
    >
      <VerticalLayout>
        <ModelessForm
          store={store.formStore}
          submit="Сохранить"
          extraButtons={
            currentItem?.type === "group" ? (
              <GroupFields2Buttons store={store} />
            ) : (
              <AttrFields2Buttons
                store={store}
                attr={store.currentEntity as ZAttribute}
              />
            )
          }
        >
          {currentItem?.type === "group" && <GroupFields2 store={store} />}
          {currentItem?.type === "attr" && (
            <AttrFields2
              store={store}
              attr={store.currentEntity as ZAttribute}
            />
          )}
        </ModelessForm>
      </VerticalLayout>
      <ModalDelete
        open={store.deleteState !== "none"}
        message={store.messageForDelete}
        waiting={store.deleteState === "work"}
        onOk={() => store.doDeleteCurNode()}
        onCancel={() => store.setDeleteState("none")}
      />
    </LayoutWithSider>
  );
});
