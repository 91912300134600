import { observer } from "mobx-react-lite";
import * as React from "react";
import { WidthLimitedFields } from "src/pages/ManagementPage/WidthLimitedFields";
import { ExtraTools } from "src/pages/ManagementPage/ExtraTools";
import { ValueO2 } from "../../Obj2Nodes";
import { Obj2TabStore } from "../../Obj2TabStore";
import { UpToObject } from "../UpToObject";
import styles from "./ValueForm2.module.less";
import { AddValueItem } from "./AddValueItem";

interface PropsValueForm2 {
  store: Obj2TabStore;
  valueNode: ValueO2;
}

export const ValueForm2: React.FC<PropsValueForm2> = observer((props) => {
  const { valueNode } = props;
  return <WidthLimitedFields>{valueNode.value.name}</WidthLimitedFields>;
});

export const ValueFormButtons: React.FC<PropsValueForm2> = observer(
  ({ store, valueNode }) => {
    const { things } = valueNode;
    const noUpdate = things.status !== "ready" || !valueNode.actions.update;
    return (
      <div className={styles.buttons}>
        <UpToObject store={store} />
        <ExtraTools>
          <AddValueItem
            store={store}
            valueNode={valueNode}
            disabled={noUpdate}
          />
        </ExtraTools>
      </div>
    );
  },
);
