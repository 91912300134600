import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageLayout } from "src/components/PageLayout";
import { LoaderBox } from "src/components/LoaderBox";
import { FormWithBlocks } from "src/components/FormWithBlocks";
import { notification } from "antd";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { ZTestRecord } from "../testTableApi";
import { testCardStore as store } from "./TestCardStore";

interface PropsInner {
  record?: ZTestRecord;
  submit: (values: ZTestRecord) => Promise<ZTestRecord>;
  onSuccess: (values: ZTestRecord) => void;
}
const Inner: React.FC<PropsInner> = observer(
  ({ record, submit, onSuccess }) => (
    <FormWithBlocks
      name="testForm"
      store={store.formStore}
      block={store.formBlocks}
      initialData={record}
      submit={submit}
      onSuccess={onSuccess}
      submitText={store.isNew ? "Создать" : "Применить"}
    />
  ),
);

export const TestCardEditPage: React.FC = observer(() => {
  const { id } = useParams();
  React.useEffect(() => {
    store.init(id);
  }, [id]);
  return (
    <PageLayout pageTitle={store.pageHeader}>
      <LoaderBox
        remoteData={store.data}
        drawReady={(rec) => (
          <Inner
            record={rec}
            submit={(values: ZTestRecord) => store.save(values)}
            onSuccess={() => {
              notification.success({ message: "Обновление выполнено" });
            }}
          />
        )}
      />
    </PageLayout>
  );
});

export const TestCardNewPage: React.FC = observer(() => {
  React.useEffect(() => {
    store.init();
  }, []);
  const navigate = useNavigate();
  return (
    <PageLayout pageTitle={store.pageHeader}>
      <Inner
        record={{ status: "begin" } as ZTestRecord}
        submit={(values: ZTestRecord) => store.create(values)}
        onSuccess={({ id, name }) => {
          notification.success({
            message: `Выполнено создание объекта "${name}"`,
          });
          navigate(makeUrl(PageUrl.testCardEdit, { id }));
        }}
      />
    </PageLayout>
  );
});
