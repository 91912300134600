import * as React from "react";
import { observer } from "mobx-react-lite";
import { AddButton, LayoutWithSider } from "src/components/LayoutWithSider";
import { ModelessForm } from "src/components/ModelessForm";
import { LoaderBox } from "src/components/LoaderBox";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ModalDelete } from "src/components/ModalDelete";
import { DashboardFields } from "./DashboardFields";
import { DashboardsList } from "./DashboardsList";
import { DashboardTabStore } from "./DashboardTabStore";

interface PropsDashboardTab {
  store: DashboardTabStore;
}

export const DashboardTab: React.FC<PropsDashboardTab> = observer(
  ({ store }) => {
    React.useEffect(() => {
      store.init();
    }, []);
    return (
      <LoaderBox
        remoteData={store.data}
        drawReady={() => <Inner store={store} />}
      />
    );
  },
);

const Inner: React.FC<PropsDashboardTab> = observer(({ store }) => (
  <LayoutWithSider
    siderHeader={
      <AddButton
        onClick={() => store.safeCreateGroup()}
        id="addDictGroup"
        text="Добавить группу"
      />
    }
    siderContent={<DashboardsList store={store} />}
  >
    <ModelessForm
      store={store.formStore}
      submit="Сохранить изменения"
      extraButtons={
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => store.tryToDelete()}
        >
          Удалить группу
        </Button>
      }
    >
      <DashboardFields store={store} />
    </ModelessForm>
    <ModalDelete
      open={store.deleteState !== "none"}
      onCancel={() => store.cancelDelete()}
      message="Удалить группу?"
      onOk={() => store.doDelete()}
    />
  </LayoutWithSider>
));
