import * as React from "react";
import { t } from "i18next";
import { Button, Form, FormRule, Input, notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { appStore } from "src/appStore";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { EdPasswordChange } from "./EdPasswordChange";
import { UserAccountPageStore } from "../userAccountPageStore";
import styles from "./PasswordChangeBlock.module.less";

interface CheckRule {
  label: string;
  check: (
    value: string,
    ctx: {
      login: string;
    },
  ) => boolean;
}
const makeCheckRules = (): CheckRule[] => [
  {
    label: t("PasswordProps.at least N characters", { count: 8 }),
    check: (value: string): boolean => value.length >= 8,
  },
  {
    label: t("PasswordProps.contain numbers", { n: "(0-9)" }),
    check: (value: string): boolean => /\d/.test(value),
  },
  {
    label: t("PasswordProps.lowercase Latin letters", { n: "(a-z)" }),
    check: (value: string): boolean => /[a-z]/.test(value),
  },
  {
    label: t("PasswordProps.uppercase Latin letters", { n: "(A-Z)" }),
    check: (value: string): boolean => /[A-Z]/.test(value),
  },
  {
    label: `${t("PasswordProps.special characters")} (!@#$%+=&?*"{}^_.,:;<>/)`,
    check: (value: string): boolean => /[!@#$%+=&?*"{}^_.,:;<>/]/.test(value),
  },
  {
    label: t("PasswordProps.password cannot be the same as login"),
    check: (value, ctx) => value !== ctx.login,
  },
];

let checkRules: CheckRule[] | undefined;
const getCheckRules = (): CheckRule[] => {
  if (checkRules) return checkRules;
  const rules = makeCheckRules();
  checkRules = rules;
  return checkRules;
};

const shortPassField = (key: keyof EdPasswordChange) => key;

const PasswordCheck: React.FC = () => {
  const newPass = Form.useWatch(shortPassField("newPass")) ?? "";
  const ctx = {
    login: appStore.userInfo.userName,
  };
  return (
    <div className={styles.passBlock}>
      <div>{t("Password must contain")}:</div>
      {getCheckRules().map(({ label, check }) => (
        <div key={label} className={styles.rule}>
          {check(newPass, ctx) ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
          <span>{label}</span>
        </div>
      ))}
    </div>
  );
};

const validatePass: FormRule = {
  validator: (_, value: string | undefined): Promise<void> => {
    if (value) {
      const ctx = {
        login: appStore.userInfo.userName,
      };
      const wrong = getCheckRules().find(({ check }) => !check(value, ctx));
      if (wrong) {
        return Promise.reject(
          Error(t("PasswordProps.Not done", { n: wrong.label })),
        );
      }
    }
    return Promise.resolve();
  },
};

const validatePass2: FormRule = ({ getFieldValue }) => ({
  validator: (_rule, value: string | undefined) =>
    value !== getFieldValue(shortPassField("newPass"))
      ? Promise.reject(Error(t("Doesn't match")))
      : Promise.resolve(),
});

interface PropsPasswordChangeBlock {
  store: UserAccountPageStore;
}

export const PasswordChangeBlock: React.FC<PropsPasswordChangeBlock> = observer(
  ({ store }) => {
    const { changingPass } = store;
    return (
      <Form<EdPasswordChange>
        className={styles.passForm}
        layout="vertical"
        onFinish={(values: EdPasswordChange) => {
          store
            .changePassword(values)
            .then((ok) => {
              if (ok)
                notification.success({
                  message: t("Password change completed"),
                });
            })
            .catch(onError);
        }}
      >
        <Form.Item
          name={shortPassField("oldPass")}
          label={t("Current password")}
          rules={[{ required: true }]}
        >
          <Input.Password autoComplete="current-password" />
        </Form.Item>
        <PasswordCheck />
        <Form.Item
          name={shortPassField("newPass")}
          label={t("New password")}
          rules={[{ required: true }, validatePass]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          name={shortPassField("newPass2")}
          label={t("Repeat new password")}
          rules={[{ required: true }, validatePass2]}
          dependencies={[shortPassField("newPass")]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={changingPass}>
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);
