import { TreeDataNode } from "antd";
import React from "react";

export const findPath = (
  list: TreeDataNode[],
  targetKey: React.Key,
): React.Key[] | null => {
  let path: React.Key[] = [];
  // Helper function to find the path recursively
  const find = (node: TreeDataNode): React.Key[] | null => {
    // Add the current node's key to the path
    path.push(node.key);

    // If the current node is the target node, return the path
    if (node.key === targetKey) {
      return path;
    }

    // If the node has children, recursively search them
    if (node.children) {
      for (let i = 0; i < node.children.length; i += 1) {
        const childNode = node.children[i];
        if (childNode) {
          const foundPath = find(childNode);
          if (foundPath) {
            return foundPath;
          }
        }
      }
    }

    // If the target node is not found in the current subtree, remove the current node from the path
    path.pop();
    return null;
  };

  // Start searching for the path from the root level
  for (let i = 0; i < list.length; i += 1) {
    const childNode = list[i];
    if (childNode) {
      const pathCopy = [...path]; // Make a copy of the path array for each branch
      const foundPath = find(childNode);
      if (foundPath) {
        return foundPath.reverse(); // Reverse the path to get it from leaf to root
      }
      path = pathCopy; // Reset path for the next iteration
    }
  }

  // If the target node is not found in the tree, return null
  return null;
};
