// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-MCEntityCopyFrom-MCEntityCopyFrom-module__tableBox--co32d {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-MCEntityCopyFrom-MCEntityCopyFrom-module__tableContent--SpbVH {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-MCEntityCopyFrom-MCEntityCopyFrom-module__toolbar--rpKka {
  padding: 8px;
  display: flex;
  flex-direction: row;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-MCEntityCopyFrom-MCEntityCopyFrom-module__prompt--nv9vl {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/NewMCEntity/MCEntityCopyFrom/MCEntityCopyFrom.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACA;EACI,OAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACA;EACI,OAAA;AACJ","sourcesContent":[".tableBox {\n    height: 100%;\n    overflow: auto;\n    display: flex;\n    flex-direction: column;\n}\n.tableContent {\n    flex: 1;\n    overflow: auto;\n    display: flex;\n    flex-direction: column;\n}\n.toolbar {\n    padding: 8px;\n    display: flex;\n    flex-direction: row;\n}\n.prompt {\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-MCEntityCopyFrom-MCEntityCopyFrom-module__tableBox--co32d`,
	"tableContent": `src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-MCEntityCopyFrom-MCEntityCopyFrom-module__tableContent--SpbVH`,
	"toolbar": `src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-MCEntityCopyFrom-MCEntityCopyFrom-module__toolbar--rpKka`,
	"prompt": `src-businessServices-services-bsMeasurementChart-MChartEntity-NewMCEntity-MCEntityCopyFrom-MCEntityCopyFrom-module__prompt--nv9vl`
};
export default ___CSS_LOADER_EXPORT___;
