export type GroupChecked = "all" | "none" | "partial";

export const groupChecked = <T>(
  group: T[],
  checked: (item: T) => boolean,
  initial?: GroupChecked,
): GroupChecked | undefined => {
  // Если группа не содержит элементов, то значение не определено.
  // Если уже известно, что заполнение частичное, то нет смысла проверять дальше.
  if (initial === "partial" || group.length === 0) return initial;
  const count = group.reduce((acc, item) => acc + (checked(item) ? 1 : 0), 0);
  if (count === 0) {
    return initial === undefined || initial === "none" ? "none" : "partial";
  }
  if (count === group.length) {
    return initial === undefined || initial === "all" ? "all" : "partial";
  }
  return "partial";
};

export const isChecked = (status: GroupChecked | undefined): boolean =>
  status === "all";

export const combineChecked = (
  a: GroupChecked | undefined,
  b: GroupChecked | undefined,
): GroupChecked | undefined => {
  if (a === b || b === undefined) return a;
  if (a === undefined) return b;
  return "partial";
};
