import * as React from "react";
import { filterDefined } from "src/common/filterDefined";
import { VerticalLayout } from "src/components/VerticalLayout";
import { FormBlockDef } from "../FormWithBlocks.types";
import { makeId } from "./makeId";

/**
 * Статический список блоков
 * @param key
 * @param subBlocks
 */

interface BlockListOptions {
  title?: string;
}

export const blocksList = (
  key: string,
  subBlocks: (FormBlockDef | undefined)[],
  options?: BlockListOptions,
): FormBlockDef => ({
  key,
  title: options?.title ?? key,
  subBlocks,
  render: (prevId, ctx) => (
    <VerticalLayout key={key}>
      {filterDefined(subBlocks).map((block) => (
        <React.Fragment key={block.key}>
          {block.render(makeId(prevId, key), ctx)}
        </React.Fragment>
      ))}
    </VerticalLayout>
  ),
});
