import * as React from "react";
import { Form, Spin } from "antd";
import {
  BlockContext,
  FormBlockDef,
  RenderResult,
} from "src/components/FormWithBlocks";
import { ZGroup } from "src/types/ZGroup";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";
import { ZValueThings, zValueThings } from "src/types/ZValueThings";
import { makeId } from "src/components/FormWithBlocks/blockTypes/makeId";
import { makeBlockId } from "src/components/FormWithBlocks/blockTypes/makeBlockId";
import { getValidStateId } from "src/common/getValidStateId";
import { apiObjUrl } from "src/common/apiUrl";
import { langHdr } from "src/lang/langHdr";
import { EntityGroupBlock } from "../EntityGroupBlock";
import { buildMnenoGroup } from "./buildMnemoGroup";
import { BuilderCtx } from "./BuilderCtx";

export const dependentGroup = (
  group: ZGroup,
  builderCtx: BuilderCtx,
): FormBlockDef => ({
  key: String(group.id),
  render(prevId: string, blockCtx: BlockContext): RenderResult {
    // Здесь потребовалась двойная вложенность. Потому что useWatch должен безусловно получить attributeId
    // Поэтому все условия проверяются тут. А хуки внутри DependentGroup
    const { attributeId } = group;
    if (!attributeId) return <div>Invalid attributeId</div>;
    return (
      <DependentGroup
        groupId={group.id}
        attributeId={attributeId}
        title={group.name}
        blockCtx={blockCtx}
        builderCtx={builderCtx}
        prevId={makeId(prevId, this.key)}
      />
    );
  },
});

interface PropsDependentGroup {
  attributeId: number;
  title: string;
  groupId: number;
  prevId: string;
  blockCtx: BlockContext;
  builderCtx: BuilderCtx;
}

const DependentGroup: React.FC<PropsDependentGroup> = (props) => {
  const {
    title,
    prevId,
    attributeId,
    groupId,
    blockCtx: ctx,
    builderCtx,
  } = props;
  const currentAttrValue = Form.useWatch(String(attributeId));
  const [buzy, setBuzy] = React.useState(false);
  const [things, setThings] = React.useState<ZValueThings | null>(null);
  React.useEffect(() => {
    if (!currentAttrValue) {
      setThings(null);
      return;
    }
    setBuzy(true);
    rest
      .get(
        apiObjUrl(
          `/groups/${groupId}/values/${currentAttrValue}/${getValidStateId(builderCtx.stateId)}`,
        ),
        {
          headers: langHdr(),
        },
      )
      .then(({ data }) => {
        setThings(zValueThings.parse(data));
      })
      .finally(() => {
        setBuzy(false);
      })
      .catch(onError);
  }, [currentAttrValue]);
  if (!things) return null;
  const block = buildMnenoGroup(
    "",
    "",
    things.attributes,
    things.groups,
    builderCtx,
  );
  return (
    <Spin spinning={buzy}>
      <EntityGroupBlock
        title={title}
        prevId={prevId}
        ctx={ctx}
        items={block.items ?? []}
        subBlocks={block.subBlocks ?? []}
        id={makeBlockId(block.key)}
      />
    </Spin>
  );
};
