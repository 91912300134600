import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import { DrawTab } from "./DrawTab";
import {
  ZSchedSettingsPlanTemplate,
  ZSchedulingSettings,
} from "../../SchedulingTypes";
import { SchedulingObjType } from "../SchedulingSettingsStore";

interface PropsPlanTemplateFields {
  store: FormObjectSectionsStore<SchedulingObjType>;
}

const fieldName = (
  name: keyof ZSchedSettingsPlanTemplate,
): ["planTemplateSettings", keyof ZSchedSettingsPlanTemplate] => [
  "planTemplateSettings",
  name,
];

export const PlanTemplateFields: React.FC<PropsPlanTemplateFields> = observer(
  ({ store }) => (
    <DrawTab>
      <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
        store={store}
        objKey={fieldName("templateObjectId")}
        objType="planTemplate"
        objLabel="Объект для формирования шаблона плана"
        title="Список обязательных позиций"
        attrs={[
          {
            attrKey: fieldName("nameAttributeId"),
            attrLabel: "Укажите атрибут названия плана",
            attrType: AttrTypeName.string,
          },
          {
            attrKey: fieldName("groupTaskListAttributeId"),
            attrLabel: "Укажите атрибут списка групп задач, включенных в план",
            attrType: AttrTypeName.childEntities,
          },
        ]}
      />
    </DrawTab>
  ),
);
