export const makeAuthMsg = (resume: string) =>
  JSON.stringify({
    msg: "method",
    method: "login",
    id: "login",
    params: [{ resume }],
  });

export const makeConnectMsg = () =>
  JSON.stringify({
    msg: "connect",
    version: "1",
    support: ["1"],
  });

export const makePongMsg = () =>
  JSON.stringify({
    msg: "pong",
  });

export const makeSubscribeRoomMessagesMsg = (roomId: string) =>
  JSON.stringify({
    msg: "sub",
    id: `subscribeRoomMessages-${roomId}`,
    name: "stream-room-messages",
    params: [roomId, false],
  });

export const makeJoinRoomMsg = (roomId: string) =>
  JSON.stringify({
    msg: "method",
    method: "joinRoom",
    id: `joinRoom-${roomId}`,
    params: [roomId],
  });
