import React, { useState, DragEvent } from "react";
import { classNames } from "src/common/classNames";
import styles from "./SortableList.module.less";

export type SortableItemDef = {
  key: React.Key;
  content: React.ReactElement;
  render?: (item: SortableItemDef) => React.ReactNode;
};

export type PropsSortableList = {
  items: SortableItemDef[];
  containerClassName?: string;
  itemClassName?: string;
  onChange?: (result: SortableItemDef[]) => void;
};

export const SortableList: React.FC<PropsSortableList> = ({
  items,
  containerClassName,
  itemClassName,
  onChange,
}) => {
  const [itemsInner, setItemsInner] = useState<SortableItemDef[]>(items);
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  React.useEffect(() => {
    setItemsInner(items);
  }, [items]);

  const onDragStart = (index: number) => () => {
    setDraggedItemIndex(index);
  };

  const onDragOver = (index: number) => (event: DragEvent<HTMLLIElement>) => {
    event.preventDefault();
    if (draggedItemIndex === null) return;

    const dragOverItemIndex = index;
    if (draggedItemIndex === dragOverItemIndex) {
      return;
    }

    const itemsCopy = [...itemsInner];
    const itemToMove = itemsCopy.splice(draggedItemIndex, 1)[0];
    if (itemToMove) {
      itemsCopy.splice(dragOverItemIndex, 0, itemToMove);
      setDraggedItemIndex(dragOverItemIndex);
      setItemsInner(itemsCopy);
    }
  };

  const onDragEnd = () => {
    setDraggedItemIndex(null);
    onChange?.(itemsInner);
  };

  return (
    <ul className={containerClassName}>
      {itemsInner.map((item, index) => (
        <li
          className={classNames([styles.item, itemClassName])}
          key={item.key}
          draggable
          onDragStart={onDragStart(index)}
          onDragOver={onDragOver(index)}
          onDragEnd={onDragEnd}
        >
          {item.render ? item.render(item) : item.content}
        </li>
      ))}
    </ul>
  );
};

SortableList.defaultProps = {
  containerClassName: undefined,
  itemClassName: undefined,
  onChange: undefined,
};
