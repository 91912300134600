import * as React from "react";
import { observer } from "mobx-react-lite";
import { Point } from "../../../math";
import { ZModelAttr } from "../../../ZModel";
import { DrawArrow } from "./DrawArrow";
import { DrawProps } from "../../DrawProps";

interface PropsDrawFigure {
  className: string;
  attr: ZModelAttr;
  drawProps: DrawProps;
  onClick?: () => void;
  onStartCopy?: (pagePos: Point) => void;
}

export const DrawFigure: React.FC<PropsDrawFigure> = observer(
  (props: PropsDrawFigure) => {
    const { className, attr, drawProps, onClick, onStartCopy } = props;
    const a = new Point(attr.xA, attr.yA);
    const b = new Point(attr.xB, attr.yB);
    const { xC, yC } = attr;
    const c =
      xC !== undefined && yC !== undefined ? new Point(xC, yC) : undefined;
    let mid = a.plus(b).times(0.5);
    if (c) mid = mid.plus(c).times(0.5);
    const path = c
      ? `M${a.x} ${a.y} Q${c.x} ${c.y} ${b.x} ${b.y}`
      : `M${a.x} ${a.y}L${b.x} ${b.y}`;
    let arr1beg = a;
    const arr1end = b;
    let arr2beg = b;
    const arr2end = a;
    if (c) {
      arr1beg = c;
      arr2beg = c;
    }
    return (
      <g
        className={className}
        onClick={onClick}
        onMouseMove={(e) => {
          e.currentTarget.style.cursor = e.ctrlKey ? "copy" : "default";
        }}
        onMouseDown={(e) => {
          if (e.ctrlKey) {
            e.stopPropagation();
            onStartCopy?.(new Point(e.pageX, e.pageY));
          }
        }}
      >
        <path className="line" d={path} />
        <DrawArrow from={arr1beg} to={arr1end} drawProps={drawProps} />
        <DrawArrow from={arr2beg} to={arr2end} drawProps={drawProps} />
        <text x={mid.x} y={mid.y}>
          {attr.attrName}
        </text>
      </g>
    );
  },
);
