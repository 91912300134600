import * as React from "react";
import { Modal, ModalProps } from "antd";
import styles from "./ModalVertFixed.module.less";
/* eslint react/no-unstable-nested-components: "off" */

type PropsModalVertFixed = ModalProps & {
  height?: number | string; // Examples: "90vh" or "300px" = 300
};
const defaultHeight = "80vh";

/**
 * Модальное окно с фиксированной высотой.
 * Требуется в тех случаях, когда нужно показать скроллируемые элементы в сочетании с фиксированными.
 * Предполагается что содержимое должно занять всю возможную высоту (height: 100%) и организовать скроллирование.
 * Например, таким компонентом является ScrollableTable
 * @param props
 * @returns
 */
export const ModalVertFixed: React.FC<PropsModalVertFixed> = (props) => {
  const { height, modalRender, ...restProps } = props;
  return (
    <Modal
      {...restProps}
      modalRender={(content) => (
        <Inner
          content={content}
          modalRender={modalRender}
          height={height ?? defaultHeight}
        />
      )}
    />
  );
};

ModalVertFixed.defaultProps = {
  height: defaultHeight,
};

interface PropsInner {
  modalRender: ModalProps["modalRender"];
  content: React.ReactNode;
  height: number | string;
}

const Inner: React.FC<PropsInner> = ({ modalRender, content, height }) => (
  <div style={{ height }} className={styles.modal}>
    {modalRender ? modalRender(content) : content}
  </div>
);
