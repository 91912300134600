import { z } from "zod";

export const enum Permission {
  objUpdate = "OBJECT_UPDATE",
  objDelete = "OBJECT_DELETE",

  attrUpdate = "ATTRIBUTE_UPDATE",
  attrDelete = "ATTRIBUTE_DELETE",
  attrView = "ATTRIBUTE_VIEW",
  attrCreate = "ATTRIBUTE_CREATE",

  entityCreate = "ENTITY_CREATE",
  entityDelete = "ENTITY_DELETE",
  entityUpdate = "ENTITY_UPDATE",
  entityView = "ENTITY_VIEW",
}

// Пока список строк. Чтобы не падало при изменениях. Но в перспективе можно будет ограничить только Permission
export const zPermissionsList = z.string().array();
export type ZPermissionsList = z.infer<typeof zPermissionsList>;

type WithPermissions = { permissions?: ZPermissionsList | null };

export const hasPermissionIn = (
  item: WithPermissions,
  what: Permission,
): boolean => item.permissions?.includes(what) ?? true;
