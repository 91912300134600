import {
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  HolderOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { Button, Spin, Upload, UploadFile, UploadProps } from "antd";
import React, { DragEvent } from "react";
import { t } from "i18next";
import { classNames } from "src/common/classNames";
import { UploadInfo } from "src/components/PlmUploader";
import styles from "./ImageCarouselDragger.module.less";

const { Dragger } = Upload;

interface PropsImageCarouselDragger {
  fileList: UploadFile[];
  uploadProps: UploadProps;
  onDelete: (index: number) => void;
  onOrderChange: (list: UploadFile[]) => void;
  maxSize?: number;
}

export const ImageCarouselDragger: React.FC<PropsImageCarouselDragger> = (
  props,
) => {
  const { fileList, uploadProps, maxSize, onOrderChange, onDelete } = props;
  const { maxCount, accept, disabled } = uploadProps;
  const [dragIndex, setDragIndex] = React.useState<number | null>(null);
  const [dragList, setDragList] = React.useState<UploadFile[]>(fileList);

  React.useEffect(() => {
    setDragList(fileList);
  }, [fileList]);

  const onDragStart = (index: number) => () => {
    setDragIndex(index);
  };

  const onDragOver = (index: number) => (event: DragEvent<HTMLLIElement>) => {
    event.preventDefault();
    if (dragIndex === null) return;
    const dragOverItemIndex = index;
    if (dragIndex === dragOverItemIndex) {
      return;
    }
    const itemsCopy = [...fileList];
    const itemToMove = itemsCopy.splice(dragIndex, 1)[0];
    if (itemToMove) {
      itemsCopy.splice(dragOverItemIndex, 0, itemToMove);
      setDragList(itemsCopy);
    }
  };

  const onDragEnd = () => {
    setDragIndex(null);
    onOrderChange?.(dragList);
  };
  const actionsDisabled =
    fileList.some(({ status }) => status === "uploading") || disabled;

  const draggable =
    !fileList.find(
      ({ status: itemStatus }) =>
        itemStatus === "error" || itemStatus === "uploading",
    ) && !disabled;

  return (
    <div className={styles.draggerWrapper}>
      <Dragger
        className={classNames([
          styles.modalUpload,
          [(fileList?.length ?? 0) >= (maxCount ?? 1), styles.uploadDisabled],
        ])}
        {...uploadProps}
        listType="picture"
      >
        <div className={styles.dragger}>
          <FileOutlined className={styles.draggerIcon} />
          <div>{t("Click or drag the file/s into the area to upload")}</div>
          <UploadInfo
            maxCount={maxCount ?? 1}
            accept={accept}
            maxSize={maxSize}
          />
        </div>
      </Dragger>
      <ul className={styles.fileList}>
        {dragList.map(
          ({ url, name, uid: itemUid, status, thumbUrl }, index) => (
            <li
              key={itemUid}
              className={classNames([
                styles.listItemTitle,
                [status === "error", styles.listItemTitleErr],
                [!draggable, styles.listItemNotDraggable],
              ])}
              draggable={draggable}
              onDragStart={onDragStart(index)}
              onDragOver={onDragOver(index)}
              onDragEnd={onDragEnd}
            >
              <div className={styles.listItemInfo}>
                {draggable && <HolderOutlined />}
                {status === "done" && (
                  <>
                    <img
                      className={styles.listItemImg}
                      src={url || thumbUrl}
                      alt=""
                    />
                    <div className={styles.listItemName}>{name}</div>
                  </>
                )}
                {status === "error" && (
                  <>
                    <PictureOutlined className={styles.listItemInfoErr} />
                    <div className={styles.listItemName}>{name}</div>
                  </>
                )}
                {status === "uploading" && <Spin />}
              </div>
              <div className={styles.listItemControl}>
                {url && status === "done" && (
                  <Button
                    icon={<DownloadOutlined />}
                    href={url}
                    className={styles.downloadButton}
                    disabled={actionsDisabled}
                  />
                )}
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    onDelete(index);
                  }}
                  type="text"
                  disabled={actionsDisabled}
                />
              </div>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

ImageCarouselDragger.defaultProps = {
  maxSize: undefined,
};
