import {
  ZArticleNumberMeta,
  ZArticleNumberInfo,
  ZArticleNumberInfoAttr,
} from "src/types/ZArticleNumberInfo";

export const makeFormName = (
  base: string,
  partName: keyof ZArticleNumberInfo,
) => [base, partName];
export const maskLinePartName = (base: string) =>
  makeFormName(base, "maskLine");
export const gbName = (
  base: string,
  name: keyof ZArticleNumberInfo["groupBy"],
) => [...makeFormName(base, "groupBy"), name];
export const attsName = (
  base: string,
  i: number,
  name: keyof ZArticleNumberInfoAttr,
) => [...makeFormName(base, "attributes"), i, name];
export const attsMetaName = (
  base: string,
  i: number,
  name: keyof ZArticleNumberMeta,
) => [...makeFormName(base, "attributeMetas"), i, name];
