type Result = {
  timestampFrom: string | undefined;
  timestampTo: string | undefined;
};

export const rangeToTimestamp = (range: string[]): Result | undefined => {
  if (range.length !== 2) {
    return undefined;
  }
  return {
    timestampFrom: range[0],
    timestampTo: range[1],
  };
};
