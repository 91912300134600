import { z } from "zod";

export const zAttrTextArea = z.object({
  editor: z.literal("TextArea"),
  maxLength: z.number().optional(),
  minRows: z.number().nullable().optional(),
  maxRows: z.number().nullable().optional(),
});
export type ZAttrTextArea = z.infer<typeof zAttrTextArea>;

export const makeTextAreaProps = (src: ZAttrTextArea) => ({
  maxLength: src.maxLength ?? undefined,
  autoSize:
    src.minRows || src.maxRows
      ? {
          minRows: src.minRows ?? undefined,
          maxRows: src.maxRows ?? undefined,
        }
      : false,
});
