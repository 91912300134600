import { Form } from "antd";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React from "react";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { TShapeForm } from "src/components/TShapeForm/TShapeForm";
import { EdCardValues } from "src/pages/EntityCardPage/apiEntityCard";
import { EntityCardStore } from "src/pages/EntityCardPage/EntityCardStore";
import { LoaderBox } from "src/components/LoaderBox";
import { SchedulingControlStore } from "../SchedulingControlStore";

interface PropsSchedTaskEditor {
  store: SchedulingControlStore;
}

export const SchedTaskEditor: React.FC<PropsSchedTaskEditor> = observer(
  ({ store }) => {
    const { editor } = store;
    if (!editor) return null;
    const { title, cardStore } = editor;

    return (
      <ModalVertFixed
        open={!!editor}
        onCancel={() => store.closeEditor()}
        footer={null}
        width="80vw"
        centered
      >
        <LoaderBox
          remoteData={cardStore.info}
          drawReady={() => (
            <Inner
              cardStore={cardStore}
              title={title}
              onSuccess={() => store.onUpdate()}
            />
          )}
        />
      </ModalVertFixed>
    );
  },
);

const entityCardFormName = "EntityTaskForm";
const idEntityFormContainer = "EntityTaskFormContainer";

interface PropsInner {
  cardStore: EntityCardStore;
  onSuccess(): void;
  title: string;
}

const Inner: React.FC<PropsInner> = observer(
  ({ cardStore, title, onSuccess }) => {
    const [form] = Form.useForm();
    const { rootBlock, formStore, initialData } = cardStore;
    if (!rootBlock) return null;
    const onSubmit = async (values: EdCardValues) => {
      if (cardStore.entityId) {
        await cardStore.save(values);
      } else {
        await cardStore.create(values);
      }
    };

    return (
      <TShapeForm
        title={title}
        form={form}
        formProps={{
          name: entityCardFormName,
          initialData,
          block: rootBlock,
          store: formStore,
          submit: onSubmit,
          submitText: t("Save"),
          onSuccess,
          onValuesChange(changedValues, values) {
            cardStore.onFormValuesChange(form, changedValues, values);
          },
        }}
        rightPartProps={{ id: idEntityFormContainer }}
      />
    );
  },
);
