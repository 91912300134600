/**
 * Компонент Select, который получает options при помощи асинхронного запроса
 * Во избежание лишних запросов нужно использовать React.useCallback для loader.
 * А если loader меняется, то запрос выполняется.
 * Это нужно если содержимое выпадающего списка должно меняться, при измении значения какого-то другого поля
 */

import * as React from "react";
import { Select, SelectProps } from "antd";
import { onError } from "src/common/onError";
import { DefaultOptionType } from "antd/es/select";

export type PropsSelectFromReference = Omit<SelectProps, "options"> & {
  loader: () => Promise<DefaultOptionType[]>;
};

export const SelectFromReference: React.FC<PropsSelectFromReference> = (
  props: PropsSelectFromReference,
) => {
  const { loader, ...commonProps } = props;
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<DefaultOptionType[]>([]);
  React.useEffect(() => {
    setLoading(true);
    loader()
      .then((result) => setOptions(result))
      .finally(() => setLoading(false))
      .catch(onError);
  }, [loader]);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Select {...commonProps} options={options} loading={loading} />;
};
