import * as React from "react";
import { observer } from "mobx-react-lite";
import { Empty } from "antd";
import { CategoryAttributesStore } from "./CategoryAttributesStore";
import { TreeStore } from "../CateroryTree";
import styles from "./CategoryAttributes.module.less";
import { AttrsListView } from "./AttrsListView";

/**
 * Отрисовка правой части справочника категорий.
 * Это атрибуты для той категории, которая выбрана в левой части (дереве категорий).
 */

interface PropsCategoryAttributes {
  attrStore: CategoryAttributesStore;
  treeStore: TreeStore;
}

export const CategoryAttributes: React.FC<PropsCategoryAttributes> = observer(
  (props) => {
    const { attrStore, treeStore } = props;
    React.useEffect(() => {
      attrStore.init(treeStore.getAllParents(treeStore.current?.key));
    }, [treeStore.current]);
    if (attrStore.nodes.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Выберите категорию"
        />
      );
    }
    return (
      <div className={styles.box}>
        <div className={styles.main}>
          <AttrsListView attrStore={attrStore} />
        </div>
      </div>
    );
  },
);
