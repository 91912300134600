import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { ResSplitOrders } from "./splitOrders";

export const apiGroupOrders = async (groupId: number, data: ResSplitOrders) => {
  await rest.post(apiObjUrl(`/groups/${groupId}/order`), data);
};

export const apiObjectOrders = async (
  objectId: number,
  data: ResSplitOrders,
) => {
  await rest.post(apiObjUrl(`/objects/${objectId}/order`), data);
};

export const apiValueOrders = async (
  groupId: number,
  valueId: number,
  data: ResSplitOrders,
) => {
  await rest.post(
    apiObjUrl(`/groups/${groupId}/values/${valueId}/order`),
    data,
  );
};
