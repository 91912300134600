import React from "react";
import { AColumn } from "src/components/tables/AsyncTable";

export const drawColumn = <TRow extends {}>(
  row: TRow,
  column: AColumn<TRow>,
  colIndex: number,
) => {
  const value = row[column.key as keyof TRow];
  const rendered = column.render?.(value, row, colIndex) as React.ReactNode;
  if (!value && !rendered) return "";
  return rendered || String(value);
};
