import * as React from "react";
import { Input, InputProps } from "antd";

export type PropsEntityInput = Omit<InputProps, "onChange" | "value"> & {
  value?: string[] | null;
  onChange?(newValue?: string[] | null): void;
};

export const EntityInput: React.FC<PropsEntityInput> = (props) => {
  const { value, onChange, ...restProps } = props;
  return (
    <Input
      {...restProps}
      value={value?.[0]}
      onChange={(e) => {
        onChange?.([e.currentTarget.value ?? ""]);
      }}
    />
  );
};

EntityInput.defaultProps = {
  value: undefined,
  onChange: undefined,
};
