/* eslint-disable no-restricted-syntax */

import { ZMenuItem } from "src/components/MainFrame/LeftMenu/ZMenuItem";

type Result = {
  owner: ZMenuItem | null;
  item: ZMenuItem;
  index: number;
};

export const findMenuItemOwnerById = (
  itemId: number,
  items: ZMenuItem[],
): Result | undefined => {
  for (const item of items) {
    if (item.id === itemId) {
      return { owner: null, item, index: items.indexOf(item) };
    }

    if (item.elements) {
      const index = item.elements.findIndex(({ id }) => id === itemId);
      if (index >= 0) {
        return { owner: item, item: item.elements[index] as ZMenuItem, index };
      }

      const res = findMenuItemOwnerById(itemId, item.elements);
      if (res) return res;
    }
  }

  return undefined;
};
