import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import React from "react";
import { AColumn } from "src/components/tables/AsyncTable";
import { SorterResult } from "antd/es/table/interface";
import styles from "./TableSortingButtonSdt.module.less";

type PropsTableSortingButtonSdt<TRow extends {}> = {
  header: React.ReactNode;
  column: AColumn<TRow>;
  onChange?: (sorter?: SorterResult<TRow>) => void;
};

export const TableSortingButtonSdt = <TRow extends {}>({
  header,
  column,
  onChange,
}: PropsTableSortingButtonSdt<TRow>) => {
  const { sorter, sortOrder } = column;
  let actualIcon = !!sorter && <SwapOutlined />;
  if (sortOrder) {
    actualIcon =
      sortOrder === "ascend" ? (
        <SortAscendingOutlined />
      ) : (
        <SortDescendingOutlined />
      );
  }

  const getNextOrder = () => {
    if (sortOrder === undefined) return "ascend";
    if (sortOrder === "ascend") return "descend";
    if (sortOrder === "descend") return undefined;
    return undefined;
  };

  return column.sorter ? (
    <button
      className={styles.sorter}
      type="button"
      onClick={() => {
        onChange?.({ column, columnKey: column.key, order: getNextOrder() });
      }}
    >
      {header}
      {actualIcon}
    </button>
  ) : (
    column.title
  );
};

TableSortingButtonSdt.defaultProps = { onChange: undefined };
