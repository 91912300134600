import { z } from "zod";
import { ZAttrMeta } from "src/types/ZAttrMeta";
import { ZAttrViewInfo } from "../zAttrViewInfo";

export const zModeObjectRef = z.enum(["standart", "tag", "link"]);
export type ZModeObjectRef = z.infer<typeof zModeObjectRef>;

export const zModeObjectRefMeta = z.object({
  tagColor: z.string().optional(),
});

export const zObectRef = z.object({
  view: z.literal("ObectRef"),
  mode: zModeObjectRef.optional(),
  modeMeta: zModeObjectRefMeta.optional(),
  labelAtts: z.string().array().optional().nullable(),
});
export type ZObectRef = z.infer<typeof zObectRef>;

export const makeObectRefProps = (
  attrMeta: ZAttrMeta,
  cmp: ZObectRef,
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
) => ({
  labelAtts: cmp.labelAtts || [],
  values,
  viewInfo,
  objectId: attrMeta.referenceId ?? null,
  viewProps: cmp,
});
