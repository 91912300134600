import * as React from "react";
import { observer } from "mobx-react-lite";
import { ZModel } from "../ZModel";

import { ModelEditorStore } from "./ModelEditorStore";
import { MEdToolbar } from "./MEdToolbar";
import { ModelEditorCanvas } from "./ModelEditorCanvas";
import { MEdHeader } from "./MEdHeader";

import { MEdAttrList } from "./MEdAttrList";
import styles from "./ModelEditor.module.less";

interface PropsModelEditor {
  model: ZModel | null;
  onModelStore(store: ModelEditorStore): void;
}

export const ModelEditor: React.FC<PropsModelEditor> = observer(
  (props: PropsModelEditor) => {
    const { model, onModelStore } = props;
    const [store] = React.useState<ModelEditorStore>(new ModelEditorStore());
    React.useEffect(() => {
      store.init(model);
      onModelStore(store);
    }, [model?.id]);
    return (
      <div className={styles.common}>
        <div className={styles.top}>
          <MEdHeader store={store} />
        </div>
        <div className={styles.tools}>
          <MEdToolbar store={store} />
        </div>
        <div className={styles.left}>
          <MEdAttrList store={store} />
        </div>
        <div className={styles.main}>
          {store.model && (
            <ModelEditorCanvas store={store} model={store.model} />
          )}
        </div>
      </div>
    );
  },
);
