/* eslint-disable react/jsx-props-no-spreading */
import { observer } from "mobx-react-lite";
import React from "react";
import { CheckListWithOrderStore } from "./CheckListWithOrderStore";
import {
  CheckList,
  PropsCheckList,
} from "../../../components/CheckList/CheckList";

export type PropsCheckListWithOrder = Omit<PropsCheckList, "store"> & {
  store: CheckListWithOrderStore;
};

export const CheckListWithOrder: React.FC<PropsCheckListWithOrder> = observer(
  ({ store, onChange, ...props }) => {
    const handleChange = (
      value: string,
      checked: boolean,
      selected: React.Key[],
    ) => {
      onChange?.(value, checked, selected);
      if (checked) {
        store.setOrder([...store.order, value]);
      } else {
        const filtered = store.order.filter((item) => item !== value);
        store.setOrder(filtered);
      }
      store.normalizeOrder();
    };

    const { checkListStore } = store;
    return (
      <CheckList
        store={checkListStore}
        onChange={handleChange}
        elementExtra={(opt) => {
          const index =
            store.order.findIndex(
              (item) => String(item) === String(opt.value),
            ) + 1;
          return <div>{index > 0 && `# ${String(index)}`}</div>;
        }}
        {...props}
      />
    );
  },
);
