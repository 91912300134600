import i18next from "i18next";

export const registerUrl = (param: string): string =>
  `/${i18next.language}/register/${param}`;

export const registerUrlPrompt = (): string =>
  `${window.location.host}${registerUrl("")}`;

export const getCurrentRegisterParam = (): string | undefined => {
  const { pathname } = window.location;
  const reg = /^\/[-a-zA-Z]+\/register\/([-_a-z\d]+)$/.exec(pathname);
  return reg?.[1];
};

export const registerParamPattern = /^[-_a-z\d]+$/;

export const registerParamPatternMsg = () =>
  `Допустимо вводить маленькие латинские буквы, цифры и знаки: _ -`;
