// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__formBlock--lHzmZ {
  max-width: 900px;
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 18px;
  grid-template-columns: 1fr 1fr;
  /* Если у блока будет не такой фон, как у страницы, то нужен padding по горизонтали */
  padding: 24px 0 24px 0;
}
.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__formBlock--lHzmZ.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__mono--YY0A5 {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__formBlock--lHzmZ.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__noPad--StAeu {
  padding: 0;
}
.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__formBlock--lHzmZ.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__widthNotLimited--HQcie {
  max-width: none;
  padding-right: 24px;
}
.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__formBlock--lHzmZ > * {
  margin: 0 !important;
}
.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__formBlock--lHzmZ h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #2B2B2B;
}
.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__wideField--cu8bi {
  grid-column: span 2;
}
/* Полезно для чекбоксов, у которых нет написи сверху. Чтобы верхний край совпадал с соседним элементом. */
.src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__withoutLabel--texk2 {
  padding-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Forms/FormFieldsBlock/FormFieldsBlock.module.less"],"names":[],"mappings":"AACA;EACI,gBAAA;EAEA,aAAA;EACA,qBAAA;EACA,kBAAA;EACA,8BAAA;EADF,qFAAqF;EAGnF,sBAAA;AADJ;AAIA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAFJ;AAKA;EACI,UAAA;AAHJ;AAMA;EACI,eAAA;EACA,mBAAA;AAJJ;AAOA;EAAiB,oBAAA;AAJjB;AAMA;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJJ;AAOA;EACI,mBAAA;AALJ;AACA,0GAA0G;AAQ1G;EACI,iBAAA;AANJ","sourcesContent":["@import \"../formStyles.less\";\n.formBlock {\n    max-width: 900px;\n\n    display: grid;\n    grid-column-gap: @blockVPad;\n    grid-row-gap: 18px;\n    grid-template-columns: 1fr 1fr;\n    /* Если у блока будет не такой фон, как у страницы, то нужен padding по горизонтали */\n    padding: @blockVPad 0 @blockVPad 0;\n}\n\n.formBlock.mono {\n    display: flex;\n    flex-direction: column;\n    gap: 18px;\n}\n\n.formBlock.noPad {\n    padding: 0;\n}\n\n.formBlock.widthNotLimited {\n    max-width: none;\n    padding-right: @blockVPad;\n}\n\n.formBlock > * { margin: 0 !important;}\n\n.formBlock h2 {\n    font-style: normal;\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 120%;\n    color: #2B2B2B;\n}\n\n.wideField {\n    grid-column: span 2;\n}\n\n/* Полезно для чекбоксов, у которых нет написи сверху. Чтобы верхний край совпадал с соседним элементом. */\n.withoutLabel {\n    padding-top: @blockVPad;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formBlock": `src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__formBlock--lHzmZ`,
	"mono": `src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__mono--YY0A5`,
	"noPad": `src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__noPad--StAeu`,
	"widthNotLimited": `src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__widthNotLimited--HQcie`,
	"wideField": `src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__wideField--cu8bi`,
	"withoutLabel": `src-components-Forms-FormFieldsBlock-FormFieldsBlock-module__withoutLabel--texk2`
};
export default ___CSS_LOADER_EXPORT___;
