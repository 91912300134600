// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-modals-AttrFields-module__colAndSuffix--vaF5C {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}
.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-modals-AttrFields-module__twoCol--uTpHt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryRefPage/CategoryAttributes/AttrLevelPanel/modals/AttrFields.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,+BAAA;EACA,SAAA;AACF;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AAAF","sourcesContent":[".colAndSuffix {\n  display: grid;\n  grid-template-columns: 1fr auto;\n  gap: 10px;\n}\n\n.twoCol {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colAndSuffix": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-modals-AttrFields-module__colAndSuffix--vaF5C`,
	"twoCol": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-modals-AttrFields-module__twoCol--uTpHt`
};
export default ___CSS_LOADER_EXPORT___;
