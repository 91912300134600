import * as React from "react";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";
import styles from "./MCTitle.module.less";

interface PropsMCTitle {
  szName: string;
  onAddVersion?(): void;
  disabled: boolean;
}

export const MCTitle: React.FC<PropsMCTitle> = ({
  szName,
  onAddVersion,
  disabled,
}) => (
  <div className={styles.titleBox}>
    <div className={styles.titleText}>{szName}</div>
    {onAddVersion && (
      <Button
        type="text"
        icon={<PlusCircleOutlined />}
        size="small"
        title={t("Add version")}
        onClick={onAddVersion}
        disabled={disabled}
      />
    )}
  </div>
);
MCTitle.defaultProps = {
  onAddVersion: undefined,
};
