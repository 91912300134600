import React from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { SchedulingControlStore } from "../../SchedulingControlStore";
import { SchedTemplateCreator } from "./SchedTemplateCreator";
import {
  createGroupFromTemplate,
  createPlanFromTemplate,
  createTaskFromTemplate,
} from "../../../apiScheduling";

interface PropsSchedTemplateTools {
  store: SchedulingControlStore;
}

export const SchedTemplateTools: React.FC<PropsSchedTemplateTools> = observer(
  ({ store }) => {
    const { settings, data } = store;
    if (!settings) return null;
    const {
      planTemplateSettings,
      groupTemplateSettings,
      taskTemplateSettings,
    } = settings;
    const planTemplateId = planTemplateSettings?.templateObjectId;
    const groupTemplateId = groupTemplateSettings?.templateObjectId;
    const taskTemplateId = taskTemplateSettings?.templateObjectId;
    const wrongData = data.status !== "ready";

    const menuProps: MenuProps = {
      items: [
        {
          key: "plan",
          label: t("Plan"),
          disabled: !planTemplateId,
          onClick: () => {
            if (!planTemplateId) return;
            store.openTemplateCreation(
              planTemplateId,
              t("Plan template"),
              createPlanFromTemplate,
            );
          },
        },
        {
          key: "group",
          label: t("Task group"),
          disabled: !groupTemplateId,
          onClick: () => {
            if (!groupTemplateId) return;
            store.openTemplateCreation(
              groupTemplateId,
              t("Task group template"),
              createGroupFromTemplate,
            );
          },
        },
        {
          key: "task",
          label: t("Task"),
          disabled: !taskTemplateId,
          onClick: () => {
            if (!taskTemplateId) return;
            store.openTemplateCreation(
              taskTemplateId,
              t("Task template"),
              createTaskFromTemplate,
            );
          },
        },
      ],
    };
    return (
      <>
        <Dropdown menu={menuProps} placement="bottomRight" disabled={wrongData}>
          <Button>
            <Space>
              {t("Create from template")}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        <SchedTemplateCreator store={store} />
      </>
    );
  },
);
