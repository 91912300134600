import { FormInstance, notification } from "antd";
import { z } from "zod";
import { onError } from "src/common/onError";

const ZErrorInField = z.object({
  response: z.object({
    status: z.number(),
    data: z.record(z.array(z.string()).nonempty()),
  }),
});

export const onAntFormError = (e: Error, formName: string) => {
  const res = ZErrorInField.safeParse(e);
  if (res.success) {
    const entries = Object.entries(res.data.response.data);
    if (res.data.response.status === 400 && entries.length > 0) {
      // С сервера пришло сообщение об ошибке в определенном поле (или в нескольких).
      // Мы можем вывести сообщение о первой ошибке и попытаться установить фокус на это поле.
      // Если нужно выделять поля красным,
      // то нужно делать специальный компонент формы с возможностью управлять свойствами полей.
      notification.error({ message: entries[0]![1][0] });
      const fieldName = `${formName}_${entries[0]![0]}`;
      const elem = document.getElementById(fieldName);
      elem?.focus?.();
      return;
    }
  }
  onError(e);
};

type FieldData = {
  name: string;
  errors: string[];
};

export const onAntFormFieldError = (
  form: FormInstance,
  fieldData: FieldData,
) => {
  form.setFields([fieldData]);
};
