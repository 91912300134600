import * as React from "react";
import styles from "./SampleMcPoints.module.less";

interface PropsSMcPointEditCell {
  children: React.ReactNode;
}

export const SMcPointEditCell: React.FC<PropsSMcPointEditCell> = ({
  children,
}) => <div className={styles.editCell}>{children}</div>;
