// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-UserAccountPage-UserAccountPage-module__pageContent--F_coO {
  display: flex;
  flex-direction: row;
  height: 100%;
  background: #F5F5F5;
}
.src-pages-UserAccountPage-UserAccountPage-module__anchorsBlock--quooa {
  width: 160px;
}
.src-pages-UserAccountPage-UserAccountPage-module__formsList--_Ddgi {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%;
  overflow: auto;
}
.src-pages-UserAccountPage-UserAccountPage-module__formsList--_Ddgi > div {
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.src-pages-UserAccountPage-UserAccountPage-module__formTitle--Hl1a3 {
  font-size: 16px;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserAccountPage/UserAccountPage.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AACF;AACA;EACE,YAAA;AACF;AACA;EACE,OAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,YAAA;EACA,cAAA;AACF;AAAE;EACE,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AAEJ;AACA;EACE,eAAA;EACA,gBAAA;AACF","sourcesContent":[".pageContent {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  background: #F5F5F5;\n}\n.anchorsBlock {\n  width: 160px;\n}\n.formsList {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 16px;\n  height: 100%;\n  overflow: auto;\n  & > div {\n    background-color: white;\n    border-radius: 4px;\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n  }\n}\n.formTitle {\n  font-size: 16px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": `src-pages-UserAccountPage-UserAccountPage-module__pageContent--F_coO`,
	"anchorsBlock": `src-pages-UserAccountPage-UserAccountPage-module__anchorsBlock--quooa`,
	"formsList": `src-pages-UserAccountPage-UserAccountPage-module__formsList--_Ddgi`,
	"formTitle": `src-pages-UserAccountPage-UserAccountPage-module__formTitle--Hl1a3`
};
export default ___CSS_LOADER_EXPORT___;
