import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Flex } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { ZSampleData } from "../../ZSampleData";
import { SamplePointsStore } from "../SamplePointsStore";

interface PropsSampleDetailsSwitcher {
  row: ZSampleData;
  store: SamplePointsStore;
  children: React.ReactNode;
}

export const SampleDetailsSwitcher: React.FC<PropsSampleDetailsSwitcher> =
  observer((props) => {
    const { store, children, row } = props;
    const isOpen = !!store.subsamples[row.id];
    return (
      <Flex gap={8} align="center" style={{ padding: "2px 8px" }}>
        <Button
          size="small"
          icon={isOpen ? <MinusOutlined /> : <PlusOutlined />}
          onClick={() => store.toggleSubsample(row.id)}
        />
        {children}
      </Flex>
    );
  });
