import * as React from "react";
import { makeAutoObservable } from "mobx";
import { ModelessFormStore } from "src/components/ModelessForm";
import { ZObjectItem } from "src/types/ZObjectItem";
import { RemoteData } from "src/common/RemoteData";
import { IdLabel, getIdLabels } from "src/references/getIdNames";
import { makeDictNameById } from "src/types/AttrType";
import { ifDef } from "src/common/ifDef";
import { onError } from "src/common/onError";
import { ZAttribute } from "src/types/ZAttribute";
import {
  loadTranslation,
  saveTranslation,
} from "src/common/api/apiTranslation";
import { loadRoleGroups } from "../Obj2Tab/roles/rolesApi";
import {
  apiCreateRole,
  apiCreateRoleAttribute,
  apiCreateRolesGroup,
  apiDeleteRoleAttr,
  apiDeleteRoleObject,
  apiDeleteRolesGroup,
  apiLoadRoles,
  apiUpdateRole,
  apiUpdateRolesGroup,
} from "./apiRolesTab";
import { ZRolesGroup, zRolesGroup } from "../Obj2Tab/roles/roleTypes";
import {
  AttrRoleNode,
  CommonRoleNode,
  ObjRoleNode,
  TopGroupRoleNode,
  makeTopGroupRoleKey,
  newItemId,
} from "./nodes/RoleNode";
import {
  createAttrRoleNode,
  createRoleNode,
  createRolesGroupNode,
  createRolesTree,
  makeAttrRoleNodeKey,
  makeRoleNodeKey,
} from "./nodes/createRolesTree";
import { initTypeIcons } from "../Obj2Tab/utils/typeIcons";
import { findNodeByKey } from "../../../common/findNodeByKey";
import { visitRoleNode } from "./nodes/visitRoleNode";
import { findNodeOwnerByKey } from "../../../common/findNodeOwnerByKey";
import { SelectDictionaryStore } from "../Obj2Tab/forms/AttrForm2/SelectDictionary";
import { updateAttribute } from "../objectsApi";
import { EdAttribute, attr2edit, edit2attr } from "../Obj2Tab/EdAttribute";
import { zRoleFields } from "./RolesTab.types";

export class RolesTabStore {
  formStore: ModelessFormStore;

  selectDictionaryStore = new SelectDictionaryStore();

  constructor() {
    this.formStore = new ModelessFormStore(
      (values) => this.save(values),
      () => this.onDataLoss(),
    );
    makeAutoObservable(this);
  }

  data: RemoteData<CommonRoleNode[]> = { status: "none" };

  setData(newData: RemoteData<CommonRoleNode[]>) {
    this.data = newData;
  }

  get treeData(): CommonRoleNode[] {
    return this.data.status === "ready" ? this.data.result : [];
  }

  refreshTree() {
    if (this.data.status === "ready") {
      this.data.result = [...this.data.result];
    }
  }

  // Справочник атрибутов

  attrTypesList: IdLabel[] = [];

  setAttrTypesList(list: IdLabel[]) {
    this.attrTypesList = list;
  }

  get attrTypesDict(): Record<number, string> {
    return makeDictNameById(this.attrTypesList);
  }

  get currentObjectId(): number | null {
    const { curNode } = this;
    if (!curNode) return null;
    return visitRoleNode<number | null>(curNode, {
      roleGroup: () => null,
      roleObject: (it) => it.obj.id,
      attr: (it) => it.ownerId,
      default: () => null,
    });
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      if (this.attrTypesList.length === 0) {
        this.setAttrTypesList(await getIdLabels("attrType", "attrType"));
        initTypeIcons(this.attrTypesDict);
      }
      await this.loadData();
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  async loadData() {
    const [groups, roles] = await Promise.all([
      loadRoleGroups(),
      apiLoadRoles(),
    ]);
    const result = createRolesTree(groups, roles);
    this.setData({ status: "ready", result });
    this.setRoleGroups(groups);
  }

  roleGroups: ZRolesGroup[] = [];

  setRoleGroups(list: ZRolesGroup[]) {
    this.roleGroups = list;
  }

  onDataLoss() {
    const { isNewNode } = this;
    if (isNewNode) {
      this.loadData().catch(onError);
    }
  }

  // ----- expanding
  expanded = new Set<React.Key>();

  get expandedKeys(): React.Key[] {
    return Array.from(this.expanded);
  }

  expand(key: React.Key, isExpand: boolean) {
    if (isExpand) {
      this.expanded.add(key);
    } else {
      this.expanded.delete(key);
    }
  }

  // ----------- select

  safeSelect(keys: React.Key[]) {
    this.formStore.safeAction(() => this.unsafeSelect(keys));
  }

  unsafeSelect(keys: React.Key[]) {
    this.setSelectedKeys(keys);
    const { curNode } = this;
    if (curNode) {
      this.loadNode(curNode);
    }
  }

  loadingNode = false;

  setLoadingNode(value: boolean) {
    this.loadingNode = value;
  }

  async loadNode(curNode: CommonRoleNode) {
    this.setLoadingNode(true);
    try {
      const values = await visitRoleNode<Promise<unknown>>(curNode, {
        roleGroup: async (it) => it.group,
        roleObject: async (it) => it.obj,
        attr: async ({ attr }) => {
          const { translations } = await loadTranslation(attr.name);
          return attr2edit(attr, {}, undefined, translations);
        },
        default: async () => null,
      });

      this.formStore.unsafeLoad(values, this.isNewNode);
    } catch (e) {
      onError(e);
    } finally {
      this.setLoadingNode(false);
    }
  }

  selectedKeys: React.Key[] = [];

  setSelectedKeys(keys: React.Key[]) {
    this.selectedKeys = keys;
  }

  get curNode(): CommonRoleNode | undefined {
    return ifDef(this.selectedKeys[0], (key) =>
      findNodeByKey(key, this.treeData),
    );
  }

  async save(values: unknown) {
    if (this.curNode) {
      await visitRoleNode(this.curNode, {
        roleGroup: (it) => this.saveRoleGroup(it, values),
        roleObject: (it) => this.saveRoleObject(it, values),
        attr: async (it) => this.saveAttr(it, values),
      });
    }
  }

  // ---- Group
  safeAddGroup() {
    this.formStore.safeAction(() => this.unsafeAddGroup());
  }

  unsafeAddGroup() {
    if (this.data.status === "ready") {
      const group: ZRolesGroup = {
        groupId: newItemId,
        groupName: "",
        roles: [],
      };
      const node = createRolesGroupNode(group, []);
      this.data.result = [...this.data.result, node];
      this.unsafeSelect([node.key]);
    }
  }

  async saveRoleGroup(node: TopGroupRoleNode, values: unknown) {
    const { group } = node;
    const zGroupFields = zRolesGroup.pick({ groupName: true });
    const fields = zGroupFields.parse(values);
    if (group.groupId === newItemId) {
      const newGroup: Omit<ZRolesGroup, "groupId"> = {
        ...fields,
        roles: [],
      };
      const resultGroup = await apiCreateRolesGroup(newGroup);
      await this.loadData();
      this.unsafeSelect([makeTopGroupRoleKey(resultGroup.groupId)]);
    } else {
      const data: ZRolesGroup = {
        ...node.group,
        ...fields,
      };
      await apiUpdateRolesGroup(data);
      await this.loadData();
    }
  }

  // ------ Role
  safeAddRole() {
    this.formStore.safeAction(() => this.unsafeAddRole());
  }

  unsafeAddRole() {
    const { curNode } = this;
    if (curNode && curNode.type === "roleGroup") {
      const newRole: ZObjectItem = {
        id: newItemId,
        name: "",
        attributes: [],
        roleId: newItemId,
      };
      const roleNode = createRoleNode(newRole);
      curNode.children?.push(roleNode);
      curNode.isLeaf = false;
      this.expand(curNode.key, true);
      this.unsafeSelect([roleNode.key]);
    }
  }

  async saveRoleObject(node: ObjRoleNode, values: unknown) {
    const { obj, key } = node;
    const fields = zRoleFields.parse(values);
    let objKey = key;
    if (obj.id === newItemId) {
      const ownerRes = findNodeOwnerByKey(node.key, this.treeData);
      if (!ownerRes) throw Error(`Не найдена группа для роли`);
      const { owner } = ownerRes;
      if (owner.type !== "roleGroup")
        throw Error(`Неправильный тип ${owner.type} для группы-владельца`);
      const newObj = await apiCreateRole(owner.group.groupId, fields);
      objKey = makeRoleNodeKey(newObj);
    } else {
      await apiUpdateRole(obj.id, fields);
    }
    await this.loadData();
    this.unsafeSelect([objKey]);
  }

  // ---- Attributes
  safeAddAttr() {
    this.formStore.safeAction(() => this.unsafeAddAttr());
  }

  unsafeAddAttr() {
    const { curNode } = this;
    if (curNode && curNode.type === "roleObject") {
      const newAttr: ZAttribute = {
        id: newItemId,
        name: "",
        valueType: undefined as unknown as number,
      };
      const newNode = createAttrRoleNode(newAttr, curNode.obj);
      curNode.children = [...(curNode.children ?? []), newNode];
      curNode.isLeaf = false;
      this.expand(curNode.key, true);
      this.unsafeSelect([newNode.key]);
      this.refreshTree();
    }
  }

  async saveAttr(attrNode: AttrRoleNode, values: unknown) {
    const ownerRes = findNodeOwnerByKey(attrNode.key, this.treeData);
    if (!ownerRes) throw Error("Не найдена роль для атрибута");
    const { owner } = ownerRes;
    if (owner.type !== "roleObject")
      throw Error("Атрибут должен принадлежать роли");
    const { attr: validValues, translations = {} } = edit2attr(
      values as EdAttribute,
    );
    if (attrNode.attr.id === newItemId) {
      const resAttr = await apiCreateRoleAttribute(owner.obj, validValues);
      const newKey = makeAttrRoleNodeKey(resAttr);
      await this.loadData();
      this.unsafeSelect([newKey]);
    } else {
      const dstAttr: ZAttribute = {
        ...attrNode.attr,
        ...validValues,
      };
      await updateAttribute(dstAttr);
      await saveTranslation(attrNode.attr.name, {
        value: dstAttr.name,
        translations,
      });
      await this.loadData();
    }
  }

  // ----- Delete

  msgDelete = "";

  setMsgDelete(msg: string) {
    this.msgDelete = msg;
  }

  deletingWait = false;

  safeDeleteCurNode() {
    // Здесь не используется safeAction, т.к. в любом случае сначала выдаётся предупреждение
    // А дальше объект удаляется. Значит, не важно сохранён от был перед этим или нет.
    const { curNode } = this;
    if (curNode) {
      const msg = visitRoleNode(curNode, {
        roleGroup: () => "Удалить текущую группу ролей?",
        roleObject: () => "Удалить роль?",
        attr: () => "Удалить атрибут?",
        default: () => "",
      });
      if (msg) {
        this.setMsgDelete(msg);
      }
    }
  }

  async unsafeDeleteCurNode() {
    const { curNode } = this;
    if (!curNode) return;
    try {
      await visitRoleNode(curNode, {
        roleGroup: async (it) => {
          if (it.group.groupId !== newItemId) {
            await apiDeleteRolesGroup(it.group);
          }
        },
        roleObject: async (it) => {
          if (it.obj.id !== newItemId) {
            await apiDeleteRoleObject(it.obj);
          }
        },
        attr: async (it) => {
          if (it.attr.id !== newItemId) {
            await apiDeleteRoleAttr(it.ownerId, it.attr.id);
          }
        },
      });
      this.formStore.unsafeLoad(null);
      this.unsafeSelect([]);
      await this.loadData();
      this.setMsgDelete("");
    } catch (e) {
      onError(e);
    }
  }

  get isNewNode(): boolean | undefined {
    const { curNode } = this;
    if (!curNode) return undefined;
    return visitRoleNode(curNode, {
      roleGroup: (it) => it.group.groupId === newItemId,
      roleObject: (it) => it.obj.id === newItemId,
      attr: (it) => it.attr.id === newItemId,
      default: () => false,
    });
  }
}
