// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-HomePage-HomePage-module__box--woaAM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  height: 100%;
  color: #000000D9;
}
.src-pages-HomePage-HomePage-module__content--dnnlo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-bottom: 60px;
}
.src-pages-HomePage-HomePage-module__logo--UCzwf {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 21px;
  font-weight: 700;
  line-height: 29px;
}
.src-pages-HomePage-HomePage-module__logo--UCzwf svg {
  color: #FC4807;
  height: 24px;
  width: 37px;
}
.src-pages-HomePage-HomePage-module__title--SOVLE {
  font-size: 36px;
  font-weight: 500;
  line-height: 47px;
  text-align: center;
  max-width: 400px;
}
.src-pages-HomePage-HomePage-module__descr--QNLys {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
}
.src-pages-HomePage-HomePage-module__background--c7itl {
  display: flex;
  justify-content: center;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAEA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,oBAAA;AAAJ;AAGA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AALA;EASQ,cAAA;EACA,YAAA;EACA,WAAA;AADR;AAKA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AAHJ;AAMA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAJJ;AAOA;EACI,aAAA;EACA,uBAAA;EACA,WAAA;AALJ","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 24px;\n    height: 100%;\n    color: #000000D9;\n}\n\n.content {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 32px;\n    padding-bottom: 60px;\n}\n\n.logo {\n    display: flex;\n    align-items: center;\n    column-gap: 12px;\n    font-size: 21px;\n    font-weight: 700;\n    line-height: 29px;\n\n    svg {\n        color: #FC4807;\n        height: 24px;\n        width: 37px;\n    }\n}\n\n.title {\n    font-size: 36px;\n    font-weight: 500;\n    line-height: 47px;\n    text-align: center;\n    max-width: 400px;\n}\n\n.descr {\n    font-size: 20px;\n    line-height: 24px;\n    font-weight: 500;\n    text-align: center;\n}\n\n.background {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-HomePage-HomePage-module__box--woaAM`,
	"content": `src-pages-HomePage-HomePage-module__content--dnnlo`,
	"logo": `src-pages-HomePage-HomePage-module__logo--UCzwf`,
	"title": `src-pages-HomePage-HomePage-module__title--SOVLE`,
	"descr": `src-pages-HomePage-HomePage-module__descr--QNLys`,
	"background": `src-pages-HomePage-HomePage-module__background--c7itl`
};
export default ___CSS_LOADER_EXPORT___;
