import * as React from "react";
import { observer } from "mobx-react-lite";
import { Empty, Radio } from "antd";
import { classNames } from "src/common/classNames";
import { ZCatAttr } from "src/types/ZCatAttr";
import confirm from "antd/lib/modal/confirm";
import { CategoryAttributesStore } from "../CategoryAttributesStore";
import styles from "./AttrLevelList.module.less";

interface PropsAttrLevelList {
  attrs: ZCatAttr[];
  typeLabels: Record<string, string>;
  editable: boolean;
  attrStore: CategoryAttributesStore;
}

export const AttrLevelList: React.FC<PropsAttrLevelList> = observer(
  (props: PropsAttrLevelList) => {
    const { attrs, typeLabels, editable, attrStore } = props;
    if (attrs.length === 0) {
      return (
        <Empty className={styles.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      );
    }
    const activeId = attrStore.curAttr?.id;
    const selectAttr = (attr: ZCatAttr) => {
      if (editable) {
        attrStore.setCurAttr(attr);
      } else {
        confirm({
          title: "Невозможно изменить артибут",
          content:
            "Чтобы изменить этот атрибут вернитесь на соответствующую категорию",
          okText: "Ок",
          cancelText: "Отмена",
        });
      }
    };
    return (
      <div
        className={classNames([styles.attrsBlock, [editable, styles.editable]])}
      >
        {attrs.map((attr) => {
          const { id, label, type } = attr;
          const active = id === activeId;
          /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
          return (
            <div
              key={id}
              className={classNames([styles.row, [active, styles.active]])}
              onClick={() => selectAttr(attr)}
            >
              {editable && <Radio name="attr" value={id} checked={active} />}
              <div className={styles.name}>{label}</div>
              <div className={styles.type}>{typeLabels[type] || type}</div>
            </div>
          );
        })}
      </div>
    );
  },
);
