import * as React from "react";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { ModelEditorStore } from "../ModelEditorStore";
import { ZModel } from "../../ZModel";
import { Point } from "../../math";
import { DrawFigure } from "./draw/DrawFigure";
import { DrawCtrlPoints } from "./draw/DrawCtrlPoints";
import { DrawNewPoints } from "./draw/DrawNewPoints";
import { ModalNewAttr } from "./modals/ModalNewAttr";
import { ModalEditAttr } from "./modals/ModalEditAttr";
import { ModalEditModel } from "./modals/ModalEditModel";
import styles from "./ModelEditorCanvas.module.less";

/* eslint jsx-a11y/mouse-events-have-key-events: "off" */
/* eslint jsx-a11y/no-static-element-interactions: "off" */

interface PropsModelEditorCanvas {
  store: ModelEditorStore;
  model: ZModel;
}

export const ModelEditorCanvas: React.FC<PropsModelEditorCanvas> = observer(
  (props: PropsModelEditorCanvas) => {
    const { store, model } = props;
    const { imgRef, imgWidth, imgHeight, attrs } = model;
    const { zoom, drawProps } = store;
    const [coordMsg, setCoordMsg] = React.useState("");
    const onKey = (e: KeyboardEvent) => {
      store.onKey(e.code);
    };
    React.useEffect(() => {
      window.addEventListener("keydown", onKey);
      return () => {
        window.removeEventListener("keydown", onKey);
      };
    }, []);
    return (
      <div className={styles.box} id="MECanvas">
        <div className={styles.scrollOwner} id="MEScroller">
          <div
            className={classNames([
              styles.scrollTarget,
              [store.isClickWait, styles.clickWait],
            ])}
            onMouseMove={(e) => {
              const pagePos = new Point(e.pageX, e.pageY);
              const wp = store.cvtPageToWorld(pagePos);
              if (wp) setCoordMsg(`${wp.x.toFixed(0)}, ${wp.y.toFixed(0)}`);
              store.onMouseMove(pagePos);
            }}
            onMouseOut={() => setCoordMsg("")}
            onMouseUp={(e) => store.onMouseUp(new Point(e.pageX, e.pageY))}
          >
            <img src={imgRef} width={imgWidth * zoom} alt="" />
            <svg
              width={imgWidth * zoom}
              viewBox={`0 0 ${imgWidth} ${imgHeight}`}
            >
              {attrs.map((a, i) => (
                <DrawFigure
                  key={a.key}
                  drawProps={drawProps}
                  attr={a}
                  className={classNames([
                    styles.figure,
                    [i === store.curAttrIndex, styles.active],
                  ])}
                  onClick={() => store.setCurAttrIndex(i)}
                  onStartCopy={(pagePos) => store.startClone(i, pagePos)}
                />
              ))}
              <DrawCtrlPoints store={store} className={styles.ctrlPoint} />
              <DrawNewPoints store={store} />
              <rect
                x={0}
                y={0}
                width={model.imgWidth - 1}
                height={model.imgHeight - 1}
                className={styles.border}
              />
            </svg>
          </div>
        </div>
        <div className={styles.coordinates}>{coordMsg}</div>
        <ModalNewAttr store={store} />
        <ModalEditAttr store={store} />
        <ModalEditModel store={store} />
      </div>
    );
  },
);
