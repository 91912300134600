import * as React from "react";
import { Spin, Tree } from "antd";
import { observer } from "mobx-react-lite";
import { EntityForm } from "src/pages/EntityCardPage/EntityForm/EntityForm";
import { Obj2TabStore } from "../Obj2TabStore";
import { CommonNodeO2, ObjectO2, visitO2 } from "../Obj2Nodes";

interface PropsObj2Orders {
  store: Obj2TabStore;
  objNode: ObjectO2;
}

export const Obj2Orders: React.FC<PropsObj2Orders> = observer(
  ({ store, objNode }) => {
    const { objOrderStore } = store;
    React.useEffect(() => {
      objOrderStore.init(objNode, store.attrTypesDict);
    }, [objNode]);
    return (
      <div style={{ padding: "16px 24px" }}>
        <div style={{ width: 350 }}>
          <Spin spinning={objOrderStore.buzy}>
            <Tree
              treeData={objOrderStore.treeData}
              blockNode
              draggable
              showIcon
              onExpand={(_keys, { node, expanded }) => {
                objOrderStore.onExpand(node, expanded);
              }}
              onDrop={() => {
                objOrderStore.finish((owner, order) =>
                  store.updateOrder(owner, order),
                );
              }}
              allowDrop={(info) => {
                const { dragNode, dropNode, dropPosition } = info;
                objOrderStore.setMsg(
                  `pos: ${dropPosition}, drop: ${getNamex(dropNode)}`,
                );
                return objOrderStore.allowDrop(
                  dragNode.key,
                  dropNode.key,
                  dropPosition,
                );
              }}
            />
            {/* <div>{objOrderStore.msg}</div>
          <div>
            {objOrderStore.dropRes
              ? `dropIndex: ${objOrderStore.dropRes.dropIndex}`
              : "null"}
          </div> */}
          </Spin>
        </div>
        {!!objOrderStore.rootBlock && (
          <div>
            <h2 style={{ background: "#EEE" }}>Предварительный просмотр</h2>
            <EntityForm
              block={objOrderStore.rootBlock}
              formStore={objOrderStore.formStore}
              submitText="OK"
              initialData={{}}
              disabled
              availableStates={undefined}
              isBuzy={false}
              onSubmit={async (values) => values}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onSuccess={() => {}}
            />
          </div>
        )}
      </div>
    );
  },
);

const getNamex = (node: CommonNodeO2 | null): string => {
  if (!node) return "null";
  return visitO2(node, {
    obj: () => "---",
    attr: (it) => `attr ${it.attr.name}`,
    group: (it) => `group ${it.group.name}`,
    value: () => "value",
  });
};
