import { ZRCMessage } from "../../../rocketChat/rocketChat.types";

export const msgList2MsgGroups = (list: ZRCMessage[]) => {
  let groupKey = "";
  return Object.entries(
    list.reduce(
      (acc, curr) => {
        if (!curr.u) return acc;
        if (Object.keys(acc).length === 0 || !acc[groupKey]) {
          // eslint-disable-next-line no-underscore-dangle
          groupKey += curr.u._id;
          acc[groupKey] = [curr];
          return acc;
        }
        acc[groupKey] = [...(acc[groupKey] || []), curr];
        return acc;
      },
      {} as Record<string, ZRCMessage[]>,
    ),
  );
};
