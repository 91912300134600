// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ModalPlm-ModalPlm-module__content--hlXQM {
  padding: 0 !important;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.src-components-ModalPlm-ModalPlm-module__header--yjPki {
  padding: 16px 24px !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #e1e3e6 !important;
}
.src-components-ModalPlm-ModalPlm-module__body--PaK1B {
  padding: 16px 24px !important;
  flex: 1;
  overflow-y: auto;
}
.src-components-ModalPlm-ModalPlm-module__footer--V2u5d {
  padding: 16px 24px !important;
  margin-top: 0 !important;
  border-top: 1px solid #e1e3e6 !important;
}
.src-components-ModalPlm-ModalPlm-module__title--IxsBC {
  font-weight: 700;
  margin: 0;
  font-size: 20px;
  line-height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalPlm/ModalPlm.module.less"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAIA;EACE,6BAAA;EACA,2BAAA;EACA,2CAAA;AAFF;AAKA;EACE,6BAAA;EACA,OAAA;EACA,gBAAA;AAHF;AAMA;EACE,6BAAA;EACA,wBAAA;EACA,wCAAA;AAJF;AAOA;EACE,gBAAA;EACA,SAAA;EACA,eAAA;EACA,iBAAA;AALF","sourcesContent":["@import \"/src/variables.less\";\n\n.content {\n  padding: 0 !important;\n  height: 100%;\n  overflow-y: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.header {\n  padding: 16px 24px !important;\n  margin-bottom: 0 !important;\n  border-bottom: 1px solid @border-color !important;\n}\n\n.body {\n  padding: 16px 24px !important;\n  flex: 1;\n  overflow-y: auto;\n}\n\n.footer {\n  padding: 16px 24px !important;\n  margin-top: 0 !important;\n  border-top: 1px solid @border-color !important;\n}\n\n.title {\n  font-weight: 700;\n  margin: 0;\n  font-size: 20px;\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `src-components-ModalPlm-ModalPlm-module__content--hlXQM`,
	"header": `src-components-ModalPlm-ModalPlm-module__header--yjPki`,
	"body": `src-components-ModalPlm-ModalPlm-module__body--PaK1B`,
	"footer": `src-components-ModalPlm-ModalPlm-module__footer--V2u5d`,
	"title": `src-components-ModalPlm-ModalPlm-module__title--IxsBC`
};
export default ___CSS_LOADER_EXPORT___;
