import React, { useEffect } from "react";
import { Flex, Form, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { SelectDictionary } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectDictionary";
import { FormObjectSection } from "src/components/Forms/FormObjectSection";
import { mChartSettings, MCObjType } from "./MChartSettingsStore";
import {
  MChartSection,
  MCPointsSection,
  PointSection,
  PointValueSection,
  SizeLineSizeSection,
  SizeLinesSection,
  SizeNamesSection,
} from "./sections";
import { ZMesChartSettings } from "../ZMesChartSettings";

const fkey = (name: keyof ZMesChartSettings) => name;

interface PropsMChartSettings {
  typesMap: Record<number, string>;
}

export const MChartSettings: React.FC<PropsMChartSettings> = observer(
  ({ typesMap }) => {
    useEffect(() => {
      mChartSettings.init(typesMap);
    }, []);
    const sectionsStore = mChartSettings.formSectionsStore;
    const { loading } = sectionsStore;
    return (
      <Spin spinning={loading}>
        <Flex gap={24} vertical>
          <SizeLinesSection store={sectionsStore} />
          <SizeLineSizeSection store={sectionsStore} />
          <MChartSection store={sectionsStore} />
          <MCPointsSection store={sectionsStore} />
          <PointSection store={sectionsStore} />
          <SizeNamesSection store={sectionsStore} />
          <PointValueSection store={sectionsStore} />
          <FormObjectSection<ZMesChartSettings, MCObjType>
            store={sectionsStore}
            objKey={["templateObjectId"]}
            objType="Template"
            objLabel="Template object"
            title="Template"
          />
          <hr />
          <Form.Item
            name={fkey("versionDictionaryNameAttrId")}
            label="Справочник версий"
            rules={[{ required: true }]}
          >
            <SelectDictionary store={mChartSettings.selectDict} />
          </Form.Item>
          <hr />
        </Flex>
      </Spin>
    );
  },
);
