// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingControl-SchedListTab-StartDatePicker-StartDatePicker-module__projectStartDate--UuZak {
  font-weight: 700;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedListTab-StartDatePicker-StartDatePicker-module__datePicker--QrlEN {
  max-width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingControl/SchedListTab/StartDatePicker/StartDatePicker.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,gBAAA;AAAF","sourcesContent":[".projectStartDate {\n  font-weight: 700;\n}\n\n.datePicker {\n  max-width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectStartDate": `src-businessServices-services-bsScheduling-SchedulingControl-SchedListTab-StartDatePicker-StartDatePicker-module__projectStartDate--UuZak`,
	"datePicker": `src-businessServices-services-bsScheduling-SchedulingControl-SchedListTab-StartDatePicker-StartDatePicker-module__datePicker--QrlEN`
};
export default ___CSS_LOADER_EXPORT___;
