import { z } from "zod";

export const zServiceInfo = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  endpoint: z.string().nullable(),
});

export type ZServiceInfo = z.infer<typeof zServiceInfo>;
