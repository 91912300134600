import { observer } from "mobx-react-lite";
import React from "react";
import { Drawer, Space, Button } from "antd";
import { t } from "i18next";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { SchedulingControlStore } from "../../../SchedulingControlStore";
import styles from "./SchedTemplateCreator.module.less";

interface PropsSchedTemplateCreator {
  store: SchedulingControlStore;
}

export const SchedTemplateCreator: React.FC<PropsSchedTemplateCreator> =
  observer(({ store }) => {
    const { templateCreator, operationLoading } = store;
    if (!templateCreator) return null;
    const { filterStore, title } = templateCreator;
    const handleClose = () => store.closeTemplateCreator();
    const disabled = !filterStore.tableStore?.selected.length;
    return (
      <Drawer
        width="75vw"
        open={!!templateCreator}
        onClose={handleClose}
        className={styles.drawer}
        title={title}
        footer={
          <Space>
            <Button
              type="primary"
              loading={operationLoading}
              disabled={disabled}
              onClick={() => store.onTemplateCreate()}
            >
              {t("Save")}
            </Button>
            <Button onClick={handleClose}>{t("Cancel")}</Button>
          </Space>
        }
      >
        <EntityFiltersTable store={filterStore} />
      </Drawer>
    );
  });
