// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ModelConstructorPage-ModelEditor-MEdAttrList-MEdAttrList-module__list--E9OiE button {
  display: block;
  padding: 0.2em 0.5em;
  color: #333;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.src-pages-ModelConstructorPage-ModelEditor-MEdAttrList-MEdAttrList-module__list--E9OiE button.src-pages-ModelConstructorPage-ModelEditor-MEdAttrList-MEdAttrList-module__active--ssoBa {
  background-color: blue;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ModelConstructorPage/ModelEditor/MEdAttrList/MEdAttrList.module.less"],"names":[],"mappings":"AAGA;EAEQ,cAAA;EACA,oBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AAHR;AAKQ;EACI,sBAAA;EACA,YAAA;AAHZ","sourcesContent":[".box {\n\n}\n.list {\n    button {\n        display: block;\n        padding: 0.2em 0.5em;\n        color: #333;\n        border: none;\n        background: none;\n        width: 100%;\n        text-align: left;\n        cursor: pointer;\n\n        &.active {\n            background-color: blue;\n            color: white;\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `src-pages-ModelConstructorPage-ModelEditor-MEdAttrList-MEdAttrList-module__list--E9OiE`,
	"active": `src-pages-ModelConstructorPage-ModelEditor-MEdAttrList-MEdAttrList-module__active--ssoBa`
};
export default ___CSS_LOADER_EXPORT___;
