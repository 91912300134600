// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityGroupBlock-EntityGroupBlock-module__box--pLD3B {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.src-pages-EntityCardPage-EntityGroupBlock-EntityGroupBlock-module__title--UKgH5 {
  display: flex;
  font-size: 16px;
  font-weight: 700;
}
.src-pages-EntityCardPage-EntityGroupBlock-EntityGroupBlock-module__items--KdYkY {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 24px;
  row-gap: 16px;
}
.src-pages-EntityCardPage-EntityGroupBlock-EntityGroupBlock-module__block--J_DHO {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px 32px 32px;
  background-color: #FFFFFF;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityGroupBlock/EntityGroupBlock.module.less"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAGA;EACI,aAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,mEAAA;EACA,gBAAA;EACA,aAAA;AAFJ;AAKA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,yBAAA;AAHJ","sourcesContent":["// Весь этот дизайн черновой. Будет полностью переделан, когда будут макеты\n.box {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n}\n\n.title {\n    display: flex;\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.items {\n    display: grid;\n    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);\n    column-gap: 24px;\n    row-gap: 16px;\n}\n\n.block {\n    display: flex;\n    flex-direction: column;\n    row-gap: 20px;\n    padding: 20px 32px 32px;\n    background-color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-EntityCardPage-EntityGroupBlock-EntityGroupBlock-module__box--pLD3B`,
	"title": `src-pages-EntityCardPage-EntityGroupBlock-EntityGroupBlock-module__title--UKgH5`,
	"items": `src-pages-EntityCardPage-EntityGroupBlock-EntityGroupBlock-module__items--KdYkY`,
	"block": `src-pages-EntityCardPage-EntityGroupBlock-EntityGroupBlock-module__block--J_DHO`
};
export default ___CSS_LOADER_EXPORT___;
