import { Input, InputProps } from "antd";
import {
  ItemProps,
  blockItem,
  extendItemProps,
} from "src/components/FormWithBlocks";

export const readOnlyInput = (
  name: string,
  label: string,
  itemProps: ItemProps,
  compProps?: InputProps,
) =>
  blockItem(
    name,
    label,
    Input,
    { disabled: true, ...compProps },
    extendItemProps(itemProps, { shouldUpdate: true }),
  );
