import * as React from "react";
import { Select } from "antd";
import { appStore } from "src/appStore";
import { ZOption } from "src/types/ZOption";
import { getLangName } from "src/types/ZLanguageProps";

interface PropsEntityContentLanguage {
  value?: string[] | null;
  onChange?(newValue: string[] | null | undefined): void;
  disabled?: boolean;
}

export const EntityContentLanguage: React.FC<PropsEntityContentLanguage> = (
  props,
) => {
  const { value, onChange, disabled } = props;
  const options: ZOption[] = appStore.contentLanguages.map((lang) => ({
    value: lang.code,
    label: getLangName(lang),
  }));
  return (
    <Select
      options={options}
      disabled={disabled}
      value={value?.[0]}
      onChange={(newValue) => {
        onChange?.(newValue ? [newValue] : []);
      }}
    />
  );
};

EntityContentLanguage.defaultProps = {
  value: undefined,
  onChange: undefined,
  disabled: false,
};
