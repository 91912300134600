import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { BomObjType } from "../Bom2SettingsStore";
import { ZBom2ColorModelSettings, ZBom2Settings } from "../../Bom2Types";

interface PropsColorModelSection {
  store: FormObjectSectionsStore<BomObjType>;
}

const fieldName = (
  name: keyof ZBom2ColorModelSettings,
): ["bomPosColorModelAttributes", keyof ZBom2ColorModelSettings] => [
  "bomPosColorModelAttributes",
  name,
];

export const ColorModelSection: React.FC<PropsColorModelSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZBom2Settings, BomObjType>
      store={store}
      objKey={["colorModelObjectId"]}
      objType="colorModel"
      objLabel="Color model object"
      title="Color model"
      attrs={[
        {
          attrKey: fieldName("linkToBomPositionId"),
          attrLabel: "Bom Position",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: fieldName("linkToColorModelId"),
          attrLabel: "Color Model",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: fieldName("linkToColorId"),
          attrLabel: "Color",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: fieldName("colorManualId"),
          attrLabel: "Color Manual",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("colorReferenceId"),
          attrLabel: "Color Reference",
          attrType: AttrTypeName.image,
        },
      ]}
    />
  ),
);
