// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__frame--nzuPf {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__header--KbQBH {
  padding: 12px 24px 16px ;
  background: #FFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 8px;
}
.src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__form--YT6tA {
  height: 100%;
  overflow-y: auto;
  flex: 1;
  padding: 16px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/FieldsWithTools/FieldsWithTools.module.less"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AAAJ;AAEA;EACI,wBAAA;EACA,gBAAA;EACA,4CAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,QAAA;AAAJ;AAEA;EACI,YAAA;EACA,gBAAA;EACA,OAAA;EACA,kBAAA;AAAJ","sourcesContent":["\n.frame {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n.header {\n    padding: 12px 24px 16px ;\n    background: #FFF;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.06);\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: end;\n    gap: 8px;\n}\n.form {\n    height: 100%;\n    overflow-y: auto; \n    flex: 1;\n    padding: 16px 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": `src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__frame--nzuPf`,
	"header": `src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__header--KbQBH`,
	"form": `src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__form--YT6tA`
};
export default ___CSS_LOADER_EXPORT___;
