// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-SelectFromTable-SelectFromTable-module__select--dhyXA {
  caret-color: transparent;
}
.src-components-SelectFromTable-SelectFromTable-module__select--dhyXA .ant-select-arrow {
  font-size: 20px;
  color: #f06326;
}
.src-components-SelectFromTable-SelectFromTable-module__select--dhyXA .ant-select-selector {
  cursor: pointer !important;
  padding-inline-end: 30px !important;
}
.src-components-SelectFromTable-SelectFromTable-module__drawer--CKMif .ant-drawer-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px 24px;
}
.src-components-SelectFromTable-SelectFromTable-module__drawer--CKMif .ant-drawer-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/SelectFromTable/SelectFromTable.module.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;AAFA;EAGI,eAAA;EACA,cAAA;AAEJ;AANA;EAOI,0BAAA;EACA,mCAAA;AAEJ;AAEA;EAEI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAJA;EASI,aAAA;EACA,sBAAA;EACA,YAAA;AAFJ","sourcesContent":[".select {\n  caret-color: transparent;\n  :global(.ant-select-arrow) {\n    font-size: 20px;\n    color: #f06326;\n  }\n  :global(.ant-select-selector) {\n    cursor: pointer !important;\n    padding-inline-end: 30px !important;\n  }\n}\n\n.drawer {\n  :global(.ant-drawer-footer) {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    padding: 16px 24px;\n  }\n\n  :global(.ant-drawer-body) {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `src-components-SelectFromTable-SelectFromTable-module__select--dhyXA`,
	"drawer": `src-components-SelectFromTable-SelectFromTable-module__drawer--CKMif`
};
export default ___CSS_LOADER_EXPORT___;
