import { ZModel } from "../../ZModel";
import { ModelEditorStore } from "../ModelEditorStore";
import { Command, generateCmdKey } from "./Command";

export class CmdModelChange implements Command {
  readonly key: number;

  constructor(
    private store: ModelEditorStore,
    private model: ZModel,
  ) {
    this.key = generateCmdKey();
  }

  readonly title = "Изменение свойств модели";

  createUndo(): Command {
    const { model } = this.store;
    if (!model) throw Error("Модель отсутствует");
    return new CmdModelChange(this.store, model);
  }

  exec(): void {
    this.store.setModel(this.model);
  }
}
