// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__common--A8yZK {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__twoCols--qYDQT {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__typeBlock--GLZZm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__typeOption--PZQq6 {
  display: flex;
  align-items: center;
  gap: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/AttrCommonFields/AttrCommonFields.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AAAJ;AAGA;EACI,aAAA;EACA,qCAAA;EACA,SAAA;AADJ;AAIA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AAFJ","sourcesContent":[".common {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n.twoCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 24px;\n}\n\n.typeBlock {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 16px;\n}\n\n.typeOption {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__common--A8yZK`,
	"twoCols": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__twoCols--qYDQT`,
	"typeBlock": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__typeBlock--GLZZm`,
	"typeOption": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__typeOption--PZQq6`
};
export default ___CSS_LOADER_EXPORT___;
