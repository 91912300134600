// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ObjectRefSelector-ObjectRefSelector-module__selectedEntities--ATnp6 {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 80px;
  overflow-x: auto;
  overflow-y: hidden;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__selectedEntitiesItemInner--vmTdf {
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #e3eeff;
  border-radius: 4px;
  color: #343a41;
  padding: 6px 12px;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__selectedEntitiesItemInner--vmTdf svg {
  color: black;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__selectedEntitiesTitle--cnGqA {
  font-size: 14px;
  font-weight: 400;
  color: #767c84;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__select--UEjrN .ant-select-arrow {
  font-size: 20px;
  color: #F06326;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__select--UEjrN .ant-select-selector {
  padding-inline-end: 30px !important;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__drawer--Fhjkb .ant-drawer-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px 24px;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__drawer--Fhjkb .ant-drawer-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__drawerContent--eVKSy {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  overflow: hidden;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__entityLink--xbEy4 {
  color: #000000D9;
  text-decoration: underline;
  white-space: nowrap;
}
.src-components-ObjectRefSelector-ObjectRefSelector-module__entityLink--xbEy4:hover {
  color: #E43D00;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/ObjectRefSelector/ObjectRefSelector.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;AACF;AARA;EASI,YAAA;AAEJ;AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAAF;AAGA;EAEI,eAAA;EACA,cAAA;AAFJ;AADA;EAMI,mCAAA;AAFJ;AAMA;EAEI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AALJ;AAAA;EASI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;AANJ;AASA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;AAPF;AAUA;EACE,gBAAA;EACA,0BAAA;EACA,mBAAA;AARF;AAUE;EACE,cAAA;EACA,0BAAA;AARJ","sourcesContent":[".selectedEntities {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  height: 80px;\n  overflow-x: auto;\n  overflow-y: hidden;\n}\n.selectedEntitiesItemInner {\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n  background-color: #e3eeff;\n  border-radius: 4px;\n  color: #343a41;\n  padding: 6px 12px;\n  svg {\n    color: black;\n  }\n}\n\n.selectedEntitiesTitle {\n  font-size: 14px;\n  font-weight: 400;\n  color: #767c84;\n}\n\n.select {\n  :global(.ant-select-arrow) {\n    font-size: 20px;\n    color: #F06326;\n  }\n  :global(.ant-select-selector) {\n    padding-inline-end: 30px !important;\n  }\n}\n\n.drawer {\n  :global(.ant-drawer-footer) {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    padding: 16px 24px;\n  }\n\n  :global(.ant-drawer-body) {\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    height: 100%;\n  }\n}\n.drawerContent {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  height: 100%;\n  overflow: hidden;\n}\n\n.entityLink {\n  color: #000000D9;\n  text-decoration: underline;\n  white-space: nowrap;\n\n  &:hover {\n    color: #E43D00;\n    text-decoration: underline;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedEntities": `src-components-ObjectRefSelector-ObjectRefSelector-module__selectedEntities--ATnp6`,
	"selectedEntitiesItemInner": `src-components-ObjectRefSelector-ObjectRefSelector-module__selectedEntitiesItemInner--vmTdf`,
	"selectedEntitiesTitle": `src-components-ObjectRefSelector-ObjectRefSelector-module__selectedEntitiesTitle--cnGqA`,
	"select": `src-components-ObjectRefSelector-ObjectRefSelector-module__select--UEjrN`,
	"drawer": `src-components-ObjectRefSelector-ObjectRefSelector-module__drawer--Fhjkb`,
	"drawerContent": `src-components-ObjectRefSelector-ObjectRefSelector-module__drawerContent--eVKSy`,
	"entityLink": `src-components-ObjectRefSelector-ObjectRefSelector-module__entityLink--xbEy4`
};
export default ___CSS_LOADER_EXPORT___;
