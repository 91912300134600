import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { MCObjType } from "../MChartSettingsStore";
import { ZMesChartSettings } from "../../ZMesChartSettings";

interface PropsSizeNamesSection {
  store: FormObjectSectionsStore<MCObjType>;
}

export const SizeNamesSection: React.FC<PropsSizeNamesSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZMesChartSettings, MCObjType>
      store={store}
      objKey={["sizeNameObjectId"]}
      objType="SizeName"
      objLabel="Size names object"
      title="Size names"
      attrs={[
        {
          attrKey: ["sizeNameNameAttrId"],
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["sizeNameSynonymsAttrId"],
          attrLabel: "Synonyms",
          attrType: AttrTypeName.childEntities,
        },
      ]}
    />
  ),
);
