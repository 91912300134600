import * as React from "react";

type CommonNode = {
  key: React.Key;
  children?: CommonNode[];
};

export const findNodeByKey = <Node extends CommonNode>(
  key: React.Key,
  tree: Node[],
): Node | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of tree) {
    if (node.key === key) return node;
    if (node.children) {
      const subNode = findNodeByKey(key, node.children);
      if (subNode) return subNode as Node;
    }
  }
  return undefined;
};
