import { zAttribute } from "src/types/ZAttribute";
import { zGroup } from "src/types/ZGroup";
import { z } from "zod";
import { zPermissionsList } from "./Permission";

// Соответствует ObjectDto в сваггере.
// Служит для не только для основной цели (представлять объект в редакторе объектно-атрибутивной модели),
// но и для описания Ролей.

export const zObjectItem = z.object({
  id: z.number(),
  name: z.string(),
  groups: zGroup.array().nullable().optional(),
  attributes: zAttribute.array(),
  permissions: zPermissionsList.nullable().optional(),
  roleId: z.number().nullable().optional(), // Похоже, что он нужен только в тех случаях, когда эта структура описывает Роль. А для объектов =0
});
export type ZObjectItem = z.infer<typeof zObjectItem>;
