import * as React from "react";
import { Select, SelectProps } from "antd";
import { observer } from "mobx-react-lite";
import { SelectDictionaryStore } from "./SelectDictionaryStore";

/* eslint react/require-default-props: "off" */

interface PropsSelectDictionary {
  value?: number;
  onChange?(value?: number): void;
  store: SelectDictionaryStore;
  disabled?: boolean;
}

export const SelectDictionary: React.FC<PropsSelectDictionary> = observer(
  (props) => {
    const { value, onChange, store, disabled } = props;

    // Внешнее изменение значения (например, выбран другой атрибут)
    React.useEffect(() => {
      store.setValue(value);
    }, [value]);

    React.useEffect(() => {
      store.init();
    }, []);

    const filterOption: SelectProps["filterOption"] = (
      input,
      option,
    ): boolean => {
      if (option && option.options) {
        return false;
      }
      return (String(option?.label) ?? "")
        .toLowerCase()
        .includes(input.toLowerCase());
    };

    return (
      <Select
        value={store.value}
        onChange={(val) => {
          store.setValue(val);
          onChange?.(val);
        }}
        options={store.dictOptions}
        disabled={disabled}
        placeholder="Выбрать"
        showSearch
        filterOption={filterOption}
      />
    );
  },
);
