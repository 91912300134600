import React from "react";
import { TreeLikeData } from "src/types/TreeLikeData";

// New method to find a node by its key
export const findNodeByKey = <TData extends {}>(
  key: React.Key,
  treeData: TreeLikeData<TData>[],
): TreeLikeData<TData> | null => {
  // Iterate through the tree data
  for (let i = 0; i < treeData.length; i += 1) {
    const node = treeData[i];
    if (node && typeof node === "object" && "key" in node) {
      // Check if node is an object with 'key' property
      // Check if the current node's key matches the target key
      if (node.key === key) {
        return node; // Return the node if found
      }
      // If the node has children and it's an array, recursively search through its children
      if (Array.isArray(node.children) && node.children.length > 0) {
        const foundInChildren = findNodeByKey(key, node.children);
        if (foundInChildren) {
          return foundInChildren; // Return the found node in children
        }
      }
    }
  }
  return null; // Return undefined if the node with the key is not found
};
