import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Input } from "antd";
import { t } from "i18next";
import { SearchOutlined } from "@ant-design/icons";
import { debounce, DebounceCounter } from "src/common/debounce";
import { SchedulingControlStore } from "../../../SchedulingControlStore";

const debounceCounter: DebounceCounter = {};

interface PropsTaskSearch {
  store: SchedulingControlStore;
}

export const TaskSearch: React.FC<PropsTaskSearch> = observer(({ store }) => {
  const { searchQuery } = store;
  const [query, setQuery] = useState(searchQuery);
  return (
    <Input
      allowClear
      addonBefore={<SearchOutlined />}
      value={query}
      placeholder={t("Search")}
      onChange={(e) => {
        setQuery(e.target.value);
        debounce(debounceCounter, 300, () => {
          store.setSearchQuery(e.target.value);
        });
      }}
    />
  );
});
