import * as React from "react";
import { t } from "i18next";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TableStore } from "src/components/tables/TableStore";
import { AColumn } from "src/components/tables/AsyncTable";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { FilterFieldsDict } from "src/components/tables/FiltersForm";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { ScrollableTable } from "src/components/ScrollableTable";
import { CellBoxText } from "src/components/Sheet/CellBoxText";
import { filterMonoSearch } from "src/components/tables/FiltersForm/filterMonoSearch";
import { KeepSelected } from "src/components/tables/KeepSelected";
import { DrawerPlmWithConfirmation } from "src/components/DrawerPlmWithConfirmation";
import {
  assignBomSupplier,
  BomSupplierFilter,
  loadBomSuppliers,
  ZBomSupplierRow,
} from "./apiBomSupplier";
import { saveBom2PositionFields } from "../../apiBom2Position";
import { Bom2PositionStore } from "../../Bom2PositionStore";
import { EdBomRow } from "../../ZBom2Position";
import { textProps } from "../textProps";
import styles from "./BomSupplierCell.module.less";

interface PropsBomSupplierCell {
  row: EdBomRow;
  store: Bom2PositionStore;
}

export const BomSupplierCell: React.FC<PropsBomSupplierCell> = observer(
  ({ row, store }) => {
    const [table, setTable] = React.useState<TableStore<
      ZBomSupplierRow,
      BomSupplierFilter
    > | null>(null);
    const [saving, setSaving] = React.useState(false);
    const open = () => {
      setTable(
        new TableStore({
          rowKey: "id",
          fnLoad: async ({ page, pageSize, filters }) => {
            const params = { page, size: pageSize, query: filters?.query };
            const { content, totalElements } = await loadBomSuppliers(params);
            return { rows: content, totalItems: totalElements };
          },
          selectionSettings: {
            selectionType: "radio",
          },
        }),
      );
    };
    const close = () => setTable(null);
    const columns: AColumn<ZBomSupplierRow>[] = React.useMemo(
      () => [
        {
          key: "name",
          title: t("Name"),
          dataIndex: "name",
        },
        {
          key: "shortName",
          title: t("Short name"),
          dataIndex: "shortName",
        },
        {
          key: "country",
          title: t("Country"),
          dataIndex: "country",
        },
        {
          key: "address",
          dataIndex: "address",
          title: t("Address"),
        },
      ],
      [],
    );
    const save = async (supplier: ZBomSupplierRow) => {
      try {
        setSaving(true);
        await assignBomSupplier(row.id, supplier.id);
        await saveBom2PositionFields(supplier.id, [
          { id: row.supplier.name.id, value: supplier.name },
          { id: row.supplier.country.id, value: supplier.country },
          { id: row.supplier.address.id, value: supplier.address },
        ]);
        close();
        store.reload();
      } finally {
        setSaving(false);
      }
    };
    const filterItems: FilterFieldsDict<BomSupplierFilter> = {
      query: filterMonoSearch(),
    };
    return (
      <div className={styles.box}>
        <div className={styles.edit}>
          <CellBoxText
            cellKey={`${row.id}:supplierManualValue`}
            store={store.sheetStore}
            textProps={textProps}
            value={row.supplierManualValue.value}
            save={async (newValue: string | null | undefined) => {
              const value = newValue ?? null;
              await saveBom2PositionFields(row.id, [
                { id: row.supplierManualValue.id, value },
              ]);
              return value;
            }}
            onSuccess={(value) => {
              // eslint-disable-next-line no-param-reassign
              row.supplierManualValue.value = value;
            }}
          />
        </div>
        <Button size="small" icon={<PlusOutlined />} onClick={open} />
        <DrawerPlmWithConfirmation
          drawerProps={{
            title: t("Supplier"),
            open: !!table,
            onClose: close,
            destroyOnClose: true,
          }}
          confirmationProps={{
            okText: t("Select"),
            okButtonProps: {
              onClick: () => {
                const supplier = table?.selected?.[0];
                if (supplier) {
                  save(supplier).catch(onError);
                }
              },
              disabled: !table?.selected.length,
              loading: saving,
            },
            cancelButtonProps: {
              onClick: close,
            },
          }}
        >
          {!!table && (
            <div className={styles.tableBox}>
              <KeepSelected
                store={table}
                makeTag={(r) => r.shortName || r.name}
              />
              <ScrollableTable
                store={table}
                columns={columns}
                filterItems={filterItems}
                onRowClick={(line) => selectOnRowClick(line, table)}
              />
            </div>
          )}
        </DrawerPlmWithConfirmation>
      </div>
    );
  },
);
