import * as React from "react";
import { classNames } from "src/common/classNames";
import styles from "./FormFieldsBlock.module.less";

/**
 * Блок решает задачи:
 * - фиксированная ширина блока полей
 * - расположение полей (любых элементов) внутри блока в две колонки
 */

// Для полей, которые должны занять всю ширину блока.
export const clsWide = styles.wideField;

// Для полей, у которых нет надписи сверху (н.р. чекбокс), чтобы они выравнивались на один уровень с другими полями.
export const clsWithoutLabel = styles.withoutLabel;

export interface PropsFormFieldsBlock {
  title?: string;
  children?: React.ReactNode;
  monoColumn?: boolean;
  widthNotLimited?: boolean;
  noPad?: boolean;
  className?: string;
}

export const FormFieldsBlock: React.FC<PropsFormFieldsBlock> = ({
  title,
  children,
  monoColumn,
  widthNotLimited,
  noPad,
  className,
}: PropsFormFieldsBlock) => {
  const mainStyles = classNames([
    styles.formBlock,
    [!!monoColumn, styles.mono],
    [!!widthNotLimited, styles.widthNotLimited],
    [!!noPad, styles.noPad],
    className,
  ]);

  return (
    <div>
      {title && <h2 className={clsWide}>{title}</h2>}
      <div className={mainStyles}>{children}</div>
    </div>
  );
};

FormFieldsBlock.defaultProps = {
  title: undefined,
  children: undefined,
  monoColumn: undefined,
  widthNotLimited: undefined,
  noPad: undefined,
  className: undefined,
};
