import { makeAutoObservable } from "mobx";
import { rest } from "src/common/rest";
import { onError } from "src/common/onError";
import { RemoteData } from "src/common/RemoteData";
import { ZOption, zOption } from "src/types/ZOption";
import { ZCatAttr, ZCatAttrNew, zCatAttr } from "src/types/ZCatAttr";
import { getFlatRefOptions } from "src/common/references/flatRefs";
import { CatNode } from "../CateroryTree";

export class CategoryAttributesStore {
  constructor() {
    makeAutoObservable(this);
  }

  // Список узлов категорий, начиная с выделенного и заканчивая самым верхним парентом.
  nodes: CatNode[] = [];

  setNodes(newList: CatNode[]) {
    this.nodes = newList;
  }

  get curNode(): CatNode | undefined {
    return this.nodes[0];
  }

  attrBlocks: Record<string, RemoteData<ZCatAttr[]>> = {};

  setAttrBlocks(blocks: Record<string, RemoteData<ZCatAttr[]>>) {
    this.attrBlocks = blocks;
  }

  setBlock(key: string, data: RemoteData<ZCatAttr[]>) {
    this.attrBlocks[key] = data;
  }

  curAttr: ZCatAttr | null = null;

  setCurAttr(attr: ZCatAttr | null) {
    this.curAttr = attr;
  }

  activeKeys: string[] = [];

  setActiveKeys(keys: string[]) {
    this.activeKeys = keys;
  }

  changeActive(key: string, expand: boolean) {
    const index = this.activeKeys.indexOf(key);
    if (expand) {
      if (index < 0) {
        this.activeKeys = [...this.activeKeys, key];
      }
    } else if (index >= 0) {
      const keys = [...this.activeKeys];
      keys.splice(index, 1);
      this.activeKeys = keys;
    }
  }

  init(nodes: CatNode[]) {
    this.setCurAttr(null);
    this.setNodes(nodes);
    const blocks: Record<string, RemoteData<ZCatAttr[]>> = {};
    nodes.forEach((node) => {
      blocks[node.key] = { status: "wait" };
      rest
        .get(`/srm-service/api/category/${node.data.id}/attributes`)
        .then((resp) => {
          const result = zCatAttr.array().parse(resp.data);
          this.setBlock(node.key, { status: "ready", result });
        })
        .catch((error) => {
          this.setBlock(node.key, { status: "error", error });
        });
    });
    this.setAttrBlocks(blocks);

    const lastNode = nodes[0];
    if (lastNode) {
      this.changeActive(lastNode.key, true);
    }

    if (this.typesDict.length === 0) {
      getFlatRefOptions("fieldMetadataType")
        .then((res) => this.setTypesDict(res))
        .catch(onError);
    }
    if (this.referencesDict.length === 0) {
      getFlatRefOptions("fieldMetadataDictionaryLIst")
        .then((res) => this.setReferencesDict(res))
        .catch(onError);
    }
  }

  typesDict: ZOption[] = [];

  setTypesDict(dict: ZOption[]) {
    this.typesDict = zOption.array().parse(dict);
  }

  // getTypeKey(typeId: string): string | undefined {
  //   return this.typesDict.find(({ value }) => typeId === value)?.key;
  // }

  get typeLabels(): Record<string, string> {
    const dict: Record<string, string> = {};
    this.typesDict.forEach(({ label, value }) => {
      dict[value] = label;
    });
    return dict;
  }

  referencesDict: ZOption[] = [];

  setReferencesDict(dict: ZOption[]) {
    this.referencesDict = dict;
  }

  get canAdd(): boolean {
    return !!this.curNode;
  }

  get canEdit(): boolean {
    return !!this.curAttr;
  }

  get canDelete(): boolean {
    return !!this.curAttr;
  }

  async onAdd(params: ZCatAttrNew) {
    const resp = await rest.post(
      "/srm-service/api/category/attributes",
      params,
    );
    const result = zCatAttr.parse(resp.data);
    const edNode = this.nodes[0];
    if (edNode) {
      const block = this.attrBlocks[edNode.key];
      this.setBlock(edNode.key, {
        status: "ready",
        result: [...(block?.status === "ready" ? block.result : []), result],
      });
      this.setCurAttr(result);
    }
  }

  async onEdit(params: ZCatAttr) {
    const resp = await rest.put(
      `/srm-service/api/category/attributes/${params.id}`,
      params,
    );
    const result = zCatAttr.parse(resp.data);
    const edNode = this.nodes[0];
    if (edNode) {
      const block = this.attrBlocks[edNode.key];
      if (block?.status === "ready") {
        const attrs = [...block.result];
        const index = attrs.findIndex(({ id }) => id === params.id);
        if (index >= 0) {
          attrs[index] = result;
          this.setBlock(edNode.key, { status: "ready", result: attrs });
        }
      }
    }
    this.setCurAttr(result);
  }

  async onDelete() {
    const { curAttr, nodes } = this;
    if (!curAttr) return;
    const edNode = nodes[0];
    if (!edNode) return;
    await rest.delete(`/srm-service/api/category/attributes/${curAttr.id}`);
    const block = this.attrBlocks[edNode.key];
    if (block?.status !== "ready") return;
    const result = [...block.result];
    const pos = result.findIndex(({ id }) => curAttr.id === id);
    if (pos < 0) return;
    result.splice(pos, 1);
    this.setBlock(edNode.key, { status: "ready", result });
    this.setCurAttr(null);
  }
}
