import React from "react";
import { observer } from "mobx-react-lite";
import { Empty } from "antd";
import { t } from "i18next";
import styles from "./ChatMsgList.module.less";
import { ChatFormStore } from "../ChatFormStore";
import { ChatMsgGroup } from "./ChatMsgGroup/ChatMsgGroup";
import { msgList2MsgGroups } from "./ChatMsgGroup/msgList2MsgGroups";

type PropsChatMsgList = {
  store: ChatFormStore;
};

export const ChatMsgList: React.FC<PropsChatMsgList> = observer(({ store }) => {
  const msgGroups = msgList2MsgGroups(store.allowedMessages);
  const isEmpty = msgGroups.length === 0;
  return (
    <div className={styles.msgList}>
      {isEmpty ? (
        <Empty description={t("There are no messages in the chat")} />
      ) : (
        msgGroups.map(([key, group]) => (
          <ChatMsgGroup msgGroup={group} key={key} store={store} />
        ))
      )}
    </div>
  );
});
