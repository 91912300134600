import * as React from "react";
import { observer } from "mobx-react-lite";
import { LayoutWithSider } from "src/components/LayoutWithSider";
import { LoaderBox } from "src/components/LoaderBox";
import { Empty } from "antd";
import { PersonsTabStore } from "./PersonsTabStore";
import { PersonsTree } from "./PersonsTree";
import { PersonRoleContent } from "./PersonRoleContent";

interface PropsPersonsTab {
  store: PersonsTabStore;
}

export const PersonsTab: React.FC<PropsPersonsTab> = observer(({ store }) => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <LoaderBox
      remoteData={store.data}
      drawReady={() => <Inner store={store} />}
    />
  );
});

const Inner: React.FC<PropsPersonsTab> = observer(({ store }) => {
  const { curNode } = store;
  return (
    <LayoutWithSider siderContent={<PersonsTree store={store} />}>
      {!curNode && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Ничего не выбрано"
        />
      )}
      {curNode?.type === "group" && <div>Группа {curNode.group.groupName}</div>}
      {curNode?.type === "role" && (
        <PersonRoleContent store={store} node={curNode} />
      )}
    </LayoutWithSider>
  );
});
