import { z } from "zod";

export const zAttrUploader = z.object({
  editor: z.literal("Uploader"),
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
  accept: z.string().nullable().optional(),
  multiple: z.boolean().optional(),
  maxCount: z.number().nullable().optional(),
  maxSize: z.number().nullable().optional(),
});

export type ZAttrUploader = z.infer<typeof zAttrUploader>;

export type AttrUploaderProps = {
  accept?: string;
  multiple?: boolean;
  maxCount?: number;
  maxSize?: number;
};

export const makeUploaderProps = (src: ZAttrUploader): AttrUploaderProps => ({
  accept: src.accept ?? undefined,
  multiple: src.multiple ?? undefined,
  maxCount: src.maxCount ?? undefined,
  maxSize: src.maxSize ?? undefined,
});
