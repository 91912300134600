/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const FolderIconSvg: React.FC<Partial<CustomIconComponentProps>> = ({
  ...props
}) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0775 0C9.29264 0 10.4419 0.55236 11.201 1.50122L12.4 3H17C18.6569 3 20 4.34315 20 6V15C20 16.6569 18.6569 18 17 18H3C1.34315 18 0 16.6569 0 15V5V4V3V2C0 0.895431 0.89543 0 2 0H8.0775Z"
    />
  </svg>
);

export const FolderIcon: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={FolderIconSvg} {...props} />;
