import { t } from "i18next";
import { makeAutoObservable } from "mobx";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { TableStore } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import { singleLoader } from "src/components/tables/singleLoader";
import {
  createSampleRequest,
  deleteSampleRequest,
  loadSampleSummary,
  updateSampleRequest,
} from "../apiSample";
import { EdSampleRequest, ZSampleRequest } from "./ZSampleRequest";
import { SamplePointsStore } from "./SamplePoints/SamplePointsStore";
import { ZMcSizeVersions } from "../../bsMeasurementChart/ZMcSizeVersions";
import { loadMcSizeVersion } from "../../bsMeasurementChart/apiMeasurementChart";

type FilterSampleRequest = {};

const keySummary = "summary";

export const sampleControlStore = makeAutoObservable({
  entityInfo: null as EntityCardDataExt | null,
  setEntityInfo(data: EntityCardDataExt) {
    this.entityInfo = data;
  },
  pointsStore: new SamplePointsStore(),

  async init(entityInfo: EntityCardDataExt) {
    this.setBuzy("loadRequests");
    this.setEntityInfo(entityInfo);
    const entityId = entityInfo.entity?.id;
    if (entityId) {
      this.setSizeVersions(await loadMcSizeVersion(entityId));
      this.pointsStore.setModelId(entityId);
    }
    await this.reload();
  },

  sizeVersions: [] as ZMcSizeVersions[],
  setSizeVersions(list: ZMcSizeVersions[]) {
    this.sizeVersions = list;
  },

  requestTable: null as TableStore<ZSampleRequest, FilterSampleRequest> | null,
  setRequestTable(
    table: TableStore<ZSampleRequest, FilterSampleRequest> | null,
  ) {
    this.requestTable = table;
  },
  requestList: [] as ZSampleRequest[],
  setRequestList(list: ZSampleRequest[]) {
    this.requestList = list;
  },
  async reload() {
    const { entityInfo } = this;
    if (!entityInfo) return;
    try {
      this.setBuzy("loadRequests");
      const objectId = entityInfo.object.id;
      const entityId = entityInfo.entity?.id;
      if (!entityId) throw Error("Invalid entity");
      const result = await loadSampleSummary(entityId, objectId);
      this.setRequestList(result);
      this.setRequestTable(
        new TableStore({
          rowKey: "id",
          fnLoad: singleLoader(
            async () => result,
            () => true,
          ),
          selectionSettings: {
            keepSelected: true,
            selectionType: "checkbox",
          },
        }),
      );
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(null);
    }
  },
  get tabs(): { key: string; label: string }[] {
    return [
      { key: keySummary, label: t("Summary") },
      ...this.requestList.map(({ id, nameRequest }) => ({
        key: String(id),
        label: nameRequest,
      })),
    ];
  },
  get activeRequest(): ZSampleRequest | null {
    const n = +this.curTab;
    return this.requestList.find(({ id }) => id === n) ?? null;
  },
  curTab: keySummary,
  setCurTab(key: string) {
    this.curTab = key;
  },
  get activeTab(): string {
    const n = +this.curTab;
    const rec = this.requestList.find(({ id }) => id === n);
    return rec ? String(rec.id) : keySummary;
  },
  async createOrUpdateRequest(
    id: number | string | null | undefined,
    values: EdSampleRequest,
  ) {
    const modelObjectId = this.entityInfo?.object.id;
    if (!modelObjectId) throw Error("Empty model object");
    const modelId = this.entityInfo?.entity?.id;
    if (!modelId) throw Error("Empty model entity");
    if (!id) {
      const data: Omit<ZSampleRequest, "id"> = {
        modelId,
        ...values,
      };
      await createSampleRequest(modelObjectId, data);
    } else {
      const data: ZSampleRequest = {
        id,
        modelId,
        ...values,
      };
      await updateSampleRequest(modelObjectId, data);
    }
    this.reload(); // без await, т.к. ждать не надо и ошибки там обрабатываются внутри
  },
  buzy: null as Task,
  setBuzy(task: Task) {
    this.buzy = task;
  },
  async deleteRequests(ids: (number | string)[]) {
    try {
      this.setBuzy("deleteRequest");
      const modelEntityId = this.entityInfo?.entity?.id;
      if (!modelEntityId) throw Error("Invalid entity");
      await deleteSampleRequest(modelEntityId, ids);
      await this.reload();
      this.requestTable?.setSelected([]);
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(null);
    }
  },
});

type Task = "deleteRequest" | "loadRequests" | null;

export type SampleControlStore = typeof sampleControlStore;
