import webstomp from "webstomp-client";
import { makeAutoObservable } from "mobx";
import { appStore } from "src/appStore";
import { ZExtNotification, zExtNotification } from "src/types/ZExtNotification";
import { rest } from "./rest";
import { apiPushUrl } from "./apiUrl";
import { onError } from "./onError";

/* eslint "no-console": "off" */

const makeWSUrl = () =>
  `${window.location.origin.replace(/^http/, "ws")}/push-service/broadcast`;

export type ConnectionStatus = "connecting" | "none" | "ready";

export interface UnreadInfo {
  count: number;
  lastReadtime: string;
  type: string;
}

export const notificationsStore = makeAutoObservable({
  status: "none" as ConnectionStatus,
  setStatus(newStatus: ConnectionStatus) {
    this.status = newStatus;
  },

  unreaded: [] as ZExtNotification[],
  setUnreaded(list: ZExtNotification[]) {
    this.unreaded = list;
  },
  get unreadedCount(): number {
    return this.unreaded.length;
  },

  notificationsLoading: false,
  setNotificationsLoading(value: boolean) {
    this.notificationsLoading = value;
  },
  async updateNotifications() {
    try {
      await this.saveReaded();
      this.clearReaded();
      this.setNotificationsLoading(true);
      const resp = await rest.get(apiPushUrl("/push"), {
        params: { status: "UNREAD" },
      });
      this.setUnreaded(zExtNotification.array().parse(resp.data));
    } catch (e) {
      onError(e);
    } finally {
      this.setNotificationsLoading(false);
    }
  },

  init() {
    this.updateNotifications();

    const connect = () => {
      console.log("connect...");
      const client = webstomp.client(makeWSUrl(), { debug: false });
      this.setStatus("connecting");
      client.connect(
        {},
        () => {
          this.setStatus("ready");
          try {
            const userId = appStore.userInfo.id;
            client.subscribe(`/messages/${userId}`, () => {
              this.updateNotifications();
            });
          } catch (e) {
            onError(e);
          }
        },
        (e) => {
          this.setStatus("none");
          console.error("ERROR", e);
          if (e instanceof CloseEvent && (e.code === 1002 || e.code === 1006)) {
            // 1002 - конец сессии
            // 1006 - Экспериментально вычисленная ситуация - разрыв соединения
            connect();
          }
        },
      );
    };
    connect();
  },
  clear() {
    this.setUnreaded([]);
  },
  toReaded: new Set<number>(),
  clearReaded() {
    this.toReaded.clear();
  },
  addReaded(msg: ZExtNotification) {
    this.toReaded.add(msg.id);
  },
  addReadedAll() {
    this.unreaded.forEach((it) => this.addReaded(it));
  },
  get isAllReaded(): boolean {
    return !this.unreaded.find(({ id }) => !this.toReaded.has(id));
  },
  // Нужно отлавливать catch
  async saveReaded() {
    const ids = Array.from(this.toReaded);
    if (ids.length > 0) {
      await rest.put(apiPushUrl("/push"), ids);
      this.setUnreaded(
        this.unreaded.filter(({ id }) => !this.toReaded.has(id)),
      );
      this.clearReaded();
    }
  },
});
