import React from "react";
import { Space } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { ProductOutlined } from "@ant-design/icons";
import { getTypeIcon } from "src/pages/ManagementPage/Obj2Tab/utils/typeIcons";

interface InputData {
  id: number;
  name: string;
  valueType?: number;
}

interface FieldOption extends DefaultOptionType {
  filterProp: string;
}

export const makeFieldOptions = (data: InputData[]): FieldOption[] =>
  data.map(({ id, name, valueType }) => ({
    value: id,
    label: (
      <Space>
        {valueType ? getTypeIcon(valueType) : <ProductOutlined />}
        {name}
      </Space>
    ),
    filterProp: name,
  }));
