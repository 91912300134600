import { z } from "zod";
import { SelectProps } from "antd";
import { filterOptionByName } from "src/common/filterOptionByName";

export const zAttrSelect = z.object({
  editor: z.literal("Select"),
  maxCount: z.number().nullable().optional(), // Для мультивыбора
  showSearch: z.boolean().optional().default(true), // Параметр поиска
});

export type ZAttrSelect = z.infer<typeof zAttrSelect>;

export type AttrSelectProps = {
  maxCount?: number;
  disabled?: boolean;
  showSearch?: boolean;
  filterOption?: SelectProps["filterOption"];
};

export const makeSelectProps = (
  src: ZAttrSelect,
  isMulti: boolean,
): AttrSelectProps => ({
  maxCount: isMulti ? src.maxCount ?? undefined : undefined,
  showSearch: src.showSearch,
  filterOption: filterOptionByName,
});
