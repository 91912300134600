import * as React from "react";
import { observer } from "mobx-react-lite";
import { Empty } from "antd";
import { classNames } from "src/common/classNames";
import { SubsampleStore } from "../SubsampleStore";
import { buildSampleMcPointColumns } from "./buildSampleMcPointColumns";
import { SampleMcPointColumn } from "./SampleMcPointColumn";
import styles from "./SampleMcPoints.module.less";

interface PropsSampleMcPoints {
  store: SubsampleStore;
}

export const SampleMcPoints: React.FC<PropsSampleMcPoints> = observer(
  ({ store }) => {
    const { pointRows } = store;
    const columns = React.useMemo(() => buildSampleMcPointColumns(store), []);
    const lastRowIndex = pointRows.length - 1;
    const gridTemplateColumns = columns
      .map(({ width, minWidth }) => width ?? `${minWidth}px`)
      .join(" ");
    return (
      <div className={styles.table}>
        <div className={styles.thead} style={{ gridTemplateColumns }}>
          {/* First header line */}
          {columns
            .filter(({ title }) => title !== null)
            .map((col, colIndex) => (
              <div
                key={`${col.key}-1`}
                style={th1Style(col)}
                className={classNames([
                  styles.th,
                  [colIndex === 0, styles.leftCol],
                ])}
              >
                {col.title}
              </div>
            ))}
          {/* Second header line */}
          {columns.map((col, colIndex) =>
            col.subTitle === null ? null : (
              <div
                key={`${col.key}-2`}
                className={classNames([
                  styles.th,
                  [colIndex === 0, styles.leftCol],
                ])}
              >
                {col.subTitle}
              </div>
            ),
          )}
          {/* Cells */}
          {pointRows.length === 0 && (
            <div
              style={{ gridColumn: `span ${columns.length}` }}
              className={styles.emptyTable}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
          {pointRows.length > 0 &&
            pointRows.map((row, rowIndex) => (
              <React.Fragment key={row.mcPoint.id}>
                {columns.map((col, colIndex) => (
                  <div
                    key={col.key}
                    className={classNames([
                      styles.td,
                      [colIndex === 0, styles.leftCol],
                      [rowIndex === lastRowIndex, styles.lastRow],
                    ])}
                  >
                    {col.cell(row.mcPoint)}
                  </div>
                ))}
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  },
);

const th1Style = ({
  colSpan,
  rowSpan,
}: SampleMcPointColumn): React.CSSProperties => ({
  gridColumn: colSpan && `span ${colSpan}`,
  gridRow: rowSpan && `span ${rowSpan}`,
});
