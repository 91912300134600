import * as React from "react";
import { observer } from "mobx-react-lite";
import { FormWithBlocks } from "src/components/FormWithBlocks";
import { Button, notification } from "antd";
import { VerticalLayout } from "src/components/VerticalLayout";
import { LoaderBox } from "src/components/LoaderBox";
import { PersonsTabStore } from "../PersonsTabStore";

interface PropsPersonForm {
  store: PersonsTabStore;
}

export const PersonForm: React.FC<PropsPersonForm> = observer(({ store }) => (
  <VerticalLayout padding>
    <LoaderBox
      remoteData={store.personData}
      drawReady={() => <Inner store={store} />}
    />
  </VerticalLayout>
));

const Inner: React.FC<PropsPersonForm> = observer(({ store }) => {
  const { formBlock } = store;
  if (!formBlock) return <div>Нет описания полей формы</div>;
  const isNew = store.currentPersonId === "new";
  return (
    <FormWithBlocks
      name="PersonForm"
      store={store.formStore}
      block={formBlock}
      initialData={store.initialData}
      submitText="Применить"
      submit={(values) => store.onSubmit(values)}
      onSuccess={() =>
        isNew
          ? notification.success({
              message: "Выполнено создание нового пользователя",
            })
          : notification.info({
              message: "Выполнено обновление данных пользователя",
            })
      }
      extraButtons={
        <Button size="large" onClick={() => store.onCancelEdit()}>
          {isNew ? "Отменить" : "Вернуться в список"}
        </Button>
      }
    />
  );
});
