import { z } from "zod";

export const zAuditEntityState = z.object({
  username: z.string(),
  fio: z.string().nullable(), // nullable временно, пока не починят бэк
  stateId: z.number(),
  timestamp: z.string(),
});
export type ZAuditEntityState = z.infer<typeof zAuditEntityState>;

export type AuditEntityStateFilters = {
  stateId?: number;
  timestamp?: string[];
  userId?: string;
};

export const zAuditEntityStateResponse = z.object({
  totalElements: z.number(),
  content: zAuditEntityState.array(),
});

export const zAuditUserFilter = z.object({
  userId: z.string(),
  fio: z.string().nullable(),
});
export type ZAuditUserFilter = z.infer<typeof zAuditUserFilter>;
