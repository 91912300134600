import { ColorPicker, Form, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { SelectProps } from "antd/lib";
import React, { useEffect, useState } from "react";
import { onError } from "src/common/onError";
import { loadDict } from "src/pages/ManagementPage/objectsApi";

type PropsSelectAttributeTagColor = SelectProps & {
  refId?: string;
  name: string[];
};

export const SelectAttributeTagColor: React.FC<
  PropsSelectAttributeTagColor
> = ({ refId, name }) => {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [loading, setLoading] = useState(false);

  const init = async () => {
    if (!refId) return;
    try {
      setLoading(true);
      const atts = await loadDict(Number(refId));
      setOptions(
        atts.map((attr) => ({ label: attr.name, value: String(attr.id) })),
      );
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [refId]);

  return (
    <Spin spinning={loading}>
      {loading && "loading..."}
      {options.map(({ value, label }) => (
        <Form.Item
          key={value}
          label={`цвет тэга - ${label}`}
          name={[...name, value || ""]}
          getValueFromEvent={(e) => `#${e.toHex()}`}
        >
          <ColorPicker format="hex" />
        </Form.Item>
      ))}
    </Spin>
  );
};

SelectAttributeTagColor.defaultProps = { refId: undefined };
