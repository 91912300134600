import { makeAutoObservable } from "mobx";
import { TableStore } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import { ZOption } from "src/types/ZOption";
import {
  TestTableFilter,
  ZTestRecord,
  ZTestStatus,
  getStatusesDict,
  initTestTable,
  makeStatusOptions,
  testTableLoader,
} from "./testTableApi";

export const testTablePageStore = makeAutoObservable({
  tableStore: new TableStore<ZTestRecord, TestTableFilter>({
    rowKey: "id",
    fnLoad: testTableLoader,
  }),
  statusesDict: {} as Record<ZTestStatus, string>,
  setStatusesDict(dict: Record<ZTestStatus, string>) {
    this.statusesDict = dict;
  },
  get statusOptions(): ZOption[] {
    return makeStatusOptions(this.statusesDict);
  },
  init() {
    initTestTable();
    getStatusesDict()
      .then((dict) => this.setStatusesDict(dict))
      .catch(onError);
  },
});
