import axios from "axios";
import { t } from "i18next";
import { notification } from "antd";
import { makeAutoObservable } from "mobx";
import { appStore } from "src/appStore";
import { apiAuthUrl } from "src/common/apiUrl";
import { onError } from "src/common/onError";

export type PassRestoreData = {
  email: string;
};

export const passRestoreStore = makeAutoObservable({
  buzy: false,
  setBuzy(value: boolean) {
    this.buzy = value;
  },
  async send(values: PassRestoreData) {
    try {
      this.setBuzy(true);
      await axios(apiAuthUrl("/forgot-password"), {
        params: { login: values.email },
      });
      appStore.setOverlayType("login");
      notification.success({ message: t("Letter has been sent") });
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  },
});
