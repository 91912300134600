import { t } from "i18next";
import { makeAutoObservable } from "mobx";
import {
  FormBlockDef,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { RemoteData } from "src/common/RemoteData";
import { zObjectItem, ZObjectItem } from "src/types/ZObjectItem";
import { getIdLabels, IdLabel } from "src/references/getIdNames";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { appStore } from "src/appStore";
import { apiAuthUrl, apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { langHdr, optionalLangParams } from "src/lang/langHdr";
import { AxiosError } from "axios";
import { onError } from "src/common/onError";
import { buildMnenoGroup } from "../EntityCardPage/blockBuilder/buildMnemoGroup";
import { BuilderCtx } from "../EntityCardPage/blockBuilder/BuilderCtx";
import {
  zPersonEntity,
  ZPersonEntity,
} from "../ManagementPage/PersonsTab/ZPersonEntity";
import { EdPasswordChange } from "./PasswordChangeBlock/EdPasswordChange";
import { ed2entity, EdCardValues } from "../EntityCardPage/apiEntityCard";

type PersonData = {
  roleObject: ZObjectItem;
  person: ZPersonEntity;
};

export class UserAccountPageStore {
  constructor() {
    makeAutoObservable(this);
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      if (this.attrTypesList.length === 0) {
        this.setAttrTypesList(await getIdLabels("attrType", "attrType"));
      }
      const { roleId: userRoleId, id: userId } = appStore.userInfo;

      // Договорились, что в запрос идёт roleId из /current.
      const langParams = optionalLangParams(true);
      const resp = await rest.get(
        apiObjUrl(`/roles/${userRoleId}`),
        langParams,
      );
      const roleObject = zObjectItem.parse(resp.data);

      const resp1 = await rest.get(apiObjUrl("/users"), {
        params: { userId },
        ...langParams,
      });
      const person = zPersonEntity.parse(resp1.data);

      this.setData({ status: "ready", result: { roleObject, person } });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  changingPass = false;

  setChangingPass(value: boolean) {
    this.changingPass = value;
  }

  async changePassword(values: EdPasswordChange): Promise<boolean> {
    const { oldPass: currentPass, newPass } = values;
    try {
      this.setChangingPass(true);
      if (!currentPass) throw Error("Old password not specified");
      if (!newPass) throw Error("New password not specified");
      await rest.put(
        apiAuthUrl("/reset-password"),
        {
          currentPassword: currentPass,
          newPassword: newPass,
        },
        {
          headers: langHdr(),
        },
      );
      return true;
    } catch (e) {
      // LPLM-1241 Костыль, т.к. на бэке не могут обработать эту ситуацию.
      const ex = e as AxiosError;
      if (ex.response?.status === 401) {
        onError(Error(t("Incorrect current password")));
      } else {
        onError(e);
      }
      return false;
    } finally {
      this.setChangingPass(false);
    }
  }

  async submitInfo(values: EdCardValues): Promise<boolean> {
    try {
      this.formStore.setSaving(true);
      if (this.data.status !== "ready") throw Error("Data are not ready");
      const { roleObject, person } = this.data.result;
      const entity = ed2entity(values, person.id, roleObject.id);
      await rest.put(apiObjUrl(`/users/${person.id}`), entity);
      return true;
    } catch (e) {
      onError(e);
      return false;
    } finally {
      this.formStore.setSaving(false);
    }
  }

  attrTypesList: IdLabel[] = [];

  setAttrTypesList(list: IdLabel[]) {
    this.attrTypesList = list;
  }

  get attrTypesDict(): Record<number, string> {
    return makeDictNameById(this.attrTypesList);
  }

  data: RemoteData<PersonData> = { status: "none" };

  setData(newData: RemoteData<PersonData>) {
    this.data = newData;
  }

  formStore = new FormWithBlockStore();

  get userRootBlock(): FormBlockDef | null {
    const { data } = this;
    if (data.status !== "ready") return null;
    const { roleObject } = data.result;
    const ctx: BuilderCtx = {
      typesMap: this.attrTypesDict,
      // Внимание, костыль! Договорились, что открытым для редактирование полем будет атрибут с типом contentLanguage
      canUpdate: (attr, typesMap) =>
        typesMap[attr.valueType] === AttrTypeName.contentLanguage,
    };
    return buildMnenoGroup("root", " ", roleObject.attributes, null, ctx);
  }

  get initialData() {
    const result: Record<string, string[] | null> = {};
    if (this.data.status === "ready") {
      const { attributeValues } = this.data.result.person;
      attributeValues.forEach(({ attributeId, values }) => {
        result[String(attributeId)] = values;
      });
    }
    return result;
  }
}

export const userAccountPageStore = new UserAccountPageStore();
