// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomPositionItem-BomPositionItem-module__positionBox--GKaI1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomPositionItem-BomPositionItem-module__name--l7gVv {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/Bom2Table/BomPositionItem/BomPositionItem.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACA;EACI,OAAA;AACJ","sourcesContent":[".positionBox {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;\n}\n.name {\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"positionBox": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomPositionItem-BomPositionItem-module__positionBox--GKaI1`,
	"name": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-BomPositionItem-BomPositionItem-module__name--l7gVv`
};
export default ___CSS_LOADER_EXPORT___;
