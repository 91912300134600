// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-RegisterPage-RegisterPage-module__page--uTgEj {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.src-pages-RegisterPage-RegisterPage-module__header--HbbG3 {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: row;
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
}
.src-pages-RegisterPage-RegisterPage-module__header--HbbG3 .src-pages-RegisterPage-RegisterPage-module__title--ORBfW {
  text-decoration: none;
  cursor: pointer;
  flex: 1;
  padding: 22px 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}
.src-pages-RegisterPage-RegisterPage-module__header--HbbG3 .src-pages-RegisterPage-RegisterPage-module__title--ORBfW span {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: rgba(0, 0, 0, 0.85);
}
.src-pages-RegisterPage-RegisterPage-module__header--HbbG3 .src-pages-RegisterPage-RegisterPage-module__lang--EuMjX {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: thin solid #F0F0F0;
  width: 64px;
}
.src-pages-RegisterPage-RegisterPage-module__header--HbbG3 .src-pages-RegisterPage-RegisterPage-module__instruction--V1SGx {
  color: #E43D00;
}
.src-pages-RegisterPage-RegisterPage-module__main--BQsWQ {
  flex: 1;
  overflow-y: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/pages/RegisterPage/RegisterPage.module.less"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;AAFJ;AAIA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,0CAAA;AAFJ;AAHA;EAOQ,qBAAA;EACA,eAAA;EACA,OAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,uBAAA;AADR;AAbA;EAgBY,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,0BAAA;AAAZ;AApBA;EAwBQ,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,+BAAA;EACA,WAAA;AADR;AA3BA;EA+BQ,cAAA;AADR;AAKA;EACI,OAAA;EACA,kBAAA;AAHJ","sourcesContent":["@colBorder: #F0F0F0;\n@divider: 0px -1px 0px 0px @colBorder inset;\n\n.page {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n.header {\n    display: flex;\n    align-items: center;\n    gap: 24px;\n    flex-direction: row;\n    box-shadow: @divider;\n    .title {\n        text-decoration: none;\n        cursor: pointer;\n        flex: 1;\n        padding: 22px 24px;\n        display: flex;\n        flex-direction: row;\n        gap: 8px;\n        align-items: flex-start;\n        span {\n            font-size: 14px;\n            font-style: normal;\n            font-weight: 700;\n            line-height: normal;\n            color: rgba(0, 0, 0, 0.85);\n        }\n    }\n    .lang {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border-left: thin solid @colBorder;\n        width: 64px;\n    }\n    .instruction {\n        color: #E43D00;\n    }\n}\n\n.main {\n    flex: 1;\n    overflow-y: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `src-pages-RegisterPage-RegisterPage-module__page--uTgEj`,
	"header": `src-pages-RegisterPage-RegisterPage-module__header--HbbG3`,
	"title": `src-pages-RegisterPage-RegisterPage-module__title--ORBfW`,
	"lang": `src-pages-RegisterPage-RegisterPage-module__lang--EuMjX`,
	"instruction": `src-pages-RegisterPage-RegisterPage-module__instruction--V1SGx`,
	"main": `src-pages-RegisterPage-RegisterPage-module__main--BQsWQ`
};
export default ___CSS_LOADER_EXPORT___;
