import { Key, ReactNode } from "react";
/* eslint no-plusplus: "off" */

export interface NotifyParserCtx {
  makeKey(index: number, text: string): Key;
  onObject(id: string, text: string): ReactNode;
  onEntity(id: string, text: string): ReactNode;
  onEmail(id: string, text: string): ReactNode;
  onExtSource(href: string): ReactNode;
  onText(text: string): ReactNode;
}
/**
 * Преобразование текста, который содержит специальные синтаксические конструкции, в список React-узлов
 * @see http://jira.d4r.int/browse/LPLM-1356
 * Синтаксические вставки: ${PARAM}{ID}{TEX}
 * Возможные параметры: "LINK TO OBJECT" | "LINK TO ENTITY" | "EMAIL"
 * @param text
 */
export const parseNotificationText = (
  srcText: string,
  ctx: NotifyParserCtx,
) => {
  let text = srcText;
  const result: [Key, ReactNode][] = [];
  let index = 1;
  for (;;) {
    const res = /\${([A-Z\s_]+)}{([^}]*)}{([^}]*)}/.exec(text);
    if (!res) {
      if (text) result.push([ctx.makeKey(index, text), ctx.onText(text)]);
      break;
    }
    const divider = res[0]!;
    const cmd = res[1]!;
    const id = res[2]!;
    const label = res[3]!;
    const pos = text.indexOf(divider);
    if (pos < 0) break; // такого быть не должно
    if (pos > 0) {
      const leftText = text.slice(0, pos);
      result.push([ctx.makeKey(index++, leftText), ctx.onText(leftText)]);
    }
    text = text.slice(pos + divider.length);
    const fn = cmdDict[cmd];
    if (fn) {
      result.push([ctx.makeKey(index++, label), fn(ctx, id, label)]);
    }
  }
  return result;
};
const cmdDict: Record<
  string,
  (ctx: NotifyParserCtx, id: string, text: string) => ReactNode
> = {
  "LINK TO OBJECT": (ctx, id, text) => ctx.onObject(id, text),
  "LINK TO ENTITY": (ctx, id, text) => ctx.onEntity(id, text),
  EMAIL: (ctx, id, text) => ctx.onEmail(id, text),
  LINK_TO_EXTERNAL_SOURCE: (ctx, _id, href) => ctx.onExtSource(href),
};
