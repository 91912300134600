import * as React from "react";
import { Checkbox } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { classNames } from "src/common/classNames";
import { ZRolesGroup } from "../../../roles/roleTypes";
import styles from "./SelectRoles.module.less";

interface PropsSelectRoles {
  value?: number[] | null;
  onChange?(newValue: number[] | undefined): void;
  roleGroups: ZRolesGroup[];
}

type RoleDef = {
  roleId: number;
  roleName: string;
  checked: boolean;
};
type GroupDef = {
  groupId: number;
  groupName: string;
  groupStatus: "empty" | "full" | "middle";
  rolesChecked: number;
  roles: RoleDef[];
};
type Groups = GroupDef[];

const createGroups = (
  values: number[] | null | undefined,
  roleGroups: ZRolesGroup[],
): Groups => {
  const valueSet = new Set<number>(values);
  const result: Groups = roleGroups.map(({ groupId, groupName, roles }) => {
    const group: GroupDef = {
      groupId,
      groupName,
      roles: roles.map(({ roleId, roleName }) => ({
        roleId,
        roleName,
        checked: valueSet.has(roleId),
      })),
      rolesChecked: 0,
      groupStatus: "middle",
    };
    const rolesChecked = group.roles.reduce(
      (sum, { checked }) => sum + (checked ? 1 : 0),
      0,
    );
    group.rolesChecked = rolesChecked;
    if (rolesChecked === 0) {
      group.groupStatus = "empty";
    } else if (rolesChecked === roles.length) {
      group.groupStatus = "full";
    }
    return group;
  });
  return result;
};

const toggleSet = (src: Set<number>, id: number): Set<number> => {
  const dst = new Set(src);
  if (dst.has(id)) {
    dst.delete(id);
  } else {
    dst.add(id);
  }
  return dst;
};

export const SelectRoles: React.FC<PropsSelectRoles> = (props) => {
  const { value, onChange, roleGroups } = props;
  const [collapsed, setCollapsed] = React.useState(new Set<number>());
  const groups = createGroups(value, roleGroups);
  const allRoles: RoleDef[] = groups.flatMap(({ roles }) => roles);
  const totalChecked: number = value?.length ?? 0;
  const rolesCount = allRoles.length;

  const callOnChange = (roleIds: number[]) => onChange?.(roleIds.sort());

  const toggleRole = (targetRoleId: number) =>
    callOnChange(
      allRoles
        .map((role) =>
          role.roleId === targetRoleId
            ? { ...role, checked: !role.checked }
            : role,
        )
        .filter(({ checked }) => checked)
        .map(({ roleId }) => roleId),
    );
  const onGroupCheck = (groupId: number) =>
    callOnChange(
      groups
        .map((group) => {
          if (group.groupId !== groupId) return group;
          // если full, то сбрасываем все. Иначе - устанавливаем все
          const checked = group.groupStatus !== "full";
          return {
            ...group,
            roles: group.roles.map((role) => ({ ...role, checked })),
          };
        })
        .flatMap(({ roles }) => roles)
        .filter(({ checked }) => checked)
        .map(({ roleId }) => roleId),
    );
  const onTotalCheck = () =>
    callOnChange(
      totalChecked === rolesCount ? [] : allRoles.map(({ roleId }) => roleId),
    );
  if (allRoles.length === 0) {
    return (
      <div className={styles.emptyBox}>
        <div>Добавьте роли для пользователей:</div>
        <div>Группы и роли</div>
      </div>
    );
  }

  return (
    <div className={styles.box}>
      <div className={styles.header}>Группы</div>
      <div className={styles.check}>
        <Checkbox
          checked={totalChecked === rolesCount}
          indeterminate={totalChecked > 0 && totalChecked < rolesCount}
          onChange={onTotalCheck}
        >
          {totalChecked} / {allRoles.length}
        </Checkbox>
      </div>
      {groups.map(
        ({ groupId, groupName, groupStatus, rolesChecked, roles }) => (
          <React.Fragment key={groupId}>
            <div className={styles.group}>
              <button
                type="button"
                className={styles.expandButton}
                onClick={() => setCollapsed((prev) => toggleSet(prev, groupId))}
              >
                {collapsed.has(groupId) ? <PlusOutlined /> : <MinusOutlined />}
              </button>
              <span>{groupName}</span>
            </div>
            <div className={classNames([styles.check, styles.header])}>
              <Checkbox
                checked={groupStatus === "full"}
                indeterminate={groupStatus === "middle"}
                onChange={() => onGroupCheck(groupId)}
              >
                {rolesChecked} / {roles.length}
              </Checkbox>
            </div>
            {!collapsed.has(groupId) &&
              roles.map(({ roleId, roleName, checked }) => (
                <React.Fragment key={roleId}>
                  <div
                    className={classNames([
                      styles.role,
                      [checked, styles.selected],
                    ])}
                  >
                    {roleName}
                  </div>
                  <div
                    className={classNames([
                      styles.check,
                      [checked, styles.selected],
                    ])}
                  >
                    <Checkbox
                      checked={checked}
                      onChange={() => toggleRole(roleId)}
                    />
                  </div>
                </React.Fragment>
              ))}
          </React.Fragment>
        ),
      )}
    </div>
  );
};

SelectRoles.defaultProps = {
  value: undefined,
  onChange: undefined,
};
