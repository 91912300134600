import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Tooltip } from "antd";
import {
  AimOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  FormOutlined,
  ProfileOutlined,
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { ModelEditorStore } from "../ModelEditorStore";

import styles from "./MEdToolbar.module.less";
import { CmdAttrDelete } from "../commands/CmdAttrDelete";

/* eslint  react/require-default-props: "off" */

interface PropsToolButton {
  icon: React.ReactElement;
  tooltip: string;
  disabled: boolean;
  primary?: boolean;
  onClick?: () => void;
}
const ToolButton: React.FC<PropsToolButton> = ({
  icon,
  tooltip,
  disabled,
  primary,
  onClick,
}: PropsToolButton) => (
  <Tooltip title={tooltip}>
    <Button
      icon={icon}
      disabled={disabled}
      size="large"
      type={primary ? "primary" : "default"}
      onClick={onClick}
    />
  </Tooltip>
);

const Divider: React.FC = () => <div className={styles.divider} />;

interface PropsMEdToolbar {
  store: ModelEditorStore;
}

export const MEdToolbar: React.FC<PropsMEdToolbar> = observer(
  (props: PropsMEdToolbar) => {
    const { store } = props;
    const { undoTitle, redoTitle, canUndo, canRedo } = store;
    return (
      <div className={styles.toolbar}>
        <ToolButton
          icon={<ProfileOutlined />}
          tooltip="Свойства модели"
          disabled={!store.canEditModel}
          onClick={() => store.setState("editModel")}
        />
        <ToolButton
          icon={<AppstoreAddOutlined />}
          tooltip="Добавить атрибут (F2)"
          disabled={!store.canAddAttr}
          onClick={() => store.onAddAttr()}
        />
        <ToolButton
          icon={<FormOutlined />}
          tooltip="Редактировать атрибут"
          disabled={!store.canEditAttr}
          onClick={() => store.startEditAttr()}
        />
        <ToolButton
          icon={<DeleteOutlined />}
          tooltip="Удалить атрибут"
          disabled={!store.canDeleteAttr}
          onClick={() => {
            if (store.curAttr) {
              store.execCmd(new CmdAttrDelete(store, store.curAttrIndex));
            }
          }}
        />
        <Divider />
        <ToolButton
          icon={<UndoOutlined />}
          tooltip={undoTitle ?? "Отменить"}
          disabled={!canUndo}
          onClick={() => store.execUndo()}
        />
        <ToolButton
          icon={<RedoOutlined />}
          tooltip={redoTitle ?? "Восстановить"}
          disabled={!canRedo}
          onClick={() => store.execRedo()}
        />
        <Divider />
        <ToolButton
          icon={<ZoomInOutlined />}
          tooltip="Увеличить"
          disabled={!store.canZoom}
          onClick={() => store.zoomIn()}
        />
        <ToolButton
          icon={<ZoomOutOutlined />}
          tooltip="Уменьшить"
          disabled={!store.canZoom}
          onClick={() => store.zoomOut()}
        />
        <ToolButton
          icon={<AimOutlined />}
          tooltip="Актуальный размер"
          disabled={!store.canZoom}
          onClick={() => store.setZoom(1)}
        />
      </div>
    );
  },
);
