import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { ZAttrMeta } from "src/types/ZAttrMeta";
import { TreeLikeData } from "src/types/TreeLikeData";
import {
  SelectionSetings,
  TableLoadParams,
  TableStore,
} from "src/components/tables/TableStore";
import { ZObjState } from "src/types/ZObjState";
import { walk } from "src/components/TreeStd";
import React from "react";
import { ZObjectItem } from "src/types/ZObjectItem";
import { hasPermissionIn, Permission } from "src/types/Permission";
import { AttsTreeStore } from "./AttsTree/AttsTreeStore";
import { CheckListWithOrderStore } from "./CheckListWithOrder/CheckListWithOrderStore";
import { compoundEntityTableStore } from "./EntityList/compoundEntityTableStore";
import { ZEntityFilters, ZEntityRow } from "./EntityList/types";
import { loadTreeAttrValues } from "./AttsTree/loadTreeAttrValues";
import { attr2TreeData, createBlankAttrTreeNode } from "./AttsTree/transforms";
import { AttrTreeData } from "./AttsTree/types";
import { loadObject } from "../EntityCardPage/apiEntityCard";
import { loadObjectStates } from "../ManagementPage/Obj2Tab/roles/rolesApi";
import {
  loadObjectAttrinbutesAll,
  makeObjectAttributesMap,
} from "../ManagementPage/objectsApi";
import { EntFilterActionType } from "./EntityFiltersPage.types";

type EntitySearchState = {
  attsOrder: string[];
  attsSelected: string[];
  treeSlected: {
    treeData: TreeLikeData<AttrTreeData>[];
    selected: string[];
    expanded: string[];
  };
};

const store2EntitySearchState = (
  store: EntityFiltersPageStore,
): EntitySearchState | null => ({
  attsOrder: store.cLOrderedStore.order.map(String),
  attsSelected: store.cLOrderedStore.checkListStore.selectedKeys.map(String),
  treeSlected: {
    treeData: store.attrsTreeStore.treeStdStore.treeData,
    selected: store.attrsTreeStore.selectedKeys.map(String),
    expanded: store.attrsTreeStore.expandedKeys.map(String),
  },
});

const createStorageKeyState = (objId: number) => `enitiesSearchState-${objId}`;
const createStorageKeyLP = (objId: number) => `enitiesSearchLP-${objId}`;

export const saveLoadParams = (
  params: TableLoadParams<ZEntityFilters> | undefined,
  objId: number,
) => {
  try {
    localStorage.setItem(createStorageKeyLP(objId), JSON.stringify(params));
  } catch (error) {
    onError(error);
  }
};

const getSavedLoadParams = (
  objId: number,
): TableLoadParams<ZEntityFilters> | null => {
  try {
    const data = localStorage.getItem(createStorageKeyLP(objId));
    return data ? JSON.parse(data) : null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

export class EntityFiltersPageStore {
  constructor(props?: {
    objectId?: number;
    selectionSettings?: SelectionSetings<ZEntityRow>;
    onLoad?: () => void;
    onRowClick?: (row: ZEntityRow, index?: number) => void;
    actions?: Set<EntFilterActionType>;
  }) {
    const { objectId, onLoad, onRowClick, selectionSettings, actions } =
      props || {};
    if (selectionSettings) this.selectionSettings = selectionSettings;
    if (objectId) this.setCurrObjId(objectId);
    if (onLoad) this.onLoad = onLoad;
    if (onRowClick) this.onRowClick = onRowClick;
    this.availableActions =
      actions ??
      new Set([
        EntFilterActionType.changeState,
        EntFilterActionType.copy,
        EntFilterActionType.create,
        EntFilterActionType.export,
        EntFilterActionType.delete,
      ]);
    makeAutoObservable(this);
  }

  protected availableActions: Set<EntFilterActionType>;

  private selectionSettings: SelectionSetings<ZEntityRow> = {
    keepSelected: true,
    selectionType: "checkbox",
  };

  private onLoad: (() => void) | null = null;

  onRowClick: ((row: ZEntityRow, index?: number) => void) | null = null;

  cLOrderedStore = new CheckListWithOrderStore();

  attrsTreeStore = new AttsTreeStore();

  attributes: ZAttrMeta[] = [];

  setAttributes(list: ZAttrMeta[]) {
    this.attributes = list;
  }

  get attributesMap() {
    return makeObjectAttributesMap(this.attributes);
  }

  get avalibleActionsSet() {
    return this.availableActions;
  }

  currObjId: number | null = null;

  setCurrObjId(id: number) {
    this.currObjId = id;
  }

  objectStates: ZObjState[] = [];

  setObjectStates(list: ZObjState[]) {
    this.objectStates = list;
  }

  currObjName: string = "";

  setCurrObjName(name: string) {
    this.currObjName = name;
  }

  curObject: ZObjectItem | null = null;

  setCurObject(obj: ZObjectItem | null) {
    this.curObject = obj;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  tableStore: TableStore<ZEntityRow, ZEntityFilters> | null = null;

  get canCreate(): boolean {
    const { curObject } = this;
    return (
      this.availableActions.has(EntFilterActionType.create) &&
      !!curObject &&
      hasPermissionIn(curObject, Permission.entityCreate)
    );
  }

  get canDelete(): boolean {
    const { curObject } = this;
    return (
      this.availableActions.has(EntFilterActionType.delete) &&
      !!curObject &&
      hasPermissionIn(curObject, Permission.entityDelete)
    );
  }

  get objectStatesOptions() {
    return this.objectStates.map((state) => ({
      label: state.name,
      value: state.id,
    }));
  }

  async init(
    objectId: number,
    options?: {
      statesLoader?: () => Promise<ZObjState[]>;
    },
  ) {
    try {
      this.setLoading(true);
      this.setCurObject(null);
      this.cLOrderedStore.clear();
      this.attrsTreeStore.clear();
      this.setCurrObjId(objectId);
      const object = await loadObject(objectId, { translate: true });
      this.setCurrObjName(object.name);
      this.setCurObject(object);
      this.setObjectStates(
        await loadObjectStates(objectId, { translate: true }),
      );
      const attrList = Object.values(
        await loadObjectAttrinbutesAll(String(objectId), { translate: true }),
      );
      this.setAttributes(attrList);
      const attsOptions = this.attributes.map((attr) => ({
        label: attr.name,
        value: String(attr.id),
      }));
      this.cLOrderedStore.checkListStore.setOptions(attsOptions);
      await this.loadEntitySearchState();

      const savedParams = getSavedLoadParams(objectId);
      const savedFilters = savedParams?.filters || {};
      const defFilters: ZEntityFilters = { objectId };
      const finalFilters = {
        ...savedFilters,
        ...defFilters,
      };
      const finalParams = { ...savedParams, filters: finalFilters };
      const { statesLoader } = options || {};
      this.tableStore = await compoundEntityTableStore(
        objectId,
        "id",
        finalParams,
        this.selectionSettings,
        () => {
          saveLoadParams(this.tableStore?.params, objectId);
          this.onLoad?.();
        },
        statesLoader,
      );
      this.reloadWithActualFilters();
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async appendFilterTree(node?: TreeLikeData<AttrTreeData>) {
    try {
      if (!this.currObjId) throw new Error("System error: currObjId = null");
      const {
        attrsTreeStore: { treeStdStore },
        cLOrderedStore: { orderedSelected },
      } = this;

      const attrIndex = orderedSelected.findIndex(
        ({ value }) => value === String(node?.attrId),
      );
      const exist = attrIndex !== -1;
      const actualIndex = exist ? attrIndex : 0;
      const nextIndex = exist ? actualIndex + 1 : 0;
      const nextKey = orderedSelected[nextIndex]?.value;
      const isLeaf = nextIndex === orderedSelected.length - 1;
      const attribute = this.attributesMap[Number(nextKey)];

      if (!attribute) return;
      this.attrsTreeStore.setLoading(true);
      const path = treeStdStore.findPath(node?.key || "") || [];
      const attrValIds = path
        .map((key) => treeStdStore.findNode(key))
        .map((item) => item?.attrValueId)
        .filter((item) => !!item) as number[][];

      const loadedAttrs = await loadTreeAttrValues(
        attribute.id,
        this.currObjId,
        attrValIds.flat(),
      );

      const treeDataProm = loadedAttrs.map((value) =>
        attr2TreeData(
          attribute,
          `${path.join("-")}-${value.ids.join("-")}`,
          value.value || "",
          value.ids,
          isLeaf,
        ),
      );

      const containtAtts = loadedAttrs.length > 0;
      const treeData = containtAtts
        ? await Promise.all(treeDataProm)
        : createBlankAttrTreeNode(attribute, `${node?.key}-${attribute.id}`);

      if (node) {
        treeStdStore.appendNode(node.key, treeData);
      } else {
        treeStdStore.setTreeData(treeData);
      }
      this.saveEntitySearchState();
    } catch (error) {
      onError(error);
    } finally {
      this.attrsTreeStore.setLoading(false);
    }
  }

  resetFilterTree() {
    this.attrsTreeStore.clear();
    this.appendFilterTree();
    this.reloadWithActualFilters();
  }

  reloadWithActualFilters() {
    const {
      attrsTreeStore,
      attrsTreeStore: { treeStdStore },
    } = this;
    const getNode = (nodeKey: React.Key) =>
      treeStdStore.findNode(nodeKey)?.attrValueId;

    const path = treeStdStore.findPath(attrsTreeStore.lastSelectedKey || "");

    const dictionaryValueIds = path
      ? (path.map(getNode).filter((id) => !!id) as number[][])
      : [];

    const actualFilters = {
      ...(this.tableStore?.filters || {}),
      dictionaryValueIds,
    };

    this.tableStore?.setFilters(actualFilters, true);
  }

  saveEntitySearchState() {
    try {
      if (!this.currObjId) throw new Error("System error: currObjId = null");
      const dataForSave = store2EntitySearchState(this);
      localStorage.setItem(
        createStorageKeyState(this.currObjId),
        JSON.stringify(dataForSave),
      );
    } catch (error) {
      onError(error);
    }
  }

  async loadEntitySearchState() {
    try {
      const data = this.getSavedSearchState();
      if (!this.currObjId || !data) return;
      const {
        cLOrderedStore,
        cLOrderedStore: { checkListStore },
        attrsTreeStore,
      } = this;
      checkListStore.setSelectedKeysSet(data.attsSelected);
      cLOrderedStore.setOrder(data.attsOrder);
      attrsTreeStore.setSelectedKeys(data.treeSlected.selected);
      attrsTreeStore.setExpandedKeys(data.treeSlected.expanded);
      /* чтобы дерево отобразилось кооректно
       * необходимо заново загрузить его структуру
       */
      await this.appendFilterTree();
      const treeLoadersList: (() => Promise<void>)[] = [];
      walk((node) => {
        const loadNodes = async () => {
          /**
           * нам не нужно грузить дерево полностью
           * загружаем только те ноды, которые были уже созданы
           */
          if (node.children && node.children.length > 0)
            await this.appendFilterTree(node);
        };
        treeLoadersList.push(loadNodes);
      }, data.treeSlected.treeData);
      await Promise.all(treeLoadersList.map((fn) => fn()));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  getSavedSearchState(): EntitySearchState | null {
    try {
      if (!this.currObjId) throw new Error("System error: currObjId = null");

      const data = localStorage.getItem(createStorageKeyState(this.currObjId));
      return data ? JSON.parse(data) : null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }
}
