import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";

export const copyEntities = async (
  entities: number[],
  attributes: number[],
  parentEntityId: number,
) => {
  await rest.post(apiObjUrl("/entities/list-copy"), {
    entities,
    attributes,
    parentEntityId,
  });
};
