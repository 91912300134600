import React, { createRef, RefObject, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Table, TableProps } from "antd";
import {
  getColumnWidth,
  getColumnWidthStyles,
} from "src/pages/EntityFiltersPage/AsyncTableView/templates/getColumnWidths";
import { ResizerStd } from "src/pages/EntityFiltersPage/AsyncTableView/ResizerStd";
import { classNames } from "src/common/classNames";
import { SchedulingControlStore } from "../SchedulingControlStore";
import { ZSchedulingRow } from "../../SchedulingTypes";
import styles from "./SchedulingTable.module.less";
import { SchedulingToolbar } from "./SchedTableToolbar";
import { SchedTaskEditor } from "../SchedTaskEditor";

interface PropsSchedulingTable {
  store: SchedulingControlStore;
}

export const SchedulingTable: React.FC<PropsSchedulingTable> = observer(
  ({ store }) => {
    const { visibleColumns, dataSource, selectedRows, columnWidths } = store;

    const rowSelection: TableProps["rowSelection"] = {
      type: "checkbox",
      getCheckboxProps: (row: ZSchedulingRow) => ({
        disabled: row.type === "PLAN",
        className: row.type === "PLAN" ? styles.hideCheckbox : "",
      }),
      onChange: (keys) => store.setSelectedRows(keys.map((key) => Number(key))),
      selectedRowKeys: selectedRows,
      checkStrictly: false,
    };

    const refTbl = React.useRef<HTMLDivElement>(null);
    const [tblHeight, setTblHeight] = React.useState(200);
    React.useEffect(() => {
      const setHeight = () => {
        const { current } = refTbl;
        if (current) {
          let h = current.clientHeight;
          const thead = current.querySelector("thead");
          if (thead) h -= thead.offsetHeight;
          setTblHeight(h);
        }
      };
      setHeight();
      const onResize = () => {
        setHeight();
      };
      window.addEventListener("resize", onResize);
      return () => {
        window.removeEventListener("resize", onResize);
      };
    }, []);

    const columnRefs = useMemo(
      () =>
        visibleColumns?.reduce(
          (acc, { key }) => ({
            ...acc,
            [key as string]: createRef<HTMLDivElement>(),
          }),
          {} as Record<string, RefObject<HTMLDivElement>>,
        ),
      [visibleColumns],
    );

    const resizableColumns = visibleColumns?.map((column) => {
      const strKey = String(column.key);
      return {
        ...column,
        title: (
          <div
            ref={columnRefs?.[strKey]}
            key={strKey}
            className={styles.titleContainer}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames([styles.titleContent, styles.cellEllipsis])}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {String(column.title)}
            </div>
            <ResizerStd
              column={{ key: strKey }}
              store={{ ...store, resizableColumns: true }}
              containerRef={columnRefs?.[strKey]}
            />
          </div>
        ),
        style: getColumnWidthStyles(columnWidths, strKey),
        width: getColumnWidth(columnWidths, strKey),
      };
    });

    return (
      <>
        <SchedulingToolbar store={store} />
        <div ref={refTbl} className={styles.tableContainer}>
          <Table
            columns={resizableColumns}
            dataSource={dataSource}
            rowKey="id"
            pagination={false}
            scroll={{ x: "max-content", y: tblHeight }}
            rowClassName={(row: ZSchedulingRow) =>
              row.type === "TASK" ? styles.taskRow : undefined
            }
            rowSelection={rowSelection}
            expandable={{
              defaultExpandAllRows: true,
            }}
            onRow={(row) => ({
              onClick: () => store.openEditor(row),
            })}
          />
        </div>
        <SchedTaskEditor store={store} />
      </>
    );
  },
);
