import { z } from "zod";
import { ZAttribute } from "src/types/ZAttribute";

export const zTagModeDictRef = z.enum(["standart", "tag", "tagsMulti"]);
export type ZTagModeDictRef = z.infer<typeof zTagModeDictRef>;
export const zTagModeDictRefMeta = z.object({
  tagsColorMap: z.record(z.string(), z.string()).optional(),
  tagColor: z.string().optional(),
});
export type ZTagModeDictRefMeta = z.infer<typeof zTagModeDictRefMeta>;
export const zDictRef = z.object({
  view: z.literal("DictRef"),
  mode: zTagModeDictRef.optional(),
  modeMeta: zTagModeDictRefMeta.optional(),
});
export type ZDictRef = z.infer<typeof zDictRef>;
export const makeDictRefProps2 = (values: string[], attr: ZAttribute) => ({
  attr,
  values,
});
