// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-DashboardsPage-DashboardsPage-module__pageBox--uLQyf {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-pages-DashboardsPage-DashboardsPage-module__tabs--zGESF {
  padding: 0px 24px;
}
.src-pages-DashboardsPage-DashboardsPage-module__tabs--zGESF .ant-tabs-nav {
  margin: 0;
}
.src-pages-DashboardsPage-DashboardsPage-module__curTab--g7HOF {
  flex: 1;
  background: #F5F5F5;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
}
.src-pages-DashboardsPage-DashboardsPage-module__item--gIxME {
  background-color: white;
}
.src-pages-DashboardsPage-DashboardsPage-module__item--gIxME > iframe {
  width: 100%;
  height: 490px;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DashboardsPage/DashboardsPage.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACA;EACI,iBAAA;AACJ;AAFA;EAGQ,SAAA;AAER;AACA;EACI,OAAA;EACA,mBAAA;EACA,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;AACJ;AACA;EACI,uBAAA;AACJ;AAAI;EACI,WAAA;EACA,aAAA;EACA,YAAA;AAER","sourcesContent":[".pageBox {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n.tabs {\n    padding: 0px 24px;\n    :global(.ant-tabs-nav) {\n        margin: 0;\n    }\n}\n.curTab {\n    flex: 1;\n    background: #F5F5F5;\n    padding: 16px 24px 24px 24px;\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    overflow: auto;\n}\n.item {\n    background-color: white;\n    & > iframe {\n        width: 100%;\n        height: 490px;\n        border: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageBox": `src-pages-DashboardsPage-DashboardsPage-module__pageBox--uLQyf`,
	"tabs": `src-pages-DashboardsPage-DashboardsPage-module__tabs--zGESF`,
	"curTab": `src-pages-DashboardsPage-DashboardsPage-module__curTab--g7HOF`,
	"item": `src-pages-DashboardsPage-DashboardsPage-module__item--gIxME`
};
export default ___CSS_LOADER_EXPORT___;
