import { rest } from "src/common/rest";
import { apiBomUrl } from "src/common/apiUrl";
import { zIdName, ZIdName } from "src/types/ZIdName";
import { langHdr } from "src/lang/langHdr";
import {
  ZBom2Settings,
  zBom2Settings,
  zBom2SummaryRow,
  ZBom2SummaryRow,
  ZBom2SupplierResponse,
  zBom2SupplierResponse,
} from "./Bom2Types";

export const urlBom2 = (path: string) => apiBomUrl(`/v2/api/${path}`);

export const loadBom2SettingsOpt = async (
  objectServiceId: number,
): Promise<ZBom2Settings> => {
  const resp = await rest.get(apiBomUrl(`/bom/object/${objectServiceId}`));
  try {
    return zBom2Settings.parse(resp.data);
  } catch (e) {
    // Когда создаётся экземпляр сервиса, то настройки пустые.
    // Но при первом сохранении они должны стать корректными.
    return resp.data as ZBom2Settings;
  }
};

export const saveBom2Settings = async (data: ZBom2Settings) => {
  await rest.post(apiBomUrl("/bom/object"), data);
};

export const loadBom2Summary = async (
  objectServiceId: number,
  entityId: number,
): Promise<ZBom2SummaryRow[]> => {
  const resp = await rest.get(urlBom2("/bom"), {
    params: {
      objectServiceId,
      entityId,
    },
  });
  return zBom2SummaryRow.array().parse(resp.data);
};

export const createBom2 = async (
  objectServiceId: number,
  entityId: number,
  name: string,
  supplierId: number | null,
) => {
  await rest.post(urlBom2("/bom"), undefined, {
    params: {
      objectServiceId,
      entityId,
      name,
      supplierId,
    },
  });
};

export const deleteBom2 = async (objectServiceId: number, bomId: number) => {
  await rest.delete(urlBom2("/bom"), { params: { objectServiceId, bomId } });
};

export const loadBom2Suppliers = async (
  page: number,
  size: number,
  query: string | undefined,
): Promise<ZBom2SupplierResponse> => {
  const resp = await rest.get(urlBom2("/supplier/get-paginated"), {
    params: { page, size, query },
  });
  return zBom2SupplierResponse.parse(resp.data);
};

export const loadBom2ColorColumns = async (
  bomId: number,
): Promise<ZIdName[]> => {
  const resp = await rest.get(
    urlBom2("/color-model/get-dynamic-color-columns"),
    {
      params: { bomId },
      headers: langHdr(),
    },
  );
  return zIdName.array().parse(resp.data);
};

export interface NewPositionColorModel {
  colorManual: string;
  colorReference: string;
  comment: string;
}

export const createBomPositionColorModel = async (
  bomPositionId: number,
  colorModelId: number,
  colorId: number,
  data: NewPositionColorModel,
) => {
  await rest.post(urlBom2("/bom-position-color-model/create"), data, {
    params: { bomPositionId, colorModelId, colorId },
  });
};
