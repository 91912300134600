import * as React from "react";
import { Link } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import {
  NotifyParserCtx,
  parseNotificationText,
} from "./parseNotificationText";
import styles from "./NotificationText.module.less";
/* eslint react/no-unstable-nested-components: "off" */

interface PropsNotificationText {
  text: string;
}

export const NotificationText: React.FC<PropsNotificationText> = ({ text }) => {
  const ctx: NotifyParserCtx = {
    makeKey: (index, txt) => `${index}:${txt}`,
    onObject: (objectId, txt) => (
      <Link to={makeUrl(PageUrl.entities, { objectId })}>{txt}</Link>
    ),
    onEntity: (id, txt) => (
      <Link to={makeUrl(PageUrl.entityCard, { id })}>{txt}</Link>
    ),
    onEmail: (addr, label) => <a href={`mailto:${addr}`}>{label}</a>,
    onExtSource: (href) => (
      <a target="_blank" href={href} rel="noreferrer">
        {href}
      </a>
    ),
    // Применение концов строк
    onText: (txt) =>
      txt.split("\n").map((line, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${i}:${line}`}>
          {i > 0 && <br />}
          {line}
        </React.Fragment>
      )),
  };
  const list = parseNotificationText(text, ctx);
  return (
    <div className={styles.text}>
      {list.map(([key, node]) => (
        <React.Fragment key={key}>{node}</React.Fragment>
      ))}
    </div>
  );
};
