import { z } from "zod";

const zCbvDictGroup = z.object({
  id: z.number(),
  name: z.string(),
});
export type ZCbvDictGroup = z.infer<typeof zCbvDictGroup>;

const zCbvParentAttr = z.object({
  id: z.number(),
  name: z.string(),
  restrictionName: z.string().optional().nullable(),
  groupByDictionary: zCbvDictGroup.array().optional().nullable(),
});
export type ZCbvParentAttr = z.infer<typeof zCbvParentAttr>;

export const zCbvLinkAttr = z.object({
  linkAttributeId: z.number(),
  linkAttributeName: z.string(),
  objectName: z.string(),
  attributes: zCbvParentAttr.array().optional().nullable(),
});
export type ZCbvLinkAttr = z.infer<typeof zCbvLinkAttr>;
