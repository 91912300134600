import { Form, Radio } from "antd";
import React, { Fragment } from "react";
import { z } from "zod";
import { obj2LabelValue } from "../obj2LabelValue";

export const zSeparator = z.enum(["comma", "slash", "newLine"]);
export type ZSeparator = z.infer<typeof zSeparator>;

const labelMap: Record<ZSeparator, string> = {
  comma: ",",
  newLine: "новая строка",
  slash: "/",
};

const valuesMap: Record<ZSeparator, React.ReactNode> = {
  comma: ",",
  newLine: <br />,
  slash: "/",
};

export const getSeparator = (key?: ZSeparator | null) =>
  key ? valuesMap[key] : labelMap.comma;

export type LabelDef = {
  content: React.ReactNode;
  key: React.Key;
};

export const seedSeparators = (
  labels: LabelDef[],
  sepKey: ZSeparator | null | undefined,
) => {
  const labelsWithSeparator: React.ReactNode[] = [];
  labels.forEach((item, index) => {
    labelsWithSeparator.push(
      <Fragment key={item.key}>{item.content}</Fragment>,
    );
    if (index !== labels.length - 1)
      labelsWithSeparator.push(
        <Fragment key={`separator_${item.key}`}>
          {getSeparator(sepKey)}
        </Fragment>,
      );
  });
  return labelsWithSeparator;
};

const options = obj2LabelValue(labelMap);

type PropsSeparator = { name: string[] };

export const Separator: React.FC<PropsSeparator> = ({ name }) => (
  <Form.Item label="Разделитель" name={name} rules={[{ required: true }]}>
    <Radio.Group>
      {options.map(({ label, value }) => (
        <Radio.Button key={value} value={value}>
          {label}
        </Radio.Button>
      ))}
    </Radio.Group>
  </Form.Item>
);
