import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { MCObjType } from "../MChartSettingsStore";
import { ZMesChartSettings } from "../../ZMesChartSettings";

interface PropsMCPointsSection {
  store: FormObjectSectionsStore<MCObjType>;
}

export const MCPointsSection: React.FC<PropsMCPointsSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZMesChartSettings, MCObjType>
      store={store}
      objKey={["msPointsObjectId"]}
      objType="MCPoint"
      objLabel="MC points object"
      title="MC points"
      attrs={[
        {
          attrKey: ["msPointsMcAttrId"],
          attrLabel: "Measurement Chart",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["msPointsPointAttrId"],
          attrLabel: "Point",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["msPointsDescriptionAttrId"],
          attrLabel: "Description",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["msPointsQcAttrId"],
          attrLabel: "QC",
          attrType: AttrTypeName.boolean,
        },
        {
          attrKey: ["msPointsTolMinusAttrId"],
          attrLabel: "Tol -",
          attrType: AttrTypeName.number,
        },
        {
          attrKey: ["msPointsTolPlusAttrId"],
          attrLabel: "Tol +",
          attrType: AttrTypeName.number,
        },
        {
          attrKey: ["msPointsPointValueAttrId"],
          attrLabel: "Point Value",
          attrType: AttrTypeName.childEntities,
        },
        {
          attrKey: ["msPointsResizeValueMinusAttrId"],
          attrLabel: "Resize -",
          attrType: AttrTypeName.number,
        },
        {
          attrKey: ["msPointsResizeValuePlusAttrId"],
          attrLabel: "Resize +",
          attrType: AttrTypeName.number,
        },
      ]}
    />
  ),
);
