import { ZAttrViewInfo, zAttrViewInfo } from "./zAttrViewInfo";

export const getViewInfo = (
  encodedData: string | null | undefined,
): ZAttrViewInfo | undefined => {
  try {
    if (!encodedData) return undefined;
    const json = JSON.parse(encodedData);
    return zAttrViewInfo.parse(json);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return undefined;
  }
};
