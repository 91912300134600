import { TreeLikeData } from "src/types/TreeLikeData";
import { ZAttribute } from "src/types/ZAttribute";
import { byAttrValType } from "src/common/attributes/byAttrValType";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { getIdNames } from "src/references/getIdNames";
import { getEntityCachedRequest } from "src/common/entity/getEntityCachedRequest";
import { getObjectRefAttrLabel } from "src/common/entity/composeEntityLabel/getObjectRefAttrLabel";
import { FileImageFilled } from "@ant-design/icons";
import React from "react";
import { getViewInfo } from "src/common/attrView";
import { Tag } from "antd";
import { PersonCellInfo } from "src/common/attrView/components/PersonCellInfo";
import { getFileAttrLabel } from "src/common/entity/composeEntityLabel/innerViews/FileRefInner/FileRefInner";
import { viewDate, viewDateTimeUtc, viewTimeUtc } from "src/common/formats";
import { AttrTreeData } from "./types";

const createObjectRefLabel = async (
  attribute: ZAttribute,
  entityId: string,
  attrTypeMap: Record<number, string>,
) => {
  const entity = await getEntityCachedRequest(Number(entityId));
  return getObjectRefAttrLabel(entity, attribute, [entityId], attrTypeMap, [], {
    separator: "comma",
    replace: { [AttrTypeName.image]: () => <FileImageFilled /> },
  });
};

const createUserListLabel = async (attribute: ZAttribute, defValue: string) => {
  const viewInfo = getViewInfo(attribute.viewType);
  if (viewInfo?.component?.view === "PersonCellInfo")
    return [
      <PersonCellInfo key={defValue} values={[defValue]} attr={attribute} />,
    ];
  return [
    <Tag key={defValue} color="red">
      error...
    </Tag>,
  ];
};

export const createTreeNodeLabel = async (
  attribute: ZAttribute,
  defValue: string,
) => {
  const attrTypeMap = makeDictNameById(await getIdNames("attrType"));
  return byAttrValType(
    attribute,
    attrTypeMap,
    {
      [AttrTypeName.object]: () =>
        createObjectRefLabel(attribute, defValue, attrTypeMap),
      [AttrTypeName.usersList]: () => createUserListLabel(attribute, defValue),
      [AttrTypeName.file]: () => getFileAttrLabel(attribute, [defValue]),
      [AttrTypeName.image]: () => getFileAttrLabel(attribute, [defValue]),
      [AttrTypeName.date]: () => Promise.resolve([viewDate(defValue)]),
      [AttrTypeName.dateTime]: () =>
        Promise.resolve([viewDateTimeUtc(defValue)]),
      [AttrTypeName.time]: () => Promise.resolve([viewTimeUtc(defValue)]),
    },
    async () => [defValue],
  );
};

export const attr2TreeData = async (
  attribute: ZAttribute,
  key: string,
  attrValue: string,
  attrValueId: number[],
  isLeaf: boolean,
): Promise<TreeLikeData<AttrTreeData>> => {
  const label = await createTreeNodeLabel(attribute, attrValue);
  return {
    key,
    title: label,
    attrId: attribute.id,
    attrValue,
    attrValueId,
    isLeaf,
  };
};

export const createBlankAttrTreeNode = (
  attribute: ZAttribute,
  key: string,
): TreeLikeData<AttrTreeData>[] => [
  {
    key,
    title: "нет данных",
    attrId: attribute.id,
    attrValue: "empty",
    attrValueId: [-1],
    isLeaf: true,
    selectable: false,
  },
];
