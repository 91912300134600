import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { MCObjType } from "../MChartSettingsStore";
import { ZMesChartSettings } from "../../ZMesChartSettings";

interface PropsMChartSection {
  store: FormObjectSectionsStore<MCObjType>;
}

export const MChartSection: React.FC<PropsMChartSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZMesChartSettings, MCObjType>
      store={store}
      objKey={["mcObjectId"]}
      objType="MChart"
      objLabel="Measurement chart object"
      title="Measurement chart"
      attrs={[
        {
          attrKey: ["mcNameAttrId"],
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["mcModelAttrId"],
          attrLabel: "Model",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["mcSizeScaleAttrId"],
          attrLabel: "Size scale",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["mcBaseSizeAttrId"],
          attrLabel: "Base size",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["mcCommentAttrId"],
          attrLabel: "Comment",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["mcMcPointAttrId"],
          attrLabel: "MC points",
          attrType: AttrTypeName.childEntities,
        },
        {
          attrKey: ["mcImageAttrId"],
          attrLabel: "Image",
          attrType: AttrTypeName.image,
        },
      ]}
    />
  ),
);
