// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-PageLayout-PageLayout-module__pageLayout--RRlbz {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.src-components-PageLayout-PageLayout-module__pageContent--Q5cdl {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageLayout/PageLayout.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,OAAA;AAAF","sourcesContent":[".pageLayout {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.pageContent {\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageLayout": `src-components-PageLayout-PageLayout-module__pageLayout--RRlbz`,
	"pageContent": `src-components-PageLayout-PageLayout-module__pageContent--Q5cdl`
};
export default ___CSS_LOADER_EXPORT___;
