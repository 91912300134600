import React from "react";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { TableStore } from "src/components/tables/TableStore";
import { TableSelectionCheckbox } from "src/pages/EntityFiltersPage/AsyncTableView";
import styles from "./ATVRowStd.module.less";
import { drawColumn } from "../drawColumn";
import { TableSelectionRadio } from "../../tableSelection/TableSelectionRadio";
import { getColumnWidthStyles } from "../getColumnWidths";

export type PropsATVRowStd<TRow extends {}, TFilters extends {}> = {
  store: TableStore<TRow, TFilters>;
  row: TRow;
  rowKey: keyof TRow;
  useSelection?: boolean;
  onClick?: (row: TRow) => void;
};

export const ATVRowStd = observer(
  <TRow extends {}, TFilters extends {}>({
    store,
    useSelection,
    row,
    rowKey,
    onClick,
  }: PropsATVRowStd<TRow, TFilters>) => {
    const keyValue = row[rowKey];
    const { selectionType } = store.selectionSettings;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className={classNames([
          styles.tableRow,
          [onClick, styles.tableRowClickable],
        ])}
        key={String(keyValue)}
        onClick={() => {
          onClick?.(row);
        }}
      >
        {useSelection && (
          <div className={classNames([styles.rowItem, styles.selectionRow])}>
            {selectionType === "checkbox" ? (
              <TableSelectionCheckbox store={store} row={row} />
            ) : (
              <TableSelectionRadio store={store} row={row} />
            )}
          </div>
        )}

        {store.finalColumns.map((column, colIndex) => (
          <div
            style={getColumnWidthStyles(store.columnWidths, column.key)}
            key={column.key}
            className={classNames([styles.rowItem])}
          >
            {drawColumn(row, column, colIndex)}
          </div>
        ))}
      </div>
    );
  },
);
