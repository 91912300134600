import * as React from "react";
import { Form, Input } from "antd";
import { ModalPlm } from "src/components/ModalPlm";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { t } from "i18next";
import { onError } from "src/common/onError";
import { ImageCarousel } from "src/components/ImageCarousel";
import { EdPhotoComment } from "../ZPhotoComment";

/* eslint react/no-unstable-nested-components: "off" */

interface PropsPhotoCommentModal {
  open: boolean;
  close(): void;
  onSuccess(values: EdPhotoComment): Promise<void>;
}

const f = (key: keyof EdPhotoComment) => key;

/**
 * При создании нового комментария можно выбрать несколько картинок. Тогда будет создано несколько комментов.
 * @param props
 * @returns
 */
export const PhotoCommentModal: React.FC<PropsPhotoCommentModal> = (props) => {
  const { open, close, onSuccess } = props;
  const [form] = Form.useForm<EdPhotoComment>();
  const [wait, setWait] = React.useState(false);
  React.useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);
  const onFinish = (values: EdPhotoComment) => {
    setWait(true);
    onSuccess(values)
      .then(close)
      .finally(() => setWait(false))
      .catch(onError);
  };
  return (
    <ModalPlm
      title="Новый фотокомментарий"
      open={open}
      onCancel={close}
      okText={t("Add")}
      okButtonProps={{ htmlType: "submit", loading: wait }}
      modalRender={(content) => (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {content}
        </Form>
      )}
    >
      <Form.Item name={f("images")} label="Image" rules={[{ required: true }]}>
        <ImageCarousel multiple maxCount={10} />
      </Form.Item>
      <Form.Item name={f("comment")} label="Comment" rules={[trimRequired()]}>
        <Input allowClear />
      </Form.Item>
    </ModalPlm>
  );
};
