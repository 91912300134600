import { getIdNames } from "src/references/getIdNames";

export type AttrTypeMap = Record<number, string>;
let attrTypMapCahce: AttrTypeMap | null = null;

export const loadAttrTypeMap = async () => {
  if (attrTypMapCahce) return attrTypMapCahce;
  const attrTypeList = await getIdNames("attrType");
  const attrTypeMap = attrTypeList.reduce(
    (acc, { id, name }) => ({ ...acc, [id]: name }),
    {} as AttrTypeMap,
  );
  attrTypMapCahce = attrTypeMap;
  return attrTypeMap;
};
