// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-dropdown-menu-item:has(.src-pages-ManagementPage-Obj2Tab-forms-AddExistingAttribute-AddExistingAttribute-module__label--cMvFC) {
  padding: 0 !important;
}
.src-pages-ManagementPage-Obj2Tab-forms-AddExistingAttribute-AddExistingAttribute-module__label--cMvFC {
  padding: 5px 12px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AddExistingAttribute-AddExistingAttribute-module__icon--k4FEJ {
  width: 12px;
  height: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AddExistingAttribute/AddExistingAttribute.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAEA;EACE,iBAAA;AAAF;AAGA;EACE,WAAA;EACA,YAAA;AADF","sourcesContent":[":global(.ant-dropdown-menu-item):has(.label) {\n  padding: 0 !important;\n}\n\n.label {\n  padding: 5px 12px;\n}\n\n.icon {\n  width: 12px;\n  height: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `src-pages-ManagementPage-Obj2Tab-forms-AddExistingAttribute-AddExistingAttribute-module__label--cMvFC`,
	"icon": `src-pages-ManagementPage-Obj2Tab-forms-AddExistingAttribute-AddExistingAttribute-module__icon--k4FEJ`
};
export default ___CSS_LOADER_EXPORT___;
