import * as React from "react";
import { observer } from "mobx-react-lite";
import { ModelEditorStore } from "..";
import styles from "./MEdHeader.module.less";

interface PropsMEdHeader {
  store: ModelEditorStore;
}

export const MEdHeader: React.FC<PropsMEdHeader> = observer(
  (props: PropsMEdHeader) => {
    const { store } = props;
    const { model } = store;
    return (
      <div className={styles.box}>
        <div className={styles.name}>{model?.name}</div>
        <div className={styles.msg}>{store.statusMessage}</div>
      </div>
    );
  },
);
