import { z } from "zod";

export type FilterPhotoComments = {
  query?: string;
};

export const zPhotoComment = z.object({
  id: z.number().or(z.string()),
  sampleId: z.number().or(z.string()),
  image: z.string(),
  comment: z.string(),
});
export type ZPhotoComment = z.infer<typeof zPhotoComment>;

export const zPhotoCommentResponse = z.object({
  content: zPhotoComment.array(),
  totalElements: z.number(),
});
export type ZPhotoCommentResponse = z.infer<typeof zPhotoCommentResponse>;

export type EdPhotoComment = {
  // изображение. Является итератором, позволяет создавать несколько экземпляров объекта Sample comments
  images: string[];
  comment: string;
};
