// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsg-ChatMsg-module__box--CP7Yy {
  padding: 8px 10px;
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsChat/ChatForm/ChatMsgList/ChatMsgGroup/ChatMsg/ChatMsg.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".box {\n  padding: 8px 10px;\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsChat-ChatForm-ChatMsgList-ChatMsgGroup-ChatMsg-ChatMsg-module__box--CP7Yy`
};
export default ___CSS_LOADER_EXPORT___;
