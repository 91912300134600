import * as React from "react";
import { Empty, Tree, Spin, Space, Button } from "antd";
import { observer } from "mobx-react-lite";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { TreeStore, CatNode } from "./TreeStore";
import {
  AddCategoryModal,
  DeleteCategoryModal,
  EditCategoryModal,
} from "./modals";
import styles from "./CategoryTree.module.less";

interface PropsCategoryTree {
  store: TreeStore;
}

type Modals = "add" | "delete" | "edit" | null;

export const CategoryTree: React.FC<PropsCategoryTree> = observer(
  ({ store }: PropsCategoryTree) => {
    const [modalVisible, setModalVisible] = React.useState<Modals>(null);

    const permissionWrite = true; // hasPermission("CATEGORY_WRITE");

    const permissionDraftDelete = true; //  hasPermission("CATEGORY_DELETE");

    const close = () => setModalVisible(null);
    if (store.treeData.length === 0) {
      return (
        <Spin spinning={store.loading}>
          <Empty />
        </Spin>
      );
    }
    return (
      <>
        <div className={styles.box}>
          <Space>
            {permissionWrite && (
              <>
                <Button
                  type="primary"
                  onClick={() => setModalVisible("add")}
                  disabled={!store.canAdd}
                  icon={<PlusOutlined />}
                >
                  Добавить
                </Button>
                <Button
                  onClick={() => setModalVisible("edit")}
                  disabled={!store.canEdit}
                  icon={<EditOutlined />}
                >
                  Редактировать
                </Button>
              </>
            )}
            {permissionDraftDelete && (
              <Button
                onClick={() => setModalVisible("delete")}
                disabled={!store.canDelete}
                icon={<DeleteOutlined />}
              >
                {t("Delete")}
              </Button>
            )}
          </Space>
          <Tree<CatNode>
            className={styles.tree}
            treeData={store.treeData}
            onSelect={(k, { node }) => store.onSelect(node)}
            selectedKeys={store.selectedKeys}
            expandedKeys={store.expandedKeys}
            autoExpandParent={false}
            onExpand={(keys) => store.onExpand(keys as string[])}
            showLine
            showIcon
            draggable
            onDragStart={(info) => store.setCurrent(info.node)}
            onDrop={(info) => {
              store.onDrop(info.dragNode, info.node);
            }}
            disabled={store.loading}
          />
        </div>
        <AddCategoryModal
          open={modalVisible === "add"}
          close={close}
          store={store}
        />
        <EditCategoryModal
          open={modalVisible === "edit"}
          close={close}
          store={store}
        />

        <DeleteCategoryModal
          open={modalVisible === "delete"}
          close={close}
          store={store}
        />
      </>
    );
  },
);
