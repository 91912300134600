import {
  AttrRoleNode,
  CommonRoleNode,
  ObjRoleNode,
  TopGroupRoleNode,
} from "./RoleNode";

interface VisitorRoleNode<Res = void> {
  roleGroup(it: TopGroupRoleNode): Res;
  attr(it: AttrRoleNode): Res;
  roleObject(it: ObjRoleNode): Res;
  default?(): Res;
}

export const visitRoleNode = <Res = void>(
  node: CommonRoleNode,
  visitor: VisitorRoleNode<Res>,
): Res => {
  switch (node.type) {
    case "roleGroup":
      return visitor.roleGroup(node);
    case "attr":
      return visitor.attr(node);
    case "roleObject":
      return visitor.roleObject(node);
    default:
      if (visitor.default) {
        return visitor.default();
      }
      throw Error(`Invalid node type`);
  }
};
