// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectModal-PointSelectModal-module__tableBox--JnHxz {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/PointSelectModal/PointSelectModal.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;AACF","sourcesContent":[".tableBox {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  height: 100%;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-PointSelectModal-PointSelectModal-module__tableBox--JnHxz`
};
export default ___CSS_LOADER_EXPORT___;
