import * as React from "react";
import styles from "./SortableHeader.module.less";

type TSort = "ascending" | "descending" | "sortable";

interface PropsSortableHeader {
  header: React.ReactNode;
  sort: TSort | undefined;
}

export const SortableHeader: React.FC<PropsSortableHeader> = (
  props: PropsSortableHeader,
) => {
  const { header, sort } = props;
  return (
    <div className={styles.headerBox}>
      <div className={styles.text}>{header}</div>
      {sort === "sortable" && <div className={styles.sortable} />}
      {sort === "ascending" && <div className={styles.asc} />}
      {sort === "descending" && <div className={styles.desc} />}
    </div>
  );
};
