import * as React from "react";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { Form, Input, Select } from "antd";
import { VerticalLayout } from "src/components/VerticalLayout";
import { FormFieldsBlock, clsWide } from "src/components/Forms/FormFieldsBlock";
import { ModelessForm } from "../../../components/ModelessForm";
import { MftTab1Store } from "./MftTab1Store";

/**
 * Форма редактирования одной записи.
 * Например, карточка объекта.
 * (Обычно id записи передаётся в урле, но тут не будем усложнять)
 */

interface PropsMftTab1 {
  store: MftTab1Store;
}

const Inner: React.FC<PropsMftTab1> = observer(({ store }) => (
  <ModelessForm store={store.formStore}>
    <VerticalLayout>
      <FormFieldsBlock title="Основное">
        <Form.Item name="firstName" label="Имя" rules={[{ required: true }]}>
          <Input allowClear />
        </Form.Item>
        <Form.Item name="lastName" label="Фамилия" rules={[{ required: true }]}>
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="position"
          label="Должность"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            options={store.positions}
            fieldNames={{ value: "id", label: "name" }}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            { required: true },
            { pattern: /^.+@.+$/, message: "Неправильный e-mail" },
          ]}
        >
          <Input allowClear />
        </Form.Item>
      </FormFieldsBlock>
      <FormFieldsBlock title="Дополнительная информация">
        <Form.Item name="comment" label="Коммент" className={clsWide}>
          <Input.TextArea allowClear />
        </Form.Item>
      </FormFieldsBlock>
    </VerticalLayout>
  </ModelessForm>
));

export const MftTab1: React.FC<PropsMftTab1> = observer(
  ({ store }: PropsMftTab1) => {
    React.useEffect(() => {
      store.init();
    }, []);
    return (
      <LoaderBox
        remoteData={store.data}
        drawReady={() => <Inner store={store} />}
      />
    );
  },
);
