// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ModelConstructorPage-ModelConstructorPage-module__modelsPage--YpNmx {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas: "head head" "list model";
}
.src-pages-ModelConstructorPage-ModelConstructorPage-module__modelsPage--YpNmx h1 {
  grid-area: head;
  padding: 0 10px;
  margin: 0;
}
.src-pages-ModelConstructorPage-ModelConstructorPage-module__modelsPage--YpNmx .src-pages-ModelConstructorPage-ModelConstructorPage-module__list--YdavX {
  grid-area: list;
}
.src-pages-ModelConstructorPage-ModelConstructorPage-module__modelsPage--YpNmx .src-pages-ModelConstructorPage-ModelConstructorPage-module__model--P_Fqk {
  grid-area: model;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ModelConstructorPage/ModelConstructorPage.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,4BAAA;EACA,+BAAA;EACA,6CAAA;AACF;AANA;EASI,eAAA;EACA,eAAA;EACA,SAAA;AAAJ;AAXA;EAaS,eAAA;AACT;AAdA;EAcU,gBAAA;AAGV","sourcesContent":[".modelsPage {\n  height: 100%;\n  display: grid;\n  grid-template-rows: auto 1fr;\n  grid-template-columns: auto 1fr;\n  grid-template-areas:\n    \"head head\"\n    \"list model\";\n  h1 {\n    grid-area: head;\n    padding: 0 10px;\n    margin: 0;\n  }\n  .list {grid-area: list;}\n  .model {grid-area: model;}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelsPage": `src-pages-ModelConstructorPage-ModelConstructorPage-module__modelsPage--YpNmx`,
	"list": `src-pages-ModelConstructorPage-ModelConstructorPage-module__list--YdavX`,
	"model": `src-pages-ModelConstructorPage-ModelConstructorPage-module__model--P_Fqk`
};
export default ___CSS_LOADER_EXPORT___;
