/**
 * @see http://dev.plm.d4r.int/sample-service/swagger-ui/index.html#/sample-controller/getSamples
 */
import { z } from "zod";

const zSampleOption = z.object({
  id: z.number().or(z.string()),
  name: z.string(),
});

export const zSampleSelection = z.object({
  id: z.number().or(z.string()).nullable(),
  name: z.string().nullable(),
  options: zSampleOption.array().nullable(),
});

export const zSampleData = z.object({
  id: z.number().or(z.string()),
  sampleRequestId: z.number().or(z.string()),
  name: z.string().nullable(),
  quantity: z.number().nullable(),
  size: zSampleOption,
  version: zSampleOption,
  requestType: zSampleSelection,
  requestStatus: zSampleSelection,
  reviewDate: z.string().nullable(),
  comment: z.string().nullable(),
});

export type ZSampleData = z.infer<typeof zSampleData>;
