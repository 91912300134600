import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import {
  loadTranslation,
  saveTranslation,
} from "src/common/api/apiTranslation";
import { BsRegSettings } from "./BsRegSettings";
import { loadBsRegSettings, saveBsRegSettings } from "./apiBsRegistration";
import {
  edit2BsRegSettings,
  EdRegSettings,
  bsRegSettings2Edit,
} from "./EdRegSettings";
import { zBsRegSettings } from "./bsRegistration.types";

export const bsRegistration: BusinessServiceDescriptor = {
  key: "external_registration",
  viewer: null,
  renderSettings: (_info, store) => <BsRegSettings store={store} />,
  async loadSettings(info) {
    const regSettings = await loadBsRegSettings(info.id);
    const successLoc = regSettings.successMessage
      ? await loadTranslation(regSettings.successMessage)
      : undefined;
    return bsRegSettings2Edit(regSettings, successLoc?.translations);
  },
  async saveSettings(info, values) {
    const { successLoc, regSettings } = edit2BsRegSettings(
      values as EdRegSettings,
    );
    const tasks: Promise<unknown>[] = [];
    const validValues = zBsRegSettings.parse({
      ...regSettings,
      isAutoCreate: true,
    });
    tasks.push(saveBsRegSettings(info.id, validValues));

    if (successLoc && validValues.successMessage) {
      tasks.push(
        saveTranslation(validValues.successMessage, {
          value: validValues.successMessage,
          translations: successLoc,
        }),
      );
    }

    await Promise.all(tasks);
  },
};
