// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-CategoryRefPage-CategoryRefPage-module__layout--hvvqQ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  flex: 1;
  padding-right: 32px;
}
.src-pages-CategoryRefPage-CategoryRefPage-module__layout--hvvqQ > * {
  min-height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryRefPage/CategoryRefPage.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,OAAA;EACA,mBAAA;AACF;AAAE;EAAO,gBAAA;AAGT","sourcesContent":[".layout {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 16px;\n  flex: 1;\n  padding-right: 32px;\n  & > * {min-height: 100%;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `src-pages-CategoryRefPage-CategoryRefPage-module__layout--hvvqQ`
};
export default ___CSS_LOADER_EXPORT___;
