import React, { useMemo } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { makeAutoObservable } from "mobx";
import { ZEntity } from "src/types/ZEntity";
import { loadTableData } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore";
import {
  loadObjectAttrinbutesAll,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { Tag } from "antd";
import { observer } from "mobx-react-lite";
import { ZAttrEditInfo } from "src/common/attrEdit";
import { makeDictNameById } from "src/types/AttrType";
import { getIdLabels } from "src/references/getIdNames";
import { onError } from "src/common/onError";
import { apiObjUrl } from "src/common/apiUrl";
import { makeDictionary } from "src/common/makeDictionary";
import { AttrLabelBuilderCtx } from "src/common/attributes/composeAttrLabel2";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import { createItemView } from "../../createItemView/createItemView";
import { getViewInfo } from "../../getViewInfo";
import { LabelDef, seedSeparators } from "../../viewFormItems/Separator";
import styles from "./ChildEntitiesCellInfo.module.less";

const createStore = (
  objectId: number,
  parentEntityId: number,
  parentLinkAttributeId: number,
) => {
  const store = makeAutoObservable({
    objAttsMap: {} as Record<number, ZAttribute>,
    setObjAttsMap(dict: Record<number, ZAttribute>) {
      this.objAttsMap = dict;
    },
    entities: [] as ZEntity[],
    setEntities(list: ZEntity[]) {
      this.entities = list;
    },
    typesMap: {} as Record<number, string>,
    setTypesMap(dict: Record<number, string>) {
      this.typesMap = dict;
    },
    async init() {
      try {
        const attrMetaMap = makeObjectAttributesMap(
          await loadObjectAttrinbutesAll(objectId, { translate: true }),
        );
        this.setTypesMap(
          makeDictNameById(await getIdLabels("attrType", "attrType")),
        );
        this.setObjAttsMap(attrMetaMap);
        const respTableData = await loadTableData(
          apiObjUrl("/entities/search"),
          {
            page: 0,
            pageSize: 30, // по договоренности с Максом
            filters: {
              objectId,
              parentEntityId,
              parentLinkAttributeId,
            },
          },
        );
        this.setEntities(respTableData.content);
      } catch (error) {
        onError(error);
      }
    },
  });
  store.init();
  return store;
};
type PropsChildEntitiesCellInfo = {
  attribute: ZAttribute;
  viewInfo: ZAttrViewInfo | undefined;
  editorInfo: ZAttrEditInfo | undefined;
  ctx?: AttrLabelBuilderCtx;
};

export const ChildEntitiesCellInfo: React.FC<PropsChildEntitiesCellInfo> =
  observer(({ attribute, editorInfo, viewInfo, ctx }) => {
    const cmpEditor = editorInfo?.component;
    const cmpView = viewInfo?.component;

    const isRightComponent =
      cmpEditor?.editor === "ChildEntities" &&
      cmpView?.view === "ChildEntitiesCellInfo";

    if (!isRightComponent) return null;

    const store = useMemo(() => {
      const objectId = attribute.referenceId;
      const entityId = ctx?.entityId;
      const linketAttrId = cmpEditor?.attrId;
      const isDataConsisted = !!objectId && !!entityId && !!linketAttrId;
      return isDataConsisted
        ? createStore(objectId, entityId, Number(linketAttrId))
        : null;
    }, []);

    if (!store)
      return <Tag color="red">config error in ChildEntitiesCellInfo</Tag>;

    const labels = useMemo(
      () =>
        store.entities.map(({ attributeValues, id }) => {
          const entAttsDict = makeDictionary(
            attributeValues,
            ({ attributeId }) => attributeId,
          );
          const nestedLabels: LabelDef[] = cmpView.labelAtts
            .map((attributeId) => {
              const nestedAttr = store.objAttsMap[Number(attributeId)];
              const nestedVals = (
                entAttsDict[Number(attributeId)]?.values || []
              ).filter(Boolean);
              const nestedViewInfo = getViewInfo(nestedAttr?.viewType);
              if (
                nestedVals.length === 0 ||
                !nestedViewInfo ||
                !nestedAttr ||
                !nestedViewInfo.appearance
              )
                return null;
              nestedViewInfo.appearance.view = viewInfo?.appearance?.view;
              const newLabel = createItemView(
                nestedAttr,
                store.typesMap,
                nestedVals,
                nestedViewInfo,
              );
              return {
                content: newLabel,
                key: attributeId,
              };
            })
            .filter(Boolean) as LabelDef[];
          return (
            <div className={styles.block} key={id}>
              {seedSeparators(
                nestedLabels,
                viewInfo?.appearance?.view?.separator,
              )}
            </div>
          );
        }),
      [store.entities],
    );

    return labels;
  });

ChildEntitiesCellInfo.defaultProps = {
  ctx: undefined,
};
