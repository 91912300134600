import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { PageLayout } from "src/components/PageLayout";
import { Empty, Tabs } from "antd";
import { dashboardsPageStore as pageStore } from "./DashboardsPageStore";
import styles from "./DashboardsPage.module.less";
import { getDashboardCssHeight } from "../ManagementPage/DashboardTab/DasboardTypes";

export const DashboardsPage: React.FC = observer(() => {
  React.useEffect(() => {
    pageStore.init();
  }, []);
  return (
    <PageLayout pageTitle={t("Dashboards")}>
      <LoaderBox remoteData={pageStore.data} drawReady={() => <Inner />} />
    </PageLayout>
  );
});

const Inner: React.FC = observer(() => {
  const { tabs, curTabKey, items } = pageStore;
  if (tabs.length === 0) {
    return <Empty />;
  }
  return (
    <div className={styles.pageBox}>
      <div className={styles.tabs}>
        <Tabs
          items={tabs}
          activeKey={curTabKey}
          onChange={(key: string) => pageStore.setCurTabKey(key)}
        />
      </div>
      <div className={styles.curTab}>
        {items.map(({ itemKey, url, height }) => (
          <div key={itemKey} className={styles.item}>
            <iframe
              src={url}
              loading="lazy"
              title={`iframe ${itemKey}`}
              style={{ height: getDashboardCssHeight(height) }}
            />
          </div>
        ))}
      </div>
    </div>
  );
});
