import * as React from "react";
import { Tree } from "antd";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { Menu2TabStore } from "./Menu2TabStore";
import { MenuTreeItem } from "./Menu2TreeItem";
import styles from "./Menu2Tab.module.less";

interface PropsMenuTreeView {
  store: Menu2TabStore;
}

export const MenuTreeView: React.FC<PropsMenuTreeView> = observer(
  ({ store }) => (
    <Tree<MenuTreeItem>
      className={classNames([styles.tree, [store.isNew, styles.treeNewItem]])}
      treeData={store.treeData}
      showIcon
      selectedKeys={store.selectedKeys}
      onSelect={(keys) => {
        store.safeSelect(keys[0] ?? null);
      }}
      onExpand={(keys) => store.onExpand(keys)}
      expandedKeys={store.expandedKeys}
      draggable={!store.isNew}
      onDrop={store.onDrop}
    />
  ),
);
