import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { MCObjType } from "../MChartSettingsStore";
import { ZMesChartSettings } from "../../ZMesChartSettings";

interface PropsPointSection {
  store: FormObjectSectionsStore<MCObjType>;
}

export const PointSection: React.FC<PropsPointSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZMesChartSettings, MCObjType>
      store={store}
      objKey={["pointObjectId"]}
      objType="Pt"
      objLabel="Point object"
      title="Point"
      attrs={[
        {
          attrKey: ["pointPointNameAttrId"],
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["pointDescriptionAttrId"],
          attrLabel: "Description",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["mcPointCategoryAttrId"],
          attrLabel: "Category",
          attrType: AttrTypeName.dictSingle,
        },
      ]}
    />
  ),
);
