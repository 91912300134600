// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-MainFrame-Header-Header-module__header--jvAsx {
  display: flex;
  align-items: center;
  height: 100%;
}
.src-components-MainFrame-Header-Header-module__headerItem--YssXC {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 100%;
}
.src-components-MainFrame-Header-Header-module__headerBreadcrumb--tj1xs .ant-breadcrumb-link {
  font-size: 14px;
}
.src-components-MainFrame-Header-Header-module__headerBreadcrumb--tj1xs,
.src-components-MainFrame-Header-Header-module__headerTitle--YaxYm {
  padding-left: 24px;
  padding-right: 24px;
  flex-grow: 1;
  font-weight: 500;
}
.src-components-MainFrame-Header-Header-module__headerTitle--YaxYm {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
}
.src-components-MainFrame-Header-Header-module__headerBreadcrumb--tj1xs {
  font-size: 16px;
  line-height: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainFrame/Header/Header.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AAAF;AAGA;EAEI,eAAA;AAFJ;AAMA;;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAJF;AAOA;EACE,eAAA;EACA,iBAAA;AALF","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n\n.headerItem {\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  height: 100%;\n}\n\n.headerBreadcrumb {\n  :global(.ant-breadcrumb-link) {\n    font-size: 14px;\n  }\n}\n\n.headerBreadcrumb, .headerTitle {\n  padding-left: 24px;\n  padding-right: 24px;\n  flex-grow: 1;\n  font-weight: 500;\n}\n\n.headerTitle {\n  font-size: 20px;\n  line-height: 24px;\n  letter-spacing: 0em;\n}\n\n.headerBreadcrumb {\n  font-size: 16px;\n  line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `src-components-MainFrame-Header-Header-module__header--jvAsx`,
	"headerItem": `src-components-MainFrame-Header-Header-module__headerItem--YssXC`,
	"headerBreadcrumb": `src-components-MainFrame-Header-Header-module__headerBreadcrumb--tj1xs`,
	"headerTitle": `src-components-MainFrame-Header-Header-module__headerTitle--YaxYm`
};
export default ___CSS_LOADER_EXPORT___;
