import * as React from "react";
import { classNames } from "src/common/classNames";
import styles from "./LayoutWithSider.module.less";

/* eslint react/require-default-props: "off" */

interface PropsLayoutWithSider {
  className?: string;
  siderHeader?: React.ReactNode;
  siderContent?: React.ReactNode;
  siderFooter?: React.ReactNode;
  children?: React.ReactNode;
}

export const LayoutWithSider: React.FC<PropsLayoutWithSider> = (props) => {
  const { className, siderHeader, siderContent, siderFooter, children } = props;
  return (
    <div className={classNames([styles.layout, className])}>
      <div className={styles.sider}>
        <div className={styles.siderHeader}>{siderHeader}</div>
        <div className={styles.siderContent}>{siderContent}</div>
        {!!siderFooter && (
          <div className={styles.siderFooter}>{siderFooter}</div>
        )}
      </div>
      <div className={styles.main}>{children}</div>
    </div>
  );
};
