import * as React from "react";
import { ZObjectItem } from "src/types/ZObjectItem";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { makeDictionary } from "src/common/makeDictionary";
import { ZAttribute } from "src/types/ZAttribute";
import { ZRolesGroup } from "../../Obj2Tab/roles/roleTypes";
import {
  AttrRoleNode,
  CommonRoleNode,
  ObjRoleNode,
  TopGroupRoleNode,
  makeTopGroupRoleKey,
} from "./RoleNode";
import { getTypeIcon } from "../../Obj2Tab/utils/typeIcons";
import { ZLightObject } from "../../Obj2Tab/Obj2Nodes";

export const createRolesTree = (
  groups: ZRolesGroup[],
  rolesList: ZObjectItem[],
): CommonRoleNode[] => {
  const rolesMap = makeDictionary(rolesList, ({ roleId }) => roleId ?? 0);
  return groups.map((group) => {
    const children = group.roles
      .map(({ roleId }) => rolesMap[roleId])
      .reduce((acc, role) => (role ? [...acc, role] : acc), [] as ZObjectItem[])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(createRoleNode);
    return createRolesGroupNode(group, children);
  });
};

export const createRolesGroupNode = (
  group: ZRolesGroup,
  children: ObjRoleNode[],
): TopGroupRoleNode => ({
  type: "roleGroup",
  key: makeTopGroupRoleKey(group.groupId),
  title: group.groupName ? (
    `${group.groupName} (${group.groupId})`
  ) : (
    <i>Новая группа</i>
  ),
  group,
  icon: <TeamOutlined />,
  children,
  isLeaf: children.length === 0,
});

export const makeRoleNodeKey = (obj: ZObjectItem): string => `obj-${obj.id}`;

export const createRoleNode = (obj: ZObjectItem): ObjRoleNode => {
  const children = obj.attributes.map((attr) => createAttrRoleNode(attr, obj));
  return {
    type: "roleObject",
    key: makeRoleNodeKey(obj),
    title: obj.name ? `${obj.name} (${obj.id})` : <i>Новая роль</i>,
    obj,
    icon: <UserOutlined />,
    isLeaf: children.length === 0,
    children,
  };
};

export const makeAttrRoleNodeKey = (attr: ZAttribute) => `attr-${attr.id}`;

export const createAttrRoleNode = (
  attr: ZAttribute,
  owner: ZLightObject,
): AttrRoleNode => ({
  type: "attr",
  key: makeAttrRoleNodeKey(attr),
  title: attr.name ? `${attr.name} (${attr.id})` : <i>Новый атрибут</i>,
  attr,
  ownerId: owner.id,
  icon: getTypeIcon(attr.valueType),
  isLeaf: true,
});
