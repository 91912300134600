import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import {
  clearRCAuth,
  createEntityChat,
  deleteEntityChat,
  loadEntityChats,
  loginChat,
  setRCAuth,
  updateEntityChat,
} from "./apiChat";
import { RCSocket } from "./rocketChat/RCSocket";
import { ChatFormStore } from "./ChatForm/ChatFormStore";
import { zChatInfo } from "./bsChat.types";
import { getUniqUserValsFromAttsUL } from "./getUniqUserValsFromAttsUL";

export class ChatListStore {
  constructor() {
    makeAutoObservable(this);
  }

  rcSocket = new RCSocket();

  chats: ChatFormStore[] = [];

  setChats(list: ChatFormStore[]) {
    this.chats = list;
  }

  chatMessages: Record<string, string> = {};

  saveMessage(chatId: string, message: string) {
    this.chatMessages[chatId] = message;
  }

  getMessage(chatId: string) {
    return this.chatMessages[chatId] || "";
  }

  activeChatId: string | null = null;

  setActiveChatId(id: string | null) {
    this.activeChatId = id;
  }

  entityId: number | null = null;

  setEntityId(id: number) {
    this.entityId = id;
  }

  async init(entityId: number, defaultChatName: string, data: EntityCardData) {
    try {
      await this.rcSocket.init([]);
      this.setEntityId(entityId);
      const allowedChats = await loadEntityChats(entityId);
      const isEmptyChats = () =>
        allowedChats.length === 0 && this.chats.length === 0;
      /**
       * если список чатов пуст, то создаем дефолтный чат.
       * дефолтный чат создается автоматически из настроек бизнесс-процесса
       */
      if (isEmptyChats()) {
        const allUsers = await getUniqUserValsFromAttsUL(data);
        await this.createChat(defaultChatName, allUsers);
      }
      /**
       * Если и после попытки создания дефолтного чата список пуст - это говорит
       * об ошибке
       */
      if (isEmptyChats()) throw new Error("init chat error");
      /**
       * и только теперь логнимся в системе рокет чата
       * потому что мы уверены, что наш токен свежий.
       * Это костыль. Тут мы сналала логинимся через рест и получаем токен (authToken рокет чата),
       * а потом только делаем рефреш с полученным токеном через сокет.
       * это приходится делать потому что нет информации о том, как сделать логин через сокет с нашим
       * внутренним токеном, связанным с кейклок, хотя для рест логина такая информация есть.
       */
      const authData = await loginChat();
      setRCAuth(authData);
      this.setChats([
        ...this.chats,
        ...allowedChats.map((chatInfo) => new ChatFormStore(chatInfo)),
      ]);
      this.chats.forEach((chat) => this.joinChat(chat));
      this.setActiveChatId(this.chats[0]?.chatId || "");
    } catch (error) {
      onError(error);
    }
  }

  async createChat(name: string, userIds: string[]) {
    try {
      if (this.entityId) {
        const newChatInfo = zChatInfo.parse(
          (await createEntityChat(this.entityId, name, userIds)).data,
        );
        const newChatFromStore = new ChatFormStore(newChatInfo);
        this.setChats([...this.chats, newChatFromStore]);
        return newChatFromStore;
      }
      return null;
    } catch (error) {
      onError(error);
      return null;
    }
  }

  joinChat(store: ChatFormStore) {
    this.rcSocket.addListener(store);
    this.rcSocket.joinRoom(store.chatId);
  }

  async updateChat(chatId: string, name: string, userIds: string[]) {
    try {
      const updatedChat = await updateEntityChat(chatId, name, userIds);
      const oldChat = this.chats.find((c) => c.chatId === updatedChat.id);
      oldChat?.setChatInfo(updatedChat);
    } catch (error) {
      onError(error);
    }
  }

  async deleteChat(chatId: string) {
    await deleteEntityChat(chatId);
    this.rcSocket.removeListener(chatId);
    this.setChats(this.chats.filter((c) => c.chatId !== chatId));
    this.setActiveChatId(this.chats[0]?.chatId ?? null);
  }

  destroy() {
    clearRCAuth();
    this.rcSocket.close();
  }
}
