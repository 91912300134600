// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Boms2Control-module__mainBox--Uz0FA {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.src-businessServices-services-bsBom2-Bom2Control-Boms2Control-module__tabsBox--QG7_T *[role=tablist] {
  margin: 0;
}
.src-businessServices-services-bsBom2-Bom2Control-Boms2Control-module__content--T2E1z {
  flex: 1;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Boms2Control.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACA;EACqB,SAAA;AACrB;AACA;EACI,OAAA;EACA,cAAA;AACJ","sourcesContent":[".mainBox {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n.tabsBox {\n    *[role=tablist] {margin:0}\n}\n.content {\n    flex: 1;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainBox": `src-businessServices-services-bsBom2-Bom2Control-Boms2Control-module__mainBox--Uz0FA`,
	"tabsBox": `src-businessServices-services-bsBom2-Bom2Control-Boms2Control-module__tabsBox--QG7_T`,
	"content": `src-businessServices-services-bsBom2-Bom2Control-Boms2Control-module__content--T2E1z`
};
export default ___CSS_LOADER_EXPORT___;
