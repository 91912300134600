import { zObjectItem } from "src/types/ZObjectItem";
import { apiObjUrl } from "./apiUrl";
import { rest } from "./rest";
import { restCache } from "./restCache";
import { makeDictionary } from "./makeDictionary";

export const loadRoles = async () => {
  const resp = await rest.get(apiObjUrl("/roles"));
  return zObjectItem.array().parse(resp.data);
};

export const loadRolesDictCached = async () => {
  const resp = await restCache.get(apiObjUrl("/roles"), {});
  return makeDictionary(
    zObjectItem.array().parse(resp.data),
    /**
     * вот это полная лажа, мало того, что роль имеет 2 id,
     * так еще одно из них необязательное
     */
    ({ roleId }) => roleId!,
  );
};
