import { notification, UploadFile } from "antd";
import { t } from "i18next";
import { onError } from "src/common/onError";

export const filterClipboardData = (
  uploadData: (File | null)[],
  maxCount: number,
  fileList: UploadFile[],
  maxSize?: number,
  accept?: string,
): (File | null)[] => {
  try {
    const files = uploadData
      .filter((file) => {
        if (!file || !accept) return !!file;
        const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;
        const result = accept
          .split(",")
          .some(
            (type) =>
              type.trim() === file.type || type.trim() === fileExtension,
          );
        if (!result)
          notification.error({
            message: t("Invalid data format:", { file: file.name }),
          });
        return result;
      })
      .slice(0, maxCount - fileList.length);

    const calculateTotalSize = (sizes: number[]) =>
      sizes.reduce((total, size) => total + (size || 0), 0) / (1024 * 1024);
    const totalSize = calculateTotalSize([
      ...fileList.map(({ size }) => size || 0),
      ...files.map((file) => file?.size || 0),
    ]);
    if (maxSize && totalSize > maxSize) {
      throw new Error(t("Total files size exceeds", { size: maxSize }));
    }
    return files;
  } catch (error) {
    onError(error);
    return [];
  }
};
