// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-EntityList-EntityExportButton-EntityExportButton-module__container--r8VQI {
  display: flex;
  align-items: center;
  padding: 5px 12px;
}
.src-pages-EntityFiltersPage-EntityList-EntityExportButton-EntityExportButton-module__icon--SS6k8 svg {
  font-size: 18px;
}
.ant-dropdown-menu-item:has(.src-pages-EntityFiltersPage-EntityList-EntityExportButton-EntityExportButton-module__container--r8VQI) {
  padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/EntityList/EntityExportButton/EntityExportButton.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AACF;AACA;EAEI,eAAA;AAAJ;AAIA;EACE,qBAAA;AAFF","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  padding: 5px 12px;\n}\n.icon {\n  svg {\n    font-size: 18px;\n  }\n}\n\n:global(.ant-dropdown-menu-item):has(.container) {\n  padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-pages-EntityFiltersPage-EntityList-EntityExportButton-EntityExportButton-module__container--r8VQI`,
	"icon": `src-pages-EntityFiltersPage-EntityList-EntityExportButton-EntityExportButton-module__icon--SS6k8`
};
export default ___CSS_LOADER_EXPORT___;
