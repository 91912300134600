import { z } from "zod";

// соответствует значениям из справочника fieldMetadataType
export const attrTypes = [
  "CHECKBOX",
  "DATE",
  "DATETIME",
  "DICTIONARY",
  "DICTIONARY_MULTI",
  "LONG",
  "NUMBER",
  "STRING",
  "TEXTAREA",
] as const;
export const zAttrType = z.enum(attrTypes);
export type ZAttrType = z.infer<typeof zAttrType>;

export const zCatAttrNew = z.object({
  groupId: z.number().nullable(),
  categoryId: z.number(),
  label: z.string(),
  required: z.boolean(),
  type: zAttrType,
  path: z.string(),
  min: z.number().nullable().optional(),
  max: z.number().nullable().optional(),
  scale: z.number().nullable().optional(),
  data: z.string().nullable().optional(),
  defaultValue: z.string().nullable().optional(),
});
export type ZCatAttrNew = z.infer<typeof zCatAttrNew>;

export const zCatAttr = zCatAttrNew.extend({
  id: z.number(),
});
export type ZCatAttr = z.infer<typeof zCatAttr>;
