import { getCurrentUserInfo } from "src/appStore";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";

export const chatGetEntityOwnerUserIds = (cardData: EntityCardData) =>
  cardData.entity?.userId;

/**
 * при создании чата нужно получать id создателя сущности и id текущего пользователя
 */
export const chatGetRequiredUsersIds = (cardData: EntityCardData): string[] =>
  Array.from(
    new Map(
      [getCurrentUserInfo().id, chatGetEntityOwnerUserIds(cardData) || ""].map(
        (id) => [id, id],
      ),
    ).values(),
  );
