/**
 * Страница Модели
 * Эскизы:
 * https://www.figma.com/file/fuw9yM7RboVtBb6qqSMAhH/OMNI?type=design&node-id=18272-95271&mode=design&t=QLFsxFPxI62OCo4Z-0
 */
import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { PageLayout } from "src/components/PageLayout";
import { modelsListPageStore as pageStore } from "./modelsListPageStore";
import styles from "./ModelsListPage.module.less";

export const ModelsListPage: React.FC = observer(() => {
  React.useEffect(() => {
    pageStore.init();
  }, []);
  return (
    <PageLayout header={t("Models")}>
      <div className={styles.page}>
        <div className={styles.tabsLine} />
        <div className={styles.totalMsg}>
          {t("Total models: {{count}}", {
            count: pageStore.tableStore.rows.length,
          })}
        </div>
        <div className={styles.search} />
        <div className={styles.left} />
        <div className={styles.main} />
      </div>
    </PageLayout>
  );
});
