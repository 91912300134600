import * as React from "react";
import { observer } from "mobx-react-lite";
import { Alert } from "antd";
import { PersonsTabStore } from "./PersonsTabStore";
import { RolePersonNode } from "./PersonNode";
import { PersonDetailsList } from "./PersonDetailsList";
import { PersonForm } from "./PersonForm";

interface PropsPersonRoleContent {
  store: PersonsTabStore;
  node: RolePersonNode;
}

export const PersonRoleContent: React.FC<PropsPersonRoleContent> = observer(
  ({ store }) => {
    const { currentPersonId, personDetailsStore } = store;
    if (currentPersonId === "new") {
      return <PersonForm store={store} />;
    }
    if (typeof currentPersonId === "number") {
      return <PersonForm store={store} />;
    }
    if (personDetailsStore) {
      return (
        <PersonDetailsList
          store={personDetailsStore}
          onNewPerson={() => store.onNewPerson()}
          onPerson={(id) => store.onPerson(id)}
          typesMap={store.attrTypesDict}
        />
      );
    }
    return (
      <Alert
        type="error"
        message="Не получилось загрузить все необходимые данные"
      />
    );
  },
);
