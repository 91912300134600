import React from "react";
import { ZRCAttachemntImg } from "src/businessServices/services/bsChat/rocketChat/rocketChat.types";
import { Button, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { classNames } from "src/common/classNames";
import { createFileAccessUrl } from "../createFileAccessUrl";
import styles from "../attachment.module.less";

type PropsImgAttachment = { data: ZRCAttachemntImg };

export const ImgAttachment: React.FC<PropsImgAttachment> = ({ data }) => {
  const url = createFileAccessUrl(data.image_url);
  return (
    <div className={classNames([styles.attachmentCard, styles.imgAttCard])}>
      <img src={url} alt="" />
      <Typography.Text strong style={{ textAlign: "center" }}>
        {data.title}
      </Typography.Text>
      <Button href={url} download={data.title}>
        <DownloadOutlined />
      </Button>
    </div>
  );
};
