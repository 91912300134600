// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__listBox--oKBUS {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__item--XcLh5 {
  cursor: pointer;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 0 24px;
  border: none;
  background: none;
}
.src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__item--XcLh5 .src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__text--eT6Dw {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__curItem--Vg2cW {
  background: #FFC8A3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/DashboardTab/DashboardsList/DashboardsList.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACA;EACI,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;EAEA,eAAA;EACA,YAAA;EACA,gBAAA;AAAJ;AAVA;EAaQ,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAR;AAGA;EACI,mBAAA;AADJ","sourcesContent":[".listBox {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n.item {\n    cursor: pointer;\n    height: 24px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 4px;\n\n    padding: 0 24px;\n    border: none;\n    background: none;\n\n    .text {\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 160%;\n    }\n}\n.curItem {\n    background: #FFC8A3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listBox": `src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__listBox--oKBUS`,
	"item": `src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__item--XcLh5`,
	"text": `src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__text--eT6Dw`,
	"curItem": `src-pages-ManagementPage-DashboardTab-DashboardsList-DashboardsList-module__curItem--Vg2cW`
};
export default ___CSS_LOADER_EXPORT___;
