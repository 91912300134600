/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const LogoIconSvg: React.FC<Partial<CustomIconComponentProps>> = (props) => (
  <svg
    viewBox="0 0 25 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="25"
    height="16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.158 4.98113C18.7871 4.42112 18.3292 3.91624 17.7957 3.48761C16.4404 2.39866 14.7083 1.89064 12.9788 2.07484C11.2493 2.25903 9.66332 3.12043 8.56836 4.47034C7.69591 5.54593 7.19185 6.86179 7.1132 8.22642H4.8356C3.29164 8.22642 2.04002 9.47664 2.04002 11.0189V11.1698C2.04002 12.712 3.29164 13.9623 4.8356 13.9623H5.22961L6.43855 12.7547H9.21521L5.96629 16H4.83222C2.16315 15.9981 0 13.8363 0 11.1698V11.0189C0 8.35123 2.16497 6.18868 4.8356 6.18868H5.40859C5.72612 5.10418 6.25739 4.08237 6.98326 3.18748C8.41805 1.41862 10.4962 0.289884 12.7625 0.0485191C15.0288 -0.192845 17.2985 0.47284 19.0744 1.89976C20.2512 2.8453 21.1476 4.07403 21.6911 5.45389C20.969 5.14945 20.1754 4.98113 19.3424 4.98113H19.158Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4962 6.18868L14.2473 9.43396H17.0254L18.2343 8.22642H19.3424C20.8863 8.22642 22.138 9.47664 22.138 11.0189V11.1698C22.138 12.712 20.8863 13.9623 19.3424 13.9623H14.2002L12.1602 16H19.3424C22.013 16 24.178 13.8375 24.178 11.1698V11.0189C24.178 8.64314 22.4609 6.668 20.1987 6.26419C19.9951 6.2278 19.7871 6.2042 19.5754 6.19419C19.4982 6.19053 19.4205 6.18868 19.3424 6.18868H17.4962Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.452 16H7.67387L10.9228 12.7547H13.701L10.452 16Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0119 6.18868L9.76303 9.43396H12.5412L15.7901 6.18868H13.0119Z"
    />
  </svg>
);

export const LogoIcon: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={LogoIconSvg} {...props} />;
