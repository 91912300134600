import React from "react";
import styles from "./DrawTab.module.less";

interface PropsDrawTab {
  children?: React.ReactNode;
}

export const DrawTab: React.FC<PropsDrawTab> = ({ children }) => (
  <div className={styles.box}>{children}</div>
);

DrawTab.defaultProps = {
  children: undefined,
};
