import React from "react";
import { Input, Form, InputRef, Flex } from "antd";
import { onError } from "src/common/onError";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { safeFocus } from "src/common/safeFocus";
import { Obj2TabStore } from "../Obj2TabStore";
import { importObj } from "./importObj";

interface PropsObjImportModal {
  open: boolean;
  close(): void;
  store: Obj2TabStore;
}

interface ImportParams {
  xml: string;
  prefix: string;
}

const { TextArea } = Input;

export const ObjImportModal: React.FC<PropsObjImportModal> = (props) => {
  const { open, close, store } = props;
  const [form] = Form.useForm<ImportParams>();
  const inpRef = React.useRef<InputRef>(null);
  const [saving, setSaving] = React.useState(false);
  React.useEffect(() => {
    if (open) form.resetFields();
    safeFocus(inpRef.current);
  }, [open]);
  const onSubmit = async ({ xml, prefix }: ImportParams) => {
    try {
      setSaving(true);
      const newObj = await importObj(xml, prefix);
      store.onNewObject(newObj);
      close();
    } catch (error) {
      onError(error);
    } finally {
      setSaving(false);
    }
  };
  return (
    <ModalVertFixed
      title="Импорт объекта"
      open={open}
      onCancel={close}
      okText="Импортировать"
      okButtonProps={{ htmlType: "submit", loading: saving }}
      // eslint-disable-next-line react/no-unstable-nested-components
      modalRender={(content) => (
        <Form<ImportParams> layout="vertical" onFinish={onSubmit} form={form}>
          {content}
        </Form>
      )}
    >
      <Flex vertical gap={12}>
        <Form.Item name="xml" label="Содержимое xml" rules={[trimRequired()]}>
          <TextArea
            ref={inpRef}
            placeholder="Введите содержимое xml-файла с объектом"
          />
        </Form.Item>
        <Form.Item name="prefix" label="Префикс">
          <Input placeholder="Введите префикс" allowClear />
        </Form.Item>
      </Flex>
    </ModalVertFixed>
  );
};
