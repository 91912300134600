import { z } from "zod";

// http://dev.plm.d4r.int:8090/i18n/swagger-ui/index.html#/i-18n-controller

export const zTranslationMap = z.record(
  z.string(),
  z.string().nullable().optional(),
);
export type ZTranslationMap = z.infer<typeof zTranslationMap>;

export const zTranslation = z.object({
  value: z.string(), // ключевая фраза для перевода
  translations: zTranslationMap,
});
export type ZTranslation = z.infer<typeof zTranslation>;
