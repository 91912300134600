import { TableStore } from "./TableStore";

export const selectOnRowClick = <TRow, TFilters extends object>(
  row: TRow,
  store: TableStore<TRow, TFilters>,
) => {
  const { rowKey, selectionSettings, selected } = store;
  const isRowSelected = selected.some(
    (item) => item[store.rowKey] === row[rowKey],
  );
  if (selectionSettings.selectionType === "checkbox") {
    store.safeSelect(
      isRowSelected
        ? selected.filter((r) => r[rowKey] !== row[rowKey])
        : [...selected, row],
    );
  } else {
    store.safeSelect(isRowSelected ? [] : [row]);
  }
  selectionSettings.onSelect?.(store.selected, [row], !isRowSelected);
};
