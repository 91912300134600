import { ItemProps, blockItem } from "src/components/FormWithBlocks";
import { Checkbox, CheckboxProps } from "antd";

export const stdCheckbox = (
  name: string,
  label: string,
  itemProps?: ItemProps,
  compProps?: CheckboxProps,
) =>
  blockItem(
    name,
    "",
    Checkbox,
    { children: label, ...compProps },
    { valuePropName: "checked", ...itemProps },
  );
