import { ReactNode } from "react";
import { ZMCRow } from "../../ZMeasurementChart";

export type MCColumn = {
  key: string;
  title?: ReactNode;
  subTitle?: ReactNode;
  width: string; // ex: "1fr" or "200px"
  rowSpan?: number;
  colSpan?: number;
  align?: "center";
  isBaseSize?: boolean;
  noPadTitle?: boolean;
  szName?: string;
  verName?: string;
  cls?: string;
  cell: (row: ZMCRow) => ReactNode;
};

export const mkKey = (sz: string, ver: string) => `sz${sz}:${ver}`;
