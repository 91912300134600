import * as React from "react";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { mChartEntityStore as store } from "./MChartEntityStore";
import styles from "./MChartEntity.module.less";
import { NewMCEntity } from "./NewMCEntity";
import { EditMCEntity } from "./EditMCEntity";

interface PropsMChartEntity {
  serviceObjectId: number;
  modelId: number;
}

export const MChartEntity: React.FC<PropsMChartEntity> = observer(
  ({ serviceObjectId, modelId }) => {
    React.useEffect(() => {
      store.init(serviceObjectId, modelId);
    }, [modelId]);
    return (
      <div className={styles.serviceBox}>
        <LoaderBox remoteData={store.data} drawReady={() => <Inner />} />
      </div>
    );
  },
);

const Inner: React.FC = observer(() => {
  const { data } = store;
  if (data.status !== "ready") {
    return null;
  }
  const { mc } = data.result;
  if (!mc.measurementChartEntityDto) {
    return <NewMCEntity store={store} />;
  }
  return <EditMCEntity store={store} />;
});
