import * as React from "react";
import { Switch, SwitchProps } from "antd";

type PropsEntitySwitch = Omit<SwitchProps, "onChange" | "value"> & {
  value?: string[] | null;
  onChange?(value?: string[] | null): void;
};

export const EntitySwitch: React.FC<PropsEntitySwitch> = (props) => {
  const { value, onChange, ...restProps } = props;
  return (
    <Switch
      {...restProps}
      value={value?.[0] === "true"}
      onChange={(checked) => onChange?.([JSON.stringify(checked)])}
    />
  );
};

EntitySwitch.defaultProps = {
  value: undefined,
  onChange: undefined,
};
