import { z } from "zod";

export const zImgList = z.object({
  view: z.literal("ImgList"),
});

export type ZImgList = z.infer<typeof zImgList>;

export const makeImgListProps = (values: string[]) => ({
  values,
});
