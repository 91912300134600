import dayjs, { Dayjs } from "dayjs";
import { Person, Subtask, Task } from "./Timeline.types";
import { maxDate, minDate } from "./dateUtils";

type SrcSubtask = Omit<Subtask, "finish" | "listIndex" | "start"> & {
  start: string;
  finish: string;
};

type SrcTask = Omit<
  Task,
  "expanded" | "finish" | "listIndex" | "start" | "subtasks"
> & {
  subtasks: SrcSubtask[];
};

const persDima: Person = { id: 1001, name: "Дима" };
const persEugen: Person = { id: 1002, name: "Женя" };
const persMasha: Person = { id: 1003, name: "Маша" };
const persDasha: Person = { id: 1004, name: "Даша" };

const src: SrcTask[] = [
  {
    id: 11,
    name: "Исследование рынка",
    colorBack: "#f4c889",
    colorComplete: "#c69d4e",
    colorText: "#b9b296",
    subtasks: [
      {
        id: 111,
        name: "Обзор дизайна",
        status: "inProgress",
        executor: [persDima],
        start: "2023-09-13",
        finish: "2023-09-25",
        colorBack: "#fcabaa",
        colorComplete: "#d4635d",
        progress: 30,
      },
      {
        id: 112,
        name: "Создание технических требований",
        status: "open",
        executor: [persMasha, persDasha, persDima],
        start: "2023-09-20",
        finish: "2023-09-31",
        colorBack: "#ffca84",
        colorComplete: "#ffca84",
      },
      {
        id: 113,
        name: "Ценовое предложение",
        status: "closed",
        executor: [],
        start: "2023-09-28",
        finish: "2023-10-01",
        colorBack: "#dbf2ec",
        colorComplete: "#dbf2ec",
      },
    ],
  },
  {
    id: 12,
    name: "1-й образец запроса",
    colorBack: "#5eada8",
    colorComplete: "#4b746e",
    colorText: "#738684",
    subtasks: [
      {
        id: 201,
        name: "Производство образцов",
        status: "inProgress",
        executor: [persEugen],
        start: "2023-09-17",
        finish: "2023-09-21",
        colorBack: "#8bc24b",
        colorComplete: "#6f8e42",
        progress: 50,
      },
      {
        id: 202,
        name: "1-й образез + Лабораторный анализ",
        status: "inProgress",
        executor: [],
        start: "2023-09-21",
        finish: "2023-09-24",
        colorBack: "#4db5aa",
        colorComplete: "#329388",
        progress: 50,
      },
      {
        id: 203,
        name: "1-й образец + доставка",
        status: "completed",
        executor: [persMasha, persDasha],
        start: "2023-09-26",
        finish: "2023-09-31",
        colorBack: "#4a7c7b",
        colorComplete: "#4a7c7b",
        progress: 100,
      },
      {
        id: 204,
        name: "Обзор линии / Обсуждение",
        status: "inProgress",
        executor: [],
        start: "2023-10-01",
        finish: "2023-10-04",
        colorBack: "#9472d0",
        colorComplete: "#6546ae",
        progress: 80,
      },
    ],
  },
  {
    id: 13,
    name: "Документация PPS",
    colorBack: "#bec35c",
    colorComplete: "#6b7246",
    colorText: "#919388",
    subtasks: [
      {
        id: 301,
        name: "Размещение заказа BS-GS",
        status: "closed",
        executor: [persEugen],
        start: "2023-09-13",
        finish: "2023-09-17",
        colorBack: "#ddecd9",
        colorComplete: "#ddecd9",
      },
      {
        id: 302,
        name: "Предоплата AKK-GS",
        status: "inProgress",
        executor: [],
        start: "2023-09-19",
        finish: "2023-09-25",
        colorBack: "#facf69",
        colorComplete: "#d0a139",
        progress: 20,
      },
      {
        id: 303,
        name: "Размещение заказа окончательно",
        status: "open",
        executor: [persEugen],
        start: "2023-09-27",
        finish: "2023-10-04",
        colorBack: "#4bb5ab",
        colorComplete: "#4bb5ab",
      },
    ],
  },
];

export const generateTasks = (): Task[] =>
  src.map(({ subtasks: srcSubtasks, ...srcTask }, taskIndex) => {
    const subtasks: Subtask[] = srcSubtasks.map(
      ({ start, finish, ...srcSubtask }, subIndex) => ({
        ...srcSubtask,
        listIndex: `${taskIndex + 1}.${subIndex + 1}`,
        start: dayjs(start),
        finish: dayjs(finish),
      }),
    );

    return {
      ...srcTask,
      listIndex: `${taskIndex + 1}`,
      expanded: true,
      subtasks,
      start: subtasks.reduce(
        (min: Dayjs | undefined, { start }) => minDate(start, min),
        undefined,
      ),
      finish: subtasks.reduce(
        (max: Dayjs | undefined, { finish }) => maxDate(finish, max),
        undefined,
      ),
    };
  });
