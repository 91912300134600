// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-EntityFiltersTable-module__lWSider--puZTs {
  display: flex;
  overflow: hidden;
  flex: 1;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__siderContent--ULHr9 {
  overflow-y: auto;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__siderContent--ULHr9::-webkit-scrollbar {
  width: 8px;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__siderContent--ULHr9::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__siderContent--ULHr9::-webkit-scrollbar-thumb {
  background: #e2e2e2;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__siderContent--ULHr9 {
  flex-grow: 1;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__lWSiderMainContent--LhA0c {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__sider--zolQR {
  display: flex;
  flex-direction: column;
  min-width: 336px;
  height: auto;
  border-right: 1px solid #e1e3e6;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__siderFooter--QqxCz,
.src-pages-EntityFiltersPage-EntityFiltersTable-module__siderHeader--JCYqk {
  padding: 24px;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__headerButtons--yE12j {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__closeButton--FibBx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__pageInner--yoeOT {
  position: relative;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__siderBox--FOIUD {
  padding: 0 24px;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__saveSearchBtnBox--JTqBX {
  display: flex;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__saveSearchBtnBox--JTqBX button {
  width: 100%;
}
.src-pages-EntityFiltersPage-EntityFiltersTable-module__loaderBox--nTr2h {
  padding: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/EntityFiltersTable.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBAAA;EACA,OAAA;AADF;AAIA;EACE,gBAAA;AAFF;AAGE;EACE,UAAA;AADJ;AAGE;EACE,mBAAA;AADJ;AAGE;EACE,mBAAA;AADJ;AAIA;EACE,YAAA;AAFF;AAIA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AAFF;AAKA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,+BAAA;AAHF;AAMA;;EAEE,aAAA;AAJF;AAQA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AANF;AASA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AAPF;AASA;EACE,kBAAA;AAPF;AAUA;EACE,eAAA;AARF;AAUA;EACE,aAAA;AARF;AAOA;EAGI,WAAA;AAPJ;AAUA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AARF","sourcesContent":["// layout with sider\n\n.lWSider {\n  display: flex;\n  overflow: hidden;\n  flex: 1;\n}\n\n.siderContent {\n  overflow-y: auto;\n  &::-webkit-scrollbar {\n    width: 8px;\n  }\n  &::-webkit-scrollbar-track {\n    background: #f1f1f1;\n  }\n  &::-webkit-scrollbar-thumb {\n    background: #e2e2e2;\n  }\n}\n.siderContent {\n  flex-grow: 1;\n}\n.lWSiderMainContent {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.sider {\n  display: flex;\n  flex-direction: column;\n  min-width: 336px;\n  height: auto;\n  border-right: 1px solid #e1e3e6;\n}\n\n.siderFooter,\n.siderHeader {\n  padding: 24px;\n}\n\n// specific\n.headerButtons {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 15px;\n}\n\n.closeButton {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n.pageInner {\n  position: relative;\n}\n\n.siderBox {\n  padding: 0 24px;\n}\n.saveSearchBtnBox {\n  display: flex;\n  button {\n    width: 100%;\n  }\n}\n.loaderBox {\n  padding: 24px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lWSider": `src-pages-EntityFiltersPage-EntityFiltersTable-module__lWSider--puZTs`,
	"siderContent": `src-pages-EntityFiltersPage-EntityFiltersTable-module__siderContent--ULHr9`,
	"lWSiderMainContent": `src-pages-EntityFiltersPage-EntityFiltersTable-module__lWSiderMainContent--LhA0c`,
	"sider": `src-pages-EntityFiltersPage-EntityFiltersTable-module__sider--zolQR`,
	"siderFooter": `src-pages-EntityFiltersPage-EntityFiltersTable-module__siderFooter--QqxCz`,
	"siderHeader": `src-pages-EntityFiltersPage-EntityFiltersTable-module__siderHeader--JCYqk`,
	"headerButtons": `src-pages-EntityFiltersPage-EntityFiltersTable-module__headerButtons--yE12j`,
	"closeButton": `src-pages-EntityFiltersPage-EntityFiltersTable-module__closeButton--FibBx`,
	"pageInner": `src-pages-EntityFiltersPage-EntityFiltersTable-module__pageInner--yoeOT`,
	"siderBox": `src-pages-EntityFiltersPage-EntityFiltersTable-module__siderBox--FOIUD`,
	"saveSearchBtnBox": `src-pages-EntityFiltersPage-EntityFiltersTable-module__saveSearchBtnBox--JTqBX`,
	"loaderBox": `src-pages-EntityFiltersPage-EntityFiltersTable-module__loaderBox--nTr2h`
};
export default ___CSS_LOADER_EXPORT___;
