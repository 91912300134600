import { makeAutoObservable } from "mobx";
import { TableStore } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import { SelectProps } from "antd";
import {
  ZAuditEntityState,
  AuditEntityStateFilters,
  ZAuditUserFilter,
} from "./EntityStatesHistory.types";
import {
  loadEntityStatesHistory,
  loadStatesUserFilter,
} from "./apiEntityStatesHistory";
import { historyLoader } from "../apiEntityHistory";

export class EntityStatesHistoryStore {
  constructor(
    public readonly entityId: number,
    public readonly stateNames: Record<number, string>,
  ) {
    makeAutoObservable(this);
  }

  async init(entityId: number) {
    try {
      const users = await loadStatesUserFilter(entityId);
      this.setUserFilterList(users);
    } catch (error) {
      onError(error);
    }
  }

  userFilterList: ZAuditUserFilter[] | null = null;

  setUserFilterList(users: ZAuditUserFilter[]) {
    this.userFilterList = users;
  }

  get userFilterOptions(): SelectProps["options"] | null {
    const { userFilterList } = this;
    return userFilterList
      ? userFilterList
          .filter(({ fio, userId }) => fio && userId)
          .map(({ fio, userId }) => ({
            label: fio,
            value: userId,
          }))
      : null;
  }

  get stateFilterOptions(): SelectProps["options"] {
    const { stateNames } = this;
    return Object.keys(stateNames).map((stateKey) => {
      const state = Number(stateKey);
      return {
        label: stateNames[state],
        value: state,
      };
    });
  }

  tableStore = new TableStore<ZAuditEntityState, AuditEntityStateFilters>({
    rowKey: "timestamp",
    fnLoad: historyLoader(this.entityId, loadEntityStatesHistory),
  });
}
