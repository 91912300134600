// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AsyncTableView-templates-ATVContainerStd-ATVContainerStd-module__table--yx57G {
  display: flex;
  flex-direction: column;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AsyncTableView/templates/ATVContainerStd/ATVContainerStd.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF","sourcesContent":[".table {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `src-pages-EntityFiltersPage-AsyncTableView-templates-ATVContainerStd-ATVContainerStd-module__table--yx57G`
};
export default ___CSS_LOADER_EXPORT___;
