import { z } from "zod";
import { zIdName } from "src/types/ZIdName";

// Группы/Роли для левого края таблицы - rows

export const zRole = z.object({
  roleId: z.number(),
  roleName: z.string(),
});
export type ZRole = z.infer<typeof zRole>;

export const zRolesGroup = z.object({
  groupId: z.number(),
  groupName: z.string(),
  roles: zRole.array(),
});
export type ZRolesGroup = z.infer<typeof zRolesGroup>;

// Получение всех возможных действий. Для верхнего заголовка - columns
export enum ActionTypeName {
  object = "OBJECT",
  entity = "ENTITY",
}

export const zTypeActions = z.object({
  type: z.string(), // ActionTypeName
  actions: zIdName.array(),
});
export type ZTypeActions = z.infer<typeof zTypeActions>;

// Непосредственно редактируемые значения

export const zPermissionRoleItem = z.object({
  roleId: z.number(),
  actionIds: z.number().array(),
});
export const zPermissionGroupItem = z.object({
  groupId: z.number(),
  roles: zPermissionRoleItem.array(),
});
export type ZPermissionGroupItem = z.infer<typeof zPermissionGroupItem>;
export type ZPermissions = ZPermissionGroupItem[];

export enum PermissionsCopyType {
  attribute = "attribute",
  object = "object",
}
