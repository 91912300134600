import { ZAttribute } from "src/types/ZAttribute";
import { getEntityCachedRequest } from "src/common/entity/getEntityCachedRequest";
import { getViewInfo } from "src/common/attrView";
import { zObectRef } from "src/common/attrView/componentsDefs";
import { AttrLabelTreeData, defAttrLabelTreeData } from "../AttrLabelTreeData";
import { AttrLabelLoader, AttrLabelLoaderParams } from "../types";
import { loadAttrLabelData } from "../loadAttrLabelData";
import { loadEntityAttsForShow } from "./utils";

export const labelLoaderObjectRef: AttrLabelLoader = async (
  values: string[],
  attrInfo: ZAttribute,
  params?: AttrLabelLoaderParams,
) => {
  const recursionRegister = params?.recursionRegister;
  if (!attrInfo || !values) return null;
  if (recursionRegister?.has(attrInfo.id))
    return defAttrLabelTreeData([], attrInfo, undefined, true);
  const entities = await Promise.all(
    values.map((id) => getEntityCachedRequest(Number(id))),
  );
  const viewInfo = getViewInfo(attrInfo.viewType);
  const viCmp = zObectRef.parse(viewInfo?.component);
  const attsIdsForShow = viCmp.labelAtts || [];
  const attsResp = await Promise.all(
    entities.map((e) => loadEntityAttsForShow(e, attsIdsForShow)),
  );
  const attsFlat = attsResp.flat();
  const deepAtts = await Promise.all(
    attsFlat.map((e) =>
      loadAttrLabelData(e.labels, e.attr, {
        recursionRegister: recursionRegister || new Set([attrInfo.id]),
      }),
    ),
  );
  return defAttrLabelTreeData(
    values,
    attrInfo,
    deepAtts.filter(Boolean) as AttrLabelTreeData[],
  );
};
