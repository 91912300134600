import * as React from "react";
import { classNames } from "src/common/classNames";
import { observer } from "mobx-react-lite";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { TimelineDemoStore } from "./TimelineDemoStore";
import { StatusId, Subtask, Task, statusName } from "./Timeline.types";
import { DayInfo, stdDateView } from "./dateUtils";
import styles from "./TimelineDemo.module.less";
import { DrawFace } from "./faces/DrawFace";

interface PropsTimelineDemo {
  store: TimelineDemoStore;
}

interface PropsCell {
  children?: React.ReactNode;
  cls?: string[];
  col: {
    key: string;
    rightBorder?: boolean;
    leftBorder?: boolean;
  };
  status?: StatusId;
  day?: DayInfo;
  style?: React.CSSProperties;
}
const Cell: React.FC<PropsCell> = ({
  children,
  cls = [],
  col,
  day,
  style,
  status,
}) => (
  <div
    className={classNames([
      styles.cell,
      status ? styles[`status-${status}`] : undefined,
      [!!col.rightBorder || !!day, styles.rightBorder],
      [!!col.leftBorder, styles.leftBorder],
      [!!day, styles.dateCell],
      [!!day?.holiday, styles.holiday],
      ...cls,
    ])}
    style={style}
  >
    {children}
  </div>
);
Cell.defaultProps = {
  children: null,
  cls: undefined,
  day: undefined,
  style: undefined,
  status: undefined,
};

interface LeftCol {
  key: string;
  title: string;
  rightBorder?: boolean;
  leftBorder?: boolean;
  renderTask(task: Task, store: TimelineDemoStore): React.ReactNode;
  renderSubtask(subTask: Subtask, store: TimelineDemoStore): React.ReactNode;
}

const leftColumns: LeftCol[] = [
  {
    key: "colNumber",
    title: "",
    leftBorder: true,
    renderTask({ listIndex }) {
      return <Cell col={this}>{listIndex}</Cell>;
    },
    renderSubtask({ listIndex }) {
      return <Cell col={this}>{listIndex}</Cell>;
    },
  },
  {
    key: "colExpand",
    title: "",
    renderTask(task, store) {
      return (
        <Cell col={this}>
          <button
            type="button"
            className={styles.expandButton}
            onClick={() => store.onExpand(task)}
          >
            {task.expanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
          </button>
        </Cell>
      );
    },
    renderSubtask() {
      return <Cell col={this} />;
    },
  },
  {
    key: "colName",
    title: "Задача",
    rightBorder: true,
    renderTask({ name, start, finish }) {
      return (
        <Cell col={this} cls={[styles.taskName]}>
          <span className={styles.limited} title={name}>
            {name} {start?.format("DD.MM.YYYY")}-{finish?.format("DD.MM.YYYY")}
          </span>
        </Cell>
      );
    },
    renderSubtask({ name, status }) {
      return (
        <Cell col={this} status={status}>
          <span className={styles.limited} title={name}>
            {name}
          </span>
        </Cell>
      );
    },
  },
  {
    key: "colExecutor",
    title: "Исполнитель",
    rightBorder: true,
    renderTask: () => null,
    renderSubtask({ executor, status }) {
      return (
        <Cell col={this} status={status}>
          <span className={styles.executors}>
            {executor.slice(0, 2).map((p) => (
              <DrawFace pers={p} />
            ))}
            <span className={styles.limited}>
              {executor.length === 0 && "не назначен"}
              {executor.length === 1 && executor[0]?.name}
              {executor.length > 2 && `+${executor.length - 2}`}
            </span>
          </span>
        </Cell>
      );
    },
  },
  {
    key: "colStatus",
    title: "Статус",
    rightBorder: true,
    renderTask: () => null,
    renderSubtask({ status }) {
      return (
        <Cell col={this} status={status}>
          <span className={styles.statusView}>
            {statusName[status] ?? status}
          </span>
        </Cell>
      );
    },
  },
];

export const TimelineDemo: React.FC<PropsTimelineDemo> = observer(
  ({ store }) => {
    React.useEffect(() => {
      store.init();
    }, []);
    const { viewBox } = store;
    const rightTopBox = React.createRef<HTMLDivElement>();
    const leftBottomBox = React.createRef<HTMLDivElement>();
    const { days, months, tasks, dayWidth } = store;
    const rightPartStyle: React.CSSProperties = {
      gridTemplateColumns: `repeat(${days.length},${dayWidth}px)`,
      width: viewBox.width + 200, // ширину необходимо задать жестко, т.к. если верх и низ будут разной ширины, то при гориз. скролле будет рассинхрон
    };
    return (
      <div className={styles.mainBox}>
        <div
          className={classNames([
            styles.leftTopBox,
            [store.leftCollapsed, styles.leftCollapsed],
          ])}
        >
          <div className={styles.leftPart}>
            {leftColumns.map((col) => (
              <Cell key={col.key} col={col} cls={[styles.leftHeader]}>
                {col.title}
              </Cell>
            ))}
          </div>
        </div>
        <div className={styles.rightTopBox} ref={rightTopBox}>
          <div className={styles.rightPart} style={rightPartStyle}>
            {months.map(({ name, year, key, daysCount }) => (
              <Cell
                key={key}
                col={{ key, rightBorder: true }}
                cls={[styles.dateCell]}
                style={{ gridColumn: `span ${daysCount}` }}
              >
                <span className={styles.limited}>
                  {name}, {year}
                </span>
              </Cell>
            ))}
            {days.map((dayInfo) => (
              <Cell key={dayInfo.iso} col={{ key: dayInfo.iso }} day={dayInfo}>
                {dayInfo.date.format("D")}
              </Cell>
            ))}
          </div>
        </div>
        <div
          className={classNames([
            styles.leftBottomBox,
            [store.leftCollapsed, styles.leftCollapsed],
          ])}
          ref={leftBottomBox}
        >
          <div className={styles.leftPart}>
            {/* Пустая строка */}
            {leftColumns.map((col) => (
              <Cell key={col.key} col={col} />
            ))}
            {/* Основная часть */}
            {tasks.map((task) => (
              <React.Fragment key={task.id}>
                {leftColumns.map((col) => (
                  <React.Fragment key={col.key}>
                    {col.renderTask(task, store)}
                  </React.Fragment>
                ))}
                {task.expanded &&
                  task.subtasks.map((subTask) => (
                    <React.Fragment key={subTask.listIndex}>
                      {leftColumns.map((col) => (
                        <React.Fragment key={col.key}>
                          {col.renderSubtask(subTask, store)}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div
          className={styles.rightBottomBox}
          onScroll={(e) => {
            if (rightTopBox.current) {
              rightTopBox.current.scrollLeft = e.currentTarget.scrollLeft;
            }
            if (leftBottomBox.current) {
              leftBottomBox.current.scrollTop = e.currentTarget.scrollTop;
            }
          }}
        >
          <button
            type="button"
            className={styles.leftCollapseSwitch}
            onClick={() => store.setLeftCollapsed(!store.leftCollapsed)}
          >
            {store.leftCollapsed ? (
              <CaretRightOutlined />
            ) : (
              <CaretLeftOutlined />
            )}
          </button>
          <div className={styles.rightPart} style={rightPartStyle}>
            {/* Пустая строка */}
            {days.map((dayInfo) => (
              <Cell
                key={`0-${dayInfo.iso}`}
                col={{ key: dayInfo.iso }}
                day={dayInfo}
              />
            ))}
            {tasks.map((task) => (
              <React.Fragment key={task.id}>
                {days.map((di) => (
                  <Cell key={di.iso} col={{ key: di.iso }} day={di} />
                ))}
                <TaskBox task={task} store={store} />
                {task.expanded &&
                  task.subtasks.map((subtask, i) => {
                    const { listIndex } = subtask;
                    return (
                      <React.Fragment key={listIndex}>
                        {days.map((di) => (
                          <Cell key={di.iso} col={{ key: di.iso }} day={di} />
                        ))}
                        <SubtaskBox subtask={subtask} store={store} />
                        {i > 0 && (
                          <SubtasksBond
                            from={task.subtasks[i - 1]!}
                            to={subtask}
                            store={store}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  },
);

interface PropsSubtaskBox {
  subtask: Subtask;
  store: TimelineDemoStore;
}

const SubtaskBox: React.FC<PropsSubtaskBox> = ({ subtask, store }) => {
  const box = store.getSubtaskBox(subtask);
  if (!box) return null;
  const boxStyle: React.CSSProperties = {
    left: box.x,
    top: box.y,
    height: box.height,
  };
  const brickStyle: React.CSSProperties = {
    width: box.width,
    height: "100%",
    background: subtask.colorBack,
    borderColor: subtask.colorComplete,
  };
  const { progress } = subtask;
  let completeStyle: React.CSSProperties | undefined;
  if (progress) {
    if (progress >= 100) {
      brickStyle.background = subtask.colorComplete;
    } else {
      completeStyle = {
        display: "block",
        height: "100%",
        width: (box.width * progress) / 100,
        background: subtask.colorComplete,
      };
    }
  }
  return (
    <div className={styles.subtaskBox} style={boxStyle}>
      <div className={styles.subtaskBrick} style={brickStyle}>
        {completeStyle && <div style={completeStyle} />}
      </div>
      <span className={styles.subtaskInfo}>
        {subtask.name}, {stdDateView(subtask.start)} -{" "}
        {stdDateView(subtask.finish)}
      </span>
    </div>
  );
};

interface PropsSubtasksBond {
  from: Subtask;
  to: Subtask;
  store: TimelineDemoStore;
}

const SubtasksBond: React.FC<PropsSubtasksBond> = ({ from, to, store }) => {
  const path = store.makePathWithSubtasks(from, to);
  if (path.length === 0) return null;
  const { viewBox } = store;
  const d = path
    .map(({ x, y }, i) => `${i === 0 ? "M" : "L"}${x} ${y}`)
    .join("");
  const p1 = path[path.length - 1]!;
  const dx = 10;
  const dy = 6;
  const dArr = `M${p1.x} ${p1.y} L${p1.x - dx} ${p1.y - dy} L${p1.x - dx} ${p1.y + dy} z`;
  return (
    <svg
      className={styles.bond}
      viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
      xmlns="http://www.w3.org/2000/svg"
      width={`${viewBox.width}`}
      height={`${viewBox.height}`}
    >
      <path className={styles.bondLine} d={d} />
      <path className={styles.bondArrow} d={dArr} />
    </svg>
  );
};

interface PropsTaskBox {
  task: Task;
  store: TimelineDemoStore;
}
const TaskBox: React.FC<PropsTaskBox> = ({ task, store }) => {
  const size = store.getTaskSize(task);
  if (!size) return null;
  const styleBox: React.CSSProperties = {
    top: size.y,
    left: size.x,
  };
  const styleText: React.CSSProperties = {
    color: task.colorText,
  };
  const styleBrick: React.CSSProperties = {
    width: size.total,
    background: task.colorBack,
  };
  return (
    <div className={styles.taskBox} style={styleBox}>
      <div className={styles.taskBoxText} style={styleText}>
        {task.name}, {stdDateView(task.start)} - {stdDateView(task.finish)}
      </div>
      <div className={styles.taskBrick} style={styleBrick} />
    </div>
  );
};
