import { Button } from "antd";
import React from "react";
import { DragableIconStd } from "src/common/icons";
import { observer } from "mobx-react-lite";
import { SortableList } from "src/components/SortableList";
import { UpOutlined } from "@ant-design/icons";
import { t } from "i18next";
import styles from "./AttsHierarchy.module.less";
import { EntityFiltersPageStore } from "../EntityFiltersPageStore";

type PropsAttsHierarchy = {
  store: EntityFiltersPageStore;
};

export const AttsHierarchy: React.FC<PropsAttsHierarchy> = observer(
  ({ store }) => {
    const [open, setOpen] = React.useState(true);
    const { cLOrderedStore } = store;
    const options = cLOrderedStore.orderedSelected;
    const empty = options.length === 0;
    if (empty) return null;

    const getPadding = (i: number) => `${(i + 1) * 16}px`;

    return (
      <div>
        <div className={styles.button}>
          {t("Hierarchy structure")}
          <Button
            className={styles.iconWrapper}
            type="text"
            icon={<UpOutlined />}
            onClick={() => {
              setOpen(!open);
            }}
          />
        </div>
        {open && (
          <div className={styles.listBox}>
            <SortableList
              onChange={(reordered) => {
                cLOrderedStore.setOrder(
                  reordered.map((item) => String(item.key)),
                );
                store.resetFilterTree();
              }}
              itemClassName={styles.listItem}
              items={options.map((opt, index) => ({
                content: (
                  <div
                    className={styles.listItemInner}
                    key={opt.value}
                    style={{ paddingLeft: getPadding(index) }}
                  >
                    {opt.label}
                    <DragableIconStd />
                  </div>
                ),
                key: String(opt.value),
              }))}
            />
          </div>
        )}
      </div>
    );
  },
);
