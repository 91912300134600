import { t } from "i18next";
import { Rule } from "rc-field-form/lib/interface";
// import {} from "day";

export const validationRules = {
  required: () => ({
    required: true,
    message: t("The field is mandatory"),
  }),

  requiredOr:
    (value1: unknown, title1: string, title2: string) => (value2: unknown) => {
      if (value1 || value2) {
        return true;
      }
      return `Введите ${title1} или ${title2}`;
    },

  requiredOrDatePerpetual:
    (value1: unknown, title1: string, title2: string) => (value2: unknown) => {
      if (value1 === true || value2) {
        return true;
      }
      return `Введите ${title1} или пометьте сертификат как ${title2}`;
    },

  requiredOrWithPattern:
    (
      value1: unknown,
      title1: string,
      title2: string,
      pattern: RegExp,
      customMessage?: string,
    ) =>
    (value2: string) => {
      if (value1 || value2) {
        if (pattern.test(value2)) {
          return true;
        }
        if (!value2) {
          return true;
        }
      }

      return customMessage || `Введите ${title1} или ${title2}`;
    },
};

// TODO: перевести на day.js
// export const disableBeforeToday = (current: Moment) =>
//   current && current < moment().startOf("day");

export const onlyNumbers = (): Rule => ({
  pattern: /^\d*$/,
  message: t("Just the numbers") || "",
});
