/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const GlassIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="20"
    height="20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C9.84871 16 11.551 15.3729 12.9056 14.3199L18.2929 19.7071L19.7071 18.2929L14.3199 12.9056C15.3729 11.551 16 9.84871 16 8C16 3.58172 12.4183 0 8 0Z"
    />
  </svg>
);
/**
 * используется в левом меню для пункта "справочник категорий" в активном состонии
 */
export const GlassIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={GlassIconStdSvg} {...props} />;
