import { rest } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import {
  EdNotifyTemplateDict,
  ed2templates,
  templates2ed,
} from "./EdNotifyTemplate";
import { zNotifyTemplate } from "./ZNotifyTemplate";

export const loadNotifyTemplates = async (
  objectId: number,
): Promise<EdNotifyTemplateDict> => {
  if (objectId < 0) return {};
  const resp = await rest.get(apiObjUrl("/message-template"), {
    params: { objectId },
  });
  const list = zNotifyTemplate.array().parse(resp.data);
  return templates2ed(list);
};

export const saveNotifyTemplates = async (
  objectId: number,
  dict: EdNotifyTemplateDict,
): Promise<void> => {
  const list = ed2templates(dict);
  await rest.post(apiObjUrl("/message-template"), list, {
    params: { objectId },
  });
};
