import * as React from "react";
import { Select, SelectProps } from "antd";
import type { BaseSelectRef } from "rc-select";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { CellBox, FnFocus, PropsCellBox } from "./CellBox";

export type PropsCellBoxSelect<
  TValue,
  Res,
  Option extends BaseOptionType | DefaultOptionType,
> = Omit<PropsCellBox<TValue, Res>, "children"> & {
  selectProps: Omit<
    SelectProps<TValue, Option>,
    "onBlur" | "onChange" | "value"
  >;
};

export const CellBoxSelect = <
  TValue,
  Res = void,
  Option extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: PropsCellBoxSelect<TValue, Res, Option>,
) => {
  const { selectProps, ...cellProps } = props;
  return (
    <CellBox<TValue, Res> {...cellProps}>
      {(chProps) => (
        <Inner<TValue, Res, Option> selectProps={selectProps} {...chProps} />
      )}
    </CellBox>
  );
};

interface PropsInner<
  TValue,
  Res,
  Option extends BaseOptionType | DefaultOptionType,
> {
  value: TValue | undefined;
  onChange(value: TValue | undefined): void;
  onBlur(): void;
  getFocus: (fn: FnFocus) => void;
  selectProps: PropsCellBoxSelect<TValue, Res, Option>["selectProps"];
}

const Inner = <TValue, Res, Option extends BaseOptionType | DefaultOptionType>(
  props: PropsInner<TValue, Res, Option>,
) => {
  const { selectProps, value, onChange, onBlur, getFocus } = props;
  const ref = React.useRef<BaseSelectRef>(null);
  React.useEffect(() => {
    getFocus(() => {
      ref.current?.focus();
    });
    return () => getFocus(undefined);
  }, [ref.current]);

  const { style, ...restProps } = selectProps;
  const selectStyle = { ...style, width: "100%" };

  return (
    <div style={{ width: "100%" }}>
      <Select<TValue, Option>
        ref={ref}
        {...restProps}
        style={selectStyle}
        value={value}
        onChange={(v) => {
          onChange(v);
        }}
        onBlur={onBlur}
      />
    </div>
  );
};
