/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const SettingsIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => (
  <svg
    viewBox="0 0 16 14"
    {...props}
    width="16"
    height="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3V4H4V0H2V1L0 1V3L2 3ZM16 3H6V1L16 1V3ZM14 8L16 8L16 6L14 6V5H12V9H14V8ZM10 8L0 8V6L10 6V8ZM0 13L5 13V14H7V13V11L7 10H5L5 11L0 11V13ZM16 13L9 13V11L16 11V13Z"
    />
  </svg>
);
/**
 * используется в левом меню для пункта "справочник категорий" в активном состонии
 */
export const SettingsIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={SettingsIconStdSvg} {...props} />;
