import * as React from "react";
import { Button, Form, Select, SelectProps } from "antd";
import { TableStore } from "src/components/tables/TableStore";
import { ZOption } from "src/types/ZOption";
import {
  DictionaryViewModal,
  createDictViewStore,
} from "./DictionaryViewModal";
import styles from "./AttrFields.module.less";
import { CategoryAttributesStore } from "../../CategoryAttributesStore";

/* eslint react/jsx-props-no-spreading: "off" */

type PropsSelectDictionaryForAttr = SelectProps<string> & {
  attrStore: CategoryAttributesStore;
};

export const SelectDictionaryForAttr: React.FC<PropsSelectDictionaryForAttr> = (
  props,
) => {
  const [dictStore, setDictStore] = React.useState<
    TableStore<ZOption, {}> | undefined
  >();
  const form = Form.useFormInstance();
  const dictionary = Form.useWatch("dictionary", form);
  const onShowDictionary = () => {
    if (dictionary) setDictStore(createDictViewStore(dictionary));
  };

  const { attrStore, ...selProps } = props;
  return (
    <div className={styles.colAndSuffix}>
      <Select {...selProps} />
      <Button disabled={!dictionary} onClick={onShowDictionary}>
        Смотреть
      </Button>
      {!!dictStore && (
        <DictionaryViewModal
          store={dictStore}
          close={() => setDictStore(undefined)}
          dictCode={dictionary || ""}
          dictRef={attrStore.referencesDict}
        />
      )}
    </div>
  );
};
