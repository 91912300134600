import { ZMenuItemLight } from "src/components/MainFrame/LeftMenu/ZMenuItem";
import { ZTranslationMap } from "src/types/ZTranslation";

export type EdMenuItem = ZMenuItemLight & {
  translation?: ZTranslationMap;
};

export const menuItem2Edit = (
  item: ZMenuItemLight,
  translation: ZTranslationMap,
): EdMenuItem => ({
  ...item,
  translation,
});

export const edit2MenuItem = (
  edit: EdMenuItem,
): {
  menuItemLight: ZMenuItemLight;
  translation: ZTranslationMap | undefined;
} => {
  const { translation, ...menuItemLight } = edit;
  return { menuItemLight, translation };
};
