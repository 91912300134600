import { Tag as TagAnt } from "antd";
import React, { useEffect, useState } from "react";
import { SimpleText } from "../SimpleText/SimpleText";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import { ZTag } from "../../componentsDefs/ZTag";

const getTagColor = (viewProps: ZTag | undefined) => viewProps?.tagColor;

const createSimpleLable = (
  viewInfo: ZAttrViewInfo | undefined,
  values: string[],
) => <SimpleText values={values} viewInfo={viewInfo} />;

const createTag = (
  viewProps: ZTag | undefined,
  viewInfo: ZAttrViewInfo | undefined,
  values?: string[],
) => (
  <div style={viewInfo?.styles ?? undefined}>
    {values?.find((val) => !!val) && (
      <TagAnt
        color={getTagColor(viewProps)}
        style={{ ...viewInfo?.styles, maxWidth: "100%" }}
      >
        {createSimpleLable(viewInfo, values)}
      </TagAnt>
    )}
  </div>
);

const createLabel = (
  viewProps: ZTag | undefined,
  viewInfo: ZAttrViewInfo | undefined,
  values: string[],
) => createTag(viewProps, viewInfo, values);

type PropsTag = {
  viewProps: ZTag | undefined;
  values: string[];
  viewInfo?: ZAttrViewInfo;
};

export const Tag: React.FC<PropsTag> = ({ viewProps, values, viewInfo }) => {
  const [label, setLabel] = useState<React.ReactNode | null>(null);
  useEffect(() => {
    if (!values?.length) return;
    setLabel(createLabel(viewProps, viewInfo, values));
  }, []);

  return label;
};
Tag.defaultProps = { viewInfo: undefined };
