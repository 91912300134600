// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AsyncTableView-TableSortingButtonSdt-TableSortingButtonSdt-module__sorter--gIrUU {
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.src-pages-EntityFiltersPage-AsyncTableView-TableSortingButtonSdt-TableSortingButtonSdt-module__sorter--gIrUU svg {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AsyncTableView/TableSortingButtonSdt/TableSortingButtonSdt.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAPA;EAQI,eAAA;AAEJ","sourcesContent":[".sorter {\n  background: none;\n  border: none;\n  box-shadow: none;\n  display: flex;\n  column-gap: 10px;\n  align-items: center;\n  svg {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sorter": `src-pages-EntityFiltersPage-AsyncTableView-TableSortingButtonSdt-TableSortingButtonSdt-module__sorter--gIrUU`
};
export default ___CSS_LOADER_EXPORT___;
