import { z } from "zod";

export const zSampleSupplier = z.object({
  id: z.number().or(z.string()),
  name: z.string(),
  shortName: z.string().nullish(),
  country: z.string().nullish(),
  address: z.string().nullish(),
});
export type ZSampleSupplier = z.infer<typeof zSampleSupplier>;

export const zSampleSuppliersResponse = z.object({
  content: zSampleSupplier.array(),
  totalElements: z.number(),
});

export const zSampleColor = z.object({
  id: z.number().or(z.string()),
  article: z.string().nullish(),
});
export type ZSampleColor = z.infer<typeof zSampleColor>;

export const zSampleColorsResponse = z.object({
  content: zSampleColor.array(),
  totalElements: z.number(),
});

// dictionaries

export const zSampleRecord = z.object({
  id: z.number().or(z.string()),
  value: z.string(),
});
export type ZSampleRecord = z.infer<typeof zSampleRecord>;

export const zSampleRequest = z.object({
  id: z.number().or(z.string()),
  modelId: z.number().or(z.string()),
  nameRequest: z.string(),
  numberRequest: z.string().nullish(),
  supplier: zSampleSupplier.nullish(),
  color: zSampleColor.nullish(),
  sendDate: z.string().nullish(),
  receiveDate: z.string().nullish(),
  comment: z.string().nullable(),
  typeRequest: zSampleRecord.nullish(),
  state: zSampleRecord.nullish(),
});

export type ZSampleRequest = z.infer<typeof zSampleRequest>;

export type EdSampleRequest = Omit<ZSampleRequest, "id" | "modelId">;
