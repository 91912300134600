/* eslint-disable react/require-default-props */
import React from "react";
import { DrawerPlm, PropsDrawerPlm } from "src/components/DrawerPlm/DrawerPlm";
import {
  ConfirmationActionsPlm,
  PropsConfirmationActionsPlm,
} from "src/components/ConfirmationActionsPlm";

type PropsDrawerPlmWithConfirmation = {
  drawerProps?: Omit<PropsDrawerPlm, "children" | "extra">;
  confirmationProps?: PropsConfirmationActionsPlm;
};

export const DrawerPlmWithConfirmation: React.FC<
  React.PropsWithChildren<PropsDrawerPlmWithConfirmation>
> = (props) => {
  const {
    confirmationProps: confirmation = {},
    drawerProps = {},
    children,
  } = props;

  return (
    <DrawerPlm
      {...drawerProps}
      extra={<ConfirmationActionsPlm {...confirmation} />}
    >
      {children}
    </DrawerPlm>
  );
};
