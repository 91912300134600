// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-attrView-components-ImgList-ImgList-module__box--WPQsq {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 3px;
}
.src-common-attrView-components-ImgList-ImgList-module__img--kUa4H {
  width: 100%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/common/attrView/components/ImgList/ImgList.module.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,eAAA;EACA,QAAA;AACF;AACA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".box {\n  display: inline-flex;\n  flex-wrap: wrap;\n  gap: 3px;\n}\n.img {\n  width: 100%;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-common-attrView-components-ImgList-ImgList-module__box--WPQsq`,
	"img": `src-common-attrView-components-ImgList-ImgList-module__img--kUa4H`
};
export default ___CSS_LOADER_EXPORT___;
