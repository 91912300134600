import * as React from "react";
import { InputNumber, InputNumberProps } from "antd";
import { ifDef } from "src/common/ifDef";

export type PropsEntityInputNumber = Omit<
  InputNumberProps,
  "onChange" | "value"
> & {
  value?: string[] | null;
  onChange?(value?: string[] | null): void;
};

export const EntityInputNumber: React.FC<PropsEntityInputNumber> = (props) => {
  const { value, onChange, ...restProps } = props;
  return (
    <InputNumber
      {...restProps}
      value={ifDef(value?.[0], (it) => +it)}
      onChange={(v) => onChange?.(ifDef(v, (it) => [String(it)]) ?? [])}
      maxLength={14}
      parser={(val) => val?.replace(",", ".") ?? ""}
    />
  );
};

EntityInputNumber.defaultProps = {
  value: undefined,
  onChange: undefined,
};
