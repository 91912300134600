// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-RegisterPage-RegFinish-RegFinish-module__totalBox--YET_8 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.src-pages-RegisterPage-RegFinish-RegFinish-module__img--Tckfo {
  margin-bottom: 16px;
}
.src-pages-RegisterPage-RegFinish-RegFinish-module__title--Qdj_B {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%;
  margin-bottom: 24px;
}
.src-pages-RegisterPage-RegFinish-RegFinish-module__toHome--dPcAG {
  width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/RegisterPage/RegFinish/RegFinish.module.less"],"names":[],"mappings":"AACA;EACI,OAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAIA;EACI,mBAAA;AAFJ;AAIA;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAFJ;AAIA;EACI,YAAA;AAFJ","sourcesContent":["@w: 600px;\n.totalBox {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n}\n\n.img {\n    margin-bottom: 16px;\n}\n.title {\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 132%; \n    margin-bottom: 24px;\n}\n.toHome {\n    width: @w;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalBox": `src-pages-RegisterPage-RegFinish-RegFinish-module__totalBox--YET_8`,
	"img": `src-pages-RegisterPage-RegFinish-RegFinish-module__img--Tckfo`,
	"title": `src-pages-RegisterPage-RegFinish-RegFinish-module__title--Qdj_B`,
	"toHome": `src-pages-RegisterPage-RegFinish-RegFinish-module__toHome--dPcAG`
};
export default ___CSS_LOADER_EXPORT___;
