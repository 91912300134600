// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-tables-AsyncTable-AsyncTable-module__hd--GcwaT .ant-table-column-title:after {
  content: "";
  display: none;
}
.src-components-tables-AsyncTable-AsyncTable-module__hd--GcwaT .ant-table-column-sorters {
  display: inline;
}
.src-components-tables-AsyncTable-AsyncTable-module__hd--GcwaT.ant-table-column-has-sorters:hover {
  text-decoration: underline;
}
.src-components-tables-AsyncTable-AsyncTable-module__rowsClickable--y791H .ant-table-row-level-0 {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/AsyncTable/AsyncTable.module.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;EACA,aAAA;AAAJ;AAHA;EAMI,eAAA;AAAJ;AAEE;EACE,0BAAA;AAAJ;AAGA;EACE,eAAA;AADF","sourcesContent":[".hd {\n  :global(.ant-table-column-title):after {\n    content: \"\";\n    display: none;\n  }\n  :global(.ant-table-column-sorters) {\n    display: inline;\n  }\n  &:global(.ant-table-column-has-sorters):hover {\n    text-decoration: underline;\n  }\n}\n.rowsClickable :global(.ant-table-row-level-0) {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hd": `src-components-tables-AsyncTable-AsyncTable-module__hd--GcwaT`,
	"rowsClickable": `src-components-tables-AsyncTable-AsyncTable-module__rowsClickable--y791H`
};
export default ___CSS_LOADER_EXPORT___;
