import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { FormObjectSectionsStore } from "src/components/Forms/FormObjectSection";

export type BomObjType =
  | "bom"
  | "color"
  | "colorModel"
  | "material"
  | "position"
  | "supplier";

export const bom2SettingsStore = makeAutoObservable({
  async init(attrTypesDict: Record<number, string>) {
    try {
      await this.formSectionsStore.init(attrTypesDict);
    } catch (e) {
      onError(e);
    }
  },
  formSectionsStore: new FormObjectSectionsStore<BomObjType>(),
});

export type Bom2SettingsStore = typeof bom2SettingsStore;
