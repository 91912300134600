// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__box--rf5Tn {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__toolbar--xkyGF {
  display: flex;
  gap: 8px;
}
.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__leftTool--yZlAs {
  flex: 1;
}
.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__table--NFgIC {
  flex: 1;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SampleSummary/SampleSummary.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACA;EACI,aAAA;EACA,QAAA;AACJ;AACA;EACI,OAAA;AACJ;AAEA;EACI,OAAA;EACA,gBAAA;AAAJ","sourcesContent":[".box {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    padding: 1em;\n}\n.toolbar {\n    display: flex;\n    gap: 8px;\n}\n.leftTool {\n    flex: 1;\n}\n\n.table {\n    flex: 1;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__box--rf5Tn`,
	"toolbar": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__toolbar--xkyGF`,
	"leftTool": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__leftTool--yZlAs`,
	"table": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__table--NFgIC`
};
export default ___CSS_LOADER_EXPORT___;
