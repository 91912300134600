// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-PlmUploader-PlmUploader-module__plmImgUploaderStart--Bp51u .ant-upload-list.ant-upload-list-picture-card .ant-upload.ant-upload-select {
  width: 100%;
}
.src-components-PlmUploader-PlmUploader-module__imageUploadButton--N6FIy {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  border: 0;
  background: none;
  cursor: pointer;
}
.src-components-PlmUploader-PlmUploader-module__plmFileUploader--JJ2Y5 {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 8px;
}
.src-components-PlmUploader-PlmUploader-module__plmFileUploader--JJ2Y5 ~ .src-components-PlmUploader-PlmUploader-module__uploadInfo--MkhA8 {
  padding-top: 8px;
}
.src-components-PlmUploader-PlmUploader-module__plmFileUploader--JJ2Y5 .ant-upload-list-item-container .ant-upload-list-item {
  margin-top: 0;
  padding: 8px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  height: 32px;
}
.src-components-PlmUploader-PlmUploader-module__plmFileUploader--JJ2Y5 .ant-upload-list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.src-components-PlmUploader-PlmUploader-module__plmFileUploader--JJ2Y5 .ant-upload-list::before,
.src-components-PlmUploader-PlmUploader-module__plmFileUploader--JJ2Y5 .ant-upload-list::after {
  display: none;
}
.src-components-PlmUploader-PlmUploader-module__uploadInfo--MkhA8 {
  font-size: 12px;
  color: #0000008C;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.src-components-PlmUploader-PlmUploader-module__fileUploadButton--tyOay {
  width: 100%;
  color: #FFFFFF;
  background-color: #595959;
}
`, "",{"version":3,"sources":["webpack://./src/components/PlmUploader/PlmUploader.module.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;AAAJ;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;AAFF;AAKA;EACE,aAAA;EACA,8BAAA;EACA,YAAA;AAHF;AAKE;EACE,gBAAA;AAHJ;AAHA;EAUI,aAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;AAJJ;AAVA;EAkBI,aAAA;EACA,sBAAA;EACA,YAAA;AALJ;AAfA;;EAyBI,aAAA;AANJ;AAUA;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AARF;AAWA;EACE,WAAA;EACA,cAAA;EACA,yBAAA;AATF","sourcesContent":[".plmImgUploaderStart {\n  :global(.ant-upload-list.ant-upload-list-picture-card .ant-upload.ant-upload-select) {\n    width: 100%;\n  }\n}\n\n.imageUploadButton {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 8px;\n  border: 0;\n  background: none;\n  cursor: pointer;\n}\n\n.plmFileUploader {\n  display: flex;\n  flex-direction: column-reverse;\n  row-gap: 8px;\n\n  & ~ .uploadInfo {\n    padding-top: 8px;\n  }\n\n  :global(.ant-upload-list-item-container .ant-upload-list-item) {\n    margin-top: 0;\n    padding: 8px;\n    border: 1px solid #D9D9D9;\n    border-radius: 4px;\n    height: 32px;\n  }\n\n  :global(.ant-upload-list) {\n    display: flex;\n    flex-direction: column;\n    row-gap: 8px;\n  }\n\n  :global(.ant-upload-list)::before,\n  :global(.ant-upload-list)::after {\n    display: none;\n  }\n}\n\n.uploadInfo {\n  font-size: 12px;\n  color: #0000008C;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.fileUploadButton {\n  width: 100%;\n  color: #FFFFFF;\n  background-color: #595959;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plmImgUploaderStart": `src-components-PlmUploader-PlmUploader-module__plmImgUploaderStart--Bp51u`,
	"imageUploadButton": `src-components-PlmUploader-PlmUploader-module__imageUploadButton--N6FIy`,
	"plmFileUploader": `src-components-PlmUploader-PlmUploader-module__plmFileUploader--JJ2Y5`,
	"uploadInfo": `src-components-PlmUploader-PlmUploader-module__uploadInfo--MkhA8`,
	"fileUploadButton": `src-components-PlmUploader-PlmUploader-module__fileUploadButton--tyOay`
};
export default ___CSS_LOADER_EXPORT___;
