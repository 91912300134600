import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { ZSampleSettings } from "../../SampleTypes";
import { SampleObjType } from "../SampleSettingsStore";

interface PropsMcPointsSection {
  store: FormObjectSectionsStore<SampleObjType>;
}

export const McPointsSection: React.FC<PropsMcPointsSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZSampleSettings, SampleObjType>
      store={store}
      objKey={["objSampleMcPoint"]}
      objType="mcPoints"
      objLabel="Sample mc points object"
      title="Sample mc points"
      attrs={[
        {
          attrKey: ["sampleMcPointSampleAttrId"],
          attrLabel: "Sample",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sampleMcPointMcPointValueAttrId"],
          attrLabel: "Point",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sampleMcPointMeasuredValueAttrId"],
          attrLabel: "Measured value",
          attrType: AttrTypeName.number,
        },
      ]}
    />
  ),
);
