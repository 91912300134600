import * as React from "react";
import { Modal, Alert, Button, Space } from "antd";
import { onError } from "src/common/onError";
import { t } from "i18next";
import { CategoryAttributesStore } from "../../CategoryAttributesStore";

interface PropsDeleteAttrModal {
  open: boolean;
  close(): void;
  attrStore: CategoryAttributesStore;
}

export const DeleteAttrModal: React.FC<PropsDeleteAttrModal> = ({
  open,
  close,
  attrStore,
}: PropsDeleteAttrModal) => {
  const [wait, setWait] = React.useState(false);

  const doDelete = () => {
    setWait(true);
    attrStore
      .onDelete()
      .finally(() => setWait(false))
      .then(() => close())
      .catch(onError);
  };
  const message = (
    <span>
      Подтвердите удаление атрибута <b>{attrStore.curAttr?.label || ""}</b>.
    </span>
  );

  return (
    <Modal
      open={open}
      onCancel={close}
      title="Удаление атрибута"
      footer={
        <Space>
          <Button type="primary" danger loading={wait} onClick={doDelete}>
            {t("Delete")}
          </Button>
          <Button onClick={close}>Отмена</Button>
        </Space>
      }
    >
      <Alert
        type="warning"
        showIcon
        message={message}
        description="Данное поле также будет удалено во всех товарах где используется."
      />
    </Modal>
  );
};
