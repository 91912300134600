// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ModelsListPage-ModelsListPage-module__page--_BA8s {
  height: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: "tabsLine tabsLine" "totalMsg search" "left main";
}
.src-pages-ModelsListPage-ModelsListPage-module__tabsLine--gR1Ro {
  grid-area: tabsLine;
  background-color: #EEE;
  height: 30px;
  border-bottom: thin solid silver;
}
.src-pages-ModelsListPage-ModelsListPage-module__totalMsg--v4mEX {
  grid-area: totalMsg;
  border-bottom: thin solid silver;
  border-right: thin solid silver;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
.src-pages-ModelsListPage-ModelsListPage-module__search--PC7RF {
  grid-area: search;
  height: 40px;
  border-bottom: thin solid silver;
}
.src-pages-ModelsListPage-ModelsListPage-module__left--YSpcv {
  grid-area: left;
  border-right: thin solid silver;
}
.src-pages-ModelsListPage-ModelsListPage-module__main--k1Z7n {
  grid-area: main;
  background-color: bisque;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ModelsListPage/ModelsListPage.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,gCAAA;EACA,iCAAA;EACA,sEAAA;AACJ;AAQA;EACI,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,gCAAA;AANJ;AASA;EACI,mBAAA;EACA,gCAAA;EACA,+BAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAPJ;AAUA;EACI,iBAAA;EACA,YAAA;EACA,gCAAA;AARJ;AAWA;EACI,eAAA;EACA,+BAAA;AATJ;AAYA;EACI,eAAA;EACA,wBAAA;AAVJ","sourcesContent":[".page {\n    height: 100%;\n    display: grid;\n    grid-template-columns: 200px 1fr; // TODO: Пока не точно\n    grid-template-rows: auto auto 1fr;\n    grid-template-areas: \n        \"tabsLine tabsLine\"\n        \"totalMsg search\"\n        \"left main\"\n    ;\n}\n\n@border: thin solid silver; // TODO: цвет\n\n.tabsLine {\n    grid-area: tabsLine;\n    background-color: #EEE; // TODO: цвет\n    height: 30px; // TODO: Не точно\n    border-bottom: @border;\n}\n\n.totalMsg {\n    grid-area: totalMsg;\n    border-bottom: @border;\n    border-right: @border;\n    display: flex;\n    align-items: center;\n    padding: 0 16px; // TODO: не точно\n}\n\n.search {\n    grid-area: search;\n    height: 40px; // Не точно\n    border-bottom: @border;\n}\n\n.left {\n    grid-area: left;\n    border-right: @border;\n}\n\n.main {\n    grid-area: main;\n    background-color: bisque;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `src-pages-ModelsListPage-ModelsListPage-module__page--_BA8s`,
	"tabsLine": `src-pages-ModelsListPage-ModelsListPage-module__tabsLine--gR1Ro`,
	"totalMsg": `src-pages-ModelsListPage-ModelsListPage-module__totalMsg--v4mEX`,
	"search": `src-pages-ModelsListPage-ModelsListPage-module__search--PC7RF`,
	"left": `src-pages-ModelsListPage-ModelsListPage-module__left--YSpcv`,
	"main": `src-pages-ModelsListPage-ModelsListPage-module__main--k1Z7n`
};
export default ___CSS_LOADER_EXPORT___;
