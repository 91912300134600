import * as React from "react";
import { observer } from "mobx-react-lite";
import { ModelsList, modelsListStore } from "./ModelsList";
import { ModelEditor } from "./ModelEditor";
import styles from "./ModelConstructorPage.module.less";

export const ModelConstructorPage: React.FC = observer(() => (
  <div className={styles.modelsPage}>
    <h1>Демо-версия (без дизайна!)</h1>
    <div className={styles.list}>
      <ModelsList store={modelsListStore} />
    </div>
    <div className={styles.model}>
      <ModelEditor
        model={modelsListStore.curModel}
        onModelStore={(store) => modelsListStore.setModelStore(store)}
      />
    </div>
  </div>
));
