// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-MChartEntity-module__serviceBox--bbUQX {
  height: 100%;
  overflow: hidden;
  padding: 0 16px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/MChartEntity.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".serviceBox {\n    height: 100%;\n    overflow: hidden;\n    padding: 0 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-MChartEntity-module__serviceBox--bbUQX`
};
export default ___CSS_LOADER_EXPORT___;
