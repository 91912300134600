/**
 * Компонент формы для выбора атрибута для указанного объекта.
 * Позволяет указать атрибут или группу, которые не должны войти в дерево.
 */
import * as React from "react";
import { Empty, Tree } from "antd";
import {
  AttrTreeNode,
  createAttrTree,
  makeAttrNodeKey,
  parseAttrNodeKey,
} from "./AttrTreeNode";
import { CommonNodeO2 } from "../../Obj2Nodes";

interface PropsSelectAttribute {
  value?: number; // id атрибута
  onChange?(newValue?: number): void;
  tree: CommonNodeO2[];
  disabled?: boolean;
  className?: string;
}

export const SelectAttribute: React.FC<PropsSelectAttribute> = (
  props: PropsSelectAttribute,
) => {
  const { tree, value, onChange, disabled, className } = props;
  const treeData: AttrTreeNode[] = React.useMemo(
    () => createAttrTree(tree),
    [tree],
  );
  const selectedKeys =
    typeof value === "number" ? [makeAttrNodeKey(value, "attr")] : [];
  if (tree.length === 0) {
    return (
      <div>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Не найдено подходящих атрибутов"
        />
      </div>
    );
  }
  const onSelect = (keys: React.Key[]) => {
    const pair = parseAttrNodeKey(keys[0]);
    onChange?.(pair ? pair[1] : undefined);
  };
  return (
    <Tree
      treeData={treeData}
      selectedKeys={selectedKeys}
      onSelect={onSelect}
      showIcon
      disabled={disabled}
      autoExpandParent
      className={className}
    />
  );
};
SelectAttribute.defaultProps = {
  value: undefined,
  onChange: undefined,
  disabled: false,
  className: undefined,
};
