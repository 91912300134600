import { AxiosError } from "axios";
import { z } from "zod";
import i18next from "i18next";
import { onError } from "src/common/onError";
import { FormWithBlockStore } from "./FormWithBlockStore";
import { FormBlockDef } from "./FormWithBlocks.types";
import { unpackName } from "./items/nameUtils";

const zErrorStruct = z.record(z.string(), z.string().array());

export const onErrorFinish = (
  e: Error,
  store: FormWithBlockStore,
  name: string,
  block: FormBlockDef,
) => {
  const axiosError = e as AxiosError;
  if (axiosError.isAxiosError) {
    const result = zErrorStruct.safeParse(axiosError.response?.data);
    let isFirst = true;
    if (result.success) {
      const { data } = result;
      const dict: Record<string, string> = {};
      Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
          const msg = data[key]![0] as string;
          dict[key] = i18next.exists(msg) ? i18next.t(msg) : msg;
          if (isFirst) {
            store.activate(name, unpackName(key), block);
          }
          isFirst = false;
        }
      });
      store.setExternalErrors(dict);
    }
  }
  onError(e);
};
