import { z } from "zod";
import { ZAttrViewInfo } from "../zAttrViewInfo";

export const zTag = z.object({
  view: z.literal("Tag"),
  tagColor: z.string().optional(),
});
export type ZTag = z.infer<typeof zTag>;

export const makeTagProps = (
  viewProps: ZTag,
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
) => ({
  values,
  viewInfo,
  viewProps,
});
