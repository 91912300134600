import { ZAttribute } from "src/types/ZAttribute";
import { ZGroup } from "src/types/ZGroup";
import { ZObjectItem } from "src/types/ZObjectItem";

const getNestedAtts = (src: ZGroup[] | null | undefined): ZAttribute[] => {
  const attrList = src?.map((e) => [
    ...(e.attributes || []),
    ...getNestedAtts(e.groups),
  ]);
  return (attrList || []).flat();
};

export const getObjectAttsFlat = (object: ZObjectItem) => [
  ...object.attributes,
  ...getNestedAtts(object.groups),
];
