import * as React from "react";
import { classNames } from "src/common/classNames";
import styles from "./VerticalLayout.module.less";

interface PropsVerticalLayout {
  children?: React.ReactNode | React.ReactNode[];
  padding?: boolean;
}

export const VerticalLayout: React.FC<PropsVerticalLayout> = ({
  children,
  padding,
}: PropsVerticalLayout) => (
  <div className={classNames([styles.box, [padding, styles.padding]])}>
    {children}
  </div>
);

VerticalLayout.defaultProps = {
  children: undefined,
};

VerticalLayout.defaultProps = { padding: false, children: null };
