import * as React from "react";
import { AnchorItem } from "src/common/anchorItem";
import { classNames } from "src/common/classNames";
import styles from "./EntityAnchor.module.less";

interface PropsEntityAnchor {
  anchors: AnchorItem[];
  containerSelector: string;
}

export const EntityAnchor: React.FC<PropsEntityAnchor> = (props) => {
  const { anchors, containerSelector } = props;
  const [curKey, setCurKey] = React.useState("");

  /* eslint no-plusplus: "off" */
  // Требуется устанавливать блокировку в случае если был клик на элементе меню.
  // Чтобы игнорировать события скролла, которые появятся из-за него.
  const lockCounter = React.useRef(0);
  const lock = () => lockCounter.current++;
  const unlock = () => lockCounter.current--;

  const resetCurrentItem = (container: HTMLElement | null) => {
    let curAnchor = anchors[0];
    if (!container || !curAnchor || lockCounter.current) return;
    const { scrollTop, offsetTop } = container as HTMLElement;
    anchors.forEach((anchor) => {
      const elem = document.querySelector(anchor.href);
      if (elem) {
        const distance = (elem as HTMLElement).offsetTop - offsetTop;
        if (scrollTop >= distance) curAnchor = anchor;
      }
    });
    setCurKey(curAnchor.key);
  };

  React.useEffect(() => {
    const curContainer = document.querySelector<HTMLElement>(containerSelector);
    const onScroll = () => resetCurrentItem(curContainer);
    curContainer?.addEventListener("scroll", onScroll);
    return () => {
      curContainer?.removeEventListener("scroll", onScroll);
    };
  }, [containerSelector]);

  React.useEffect(() => {
    setTimeout(() => {
      resetCurrentItem(document.querySelector(containerSelector));
    }, 10);
  }, [containerSelector]);

  return (
    <div className={styles.box}>
      {anchors.map(({ key, href, title }) => (
        <a
          key={key}
          href={href}
          className={classNames([styles.item, [key === curKey, styles.active]])}
          onClick={(e) => {
            e.preventDefault();
            setCurKey(key);
            lock(); // На некоторое время заблокировать обработку событий скролла.
            setTimeout(unlock, 600);
            document
              .querySelector(href)
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          {title}
        </a>
      ))}
    </div>
  );
};
