import { z } from "zod";

export const zValueRestriction = z.object({
  sourceId: z.number(), // master(first)
  restrictionId: z.number(), // slave(second)
});
export type ZValueRestriction = z.infer<typeof zValueRestriction>;

export const zRestriction = z.object({
  // sourceAttributeId: z.number(), // id атрибута с ограничивающими значениями
  restrictionAttributeId: z.number(), // id справочника (= sourceAttribute.referenceId)
  values: zValueRestriction.array(),
});
export type ZRestriction = z.infer<typeof zRestriction>;
