// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .ant-image {
  max-width: 100%;
  max-height: 100%;
  display: flex;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__image--zgoo7,
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageError--TNKu1 {
  display: none;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__image--zgoo7 {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__image--zgoo7.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__active--JJI2r {
  display: block;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageError--TNKu1 {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageError--TNKu1.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__active--JJI2r {
  display: flex;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageError--TNKu1 span {
  height: 25%;
  width: 25%;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07 .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageError--TNKu1 svg {
  color: #E9033A;
  height: 100%;
  width: 100%;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__toolbar--b9E0G {
  background-color: #00000073;
  padding: 14px 22px;
  border-radius: 24px;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__toolbar--b9E0G .src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__downloadButton--c17Oc {
  padding: 0;
  width: auto;
  height: auto;
  background: none;
  border: none;
  color: #FFFFFF99 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__toolbar--b9E0G svg {
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageCarousel/ImageCarouselInner/ImageCarouselInner.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACF;AALA;EAOI,eAAA;EACA,gBAAA;EACA,aAAA;AACJ;AAVA;;EAaI,aAAA;AACJ;AAdA;EAiBI,mBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAtBA;EA2BI,YAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAII;EACE,aAAA;AAFN;AA/BA;EAqCM,WAAA;EACA,UAAA;AAHN;AAnCA;EA0CM,cAAA;EACA,YAAA;EACA,WAAA;AAJN;AASA;EACE,2BAAA;EACA,kBAAA;EACA,mBAAA;AAPF;AAIA;EAMI,UAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAPJ;AAPA;EAkBI,eAAA;AARJ","sourcesContent":[".imageContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n\n  :global(.ant-image) {\n    max-width: 100%;\n    max-height: 100%;\n    display: flex;\n  }\n\n  .image, .imageError {\n    display: none;\n  }\n\n  .image {\n    object-fit: contain;\n    max-width: 100%;\n    max-height: 100%;\n\n    &.active {\n      display: block;\n    }\n  }\n\n  .imageError {\n    height: 100%;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n\n    &.active {\n      display: flex;\n    }\n\n    span {\n      height: 25%;\n      width: 25%;\n    }\n\n    svg {\n      color: #E9033A;\n      height: 100%;\n      width: 100%;\n    }\n  }\n}\n\n.toolbar {\n  background-color: #00000073;\n  padding: 14px 22px;\n  border-radius: 24px;\n\n  .downloadButton {\n    padding: 0;\n    width: auto;\n    height: auto;\n    background: none;\n    border: none;\n    color: #FFFFFF99 !important;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  svg {\n    font-size: 20px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageContainer--Shm07`,
	"image": `src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__image--zgoo7`,
	"imageError": `src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__imageError--TNKu1`,
	"active": `src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__active--JJI2r`,
	"toolbar": `src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__toolbar--b9E0G`,
	"downloadButton": `src-components-ImageCarousel-ImageCarouselInner-ImageCarouselInner-module__downloadButton--c17Oc`
};
export default ___CSS_LOADER_EXPORT___;
