// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-VerticalLayout-VerticalLayout-module__box--VbTfV {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}
.src-components-VerticalLayout-VerticalLayout-module__padding--Z0yE7 {
  padding: 8px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/VerticalLayout/VerticalLayout.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AACJ;AACA;EACI,iBAAA;AACJ","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    height: 100%;\n}\n.padding {\n    padding: 8px 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-components-VerticalLayout-VerticalLayout-module__box--VbTfV`,
	"padding": `src-components-VerticalLayout-VerticalLayout-module__padding--Z0yE7`
};
export default ___CSS_LOADER_EXPORT___;
