// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingSettings-tabs-DrawTab-DrawTab-module__box--t05La {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 0;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingSettings/tabs/DrawTab/DrawTab.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;AACF","sourcesContent":[".box {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 16px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsScheduling-SchedulingSettings-tabs-DrawTab-DrawTab-module__box--t05La`
};
export default ___CSS_LOADER_EXPORT___;
