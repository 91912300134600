// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-CategoryRefPage-CateroryTree-CategoryTree-module__box--CnMdL {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: thin solid #bfbfbf;
  border-radius: 4px;
  padding: 17px 32px;
}
.src-pages-CategoryRefPage-CateroryTree-CategoryTree-module__tree--BEQlm {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryRefPage/CateroryTree/CategoryTree.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EAEA,0BAAA;EACA,kBAAA;EACA,kBAAA;AAAF;AAEA;EACE,OAAA;AAAF","sourcesContent":[".box {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 14px;\n\n  border: thin solid #bfbfbf;\n  border-radius: 4px;\n  padding: 17px 32px;\n}\n.tree {\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-CategoryRefPage-CateroryTree-CategoryTree-module__box--CnMdL`,
	"tree": `src-pages-CategoryRefPage-CateroryTree-CategoryTree-module__tree--BEQlm`
};
export default ___CSS_LOADER_EXPORT___;
