import * as React from "react";
import { t } from "i18next";
import { Form, Select } from "antd";
import { ModalPlm } from "src/components/ModalPlm";
import { VerticalLayout } from "src/components/VerticalLayout";
import { ZMcSizeVersions } from "src/businessServices/services/bsMeasurementChart/ZMcSizeVersions";
import { ZIdName } from "src/types/ZIdName";
import { onError } from "src/common/onError";
import { NewSampleData } from "./SamplePointsTypes";

/* eslint react/no-unstable-nested-components: "off" */

interface PropsAddSampleModal {
  open: boolean;
  close(): void;
  sizeVersions: ZMcSizeVersions[];
  onSubmit(values: NewSampleData): Promise<void>;
}

const field = (key: keyof NewSampleData) => key;

export const AddSampleModal: React.FC<PropsAddSampleModal> = (props) => {
  const { open, close, sizeVersions, onSubmit } = props;
  const [form] = Form.useForm<NewSampleData>();
  const sizes = React.useMemo(
    () => sizeVersions.map(({ size }) => size),
    [sizeVersions],
  );
  const [wait, setWait] = React.useState(false);
  const [versions, setVersions] = React.useState<ZIdName[]>([]);
  const updateVersions = (sizeId: number | string) => {
    form.resetFields([field("versionId")]);
    const sizeExt = sizeVersions.find(({ size }) => size.id === sizeId);
    setVersions(sizeExt?.versions ?? []);
  };
  React.useEffect(() => {
    if (open) form.resetFields();
  }, [open]);
  const onFinish = (values: NewSampleData) => {
    setWait(true);
    onSubmit(values)
      .then(close)
      .finally(() => {
        setWait(false);
      })
      .catch(onError);
  };
  return (
    <ModalPlm
      title="New sample"
      open={open}
      onCancel={close}
      okButtonProps={{ htmlType: "submit", loading: wait }}
      okText={t("Add")}
      modalRender={(content) => (
        <Form
          form={form}
          layout="vertical"
          onValuesChange={({ sizeId }) => {
            if (sizeId) updateVersions(sizeId);
          }}
          onFinish={onFinish}
        >
          {content}
        </Form>
      )}
    >
      <VerticalLayout>
        <Form.Item
          name={field("sizeId")}
          label="Size"
          rules={[{ required: true }]}
        >
          <Select
            options={sizes}
            fieldNames={{ value: "id", label: "name" }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name={field("versionId")}
          label="Version"
          rules={[{ required: true }]}
        >
          <Select
            options={versions}
            fieldNames={{ value: "id", label: "name" }}
            allowClear
          />
        </Form.Item>
      </VerticalLayout>
    </ModalPlm>
  );
};
