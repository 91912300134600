import * as React from "react";
import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { buildMainBlock } from "src/pages/EntityCardPage/blockBuilder/buildMainBlock";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZAttribute } from "src/types/ZAttribute";
import {
  FormBlockDef,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { ZGroup } from "src/types/ZGroup";
import { findNodeByKey } from "../../../../common/findNodeByKey";
import { loadObjects } from "../../objectsApi";
import { activateValueNodeO2, createObjNode2 } from "../utils/createMainTree";
import { CommonNodeO2, GroupO2, ObjectO2 } from "../Obj2Nodes";
import { splitOrders } from "./splitOrders";
import { apiGroupOrders, apiObjectOrders, apiValueOrders } from "./apiOrders";
import { findNodeOwnerByKey } from "../../../../common/findNodeOwnerByKey";

type Level = {
  levelNodes: CommonNodeO2[];
  index: number;
  node: CommonNodeO2;
  owner: CommonNodeO2 | null;
};

type DragDropResult = {
  dstOwnerKey: React.Key;
  dstLevel: CommonNodeO2[];
  dstIndex: number;
  srcOwnerKey: React.Key;
  srcLevel: CommonNodeO2[];
  srcIndex: number;
};

type SrcObject = {
  key: React.Key;
  id: number;
};

export class Obj2OrdersStore {
  constructor() {
    this.formStore = new FormWithBlockStore();
    makeAutoObservable(this);
  }

  formStore: FormWithBlockStore;

  typesMap: Record<number, string> = {};

  buzy = false;

  setBuzy(flag: boolean) {
    this.buzy = flag;
  }

  async init(objNode: ObjectO2, typesMap: Record<number, string>) {
    this.typesMap = typesMap;
    try {
      this.setBuzy(true);
      this.setSrcObject({
        key: objNode.key,
        id: objNode.object.id,
      });
      this.setTreeData([]);
      const srcObjects = await loadObjects();
      const srcObj = srcObjects.find(({ id }) => objNode.object.id === id);
      if (!srcObj) throw Error("Объект более не существует");
      const dstObjNode = createObjNode2(srcObj);
      this.setTreeData(dstObjNode.children ?? []);
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  }

  srcObject: SrcObject | null = null;

  setSrcObject(def: SrcObject) {
    this.srcObject = def;
  }

  async onExpand(srcNode: CommonNodeO2, expanded: boolean) {
    try {
      const dstNode = findNodeByKey(srcNode.key, this.treeData);
      if (expanded && dstNode) {
        if (dstNode.type === "group") {
          await dstNode.onExpand?.(dstNode);
          this.refreshTree();
        } else if (dstNode.type === "value") {
          await activateValueNodeO2(dstNode);
          this.refreshTree();
        }
      }
    } catch (e) {
      onError(e);
    }
  }

  treeData: CommonNodeO2[] = [];

  setTreeData(data: CommonNodeO2[]) {
    this.treeData = data;
  }

  refreshTree() {
    this.setTreeData([...this.treeData]);
  }

  msg: string = "";

  setMsg(s: string) {
    this.msg = s;
  }

  updateIsLeaf(nodeOwnerKey: React.Key) {
    const node = findNodeByKey(nodeOwnerKey, this.treeData);
    if (node && node.type === "group") {
      const { children } = node;
      node.isLeaf = children?.length === 0;
    }
  }

  async finish(
    syncWithMainTree: (ownerKey: React.Key, order: React.Key[]) => void,
  ) {
    if (!this.dragDropRes) return;
    try {
      this.setBuzy(true);
      const {
        srcIndex,
        dstIndex,
        srcOwnerKey,
        dstOwnerKey,
        srcLevel,
        dstLevel,
      } = this.dragDropRes;
      const srcNode = srcLevel[srcIndex];
      if (!srcNode) return;

      if (srcIndex < dstIndex) {
        dstLevel.splice(dstIndex, 0, srcNode);
        srcLevel.splice(srcIndex, 1);
      } else {
        srcLevel.splice(srcIndex, 1);
        dstLevel.splice(dstIndex, 0, srcNode);
      }
      this.updateIsLeaf(dstOwnerKey);
      this.updateIsLeaf(srcOwnerKey);

      const movedKey = dstOwnerKey !== srcOwnerKey ? srcNode.key : undefined;
      await this.saveOrders(dstLevel, dstOwnerKey, movedKey);

      this.refreshTree();
      syncWithMainTree(
        dstOwnerKey,
        dstLevel.map(({ key }) => key),
      );
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  }

  async saveOrders(
    nodes: CommonNodeO2[],
    ownerKey: React.Key,
    movedKey?: React.Key,
  ) {
    const data = splitOrders(nodes, movedKey);
    if (ownerKey === this.srcObject?.key) {
      const objectId = this.srcObject?.id;
      if (!objectId) throw Error("Отсутствует id объекта");
      await apiObjectOrders(objectId, data);
    } else {
      const owner = findNodeByKey(ownerKey, this.treeData);
      if (!owner) throw Error(`Не найден узел ${ownerKey}`);
      if (owner.type === "group") {
        await apiGroupOrders(owner.group.id, data);
      } else if (owner.type === "value") {
        const valueOwner = findNodeOwnerByKey(owner.key, this.treeData);
        if (!valueOwner)
          throw Error(`Не найдена группа для значения "${owner.value.name}"`);
        if (valueOwner.owner.type !== "group") {
          throw Error(
            `Значение "${owner.value.name}" должно входить в группу, а не в "${valueOwner.owner.type}"`,
          );
        }
        await apiValueOrders(valueOwner.owner.group.id, owner.value.id, data);
      } else {
        throw Error(`Нет функции для сохранения порядка типа ${owner.type}`);
      }
    }
  }

  dragDropRes: DragDropResult | null = null;

  setDragDropRes(res: DragDropResult | null) {
    this.dragDropRes = res;
  }

  allowDrop(
    srcNodeKey: React.Key,
    dstNodeKey: React.Key,
    pos: number,
  ): boolean {
    // Если pos < 0, это значит что src позиционируется перед drop. Иначе - после
    // мы имеем смесь атрибутов и групп на разных уровнях
    // нужно отличать одно от другого.
    // Предполагается, что первыми идут все атрибуты а дальше - все группы
    this.setDragDropRes(null);
    const srcRes = findLevel(srcNodeKey, this.treeData, null);
    if (!srcRes) return false;
    const {
      levelNodes: srcLevelNodes,
      node: srcNode,
      owner: srcOwner,
      index: srcIndex,
    } = srcRes;
    const srcOwnerKey = srcOwner?.key ?? this.srcObject?.key;

    const dstRes = findLevel(dstNodeKey, this.treeData, null);
    if (!dstRes) return false;
    const {
      levelNodes: dstLevelNodes,
      node: dstNode,
      owner: dstOwner,
      index: dstIndex,
    } = dstRes;
    const dstOwnerKey = dstOwner?.key ?? this.srcObject?.key;

    if (!srcOwnerKey || !dstOwnerKey) return false;

    const srcResult = { srcOwnerKey, srcIndex, srcLevel: srcLevelNodes };

    if (srcNode.type === "attr" && dstNode.type === "attr") {
      this.setDragDropRes({
        ...srcResult,
        dstOwnerKey,
        dstLevel: dstLevelNodes,
        dstIndex: dstIndex + (pos < 0 ? 0 : 1),
      });
      return true;
    }

    if (srcNode.type === "group" && dstNode.type === "attr") {
      if (dstLevelNodes[dstIndex + 1]?.type === "group") {
        this.setDragDropRes({
          ...srcResult,
          dstOwnerKey,
          dstLevel: dstLevelNodes,
          dstIndex: dstIndex + 1,
        });
        return true;
      }
    }

    if (srcNode.type === "group" && dstNode.type === "group") {
      this.setDragDropRes({
        ...srcResult,
        dstOwnerKey: pos === 0 ? dstNode.key : dstOwnerKey,
        dstLevel: pos === 0 ? dstNode.children! : dstLevelNodes,
        dstIndex:
          pos === 0 ? dstNode.children!.length : dstIndex + (pos < 0 ? 0 : 1),
      });
      return true;
    }

    if (srcNode.type === "attr" && dstNode.type === "group") {
      if (pos === 0) {
        this.setDragDropRes({
          ...srcResult,
          dstOwnerKey: dstNode.key,
          dstLevel: dstNode.children!,
          dstIndex: 0,
        });
        return true;
      }
    }
    return false;
  }

  get rootBlock(): FormBlockDef | null {
    const attributes: ZAttribute[] = [];
    const groups: ZGroup[] = [];
    const createGroup = (node: GroupO2): ZGroup => ({
      ...node.group,
      attributes: node.children?.reduce(
        (acc, subNode) =>
          subNode.type === "attr" ? [...acc, subNode.attr] : acc,
        [] as ZAttribute[],
      ),
      groups: node.children?.reduce(
        (acc, subNode) =>
          subNode.type === "group" ? [...acc, createGroup(subNode)] : acc,
        [] as ZGroup[],
      ),
    });
    this.treeData.forEach((node) => {
      if (node.type === "attr") {
        attributes.push(node.attr);
      } else if (node.type === "group") {
        groups.push(createGroup(node));
      }
    });
    if (attributes.length === 0 && groups.length === 0) return null;
    const obj: ZObjectItem = {
      id: 1,
      name: "Test",
      attributes,
      groups,
    };
    return buildMainBlock(obj, {
      typesMap: this.typesMap,
      canUpdate: false,
    });
  }
}

const findLevel = (
  needKey: React.Key,
  levelNodes: CommonNodeO2[],
  owner: CommonNodeO2 | null,
): Level | undefined => {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < levelNodes.length; index++) {
    const node = levelNodes[index];
    if (node) {
      if (node.key === needKey)
        return {
          levelNodes,
          index,
          node,
          owner,
        };
      if (node.children) {
        const res = findLevel(needKey, node.children, node);
        if (res) return res;
      }
    }
  }
  return undefined;
};
