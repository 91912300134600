import { PageUrl } from "src/routes/PageUrl";
import { z } from "zod";

export const viewType = [
  "bigGroup",
  "bigLink",
  "smallGroup",
  "smallLink",
] as const;
export const zViewType = z.enum(viewType);
export type ZViewType = z.infer<typeof zViewType>;

export const iconName = [
  "home",
  "plans",
  "models",
  "folder",
  "report",
  "settings",
  "calendar",
] as const;
export const zIconName = z.enum(iconName);
export type ZIconName = z.infer<typeof zIconName>;

export const zTextMenuItem = z.object({
  type: z.literal("text"),
});

export const zObjectMenuLink = z.object({
  type: z.literal("object"),
  objectId: z.number(),
});

export const internalMenuLinkUrls = Object.values(PageUrl).filter(
  (url) => !url.includes(":"),
) as [string, ...string[]];

export const zInternalMenuLink = z.object({
  type: z.literal("internal"),
  url: z.enum(internalMenuLinkUrls),
});

export const zExternalMenuLink = z.object({
  type: z.literal("external"),
  href: z.string().regex(/^https?:\/\//),
});

export const zMenuLink = z.discriminatedUnion("type", [
  zTextMenuItem,
  zObjectMenuLink,
  zInternalMenuLink,
  zExternalMenuLink,
]);
export type ZMenuLink = z.infer<typeof zMenuLink>;

export interface ZMenuItem {
  viewType: ZViewType;
  name: string;
  id: number;
  iconName?: ZIconName;
  link: ZMenuLink;
  elements?: ZMenuItem[];
  roleIds?: number[];
}

export const zMenuItemLight = z.object({
  viewType: zViewType,
  name: z.string(),
  id: z.number(),
  link: zMenuLink,
  iconName: zIconName.optional(),
  roleIds: z.number().array().optional(),
});
export type ZMenuItemLight = z.infer<typeof zMenuItemLight>;

export const zMenuItem: z.ZodType<ZMenuItem> = z.lazy(() =>
  z
    .object({
      elements: zMenuItem.array().optional(),
    })
    .merge(zMenuItemLight),
);
