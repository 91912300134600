import * as React from "react";
import { TableStore } from "src/components/tables/TableStore";
import { Table, TablePaginationConfig, TableProps } from "antd";
import {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { classNames } from "src/common/classNames";
import { ifDef } from "src/common/ifDef";
import styles from "./AsyncTable.module.less";
import { SortableHeader } from "./SortableHeader";
import { AColumn } from "./types";
import { onTableParamsChange } from "./onTableParamsChange";

interface PropsAsyncTable<TRow, TFilters extends object> {
  store: TableStore<TRow, TFilters>;
  columns: AColumn<TRow>[];
  initialColumns?: AColumn<TRow>[]; // Используются в случае динамического изменения columns
  usePagination?: boolean;
  rowClassName?: (row: TRow, index: number) => string;
  onRowClick?(row: TRow, index?: number): void;
  size?: SizeType;
  scroll?: TableProps<TRow>["scroll"] & {
    scrollToFirstRowOnChange?: boolean;
  };
}

export const AsyncTable = observer(
  <TRow extends {}, TFilters extends object>(
    props: PropsAsyncTable<TRow, TFilters>,
  ): React.ReactElement => {
    const {
      store,
      scroll,
      columns,
      initialColumns,
      usePagination,
      onRowClick,
      rowClassName,
      size,
    } = props;

    React.useEffect(() => {
      store.init(initialColumns ?? columns);
    }, [store]);

    const onChange = (
      pagination: TablePaginationConfig,
      filters?: Record<string, FilterValue | null>,
      sorter?: SorterResult<TRow> | SorterResult<TRow>[],
    ) => {
      onTableParamsChange(store, pagination, sorter);
    };

    const { rowKey } = store;

    const { selectionType } = store.selectionSettings;
    const selection: TableRowSelection<TRow> | undefined = selectionType
      ? {
          type: selectionType,
          onChange: (keys, rows) => {
            store.safeSelect(rows);
          },
          selectedRowKeys: [
            ...store.selected.map((row) => row[rowKey] as React.Key),
          ],
          getCheckboxProps: ifDef(
            store.getDisabledSelection(rowKey),
            (fn) => (row: TRow) => {
              const disabled = fn(row);
              return { disabled, indeterminate: disabled };
            },
          ),
        }
      : undefined;

    const pagination: TablePaginationConfig | boolean = usePagination
      ? {
          current: store.page + 1,
          pageSize: store.pageSize,
          showSizeChanger: true,
          total: store.totalItems,
          position: ["bottomCenter"],
        }
      : false;

    const classes: string[] = [];
    if (onRowClick) classes.push(styles.rowsClickable);

    return (
      <Table<TRow>
        scroll={scroll}
        className={classes.join(" ")}
        columns={store.finalColumns}
        dataSource={store.result.rows}
        rowKey={String(rowKey)}
        rowClassName={rowClassName}
        loading={store.loading}
        onChange={onChange}
        pagination={pagination}
        rowSelection={selection}
        onRow={(row, index) => ({
          onClick: () => onRowClick?.(row, index),
        })}
        components={{
          header: {
            cell: CustomHeaderCell,
          },
        }}
        size={size}
      />
    );
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomHeaderCell = (props: any) => {
  const { children, className, ...rest } = props;
  const ariaSort: "ascending" | "descending" | undefined = rest["aria-sort"];
  const showSortable: boolean = !ariaSort && !!rest["aria-label"];
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <th className={classNames([className, styles.hd])} {...rest}>
      <SortableHeader
        header={children}
        sort={showSortable ? "sortable" : ariaSort}
      />
    </th>
  );
};
