import * as React from "react";
import { Breadcrumb, appStore } from "src/appStore";
import { getGlobalParams } from "src/common/globalParams";
import styles from "./PageLayout.module.less";

export type PropsPageLayout = {
  children?: React.ReactNode;
  pageTitle?: React.ReactNode;
  header?: React.ReactNode;
  breadcrumb?: Breadcrumb[];
  documentTitle?: string;
};

export const PageLayout: React.FC<PropsPageLayout> = ({
  pageTitle,
  header,
  children,
  breadcrumb,
  documentTitle,
}: PropsPageLayout) => {
  /**
   * Заголовок страницы выводится в области header
   * было решено передавать его в PageLayout и устанавливать тут
   */
  React.useEffect(() => {
    const globalTitle = getGlobalParams().title;
    appStore.setPageTitle(pageTitle);
    appStore.setBreadcrumb(breadcrumb ?? []);
    appStore.setDocumentTitle(
      documentTitle ? `${documentTitle} - ${globalTitle}` : globalTitle,
    );
    return () => {
      appStore.setPageTitle("");
      appStore.setBreadcrumb([]);
      appStore.setDocumentTitle(globalTitle);
    };
  }, [pageTitle, breadcrumb, documentTitle]);

  return (
    <div className={styles.pageLayout}>
      {header}
      <div className={styles.pageContent}>{children}</div>
    </div>
  );
};

PageLayout.defaultProps = {
  children: undefined,
  pageTitle: undefined,
  header: undefined,
  breadcrumb: [],
  documentTitle: "",
};
