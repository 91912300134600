import React, { useEffect, useMemo } from "react";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { Tabs, TabsProps, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { appStore } from "src/appStore";
import { t } from "i18next";
import styles from "./ChatListPage.module.less";
import { ChatListStore } from "./ChatListStore";
import { ChatForm } from "./ChatForm/ChatForm";
import { NewChatButton } from "./NewChatButton/NewChatButton";
import { ChatSettingsMenu } from "./ChatForm/chatSettings/ChatSettingsMenu";
import { ZChatInfo } from "./bsChat.types";

const isChatOwner = (userId: string) =>
  appStore.userData.status === "ready" &&
  appStore.userData.result.id === userId;

type PropsChatList = { info: ObjectServiceExt; data: EntityCardData };

export const ChatList: React.FC<PropsChatList> = observer(({ data, info }) => {
  const { entity } = data;
  const store = useMemo(() => new ChatListStore(), []);
  const { activeChatId: activeChatid } = store;

  useEffect(() => {
    if (entity) store.init(entity.id, info.name, data);
    return () => store.destroy();
  }, []);

  const isDefaultchat = (ci: ZChatInfo | null) => ci?.name === info.name;

  // сортируем, чтобы дефолтный чат выводился первым
  const sortedChats = store.chats
    .slice()
    .sort((chat) => (isDefaultchat(chat.chatInfo) ? -1 : 1));

  const items: TabsProps["items"] = sortedChats.map((item) => ({
    key: item.chatId,
    label: item.chatName,
  }));

  const chatStore = sortedChats.find((c) => c.chatId === activeChatid);

  return (
    <div className={styles.layout}>
      <div className={styles.sider}>
        <Typography.Text strong>{t("Chat list")}</Typography.Text>
        <Tabs
          tabPosition="left"
          items={items}
          onChange={(id) => store.setActiveChatId(id)}
          activeKey={store.activeChatId ?? undefined}
        />
        <NewChatButton cardData={data} chatListStore={store} />
      </div>
      <div className={styles.content}>
        {chatStore?.chatInfo && (
          <ChatForm
            key={chatStore.chatId}
            store={chatStore}
            chatListStore={store}
            headerExtra={
              !isDefaultchat(chatStore?.chatInfo) &&
              isChatOwner(chatStore.chatInfo.ownerUserId) && (
                <ChatSettingsMenu cardData={data} chatListStore={store} />
              )
            }
          />
        )}
      </div>
    </div>
  );
});
