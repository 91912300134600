import { ItemProps, blockItem } from "src/components/FormWithBlocks";
import {
  DatePickerString,
  PropsDatePickerString,
} from "src/components/DatePickerString";

export const stdDatePicker = (
  name: string,
  label: string,
  itemProps: ItemProps,
  compProps?: PropsDatePickerString,
) =>
  blockItem(
    name,
    label,
    DatePickerString,
    { allowClear: true, style: { width: "100%" }, ...compProps },
    itemProps,
  );
