// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-DashboardTab-DashboardFields-DashboardFields-module__fieldsBox--taJJF {
  width: 880px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.src-pages-ManagementPage-DashboardTab-DashboardFields-DashboardFields-module__fieldsBox--taJJF h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.src-pages-ManagementPage-DashboardTab-DashboardFields-DashboardFields-module__itemHeader--hEkzt {
  display: grid;
  grid-template-columns: 1fr auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/DashboardTab/DashboardFields/DashboardFields.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EAEA,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AALA;EAQQ,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAR;AAGA;EACI,aAAA;EACA,+BAAA;AADJ","sourcesContent":[".fieldsBox {\n    width: 880px;\n\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n\n    h2 {\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 150%;\n    }\n}\n.itemHeader {\n    display: grid;\n    grid-template-columns: 1fr auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsBox": `src-pages-ManagementPage-DashboardTab-DashboardFields-DashboardFields-module__fieldsBox--taJJF`,
	"itemHeader": `src-pages-ManagementPage-DashboardTab-DashboardFields-DashboardFields-module__itemHeader--hEkzt`
};
export default ___CSS_LOADER_EXPORT___;
