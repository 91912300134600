import * as React from "react";
import { classNames } from "src/common/classNames";
import styles from "./EntityGroupBlock.module.less";

interface PropsEntityGroupInnerBlock {
  title?: string;
  children?: React.ReactNode;
  id?: string;
  monoColumn?: boolean;
}

export const EntityGroupInnerBlock: React.FC<PropsEntityGroupInnerBlock> = ({
  title,
  children,
  id,
  monoColumn,
}) => (
  <div className={styles.block} id={id}>
    {!!title && <h2 className={styles.title}>{title}</h2>}
    <div className={classNames([[!monoColumn, styles.items]])}>{children}</div>
  </div>
);

EntityGroupInnerBlock.defaultProps = {
  title: undefined,
  children: undefined,
  id: undefined,
  monoColumn: false,
};
