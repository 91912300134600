import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { onError } from "src/common/onError";
import { ifDef } from "src/common/ifDef";
import { loadRefDict } from "src/common/loadRefDict";
import { ZAttributeValue } from "src/types/ZAttribute";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { SheetStore } from "src/components/Sheet/SheetStore";
import { RowSelectionType } from "antd/es/table/interface";
import { TableStore } from "src/components/tables/TableStore";
import {
  loadFromAttr,
  loadObjectAttrinbutesAll,
} from "src/pages/ManagementPage/objectsApi";
import { ZIdName } from "src/types/ZIdName";
import { t } from "i18next";
import { loadEntity, saveEntity } from "src/pages/EntityCardPage/apiEntityCard";
import {
  AttrImageCarouselProps,
  makeImageCarouselProps,
} from "src/common/attrEdit/components";
import { getEditorInfo } from "src/common/attrEdit";
import { rest } from "src/common/rest";
import { apiMeasurementChart } from "src/common/apiUrl";
import { ZMesChartSettings } from "../ZMesChartSettings";
import {
  copyMeasurementChart,
  createMeasurementChart,
  deleteMeasurementChart,
  loadBaseSizes,
  loadEntitiesAsRef,
  loadMeasurementChart,
  loadMesChartSettings,
  saveMcPointValue,
  saveMcPontField,
  deleteMcRows,
  addMcVersionToSize,
  deleteMcVersion,
  createNewMcRows,
  scaleMeasurementChart,
} from "../apiMeasurementChart";
import {
  NewMeasurementChartEntityData,
  ZMCSizeColumn,
  ZMCRow,
  ZMeasurementChart,
  ZMeasurementChartEntity,
  ZMCPointValue,
  ZMCPoint,
  ZMCSize,
} from "../ZMeasurementChart";
import { MCColumn, mkKey } from "./EditMCEntity/MCColumn";
import { buildColumns } from "./EditMCEntity/MChartTable/buildColumns";
import { NumOption, PointOpt } from "./EditMCEntity/MChartTable/PointDict";
import { loadPointsTableData } from "./loadPointsTableData";
import { FilterMcPoints } from "./EditMCEntity/PointSelectModal";

type MChartData = {
  settings: ZMesChartSettings;
  mc: ZMeasurementChart;
};

export const mChartEntityStore = makeAutoObservable({
  data: { status: "none" } as RemoteData<MChartData>,
  setData(newData: RemoteData<MChartData>) {
    this.data = newData;
  },
  serviceObjectId: 0,
  modelId: 0,

  async init(serviceObjectId: number, modelId: number) {
    this.serviceObjectId = serviceObjectId;
    this.modelId = modelId;
    this.selected.clear();
    this.hiddenColumns.clear();
    this.setSheetStore(new SheetStore());
    try {
      this.setData({ status: "wait" });
      const settings = await loadMesChartSettings(serviceObjectId);
      await Promise.all([
        this.loadSizeLineOptions(settings),
        this.loadVersions(settings),
        this.loadPointCategories(settings),
      ]);
      const mc = await loadMeasurementChart(modelId);
      this.setData({
        status: "ready",
        result: { settings, mc },
      });
      await this.updateBaseSizeOptions(
        mc.measurementChartEntityDto?.sizeLineEntityId ?? null,
      );
    } catch (error) {
      this.setData({ status: "error", error });
    }
  },

  /**
   * Получить список изображений (uid) для текущего MC
   * @returns
   */
  async getImages(): Promise<{
    images: string[] | null;
    props: AttrImageCarouselProps;
  }> {
    const { data } = this;
    if (data.status !== "ready") throw Error("Data not loaded");
    const { settings, mc } = data.result;
    // Изображения лежат в экземпляре объекта Measurement Chart, атрибут указан в settings как mcImageAttrId
    const mcEntityId = mc.measurementChartEntityDto?.mcEntityId;
    if (!mcEntityId) throw Error("Empty measurementChartEntityDto");
    const { mcImageAttrId, mcObjectId } = settings;
    if (!mcObjectId) throw Error("Empty mcObjectId");
    //
    const mcEntity = await loadEntity(mcEntityId);
    const mcImageAttr = mcEntity.attributeValues.find(
      ({ attributeId }) => mcImageAttrId === attributeId,
    );
    // Для компонента ImageCarousel нужно загрузить свойства из атрибута
    const attrs = await loadObjectAttrinbutesAll(mcObjectId);
    const mcItamgAttrDef = attrs.find(({ id }) => id === mcImageAttrId);
    if (!mcItamgAttrDef) throw Error("Not found image attr in MC object");
    const edInfo = getEditorInfo(mcItamgAttrDef.viewStyles);
    let props: AttrImageCarouselProps = {};
    if (edInfo?.component?.editor === "ImageCarousel") {
      props = makeImageCarouselProps(edInfo.component);
    }

    return {
      images: mcImageAttr?.values ?? null,
      props,
    };
  },

  async saveImages(images: string[]): Promise<string[]> {
    const { data } = this;
    if (data.status !== "ready") throw Error("Data not loaded");
    const { settings, mc } = data.result;
    const { mcImageAttrId } = settings;
    if (!mcImageAttrId) throw Error("mcImageAttrId not defined");
    const mcEntityId = mc.measurementChartEntityDto?.mcEntityId;
    if (!mcEntityId) throw Error("Empty measurementChartEntityDto");
    const oldEntity = await loadEntity(mcEntityId);
    const newEntity = await saveEntity({
      ...oldEntity,
      attributeValues: [
        ...oldEntity.attributeValues.filter(
          ({ attributeId }) => attributeId !== mcImageAttrId,
        ),
        {
          attributeId: mcImageAttrId,
          values: images,
        },
      ],
    });
    // Чтобы было полноценное изображение с url, а не с thumbUrl, надо возвращать значения сохраненного экземпляра
    const newImages = newEntity.attributeValues.find(
      ({ attributeId }) => attributeId === mcImageAttrId,
    )?.values;
    return newImages || [];
  },

  // sizeLineOptions
  sizeLineOptions: [] as NumOption[],
  setSizeLineOptions(list: NumOption[]) {
    this.sizeLineOptions = list;
  },
  async loadSizeLineOptions(settings: ZMesChartSettings) {
    const { sizeLinesObjectId, sizeLinesNameAttrId } = settings;
    if (!sizeLinesObjectId)
      throw Error(t("Missing value", { parameter: "sizeLinesObjectId" }));
    if (!sizeLinesNameAttrId)
      throw Error(t("Missing value", { parameter: "sizeLinesNameAttrId" }));

    this.setSizeLineOptions(
      await loadNumOptions(sizeLinesObjectId, sizeLinesNameAttrId, "SizeLines"),
    );
  },
  getMCEntityValue<K extends keyof ZMeasurementChartEntity>(
    name: K,
  ): ZMeasurementChartEntity[K] | undefined {
    return this.data.status === "ready"
      ? this.data.result.mc.measurementChartEntityDto?.[name]
      : undefined;
  },
  get curSizeLineValue(): number | undefined {
    return this.getMCEntityValue("sizeLineEntityId");
  },
  get curSizeLineName(): string {
    return (
      ifDef(
        this.getMCEntityValue("sizeLineEntityId"),
        (id) => this.sizeLineOptions.find(({ value }) => value === id)?.label,
      ) ?? "-"
    );
  },
  async changeSizeLine(sizeLineId: number) {
    try {
      this.setBuzy("sizeLine");
      await rest.put(
        apiMeasurementChart(`/entity/${this.modelId}/size-line`),
        undefined,
        { params: { sizeLineId } },
      );
      await this.reloadMc();
      await this.updateBaseSizeOptions(sizeLineId);
    } finally {
      this.setBuzy(false);
    }
  },

  // baseSize options
  baseSizeOptions: [] as NumOption[],
  setBaseSizeOptions(list: NumOption[]) {
    this.baseSizeOptions = list;
  },
  loadingBaseSize: false,
  setLoadingBaseSize(value: boolean) {
    this.loadingBaseSize = value;
  },
  async updateBaseSizeOptions(sizeId: number | null) {
    this.setBaseSizeOptions([]);
    if (sizeId) {
      try {
        this.setLoadingBaseSize(true);
        const list = await loadBaseSizes(this.serviceObjectId, sizeId);
        this.setBaseSizeOptions(list);
      } catch (e) {
        onError(e);
      } finally {
        this.setLoadingBaseSize(false);
      }
    }
  },
  get curBaseSizeValue(): number | undefined {
    return this.getMCEntityValue("baseSizeEntityId");
  },
  get curBaseSizeName(): string {
    return (
      ifDef(
        this.getMCEntityValue("baseSizeEntityId"),
        (id) => this.baseSizeOptions.find(({ value }) => value === id)?.label,
      ) ?? "-"
    );
  },
  async changeBaseSize(baseSizeId: number) {
    try {
      this.setBuzy("baseSize");
      await rest.put(
        apiMeasurementChart(`/entity/${this.modelId}/base-size`),
        undefined,
        { params: { baseSizeId } },
      );
      await this.reloadMc();
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  },

  async reloadMc(options?: { baseSize?: boolean }) {
    const { data } = this;
    if (data.status === "ready") {
      const { result } = data;
      const mc = await loadMeasurementChart(this.modelId);
      result.mc = mc;
      this.setData({ status: "ready", result });
      if (options?.baseSize) {
        await this.updateBaseSizeOptions(
          mc.measurementChartEntityDto?.sizeLineEntityId ?? null,
        );
      }
    }
  },

  // Versions
  verList: [] as ZAttributeValue[],
  setVerList(list: ZAttributeValue[]) {
    this.verList = list;
  },
  get verOptions(): NumOption[] {
    return this.verList.map(({ id, value }) => ({
      value: id,
      label: value || String(id),
    }));
  },
  get verDict() {
    const dict: Record<number, string> = {};
    this.verOptions.forEach(({ value, label }) => {
      dict[value] = label;
    });
    return dict;
  },
  async loadVersions(settings: ZMesChartSettings) {
    const { versionDictionaryNameAttrId } = settings;
    if (!versionDictionaryNameAttrId)
      throw Error(
        t("Missing value", { parameter: "versionDictionaryNameAttrId" }),
      );
    const verList = await loadRefDict(versionDictionaryNameAttrId, {
      translate: true,
    });
    if (verList.length === 0)
      throw Error(
        t("The dictionary must contain at least one entry", {
          dict: versionDictionaryNameAttrId,
        }),
      );
    this.setVerList(verList);
  },

  // Points
  categoryList: [] as ZIdName[],
  setCategoryList(list: ZIdName[]) {
    this.categoryList = list;
  },

  createPointSelectStore(selectionType: RowSelectionType) {
    const { data } = this;
    if (data.status !== "ready") return null;
    const {
      settings: {
        pointObjectId,
        pointPointNameAttrId,
        pointDescriptionAttrId,
        mcPointCategoryAttrId,
      },
    } = data.result;
    try {
      if (!pointObjectId)
        throw Error(t("Missing value", { parameter: "pointObjectId" }));
      if (!pointPointNameAttrId)
        throw Error(t("Missing value", { parameter: "pointPointNameAttrId" }));
      if (!pointDescriptionAttrId)
        throw Error(
          t("Missing value", { parameter: "pointDescriptionAttrId" }),
        );
      if (!mcPointCategoryAttrId)
        throw Error(t("Missing value", { parameter: "mcPointCategoryAttrId" }));
      const store = new TableStore<PointOpt, FilterMcPoints>({
        rowKey: "value",
        fnLoad: loadPointsTableData(
          pointObjectId,
          pointPointNameAttrId,
          pointDescriptionAttrId,
          mcPointCategoryAttrId,
          this.categoryList,
        ),
        selectionSettings: {
          selectionType,
          keepSelected: true,
        },
      });
      return store;
    } catch (error) {
      onError(error);
      return null;
    }
  },

  async loadPointCategories(settings: ZMesChartSettings) {
    const categoriesAttrId = settings.mcPointCategoryAttrId;
    if (!categoriesAttrId)
      throw Error(t("Missing value", { parameter: "categoriesAttrId" }));
    const catList = await loadFromAttr(categoriesAttrId);
    if (catList.length === 0)
      throw Error(
        t("The dictionary must contain at least one entry", {
          dict: categoriesAttrId,
        }),
      );
    this.setCategoryList(catList);
  },

  buzy: false as BuzyType,
  setBuzy(value: BuzyType) {
    this.buzy = value;
  },

  async create(values: NewMeasurementChartEntityData) {
    try {
      this.setBuzy("create");
      const correctedValues = {
        ...values,
        entityId: this.modelId,
        mcServiceId: this.serviceObjectId,
      };
      const mc = await createMeasurementChart(correctedValues);
      this.updateMC(mc);
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  },

  async updateMC(mc: ZMeasurementChart) {
    const { data } = this;
    if (data.status !== "ready") throw Error(t("System is not ready"));
    this.setData({
      status: "ready",
      result: {
        ...data.result,
        mc,
      },
    });
  },

  async reset() {
    try {
      this.setBuzy("reset");
      await deleteMeasurementChart(this.modelId);
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
    await this.init(this.serviceObjectId, this.modelId);
  },

  get canScale(): boolean {
    return this.selected.size !== 0;
  },

  /**
   * Скейлинг производится на основе данных, которые уже сохранены,
   * поэтому тут ничего сложного нам собирать не нужно.
   */
  async scale() {
    if (this.data.status === "ready") {
      this.setBuzy("scale");
      scaleMeasurementChart(this.modelId, Array.from(this.selected.values()))
        .then((data) => this.updateMC(data))
        .finally(() => this.setBuzy(false))
        .catch(onError);
    }
  },

  get columns(): MCColumn[] {
    return buildColumns(this, this.hiddenColumns);
  },
  hiddenColumns: new Set<string>(),
  toggleColumnVisibility(key: string) {
    const { hiddenColumns } = this;
    if (hiddenColumns.has(key)) {
      hiddenColumns.delete(key);
    } else {
      hiddenColumns.add(key);
    }
  },

  clearHiddenColumns() {
    this.hiddenColumns.clear();
  },
  get sizeColumns(): ZMCSizeColumn[] {
    if (this.data.status === "ready") {
      const { mc } = this.data.result;
      if (mc.columns) return mc.columns;
    }
    return [];
  },
  //
  get sizeVersions() {
    // Size Name => Version name[]
    const dict: Record<string, Set<string>> = {};
    const sizes: ZMCSizeColumn[] =
      (this.data.status === "ready" && this.data.result.mc.columns) || [];
    // Первая версия должна быть всегда
    const verA = this.verOptions[0];
    if (verA) {
      sizes.forEach((sz) => {
        dict[sz.name] = new Set([verA.label]);
      });
    }

    this.rows.forEach(({ mcPoint }) => {
      mcPoint.sizes.forEach((sz) => {
        let ver = dict[sz.name];
        if (!ver) {
          ver = new Set();
          dict[sz.name] = ver;
        }
        sz.pointValues.forEach(({ version }) => {
          ver!.add(version.name);
        });
      });
    });
    return dict;
  },
  get sizeVersionsList() {
    const list: [string, Set<string>][] = Object.entries(this.sizeVersions);
    return list.flatMap(([szName, verSet]) =>
      Array.from(verSet).map((verName) => ({
        key: mkKey(szName, verName),
        szName,
        verName,
      })),
    );
  },
  findNextVersion(sizeName: string): NumOption | undefined {
    const existNames: Set<string> = this.sizeVersions[sizeName] ?? new Set();
    return this.verOptions.find(({ label }) => !existNames.has(label));
  },
  canAddVersion(sizeName: string): boolean {
    if (this.rows.length === 0) return false;
    return !!this.findNextVersion(sizeName);
  },
  async addVersion(sizeName: string) {
    const sizeCol = this.sizeColumns.find(({ name }) => name === sizeName);
    const nextVerOpt = this.findNextVersion(sizeName);
    if (sizeCol && nextVerOpt && this.data.status === "ready") {
      try {
        this.setBuzy("addVersion");
        const pointValue = {
          version: {
            id: nextVerOpt.value,
            name: nextVerOpt.label,
          },
          value: 0,
        } satisfies Omit<ZMCPointValue, "id">;
        await addMcVersionToSize(this.modelId, sizeCol.id, pointValue);
        await this.reloadMc();
      } catch (e) {
        onError(e);
      } finally {
        this.setBuzy(false);
      }
    }
  },
  canDeleteVersion(sizeName: string, verName: string): boolean {
    const verList: string[] =
      ifDef(this.sizeVersions[sizeName], (verSet) => Array.from(verSet)) ?? [];
    return verList.length > 1 && verList[verList.length - 1] === verName;
  },
  async deleteVersion(sizeName: string, verName: string) {
    const { data } = this;
    if (data.status === "ready") {
      // TODO: очевидно, это бага бэкенда. Удаление работает неправильно. И параметры нужны друние
      const findPointValue = (): ZMCPointValue | undefined => {
        const row = data.result.mc.tableDto?.rows[0];
        if (!row) return undefined;
        const size = row.mcPoint.sizes.find(({ name }) => name === sizeName);
        if (!size) return undefined;
        return size.pointValues.find(({ version }) => version.name === verName);
      };
      const pointValue = findPointValue();
      if (!pointValue) return;
      try {
        await deleteMcVersion(this.modelId, pointValue);
        await this.reloadMc();
      } catch (e) {
        onError(e);
      } finally {
        this.setBuzy(false);
      }
    }
  },
  get templateColumns(): string {
    return this.columns.map(({ width }) => width).join(" ");
  },
  get mcData(): ZMeasurementChart | null {
    return this.data.status === "ready" ? this.data.result.mc : null;
  },
  get rows(): ZMCRow[] {
    if (this.data.status === "ready") {
      const rows = this.data.result.mc.tableDto?.rows;
      if (rows) return rows;
    }
    return [];
  },
  async createRows(points: PointOpt[]) {
    try {
      this.setBuzy("createRow");
      await createNewMcRows(
        this.modelId,
        points.map(({ value, label, desc }) => ({
          pointDto: {
            id: value,
            name: label,
          },
          description: desc,
        })),
      );
      await this.reloadMc();
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  },
  selected: new Set<number>(),
  toggleSelect(id: number) {
    const { selected } = this;
    if (selected.has(id)) {
      selected.delete(id);
    } else {
      selected.add(id);
    }
  },
  get selectStatus(): "all" | "none" | "partial" {
    const { selected } = this;
    if (selected.size === 0) return "none";
    if (selected.size === this.rows.length) return "all";
    return "partial";
  },
  toggleSelectAll() {
    if (this.selectStatus === "all") {
      this.selected.clear();
    } else {
      this.rows.forEach(({ mcPoint }) => this.selected.add(mcPoint.id));
    }
  },
  deleteSelected() {
    if (this.data.status === "ready") {
      const { tableDto } = this.data.result.mc;
      if (tableDto) {
        const isGoodRow = ({ mcPoint }: ZMCRow) =>
          !this.selected.has(mcPoint.id);
        const badRows = tableDto.rows.filter((row) => !isGoodRow(row));
        const goodRows = tableDto.rows.filter(isGoodRow);
        this.setBuzy("deleteRow");
        deleteMcRows(this.modelId, badRows)
          .then(() => {
            tableDto.rows = goodRows;
          })
          .finally(() => this.setBuzy(false))
          .catch(onError);
      }
    }
    this.selected.clear();
  },

  templateTable: { status: "none" } as RemoteData<EntityFiltersPageStore>,
  setTemplateTable(newTable: RemoteData<EntityFiltersPageStore>) {
    this.templateTable = newTable;
  },
  async initTemplateTable() {
    try {
      this.setTemplateTable({ status: "wait" });
      const { data } = this;
      if (data.status !== "ready") throw Error(t("System is not ready"));
      const { settings } = data.result;
      const { templateObjectId } = settings;
      if (!templateObjectId)
        throw Error(t("Is not selected", { parameter: "Template object" }));
      const result = new EntityFiltersPageStore({
        objectId: templateObjectId,
        selectionSettings: { selectionType: "radio" },
        actions: new Set(),
        onRowClick: (row) => {
          result.tableStore?.safeSelect([row]);
        },
      });
      this.setTemplateTable({ status: "ready", result });
      // Если не вызывать init, то таблица рисуется только при первом появлении. А если переключиться и вернуться, то нет.
      result.init(templateObjectId).catch(onError);
    } catch (error) {
      this.setTemplateTable({ status: "error", error });
    }
  },

  table: { status: "none" } as RemoteData<EntityFiltersPageStore>,
  setTable(newTable: RemoteData<EntityFiltersPageStore>) {
    this.table = newTable;
  },
  async initTable() {
    try {
      this.setTable({ status: "wait" });
      const { data } = this;
      if (data.status !== "ready") throw Error(t("System is not ready"));
      const { settings } = data.result;
      const { objectId } = settings;
      const result = new EntityFiltersPageStore({
        objectId,
        selectionSettings: { selectionType: "radio" },
        actions: new Set(),
        onRowClick: (row) => {
          result.tableStore?.safeSelect([row]);
        },
      });
      this.setTable({ status: "ready", result });
      // Если не вызывать init, то таблица рисуется только при первом появлении. А если переключиться и вернуться, то нет.
      result.init(objectId).catch(onError);
    } catch (error) {
      this.setTable({ status: "error", error });
    }
  },
  async copyFrom(srcModelId: number) {
    try {
      this.setBuzy("create");
      await copyMeasurementChart(srcModelId, this.modelId);
      await this.reloadMc({ baseSize: true });
    } catch (error) {
      onError(error);
    } finally {
      this.setBuzy(false);
    }
  },
  async copyTo(): Promise<boolean> {
    try {
      this.setBuzy("copy");
      const selected =
        (this.table.status === "ready"
          ? this.table.result.tableStore?.selected
          : null) ?? [];
      const dstModel = selected[0];
      if (!dstModel)
        throw Error(t("Is not selected", { parameter: "Destination model" }));
      await copyMeasurementChart(this.modelId, dstModel.id);
      return true;
    } catch (e) {
      onError(e);
      return false;
    } finally {
      this.setBuzy(false);
    }
  },

  // http://jira.d4r.int/browse/LPLM-1976
  sheetStore: new SheetStore(),
  setSheetStore(store: SheetStore) {
    this.sheetStore = store;
  },
  getVersionValue(
    row: ZMCRow,
    szName: string,
    verName: string,
  ): number | undefined {
    const size = row.mcPoint.sizes.find(({ name }) => szName === name);
    if (!size) return undefined;
    return (
      size.pointValues.find(({ version }) => version.name === verName)?.value ??
      undefined
    );
  },
  async saveVersionValue(
    row: ZMCRow,
    szName: string,
    verName: string,
    value: number,
  ): Promise<VersionResult | undefined> {
    const size = row.mcPoint.sizes.find(({ name }) => szName === name);
    if (size) {
      const pos = size.pointValues.findIndex(
        ({ version }) => version.name === verName,
      );
      if (pos >= 0) {
        const srcPoint: ZMCPointValue = { ...size.pointValues[pos]!, value };
        const newPointValue = await saveMcPointValue(this.modelId, srcPoint);
        return { size, pos, newPointValue };
        // Если не обрабатывать onSuccess, то draft-значение будет отличаться от исходного,
        // что будет приводить к неправильному срабатыванию сохранения.
      }
    }
    return undefined;
  },
  updateVersionValue(result: VersionResult | undefined) {
    if (result) {
      const { size, pos, newPointValue } = result;
      size.pointValues[pos] = newPointValue;
    }
  },
  async savePointValue<
    Field extends keyof ZMCPoint,
    TValue extends ZMCPoint[Field],
  >(row: ZMCRow, field: Field, value: TValue): Promise<ZMCPoint> {
    const srcPoint = { ...row.mcPoint, [field]: value };
    return saveMcPontField(this.modelId, field, srcPoint);
  },
  updatePointValue<Key extends keyof ZMCPoint>(
    row: ZMCRow,
    value: ZMCPoint,
    key: Key,
  ) {
    // eslint-disable-next-line no-param-reassign
    row.mcPoint[key] = value[key];
  },

  // Показ изображений
  isImagesVisible: false,
  setImageVisible(value: boolean) {
    this.isImagesVisible = value;
  },
  imgWidth: 250,
  setImgWidth(w: number) {
    this.imgWidth = w;
  },
  imgX0: 0,
  setImgX0(x0: number) {
    this.imgX0 = x0;
  },
});
type BuzyType =
  | "addVersion"
  | "baseSize"
  | "copy"
  | "create"
  | "createRow"
  | "deleteRow"
  | "deleteVersion"
  | "reset"
  | "save"
  | "scale"
  | "sizeLine"
  | false;

export type MChartEntityStore = typeof mChartEntityStore;

export type VersionResult = {
  size: ZMCSize;
  pos: number;
  newPointValue: ZMCPointValue;
};

const loadNumOptions = async (
  objId: number,
  nameAttrId: number,
  objName: string,
): Promise<NumOption[]> => {
  const srcList = await loadEntitiesAsRef(objId);
  const dstList: NumOption[] = [];
  srcList.forEach((entity) => {
    const attr = entity.attributeValues.find(
      ({ attributeId }) => attributeId === nameAttrId,
    );
    const label = attr?.values?.[0];
    if (label) {
      dstList.push({ value: entity.id, label });
    }
  });
  if (dstList.length === 0)
    throw Error(t("Reference is empty", { object: objName }));
  return dstList;
};
