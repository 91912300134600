import { z } from "zod";

export enum ZNotifyType {
  push = 0,
  email = 1,
  all = 2,
}
export const zNotifyType = z.nativeEnum(ZNotifyType);

export const zNotifyTemplate = z.object({
  id: z.string(),
  stateId: z.number(),
  roleIds: z.number().array(),
  messageType: zNotifyType,
  templateText: z.record(z.string(), z.string()),
  active: z.boolean(),
});

export type ZNotifyTemplate = z.infer<typeof zNotifyTemplate>;

export type ZNotifyTemplateToSave = Omit<ZNotifyTemplate, "id"> & {
  id?: string;
};
