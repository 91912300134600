import * as React from "react";
import { CalendarFilled, HomeFilled } from "@ant-design/icons";
import { ZIconName } from "./ZMenuItem";
import { FolderIcon } from "./icons/FolderIcon";
import { ModelsIcon } from "./icons/ModelsIcon";
import { PlansIcon } from "./icons/PlansIcon";
import { ReportIcon } from "./icons/ReportIcon";
import { SettingsIcon } from "./icons/SettingsIcon";

export const iconsMap: Record<ZIconName, React.ReactNode> = {
  home: <HomeFilled />,
  plans: <PlansIcon />,
  models: <ModelsIcon />,
  folder: <FolderIcon />,
  report: <ReportIcon />,
  settings: <SettingsIcon />,
  calendar: <CalendarFilled />,
};
