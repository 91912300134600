// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-TooltipExtEdit-TooltipExtEdit-module__form--jh4Xb {
  height: 100%;
  overflow: hidden;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-TooltipExtEdit-TooltipExtEdit-module__content--dqTXN {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-TooltipExtEdit-TooltipExtEdit-module__fields--qGTe9 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow-y: auto;
  padding-right: 12px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-TooltipExtEdit-TooltipExtEdit-module__preview--xnVrd {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/MetaInfo/TooltipExtEdit/TooltipExtEdit.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;AACJ;AACA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,gCAAA;EACA,gBAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".form {\n    height: 100%;\n    overflow: hidden;\n}\n.content {\n    height: 100%;\n    overflow: hidden;\n    display: grid;\n    grid-template-columns: 200px 1fr;\n    column-gap: 16px;\n}\n.fields {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    height: 100%;\n    overflow-y: auto;\n    padding-right: 12px;\n}\n.preview {\n    display: flex;\n    flex-direction: column;\n    row-gap: 10px;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-TooltipExtEdit-TooltipExtEdit-module__form--jh4Xb`,
	"content": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-TooltipExtEdit-TooltipExtEdit-module__content--dqTXN`,
	"fields": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-TooltipExtEdit-TooltipExtEdit-module__fields--qGTe9`,
	"preview": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-TooltipExtEdit-TooltipExtEdit-module__preview--xnVrd`
};
export default ___CSS_LOADER_EXPORT___;
