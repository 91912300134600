import React from "react";
import { TreeStd } from "src/components/TreeStd";
import { observer } from "mobx-react-lite";
import { DownOutlined } from "@ant-design/icons";
import { TreeProps } from "antd/lib";
import { Spin } from "antd";
import { EntityFiltersPageStore } from "../EntityFiltersPageStore";
import styles from "./AttsTree.module.less";
import { AttrTreeData } from "./types";

type PropsAttsTree = { store: EntityFiltersPageStore };

export const AttsTree: React.FC<PropsAttsTree> = observer(({ store }) => {
  const {
    attrsTreeStore,
    attrsTreeStore: { treeStdStore, expandedKeys },
  } = store;

  const handleExpand: TreeProps["onExpand"] = (_, info) => {
    const { expanded, node } = info;
    const expandedNode = treeStdStore.findNode(node.key);
    if (!expandedNode) return;
    if (expanded) {
      attrsTreeStore.setExpandedKeys([...expandedKeys, node.key]);
    } else {
      attrsTreeStore.setExpandedKeys(
        expandedKeys.filter((item) => item !== node.key),
      );
    }
    store.saveEntitySearchState();
    const canAppend =
      !node.children || (node.children && node.children.length === 0);
    if (!canAppend) return;
    store.appendFilterTree(expandedNode);
  };

  const handleSelect = (selectedKeys: React.Key[]) => {
    attrsTreeStore.setSelectedKeys(selectedKeys);
    store.saveEntitySearchState();
    store.reloadWithActualFilters();
  };

  React.useEffect(() => {
    treeStdStore.walk((node) => {
      if (attrsTreeStore.selectedKeys.includes(node.key)) {
        treeStdStore.updateNode(node.key, { className: styles.activeNode });
      } else {
        treeStdStore.updateNode(node.key, { className: "" });
      }
    });
  }, [attrsTreeStore.selectedKeys]);

  return (
    <Spin spinning={attrsTreeStore.loading}>
      <div className={styles.box}>
        <TreeStd<AttrTreeData>
          showLine
          switcherIcon={<DownOutlined />}
          expandedKeys={attrsTreeStore.expandedKeys}
          selectedKeys={attrsTreeStore.selectedKeys}
          store={treeStdStore}
          onExpand={handleExpand}
          onSelect={handleSelect}
          className={styles.attsTree}
        />
      </div>
    </Spin>
  );
});
