// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsChat-ChatForm-ChatForm-module__box--cF0V8 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 15px;
}
.src-businessServices-services-bsChat-ChatForm-ChatForm-module__header--v6W0W {
  justify-content: space-between;
}
.src-businessServices-services-bsChat-ChatForm-ChatForm-module__headerInfo--Gfcaf {
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsChat-ChatForm-ChatForm-module__form--pJcb7 {
  max-width: 956px;
}
.src-businessServices-services-bsChat-ChatForm-ChatForm-module__loaderWrapper--HSkwq {
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translate(10vh, -50%);
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsChat/ChatForm/ChatForm.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;EACA,SAAA;AACF;AAEA;EACE,8BAAA;AAAF;AAGA;EACE,aAAA;EACA,sBAAA;AADF;AAIA;EACE,gBAAA;AAFF;AAKA;EACE,eAAA;EACA,SAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,0CAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yCAAA;AAHF","sourcesContent":[".box {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n  gap: 15px;\n}\n\n.header {\n  justify-content: space-between;\n}\n\n.headerInfo {\n  display: flex;\n  flex-direction: column;\n}\n\n.form {\n  max-width: 956px;\n}\n\n.loaderWrapper {\n  position: fixed;\n  top: 10vh;\n  left: 50%;\n  transform: translate(10vh, -50%);\n  z-index: 1000;\n  background-color: rgba(255, 255, 255, 0.8);\n  padding: 20px;\n  border-radius: 8px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__box--cF0V8`,
	"header": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__header--v6W0W`,
	"headerInfo": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__headerInfo--Gfcaf`,
	"form": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__form--pJcb7`,
	"loaderWrapper": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__loaderWrapper--HSkwq`
};
export default ___CSS_LOADER_EXPORT___;
