/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, Space } from "antd";
import { PageLayout } from "src/components/PageLayout";
import { TableStore } from "src/components/tables/TableStore";
import { AColumn } from "src/components/tables/AsyncTable";
import { FiltersForm } from "src/components/tables/FiltersForm";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { Link } from "react-router-dom";
import { VerticalLayout } from "src/components/VerticalLayout";
import { viewDateUtc } from "src/common/formats";
import { BarsOutlined, TableOutlined } from "@ant-design/icons";
import { testTablePageStore as store } from "./TestTablePageStore";
import { TestTableFilter, ZTestRecord } from "./testTableApi";
import { ATVTemplateToken } from "../EntityFiltersPage/AsyncTableView/types";
import { ATVRowGrid } from "../EntityFiltersPage/atvTemplatesGrid/ATVRowGrid/ATVRowGrid";
import { ATVContainerGrid } from "../EntityFiltersPage/atvTemplatesGrid/ATVContainerGrid/ATVContainerGrid";
import { AsyncTableView } from "../EntityFiltersPage/AsyncTableView";

interface PropsTestTable {
  tableStore: TableStore<ZTestRecord, TestTableFilter>;
}

const sorter = true;

const tableTemplateTokenGrid: ATVTemplateToken = {
  container(_, children) {
    return <ATVContainerGrid>{children}</ATVContainerGrid>;
  },
  item(baseProps, row, rowKey) {
    return (
      <ATVRowGrid
        store={baseProps.store}
        columns={baseProps.store.finalColumns}
        row={row}
        rowKey={rowKey}
      />
    );
  },
};

const TestTable: React.FC<PropsTestTable> = observer(({ tableStore }) => {
  const [showAsGrid, setShowAsGrid] = React.useState(true);

  const toggle = (f: boolean) => setShowAsGrid(f);

  React.useEffect(() => {
    tableStore.setColumns(showAsGrid ? columns2 : columns);
  }, [showAsGrid]);

  const columns: AColumn<ZTestRecord>[] = [
    { key: "id", dataIndex: "id", title: "ID", sorter },
    {
      key: "name",
      title: "Наименование",
      sorter,
      render: (_, { id, name }) => (
        <Link to={makeUrl(PageUrl.testCardEdit, { id })}>{name}</Link>
      ),
    },
    {
      key: "status",
      title: "Статус",
      sorter,
      render: (_, { status }) => (
        <span>{store.statusesDict[status] ?? status}</span>
      ),
    },
    {
      key: "expirationDate",
      title: "Окончание",
      render: (_, { expirationDate }) => viewDateUtc(expirationDate),
    },
  ];

  const columns2: AColumn<ZTestRecord>[] = [
    {
      key: "name",
      title: "Наименование",
      sorter,
      render: (_, { id, name }) => (
        <Link to={makeUrl(PageUrl.testCardEdit, { id })}>{name}</Link>
      ),
    },
    {
      key: "status",
      title: "Статус",
      sorter,
      render: (_, { status }) => (
        <span>{store.statusesDict[status] ?? status}</span>
      ),
    },
    {
      key: "expirationDate",
      title: "Окончание",
      render: (_, { expirationDate }) => viewDateUtc(expirationDate),
    },
  ];

  return (
    <>
      <Space>
        <Button
          size="large"
          onClick={() => toggle(false)}
          icon={<BarsOutlined />}
        />
        <Button
          size="large"
          onClick={() => toggle(true)}
          icon={<TableOutlined />}
        />
      </Space>
      <AsyncTableView<ZTestRecord, TestTableFilter>
        store={tableStore}
        columns={tableStore.columns}
        useHeader
        usePagination
        useSelection
        onRowClick={(r) => {
          // eslint-disable-next-line no-console
          console.log(r);
        }}
        templateToken={showAsGrid ? tableTemplateTokenGrid : undefined}
      />
    </>
  );
});

export const TestTablePage: React.FC = observer(() => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <PageLayout pageTitle="Тестовая таблица">
      <VerticalLayout>
        <FiltersForm store={store.tableStore}>
          <Form.Item name="name">
            <Input placeholder="Имя" allowClear />
          </Form.Item>
        </FiltersForm>
        <TestTable tableStore={store.tableStore} />
      </VerticalLayout>
    </PageLayout>
  );
});
