import * as React from "react";
import { observer } from "mobx-react-lite";
import { makeAutoObservable } from "mobx";
import { Button, Collapse, Tooltip } from "antd";
import { CollapseProps } from "antd/lib/collapse/Collapse";
import { CollapsePanelProps } from "antd/lib/collapse/CollapsePanel";
import {
  FormFieldsBlock,
  PropsFormFieldsBlock,
} from "src/components/Forms/FormFieldsBlock";
import { DeleteOutlined } from "@ant-design/icons";
import { delay } from "src/common/delay";
import { ifDef } from "src/common/ifDef";
import { filterDefined } from "src/common/filterDefined";
import { VerticalLayout } from "src/components/VerticalLayout";
import { DrawItems } from "src/components/DrawItems";
import { t } from "i18next";
import { makeId } from "./makeId";
import {
  BlockContext,
  RenderResult,
  FormBlockDef,
  FormBlockItem,
  getArrayCtx,
  StateManager,
  StoreWithExpand,
  ArrayContext,
} from "../FormWithBlocks.types";
import { renderTitled } from "./renderTitled";
import styles from "../FormWithBlock.module.less";

/* eslint react/jsx-props-no-spreading: "off" */

const { Panel } = Collapse;

interface PanelBlock {
  panelProps: CollapsePanelProps;
}

export class CollapseState implements StoreWithExpand {
  constructor() {
    makeAutoObservable(this, { keys: false, setKeys: false });
  }

  keys: string[] = [];

  setKeys(keysList: string[]) {
    this.keys = keysList;
  }

  activeKey: string[] = [];

  setActiveKey(newKey: string[] | string) {
    this.activeKey = Array.isArray(newKey) ? newKey : [newKey];
  }

  async expand(keyOrIndex: number | string): Promise<void> {
    const key: string =
      (typeof keyOrIndex === "number" && this.keys[keyOrIndex]) ||
      String(keyOrIndex);
    if (!this.activeKey.includes(key)) {
      this.setActiveKey([...this.activeKey, key]);
      await delay(50);
    }
  }
}

interface PropsBlockCollapse {
  prevId: string;
  blockKey: string;
  stateManager: StateManager;
  collapseProps: CollapseProps;
  subBlocks: (FormBlockDef | undefined)[];
  ctx: BlockContext;
}

const BlockCollapse: React.FC<PropsBlockCollapse> = observer(
  (props: PropsBlockCollapse) => {
    const { prevId, blockKey, stateManager, collapseProps, subBlocks, ctx } =
      props;
    const id = makeId(prevId, blockKey);
    const store = stateManager.getBlockStore(id, () => new CollapseState());
    React.useEffect(() => {
      // Будем считать, что при первой отрисовке надо открыть все панели
      store.setActiveKey(filterDefined(subBlocks).map(({ key }) => key));
    }, []);
    return (
      <Collapse
        {...collapseProps}
        activeKey={store.activeKey}
        onChange={(k) => store.setActiveKey(k)}
        className={styles.blockCollapse}
      >
        {filterDefined(subBlocks).map((block) => {
          // Если это специальный вариант, использующий Panel, то можно сразу использовать render
          if ("panelProps" in block) {
            return block.render(id, ctx);
          }
          return (
            <Panel
              key={block.key}
              header={block.title ?? block.key}
              forceRender
            >
              {renderTitled(block, false, id, ctx)}
            </Panel>
          );
        })}
      </Collapse>
    );
  },
);

/**
 * Блок, использующий компонент Collapse
 * @param key
 * @param stateManager
 * @param props
 * @param subBlocks
 * @param ext
 */
export const blockCollapse = (
  key: string,
  stateManager: StateManager,
  props: CollapseProps,
  subBlocks: (FormBlockDef | undefined)[],
  ext?: Partial<FormBlockDef>,
): FormBlockDef => ({
  key,
  subBlocks,
  render: (prevId, ctx) => (
    <BlockCollapse
      prevId={prevId}
      key={key}
      blockKey={key}
      stateManager={stateManager}
      collapseProps={props}
      subBlocks={subBlocks}
      ctx={ctx}
    />
  ),
  ...ext,
});

export const applyField = (text: string, aCtx: ArrayContext): string => {
  const { index } = aCtx;
  return text.replace("%key", String(index + 1));
};

interface CollapsePanelPropsExt {
  makeTitle?(aCtx: ArrayContext): React.ReactNode;
  noDelete?: boolean;
}

// Здесь нельзя использовать компонент. Т.к <Panel> должен быть непосредственно вложен в <Collapse>
const drawPanel = (
  ctx: BlockContext | undefined,
  content: React.ReactNode,
  panelProps: CollapsePanelProps & CollapsePanelPropsExt,
) =>
  ifDef(getArrayCtx(ctx), (aCtx) => (
    <Panel
      forceRender
      key={String(aCtx.field.key)}
      header={
        panelProps.makeTitle?.(aCtx) ??
        applyField(
          typeof panelProps.header === "string" ? panelProps.header : "",
          aCtx,
        )
      }
      extra={
        !panelProps.noDelete && (
          <Tooltip title={t("Delete")}>
            <Button
              size="small"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                aCtx.operation.remove(aCtx.index);
              }}
            />
          </Tooltip>
        )
      }
    >
      {content}
    </Panel>
  )) ?? <Panel {...panelProps}>{content}</Panel>;

/**
 * Блок полей, специализированный для вставки непосредственно в blockCollapse
 * @param panelProps
 * @param fieldsProps
 * @param items
 */
export const blockPanelFields = (
  panelProps: CollapsePanelProps & CollapsePanelPropsExt,
  fieldsProps: PropsFormFieldsBlock,
  items: (FormBlockItem | undefined)[],
): FormBlockDef & PanelBlock => ({
  key: String(panelProps.key),
  title: typeof panelProps.header === "string" ? panelProps.header : undefined,
  panelProps,
  items,
  render: (prevId, ctx): RenderResult => {
    const content = (
      <FormFieldsBlock {...fieldsProps}>
        <DrawItems items={items} ctx={ctx} />
      </FormFieldsBlock>
    );
    return drawPanel(ctx, content, panelProps);
  },
});

/**
 * Возможность передать внутрь Collapse список блоков
 */
export const blockPanelBlocks = (
  panelProps: CollapsePanelProps & CollapsePanelPropsExt,
  blocks: (FormBlockDef | undefined)[],
): FormBlockDef & PanelBlock => ({
  key: String(panelProps.key),
  title: typeof panelProps.header === "string" ? panelProps.header : undefined,
  panelProps,
  subBlocks: blocks,
  render: (prevId, ctx): RenderResult => {
    const key = String(panelProps.key);
    const content = (
      <VerticalLayout>
        {filterDefined(blocks).map((block) => (
          <React.Fragment key={block.key}>
            {block.render(makeId(prevId, key), ctx)}
          </React.Fragment>
        ))}
      </VerticalLayout>
    );
    return drawPanel(ctx, content, panelProps);
  },
});
