import { appStore } from "src/appStore";

/**
 * Добавление в запрос заголовка для мультиязычности
 * Пример:
 * rest.get("some url", {headers: langHdr()})
 * @returns
 */
export const langHdr = (): Record<string, string> => ({
  "Content-Language": appStore.curLang,
});

export const optionalLangHeaders = (translate: boolean | undefined) =>
  translate ? langHdr() : undefined;

export const optionalLangParams = (translate: boolean | undefined) => ({
  headers: optionalLangHeaders(translate),
});
