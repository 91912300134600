// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-atvTemplatesGrid-ATVContainerGrid-ATVContainerGrid-module__grid--x8LSH {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/atvTemplatesGrid/ATVContainerGrid/ATVContainerGrid.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kCAAA;AACF","sourcesContent":[".grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `src-pages-EntityFiltersPage-atvTemplatesGrid-ATVContainerGrid-ATVContainerGrid-module__grid--x8LSH`
};
export default ___CSS_LOADER_EXPORT___;
