// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsChat-ChatListPage-module__layout--tk8Av {
  display: flex;
  padding: 16px 24px;
  height: 100%;
  gap: 20px;
  background: #f5f5f5;
}
.src-businessServices-services-bsChat-ChatListPage-module__layout--tk8Av .ant-tabs-content,
.src-businessServices-services-bsChat-ChatListPage-module__layout--tk8Av .ant-tabs-tabpane {
  height: 100%;
}
.src-businessServices-services-bsChat-ChatListPage-module__layout--tk8Av .ant-tabs-content-holder {
  border: none !important;
}
.src-businessServices-services-bsChat-ChatListPage-module__layout--tk8Av .ant-tabs-ink-bar {
  left: 0;
}
.src-businessServices-services-bsChat-ChatListPage-module__layout--tk8Av .src-businessServices-services-bsChat-ChatListPage-module__sider--g4a2l {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 24px;
  width: 250px;
  overflow: auto;
}
.src-businessServices-services-bsChat-ChatListPage-module__layout--tk8Av .src-businessServices-services-bsChat-ChatListPage-module__content--zDqrR {
  flex: 1;
  padding: 10px 24px;
  background: white;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsChat/ChatListPage.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;EACA,mBAAA;AACF;AANA;;EAQI,YAAA;AAEJ;AAVA;EAWI,uBAAA;AAEJ;AAbA;EAcI,OAAA;AAEJ;AAhBA;EAkBI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;EACA,cAAA;AACJ;AAxBA;EA0BI,OAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".layout {\n  display: flex;\n  padding: 16px 24px;\n  height: 100%;\n  gap: 20px;\n  background: #f5f5f5;\n  :global(.ant-tabs-content),\n  :global(.ant-tabs-tabpane) {\n    height: 100%;\n  }\n  :global(.ant-tabs-content-holder) {\n    border: none !important;\n  }\n  :global(.ant-tabs-ink-bar) {\n    left: 0;\n  }\n\n  .sider {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding-top: 24px;\n    width: 250px;\n    overflow: auto;\n  }\n  .content {\n    flex: 1;\n    padding: 10px 24px;\n    background: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `src-businessServices-services-bsChat-ChatListPage-module__layout--tk8Av`,
	"sider": `src-businessServices-services-bsChat-ChatListPage-module__sider--g4a2l`,
	"content": `src-businessServices-services-bsChat-ChatListPage-module__content--zDqrR`
};
export default ___CSS_LOADER_EXPORT___;
