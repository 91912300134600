import { ZAttribute } from "src/types/ZAttribute";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { getIdNames } from "src/references/getIdNames";
import {
  labelLoaderDict,
  labelLoaderObjectRef,
  labelLoaderChildEntities,
  labelLoaderUsersList,
} from "./labelLoaders";
import { AttrLabelTreeData, defAttrLabelTreeData } from "./AttrLabelTreeData";
import { AttrLabelLoaderParams } from "./types";

/*
 * тестовая фича.
 * сейчас +- понятно, как формируются лейблы в атрибутах,
 * подумалось, что наиболее подходящей структурой для их обработки будет дерево,
 * так как оно сохранет не только конечные значения, но и иерархию их подгрузки.
 *
 * В дальнейшем ее нужно использовать при формировании лейблов атрибутов во всей системе.
 */

let typesMap: Record<number, string> | null = null;
export const loadAttrLabelData = async (
  values: string[],
  attrInfo: ZAttribute,
  params?: AttrLabelLoaderParams,
) => {
  const loaders: Partial<
    Record<AttrTypeName, () => Promise<AttrLabelTreeData | null>>
  > = {
    [AttrTypeName.dictMulti]: () => labelLoaderDict(values, attrInfo),
    [AttrTypeName.dictSingle]: () => labelLoaderDict(values, attrInfo),
    [AttrTypeName.object]: () => labelLoaderObjectRef(values, attrInfo),
    [AttrTypeName.usersList]: () => labelLoaderUsersList(values, attrInfo),
    [AttrTypeName.childEntities]: () =>
      labelLoaderChildEntities(values, attrInfo, params),
  };
  if (!typesMap) typesMap = makeDictNameById(await getIdNames("attrType"));
  const typeName = typesMap[attrInfo.valueType] as AttrTypeName;
  if (!typeName) throw Error(`${attrInfo.valueType} attr type is not exist`);
  const loader = loaders[typeName];
  return loader ? loader() : defAttrLabelTreeData(values, attrInfo);
};
