// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-UserAccountPage-UserEdit-UserEdit-module__block--mVw59 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 16px;
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserAccountPage/UserEdit/UserEdit.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kCAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AACJ","sourcesContent":[".block {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    column-gap: 24px;\n    row-gap: 16px;\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `src-pages-UserAccountPage-UserEdit-UserEdit-module__block--mVw59`
};
export default ___CSS_LOADER_EXPORT___;
