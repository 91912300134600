import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { Dayjs } from "dayjs";
import { Subtask, Task } from "./Timeline.types";
import { generateTasks } from "./generateTasks";
import {
  DayInfo,
  MonthInfo,
  buildMonths,
  maxDateOpt,
  minDateOpt,
} from "./dateUtils";

/* eslint no-plusplus: "off" */
/* eslint no-restricted-syntax: "off" */

export class TimelineDemoStore {
  constructor() {
    makeAutoObservable(this);
  }

  tasks: Task[] = [];

  setTasks(newTasks: Task[]) {
    this.tasks = newTasks;
  }

  init() {
    try {
      this.setTasks(generateTasks());
    } catch (e) {
      onError(e);
    }
  }

  get minStart(): Dayjs | undefined {
    return this.tasks.reduce(
      (min: Dayjs | undefined, { start }) => minDateOpt(min, start),
      undefined,
    );
  }

  get maxFinish(): Dayjs | undefined {
    return this.tasks.reduce(
      (max: Dayjs | undefined, { finish }) => maxDateOpt(max, finish),
      undefined,
    );
  }

  get days(): DayInfo[] {
    const { minStart, maxFinish } = this;
    const result: DayInfo[] = [];
    if (minStart && maxFinish) {
      const count = maxFinish.diff(minStart, "day");
      const cellsCount = count + 3;
      let date = minStart.subtract(1, "day");
      for (let i = 0; i < cellsCount; i++) {
        const dow = date.toDate().getDay();
        result.push({
          date,
          iso: date.format("YYYY-MM-DD"),
          holiday: dow === 0 || dow === 6, // упрощенный вариант: праздники это сб и вс.
        });
        date = date.add(1, "day");
      }
    }
    return result;
  }

  get months(): MonthInfo[] {
    return buildMonths(this.days);
  }

  dayWidth = 50;

  setDayWidth(w: number) {
    this.dayWidth = w;
  }

  cellHeight = 30;

  get viewBox(): { width: number; height: number } {
    return {
      width: this.dayWidth * (this.days.length + 1),
      height:
        this.cellHeight *
        this.tasks.reduce(
          (sum, task) => sum + 1 + (task.expanded ? task.subtasks.length : 0),
          1,
        ),
    };
  }

  findSubtaskIndex(targetId: number): number | undefined {
    let result = 1;
    for (const task of this.tasks) {
      result++; // задача всегда занимает ячейку
      if (task.expanded) {
        for (const subtask of task.subtasks) {
          if (subtask.id === targetId) return result;
          result++;
        }
      }
    }
    return undefined;
  }

  findTaskIndex(taskId: number): number | undefined {
    let result = 1;
    for (const task of this.tasks) {
      if (task.id === taskId) return result;
      result++; // задача всегда занимает ячейку
      if (task.expanded) {
        result += task.subtasks.length;
      }
    }
    return undefined;
  }

  getTaskSize(task: Task) {
    const { start, finish } = task;
    const index = this.findTaskIndex(task.id);
    if (!start || !finish || !index) return undefined;
    const totalDays = finish.diff(start, "days") + 1;
    const offsetInDays = start.diff(this.minStart, "days") + 1;
    return {
      y: index * this.cellHeight,
      x: offsetInDays * this.dayWidth,
      total: totalDays * this.dayWidth,
    };
  }

  getSubtaskBox(
    subtask: Subtask,
  ): { x: number; y: number; width: number; height: number } | undefined {
    const index = this.findSubtaskIndex(subtask.id);
    if (index === undefined) return undefined;
    const offsetInDays = subtask.start.diff(this.minStart, "days") + 1; // +1 т.к. одна ячейка слева в виде отступа
    const lengthInDays = subtask.finish.diff(subtask.start, "days") + 1; // +1 т.к. если задача начинается и оканч в один день, то это считается всё равно целый день
    const dy = 4;
    return {
      x: offsetInDays * this.dayWidth,
      y: index * this.cellHeight + dy,
      width: lengthInDays * this.dayWidth,
      height: this.cellHeight - 2 * dy,
    };
  }

  makePathWithSubtasks(from: Subtask, to: Subtask): { x: number; y: number }[] {
    const result: { x: number; y: number }[] = [];
    const a = this.getSubtaskBox(from);
    const b = this.getSubtaskBox(to);
    if (a && b) {
      const dx = 9;
      const p0 = { x: a.x + a.width, y: a.y + a.height / 2 };
      const p1 = { x: b.x, y: b.y + b.height / 2 };
      const minX = Math.min(p0.x, p1.x) - dx * 2;
      result.push(p0);
      result.push({ x: p0.x + dx, y: p0.y });
      result.push({ x: p0.x + dx, y: p0.y + this.cellHeight / 2 });
      result.push({ x: minX, y: p0.y + this.cellHeight / 2 });
      result.push({ x: minX, y: p1.y });
      result.push(p1);
    }
    return result;
  }

  onExpand(target: Task) {
    this.tasks = this.tasks.map((task) =>
      task.id === target.id ? { ...task, expanded: !task.expanded } : task,
    );
  }

  expandAll(isExpand: boolean) {
    this.tasks.forEach((task) => {
      if (task.expanded !== isExpand) this.onExpand(task);
    });
  }

  leftCollapsed = false;

  setLeftCollapsed(state: boolean) {
    this.leftCollapsed = state;
  }
}
