// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-DrawItems-DrawItems-module__formItem--ADJ8c .ant-row .ant-col label {
  height: auto;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 7px;
}
.src-components-DrawItems-DrawItems-module__formItem--ADJ8c .ant-row .ant-col label .ant-form-item-tooltip {
  margin-inline-start: 0;
}
.src-components-DrawItems-DrawItems-module__formItem--ADJ8c .ant-row .ant-col label.ant-form-item-required::before {
  margin-inline-end: 0;
}
.src-components-DrawItems-DrawItems-module__formItem--ADJ8c .ant-input-number-group-addon,
.src-components-DrawItems-DrawItems-module__formItem--ADJ8c .ant-input-group-addon {
  background-color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/DrawItems/DrawItems.module.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AAAJ;AANA;EAUI,sBAAA;AADJ;AATA;EAcI,oBAAA;AAFJ;AAZA;;EAmBI,yBAAA;AAHJ","sourcesContent":[".formItem {\n  :global(.ant-row .ant-col label) {\n    height: auto;\n    line-height: 22px;\n    display: flex;\n    align-items: center;\n    gap: 7px;\n  }\n\n  :global(.ant-row .ant-col label .ant-form-item-tooltip) {\n    margin-inline-start: 0;\n  }\n\n  :global(.ant-row .ant-col label.ant-form-item-required::before) {\n    margin-inline-end: 0;\n  }\n\n  :global(.ant-input-number-group-addon),\n  :global(.ant-input-group-addon) {\n    background-color: inherit;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": `src-components-DrawItems-DrawItems-module__formItem--ADJ8c`
};
export default ___CSS_LOADER_EXPORT___;
