export interface Command {
  exec(): void;
  createUndo(): Command;
  readonly title: string;
  readonly key: number;
}

let glbKey = 0;

// eslint-disable-next-line no-plusplus
export const generateCmdKey = (): number => ++glbKey;
