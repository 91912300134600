import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { GroupType } from "src/types/ZGroup";
import { ZIdName } from "src/types/ZIdName";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { MultiLangFields } from "src/components/MultiLangFields";
import { WidthLimitedFields } from "src/pages/ManagementPage/WidthLimitedFields";
import { ExtraTools } from "src/pages/ManagementPage/ExtraTools";
import { SelectGroupType } from "./SelectGroupType";
import { SelectAttribute, buildTreeForSrcDict } from "../SelectAttribute";
import { GroupFieldName } from "./GroupFieldName";
import { Obj2TabStore } from "../../Obj2TabStore";
import { GroupO2, newItemId } from "../../Obj2Nodes";
import { findNodeOwnerObject } from "../../utils/findNodeOwnerObject";
import { UpToObject } from "../UpToObject";
import styles from "./GroupForm2.module.less";
import { AddGroupItem } from "./AddGroupItem";

interface PropsGroupFields {
  store: Obj2TabStore;
  groupNode: GroupO2;
}

export const GroupForm2: React.FC<PropsGroupFields> = observer(
  ({ store, groupNode }) => {
    const groupType: ZIdName | undefined = Form.useWatch(
      GroupFieldName.groupType,
    );
    const disabled = !groupNode.actions.update;
    const canSwitchType = false;
    const tooltip = "Тип группы зафиксирован и изменению не подлежит";

    return (
      <WidthLimitedFields>
        <Form.Item
          name={GroupFieldName.name}
          label="Название группы атрибутов"
          rules={[trimRequired()]}
        >
          <Input allowClear disabled={disabled} />
        </Form.Item>
        <MultiLangFields basePath={[GroupFieldName.translations]} />

        <Form.Item
          name={GroupFieldName.groupType}
          label="Тип группы"
          rules={[{ required: true }]}
          tooltip={tooltip}
        >
          <SelectGroupType disabled={!canSwitchType} />
        </Form.Item>

        <Form.Item name={GroupFieldName.description} label="Описание">
          <Input.TextArea allowClear disabled={disabled} />
        </Form.Item>
        {groupType?.name === GroupType.ByDictionary && (
          <Form.Item
            name={GroupFieldName.attributeId}
            label="Ссылка на атрибут"
            rules={[{ required: true }]}
          >
            <SelectAttribute
              tree={attrTree(groupNode, store)}
              disabled={disabled}
              className="idAttrReference"
            />
          </Form.Item>
        )}
      </WidthLimitedFields>
    );
  },
);

const attrTree = (groupNode: GroupO2, store: Obj2TabStore) => {
  const rootObject = findNodeOwnerObject(groupNode.key, store.treeData);
  return rootObject
    ? buildTreeForSrcDict(rootObject, {
        dstGroupId: groupNode.group.id,
        typeFilter: (typeId: number) => store.isDictionary1Type(typeId),
      })
    : [];
};

export const GroupFormButtons: React.FC<PropsGroupFields> = observer(
  ({ store, groupNode }) => {
    const { group } = groupNode;
    const isNew = group.id === newItemId;
    const isNotMnenonic = group.groupType.name !== GroupType.Mnemonic;
    const disabled = !groupNode.actions.update;
    return (
      <div className={styles.buttons}>
        <UpToObject store={store} />
        <ExtraTools>
          <AddGroupItem
            store={store}
            disabled={isNotMnenonic || isNew || disabled}
            buttonId="addGroupItem"
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              store.safeDeleteCurNode();
            }}
            disabled={!isNew && !groupNode.actions.delete}
          >
            Удалить группу
          </Button>
        </ExtraTools>
      </div>
    );
  },
);
