import { CommonNodeO2 } from "../../Obj2Nodes";
import { createGroupNode2 } from "../../utils/createMainTree";

/**
 * Построить дерево для ссылки на атрибут-справочник
 * Условия:
 * - тип атрибутов: справочник
 * - если указана целевая группа, то атрибут не должен в нее входить
 */

type Params = {
  dstGroupId?: number;
  dstAttrId?: number;
  typeFilter?: (typeId: number) => boolean;
};

export const buildTreeForSrcDict = (
  object: CommonNodeO2,
  params?: Params,
): CommonNodeO2[] => {
  const filterAttrs = (nodes: CommonNodeO2[]): CommonNodeO2[] => {
    const typeFilter = params?.typeFilter;
    return (
      typeFilter
        ? nodes.filter(
            (node) => node.type === "attr" && typeFilter(node.attr.valueType),
          )
        : nodes
    ).filter(
      (node) => node.type === "attr" && node.attr.id !== params?.dstAttrId,
    );
  };

  const filterGroups = (nodes: CommonNodeO2[]): CommonNodeO2[] =>
    nodes.reduce((acc, node) => {
      if (node.type !== "group" || !node.children) return acc;
      const groups = filterGroups(node.children);
      const attrs = filterAttrs(node.children);
      if (groups.length === 0 && attrs.length === 0) return acc;
      const newNode = createGroupNode2(node.group, {
        update: true,
        delete: true,
      });
      newNode.children = [...groups, ...attrs];
      return [...acc, newNode];
    }, [] as CommonNodeO2[]);

  const filterNodes = (nodes: CommonNodeO2[]): CommonNodeO2[] => {
    const groups = filterGroups(nodes);
    const attrs = filterAttrs(nodes);
    return [...groups, ...attrs];
  };

  return filterNodes(object.children ?? []);
};
