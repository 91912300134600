import * as React from "react";
import { observer } from "mobx-react-lite";
import { Checkbox } from "antd";
import { isChecked } from "src/common/groupChecked";
import { Bom2PositionStore } from "../Bom2PositionStore";
import { EdBomPos, EdBomRow } from "../ZBom2Position";

interface PropsBomSelectAll {
  store: Bom2PositionStore;
}

export const BomSelectAll: React.FC<PropsBomSelectAll> = observer(
  ({ store }) => {
    const checked = store.selectedAll;
    return (
      <Checkbox
        checked={isChecked(checked)}
        indeterminate={checked === "partial"}
        onChange={() => store.selectAll(!isChecked(checked))}
      />
    );
  },
);

interface PropsBomSelectPos {
  store: Bom2PositionStore;
  pos: EdBomPos;
}
export const BomSelectPos: React.FC<PropsBomSelectPos> = observer(
  ({ store, pos }) => {
    const checked = store.selectedPos[pos.materialTypeId] ?? "none";
    return (
      <Checkbox
        checked={isChecked(checked)}
        indeterminate={checked === "partial"}
        onChange={() => store.selectPos(pos, !isChecked(checked))}
        disabled={pos.rows.length === 0}
      />
    );
  },
);

interface PropsBomSelectRow {
  store: Bom2PositionStore;
  row: EdBomRow;
}

export const BomSelectRow: React.FC<PropsBomSelectRow> = observer(
  ({ store, row }) => (
    <Checkbox
      checked={store.selected.has(row.id)}
      onChange={(e) => store.selectRow(row, e.target.checked)}
    />
  ),
);
