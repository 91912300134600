import { ZMenuItem } from "src/components/MainFrame/LeftMenu/ZMenuItem";

/* eslint-disable no-restricted-syntax */
export const findMenuItemById = (
  id: number,
  items: ZMenuItem[],
): ZMenuItem | undefined => {
  for (const item of items) {
    if (item.id === id) return item;
    if (item.elements) {
      const subNode = findMenuItemById(id, item.elements);
      if (subNode) return subNode;
    }
  }
  return undefined;
};
