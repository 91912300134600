import { Form, FormRule, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { AttrTypeName } from "src/types/AttrType";
import { ZArticleNumberMeta } from "src/types/ZArticleNumberInfo";
import { makeDictionary } from "src/common/makeDictionary";
import { makeFormName } from "./names";
import { getAttrArticleMetaFC } from "./metaControllers/getAttrArticleMetaFC";
import { defineMetaController } from "./metaControllers/defineMetaController";

export const metaRequired = (attributes: ZAttribute[]): FormRule => ({
  validator: (_, value: ZArticleNumberMeta[] | undefined) => {
    const valDict = makeDictionary(
      value || [],
      ({ attributeId }) => attributeId,
    );
    const unfilled = attributes.filter((attr) => !valDict[attr.id]);
    if (unfilled.length > 0) {
      return Promise.reject(
        Error(
          `Необходимо настроить атрибуты: ${unfilled.map((attr) => attr.name).join()}`,
        ),
      );
    }
    return Promise.resolve();
  },
});

interface Store {
  attrTypesMap: Record<number, string>;
}
type PropsArticleAttrMetaForm = {
  attributes: ZAttribute[];
  baseName: string;
  store: Store;
};
export const ArticleAttrMetaForm: React.FC<PropsArticleAttrMetaForm> = observer(
  ({ attributes, baseName, store }) => (
    <Form.Item
      label="Выполните настройку полей"
      name={makeFormName(baseName, "attributeMetas")}
      rules={[metaRequired(attributes)]}
    >
      <MetaDefiner attributes={attributes} store={store} />
    </Form.Item>
  ),
);

type PropsMetaDefiner = {
  attributes: ZAttribute[];
  store: Store;
  onChange?: (v: unknown) => void;
  value?: ZArticleNumberMeta[];
};
type DQueue = [unknown[], unknown[]];
const MetaDefiner: React.FC<PropsMetaDefiner> = ({
  value,
  attributes,
  onChange,
  store,
}) => {
  const [reg, setReg] = useState<DQueue>([[], []]);
  const valsDict = makeDictionary(
    value?.filter(Boolean) || [],
    ({ attributeId }) => attributeId,
  );
  const handleChange = (data: unknown, index: number) => {
    onChange?.(
      attributes
        .map((attr, indexInner) =>
          indexInner === index ? data : valsDict[attr.id],
        )
        .filter(Boolean),
    );
  };
  useEffect(() => {
    const tmp = [reg[1] || [], attributes] satisfies DQueue;
    setReg(tmp);
    if (tmp[0].length !== tmp[1].length)
      onChange?.(attributes.map((attr) => valsDict[attr.id]).filter(Boolean));
  }, [attributes]);
  return (
    <Space direction="vertical">
      {attributes.map((attr, i) => {
        const attrTypeName = store.attrTypesMap[attr.valueType] as AttrTypeName;
        const Cmp = getAttrArticleMetaFC(attrTypeName);
        if (!Cmp) return null;
        return (
          <React.Fragment key={attr.id}>
            {defineMetaController(Cmp, attr, valsDict[attr.id], (data) =>
              handleChange(data, i),
            )}
          </React.Fragment>
        );
      })}
    </Space>
  );
};
MetaDefiner.defaultProps = {
  value: undefined,
  onChange: undefined,
};
