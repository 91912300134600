import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { ZSampleSettings } from "../../SampleTypes";
import { SampleObjType } from "../SampleSettingsStore";

interface PropsSupplierSection {
  store: FormObjectSectionsStore<SampleObjType>;
}

export const SupplierSection: React.FC<PropsSupplierSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZSampleSettings, SampleObjType>
      store={store}
      objKey={["objSupplier"]}
      objType="supplier"
      objLabel="Sample supplier object"
      title="Sample supplier"
      attrs={[
        {
          attrKey: ["supplierSupplierNameAttrId"],
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["supplierSupplierShortNameAttrId"],
          attrLabel: "Short name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["supplierCountryAttrId"],
          attrLabel: "Country",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["supplierAddressAttrId"],
          attrLabel: "Address",
          attrType: AttrTypeName.string,
        },
      ]}
    />
  ),
);
