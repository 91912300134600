import * as React from "react";
import { Checkbox, CheckboxProps } from "antd";

type PropsEntityCheckbock = Omit<CheckboxProps, "onChange" | "value"> & {
  label: string;
  value?: string[] | null;
  onChange?(value?: string[] | null): void;
};

export const EntityCheckbock: React.FC<PropsEntityCheckbock> = (props) => {
  const { label, value, onChange, ...restProps } = props;
  return (
    <Checkbox
      {...restProps}
      checked={value?.[0] === "true"}
      onChange={(e) =>
        onChange?.([e.target.checked ? "true" : "false"] ?? ["false"])
      }
    >
      {label}
    </Checkbox>
  );
};

EntityCheckbock.defaultProps = {
  value: undefined,
  onChange: undefined,
};
