import React, { useEffect, useMemo, useState } from "react";
import { Button, Space } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { DefaultOptionType } from "antd/es/select";
import { ZCopyByValueRule } from "src/common/attrEdit";
import { onError } from "src/common/onError";
import { CopyByValueModal } from "./CopyByValueModal";
import { loadCbvLinkAttributes } from "./loadCbvLinkAttributes";
import { ZCbvLinkAttr } from "./CopyByValueEdit.types";

interface PropsCopyByValueEdit {
  objectId: number;
  curAttrId: number;
  value?: ZCopyByValueRule;
  onChange?(newValue?: ZCopyByValueRule): void;
  valueType: number;
  typeName: string;
}

export const CopyByValueEdit: React.FC<PropsCopyByValueEdit> = ({
  valueType,
  value,
  onChange,
  objectId,
  curAttrId,
  typeName,
}) => {
  const [open, setOpen] = useState(false);
  const [linkAttrs, setLinkAttrs] = useState<ZCbvLinkAttr[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const init = async () => {
    try {
      setLoading(true);
      const linkAttrsList = await loadCbvLinkAttributes(objectId, valueType);
      setLinkAttrs(linkAttrsList);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      init();
    }
  }, [open, objectId]);

  const linkAttrIdOpts: DefaultOptionType[] = useMemo(
    () =>
      linkAttrs.map(({ linkAttributeId, objectName, linkAttributeName }) => ({
        value: linkAttributeId,
        label: (
          <Space>
            <EnvironmentOutlined />
            {`${objectName}-${linkAttributeName}`}
          </Space>
        ),
      })),
    [linkAttrs, curAttrId],
  );

  const onSubmit = (values: ZCopyByValueRule) => {
    onChange?.(values);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {value?.parentAttributeId ? "Eсть" : "Нет"}
      </Button>
      <CopyByValueModal
        open={open}
        loading={loading}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
        initialValues={value}
        linkAttrIdOpts={linkAttrIdOpts}
        linkAttrs={linkAttrs}
        valueType={valueType}
        typeName={typeName}
      />
    </>
  );
};

CopyByValueEdit.defaultProps = {
  value: undefined,
  onChange: undefined,
};
