export const allowedFileTypes: Set<string> = new Set([
  // Документы
  "application/pdf", // PDF
  "application/msword", // Microsoft Word (DOC)
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Microsoft Word (DOCX)
  "application/vnd.ms-excel", // Microsoft Excel (XLS)
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Microsoft Excel (XLSX)
  "application/vnd.ms-powerpoint", // Microsoft PowerPoint (PPT)
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // Microsoft PowerPoint (PPTX)
  "text/plain", // Текстовый файл (TXT)
  "application/rtf", // RTF (Rich Text Format)

  // Изображения
  "image/jpeg", // JPEG (JPG)
  "image/png", // PNG
  "image/gif", // GIF
  "image/tiff", // TIFF
  "image/svg+xml", // SVG

  // Архивы
  "application/zip", // ZIP
  "application/x-7z-compressed", // 7-Zip
  "application/x-rar-compressed", // RAR
  "application/gzip", // Gzip
]);
