import * as React from "react";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { LoaderBox } from "src/components/LoaderBox";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { Button } from "antd";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { t } from "i18next";
import { MChartEntityStore } from "../../MChartEntityStore";
import styles from "../MCEntityCopyFrom/MCEntityCopyFrom.module.less";

interface PropsMCTemplate {
  store: MChartEntityStore;
}

export const MCTemplate: React.FC<PropsMCTemplate> = observer(({ store }) => {
  React.useEffect(() => {
    store.initTemplateTable().catch(onError);
  }, []);
  return (
    <LoaderBox
      remoteData={store.templateTable}
      drawReady={(tableStore) => (
        <Inner mcStore={store} tableStore={tableStore} />
      )}
    />
  );
});

interface PropsInner {
  mcStore: MChartEntityStore;
  tableStore: EntityFiltersPageStore;
}
const Inner: React.FC<PropsInner> = observer(({ mcStore, tableStore }) => {
  const onCopy = () => {
    const templates = tableStore.tableStore?.selected ?? [];
    const template = templates[0];
    if (template) mcStore.copyFrom(template.id);
  };
  return (
    <div className={styles.tableBox}>
      <div className={styles.toolbar}>
        <div className={styles.prompt}>
          {t("Please, select a template instance and click the Copy button.")}
        </div>
        <Button
          type="primary"
          disabled={!tableStore.tableStore?.selected.length}
          loading={mcStore.buzy === "create"}
          onClick={onCopy}
        >
          {t("Copy")}
        </Button>
      </div>
      <div className={styles.tableContent}>
        <EntityFiltersTable store={tableStore} />
      </div>
    </div>
  );
});
