import { z } from "zod";
import React from "react";
import { ZAttrViewInfo } from "../zAttrViewInfo";

export const zSimpleText = z.object({
  view: z.literal("SimpleText"),
});
export type ZSimpleText = z.infer<typeof zSimpleText>;

export const makeSimpleTextProps = (
  values: React.ReactNode[],
  viewInfo: ZAttrViewInfo | undefined,
) => ({
  values,
  viewInfo,
});
