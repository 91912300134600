// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__drawer--esmVX .ant-drawer-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px 24px;
}
.src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__drawer--esmVX .ant-drawer-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  gap: 24px;
}
.src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__drawer--esmVX .src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__entitiesTableBlock--kId91,
.src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__drawer--esmVX .src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__childTableBlock--UHBYV {
  gap: 12px;
  display: flex;
  flex-direction: column;
  height: 50%;
}
.src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__drawer--esmVX .src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__entitiesTableBlock--kId91 {
  overflow: auto;
}
.src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__drawer--esmVX .src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__childTable--c5A2p {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChildEntities/ChildEntitiesActions/CopyFrom/CopyFrom.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AALA;EASI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;AADJ;AAZA;;EAiBI,SAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AADJ;AAnBA;EAwBI,cAAA;AAFJ;AAtBA;EA4BI,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;AAHJ","sourcesContent":[".drawer {\n  :global(.ant-drawer-footer) {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    padding: 16px 24px;\n  }\n\n  :global(.ant-drawer-body) {\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    height: 100%;\n    gap: 24px;\n  }\n\n  .entitiesTableBlock, .childTableBlock {\n    gap: 12px;\n    display: flex;\n    flex-direction: column;\n    height: 50%;\n  }\n\n  .entitiesTableBlock {\n    overflow: auto;\n  }\n  \n  .childTable {\n    display: flex;\n    flex-direction: column;\n    overflow: auto;\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": `src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__drawer--esmVX`,
	"entitiesTableBlock": `src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__entitiesTableBlock--kId91`,
	"childTableBlock": `src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__childTableBlock--UHBYV`,
	"childTable": `src-components-ChildEntities-ChildEntitiesActions-CopyFrom-CopyFrom-module__childTable--c5A2p`
};
export default ___CSS_LOADER_EXPORT___;
