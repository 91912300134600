import React from "react";
import styles from "./ATVContainerStd.module.less";

export type PropsATVContainerStd = {
  children: React.ReactNode;
};

export const ATVContainerStd: React.FC<PropsATVContainerStd> = ({
  children,
}) => <div className={styles.table}>{children}</div>;
