// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__common--XBOqQ {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
}
.src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__left--HumZE {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__left--HumZE .src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__leftTop--AGdUr {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__left--HumZE .src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__leftList--yGCst {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ModelessFormTestPage/MftTab2/MftTab2.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,+BAAA;EACA,gBAAA;AACJ;AAEA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAAJ;AAHA;EAKQ,aAAA;EACA,mBAAA;EACA,SAAA;AACR;AARA;EAUQ,OAAA;AACR","sourcesContent":[".common {\n    display: grid;\n    grid-template-columns: auto 1fr;\n    column-gap: 16px;\n}\n\n.left {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    .leftTop {\n        display: flex;\n        flex-direction: row;\n        gap: 16px;\n    }\n    .leftList {\n        flex: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": `src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__common--XBOqQ`,
	"left": `src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__left--HumZE`,
	"leftTop": `src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__leftTop--AGdUr`,
	"leftList": `src-pages-ModelessFormTestPage-MftTab2-MftTab2-module__leftList--yGCst`
};
export default ___CSS_LOADER_EXPORT___;
