import { Spin } from "antd";
import * as React from "react";
import { apiObjUrl } from "src/common/apiUrl";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";

export const VersionTab: React.FC = () => {
  const [wait, setWait] = React.useState(false);
  const [data, setDatat] = React.useState<unknown>(undefined);
  React.useEffect(() => {
    setWait(true);
    rest
      .get(apiObjUrl("/info"))
      .then((res) => {
        setDatat(res.data);
      })
      .finally(() => setWait(false))
      .catch(onError);
  }, []);
  return (
    <div style={{ padding: "1rem" }}>
      {wait ? (
        <Spin size="large" />
      ) : (
        <pre>{JSON.stringify(data, null, "  ")}</pre>
      )}
    </div>
  );
};
