import { makeAutoObservable } from "mobx";
import { delay } from "src/common/delay";
import { RemoteData } from "src/common/RemoteData";
import { onError } from "src/common/onError";
import { ModelessFormStore } from "../../../components/ModelessForm/ModelessFormStore";

type Data1 = {
  firstName: string;
  lastName: string;
  position: number;
  email: string;
  comment?: string;
};

type StdOption = {
  id: number;
  name: string;
};

export class MftTab1Store {
  formStore: ModelessFormStore;

  constructor() {
    this.formStore = new ModelessFormStore(async (values: unknown) => {
      await saveData1(values as Data1); // в реальном случае нужно контролировать
    });
    makeAutoObservable(this);
  }

  async init() {
    loadPositions()
      .then((list) => this.setPositions(list))
      .catch(onError);
    try {
      this.setData({ status: "wait" });
      const result = await loadData1();
      this.setData({ status: "ready", result });
      this.formStore.safeLoad(result);
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  data: RemoteData<Data1> = { status: "none" };

  setData(newData: RemoteData<Data1>) {
    this.data = newData;
  }

  positions: StdOption[] = [];

  setPositions(list: StdOption[]) {
    this.positions = list;
  }
}

// ---------- back-end

const loadPositions = async (): Promise<StdOption[]> => {
  await delay(2000);
  return [
    { id: 1, name: "Администратор" },
    { id: 2, name: "Менеджер" },
    { id: 3, name: "Контролёр качества" },
    { id: 4, name: "Курьер" },
  ];
};

let data1: Data1 = {
  firstName: "Иван",
  lastName: "Иванов",
  position: 1,
  email: "hello@world.com",
  comment:
    "Технический контроль всех этапов разработки продукта. Архитектурный надзор. Анализ и исследование технических решений в рамках разработки проекта.",
};

const loadData1 = async (): Promise<Data1> => {
  await delay(2000);
  const text = localStorage.getItem("testTab1");
  if (text) return JSON.parse(text);
  return data1;
};

const saveData1 = async (data: Data1) => {
  await delay(2000);
  data1 = JSON.parse(JSON.stringify(data));
  localStorage.setItem("testTab1", JSON.stringify(data));
};
