/* eslint-disable import/no-extraneous-dependencies */
import { BaseOptionType } from "antd/lib/select";
import { FieldNames, DefaultOptionType } from "rc-tree-select/lib/TreeSelect";

const makeFilterKey = (defaultKey: string, fieldNames?: FieldNames) =>
  fieldNames?.label || defaultKey;

const stdFilter = (tester: string, testee: string) =>
  tester.toLocaleLowerCase().includes(testee.toLocaleLowerCase());

export const searchFilterStdTree =
  (fieldNames?: FieldNames) => (value: string, node: DefaultOptionType) =>
    // совместимо с TreeItem
    stdFilter(String(node[makeFilterKey("title", fieldNames)]), value);

export const searchFilterStdSelect =
  (fieldNames?: FieldNames) =>
  (value: string, node: BaseOptionType | DefaultOptionType | undefined) =>
    // совместимо с ZOption
    stdFilter(String(node?.[makeFilterKey("label", fieldNames)]), value);
