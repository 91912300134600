import * as React from "react";
import { observer } from "mobx-react-lite";
import { ModelEditorStore, PointIndex } from "../../ModelEditorStore";
import { Point } from "../../../math";

interface PropsDrawCtrlPoints {
  store: ModelEditorStore;
  className: string;
}

export const DrawCtrlPoints: React.FC<PropsDrawCtrlPoints> = observer(
  ({ store, className }: PropsDrawCtrlPoints) => {
    const { curAttr } = store;
    if (!curAttr) return null;
    const { xC, yC } = curAttr;
    const d = 10;
    return (
      <>
        <rect
          x={curAttr.xA - d}
          y={curAttr.yA - d}
          width={2 * d}
          height={2 * d}
          className={className}
          onMouseDown={(e) => {
            store.startMovePoint(PointIndex.begin, new Point(e.pageX, e.pageY));
          }}
        />
        <rect
          x={curAttr.xB - d}
          y={curAttr.yB - d}
          width={2 * d}
          height={2 * d}
          className={className}
          onMouseDown={(e) => {
            store.startMovePoint(PointIndex.end, new Point(e.pageX, e.pageY));
          }}
        />
        {xC !== undefined && yC !== undefined && (
          <rect
            x={xC - d}
            y={yC - d}
            width={2 * d}
            height={2 * d}
            className={className}
            onMouseDown={(e) => {
              store.startMovePoint(
                PointIndex.middle,
                new Point(e.pageX, e.pageY),
              );
            }}
          />
        )}
      </>
    );
  },
);
