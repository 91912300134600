// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-tables-FiltersForm-FiltersForm-module__box--cINo8 {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}
.src-components-tables-FiltersForm-FiltersForm-module__itemsBlock--acADf {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.src-components-tables-FiltersForm-FiltersForm-module__items--u0IOJ {
  display: flex !important;
  flex-direction: row;
  gap: 16px;
  flex-flow: wrap;
}
.src-components-tables-FiltersForm-FiltersForm-module__items--u0IOJ > * {
  margin: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/FiltersForm/FiltersForm.module.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AACF;AACA;EACE,OAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACA;EACE,wBAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AACF;AAAE;EAAO,oBAAA;AAGT","sourcesContent":[".box {\n  display: flex !important;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 16px;\n}\n.itemsBlock {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.items {\n  display: flex !important;\n  flex-direction: row;\n  gap: 16px;\n  flex-flow: wrap;\n  & > * {margin: 0 !important;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-components-tables-FiltersForm-FiltersForm-module__box--cINo8`,
	"itemsBlock": `src-components-tables-FiltersForm-FiltersForm-module__itemsBlock--acADf`,
	"items": `src-components-tables-FiltersForm-FiltersForm-module__items--u0IOJ`
};
export default ___CSS_LOADER_EXPORT___;
