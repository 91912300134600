// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-RestrictionsTable-RestrictionsTable-module__table--ymJl2 th {
  padding: 0 8px !important;
}
.src-pages-ManagementPage-Obj2Tab-forms-RestrictionsTable-RestrictionsTable-module__table--ymJl2 td {
  padding: 4px 8px !important;
}
.src-pages-ManagementPage-Obj2Tab-forms-RestrictionsTable-RestrictionsTable-module__table--ymJl2 button {
  width: 100%;
  height: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
  color: red;
}
.src-pages-ManagementPage-Obj2Tab-forms-RestrictionsTable-RestrictionsTable-module__table--ymJl2 button:hover {
  background: #FDD;
}
.src-pages-ManagementPage-Obj2Tab-forms-RestrictionsTable-RestrictionsTable-module__table--ymJl2 button:disabled {
  color: gray;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/RestrictionsTable/RestrictionsTable.module.less"],"names":[],"mappings":"AAAA;EACQ,yBAAA;AACR;AAFA;EAES,2BAAA;AAGT;AALA;EAIQ,WAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;AAIR;AAHQ;EACI,gBAAA;AAKZ;AAHQ;EACI,WAAA;AAKZ","sourcesContent":[".table {\n    th {padding: 0 8px !important;}\n    td { padding: 4px 8px !important;}\n    button {\n        width: 100%;\n        height: 1.5em;\n        background: none;\n        border: none;\n        cursor: pointer;\n        color: red;\n        &:hover {\n            background: #FDD;\n        }\n        &:disabled {\n            color: gray;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `src-pages-ManagementPage-Obj2Tab-forms-RestrictionsTable-RestrictionsTable-module__table--ymJl2`
};
export default ___CSS_LOADER_EXPORT___;
