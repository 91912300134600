// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SampleControl-module__mainBox--Rc9tt {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsSample-SampleControl-SampleControl-module__contentFrame--jWojO {
  flex: 1;
  overflow: hidden;
  position: relative;
}
.src-businessServices-services-bsSample-SampleControl-SampleControl-module__spinBox--oCeoQ {
  position: absolute;
  height: 100%;
  width: 100%;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.src-businessServices-services-bsSample-SampleControl-SampleControl-module__contentInbox--zwLPN {
  height: 100%;
  overflow: hidden;
  padding: 0 24px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SampleControl.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACA;EACI,OAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;AACJ;AACA;EACI,YAAA;EACA,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".mainBox {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n.contentFrame {\n    flex: 1;\n    overflow: hidden;\n    position: relative;\n}\n.spinBox {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n    background: none;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1;\n}\n.contentInbox {\n    height: 100%;\n    overflow: hidden;\n    padding: 0 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainBox": `src-businessServices-services-bsSample-SampleControl-SampleControl-module__mainBox--Rc9tt`,
	"contentFrame": `src-businessServices-services-bsSample-SampleControl-SampleControl-module__contentFrame--jWojO`,
	"spinBox": `src-businessServices-services-bsSample-SampleControl-SampleControl-module__spinBox--oCeoQ`,
	"contentInbox": `src-businessServices-services-bsSample-SampleControl-SampleControl-module__contentInbox--zwLPN`
};
export default ___CSS_LOADER_EXPORT___;
