import React, { useEffect, useState } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import {
  ZSeparator,
  seedSeparators,
} from "src/common/attrView/viewFormItems/Separator";
import { getViewInfo } from "src/common/attrView";
import { ZEntityField } from "src/types/ZEntity";
import { loadFileInfo } from "src/common/apiFiles";
import { onError } from "src/common/onError";

export const getFileAttrLabel = async (
  objectAttr: ZAttribute,
  values: string[] | null,
  separator?: ZSeparator,
) => {
  if (!values) return null;
  const result = await Promise.all(
    values.map(async (uid) => {
      const fileResp = await loadFileInfo(uid);
      return { fileResp, uid };
    }),
  );
  const viewInfo = getViewInfo(objectAttr.viewType);
  const actualSeparator = separator || viewInfo?.appearance?.view?.separator;
  const separetedValues = seedSeparators(
    result.map(({ fileResp, uid }) => ({
      content: fileResp.fileName,
      key: uid,
    })),
    actualSeparator,
  );
  return separetedValues;
};

type PropsFileRefInner = {
  objectAttr: ZAttribute;
  entityAttr: ZEntityField;
  separator?: ZSeparator;
};

export const FileRefInner: React.FC<PropsFileRefInner> = ({
  entityAttr,
  objectAttr,
  separator,
}) => {
  const [label, setLabel] = useState<React.ReactNode[] | null>(null);
  const init = async () => {
    try {
      setLabel(
        await getFileAttrLabel(objectAttr, entityAttr.values, separator),
      );
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return label;
};

FileRefInner.defaultProps = { separator: undefined };
