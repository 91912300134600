import { EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Tag } from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import TextArea, { TextAreaProps, TextAreaRef } from "antd/es/input/TextArea";
import { MenuProps } from "antd/lib";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";
import {
  makeFormulaAttPartView,
  transformFormula4Show,
  ZFormulaArg,
} from "src/common/attrEdit/components";
import { onError } from "src/common/onError";
import { loadFormulaArguments } from "./loadFormulaArguments";

const createStore = (objectId: number) =>
  makeAutoObservable({
    attrList: [] as ZFormulaArg[],
    setAttrList(list: ZFormulaArg[]) {
      this.attrList = list;
    },
    async init() {
      return loadFormulaArguments(objectId)
        .then(({ availableAttributes }) => {
          this.setAttrList(availableAttributes);
        })
        .catch(onError);
    },
  });

type PropsFormulaTemplateInput = Omit<
  TextAreaProps,
  "name" | "onChange" | "value"
> & {
  objectId: number;
  name: string[];
  value?: string;
  onChange?: (v: string) => void;
};

export const FormulaTemplateInput2: React.FC<PropsFormulaTemplateInput> =
  observer(({ objectId, name, value, onChange, ...props }) => {
    const store = useMemo(() => createStore(objectId), [objectId]);
    const inputRef = useRef<TextAreaRef>(null);
    const form = useFormInstance();

    const init = async () => {
      await store.init();
      const view = await transformFormula4Show(value || "", store.attrList);
      form.setFields([{ name, touched: false, value: view }]);
    };
    useEffect(() => {
      init();
    }, [store]);

    const isEmpty = store.attrList.length === 0;
    if (isEmpty)
      return <Tag color="red">Нет атрибутов для использования в формуле</Tag>;

    const items: MenuProps["items"] = store.attrList.map((arg) => ({
      key: arg.id,
      label: arg.name,
      onClick: () => {
        const { current } = inputRef;
        const position =
          current?.resizableTextArea?.textArea?.selectionStart || 0;
        const safeValue = value || "";
        const newFormula =
          safeValue.slice(0, position) +
          makeFormulaAttPartView(arg) +
          safeValue.slice(position);
        onChange?.(newFormula);
      },
    }));
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <TextArea
          {...props}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          ref={inputRef}
        />
        <Dropdown menu={{ items }}>
          <Button>
            <EditOutlined />
          </Button>
        </Dropdown>
      </Space>
    );
  });
