import { zAttribute, ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import { ZGroup } from "src/types/ZGroup";
import { ZObjectItem } from "src/types/ZObjectItem";
import { langHdr } from "src/lang/langHdr";
import { ZOption } from "src/types/ZOption";
import { t } from "i18next";
import { walk } from "src/components/TreeStd";
import { AttrTypeName } from "src/types/AttrType";
import { apiObjUrl } from "./apiUrl";
import { rest } from "./rest";
import {
  AttrLabelTreeData,
  loadAttrLabelData,
} from "./attributes/composeAttrLabel2";

export const createAttrLabelForEntityName = (
  labelData: AttrLabelTreeData,
  typesMap: Record<number, string>,
) => {
  const treeData = [labelData];
  // не выводить атрибуты этих типов в название экземпляра
  const isAllowedAttr = (attr: ZAttribute) =>
    !new Set([
      AttrTypeName.childEntities,
      AttrTypeName.file,
      AttrTypeName.usersList,
      AttrTypeName.image,
    ]).has(typesMap[attr.valueType] as AttrTypeName);
  const labels: string[] = [];
  walk((node) => {
    // отрезаем всю ветку дерева, если атрибут не нужно выводить
    if (!isAllowedAttr(node.attr)) {
      // eslint-disable-next-line no-param-reassign
      node.children = undefined;
      return;
    }
    if (node.children || node.recursion) return;
    labels.push(...node.labels);
  }, treeData);
  return labels.join();
};

type ParamsEntityName = {
  entity: ZEntity;
  object: ZObjectItem;
  typesMap: Record<number, string>;
};

export const getEntityName = async (
  params: ParamsEntityName,
): Promise<string> => {
  const { entity, object, typesMap } = params;
  const attr = findNameAttr(object);
  if (!attr) return t("Instance of object", { name: object.name });
  const field = entity.attributeValues.find(
    ({ attributeId }) => attributeId === attr.id,
  );
  const attrLabelTreeData = await loadAttrLabelData(field?.values || [], attr, {
    ctx: { entityId: entity.id },
  });

  const entityName =
    attrLabelTreeData &&
    createAttrLabelForEntityName(attrLabelTreeData, typesMap);
  return entityName || t("Instance of object", { name: object.name });
};

export const findNameAttr = (object: ZObjectItem): ZAttribute | undefined =>
  findInAttrs(object.attributes) || findInGroups(object.groups);

const findInAttrs = (attrList?: ZAttribute[] | null): ZAttribute | undefined =>
  attrList?.find(({ fieldKey }) => fieldKey === "NAME");

const findInGroup = (group: ZGroup): ZAttribute | undefined =>
  findInAttrs(group.attributes) || findInGroups(group.groups);

const findInGroups = (groups?: ZGroup[] | null): ZAttribute | undefined => {
  if (groups) {
    // eslint-disable-next-line no-restricted-syntax
    for (const group of groups) {
      const attr = findInGroup(group);
      if (attr) return attr;
    }
  }
  return undefined;
};

export const loadRefOptions = async (
  referenceId: number,
): Promise<ZOption[]> => {
  const resp = await rest.get(apiObjUrl(`/attributes/${referenceId}`), {
    headers: langHdr(),
  });
  const ref = zAttribute.parse(resp.data);
  return (
    ref.values?.map((a) => ({
      label: a.value || String(a.id),
      value: String(a.id),
    })) ?? []
  );
};
