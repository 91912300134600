// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Table-module__box--p19OL {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Table-module__header--Pio_3 {
  display: grid;
  overflow: hidden;
  background-color: #F0F0F0;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Table-module__cells--pJwR6 {
  flex: 1;
  overflow: auto;
  display: grid;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Table-module__spin--kzWK9 {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/Bom2Table/Bom2Table.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AACJ;AACA;EACI,aAAA;EACA,gBAAA;EACA,yBAAA;AACJ;AACA;EACI,OAAA;EACA,cAAA;EACA,aAAA;AACJ;AACA;EACI,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".box {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n.header {\n    display: grid;\n    overflow: hidden;\n    background-color: #F0F0F0;\n}\n.cells {\n    flex: 1;\n    overflow: auto;\n    display: grid;\n}\n.spin {\n    position: absolute;\n    z-index: 1;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Table-module__box--p19OL`,
	"header": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Table-module__header--Pio_3`,
	"cells": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Table-module__cells--pJwR6`,
	"spin": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-Bom2Table-Bom2Table-module__spin--kzWK9`
};
export default ___CSS_LOADER_EXPORT___;
