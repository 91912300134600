import * as React from "react";
import { t } from "i18next";
import { appStore } from "src/appStore";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { PageUrl } from "src/routes/PageUrl";
import { makeUrl } from "src/routes/makeUrl";
import styles from "./UserMenu.module.less";
import { ArrowIcon } from "./ArrowIcon";

interface MenuItem {
  key: string;
  label: React.ReactNode;
  onClick?(): void;
  href?: string;
  condition?(): boolean;
}

export const UserMenu: React.FC = observer(() => {
  const options: MenuItem[] = [
    {
      key: "userAccount",
      label: <Link to={makeUrl(PageUrl.userAccount)}>{t("UserAccount")}</Link>,
    },
    {
      key: "logout",
      label: (
        <a
          href="#logout"
          onClick={(e) => {
            e.preventDefault();
            appStore.logout();
          }}
        >
          {t("Logout")}
        </a>
      ),
    },
  ];
  const [open, setOpen] = React.useState(false);
  const onUserClick = () => setOpen(!open);
  const onKey = React.useCallback((e: KeyboardEvent) => {
    if (e.code === "Escape") setOpen(false);
  }, []);
  const stopMouse = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);
  const onMouseDown = React.useCallback(() => {
    setOpen(false);
  }, []);
  React.useEffect(() => {
    window.addEventListener("keyup", onKey);
    window.addEventListener("mousedown", onMouseDown);
    return () => {
      window.removeEventListener("keyup", onKey);
      window.removeEventListener("mousedown", onMouseDown);
    };
  }, []);
  const validOptions = options.filter(({ condition }) => condition?.() ?? true);
  const user = appStore.userInfo;
  if (!user) return <div />;
  let name = `${user?.firstName || ""} ${user?.secondName || ""}`;
  if (name === " ") {
    name = (user?.userName || "").split("@")[0]!;
  }
  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
  return (
    <div
      className={styles.userMenu}
      onClick={onUserClick}
      onMouseDown={stopMouse}
    >
      <div className={styles.menuContainer} data-testid="curUser-menu">
        <span className={styles.userName}>{name}</span>
        <ArrowIcon className={`${styles.arrow} ${open ? styles.open : ""}`} />
        {open && (
          <menu
            className={styles.menu}
            onClick={() => setOpen(false)}
            onMouseDown={stopMouse}
          >
            {validOptions.map(({ key, label }) => (
              <div key={key} className={styles.menuItem}>
                {label}
              </div>
            ))}
          </menu>
        )}
      </div>
    </div>
  );
});
