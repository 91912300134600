/* eslint-disable react/require-default-props */
import * as React from "react";
import { onError } from "src/common/onError";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import styles from "./LinkView.module.less";

type PropsLinkView = {
  values?: string[];
  viewInfo?: ZAttrViewInfo;
};

export const LinkView: React.FC<PropsLinkView> = ({ values, viewInfo }) => {
  const ref = values?.[0];
  if (!ref) return null;
  const overflow = styles[viewInfo?.appearance?.view?.overflow || ""];
  let label = ref;
  try {
    label = decodeURIComponent(ref);
  } catch (e) {
    onError(e);
  }
  return (
    <div className={overflow} style={{ ...viewInfo?.styles }}>
      <a
        href={ref}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {label}
      </a>
    </div>
  );
};
