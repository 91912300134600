import * as React from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";

/* eslint react/jsx-props-no-spreading: "off" */

/**
 * Стандартный компонент для числового ввода
 * Учитывается precision. Для целочисленных полей нужно указывать precision=0
 * @param props Пропсы от компонента InputNumber
 * @constructor
 */
export const InputNumberStd: React.FC<InputNumberProps> = (
  props: InputNumberProps,
) => (
  <InputNumber {...props} parser={(value) => value?.replace(",", ".") ?? ""} />
);
