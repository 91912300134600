import * as React from "react";
import { Modal, Button, Form } from "antd";
import { ZCatAttr } from "src/types/ZCatAttr";
import {
  FormBlockDef,
  FormWithBlockStore,
  FormWithBlocks,
} from "src/components/FormWithBlocks";
import { blockFields } from "src/components/FormWithBlocks/blockTypes";
import { CategoryAttributesStore } from "../../CategoryAttributesStore";
import { catAttrToEdit, cvtEditToSubmit, EditParams } from "./AttrFields";
import { attrFieldsWithType } from "./attrFieldsWithType";

interface PropsEditAttrModal {
  open: boolean;
  close(): void;
  attrStore: CategoryAttributesStore;
}

const formName = "EditAttr";

export const EditAttrModal: React.FC<PropsEditAttrModal> = ({
  open,
  close,
  attrStore,
}: PropsEditAttrModal) => {
  const [formStore] = React.useState(new FormWithBlockStore());
  const [form] = Form.useForm();
  React.useEffect(() => {
    const { curAttr } = attrStore;
    if (open && curAttr) {
      setTimeout(() => form.setFieldsValue(catAttrToEdit(curAttr)), 20);
    }
  }, [open]);
  const submit = async (values: EditParams): Promise<EditParams> => {
    const { curAttr } = attrStore;
    if (curAttr) {
      const newData: ZCatAttr = {
        ...curAttr,
        ...cvtEditToSubmit(values, curAttr.categoryId),
      };
      await attrStore.onEdit(newData);
    }
    return values;
  };
  const block: FormBlockDef = blockFields("root", { monoColumn: true }, [
    ...attrFieldsWithType(attrStore.curAttr?.type, attrStore, "edit"),
  ]);

  return (
    <Modal
      title="Редактирование атрибута"
      open={open}
      onCancel={close}
      destroyOnClose
      footer={null}
    >
      <FormWithBlocks
        name={formName}
        form={form}
        store={formStore}
        block={block}
        submitText="Применить"
        submit={submit}
        formSize="middle"
        buttonsSize="middle"
        onSuccess={close}
        extraButtons={<Button onClick={close}>Отмена</Button>}
      />
    </Modal>
  );
};
