import { rest } from "src/common/rest";
import {
  zMeasurementChart,
  ZMeasurementChart,
  ZSampleMcPoint,
} from "src/businessServices/services/bsMeasurementChart/ZMeasurementChart";
import {
  ZPhotoComment,
  ZPhotoCommentResponse,
  zPhotoCommentResponse,
} from "./ZPhotoComment";
import { apiSample } from "../../../apiSample";

type ParamsSampleIds = {
  modelId: number | string;
  sampleRequestId: number | string;
  sampleId: number | string;
};

export const loadPhotoComments = async (
  params: ParamsSampleIds & {
    page: number;
    size: number;
    query?: string;
  },
): Promise<ZPhotoCommentResponse> => {
  const resp = await rest.get(apiSample("/photo-comment"), { params });
  return zPhotoCommentResponse.parse(resp.data);
};

export const createPhotoComment = async (
  params: ParamsSampleIds,
  data: Omit<ZPhotoComment, "id">,
) => {
  await rest.post(apiSample("/photo-comment"), data, { params });
};

export const deletePhotoComments = async (
  params: ParamsSampleIds,
  data: (number | string)[],
) => {
  await rest.delete(apiSample("/photo-comment"), { params, data });
};

// -----

export const loadSampleMcPoints = async (
  params: ParamsSampleIds,
): Promise<ZMeasurementChart> => {
  const resp = await rest.get(apiSample("/mc-sample"), { params });
  return zMeasurementChart.parse(resp.data);
};

export const saveSampleMcPoint = async (
  params: ParamsSampleIds,
  data: ZSampleMcPoint,
): Promise<void> => {
  await rest.put(apiSample("/mc-sample"), data, { params });
};
