// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__columnResizeHandle--F4mxc {
  position: absolute;
  right: 0;
  height: 100%;
  width: 13px;
  display: flex;
  cursor: col-resize;
  background-color: transparent;
  z-index: 1;
}
.src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__columnResizeHandle--F4mxc .src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__icon--JulDx {
  display: none;
  transform: rotate(90deg);
}
.src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__columnResizeHandle--F4mxc:hover {
  border-left: 2px solid #e2e2e2;
}
.src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__columnResizeHandle--F4mxc:hover .src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__icon--JulDx {
  display: inline-flex;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AsyncTableView/ResizerStd/ResizerStd.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,6BAAA;EACA,UAAA;AACF;AAAE;EACE,aAAA;EACA,wBAAA;AAEJ;AAAE;EACE,8BAAA;AAEJ;AAHE;EAGI,oBAAA;AAGN","sourcesContent":[".columnResizeHandle {\n  position: absolute;\n  right: 0;\n  height: 100%;\n  width: 13px;\n  display: flex;\n  cursor: col-resize;\n  background-color: transparent;\n  z-index: 1;\n  & .icon {\n    display: none;\n    transform: rotate(90deg);\n  }\n  &:hover {\n    border-left: 2px solid #e2e2e2;\n    .icon {\n      display: inline-flex;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnResizeHandle": `src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__columnResizeHandle--F4mxc`,
	"icon": `src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__icon--JulDx`
};
export default ___CSS_LOADER_EXPORT___;
