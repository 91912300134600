/**
 * @see http://dev.plm.d4r.int:8066/ms-object/swagger-ui/index.html#/role-controller/get
 */

import { apiAdminUrl, apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { ZObjectItem, zObjectItem } from "src/types/ZObjectItem";
import { ZAttribute, zAttribute } from "src/types/ZAttribute";
import { ZRolesGroup, zRolesGroup } from "../Obj2Tab/roles/roleTypes";
import { ZLightObject } from "../Obj2Tab/Obj2Nodes";
import { ZRoleFields } from "./RolesTab.types";

export const apiLoadRoles = async (): Promise<ZObjectItem[]> => {
  const resp = await rest.get(apiObjUrl("/roles"));
  return zObjectItem.array().parse(resp.data);
};

export const apiCreateRolesGroup = async (
  group: Omit<ZRolesGroup, "groupId">,
): Promise<ZRolesGroup> => {
  const resp = await rest.post(apiAdminUrl("/groups"), group);
  return zRolesGroup.parse(resp.data);
};

export const apiUpdateRolesGroup = async (group: ZRolesGroup) => {
  await rest.put(apiAdminUrl("/groups"), group);
};

export const apiDeleteRolesGroup = async (group: ZRolesGroup) => {
  await rest.delete(apiAdminUrl(`/groups/${group.groupId}`));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const apiDeleteRoleObject = async (roleObj: ZLightObject) => {
  await rest.delete(apiObjUrl(`/roles/${roleObj.id}`));
};

export const apiDeleteRoleAttr = async (ownerId: number, attrId: number) => {
  await rest.delete(apiObjUrl(`/roles/${ownerId}/attributes/${attrId}`));
};

export const apiCreateRole = async (
  groupId: number,
  data: ZRoleFields,
): Promise<ZObjectItem> => {
  const { isOnAttributes, isOnObjects, sourceRoleId, ...other } = data;
  const resp = await rest.post(apiObjUrl("/roles"), other, {
    params: { groupId, isOnAttributes, isOnObjects, sourceRoleId },
  });
  return zObjectItem.parse(resp.data);
};

export const apiUpdateRole = async (roleId: number, data: unknown) => {
  await rest.put(apiObjUrl(`/roles/${roleId}`), data);
};

export const apiCreateRoleAttribute = async (
  roleObj: ZLightObject,
  values: unknown,
): Promise<ZAttribute> => {
  const resp = await rest.post(
    apiObjUrl(`/roles/${roleObj.id}/attributes`),
    values,
  );
  return zAttribute.parse(resp.data);
};
