import * as React from "react";
import { CommonPickerMethods } from "antd/lib/date-picker/generatePicker/interface";
import { CellBox, FnFocus, PropsCellBox } from "./CellBox";
import { DatePickerString, PropsDatePickerString } from "../DatePickerString";

type PropsCellBoxDate<TValue, Res> = Omit<
  PropsCellBox<TValue, Res>,
  "children"
> & {
  pickerProps?: Omit<PropsDatePickerString, "onBlur" | "onChange" | "value">;
};

type BaseValue = string | null;

export const CellBoxDate = <TValue extends BaseValue, Res = void>(
  props: PropsCellBoxDate<TValue, Res>,
) => {
  const { pickerProps, ...cellProps } = props;
  return (
    <CellBox<TValue, Res> {...cellProps}>
      {(chProps) => (
        <CellBoxDateChildren {...chProps} pickerProps={pickerProps} />
      )}
    </CellBox>
  );
};

type PropsCellBoxDateChildren<TValue extends BaseValue> = {
  value: TValue | undefined;
  onChange: (v?: TValue) => void;
  onBlur: () => void;
  pickerProps: PropsCellBoxDate<TValue, void>["pickerProps"];
  getFocus: (fn: FnFocus) => void;
};

const CellBoxDateChildren = <TValue extends BaseValue>(
  props: PropsCellBoxDateChildren<TValue>,
) => {
  const { value, onChange, onBlur, getFocus, pickerProps } = props;
  const ref = React.useRef<CommonPickerMethods>(null);
  React.useEffect(() => {
    getFocus(() => {
      ref.current?.focus();
    });
    return () => getFocus(undefined);
  }, [ref.current]);
  return (
    <DatePickerString
      {...pickerProps}
      style={{ ...pickerProps?.style, width: "100%" }}
      value={value ?? undefined}
      onChange={(newValue?: string) => onChange(newValue as TValue)}
      onBlur={onBlur}
      ref={ref}
    />
  );
};
