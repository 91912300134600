import { z } from "zod";

export interface ZTreeCategoryNode {
  id: number;
  key: string;
  name: string;
  parentId: number | null;
  hasChildren: boolean;
  selectable?: boolean | null;
  active?: boolean | null;
  children?: ZTreeCategoryNode[] | null;
  order: number;
}

export const zTreeCategoryNode: z.ZodType<ZTreeCategoryNode> = z.lazy(() =>
  z.object({
    id: z.number(),
    key: z.string(),
    name: z.string(),
    parentId: z.number().nullable(),
    hasChildren: z.boolean(),
    selectable: z.boolean().nullable().optional(),
    active: z.boolean().nullable().optional(),
    order: z.number(),
    children: z.array(zTreeCategoryNode).nullable().optional(),
  }),
);
