import { Form, Select } from "antd";
import { SelectProps } from "antd/lib";
import { t } from "i18next";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useWatch } from "rc-field-form";
import React, { useEffect, useState } from "react";
import { apiObjUrl } from "src/common/apiUrl";
import { makeDictionary } from "src/common/makeDictionary";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";
import { validationRules } from "src/common/validationRules/validationRules";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectItem, zObjectItem } from "src/types/ZObjectItem";

const createRoleAttsLoader = (roleId: string) => async () => {
  try {
    const resp = await rest.get(apiObjUrl(`/roles/${roleId}`));
    return zObjectItem.parse(resp.data).attributes;
  } catch (error) {
    onError(error);
    return [];
  }
};

type PropsSelectAttributesRoles = Omit<SelectProps, "options"> & {
  loader: () => Promise<ZAttribute[]>;
};

const SelectAttributesRoles: React.FC<PropsSelectAttributesRoles> = observer(
  ({ loader, ...props }) => {
    const [atts, setAtts] = useState<ZAttribute[]>([]);
    const options = atts.map((a) => ({
      label: a.name,
      value: String(a.id),
    }));
    useEffect(() => {
      loader().then(setAtts).catch(onError);
    }, []);
    return <Select {...props} options={options} />;
  },
);

const store = makeAutoObservable({
  rolesMap: null as Record<string, ZObjectItem> | null,
  setRolesMap(map: Record<string, ZObjectItem>) {
    this.rolesMap = map;
  },
  async init() {
    try {
      const resp = await rest.get(apiObjUrl("/roles"));
      const roles = zObjectItem.array().parse(resp.data);
      this.setRolesMap(makeDictionary(roles, ({ roleId }) => String(roleId)));
    } catch (error) {
      onError(error);
    }
  },
  getRoleId(key: string) {
    return this.rolesMap?.[key] ? String(this.rolesMap[key]?.id) : undefined;
  },
  getRoleLabel(key: string) {
    return this.rolesMap?.[key] ? String(this.rolesMap[key]?.name) : undefined;
  },
});

type PropsSelectAttributesRolesList = { rolesIds: string[]; name: string[] };

export const SelectAttributesRolesList: React.FC<PropsSelectAttributesRolesList> =
  observer(({ rolesIds, name }) => {
    const form = Form.useFormInstance();
    const formData = useWatch(name);

    useEffect(() => {
      store.init();
    }, []);

    const setRolesData = (roleId: string, attsIds: string[]) => {
      const newData: Record<string, string[]> = {};
      rolesIds.forEach((id) => {
        const key = store.getRoleId(id);
        const piece = key ? formData[key] : undefined;
        if (key && piece) newData[key] = piece;
      });
      form.setFieldValue(name, { ...newData, [roleId]: attsIds });
    };

    if (!store.rolesMap) return null;

    return rolesIds.map((id) => {
      const roleId = store.getRoleId(id);
      const roleLabel = store.getRoleLabel(id);
      if (!roleId) return null;
      return (
        <Form.Item
          label={t('Attributes to display the role "{{role}}"', {
            role: roleLabel,
          })}
          key={id}
          rules={[validationRules.required()]}
          name={[...name, roleId]}
        >
          <SelectAttributesRoles
            mode="multiple"
            loader={createRoleAttsLoader(roleId)}
            onChange={(value) => {
              setRolesData(roleId, value as string[]);
            }}
          />
        </Form.Item>
      );
    });
  });
