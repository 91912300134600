// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-AuthChecker-overlays-LoginPage-LoginPage-module__resetPasswordLink--djHxl {
  font-size: 14px;
  padding: 0;
  width: max-content;
}
.src-components-AuthChecker-overlays-LoginPage-LoginPage-module__buttons--ON6qX {
  padding-top: 16px;
}
.src-components-AuthChecker-overlays-LoginPage-LoginPage-module__buttons--ON6qX button {
  width: 100%;
}
.src-components-AuthChecker-overlays-LoginPage-LoginPage-module__buttons--ON6qX .ant-divider {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/AuthChecker/overlays/LoginPage/LoginPage.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,UAAA;EACA,kBAAA;AACF;AAEA;EACE,iBAAA;AAAF;AADA;EAII,WAAA;AAAJ;AAJA;EAQI,SAAA;AADJ","sourcesContent":[".resetPasswordLink {\n  font-size: 14px;\n  padding: 0;\n  width: max-content;\n}\n\n.buttons {\n  padding-top: 16px;\n\n  button{\n    width: 100%;\n  }\n\n  :global(.ant-divider) {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetPasswordLink": `src-components-AuthChecker-overlays-LoginPage-LoginPage-module__resetPasswordLink--djHxl`,
	"buttons": `src-components-AuthChecker-overlays-LoginPage-LoginPage-module__buttons--ON6qX`
};
export default ___CSS_LOADER_EXPORT___;
