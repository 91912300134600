import * as React from "react";

type CommonNode = {
  key: React.Key;
  children?: CommonNode[];
};

type Result<T extends CommonNode> = {
  owner: T;
  node: T;
  index: number;
};

export const findNodeOwnerByKey = <T extends CommonNode>(
  nodeKey: React.Key,
  nodes: T[],
): Result<T> | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of nodes) {
    const children = node.children as T[] | undefined;
    // eslint-disable-next-line no-continue
    if (!children) continue;
    const index = children.findIndex(({ key }) => key === nodeKey);
    if (index >= 0) {
      return { owner: node, node: children[index]! as T, index };
    }
    const res = findNodeOwnerByKey(nodeKey, children);
    if (res) return res;
  }
  return undefined;
};
