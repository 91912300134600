import { Input } from "antd";
import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { DebounceCounter, debounce } from "src/common/debounce";
import { observer } from "mobx-react-lite";
import { CtrlFiltration } from "src/components/tables/TableStore";
import { InputProps } from "antd/lib";
import { t } from "i18next";
import { ZEntityFilters } from "./types";

const debounceCounter: DebounceCounter = {};

type PropsInputSearch = InputProps & { store: CtrlFiltration<ZEntityFilters> };

export const InputSearch: React.FC<PropsInputSearch> = observer(
  ({ store, ...props }) => {
    const [query, setQuery] = useState(store.filters?.query);
    return (
      <Input
        {...props}
        allowClear
        value={query}
        addonBefore={<SearchOutlined />}
        placeholder={t("Search")}
        onChange={(e) => {
          setQuery(e.target.value);
          debounce(debounceCounter, 300, () => {
            store.setFilters({
              ...store.filters,
              query: e.target.value,
            });
          });
        }}
      />
    );
  },
);
