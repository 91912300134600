/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import * as React from "react";
import { Flex, Modal, ModalProps } from "antd";
import { classNames } from "src/common/classNames";
import {
  ConfirmationActionsPlm,
  PropsConfirmationActionsPlm,
} from "src/components/ConfirmationActionsPlm";
import styles from "./ModalPlm.module.less";

type PropsModalPlm = ModalProps & {
  height?: number | string;
};

export const ModalPlm: React.FC<PropsModalPlm> = (props) => {
  const {
    title,
    classNames: classNamesProp = {},
    okText,
    okButtonProps,
    onOk,
    cancelButtonProps,
    cancelText,
    onCancel,
    footer = null,
    ...restProps
  } = props;

  const confirmationProps: PropsConfirmationActionsPlm = {
    okText,
    cancelText,
    okButtonProps: {
      ...okButtonProps,
      onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onOk?.(e);
        okButtonProps?.onClick?.(e);
      },
    },
    cancelButtonProps: {
      ...cancelButtonProps,
      onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onCancel?.(e);
        cancelButtonProps?.onClick?.(e);
      },
    },
  };

  return (
    <Modal
      {...restProps}
      onCancel={onCancel}
      classNames={{
        ...classNamesProp,
        header: classNames([styles.header, classNamesProp.header]),
        content: classNames([styles.content, classNamesProp.content]),
        body: classNames([styles.body, classNamesProp.body]),
        footer: classNames([styles.footer, classNamesProp.footer]),
      }}
      title={
        <Flex justify="space-between" align="center">
          <div className={styles.title}>{title}</div>
          <ConfirmationActionsPlm {...confirmationProps} />
        </Flex>
      }
      footer={footer ?? null}
    />
  );
};

ModalPlm.defaultProps = {
  height: "80vh",
  closable: false,
};
