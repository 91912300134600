/**
 * Преобразование строкового представления (которое используется для JSON) в RegExp
 * @param str Строка в формате /pattern/flags. flags могут быть пустыми, pattern - нет
 * @returns
 */
export const str2Regexp = (str: string): RegExp => {
  if (str[0] !== "/") throw Error("Регулярное выражение должно начинаться с /");
  const last = str.lastIndexOf("/");
  if (last === 0) throw Error("Регулярное выражение должно заканчиваться /");
  const pattern = str.slice(1, last);
  if (pattern.length === 0) throw Error("Не должно быть пустого выражения");
  const flags = str.slice(last + 1);
  return new RegExp(pattern, flags);
};

export const safeStr2Regexp = (
  str: string | null | undefined,
): RegExp | undefined => {
  if (!str) return undefined;
  try {
    return str2Regexp(str);
  } catch (e) {
    return undefined;
  }
};
