import React from "react";
import { Form, FormInstance, Menu } from "antd";
import { MenuProps } from "antd/lib";
import { ChatFormStore } from "../ChatFormStore";
import { chatFormNames } from "../chatFromNames";
import { getMentionReplace, mentionRegExp } from "./mentionUtils";

type PropsMentionList = {
  store: ChatFormStore;
  form: FormInstance;
};
export const MentionList: React.FC<PropsMentionList> = ({ store, form }) => {
  const { personsDict, chatInfo } = store;
  const msg = String(Form.useWatch([chatFormNames.message], form));
  const items: MenuProps["items"] = chatInfo?.userIds.map((i) => ({
    label: personsDict[i]?.userLogin,
    key: i,
    onClick: () => {
      const userName = personsDict[i]?.userLogin || "";
      const replace = getMentionReplace(userName);
      const newMsg = msg.replace(mentionRegExp, replace);
      form.setFieldValue(chatFormNames.message, newMsg);
    },
  }));
  return <Menu items={items} mode="inline" />;
};
