import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Slider } from "antd";
import { TimelineDemoStore } from "../TimelineDemo/TimelineDemoStore";
import styles from "./TimelineToolbar.module.less";

interface PropsTimelineToolbar {
  store: TimelineDemoStore;
}

export const TimelineToolbar: React.FC<PropsTimelineToolbar> = observer(
  ({ store }) => (
    <div className={styles.toolbarBox}>
      <Button onClick={() => store.expandAll(true)}>Развернуть все</Button>
      <Button onClick={() => store.expandAll(false)}>Свернуть все</Button>
      <div className={styles.dayWidth}>
        <div>Масштаб</div>
        <Slider
          value={store.dayWidth}
          onChange={(v) => store.setDayWidth(v)}
          min={30}
          max={100}
        />
      </div>
    </div>
  ),
);
