import { AxiosResponse, AxiosRequestConfig } from "axios";
import { rest } from "./rest";

type Headers = AxiosRequestConfig["headers"];

/**
 * Примитивный кеш для get-запросов к rest
 */
export const restCache = {
  /**
   *
   * @param url
   * @param params Важен порядок следования параметров. Т.е. {a:1,b:1} не равно {b:1,a:1}. Это конечно неправильно, но пока не хочется усложнять
   */
  get(url: string, params: object, headers?: Headers): Promise<AxiosResponse> {
    const key = makeKey(url, params, headers);
    let item = dict.get(key);
    if (!item) {
      const waitItem: WaitItem = { type: "wait", subscribers: [] };
      item = waitItem;
      dict.set(key, item);
      rest
        .get(url, { params, headers })
        .then((response) => {
          dict.set(key, { type: "ready", response });
          waitItem.subscribers.forEach(({ resolve }) => resolve(response));
        })
        .catch((error) => {
          dict.set(key, { type: "error", error });
          waitItem.subscribers.forEach(({ reject }) => reject(error));
        });
    }
    if (item.type === "wait") {
      const { subscribers } = item;
      return new Promise((resolve, reject) => {
        subscribers.push({ resolve, reject });
      });
    }
    if (item.type === "error") {
      return Promise.reject(item.error);
    }
    return Promise.resolve(item.response);
  },
  invalidate() {
    dict.clear();
  },
};

type Subscriber = {
  resolve(response: AxiosResponse): void;
  reject(error: Error): void;
};

type WaitItem = {
  type: "wait";
  subscribers: Subscriber[];
};
type ReadyItem = {
  type: "ready";
  response: AxiosResponse;
};
type ErrorItem = {
  type: "error";
  error: Error;
};
type CacheItem = ErrorItem | ReadyItem | WaitItem;

const makeKey = (url: string, params: object, headers?: Headers): string =>
  JSON.stringify({ url, params, headers });

const dict = new Map<string, CacheItem>();
