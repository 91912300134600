import * as React from "react";
import { PropsSelectFromTable, SelectFromTable } from "./SelectFromTable";

type PropsSelectFromTableSingle<Row extends {}, Filter extends object> = Omit<
  PropsSelectFromTable<Row, Filter>,
  "maxCount" | "onChange" | "value"
> & {
  value?: Row;
  onChange?: (newValue?: Row) => void;
};

export const SelectFromTableSingle = <
  Row extends {},
  Filter extends object = {},
>(
  props: PropsSelectFromTableSingle<Row, Filter>,
): React.ReactElement => {
  const { value, onChange, ...restProps } = props;
  return (
    <SelectFromTable<Row, Filter>
      value={value ? [value] : []}
      onChange={(newValues) => onChange?.(newValues[0])}
      maxCount={1}
      {...restProps}
    />
  );
};
