import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tabs } from "antd";
import { PageLayout } from "src/components/PageLayout";
import { useModelessNavProtector } from "src/components/ModelessForm";
import {
  MFTKey,
  modelessFormTestStore as pageStore,
} from "./modelessFormTestStore";

import { MftTab1 } from "./MftTab1";
import { MftTab2 } from "./MftTab2";

type Tab = {
  label: string;
  children: React.ReactNode;
};

const tabsDict: Record<MFTKey, Tab> = {
  tab1: {
    label: "First",
    children: <MftTab1 store={pageStore.storeTab1} />,
  },
  tab2: {
    label: "Second",
    children: <MftTab2 store={pageStore.storeTab2} />,
  },
};
const tabItems = Object.entries(tabsDict).map(([key, value]) => ({
  key,
  ...value,
}));

export const ModelessFormTestPage: React.FC = observer(() => {
  useModelessNavProtector(pageStore.formStores);
  return (
    <PageLayout>
      <div style={{ padding: "0 16px" }}>
        <Tabs
          items={tabItems}
          activeKey={pageStore.curTab}
          onChange={(key) => pageStore.safeSetCurTab(key as unknown as MFTKey)}
        />
      </div>
    </PageLayout>
  );
});
