import * as React from "react";
import iconTrue from "./iconTrue.svg";
import iconFalse from "./iconFalse.svg";

interface PropsBoolView {
  value: boolean | null | undefined;
}

export const BoolView: React.FC<PropsBoolView> = ({ value }) => {
  if (value === true) return <img src={iconTrue} alt="" />;
  if (value === false) return <img src={iconFalse} alt="" />;
  return null;
};
