import React from "react";
import { Form, Switch } from "antd";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import { DrawTab } from "./DrawTab";
import {
  ZSchedSettingsTaskTemplate,
  ZSchedulingSettings,
} from "../../SchedulingTypes";
import { TaskTiesSection } from "./sections";
import { SchedulingObjType } from "../SchedulingSettingsStore";

interface PropsTaskTemplateFields {
  store: FormObjectSectionsStore<SchedulingObjType>;
}

const fieldName = (
  name: keyof ZSchedSettingsTaskTemplate,
): ["taskTemplateSettings", keyof ZSchedSettingsTaskTemplate] => [
  "taskTemplateSettings",
  name,
];

export const TaskTemplateFields: React.FC<PropsTaskTemplateFields> = observer(
  ({ store }) => (
    <DrawTab>
      <Form.Item
        name={fieldName("showByCondition")}
        label="Отображать шаблон в зависимости от состояния объекта"
      >
        <Switch />
      </Form.Item>
      <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
        store={store}
        objKey={fieldName("templateObjectId")}
        objType="taskTemplate"
        objLabel="Объект для формирования шаблона задачи"
        title="Список обязательных позиций"
        attrs={[
          {
            attrKey: fieldName("nameAttributeId"),
            attrLabel: "Укажите атрибут названия задачи",
            attrType: AttrTypeName.string,
          },
          {
            attrKey: fieldName("durationAttributeId"),
            attrLabel: "Укажите атрибут длительности задачи",
            attrType: AttrTypeName.int,
          },
          {
            attrKey: fieldName("groupAttributeId"),
            attrLabel: "Укажите атрибут принадлежности задачи к группе задач",
            attrType: AttrTypeName.object,
          },
          {
            attrKey: fieldName("divisionLinkAttributeId"),
            attrLabel: "Укажите атрибут принадлежности к подразделению",
            attrType: AttrTypeName.object,
          },
          {
            attrKey: fieldName("performerLinkAttributeId"),
            attrLabel: "Укажите атрибут исполнителя",
            attrType: AttrTypeName.object,
          },
        ]}
      />
      <TaskTiesSection
        objType="taskTemplateTies"
        tabKey="taskTemplateSettings"
        store={store}
      />
    </DrawTab>
  ),
);
