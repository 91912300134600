import React from "react";
import { classNames } from "src/common/classNames";
import { ZRCAttachment } from "../../../../rocketChat/rocketChat.types";
import {
  AttachmentRenderParams,
  defineAttachment,
} from "../defineAttachment/defineAttachment";
import styles from "./ChatMsg.module.less";

type TypeAttachmentDef = ZRCAttachment & {
  key: React.Key;
};

type PropsChatMsg = {
  msgText: string;
  attachments: TypeAttachmentDef[];
  renderParams: AttachmentRenderParams;
  className?: string;
};

export const ChatMsg: React.FC<PropsChatMsg> = ({
  attachments,
  msgText,
  className,
  renderParams,
}) => {
  const { createMsg } = renderParams;
  return (
    <div className={classNames([styles.box, className])}>
      {msgText && <div>{createMsg(msgText)}</div>}
      {attachments?.map((item) => (
        <React.Fragment key={item.key}>
          {defineAttachment(item, renderParams)}
        </React.Fragment>
      ))}
    </div>
  );
};

ChatMsg.defaultProps = {
  className: undefined,
};
