import { TooltipProps } from "antd";
import * as React from "react";
import { ZAttrItemProps } from "src/common/attrEdit";
import { TooltipPlacement } from "antd/es/tooltip";
import { TooltipMode, getTooltipMode } from "./getTooltipMode";
import { ExtendedTooltipInner } from "./ExtendedTooltipInner";

/**
 * Создание правильного значения для пропса tooltip компонента <Form.Item>
 * @param itemProps
 */
export const createTooltipProperty = (
  itemProps: ZAttrItemProps,
): TooltipProps | string | undefined => {
  const mode = getTooltipMode(itemProps);
  return modeDict[mode]?.(itemProps);
};
const modeDict: Record<
  TooltipMode,
  (itemProps: ZAttrItemProps) => TooltipProps | string | undefined
> = {
  none: () => undefined,
  text: ({ tooltip }) => ({
    title: tooltip,
    color: "white",
    overlayInnerStyle: {
      color: "rgba(0, 0, 0, 0.85)",
    },
  }),
  ext: ({ tooltip, tooltipExt }) => ({
    placement: tooltipExt?.placement as TooltipPlacement,
    title: (
      <ExtendedTooltipInner tooltipData={{ ...tooltipExt, text: tooltip }} />
    ),
    color: "white",
  }),
};
