import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import { loadSampleSettings, saveSampleSettings } from "./apiSample";
import { zSampleSettings } from "./SampleTypes";
import { SampleSettings } from "./SampleSettings";
import { SampleControl } from "./SampleControl";

export const bsSample: BusinessServiceDescriptor = {
  key: "Sample",
  viewer: (_info, data) => <SampleControl entityData={data} />,
  renderSettings: (_info, store) => (
    <SampleSettings typesMap={store.attrTypesDict} />
  ),
  loadSettings: async (info) => loadSampleSettings(info.objectId),
  async saveSettings(info, values) {
    const { objectId, id } = info;
    const settings = zSampleSettings.parse(values);
    // временно отправляем вместо версии просто 1
    await saveSampleSettings(objectId, id, 1, settings);
  },
};
