import * as React from "react";
import { Button, Form, FormRule, Input, InputRef } from "antd";
import { DeleteOutlined, SisternodeOutlined } from "@ant-design/icons";
import { ZDictGroup } from "src/types/ZGroup";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { safeFocus } from "src/common/safeFocus";
import { Dict2TabStore } from "../Dict2TabStore";
import { WidthLimitedFields } from "../../WidthLimitedFields";
import { ExtraTools } from "../../ExtraTools";

interface PropsGroupFields2 {
  store: Dict2TabStore;
}

export const GroupFields2: React.FC<PropsGroupFields2> = ({ store }) => {
  const ref1 = React.useRef<InputRef>(null);
  React.useEffect(() => {
    safeFocus(ref1.current);
  }, [store.currentItem]);
  return (
    <WidthLimitedFields>
      <Form.Item
        name="name"
        label="Название группы"
        rules={[
          trimRequired(),
          validateUniqueName(store.groupsList, store.currentItem?.id),
        ]}
      >
        <Input ref={ref1} allowClear />
      </Form.Item>
    </WidthLimitedFields>
  );
};

const validateUniqueName = (
  groupsList: ZDictGroup[],
  currentId: number | undefined,
): FormRule => ({
  validator: (rule, value) => {
    if (
      value &&
      groupsList.find(({ id, name }) => id !== currentId && name === value)
    ) {
      return Promise.reject(Error("Название уже использовано"));
    }
    return Promise.resolve();
  },
});

export const GroupFields2Buttons: React.FC<PropsGroupFields2> = ({ store }) => (
  <ExtraTools>
    <Button icon={<SisternodeOutlined />} onClick={() => store.safeAddAttr()}>
      Добавить справочник
    </Button>
    <Button
      icon={<DeleteOutlined />}
      danger
      onClick={() => {
        store.startDeleteCurNode();
      }}
    >
      Удалить группу
    </Button>
  </ExtraTools>
);
