// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-FormWithBlocks-FormWithBlock-module__layout--_JWsF {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.src-components-FormWithBlocks-FormWithBlock-module__blocksBox--ud7v3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px 16px 16px;
  border-radius: 4px;
}
.src-components-FormWithBlocks-FormWithBlock-module__buttons--JBFwq {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;
}
.src-components-FormWithBlocks-FormWithBlock-module__blockCollapse--meEWM {
  margin: 24px 0 24px 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormWithBlocks/FormWithBlock.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;AAGA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,4BAAA;EACA,kBAAA;AADF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AADF;AAGA;EACE,gCAAA;AADF","sourcesContent":["@import \"../Forms/formStyles.less\";\n\n.layout {\n  display: flex;\n  flex-direction: column;\n  gap: 18px;\n}\n.blocksBox {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 16px 24px 16px 16px;\n  border-radius: 4px;\n}\n.buttons {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 18px;\n}\n.blockCollapse {\n  margin: @blockVPad 0 @blockVPad 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `src-components-FormWithBlocks-FormWithBlock-module__layout--_JWsF`,
	"blocksBox": `src-components-FormWithBlocks-FormWithBlock-module__blocksBox--ud7v3`,
	"buttons": `src-components-FormWithBlocks-FormWithBlock-module__buttons--JBFwq`,
	"blockCollapse": `src-components-FormWithBlocks-FormWithBlock-module__blockCollapse--meEWM`
};
export default ___CSS_LOADER_EXPORT___;
