import { AttrTypeName } from "src/types/AttrType";
import { findNodeOwnerByKey } from "src/common/findNodeOwnerByKey";
import { GroupType, ZGroup } from "src/types/ZGroup";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZAttribute } from "src/types/ZAttribute";
import { flattenItems } from "src/common/flattenItems";
import { AttributeO2, CommonNodeO2 } from "./Obj2Tab/Obj2Nodes";
import { findNodeOwnerObject } from "./Obj2Tab/utils/findNodeOwnerObject";

export type ObjIteratorMode = "blocked" | "disabled" | "enabled" | "hidden";
const iterableTypes = new Set([AttrTypeName.object, AttrTypeName.image]);
export const isIteratorAvailable = (
  attrNode: AttributeO2,
  typeName: AttrTypeName, // Нельзя брать тип из атрибута, т.к. он может меняться на форме
  tree: CommonNodeO2[],
): ObjIteratorMode => {
  if (!iterableTypes.has(typeName)) {
    // Неподходящий тип атрибута
    return "hidden";
  }
  let curKey = attrNode.key;
  for (;;) {
    const res = findNodeOwnerByKey(curKey, tree);
    if (!res) return "hidden";
    const { owner } = res;
    if (owner.type === "obj") break;
    // Считаем что если атрибут внутри значения, то он не может быть итератором.
    if (owner.type === "value") return "disabled";
    curKey = owner.key;
  }
  // Проверка на уникальность, нельзя создавать 2 итератора в одном объекте
  const attrOwner = findNodeOwnerObject(attrNode.key, tree);
  const iteratorInTreeExist =
    flattenItems(tree, "children")
      ?.filter(
        (item) =>
          item.type === "attr" &&
          item.attr.iterator &&
          item.attr.id !== attrNode.attr.id,
      )
      .filter((item) => {
        const itemOwner = findNodeOwnerObject(item.key, tree);
        return itemOwner?.key === attrOwner?.key;
      }) || [];
  if (iteratorInTreeExist.length > 0) return "blocked";

  return "enabled";
};

const iteratorInAttributes = (
  attributes: ZAttribute[] | null | undefined,
): boolean => !!attributes?.find(({ iterator }) => iterator);
const iteratorInGroup = ({
  groupType,
  attributes,
  groups,
}: ZGroup): boolean => {
  if (groupType.name !== GroupType.Mnemonic) return false;
  if (iteratorInAttributes(attributes)) return true;
  return iteratorInGroups(groups);
};
const iteratorInGroups = (groups: ZGroup[] | null | undefined): boolean =>
  !!groups?.find(iteratorInGroup);

export const hasIterator = (obj: ZObjectItem): boolean => {
  if (iteratorInAttributes(obj.attributes)) {
    return true;
  }
  return iteratorInGroups(obj.groups);
};
