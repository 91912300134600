import { makeAutoObservable } from "mobx";
import { TableStore } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import { RemoteData } from "src/common/RemoteData";
import {
  ZMCRow,
  ZMeasurementChart,
  ZSampleMcPoint,
} from "src/businessServices/services/bsMeasurementChart/ZMeasurementChart";
import { SheetStore } from "src/components/Sheet/SheetStore";
import {
  createPhotoComment,
  deletePhotoComments,
  loadPhotoComments,
  loadSampleMcPoints,
  saveSampleMcPoint,
} from "./apiSubsample";
import {
  EdPhotoComment,
  FilterPhotoComments,
  ZPhotoComment,
} from "./ZPhotoComment";

export class SubsampleStore {
  photoCommTable: TableStore<ZPhotoComment, FilterPhotoComments>;

  constructor(
    public id: number | string,
    public sampleRequestId: number | string,
    public modelId: number | string,
  ) {
    this.photoCommTable = new TableStore({
      rowKey: "id",
      fnLoad: async ({ page, pageSize, filters }) => {
        const res = await loadPhotoComments({
          modelId,
          sampleRequestId,
          sampleId: this.id,
          page,
          size: pageSize,
          query: filters?.query,
        });
        return {
          rows: res.content,
          totalItems: res.totalElements,
        };
      },
      selectionSettings: {
        selectionType: "checkbox",
      },
    });
    makeAutoObservable(this);
  }

  async init() {
    await this.loadPoints();
  }

  async createComment({ images, comment }: EdPhotoComment) {
    const { id: sampleId, sampleRequestId, modelId } = this;
    await Promise.all(
      images.map((image) =>
        createPhotoComment(
          {
            sampleId,
            sampleRequestId,
            modelId,
          },
          {
            sampleId,
            image,
            comment,
          },
        ),
      ),
    );
    this.photoCommTable.reload();
  }

  get canDeleteComment(): boolean {
    const { photoCommTable } = this;
    return photoCommTable.selected.length > 0 && !photoCommTable.loading;
  }

  deleteComments() {
    const { photoCommTable, id: sampleId, sampleRequestId, modelId } = this;
    const ids = photoCommTable.selected.map(({ id }) => id);
    photoCommTable.setLoading(true);
    deletePhotoComments({ modelId, sampleRequestId, sampleId }, ids)
      .then(() => photoCommTable.setSelected([]))
      .then(() => photoCommTable.reload())
      .finally(() => photoCommTable.setLoading(false))
      .catch(onError);
  }

  /// ////////////////////////
  ptSheet = new SheetStore();

  mcPoints: RemoteData<ZMeasurementChart> = { status: "none" };

  setMcPoints(data: RemoteData<ZMeasurementChart>) {
    this.mcPoints = data;
  }

  get pointRows(): ZMCRow[] {
    return this.mcPoints.status === "ready"
      ? this.mcPoints.result.tableDto?.rows ?? []
      : [];
  }

  get pointColumn(): { title: string; subTitle: string } | null {
    // Предполагается, что должна быть одна колонка с размером и версией.
    const { pointRows } = this;
    const row0 = pointRows[0];
    const size0 = row0?.mcPoint.sizes[0];
    if (!size0) return null;
    const ver0 = size0.pointValues[0];
    if (!ver0) return null;
    return {
      title: size0.name,
      subTitle: ver0.version.name,
    };
  }

  async loadPoints() {
    try {
      this.setMcPoints({ status: "wait" });
      const { modelId, sampleRequestId, id: sampleId } = this;
      const result = await loadSampleMcPoints({
        sampleId,
        modelId,
        sampleRequestId,
      });
      this.setMcPoints({ status: "ready", result });
    } catch (error) {
      this.setMcPoints({ status: "error", error });
    }
  }

  async saveSample(data: ZSampleMcPoint) {
    const { modelId, sampleRequestId, id: sampleId } = this;
    await saveSampleMcPoint({ sampleId, modelId, sampleRequestId }, data);
  }
}
