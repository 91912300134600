import * as React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import styles from "./MainFrame.module.less";
import { Header } from "./Header/Header";
import { LeftMenu } from "./LeftMenu";

export const MainFrame: React.FC = () => (
  <Layout className={styles.mainFrame}>
    <header>
      <Header />
    </header>
    <nav>
      <LeftMenu />
    </nav>
    <main>
      <Outlet />
    </main>
  </Layout>
);
