import * as React from "react";
import {
  FilterFieldsDict,
  FiltersForm,
} from "src/components/tables/FiltersForm";
import { ColumnsSettings } from "src/components/tables/ColumnsSettings";
import { observer } from "mobx-react-lite";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { TableProps } from "antd";
import { TableStore } from "../TableStore";
import { AColumn, AsyncTable } from "../AsyncTable";
import styles from "./TableFacade.module.less";

interface PropsTableFacade<TRow, TFilters extends object> {
  store: TableStore<TRow, TFilters>;
  columns: AColumn<TRow>[];
  initialColumns?: AColumn<TRow>[]; // Для настроек, если columns будут меняться динамически.
  filterItems?: FilterFieldsDict<TFilters>;
  filterExtra?: React.ReactNode;
  showSettings?: boolean;
  onRowClick?(row: TRow, index?: number): void;
  rowClassName?: (row: TRow, index: number) => string;
  middlePart?: React.ReactNode; // Элементы между фильтрами и таблицей
  size?: SizeType;
  scroll?: TableProps<TRow>["scroll"] & {
    scrollToFirstRowOnChange?: boolean;
  };
}

export const TableFacade = observer(
  <TRow extends {}, TFilters extends object = {}>(
    props: PropsTableFacade<TRow, TFilters>,
  ): React.ReactElement => {
    const {
      store,
      scroll,
      columns,
      initialColumns,
      filterItems,
      filterExtra,
      showSettings,
      onRowClick,
      rowClassName,
      middlePart,
      size,
    } = props;
    const filtersVisible = !!filterItems;
    const settingsVisible =
      typeof showSettings === "boolean" ? showSettings : !!store.settingsKey;
    const toolsVisible = filtersVisible || settingsVisible || !!filterExtra;
    return (
      <>
        {toolsVisible && (
          <div className={styles.filersRow}>
            <div>
              {filtersVisible && (
                <FiltersForm<TFilters> store={store} items={filterItems} />
              )}
              {filterExtra}
            </div>
            <div>{settingsVisible && <ColumnsSettings store={store} />}</div>
          </div>
        )}
        {middlePart}
        <AsyncTable<TRow, TFilters>
          scroll={scroll}
          store={store}
          columns={columns}
          initialColumns={initialColumns}
          rowClassName={rowClassName}
          onRowClick={onRowClick}
          usePagination
          size={size}
        />
      </>
    );
  },
);
