import * as React from "react";
import { Button, Empty, Form, Mentions, Radio, Switch } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { ZOption } from "src/types/ZOption";
import { onError } from "src/common/onError";
import { RoleSelect } from "src/components/RoleSelect";
import { appStore } from "src/appStore";
import { getLangName } from "src/types/ZLanguageProps";
import { EdObject } from "../EdObject";
import { ZRolesGroup } from "../roles/roleTypes";
import { EdNotifyTemplate } from "./EdNotifyTemplate";
import { ZNotifyType } from "./ZNotifyTemplate";
import { loadTemplatePlaceholders } from "./loadTemplatePlaceholders";
import styles from "./NotifyTemplates.module.less";

interface PropsNotifyTemplates {
  stateId: string;
  roles: ZRolesGroup[];
}

const root: keyof EdObject = "templatesMap";

const tmplKey = (key: keyof EdNotifyTemplate) => key;

const typesMap: Record<ZNotifyType, string> = {
  [ZNotifyType.push]: "Push", // При локализации тут должны быть ключи, а трансляция в функции typeOptions
  [ZNotifyType.email]: "Email",
  [ZNotifyType.all]: "Все",
};
const typeOptions = (): { value: number; label: string }[] =>
  Object.entries(typesMap).map(([key, label]) => ({ value: +key, label }));

export const NotifyTemplates: React.FC<PropsNotifyTemplates> = (props) => {
  const { stateId, roles: roleGroups } = props;

  const [placeholders, setPlaceholders] = React.useState<ZOption[]>([]);
  React.useEffect(() => {
    loadTemplatePlaceholders().then(setPlaceholders).catch(onError);
  }, []);

  return (
    <Form.List name={[root, stateId]}>
      {(fields, { add, remove }) => (
        <div className={styles.formListItem}>
          {fields.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {fields.map((field, index) => (
            <React.Fragment key={field.key}>
              <div className={styles.header}>
                <div className={styles.title}>Шаблон {index + 1}</div>
                <Button
                  size="small"
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => remove(field.name)}
                  title="Удалить шаблон"
                />
              </div>
              <Form.Item
                name={[field.name, tmplKey("active")]}
                label="Отправка уведомления"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name={[field.name, tmplKey("roleIds")]}
                label="Роли"
                rules={[{ required: true }]}
              >
                <RoleSelect roleGroups={roleGroups} />
              </Form.Item>
              <Form.Item
                name={[field.name, tmplKey("messageType")]}
                label="Тип уведомления"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  options={typeOptions()}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              {appStore.contentLanguages.map((lang) => (
                <Form.Item
                  key={lang.code}
                  name={[field.name, tmplKey("templateText"), lang.code]}
                  label={`Текст уведомления ${getLangName(lang)}`}
                  rules={[trimRequired()]}
                >
                  <Mentions
                    allowClear
                    rows={3}
                    prefix="%"
                    options={placeholders}
                  />
                </Form.Item>
              ))}
            </React.Fragment>
          ))}
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => add(defaultTemplate)}
          >
            {t("Add")}
          </Button>
        </div>
      )}
    </Form.List>
  );
};

const defaultTemplate: EdNotifyTemplate = {
  active: true,
  roleIds: [],
  messageType: undefined as unknown as number, // Это пользователь должен выбрать
  templateText: {},
};
