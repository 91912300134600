// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-CopyByValueEdit-CopyByValueModal-module__withRestr--WE3Q1 {
  padding: 20px 24px;
  background-color: #FFF5D4;
  border-radius: 6px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-CopyByValueEdit-CopyByValueModal-module__withRestrTitle--AVCzw {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/MetaInfo/CopyByValueEdit/CopyByValueModal.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;AACF;AAEA;EACE,eAAA;AAAF","sourcesContent":[".withRestr {\n  padding: 20px 24px;\n  background-color: #FFF5D4;\n  border-radius: 6px;\n}\n\n.withRestrTitle {\n  font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withRestr": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-CopyByValueEdit-CopyByValueModal-module__withRestr--WE3Q1`,
	"withRestrTitle": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-CopyByValueEdit-CopyByValueModal-module__withRestrTitle--AVCzw`
};
export default ___CSS_LOADER_EXPORT___;
