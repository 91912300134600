export const clearObjectFields = <T extends object>(obj: T): T =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key as keyof T];
    if (value && typeof value === "object" && !Array.isArray(value)) {
      acc[key as keyof T] = clearObjectFields(value) as T[keyof T];
    } else {
      acc[key as keyof T] = undefined as T[keyof T];
    }
    return acc;
  }, {} as T);
