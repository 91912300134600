import { TableLoadParams } from "../TableStore";

/**
 * С сортировкой пока что все сложно.
 * На бэке закладывается реализация, при которой возможна сортировка по нескольким столбцам,
 * поэтому тут возвращается массив.
 * Сортировка имеет такую структуру sorting = <тип сортировки>,<порядок сортировки>,<параметры?>
 * <тип сортировки> : attributeId (могут быть другие варианты, например, дата создания)
 * <порядок сортировки> : asc|desc
 * <параметры> : в случае attributeId это (id атрибута), если это другое поле, то эта чать отсутствует
 */
export const formatSortParam = <TFilters extends object>({
  sort,
  sortOrder,
}: TableLoadParams<TFilters>): string[] => {
  if (sort && sortOrder) {
    return [`attributeId,${sortOrder === "descend" ? "desc" : "asc"},${sort}`];
  }
  return [];
};

export const formatParams = <TFilters extends object>(
  params: TableLoadParams<TFilters>,
): Record<string, unknown> => {
  const { page, pageSize } = params;
  const formatted: Record<string, unknown> = {
    page,
    size: pageSize,
  };
  const sortParam = formatSortParam(params);
  if (sortParam) formatted.sorting = sortParam;
  return formatted;
};
