import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { MCObjType } from "../MChartSettingsStore";
import { ZMesChartSettings } from "../../ZMesChartSettings";

interface PropsSizeLineSizeSection {
  store: FormObjectSectionsStore<MCObjType>;
}

export const SizeLineSizeSection: React.FC<PropsSizeLineSizeSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZMesChartSettings, MCObjType>
      store={store}
      objKey={["sizeLineSizeObjectId"]}
      objType="SizeLineSize"
      objLabel="Size line size object"
      title="Size line size"
      attrs={[
        {
          attrKey: ["sizeLineAttrId"],
          attrLabel: "Size line",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sizeLineSizePreviousSizeAttrId"],
          attrLabel: "Previous size",
          attrType: AttrTypeName.object,
        },
      ]}
    />
  ),
);
