// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartCopyTo-MChartCopyTo-module__modalContent--ZO2_5 {
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/MChartCopyTo/MChartCopyTo.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AACJ","sourcesContent":[".modalContent {\n    height: 80vh;\n    display: flex;\n    flex-direction: column;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartCopyTo-MChartCopyTo-module__modalContent--ZO2_5`
};
export default ___CSS_LOADER_EXPORT___;
