import { SelectProps } from "antd";

export const filterOptionByName: SelectProps["filterOption"] = (
  input,
  option,
): boolean => {
  if (option && option.options) {
    return false;
  }
  return (String(option?.label) ?? "")
    .toLowerCase()
    .includes(input.toLowerCase());
};
