// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-DrawerPlm-DrawerPlm-module__content--fZc_w {
  padding: 0 !important;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.src-components-DrawerPlm-DrawerPlm-module__header--_rpvD {
  padding: 20px 24px !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #e1e3e6 !important;
}
.src-components-DrawerPlm-DrawerPlm-module__body--POuxX {
  padding: 20px 24px !important;
  flex: 1;
  overflow-y: auto;
}
.src-components-DrawerPlm-DrawerPlm-module__footer--ezV8j {
  padding: 20px 24px !important;
  margin-top: 0 !important;
  border-top: 1px solid #e1e3e6 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/DrawerPlm/DrawerPlm.module.less"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAIA;EACE,6BAAA;EACA,2BAAA;EACA,2CAAA;AAFF;AAKA;EACE,6BAAA;EACA,OAAA;EACA,gBAAA;AAHF;AAMA;EACE,6BAAA;EACA,wBAAA;EACA,wCAAA;AAJF","sourcesContent":["@import \"/src/variables.less\";\n\n.content {\n  padding: 0 !important;\n  height: 100%;\n  overflow-y: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.header {\n  padding: 20px 24px !important;\n  margin-bottom: 0 !important;\n  border-bottom: 1px solid @border-color !important;\n}\n\n.body {\n  padding: 20px 24px !important;\n  flex: 1;\n  overflow-y: auto;\n}\n\n.footer {\n  padding: 20px 24px !important;\n  margin-top: 0 !important;\n  border-top: 1px solid @border-color !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `src-components-DrawerPlm-DrawerPlm-module__content--fZc_w`,
	"header": `src-components-DrawerPlm-DrawerPlm-module__header--_rpvD`,
	"body": `src-components-DrawerPlm-DrawerPlm-module__body--POuxX`,
	"footer": `src-components-DrawerPlm-DrawerPlm-module__footer--ezV8j`
};
export default ___CSS_LOADER_EXPORT___;
