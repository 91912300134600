import { observer } from "mobx-react-lite";
import { Form } from "antd";
import { SelectFromReference } from "src/components/SelectFromReference";
import {
  ZArticleNumberMeta,
  zArticleNumberMetaObjRef,
} from "src/types/ZArticleNumberInfo";
import React from "react";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { AttrTypeName } from "src/types/AttrType";
import { ArticleAttrMetaCtrlFC } from "./getAttrArticleMetaFC";

export const AttrArticleMetaObjRef: ArticleAttrMetaCtrlFC = observer(
  ({ attr, value, onChange }) => {
    const parseValue = () => {
      try {
        return zArticleNumberMetaObjRef.parse(value).refAttributeId;
      } catch (error) {
        return undefined;
      }
    };
    return (
      <Form.Item label={attr.name}>
        <SelectFromReference
          value={parseValue()}
          loader={async () =>
            loadObjectAttrinbutesAll(Number(attr.referenceId)).then((data) =>
              data.map((item) => ({ label: item.name, value: item.id })),
            )
          }
          onChange={(v) => {
            onChange?.({
              attributeId: attr.id,
              valueTypeName: AttrTypeName.object,
              refAttributeId: v,
            } satisfies ZArticleNumberMeta);
          }}
        />
      </Form.Item>
    );
  },
);
