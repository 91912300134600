import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsPlan, ZSchedulingSettings } from "../../SchedulingTypes";
import { SchedulingObjType } from "../SchedulingSettingsStore";

interface PropsPlanFields {
  store: FormObjectSectionsStore<SchedulingObjType>;
}

const fieldName = (
  name: keyof ZSchedSettingsPlan,
): ["planSettings", keyof ZSchedSettingsPlan] => ["planSettings", name];

export const PlanFields: React.FC<PropsPlanFields> = observer(({ store }) => (
  <DrawTab>
    <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
      store={store}
      objKey={fieldName("templateObjectId")}
      objType="plan"
      objLabel="Объект для формирования плана"
      title="Список обязательных позиций"
      attrs={[
        {
          attrKey: fieldName("nameAttributeId"),
          attrLabel: "Укажите атрибут названия плана",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("groupTaskListAttributeId"),
          attrLabel: "Укажите атрибут списка групп задач, включенных в план",
          attrType: AttrTypeName.childEntities,
        },
        {
          attrKey: fieldName("startDateAttributeId"),
          attrLabel: "Укажите атрибут плановой даты начала",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: fieldName("modelLinkAttributeId"),
          attrLabel: "Укажите атрибут принадлежности плана к товарной позиции",
          attrType: AttrTypeName.object,
        },
      ]}
    />
  </DrawTab>
));
