// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-LoaderBox-LoaderBox-module__wait--UQjGh {
  padding: 2em 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoaderBox/LoaderBox.module.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".wait {\n  padding: 2em 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wait": `src-components-LoaderBox-LoaderBox-module__wait--UQjGh`
};
export default ___CSS_LOADER_EXPORT___;
