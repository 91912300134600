import {
  FormBlockItem,
  ItemProps,
  blockItem,
} from "src/components/FormWithBlocks";
import { ZAttribute } from "src/types/ZAttribute";
import { AttrUploaderProps } from "src/common/attrEdit/components";
import { ImageCarousel } from "src/components/ImageCarousel";

export const buildImageCarousel = (
  attr: ZAttribute,
  itemProps: ItemProps,
  compProps: AttrUploaderProps,
  disabled: boolean,
): FormBlockItem =>
  blockItem(
    String(attr.id),
    attr.name,
    ImageCarousel,
    { ...compProps, disabled },
    itemProps,
  );
