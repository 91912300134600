import * as React from "react";
import { ZDictGroup } from "src/types/ZGroup";
import { GroupOutlined, ProfileOutlined } from "@ant-design/icons";
import { GroupsTreeItem } from "./GroupsTreeItem";

export const buildGroupsTree = (groups: ZDictGroup[]): GroupsTreeItem[] =>
  groups.map((group) => ({
    id: group.id,
    name: group.name,
    type: "group",
    key: `g${group.id}`,
    title: group.name || <i>Новая группа</i>,
    icon: <GroupOutlined />,
    children: group.attributes?.map((attr) => ({
      id: attr.id,
      name: attr.name,
      type: "attr",
      key: `a${attr.id}`,
      title: attr.name ? `${attr.name} (${attr.id})` : <i>Новый атрибут</i>,
      icon: <ProfileOutlined />,
    })),
  }));
