import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Empty, Tooltip } from "antd";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { confirmDelete } from "src/components/ModalDelete";
import { ModelsListStore } from "./ModelsListStore";
import { ModalAddModel } from "./ModalAddModel";
import styles from "./ModelsList.module.less";

interface PropsModelsList {
  store: ModelsListStore;
}

/* eslint  jsx-a11y/anchor-is-valid: "off" */
/* eslint jsx-a11y/no-static-element-interactions: "off" */
/* eslint jsx-a11y/click-events-have-key-events: "off" */

export const ModelsList: React.FC<PropsModelsList> = observer(
  ({ store }: PropsModelsList) => {
    React.useEffect(() => {
      store.init();
    }, []);
    const { modelsList } = store;
    return (
      <div className={styles.common}>
        <div className={styles.tools}>
          <Tooltip title="Сохранить изменения">
            <Button
              icon={<CloudUploadOutlined />}
              onClick={() => store.save()}
            />
          </Tooltip>
          <Tooltip title="Добавить модель">
            <Button
              icon={<FileAddOutlined />}
              onClick={() => store.setState("add")}
            />
          </Tooltip>
          <Tooltip title="Удалить модель">
            <Button
              icon={<DeleteOutlined />}
              disabled={!store.canDelete}
              onClick={() =>
                confirmDelete("Удалить текущую модель?", () =>
                  store.deleteCurrent(),
                )
              }
            />
          </Tooltip>
        </div>
        <div className={styles.list}>
          {modelsList.length > 0 ? (
            modelsList.map((m, i) => (
              <a
                key={m.id}
                className={m.id === store.curModel?.id ? styles.active : ""}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  store.setCurModelIndex(i);
                }}
              >
                {m.name}
              </a>
            ))
          ) : (
            <div
              className={styles.noData}
              onClick={() => store.createTestData()}
            >
              <Empty />
              <Button>Добавить тестовые данные</Button>
            </div>
          )}
        </div>
        <ModalAddModel store={store} />
      </div>
    );
  },
);
