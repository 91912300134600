import { FormBlockItem, blockItem } from "src/components/FormWithBlocks";
import {
  stdCheckbox,
  stdInput,
  stdNumber,
} from "src/components/FormWithBlocks/items";
import { ZAttrType } from "src/types/ZCatAttr";
import { Select } from "antd";
import { AttrType } from "./AttrType";
import { SelectDictionaryForAttr } from "./SelectDictionaryForAttr";
import { CategoryAttributesStore } from "../../CategoryAttributesStore";

export const attrFieldsWithType = (
  typeKey: ZAttrType | undefined,
  attrStore: CategoryAttributesStore,
  mode: "add" | "edit",
): FormBlockItem[] => {
  const res: FormBlockItem[] = [
    stdInput("label", "Наименование атрибута", { rules: [{ required: true }] }),
    stdCheckbox("required", "Заполнение атрибута является обязательным"),
    blockItem(
      "type",
      "Тип данных поля",
      Select,
      {
        options: attrStore.typesDict,
        disabled: mode === "edit",
      },
      { rules: [{ required: true }] },
    ),
  ];
  if (typeKey === AttrType.select || typeKey === AttrType.multiSelect) {
    res.push(
      blockItem(
        "dictionary",
        "Выберите справочник",
        SelectDictionaryForAttr,
        {
          options: attrStore.referencesDict,
          fieldNames: { value: "key" },
          attrStore,
        },
        {
          rules: [{ required: true, message: "Необходимо выбрать справочник" }],
        },
      ),
    );
  }
  if (typeKey === AttrType.number) {
    res.push(
      stdNumber(
        "scale",
        "Количество символов после запятой",
        { rules: [{ required: true }] },
        {
          // При выборе значения, в форме появляется обязательное поле "Количество символов после запятой" (тип число).
          // В котором пользователь устанавливает ограничение на количество символов после запятой (по умолчанию установлено значение 1)
          min: 0,
          max: 4,
          precision: 0,
        },
      ),
    );
  }
  return res;
};
