import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex, Form, Modal, Select, Space, Switch } from "antd";
import { ZCopyByValueRule } from "src/common/attrEdit";
import { DeleteOutlined } from "@ant-design/icons";
import { AttrTypeName } from "src/types/AttrType";
import { DefaultOptionType } from "antd/es/select";
import { getTypeIcon } from "src/pages/ManagementPage/Obj2Tab/utils/typeIcons";
import { clearObjectFields } from "src/common/clearObjectFields";
import styles from "./CopyByValueModal.module.less";
import {
  ZCbvDictGroup,
  ZCbvLinkAttr,
  ZCbvParentAttr,
} from "./CopyByValueEdit.types";

interface PropsCopyByValueModal {
  open: boolean;
  loading: boolean;
  onClose(): void;
  onSubmit(values: ZCopyByValueRule): void;
  initialValues?: ZCopyByValueRule;
  linkAttrIdOpts: DefaultOptionType[];
  linkAttrs: ZCbvLinkAttr[];
  valueType: number;
  typeName: string;
}

const fieldName = (name: keyof ZCopyByValueRule) => name;

export const CopyByValueModal: React.FC<PropsCopyByValueModal> = ({
  valueType,
  typeName,
  initialValues,
  open,
  onClose,
  onSubmit,
  linkAttrIdOpts,
  linkAttrs,
  loading,
}) => {
  const [form] = Form.useForm();
  const [validAttrs, setValidAttrs] = useState<ZCbvParentAttr[]>([]);
  const [restrAttrName, setRestrAttrName] = useState<string>("");
  const [groupsByDict, setGroupsByDict] = useState<ZCbvDictGroup[]>([]);

  const curLinkAttr: number | undefined = Form.useWatch(
    fieldName("linkAttributeId"),
    form,
  );
  const curParentAttr: number | undefined = Form.useWatch(
    fieldName("parentAttributeId"),
    form,
  );
  const parentAttrField = fieldName("parentAttributeId");
  const withRestrField = fieldName("withRestrictions");
  const groupIdsToCopyField = fieldName("groupIdsToCopy");

  useEffect(() => {
    if (open) {
      form.setFieldsValue(initialValues);
    }
  }, [open]);

  useEffect(() => {
    setValidAttrs([]);
    const attrList = linkAttrs.find(
      ({ linkAttributeId }) => linkAttributeId === curLinkAttr,
    )?.attributes;
    if (!attrList) return;
    setValidAttrs(attrList);
  }, [curLinkAttr, linkAttrs]);

  useEffect(() => {
    if (typeName !== AttrTypeName.dictSingle) return;
    setRestrAttrName("");
    setGroupsByDict([]);
    const { restrictionName, groupByDictionary } =
      validAttrs.find(({ id }) => id === curParentAttr) || {};
    if (restrictionName) setRestrAttrName(restrictionName);
    if (groupByDictionary) setGroupsByDict(groupByDictionary);
  }, [curParentAttr, validAttrs]);

  const attrOpts: DefaultOptionType[] = useMemo(
    () =>
      validAttrs.map(({ id, name }) => ({
        value: id,
        label: (
          <Space>
            {getTypeIcon(valueType)} {name}
          </Space>
        ),
      })),
    [validAttrs],
  );

  const groupByDictOpts: DefaultOptionType[] = useMemo(
    () =>
      groupsByDict.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [groupsByDict],
  );

  const onDelete = () => {
    const newValue = clearObjectFields(form.getFieldsValue());
    form.setFieldsValue(newValue);
    onSubmit(newValue);
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Копировать из атрибута связанного объекта"
      open={open}
      onCancel={onCancel}
      // eslint-disable-next-line react/no-unstable-nested-components
      modalRender={(content) => (
        <Form
          name="CopyByValueEdit"
          form={form}
          layout="vertical"
          onFinish={onSubmit}
        >
          {content}
        </Form>
      )}
      footer={
        <Flex gap={16} justify="space-between">
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={onDelete}
            title="Все поля будут очищены и окно закроется"
          >
            Удалить правило
          </Button>
          <Space>
            <Button type="primary" htmlType="submit">
              Применить
            </Button>
            <Button onClick={onCancel}>Отмена</Button>
          </Space>
        </Flex>
      }
    >
      <Flex vertical gap={16}>
        <Form.Item
          name={fieldName("linkAttributeId")}
          label="Связанная ссылка на другой объект"
          rules={[{ required: true }]}
        >
          <Select
            loading={loading}
            options={linkAttrIdOpts}
            allowClear
            placeholder="Выберете ссылку"
            onChange={() => form.resetFields([parentAttrField])}
          />
        </Form.Item>
        <Form.Item
          name={fieldName("parentAttributeId")}
          label="Поле для сравнения"
          rules={[{ required: true }]}
        >
          <Select
            loading={loading}
            options={attrOpts}
            disabled={!curLinkAttr}
            placeholder={
              !curLinkAttr
                ? "Сначала необходиио выбрать ссылку на другой объект"
                : "Выберете атрибут"
            }
            allowClear
            onChange={() =>
              form.resetFields([withRestrField, groupIdsToCopyField])
            }
          />
        </Form.Item>
        {typeName === AttrTypeName.dictSingle && (
          <>
            <Flex vertical gap={8} className={styles.withRestr}>
              <Flex align="center" justify="space-between">
                <div className={styles.withRestrTitle}>
                  Перенести ограничения по значениям
                </div>
                <Form.Item name={fieldName("withRestrictions")}>
                  <Switch disabled={!restrAttrName} />
                </Form.Item>
              </Flex>
              {restrAttrName ? (
                <div>{`Справочник ${restrAttrName} будет перенесён в связанный объект`}</div>
              ) : (
                "У справочника из поля для сравнения нет ограничений по значению"
              )}
            </Flex>
            <Form.Item
              name={fieldName("groupIdsToCopy")}
              label="Группы на основе справочника для копирования"
              tooltip="Если выбранные группы на основе справочника уже существуют в текущем объекте, то копирование групп не произойдет"
            >
              <Select
                loading={loading}
                options={groupByDictOpts}
                disabled={!curParentAttr}
                placeholder={
                  !curParentAttr
                    ? "Сначала необходиио выбрать поле для сравнения"
                    : "Выберете группы на основе справочника"
                }
                allowClear
                mode="multiple"
              />
            </Form.Item>
          </>
        )}
      </Flex>
    </Modal>
  );
};

CopyByValueModal.defaultProps = {
  initialValues: undefined,
};
