// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsTechpack-TechpackControl-TechpackControl-module__container--_SKr2 {
  padding: 16px 24px;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsTechpack/TechpackControl/TechpackControl.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF","sourcesContent":[".container {\n  padding: 16px 24px;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-businessServices-services-bsTechpack-TechpackControl-TechpackControl-module__container--_SKr2`
};
export default ___CSS_LOADER_EXPORT___;
