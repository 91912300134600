import * as React from "react";
import { observer } from "mobx-react-lite";
import { Modal, Form, Input, InputNumber, Space, Button, Tooltip } from "antd";
import { onError } from "src/common/onError";
import { ModelsListStore } from "./ModelsListStore";
import { ZModel } from "../ZModel";
import { Point } from "../math";

interface PropsModalAddModel {
  store: ModelsListStore;
}

type NewModelData = Pick<ZModel, "imgHeight" | "imgRef" | "imgWidth" | "name">;

const initialValues: NewModelData = {
  name: "",
  imgRef: "",
} as NewModelData;

export const ModalAddModel: React.FC<PropsModalAddModel> = observer(
  ({ store }: PropsModalAddModel) => {
    const open = store.state === "add";
    const onCancel = () => store.setState("view");
    const onSubmit = (values: NewModelData) => {
      const model: ZModel = {
        id: store.generateNewModelId(),
        ...values,
        attrs: [],
      };
      const index = store.modelsList.length;
      store.modelsList.push(model);
      store.setCurModelIndex(index);
      onCancel();
    };
    const [form] = Form.useForm<NewModelData>();
    const [loading, setLoading] = React.useState(false);
    const [demoSrc, setDemoSrc] = React.useState("");
    const updateSize = async () => {
      try {
        setLoading(true);
        const src = (form.getFieldValue("imgRef") ?? "").trim();
        if (!src) throw Error("Не указана ссылка на изображение");
        const size = await loadImageSize(src);
        form.setFieldValue("imgWidth", size.x);
        form.setFieldValue("imgHeight", size.y);
      } catch (e) {
        onError(e);
      } finally {
        setLoading(false);
      }
    };
    React.useEffect(() => {
      if (open) form.resetFields();
    }, [open]);
    return (
      <Modal
        open={open}
        title="Добавление новой модели"
        destroyOnClose
        onCancel={onCancel}
        footer={null}
      >
        <Form<NewModelData>
          layout="vertical"
          onFinish={onSubmit}
          form={form}
          initialValues={initialValues}
        >
          <Form.Item name="name" label="Название" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="imgRef"
            label="Ссылка на изображение"
            rules={[{ required: true }]}
          >
            <Input onBlur={(e) => setDemoSrc(e.currentTarget.value)} />
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <img src={demoSrc} alt="" style={{ maxHeight: 60 }} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
            <Form.Item
              name="imgWidth"
              label="Ширина"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="imgHeight"
              label="Высота"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item label=" ">
              <Tooltip title="Заполнить ширину и высоту из текущего изображения">
                <Button
                  htmlType="button"
                  onClick={() => updateSize()}
                  loading={loading}
                >
                  Взять из изображения
                </Button>
              </Tooltip>
            </Form.Item>
          </div>
          <Space>
            <Button type="primary" htmlType="submit">
              Создать
            </Button>
            <Button htmlType="button" onClick={onCancel}>
              Отмена
            </Button>
          </Space>
        </Form>
      </Modal>
    );
  },
);

const loadImageSize = (src: string): Promise<Point> =>
  new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.addEventListener("load", () => {
      const { width, height } = img;
      if (!width || !height) {
        reject(Error("Некорректный размер"));
      } else {
        resolve(new Point(width, height));
      }
    });
    img.addEventListener("error", () => {
      reject(Error("Ошибка при загрузке изображения"));
    });
    img.src = src;
  });
