import { FormRule } from "antd";
import { str2Regexp } from "./str2Regexp";

export const validatorRegexpInput: FormRule = {
  validator: async (rule, value) => {
    if (value) {
      str2Regexp(value);
    }
  },
};
