import { ZAttrViewInfo } from "src/common/attrView";
import { ZAttribute } from "src/types/ZAttribute";
import { z } from "zod";

export const zObjectRefSelector = z.object({
  editor: z.literal("ObectRefSelector"),
  multiple: z.boolean().optional(),
  labelAtts: z.string().array().optional().nullable(),
  isCreateButtonAvailable: z.boolean().optional(),
});
export type ZObjectRefSelector = z.infer<typeof zObjectRefSelector>;

export const makeObjectRefSelectorProps = (
  attrInfo: ZAttribute,
  component: ZObjectRefSelector,
  viewInfo: ZAttrViewInfo | undefined,
  objectName?: string,
) => ({
  attrInfo,
  viewInfo,
  component,
  objectName,
});
