// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-utils-typeIcons-module__systemAttr--F_7yC {
  color: blue;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/utils/typeIcons.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ","sourcesContent":[".systemAttr {\n    color: blue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"systemAttr": `src-pages-ManagementPage-Obj2Tab-utils-typeIcons-module__systemAttr--F_7yC`
};
export default ___CSS_LOADER_EXPORT___;
