import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { BomObjType } from "../Bom2SettingsStore";
import { ZBom2PositionSettings, ZBom2Settings } from "../../Bom2Types";

interface PropsPositionSection {
  store: FormObjectSectionsStore<BomObjType>;
}

const fieldName = (
  name: keyof ZBom2PositionSettings,
): ["positionAttributes", keyof ZBom2PositionSettings] => [
  "positionAttributes",
  name,
];

export const PositionSection: React.FC<PropsPositionSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZBom2Settings, BomObjType>
      store={store}
      objKey={["positionObjectId"]}
      objType="position"
      objLabel="Position object"
      title="Position"
      attrs={[
        {
          attrKey: fieldName("nameId"),
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("materialTypeId"),
          attrLabel: "Material type",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: fieldName("materialLocationId"),
          attrLabel: "Material location",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: fieldName("materialSubtypeId"),
          attrLabel: "Material subtype",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: fieldName("imageId"),
          attrLabel: "Image",
          attrType: AttrTypeName.image,
        },
        {
          attrKey: fieldName("locationId"),
          attrLabel: "Location",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("trimSizeId"),
          attrLabel: "Trim size",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("amountId"),
          attrLabel: "Amount",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("commentId"),
          attrLabel: "Comment",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("qualityDetailsId"),
          attrLabel: "Quality details",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("linkToBomPositionColorModelsId"),
          attrLabel: "Link To Bom Position Color Models",
          attrType: AttrTypeName.childEntities,
        },
      ]}
    />
  ),
);
