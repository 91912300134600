import * as React from "react";
import { DatePicker } from "antd";
import { t } from "i18next";
import dayjs, { Dayjs } from "dayjs";
import { PickerDateProps } from "antd/es/date-picker/generatePicker";
import { CommonPickerMethods } from "antd/lib/date-picker/generatePicker/interface";

/* eslint react/jsx-props-no-spreading: "off" */

export type PropsDatePickerString = Omit<
  PickerDateProps<Dayjs>,
  "onChange" | "value"
> & {
  value?: string;
  onChange?(newValue?: string): void;
  srcFormat?: string;
};

/**
 * Компонент для выбора даты (и времени), который использует строковый формат данных
 * @param props
 */
export const DatePickerString = React.forwardRef<
  CommonPickerMethods,
  PropsDatePickerString
>((props, ref) => {
  const { value, onChange, srcFormat, format, showTime } = props;
  const valueFormat = srcFormat || `YYYY-MM-DD${showTime ? "THH:mm" : ""}`;
  const dayjsValue: Dayjs | undefined = value ? dayjs(value) : undefined;
  const dstFormat =
    format ||
    (showTime
      ? t("DateTimeFormat") || "DD.MM.YYYY"
      : t("DateFormat") || "DD.MM.YYYY HH:mm");

  const iref = React.useRef<CommonPickerMethods>(null);
  React.useImperativeHandle(ref, () => ({
    focus() {
      iref.current?.focus();
    },
    blur() {
      iref.current?.blur();
    },
  }));

  const onChangeProxy = (d: Dayjs | null): void => {
    iref.current?.focus?.();
    onChange?.(d?.format(valueFormat));
  };
  return (
    <DatePicker
      {...props}
      placeholder=""
      value={dayjsValue}
      onChange={onChangeProxy}
      format={dstFormat}
      // @ts-ignore
      ref={iref}
    />
  );
});
