import { z } from "zod";

export type SupportState = "none" | "show" | "success" | "work";

export const zSupportFields = z.object({
  question: z.string(),
  subject: z.string(),
  attachedFiles: z.string().array(),
});
export type ZSupportFields = z.infer<typeof zSupportFields>;
