import { rest } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import { ZAttributeSum, zAttributeSum } from "./ChildEntities.types";

export const loadChildEntsSummary = async (
  objectId: number,
  parentEntityId: number,
  parentLinkAttributeId: number,
  attributeIds: string[],
  query?: string,
): Promise<ZAttributeSum[]> => {
  const resp = await rest.get(apiObjUrl("/entities/child-entities-sum"), {
    params: {
      objectId,
      parentEntityId,
      parentLinkAttributeId,
      attributeIds,
      query,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return zAttributeSum.array().parse(resp.data);
};
