import { RowSelectionType } from "antd/es/table/interface";
import * as React from "react";
import { PropsSelection } from "./types";
import { SingleSelection } from "./SingleSelection";
import { MultipleSelection } from "./MultipleSelection";

export const makeSelection = (
  type: RowSelectionType | undefined,
  props: PropsSelection,
): React.ReactNode => {
  if (!type) return null;
  const selectionMap: Record<RowSelectionType, React.ReactNode> = {
    radio: <SingleSelection {...props} />,
    checkbox: <MultipleSelection {...props} />,
  };
  return selectionMap[type];
};
