import React from "react";

type PropsObjectStateLabel = {
  statesLabels: Record<number, string>;
  stateId: number;
};

export const ObjectStateLabel: React.FC<PropsObjectStateLabel> = ({
  statesLabels,
  stateId,
}) => <div>{statesLabels[stateId]}</div>;
