import { observer } from "mobx-react-lite";
import * as React from "react";
import { LoaderBox } from "src/components/LoaderBox";
import { AddButton, LayoutWithSider } from "src/components/LayoutWithSider";
import { VerticalLayout } from "src/components/VerticalLayout";
import { ModelessForm } from "src/components/ModelessForm";
import { ModalDelete } from "src/components/ModalDelete";
import { Menu2TabStore } from "./Menu2TabStore";
import { MenuTreeView } from "./MenuTreeView";
import { MenuExtraButtons, MenuItemFields } from "./MenuItemFields";

interface PropsMenu2Tab {
  store: Menu2TabStore;
}

export const Menu2Tab: React.FC<PropsMenu2Tab> = observer(({ store }) => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <LoaderBox
      remoteData={store.data}
      drawReady={() => <Inner store={store} />}
    />
  );
});

const Inner: React.FC<PropsMenu2Tab> = observer(({ store }) => (
  <LayoutWithSider
    siderHeader={
      <AddButton
        onClick={() => store.safeCreateMenuItem()}
        id="addMenuItem"
        text="Добавить пункт меню"
      />
    }
    siderContent={<MenuTreeView store={store} />}
  >
    <VerticalLayout>
      <ModelessForm
        store={store.formStore}
        submit="Сохранить"
        extraButtons={<MenuExtraButtons store={store} />}
      >
        <MenuItemFields store={store} />
      </ModelessForm>
    </VerticalLayout>
    <ModalDelete
      open={store.deleteState !== "none"}
      message="Удалить пункт меню?"
      waiting={store.deleteState === "work"}
      onOk={() => store.doDeleteCurNode()}
      onCancel={() => store.setDeleteState("none")}
    />
  </LayoutWithSider>
));
