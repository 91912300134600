import { Input } from "antd";
import { t } from "i18next";
import { filterItem, FiltersFormItem } from "./FiltersFormItem";

export const filterMonoSearch = (): FiltersFormItem =>
  filterItem(
    Input.Search,
    { allowClear: true, placeholder: t("Search") },
    { style: { flex: 1 } },
  );
