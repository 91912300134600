export enum PageUrl {
  categories = "/categories",
  dashboards = "/dashboards",
  entityCard = "/entity/:id",
  entityNew = "/entity/new/:objectId",
  testTable = "/test-table",
  testCardNew = "/test-table/new",
  testCardEdit = "/test-table/:id",
  test = "/test",
  userAccount = "/user-account",
  home = "/home",
  modelConstructor = "/model-constructor",
  modelessForm = "/modeless-form",
  models = "/models",
  management = "/management",
  entities = "/entities/:objectId?",
}
