import * as React from "react";
import { t } from "i18next";
import { Button, Drawer, Select, Space, Spin } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { FnLoad, TableStore } from "src/components/tables/TableStore";
import { TableFacade } from "../tables/TableFacade";
import { AColumn } from "../tables/AsyncTable";
import { selectOnRowClick } from "../tables/selectOnRowClick";
import { KeepSelected } from "../tables/KeepSelected";
import { FilterFieldsDict } from "../tables/FiltersForm";
import styles from "./SelectFromTable.module.less";

export interface SelectFromTableOption {
  value: string;
  label: React.ReactNode;
}

export interface PropsSelectFromTable<Row, Filter extends object> {
  maxCount?: number;
  value?: Row[];
  onChange?: (newValue: Row[]) => void;
  disabled?: boolean;

  title: React.ReactNode;
  rowKey: keyof Row;
  makeOption(row: Row): SelectFromTableOption;
  load: FnLoad<Row, Filter>;
  columns: AColumn<Row>[];
  filterItems?: FilterFieldsDict<Filter>;
}

export const SelectFromTable = <Row extends {}, Filter extends object = {}>(
  props: PropsSelectFromTable<Row, Filter>,
): React.ReactElement => {
  const {
    disabled,
    value,
    onChange,
    makeOption,
    maxCount,
    rowKey,
    load,
    title,
    columns,
    filterItems,
  } = props;
  const isSingle = maxCount === 1;
  const [options, setOptions] = React.useState<SelectFromTableOption[]>([]);
  const [open, setOpen] = React.useState(false);
  const store = React.useMemo(
    () =>
      new TableStore<Row, Filter>({
        rowKey,
        fnLoad: load,
        selectionSettings: {
          keepSelected: true,
          selectionType: isSingle ? "radio" : "checkbox",
        },
      }),
    [],
  );
  React.useEffect(() => {
    const rows: Row[] = value ?? [];
    setOptions(rows.map(makeOption));
    store.setSelected(rows);
  }, [value]);
  const updateValue = () => {
    onChange?.(store.selected);
  };
  return (
    <Spin spinning={store.loading}>
      <Select
        open={false}
        disabled={disabled}
        options={options}
        mode={isSingle ? undefined : "multiple"}
        suffixIcon={!disabled && <PlusCircleFilled />}
        onClick={() => setOpen(true)}
        value={options.map((op) => op.value)}
        className={styles.select}
      />
      <Drawer
        title={title}
        width="75vw"
        open={open && !disabled}
        onClose={() => setOpen(false)}
        className={styles.drawer}
        footer={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setOpen(false);
                updateValue();
              }}
            >
              {t("Apply")}
            </Button>
            <Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>
          </Space>
        }
      >
        <KeepSelected store={store} makeTag={(row) => makeOption(row).label} />
        <TableFacade<Row, Filter>
          store={store}
          columns={columns}
          onRowClick={(row) => selectOnRowClick(row, store)}
          filterItems={filterItems}
        />
      </Drawer>
    </Spin>
  );
};
