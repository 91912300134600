import { AttrTypeName } from "src/types/AttrType";
import { z } from "zod";
import { FormRule } from "antd";
import { validateEntityFormula } from "src/pages/EntityCardPage/apiEntityCard";
import { ZAttribute } from "src/types/ZAttribute";
import { getEditorInfo } from "../getEditorInfo";

export const zAttrFormula = z.object({
  editor: z.literal("Formula"),
  formula: z.string(),
  // Поскольку в расчетах теперь участвуют атрибуты не только из текущего объекта, нужно предзаполнять доп поле, содержащее и ссылки на другие объекты
  // Для корректной работы формул их придется их пересохранить
  argumentAtts: z.number().array().optional(),
  precision: z.number().nullable().optional(),
  addonBefore: z.string().nullable().optional(),
  addonAfter: z.string().nullable().optional(),
});
export type ZAttrFormula = z.infer<typeof zAttrFormula>;

const zFormulaErrorInfo = z.object({
  argIds: z.number().array(),
  formulaId: z.number(),
  message: z.string(),
});

export const zFormulaError = z.object({
  formulaInfo: zFormulaErrorInfo.optional(),
});

export const zFormulaArg = z.object({
  id: z.number(),
  name: z.string(),
  attributeRepresentation: z.string(),
});
export type ZFormulaArg = z.infer<typeof zFormulaArg>;

enum formulaLinkType {
  objectLink = "OBJECT_ID",
}

const zArgMetaBase = z.object({
  attributeId: z.number(),
});

const zObjectLinkArg = zArgMetaBase.extend({
  linkTypeName: z.literal(formulaLinkType.objectLink),
  attributeLinkToObjectId: z.number(),
});
type ZObjectLinkArg = z.infer<typeof zObjectLinkArg>;

// Вероятно, в будущем будут возможны и другие варианты доп информации об аргументах
const zArgMeta = z.discriminatedUnion("linkTypeName", [zObjectLinkArg]);
type ZArgMeta = z.infer<typeof zArgMeta>;

export const zFormulaArgsInfo = z.object({
  availableAttributes: zFormulaArg.array(),
  availableAttributesLinks: zArgMeta.array(),
});
export type ZFormulaArgsInfo = z.infer<typeof zFormulaArgsInfo>;

export const makeFormulaProps = (src: ZAttrFormula) => ({
  precision: src.precision ?? undefined,
  addonBefore: src.addonBefore ?? undefined,
  addonAfter: src.addonAfter ?? undefined,
});

export const attrFormulaPefixView = "@";
export const attrFormulaPefixBuss = "a";

export const makeFormulaAttPartView = (attr: ZFormulaArg) =>
  `${attrFormulaPefixView}${attr.name}`;
export const makeFormulaAttPartBuss = (attr: ZFormulaArg) =>
  attr.attributeRepresentation;

const argMetaHandlers: Record<formulaLinkType, (metaInfo: ZArgMeta) => number> =
  {
    [formulaLinkType.objectLink]: (metaInfo: ZArgMeta) =>
      (metaInfo as ZObjectLinkArg).attributeLinkToObjectId,
  };

export const parseFormulaAtts = (
  formula: string,
  { availableAttributes, availableAttributesLinks }: ZFormulaArgsInfo,
): number[] => {
  const metaInfo = new Map<number, ZArgMeta>(
    availableAttributesLinks.map((info) => [info.attributeId, info]),
  );
  const result = new Set<number>();
  availableAttributes.forEach(({ attributeRepresentation, id }) => {
    if (!formula.includes(attributeRepresentation)) return;
    const argMetaInfo = metaInfo.get(id);
    if (!argMetaInfo) {
      result.add(id);
      return;
    }
    const getMetaAttrId = argMetaHandlers[argMetaInfo.linkTypeName];
    result.add(getMetaAttrId(argMetaInfo));
  });
  return Array.from(result);
};

const hasWrongAttrPartView = (template: string, atts: ZFormulaArg[]) => {
  let formula = template;
  atts
    .map(makeFormulaAttPartView)
    .sort((a, b) => b.length - a.length)
    .forEach((part) => {
      formula = formula.replaceAll(part, "");
    });
  return (
    formula[formula.length - 1] !== attrFormulaPefixView &&
    formula.includes(attrFormulaPefixView)
  );
};

export const validateFormula = (
  objectId: number,
  attrList: ZFormulaArg[],
): FormRule => ({
  validator: async (rule, value) => {
    try {
      const bussinesValue = await transformFormula4Save(value, attrList);
      await validateEntityFormula(bussinesValue, objectId);
      if (hasWrongAttrPartView(value, attrList)) {
        return Promise.reject(Error("Ошибка в формуле"));
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(Error("Ошибка в формуле"));
    }
  },
});

export const getFormulaDepAttsIds = (
  attributes: ZAttribute[],
  attrTypesMap: Record<number, string>,
) => {
  const formulaAtts = attributes.filter(
    ({ valueType }) => attrTypesMap[valueType] === AttrTypeName.formula,
  );
  const formulaAttIds = formulaAtts.map(({ id }) => String(id));
  const depAtts = formulaAtts
    .map(({ viewStyles }) => {
      const editor = getEditorInfo(viewStyles);
      if (editor?.component?.editor === "Formula") {
        return editor.component.argumentAtts?.map((id) => String(id));
      }
      return [];
    })
    .flat();
  return { depAtts, formulaAtts: formulaAttIds };
};

const transformEdAttVals = async (
  search: (attr: ZFormulaArg) => string,
  replace: (attr: ZFormulaArg) => string,
  formula: string,
  attrList: ZFormulaArg[],
) => {
  let newFormula = formula;
  attrList
    .sort((a, b) => b.name.length - a.name.length)
    .forEach((attr) => {
      newFormula = newFormula.replaceAll(search(attr), replace(attr));
    });
  return newFormula;
};

export const transformFormula4Save = async (
  formula: string,
  attrList: ZFormulaArg[],
) =>
  transformEdAttVals(
    makeFormulaAttPartView,
    makeFormulaAttPartBuss,
    formula,
    attrList,
  );

export const transformFormula4Show = async (
  formula: string,
  attrList: ZFormulaArg[],
) =>
  transformEdAttVals(
    makeFormulaAttPartBuss,
    makeFormulaAttPartView,
    formula,
    attrList,
  );
