// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleRequestModal-SampleRequestModal-module__formBody--Dd9Yi {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SampleSummary/SampleRequestModal/SampleRequestModal.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,YAAA;AACJ","sourcesContent":[".formBody {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 24px;\n    row-gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formBody": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleRequestModal-SampleRequestModal-module__formBody--Dd9Yi`
};
export default ___CSS_LOADER_EXPORT___;
