import { urlBom2 } from "src/businessServices/services/bsBom2/apiBom2";
import { rest } from "src/common/rest";
import { z } from "zod";

const zBomSupplierRow = z.object({
  id: z.number(),
  name: z.string(),
  shortName: z.string().nullable(),
  country: z.string().nullable(),
  address: z.string().nullable(),
});
export type ZBomSupplierRow = z.infer<typeof zBomSupplierRow>;

export type BomSupplierFilter = {
  query?: string;
};

export type ParamsBomSuppliers = BomSupplierFilter & {
  page: number;
  size: number;
};

const zBomSupplierResponse = z.object({
  content: zBomSupplierRow.array(),
  totalElements: z.number(),
});

export const loadBomSuppliers = async (params: ParamsBomSuppliers) => {
  const resp = await rest.get(urlBom2("/supplier/get-paginated"), { params });
  return zBomSupplierResponse.parse(resp.data);
};

export const assignBomSupplier = async (
  bomPositionId: number,
  supplierId: number,
) => {
  await rest.post(urlBom2("/bom-position/assign-supplier"), undefined, {
    params: { bomPositionId, supplierId },
  });
};
