// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ChildEntities-ChildEntities-module__box--lzznv {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  gap: 16px;
  padding: 10px 0;
  height: 100%;
}
.src-components-ChildEntities-ChildEntities-module__header--MoPcY {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  overflow-x: auto;
}
.src-components-ChildEntities-ChildEntities-module__search--g6ab5 {
  flex: auto;
}
.src-components-ChildEntities-ChildEntities-module__toolbar--L0BYl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.src-components-ChildEntities-ChildEntities-module__tableContainer--XCypX {
  overflow-y: auto;
  height: 100%;
}
.src-components-ChildEntities-ChildEntities-module__heightLimited--qEnIv {
  height: 60vh;
}
.src-components-ChildEntities-ChildEntities-module__openCreationButton--Q9GG_ {
  height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChildEntities/ChildEntities.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,0BAAA;EACA,mCAAA;EACA,SAAA;EACA,eAAA;EACA,YAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,QAAA;EACA,gBAAA;AAAF;AAEA;EACE,UAAA;AAAF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,QAAA;AAAF;AAGA;EACE,gBAAA;EACA,YAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACE,YAAA;AAHF","sourcesContent":[".box {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: max-content 1fr;\n  gap: 16px;\n  padding: 10px 0;\n  height: 100%;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  overflow-x: auto;\n}\n.search {\n  flex: auto;\n}\n.toolbar {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n}\n\n.tableContainer {\n  overflow-y: auto;\n  height: 100%;\n}\n\n.heightLimited {\n  height: 60vh;\n}\n\n.openCreationButton {\n  height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-components-ChildEntities-ChildEntities-module__box--lzznv`,
	"header": `src-components-ChildEntities-ChildEntities-module__header--MoPcY`,
	"search": `src-components-ChildEntities-ChildEntities-module__search--g6ab5`,
	"toolbar": `src-components-ChildEntities-ChildEntities-module__toolbar--L0BYl`,
	"tableContainer": `src-components-ChildEntities-ChildEntities-module__tableContainer--XCypX`,
	"heightLimited": `src-components-ChildEntities-ChildEntities-module__heightLimited--qEnIv`,
	"openCreationButton": `src-components-ChildEntities-ChildEntities-module__openCreationButton--Q9GG_`
};
export default ___CSS_LOADER_EXPORT___;
