import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { ModelessFormStore } from "../../components/ModelessForm/ModelessFormStore";
import { Dict2TabStore } from "./Dict2Tab/Dict2TabStore";
import { Obj2TabStore } from "./Obj2Tab";
import { RolesTabStore } from "./RolesTab";
import { Menu2TabStore } from "./Menu2Tab/Menu2TabStore";
import { PersonsTabStore } from "./PersonsTab/PersonsTabStore";
import { DashboardTabStore } from "./DashboardTab/DashboardTabStore";
import { ExtraSettingsTabStore } from "./ExtraSettingsTab/ExtraSettingsTabStore";

export type ManagementTabKey =
  | "dashboard"
  | "dict"
  | "dictWithLogic"
  | "extraSettings"
  | "menu"
  | "obj"
  | "persons"
  | "roles"
  | "version";

// Для запоминания текущего таба используется localStorage
const tabKey = "ManagementPageTab";

export const managementPageStore = makeAutoObservable({
  curTab: <ManagementTabKey>"obj",
  setCurTab(tab: ManagementTabKey) {
    this.curTab = tab;
  },
  safeSetCurTab(tab: ManagementTabKey) {
    ModelessFormStore.safeMultiAction(this.formStores, () => {
      this.setCurTab(tab);
      try {
        localStorage.setItem(tabKey, tab);
      } catch (e) {
        onError(e);
      }
    });
  },
  dict2TabStore: new Dict2TabStore(),
  obj2TabStore: new Obj2TabStore(),
  rolesTabStore: new RolesTabStore(),
  personsTabStore: new PersonsTabStore(),
  menu2TabStore: new Menu2TabStore(),
  dashboardsStore: new DashboardTabStore(),
  extraSettingsStore: new ExtraSettingsTabStore(),

  get formStores(): ModelessFormStore[] {
    return [
      this.dict2TabStore.formStore,
      this.obj2TabStore.formStore,
      this.rolesTabStore.formStore,
      this.menu2TabStore.formStore,
      this.dashboardsStore.formStore,
    ];
  },
  init() {
    try {
      const tab = localStorage.getItem(tabKey);
      if (tab) this.setCurTab(tab as ManagementTabKey);
    } catch (e) {
      onError(e);
    }
  },
});

export type ManagementPageStore = typeof managementPageStore;
