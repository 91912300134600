// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-LangSwitcher-LangSwitcher-module__langMenu--fXyNV {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  position: relative;
  height: fit-content;
  cursor: pointer;
}
.src-components-LangSwitcher-LangSwitcher-module__langName--t9rkH {
  font-size: 14px;
  color: #343a41;
}
.src-components-LangSwitcher-LangSwitcher-module__menu--tZnGJ {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 2px 10px 0px #00000026;
  background-color: #fff;
  margin: 0;
  padding: 0;
}
.src-components-LangSwitcher-LangSwitcher-module__menu--tZnGJ ul {
  display: grid;
  grid-template-columns: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}
.src-components-LangSwitcher-LangSwitcher-module__menu--tZnGJ li {
  cursor: pointer;
  list-style-type: none;
}
.src-components-LangSwitcher-LangSwitcher-module__menu--tZnGJ li > a {
  display: inline-block;
  color: #343a41;
  text-decoration: none;
  line-height: 1;
  margin: 0;
  padding: 15px 17px;
}
.src-components-LangSwitcher-LangSwitcher-module__active--fKPgZ {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/LangSwitcher/LangSwitcher.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AADF;AAIA;EACE,eAAA;EACA,cAAA;AAFF;AAKA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,aAAA;EACA,sCAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;AAHF;AALA;EAUI,aAAA;EACA,2BAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;AAFJ;AAZA;EAiBI,eAAA;EACA,qBAAA;AAFJ;AAGI;EACE,qBAAA;EACA,cAAA;EACA,qBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;AADN;AAMA;EACE,aAAA;AAJF","sourcesContent":["@import \"/src//variables.less\";\n\n.langMenu {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n  position: relative;\n  height: fit-content;\n  cursor: pointer;\n}\n\n.langName {\n  font-size: 14px;\n  color: @text-main;\n}\n\n.menu {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  z-index: 1000;\n  box-shadow: 0px 2px 10px 0px #00000026;\n  background-color: @fill-main;\n  margin: 0;\n  padding: 0;\n  ul {\n    display: grid;\n    grid-template-columns: auto;\n    margin: 0;\n    padding: 0;\n    list-style: none;\n  }\n  li {\n    cursor: pointer;\n    list-style-type: none;\n    & > a {\n      display: inline-block;\n      color: @text-main;\n      text-decoration: none;\n      line-height: 1;\n      margin: 0;\n      padding: 15px 17px;\n    }\n  }\n}\n\n.active {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"langMenu": `src-components-LangSwitcher-LangSwitcher-module__langMenu--fXyNV`,
	"langName": `src-components-LangSwitcher-LangSwitcher-module__langName--t9rkH`,
	"menu": `src-components-LangSwitcher-LangSwitcher-module__menu--tZnGJ`,
	"active": `src-components-LangSwitcher-LangSwitcher-module__active--fKPgZ`
};
export default ___CSS_LOADER_EXPORT___;
