import * as React from "react";
import { Link } from "react-router-dom";
import { classNames } from "src/common/classNames";
import { observer } from "mobx-react-lite";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { leftMenuStore as store } from "./leftMenuStore";
import styles from "./LeftMenu.module.less";
import { ZMenuItem } from "./ZMenuItem";
import { iconsMap } from "./iconsMap";
import { makeMenuItemKey } from "./makeMenuItemKey";

type MenuItemLabelProps = {
  item: ZMenuItem;
};

export const MenuItemLabel: React.FC<MenuItemLabelProps> = observer(
  ({ item }) => {
    const { name, iconName, link, id } = item;
    const icon = iconName ? iconsMap[iconName] : undefined;
    const key = makeMenuItemKey(id);
    const labelStyle = classNames([
      styles.menuItemLabel,
      [store.activeMenuElement === key, styles.menuItemLabelActive],
    ]);
    const labelContent = (
      <>
        {icon}
        <span className={styles.menuItemName}>{name}</span>
      </>
    );

    const renderLink = (to: string) => (
      <Link to={to} onClick={(e) => e.stopPropagation()} className={labelStyle}>
        {labelContent}
      </Link>
    );

    switch (link.type) {
      case "internal":
        return renderLink(makeUrl(link.url as PageUrl));
      case "object":
        return renderLink(
          makeUrl(PageUrl.entities, { objectId: link.objectId }),
        );
      case "external":
        return (
          <a href={link.href} className={labelStyle}>
            {labelContent}
          </a>
        );
      default:
        return <div className={labelStyle}>{labelContent}</div>;
    }
  },
);
