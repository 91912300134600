import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { ModelessFormStore } from "src/components/ModelessForm";
import { onError } from "src/common/onError";
import {
  zDashboardGroup,
  ZDashboardGroup,
  ZDashboardItem,
} from "./DasboardTypes";
import { saveDashboards, loadDashboards } from "./apiDashboards";
import { ZRolesGroup } from "../Obj2Tab/roles/roleTypes";
import { loadRoleGroups } from "../Obj2Tab/roles/rolesApi";

export class DashboardTabStore {
  constructor() {
    this.formStore = new ModelessFormStore(
      (values: unknown) => this.save(values),
      () => this.removeNewItem(),
    );
    makeAutoObservable(this);
  }

  data: RemoteData<ZDashboardGroup[]> = { status: "none" };

  setData(newData: RemoteData<ZDashboardGroup[]>) {
    this.data = newData;
  }

  get groups(): ZDashboardGroup[] {
    return this.data.status === "ready" ? this.data.result : [];
  }

  curGroupKey = 0;

  setCurGroupKey(key: number) {
    this.curGroupKey = key;
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      if (this.roleGroups.length === 0) {
        this.setRoleGroups(await loadRoleGroups());
      }
      const result = await loadDashboards();
      this.setData({ status: "ready", result });
      if (!this.curGroupKey && result[0]) {
        this.unsafeSelect(result[0].groupKey);
      }
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  formStore: ModelessFormStore;

  roleGroups: ZRolesGroup[] = [];

  setRoleGroups(data: ZRolesGroup[]) {
    this.roleGroups = data;
  }

  safeCreateGroup() {
    this.formStore.safeAction(() => this.unsafeAddGroup());
  }

  unsafeAddGroup() {
    if (this.data.status === "ready") {
      this.data.result.push({
        groupKey: newItemKey,
        name: "",
        nameLoc: {},
        roleIds: [],
        items: [createDasboardItem()],
      });
      this.unsafeSelect(newItemKey);
    }
  }

  safeSelect(key: number) {
    this.formStore.safeAction(() => this.unsafeSelect(key));
  }

  unsafeSelect(key: number) {
    this.setCurGroupKey(key);
    const curItem =
      this.groups.find(({ groupKey }) => groupKey === key) ?? null;
    this.formStore.unsafeLoad(curItem, key === newItemKey);
  }

  removeNewItem() {
    this.removeGroupByKey(newItemKey);
  }

  async save(values: unknown) {
    if (this.data.status !== "ready") throw Error(`Данные недоступны`);
    const srcGroups: ZDashboardGroup[] = this.data.result;
    const { curGroupKey } = this;
    const groupFields = zDashboardGroup.omit({ groupKey: true }).parse(values);
    const changedGroup: ZDashboardGroup = {
      groupKey:
        curGroupKey === newItemKey ? this.generateGroupKey() : curGroupKey,
      ...groupFields,
    };
    changedGroup.items = changedGroup.items.map((item, i) => ({
      ...item,
      itemKey: i + 1,
    }));
    const result = srcGroups.map((group) =>
      group.groupKey === curGroupKey ? changedGroup : group,
    );
    await saveDashboards(result);
    this.setData({ status: "ready", result });
    this.setCurGroupKey(changedGroup.groupKey);
  }

  generateGroupKey(): number {
    return (
      this.groups.reduce((acc, { groupKey }) => Math.max(acc, groupKey), 0) + 1
    );
  }

  deleteState: "ask" | "none" | "wait" = "none";

  setDeleteState(state: DashboardTabStore["deleteState"]) {
    this.deleteState = state;
  }

  tryToDelete() {
    if (this.deleteState === "none") {
      this.setDeleteState("ask");
    }
  }

  cancelDelete() {
    this.setDeleteState("none");
  }

  async doDelete() {
    if (this.data.status !== "ready") return;
    try {
      this.setDeleteState("wait");
      this.removeGroupByKey(this.curGroupKey);
      await saveDashboards(this.data.result);
      this.setDeleteState("none");
    } catch (e) {
      onError(e);
      this.setDeleteState("ask");
    }
  }

  removeGroupByKey(key: number) {
    if (this.data.status === "ready") {
      const pos = this.data.result.findIndex(
        ({ groupKey }) => groupKey === key,
      );
      if (pos >= 0) {
        this.data.result.splice(pos, 1);
        if (key === this.curGroupKey) {
          const newPos = this.data.result.length === pos ? pos - 1 : pos;
          this.unsafeSelect(this.data.result[newPos]?.groupKey ?? 0);
        }
      }
    }
  }
}

const newItemKey = -1;

export const createDasboardItem = (): ZDashboardItem => ({
  itemKey: 0,
  url: "",
});
