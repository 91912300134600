// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectForm2-module__entitiesLink--OVnL1 {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectForm2-module__buttons--dUEyz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/ObjectForm2/ObjectForm2.module.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,QAAA;EACA,WAAA;EACA,eAAA;AADF","sourcesContent":["\n.entitiesLink {\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n\n.buttons {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  width: 100%;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entitiesLink": `src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectForm2-module__entitiesLink--OVnL1`,
	"buttons": `src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectForm2-module__buttons--dUEyz`
};
export default ___CSS_LOADER_EXPORT___;
