import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Modal,
  Form,
  Input,
  InputRef,
  Space,
  Button,
  Segmented,
  notification,
} from "antd";
import { ModelEditorStore } from "../../ModelEditorStore";
import { CmdAttrChange } from "../../commands/CmdAttrChange";

interface PropsModalEditAttr {
  store: ModelEditorStore;
}

export const ModalEditAttr: React.FC<PropsModalEditAttr> = observer(
  ({ store }: PropsModalEditAttr) => {
    const open = store.state === "editAttr";
    const ref1 = React.useRef<InputRef>(null);
    React.useEffect(() => {
      if (open) setTimeout(() => ref1.current?.focus(), 20);
    }, [open]);
    const onCancel = () => {
      store.setState("view");
    };
    const { curAttr } = store;
    if (!curAttr) return null;
    const attrType =
      curAttr.xC !== undefined && curAttr.yC !== undefined ? "curve" : "line";
    const initialValues = {
      attrName: curAttr.attrName,
      type: attrType,
    };
    return (
      <Modal
        open={open}
        title="Редактирование свойств"
        destroyOnClose
        footer={null}
        onCancel={onCancel}
      >
        <Form
          layout="vertical"
          initialValues={initialValues}
          onFinish={(values) => {
            if (JSON.stringify(values) === JSON.stringify(initialValues)) {
              notification.warning({ message: "Нет никаких изменений" });
            } else {
              const { attrName, type } = values;
              const newAttr = {
                ...curAttr,
                attrName,
              };
              if (type !== initialValues.type) {
                if (type === "curve") {
                  newAttr.xC = (newAttr.xA + newAttr.xB) / 2;
                  newAttr.yC = (newAttr.yA + newAttr.yB) / 2;
                } else if (type === "line") {
                  delete newAttr.xC;
                  delete newAttr.yC;
                }
              }
              store.execCmd(
                new CmdAttrChange(store, store.curAttrIndex, newAttr),
              );
            }
            store.setState("view");
          }}
        >
          <Form.Item
            name="attrName"
            label="Название"
            rules={[{ required: true }]}
          >
            <Input ref={ref1} allowClear />
          </Form.Item>
          <Form.Item name="type" label="Тип">
            <Segmented options={attrTypes} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Применить
              </Button>
              <Button htmlType="button" onClick={onCancel}>
                Отмена
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);

const attrTypes = [
  { label: "Прямая", value: "line" },
  { label: "Кривая", value: "curve" },
];
