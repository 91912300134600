import * as React from "react";
import { Form } from "antd";
import {
  BlockContext,
  FormBlockItem,
  applyItemProps,
  getContextField,
  makeNameExt,
} from "../FormWithBlocks";
import { externalErrorItemProps } from "../FormWithBlocks/externalErrors";
import styles from "./DrawItems.module.less";

interface PropsDrawItems {
  items: (FormBlockItem | undefined)[];
  ctx: BlockContext;
}

export const DrawItems: React.FC<PropsDrawItems> = (props) => {
  const { items, ctx } = props;
  return (
    <>
      {(
        items?.filter(
          (item) => !!item && !item.isHidden?.(ctx),
        ) as FormBlockItem[]
      ).map(({ key, render, itemProps }) => (
        <Form.Item
          className={styles.formItem}
          {...applyItemProps(itemProps, ctx)}
          {...getContextField(ctx)}
          {...externalErrorItemProps(ctx, key)}
          name={makeNameExt(key, ctx)}
          key={key}
        >
          {render(ctx)}
        </Form.Item>
      ))}
    </>
  );
};
