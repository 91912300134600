import React from "react";
import { observer } from "mobx-react-lite";
import { Dropdown, Checkbox, Button, theme } from "antd";
import { SettingsIconStd } from "src/common/icons";
import { t } from "i18next";
import styles from "./ColumnVisibilityButton.module.less";
import { SchedulingControlStore } from "../../../SchedulingControlStore";

const { useToken } = theme;

interface PropsColumnVisibilityButton {
  store: SchedulingControlStore;
}

export const ColumnVisibilityButton: React.FC<PropsColumnVisibilityButton> =
  observer(({ store }) => {
    const { token } = useToken();
    const contentStyle: React.CSSProperties = {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
    };
    const { columns, hiddenColumns } = store;
    return (
      <Dropdown
        trigger={["click"]}
        dropdownRender={() => (
          <div style={contentStyle} className={styles.dropdownBox}>
            {columns
              ?.filter(({ key }) => key !== "name")
              .map(({ key, title }) => {
                const strKey = String(key);
                return (
                  <div key={key}>
                    <Checkbox
                      checked={!hiddenColumns.has(strKey)}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        store.toggleColumnVisibility(strKey);
                      }}
                    >
                      {String(title)}
                    </Checkbox>
                  </div>
                );
              })}
          </div>
        )}
      >
        <Button
          icon={<SettingsIconStd />}
          title={t("Number of hidden columns", { count: hiddenColumns.size })}
          type="text"
        />
      </Dropdown>
    );
  });
