import * as React from "react";
import { t } from "i18next";
import { Button, Flex, Form, Input, InputRef } from "antd";
import { appStore } from "src/appStore";
import { ruleEmail } from "src/common/validationRules/ruleEmail";
import { observer } from "mobx-react-lite";
import { PassRestoreData, passRestoreStore as store } from "./PassRestoreStore";
import { CommonOverlay } from "../CommonOverlay";
import styles from "./PassRestorePage.module.less";

export const PassRestorePage: React.FC = observer(() => {
  const inpRef = React.useRef<InputRef>(null);
  React.useEffect(() => {
    inpRef.current?.focus?.();
  }, [inpRef.current]);
  return (
    <CommonOverlay title={t("Password recovery")}>
      <Form<PassRestoreData>
        className={styles.form}
        layout="vertical"
        size="large"
        onFinish={(values: PassRestoreData) => store.send(values)}
      >
        <div className={styles.infoText}>
          {t("Enter the Email you used when registering your account")}
        </div>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: t("The field is mandatory") || "" },
            ruleEmail(),
          ]}
        >
          <Input ref={inpRef} allowClear />
        </Form.Item>
        <Flex vertical gap={12} className={styles.buttons}>
          <Button type="primary" htmlType="submit" loading={store.buzy}>
            {t("Send link")}
          </Button>
          <Button onClick={() => appStore.setOverlayType("login")}>
            {t("Return to the authorization page")}
          </Button>
        </Flex>
      </Form>
    </CommonOverlay>
  );
});
