import { Form, Radio } from "antd";
import React from "react";
import { ZTextAlign } from "../ZAttrViewStyles";
import { obj2LabelValue } from "../obj2LabelValue";

type PropsTextAlign = { name: string[] };

const labelMap: Record<ZTextAlign, string> = {
  center: "Центр",
  left: "Лево",
  right: "Право",
};

const options = obj2LabelValue(labelMap);

export const TextAlign: React.FC<PropsTextAlign> = ({ name }) => (
  <Form.Item label="Выравнивание текста" name={name}>
    <Radio.Group>
      {options.map(({ label, value }) => (
        <Radio.Button key={value} value={value}>
          {label}
        </Radio.Button>
      ))}
    </Radio.Group>
  </Form.Item>
);
