/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const PlansIconSvg: React.FC<Partial<CustomIconComponentProps>> = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="20"
    height="20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.70711 14.2071L15.7071 7.20711L14.2929 5.79289L8 12.0858L5.20711 9.29289L3.79289 10.7071L7.29289 14.2071L8 14.9142L8.70711 14.2071Z"
    />
  </svg>
);

export const PlansIcon: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={PlansIconSvg} {...props} />;
