import { z } from "zod";
import { ZAttrMeta } from "src/types/ZAttrMeta";
import { ZAttrViewInfo } from "../zAttrViewInfo";

export const zLinkStd = z.object({
  view: z.literal("LinkStd"),
});

export type ZLinkStd = z.infer<typeof zLinkStd>;

export const makeLinkStdProps = (
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
  attrMeta: ZAttrMeta,
) => ({
  values,
  viewInfo,
  attrMeta,
});
