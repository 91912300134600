import { Form, Radio } from "antd";
import React from "react";
import { ZFontWeight } from "../ZAttrViewStyles";
import { obj2LabelValue } from "../obj2LabelValue";

type PropsFontWeight = { name: string[] };

const labelMap: Record<ZFontWeight, string> = {
  "400": "Обычный",
  "700": "Жирный",
};

const options = obj2LabelValue(labelMap);

export const FontWeight: React.FC<PropsFontWeight> = ({ name }) => (
  <Form.Item label="Жирность текста" name={name}>
    <Radio.Group>
      {options.map(({ label, value }) => (
        <Radio.Button key={value} value={value}>
          {label}
        </Radio.Button>
      ))}
    </Radio.Group>
  </Form.Item>
);
