import { z } from "zod";

export const zExtNotificationStatus = z.enum(["READ", "UNREAD"]);
export type ZExtNotificationStatus = z.infer<typeof zExtNotificationStatus>;

/**
 * Формат сообщений, которые получаем от сервера (при участии WebSocket)
 */
export const zExtNotification = z.object({
  id: z.number(),
  userId: z.string(), // "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  type: z.string(),
  eventType: z.string(),
  subject: z.string(),
  text: z.string(),
  status: zExtNotificationStatus,
  eventTimestamp: z.string(), // "2024-07-17T10:52:23.543Z"
});

export type ZExtNotification = z.infer<typeof zExtNotification>;
