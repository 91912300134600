import * as React from "react";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { LoaderBox } from "src/components/LoaderBox";
import { Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ZMcSizeVersions } from "src/businessServices/services/bsMeasurementChart/ZMcSizeVersions";
import { confirmDelete } from "src/components/ModalDelete";
import { AddSampleModal } from "./AddSampleModal";
import styles from "./SamplePoints.module.less";
import { SamplesTable } from "./SamplesTable";
import { SamplePointsStore } from "./SamplePointsStore";

interface PropsSamplePoints {
  requestId: number | string;
  store: SamplePointsStore;
  sizeVersions: ZMcSizeVersions[];
}

export const SamplePoints: React.FC<PropsSamplePoints> = observer(
  ({ requestId, store, sizeVersions }) => {
    const [openAdd, setOpenAdd] = React.useState(false);
    React.useEffect(() => {
      store.init(requestId).catch(onError);
    }, [requestId]);
    const onDelete = () => {
      confirmDelete("Delete samples?", () => store.deleteSamples());
    };
    return (
      <div className={styles.frame}>
        <div className={styles.tools}>
          <div className={styles.leftTool} />
          <Button
            danger
            icon={<DeleteOutlined />}
            disabled={!store.canDelete}
            loading={store.deleting}
            onClick={onDelete}
          >
            Delete samples
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={!store.canAdd}
            onClick={() => setOpenAdd(true)}
          >
            Add sample
          </Button>
        </div>
        <div className={styles.content}>
          <LoaderBox
            remoteData={store.data}
            drawReady={() => <SamplesTable store={store} />}
          />
        </div>
        <AddSampleModal
          open={openAdd}
          close={() => setOpenAdd(false)}
          sizeVersions={sizeVersions}
          onSubmit={(values) => store.createSample(values).catch(onError)}
        />
      </div>
    );
  },
);
