import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Tabs, TabsProps } from "antd";
import { t } from "i18next";
import { schedulingControlStore as store } from "./SchedulingControlStore";
import { SchedulingHeader } from "./SchedulingHeader";
import { SchedListTab } from "./SchedListTab";
import styles from "./SchedulingControl.module.less";

interface PropsSchedulingControl {
  entityId: number;
  objectServiceId: number;
}

export const SchedulingControl: React.FC<PropsSchedulingControl> = observer(
  ({ entityId, objectServiceId }) => {
    useEffect(() => {
      store.init(entityId, objectServiceId);
    }, [entityId, objectServiceId]);
    const items: TabsProps["items"] = [
      {
        key: "taskList",
        label: t("Task list"),
        children: <SchedListTab store={store} />,
      },
    ];
    return (
      <>
        <SchedulingHeader store={store} />
        <div className={styles.box}>
          <Tabs items={items} />
        </div>
      </>
    );
  },
);
