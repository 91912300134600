import { FormRule } from "antd";
import { z } from "zod";

export const zAttrArticle = z.object({
  editor: z.literal("Article"),
});

const leftBrace = "{";
const rightBrace = "}";
const counter = "=";
const counterCell = `${leftBrace}${counter}${rightBrace}`;
const cellsParsingReg = new RegExp(
  `(${leftBrace}[^${leftBrace}${rightBrace}]+${rightBrace})`,
);
const escSpecSymbReg = /\\./g;

export const hasAttrArticleCounter = (guess: string) =>
  guess.includes(counterCell);
export const isLiter = (guess: string) => {
  const withoutSpecSymb = replaceSpecSymbols(guess);
  return (
    !withoutSpecSymb.includes(leftBrace) &&
    !withoutSpecSymb.includes(rightBrace)
  );
};
export const replaceSpecSymbols = (template: string) =>
  template.replaceAll(escSpecSymbReg, "");
export const isArticleCell = (guess: string) =>
  new RegExp(cellsParsingReg).test(guess);
export const isArticleCounter = (guess: string) => guess === counterCell;
export const parseArticleTemplateCells = (template: string) =>
  template.split(cellsParsingReg);
export const getArticleAttrCells = (template: string) =>
  parseArticleTemplateCells(template)
    .filter(isArticleCell)
    .filter((v) => !isArticleCounter(v));
export const validateArticle = (): FormRule => ({
  validator: async (_, value: string) => {
    const withoutSpecSymb = replaceSpecSymbols(value);
    /**
     * 1) кол-во открывающихся скобок = кол-ву закрывающихся
     * 2) нет пустых ячеек {}
     * 3) части шаблона либо литеры, либо ячейки
     */
    const lbdelta = withoutSpecSymb.replaceAll(leftBrace, "");
    const rbdelta = withoutSpecSymb.replaceAll(rightBrace, "");
    const parsed = parseArticleTemplateCells(withoutSpecSymb);
    const hasWrong = !!parsed.find((v) => !isArticleCell(v) && !isLiter(v));
    if (lbdelta.length !== rbdelta.length || hasWrong) {
      return Promise.reject(Error("Неправильный формат шаблона артикула"));
    }
    const cells = getArticleAttrCells(value)
      .filter(Boolean)
      .map((v) => v.toLocaleUpperCase());
    const keys = new Set<string>();
    const hasCopy = cells.find((v) => {
      if (keys.has(v)) return true;
      keys.add(v);
      return false;
    });
    if (hasCopy) {
      return Promise.reject(
        Error(
          `Шаблон не может содержать одинаковые идентификаторы '${hasCopy}'`,
        ),
      );
    }
    return Promise.resolve();
  },
});
