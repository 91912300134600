import { ZAttributeValue, zAttribute } from "src/types/ZAttribute";
import { optionalLangHeaders } from "src/lang/langHdr";
import { apiObjUrl } from "./apiUrl";
import { restCache } from "./restCache";

export const loadRefDict = async (
  refId: number,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZAttributeValue[]> => {
  const resp = await restCache.get(
    apiObjUrl(`/attributes/${refId}`),
    {},
    optionalLangHeaders(options.translate),
  );
  return zAttribute.parse(resp.data).values || [];
};
