// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ModalSupport-ModalSupport-module__modal--UFFw2 .ant-modal-content,
.src-components-ModalSupport-ModalSupport-module__modal--UFFw2 .src-components-ModalSupport-ModalSupport-module__successContent--o7Ybh {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.src-components-ModalSupport-ModalSupport-module__modal--UFFw2 .ant-modal-header {
  margin-bottom: 0;
}
.src-components-ModalSupport-ModalSupport-module__form--Dk44J {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.src-components-ModalSupport-ModalSupport-module__uploadLabel--QsNTX {
  color: #0000008C;
}
.src-components-ModalSupport-ModalSupport-module__buttonsBlock--iNiAr {
  align-self: end;
}
.src-components-ModalSupport-ModalSupport-module__closeButton--gdOyB {
  align-self: end;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalSupport/ModalSupport.module.less"],"names":[],"mappings":"AAAA;;EAEI,aAAA;EACA,sBAAA;EACA,aAAA;AACJ;AALA;EAQI,gBAAA;AAAJ;AAIA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAFF;AAKA;EACE,gBAAA;AAHF;AAMA;EACE,eAAA;AAJF;AAOA;EACE,eAAA;AALF","sourcesContent":[".modal {\n  :global(.ant-modal-content), .successContent {\n    display: flex;\n    flex-direction: column;\n    row-gap: 24px;\n  }\n\n  :global(.ant-modal-header) {\n    margin-bottom: 0;\n  }\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  row-gap: 24px;\n}\n\n.uploadLabel {\n  color: #0000008C;\n}\n\n.buttonsBlock {\n  align-self: end;\n}\n\n.closeButton {\n  align-self: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `src-components-ModalSupport-ModalSupport-module__modal--UFFw2`,
	"successContent": `src-components-ModalSupport-ModalSupport-module__successContent--o7Ybh`,
	"form": `src-components-ModalSupport-ModalSupport-module__form--Dk44J`,
	"uploadLabel": `src-components-ModalSupport-ModalSupport-module__uploadLabel--QsNTX`,
	"buttonsBlock": `src-components-ModalSupport-ModalSupport-module__buttonsBlock--iNiAr`,
	"closeButton": `src-components-ModalSupport-ModalSupport-module__closeButton--gdOyB`
};
export default ___CSS_LOADER_EXPORT___;
