// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-EntityList-GroupCell-GroupCell-module__box--Pa2t8 {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 3px;
}
.src-pages-EntityFiltersPage-EntityList-GroupCell-GroupCell-module__box--Pa2t8 .ant-tag {
  margin: 0;
}
.src-pages-EntityFiltersPage-EntityList-GroupCell-GroupCell-module__withNewLineSep--cguOE {
  overflow: hidden;
  width: 100%;
  height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/EntityList/GroupCell/GroupCell.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,eAAA;EACA,QAAA;AACF;AALA;EAMI,SAAA;AAEJ;AAEA;EACE,gBAAA;EACA,WAAA;EACA,SAAA;AAAF","sourcesContent":[".box {\n  display: flex;\n  align-items: baseline;\n  flex-wrap: wrap;\n  gap: 3px;\n  :global(.ant-tag) {\n    margin: 0;\n  }\n}\n\n.withNewLineSep {\n  overflow: hidden;\n  width: 100%;\n  height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-EntityFiltersPage-EntityList-GroupCell-GroupCell-module__box--Pa2t8`,
	"withNewLineSep": `src-pages-EntityFiltersPage-EntityList-GroupCell-GroupCell-module__withNewLineSep--cguOE`
};
export default ___CSS_LOADER_EXPORT___;
