// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-CategoryRefPage-CategoryAttributes-CategoryAttributes-module__box--iRh1P {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.src-pages-CategoryRefPage-CategoryAttributes-CategoryAttributes-module__main--WtwbB {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryRefPage/CategoryAttributes/CategoryAttributes.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACA;EACE,OAAA;AACF","sourcesContent":[".box {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.main {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-CategoryRefPage-CategoryAttributes-CategoryAttributes-module__box--iRh1P`,
	"main": `src-pages-CategoryRefPage-CategoryAttributes-CategoryAttributes-module__main--WtwbB`
};
export default ___CSS_LOADER_EXPORT___;
