import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { PlusCircleFilled } from "@ant-design/icons";
import { Alert, Button, Drawer, Form, Select, Space, Spin } from "antd";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { ZAttrViewInfo } from "src/common/attrView";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectRefSelector } from "src/common/attrEdit/components";
import { ObjectRefSelectorStore } from "./ObjectRefSelectorStore";
import { ObjectRefSelectorLabel } from "./ObjectRefSelectorLabel";
import { KeepSelected } from "../tables/KeepSelected";
import styles from "./ObjectRefSelector.module.less";

type PropsObjectRefSelector = {
  attrInfo: ZAttribute;
  component: ZObjectRefSelector;
  viewInfo: ZAttrViewInfo | undefined;
  value?: string[];
  disabled?: boolean;
  onChange?: (value?: string[]) => void;
  label: string;
  objectName?: string;
  entityId?: number;
};

export const ObjectRefSelector: React.FC<PropsObjectRefSelector> = observer(
  ({
    attrInfo,
    component,
    viewInfo,
    value,
    disabled,
    onChange,
    label,
    objectName,
    entityId,
  }) => {
    const [open, setOpen] = useState(false);
    const store = useMemo(() => new ObjectRefSelectorStore(), []);
    const { filtersPageStore: filterStore } = store;
    const form = Form.useFormInstance();
    const filterField: string[] | null | undefined = Form.useWatch(
      String(attrInfo.filter),
      form,
    );
    // Итератор используется только для создания новых экземпляров. LPLM-1282
    const isIterator = !!attrInfo.iterator && !entityId;
    const safeValues = value || [];
    // Если компонент в принципе может использоваться для множественного создания, то параметр из описания компонента игнорируется
    const multiple = attrInfo.iterator ? isIterator : !!component.multiple;

    const syncWithValues = (vals: string[]) => {
      store.setValues(vals);
      store.syncSelected(vals);
    };

    useEffect(() => {
      syncWithValues(safeValues);
    }, [value]);

    useEffect(() => {
      if (!viewInfo) return;
      store.init(attrInfo, component, viewInfo, multiple, value);
    }, [attrInfo.referenceId]);

    useEffect(() => {
      if (open) {
        store.addFilters(form);
        store.setInitialValues(safeValues);
      }
    }, [open]);

    const selectDisabled =
      (attrInfo.filter && !filterField?.length) || disabled;

    const tableStore = filterStore?.tableStore;

    const handleClose = () => {
      store.syncSelected(store.initialValues || []);
      setOpen(false);
    };

    const handleOk = () => {
      const newValues = tableStore?.selected.map(({ id }) => String(id));
      onChange?.(newValues || []);
      store.setInitialValues(newValues || []);
      setOpen(false);
    };

    if (!filterStore) return null;
    const options = safeValues.map((eId) => ({
      label: (
        <ObjectRefSelectorLabel
          component={store.component}
          viewInfo={store.viewInfo}
          entityId={Number(eId)}
        />
      ),
      value: String(eId),
    }));

    return (
      <Spin spinning={store.isLoading}>
        <Select
          key={`$refSelect-${value?.join("-")}`}
          disabled={selectDisabled}
          className={styles.select}
          open={false}
          value={value}
          loading={store.loading}
          mode={multiple ? "multiple" : undefined}
          onClick={() => {
            if (!selectDisabled) setOpen(true);
          }}
          showSearch={false}
          options={options}
          suffixIcon={!selectDisabled && <PlusCircleFilled />}
          onChange={(list) => {
            onChange?.(list);
          }}
        />
        {isIterator && (
          <Alert
            type="warning"
            showIcon
            message={t(
              "When saving, multiple instances of the object will be created",
              { attr: label, obj: objectName ?? "?" },
            )}
          />
        )}
        <Drawer
          width="75vw"
          open={open && !selectDisabled}
          onClose={handleClose}
          className={styles.drawer}
          title={label}
          forceRender={!!attrInfo.filter}
          footer={
            <Space>
              <Button type="primary" onClick={handleOk}>
                {t("Save")}
              </Button>
              <Button onClick={handleClose}>{t("Cancel")}</Button>
            </Space>
          }
        >
          <div className={styles.drawerContent}>
            {tableStore && (
              <>
                <div className={styles.selectedEntitiesTitle}>
                  {t("Selected instances")}
                </div>
                <KeepSelected
                  store={tableStore}
                  makeTag={({ id }) => (
                    <ObjectRefSelectorLabel
                      component={store.component}
                      viewInfo={store.viewInfo}
                      entityId={id}
                    />
                  )}
                />
              </>
            )}
            <EntityFiltersTable store={filterStore} />
          </div>
        </Drawer>
      </Spin>
    );
  },
);

ObjectRefSelector.defaultProps = {
  value: undefined,
  onChange: undefined,
  objectName: undefined,
};
