import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AttrCommonFields } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/AttrCommonFields";
import { ZAttribute } from "src/types/ZAttribute";
import { findNodeOwnerByKey } from "src/common/findNodeOwnerByKey";
import { findNode } from "src/common/findNode";
import { loadObjects } from "src/pages/ManagementPage/objectsApi";
import { ExtraTools } from "src/pages/ManagementPage/ExtraTools";
import { RolesTabStore } from "../../RolesTabStore";
import { AttrRoleNode, CommonRoleNode } from "../../nodes/RoleNode";

interface PropsRoleAttrField {
  store: RolesTabStore;
  node: AttrRoleNode;
}

export const RoleAttrField: React.FC<PropsRoleAttrField> = observer(
  ({ store, node }) => (
    // const isNew = node.attr.id === newItemId;
    <AttrCommonFields
      disabled={false}
      attrKey={node.key}
      attrId={node.attr.id}
      objectId={store.currentObjectId ?? 0}
      dataSource={store}
      isFieldUnique={uniqueAttrTest(store.treeData)}
      loadObjectOptions={loadObjects}
      objIteratorMode="hidden"
    />
  ),
);

const uniqueAttrTest =
  (treeData: CommonRoleNode[]) =>
  (field: keyof ZAttribute, attrKey: React.Key, value: unknown): boolean => {
    const objRes = findNodeOwnerByKey(attrKey, treeData);
    if (!objRes) return true;
    return !findNode((node: CommonRoleNode) => {
      if (node.type !== "attr" || node.key === attrKey || !value) return false;
      return node.attr[field] === value;
    }, objRes.owner.children ?? []);
  };

export const RoleAttrButtons: React.FC<PropsRoleAttrField> = observer(
  ({ store }) => {
    const canDelete = true; // Пока нет ролевой модели
    return (
      <ExtraTools>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => store.safeDeleteCurNode()}
          disabled={!canDelete}
          id="deleteCurAttr"
        >
          Удалить атрибут
        </Button>
      </ExtraTools>
    );
  },
);
