// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleCellEdit-SampleCellEdit-module__fullWidth--RULRF {
  width: 100%;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleCellEdit-SampleCellEdit-module__redCell--ACHhy input {
  color: #E9033A !important;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SamplePoints/Subsample/SampleMcPoints/SampleCellEdit/SampleCellEdit.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AACA;EACW,yBAAA;AACX","sourcesContent":[".fullWidth {\n    width: 100%;\n}\n.redCell {\n    input {color: #E9033A !important;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleCellEdit-SampleCellEdit-module__fullWidth--RULRF`,
	"redCell": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleCellEdit-SampleCellEdit-module__redCell--ACHhy`
};
export default ___CSS_LOADER_EXPORT___;
