import * as React from "react";
import { Point, toa } from "../../../math";
import { DrawProps } from "../../DrawProps";

interface PropsDrawArrow {
  from: Point;
  to: Point;
  drawProps: DrawProps;
}

export const DrawArrow: React.FC<PropsDrawArrow> = ({
  from,
  to,
  drawProps,
}: PropsDrawArrow) => {
  const { arrowL, arrowD } = drawProps;
  const n = from.minus(to).normal();
  const c = to.plus(n.times(arrowL));
  const cL = c.plus(n.transpon(true).times(arrowD));
  const cR = c.plus(n.transpon().times(arrowD));

  const points: Point[] = [to, cL, cR];
  const path = `${points
    .map((p, i) => `${i ? "L" : "M"}${toa(p.x)} ${toa(p.y)}`)
    .join("")} z`;
  return <path className="arrow" d={path} />;
};
