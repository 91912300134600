/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const TemplatesIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => (
  <svg
    viewBox="0 0 16 16"
    {...props}
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0V6H16V0H0ZM14.5 1.5H1.5V4.5H14.5V1.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.5V16H9.5V7.5H0ZM8 9H1.5V14.5H8V9Z"
    />
    <path d="M16 8H11V9.5H16V8Z" fillRule="evenodd" clipRule="evenodd" />
    <path d="M11 11H16V12.5H11V11Z" fillRule="evenodd" clipRule="evenodd" />
    <path d="M16 14H11V15.5H16V14Z" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);

export const TemplatesIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={TemplatesIconStdSvg} {...props} />;
