import React from "react";
import { observer } from "mobx-react-lite";
import { CtrlRows } from "src/components/tables/TableStore";
import { t } from "i18next";
import styles from "./EntityList.module.less";
import { ZEntityRow } from "./types";

type PropsEntityCount = {
  store: CtrlRows<ZEntityRow>;
};

export const EntityCount: React.FC<PropsEntityCount> = observer(({ store }) => (
  <div className={styles.entityCount}>
    {t("Total instances count", { count: store?.rows.length })}
  </div>
));
