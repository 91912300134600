// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-EntityFiltersPage-module__needSelect--Vs71P {
  padding: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/EntityFiltersPage.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF","sourcesContent":[".needSelect {\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"needSelect": `src-pages-EntityFiltersPage-EntityFiltersPage-module__needSelect--Vs71P`
};
export default ___CSS_LOADER_EXPORT___;
