import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "antd";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { ExpandButton } from "src/components/ExpandButton";
import { onError } from "src/common/onError";
import { t } from "i18next";
import { EdBomPos } from "../../ZBom2Position";
import { Bom2PositionStore } from "../../Bom2PositionStore";
import { Bom2Cell } from "../Bom2Cell";
import styles from "./BomPositionItem.module.less";
import { SelectBomMaterial } from "../../SelectBomMaterial";

interface PropsBomPositionItem {
  store: Bom2PositionStore;
  pos: EdBomPos;
}

export const BomPositionItem: React.FC<PropsBomPositionItem> = observer(
  ({ store, pos }) => {
    const { length } = store.columns;
    const { materialTypeId } = pos;
    const gridColumn = `span ${length - 1}`;
    const [matOpen, setMatOpen] = React.useState(false);
    return (
      <Bom2Cell style={{ gridColumn }} minWidth={undefined}>
        <div className={styles.positionBox}>
          <ExpandButton
            collapsed={store.collapsed.has(materialTypeId)}
            onClick={() => store.toggleCollapse(pos)}
          />
          <div className={styles.name}>
            {pos.materialTypeName} ({pos.rows.length})
          </div>
          <Button
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() =>
              store.createEmptyPosition(materialTypeId).catch(onError)
            }
          >
            {t("Create empty matType", { matType: pos.materialTypeName })}
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => setMatOpen(true)}
          >
            {t("Create matType from library", {
              matType: pos.materialTypeName,
            })}
          </Button>
        </div>
        <SelectBomMaterial
          title={t("Creating matType from library", {
            matType: pos.materialTypeName,
          })}
          materialTypeId={materialTypeId}
          multi
          open={matOpen}
          close={() => setMatOpen(false)}
          exec={(list) => store.createPositions(list).catch(onError)}
        />
      </Bom2Cell>
    );
  },
);
