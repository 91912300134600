import * as React from "react";
import { ZIdName } from "src/types/ZIdName";
import { Form, FormRule, Spin } from "antd";
import { onError } from "src/common/onError";
import { edAttrField } from "../../EdAttribute";
import { PrimaryDirectionMode, RestrictionsTable } from "../RestrictionsTable";
import { isRestrictionDictEmpty } from "../RestrictionsTable/restrictionUtils";
import { loadDict, loadFromAttr } from "../../../objectsApi";

interface PropsRestrictionsTableField {
  disabled?: boolean;
}

// Возникла проблема, связанная с тем что пользователь может произвольно поменять оба справочника, от которых зависит таблица
// В этом случае, нужно заполнить таблицу новыми значениями
// Конечно, для этого надо отслеживать изменения соответствующих полей формы.
// Но эти изменения происходят не только в результате действий пользователя, но и в результате процессов загрузки.
// Поэтому нужно как-то отличать эти ситуации.
// Будем считать, что пока любой из словарей undefined, то происходит подготовка.
// При подготовке значение таблицы не меняется. А если уже ready, то таблица очищается.

type DataStatus = "prepare" | "ready";

export const RestrictionsTableField: React.FC<PropsRestrictionsTableField> = ({
  disabled,
}) => {
  const form = Form.useFormInstance();
  const slaveDictId = Form.useWatch(edAttrField.referenceId);
  const masterAttrId = Form.useWatch(edAttrField.masterAttrId);
  const [status, setStatus] = React.useState<DataStatus>("prepare");

  const [lockCounter, setLockCounter] = React.useState(0);
  const changeLock = (step: -1 | 1) =>
    setLockCounter((prevCounter) => prevCounter + step);
  const lock = () => changeLock(1);
  const unlock = () => changeLock(-1);

  // Управление режимом для направления первичного словаря
  const [direction, setDirection] =
    React.useState<PrimaryDirectionMode>("column");

  const [masterDict, setMasterDict] = React.useState<ZIdName[] | undefined>();
  React.useEffect(() => {
    if (masterAttrId) {
      lock();
      loadFromAttr(masterAttrId)
        .then((list) => setMasterDict(list))
        .finally(unlock)
        .catch(onError);
    } else {
      setMasterDict(undefined);
    }
  }, [masterAttrId]);

  const [slaveDict, setSlaveDict] = React.useState<ZIdName[] | undefined>();
  React.useEffect(() => {
    if (slaveDictId) {
      lock();
      loadDict(slaveDictId)
        .then((list) => {
          setSlaveDict(list);
        })
        .finally(unlock)
        .catch(onError);
    } else {
      setSlaveDict(undefined);
    }
  }, [slaveDictId]);

  React.useEffect(() => {
    if (status === "prepare") {
      if (masterDict && slaveDict) {
        setStatus("ready");
      }
    } else if (masterDict && slaveDict) {
      form.setFieldValue(edAttrField.restrictions, {});
      // При смене словарей все ранее сделанные изменения сбрасываются
      // Можно их заполнять - createRestrictions(masterDict, slaveDict)
    }
  }, [masterDict, slaveDict]);

  if (!masterDict?.length || !slaveDict?.length) {
    return status === "ready" ? (
      <div>Не указаны подходящие справочники</div>
    ) : (
      <Spin />
    );
  }

  return (
    <Spin spinning={lockCounter !== 0}>
      <Form.Item
        name={edAttrField.restrictions}
        rules={[ruleNonEmptyRestrictions]}
      >
        <RestrictionsTable
          primaryDictionary={masterDict}
          secondaryDictionary={slaveDict}
          direction={direction}
          onChangeDirection={(mode) => setDirection(mode)}
          disabled={disabled}
        />
      </Form.Item>
    </Spin>
  );
};

RestrictionsTableField.defaultProps = {
  disabled: false,
};

const ruleNonEmptyRestrictions: FormRule = {
  validator: (rule, value) => {
    if (value && isRestrictionDictEmpty(value)) {
      return Promise.reject(Error("Не указано ни одной зависимости"));
    }
    return Promise.resolve();
  },
};
