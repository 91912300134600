/* eslint import/no-extraneous-dependencies: "off" */
import * as React from "react";
import { PageLayout } from "src/components/PageLayout";
import { t } from "i18next";
import { Tabs } from "antd";
import { Tab } from "rc-tabs/lib/interface";
import { observer } from "mobx-react-lite";
import { useModelessNavProtector } from "src/components/ModelessForm";
import {
  ManagementTabKey,
  managementPageStore as pageStore,
} from "./managementPageStore";
import { Dict2Tab } from "./Dict2Tab";
import { Obj2Tab } from "./Obj2Tab";
import { RolesTab } from "./RolesTab";
import { Menu2Tab } from "./Menu2Tab/Menu2Tab";
import { PersonsTab } from "./PersonsTab";
import styles from "./ManagementPage.module.less";
import { DashboardTab } from "./DashboardTab";
import { ExtraSettingsTab } from "./ExtraSettingsTab";
import { VersionTab } from "./VersionTab";

type ManagementTabDescr = {
  labelKey: string;
  content: React.ReactNode;
};

const partMapping: Record<ManagementTabKey, ManagementTabDescr> = {
  obj: {
    labelKey: "Objects and attributes",
    content: <Obj2Tab store={pageStore.obj2TabStore} />,
  },
  dict: {
    labelKey: "Dictionaries",
    content: <Dict2Tab store={pageStore.dict2TabStore} />,
  },
  roles: {
    labelKey: "Groups and roles",
    content: <RolesTab store={pageStore.rolesTabStore} />,
  },
  persons: {
    labelKey: "Persons",
    content: <PersonsTab store={pageStore.personsTabStore} />,
  },
  dictWithLogic: {
    labelKey: "Bussiness dictionaries",
    content: <div>Пока не брали в работу</div>,
  },
  menu: {
    labelKey: "Menu settings",
    content: <Menu2Tab store={pageStore.menu2TabStore} />,
  },
  dashboard: {
    labelKey: "Dashboards",
    content: <DashboardTab store={pageStore.dashboardsStore} />,
  },
  extraSettings: {
    labelKey: "Advanced settings",
    content: <ExtraSettingsTab store={pageStore.extraSettingsStore} />,
  },
  version: {
    labelKey: "Version",
    content: <VersionTab />,
  },
};

export const ManagementPage: React.FC = observer(() => {
  useModelessNavProtector(pageStore.formStores);
  const items: Tab[] = Object.entries(partMapping).map(
    ([key, { labelKey }]) => ({ key, label: t(labelKey) }),
  );
  React.useEffect(() => pageStore.init(), []);

  return (
    <PageLayout
      pageTitle={t("Management")}
      header={
        <div className={styles.header}>
          <Tabs
            activeKey={pageStore.curTab}
            items={items}
            onChange={(key) => pageStore.safeSetCurTab(key as ManagementTabKey)}
          />
        </div>
      }
    >
      {partMapping[pageStore.curTab]?.content}
    </PageLayout>
  );
});
