import { ZNotifyTemplate, ZNotifyTemplateToSave } from "./ZNotifyTemplate";

export type EdNotifyTemplate = Omit<ZNotifyTemplateToSave, "stateId">;
export type EdNotifyTemplateDict = Record<number, EdNotifyTemplate[]>;

export const templates2ed = (list: ZNotifyTemplate[]): EdNotifyTemplateDict => {
  const dict: EdNotifyTemplateDict = {};
  list.forEach(({ stateId, ...rest }) => {
    dict[stateId] = dict[stateId] ?? [];
    dict[stateId]?.push(rest);
  });
  return dict;
};

export const ed2templates = (
  dict: EdNotifyTemplateDict,
): ZNotifyTemplateToSave[] => {
  const list: ZNotifyTemplateToSave[][] = Object.entries(dict).map(
    ([stateId, t]: [string, EdNotifyTemplate[]]) =>
      t.map((it) => ({ stateId: +stateId, ...it })),
  );
  return list.flatMap((item) => item);
};
