import * as React from "react";
import { InputNumber, InputNumberProps } from "antd";
import { CellBox, PropsCellBox, PropsCellBoxChildren } from "./CellBox";

type NumberValue = number | string | null | undefined;
type NumberProps = Omit<InputNumberProps, "onBlur" | "onChange" | "value">;

export type PropsCellBoxNumber<TValue extends NumberValue, Res> = Omit<
  PropsCellBox<TValue, Res>,
  "children"
> & {
  numberProps?: NumberProps;
};

export const CellBoxNumber = <TValue extends NumberValue, Res = void>(
  props: PropsCellBoxNumber<TValue, Res>,
) => {
  const { numberProps, ...cellProps } = props;
  return (
    <CellBox<TValue, Res> {...cellProps}>
      {(chProps) => (
        <CellBoxNumberChildren {...chProps} numberProps={numberProps} />
      )}
    </CellBox>
  );
};

CellBoxNumber.defaultProps = {
  numberProps: undefined,
};

type PropsCellBoxNumberChildren<TValue extends NumberValue> =
  PropsCellBoxChildren<TValue> & {
    numberProps: NumberProps | undefined;
  };

const CellBoxNumberChildren = <TValue extends NumberValue>(
  props: PropsCellBoxNumberChildren<TValue>,
) => {
  const { numberProps, value, onChange, onBlur, getFocus } = props;
  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    getFocus(() => {
      ref.current?.select();
    });
    return () => getFocus(undefined);
  }, [ref.current]);
  return (
    <InputNumber<number | string>
      ref={ref}
      maxLength={14}
      style={{ width: "100%", backgroundColor: "transparent" }}
      {...numberProps}
      value={value}
      onChange={(v) => onChange(v as unknown as TValue)}
      onBlur={() => onBlur()}
      parser={(val) => val?.replace(",", ".") ?? ""}
    />
  );
};
