import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import {
  SupportState,
  ZSupportFields,
  zSupportFields,
} from "./ModalSupport.types";

export const modalSupportStore = makeAutoObservable({
  supportState: <SupportState>"none",
  setSupportState(state: SupportState) {
    this.supportState = state;
  },

  initialValues: <ZSupportFields>{
    subject: "",
    question: "",
    attachedFiles: [],
  },

  onCancel() {
    this.setSupportState("none");
  },

  async onSubmit(values: ZSupportFields) {
    try {
      const result = zSupportFields.partial().parse(values);
      this.setSupportState("work");
      await rest.post(apiObjUrl("/support"), result);
      this.setSupportState("success");
    } catch (e) {
      onError(e);
      this.setSupportState("show");
    }
  },
});

export type ModalSupportStore = typeof modalSupportStore;
