// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingControl-module__box--wRBUV {
  padding: 16px 24px;
  border-top: 1px solid #0000000F;
  height: 100%;
  overflow: hidden;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingControl-module__box--wRBUV .ant-tabs {
  height: 100%;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingControl-module__box--wRBUV .ant-tabs .ant-tabs-content {
  height: 100%;
}
.src-businessServices-services-bsScheduling-SchedulingControl-SchedulingControl-module__box--wRBUV .ant-tabs .ant-tabs-tabpane {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingControl/SchedulingControl.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,+BAAA;EACA,YAAA;EACA,gBAAA;AACF;AALA;EAOI,YAAA;AACJ;AARA;EASM,YAAA;AAEN;AAXA;EAYM,YAAA;AAEN","sourcesContent":[".box {\n  padding: 16px 24px;\n  border-top: 1px solid #0000000F;\n  height: 100%;\n  overflow: hidden;\n\n  :global(.ant-tabs) {\n    height: 100%;\n    :global(.ant-tabs-content) {\n      height: 100%;\n    } \n    :global(.ant-tabs-tabpane) {\n      height: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsScheduling-SchedulingControl-SchedulingControl-module__box--wRBUV`
};
export default ___CSS_LOADER_EXPORT___;
