// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__emptyBox--pWqAd {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  gap: 4px;
  color: rgba(0, 0, 0, 0.55);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__box--iSwe5 {
  display: grid;
  grid-template-columns: 1fr 88px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__box--iSwe5 > div {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-bottom: #D9D9D9 thin solid;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__box--iSwe5 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__selected--q4bkb {
  background-color: #FFF1E6;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__box--iSwe5 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__header--DXKeW {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 14px;
  background-color: #FAFAFA;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__box--iSwe5 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__group--CmoMQ {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__box--iSwe5 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__role--DQPxJ {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 32px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__box--iSwe5 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__expandButton--FzG5U {
  cursor: pointer;
  background: none;
  border: #D9D9D9 thin solid;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectRoles/SelectRoles.module.less"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,QAAA;EAEA,0BAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;AAOA;EACI,aAAA;EACA,+BAAA;AALJ;AAMI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;EACA,iCAAA;AAJR;AALA;EAYQ,yBAAA;AAJR;AARA;EAeQ,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;AAJR;AAdA;EAqBQ,gBAAA;EACA,eAAA;EACA,0BAAA;AAJR;AAnBA;EAqBQ,gBAAA;EACA,eAAA;EACA,0BAAA;EAIA,kBAAA;AAFR;AAzBA;EAgCQ,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAJR","sourcesContent":["@border: #D9D9D9 thin solid;\n\n\n.emptyBox {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 24px 0;\n    gap: 4px;\n\n    color: rgba(0, 0, 0, 0.55);\n    // text-align: center;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 150%;\n}\n\n.box {\n    display: grid;\n    grid-template-columns: 1fr 88px;\n    & > div {\n        padding: 8px;\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        gap: 8px;\n        border-bottom: @border;\n    }\n    .selected {\n        background-color: #FFF1E6;\n    }\n    .header {\n        color: rgba(0, 0, 0, 0.85);\n        font-weight: 500;\n        font-size: 14px;\n        background-color: #FAFAFA;\n    }\n    .group {\n        font-weight: 400;\n        font-size: 14px;\n        color: rgba(0, 0, 0, 0.85);\n    }\n    .role {\n        .group;\n        padding-left: 32px;\n    }\n    .check {\n    }\n    .expandButton {\n        cursor: pointer;\n        background: none;\n        border: #D9D9D9 thin solid;\n        border-radius: 4px;\n        width: 16px;\n        height: 16px;\n        padding: 0;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        font-size: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyBox": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__emptyBox--pWqAd`,
	"box": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__box--iSwe5`,
	"selected": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__selected--q4bkb`,
	"header": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__header--DXKeW`,
	"group": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__group--CmoMQ`,
	"role": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__role--DQPxJ`,
	"expandButton": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-SelectRoles-SelectRoles-module__expandButton--FzG5U`
};
export default ___CSS_LOADER_EXPORT___;
