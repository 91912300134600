import { zRolesViewAtts } from "src/common/roles/ZRolesViewAtts";
import { z } from "zod";
import { ZAttribute } from "src/types/ZAttribute";

export const zModePersonCell = z.enum(["standart", "tag"]);
export type ZModePersonCell = z.infer<typeof zModePersonCell>;

export const zModePersonCellMeta = z.object({
  tagColor: z.string().optional(),
});

export const zPersonCellInfo = z.object({
  view: z.literal("PersonCellInfo"),
  mode: zModePersonCell.optional(),
  modeMeta: zModePersonCellMeta.optional(),
  rolesViewAtts: zRolesViewAtts,
});

export type ZPersonCellInfo = z.infer<typeof zPersonCellInfo>;

export const makePersonCellInfoProps = (
  values: string[],
  attr: ZAttribute,
) => ({
  values,
  attr,
});
