import { ZCmpOp, dateCmpOpLabel } from "src/common/attrEdit";

type DateCmpOption = {
  value: ZCmpOp;
  label: string;
};

let options: DateCmpOption[] | undefined;

export const dateCmpOptions = (): DateCmpOption[] => {
  if (options) return options;
  options = Object.entries(dateCmpOpLabel).map(([value, label]) => ({
    value: value as ZCmpOp,
    label,
  }));
  return options;
};
