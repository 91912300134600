import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, InputRef } from "antd";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { safeFocus } from "src/common/safeFocus";
import { TopGroupRoleNode } from "../nodes/RoleNode";
import { RolesTabStore } from "../RolesTabStore";
import { WidthLimitedFields } from "../../WidthLimitedFields";
import { ExtraTools } from "../../ExtraTools";

interface PropsRoleGroupFields {
  node: TopGroupRoleNode;
  store: RolesTabStore;
}

export const RoleGroupFields: React.FC<PropsRoleGroupFields> = observer(
  ({ node }) => {
    const ref1 = React.createRef<InputRef>();
    React.useEffect(() => {
      safeFocus(ref1.current);
    }, [node.group.groupId]);
    // const isNew = node.group.groupId === newItemId;
    return (
      <WidthLimitedFields>
        <Form.Item
          name="groupName"
          label="Название группы ролей"
          rules={[{ required: true }]}
        >
          <Input ref={ref1} allowClear />
        </Form.Item>
      </WidthLimitedFields>
    );
  },
);

export const RoleGroupButtons: React.FC<PropsRoleGroupFields> = observer(
  ({ store }) => {
    const canDelele = true; // Пока нет пермишенов
    return (
      <ExtraTools>
        <Button icon={<UserAddOutlined />} onClick={() => store.safeAddRole()}>
          Добавить роль
        </Button>
        <Button
          icon={<DeleteOutlined />}
          danger
          onClick={() => {
            store.safeDeleteCurNode();
          }}
          disabled={!canDelele}
        >
          Удалить группу
        </Button>
      </ExtraTools>
    );
  },
);
