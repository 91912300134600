import { SavedTranslation } from "src/common/api/apiTranslation";
import { makeDictionary } from "src/common/makeDictionary";
import { ZAttribute, ZAttributeValue } from "src/types/ZAttribute";
import { ZTranslation, ZTranslationMap } from "src/types/ZTranslation";

type DictValue = ZAttributeValue & {
  loc: ZTranslationMap;
};

export type EdDict = {
  name: string;
  values: DictValue[];
};

export const attr2edDict = (
  attr: ZAttribute,
  localValues?: ZTranslation[],
): EdDict => {
  const locDict = localValues
    ? makeDictionary(localValues, ({ value }) => value)
    : {};
  return {
    name: attr.name,
    values: (attr.values ?? []).map(({ id, value }) => ({
      id,
      value,
      loc: locDict[value ?? ""]?.translations ?? {},
    })),
  };
};

export const edDict2attr = (
  ed: EdDict,
  oldAttr: ZAttribute,
): {
  attr: Pick<ZAttribute, "name" | "values">;
  translations: SavedTranslation[];
} => {
  const { values, ...restAttr } = ed;
  const oldDict = (oldAttr.values ?? []).reduce(
    (acc, { id, value }) => ({ ...acc, [id]: value }),
    {} as Record<number, string | null>,
  );
  return {
    attr: {
      ...restAttr,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      values: values.map(({ loc, ...restVal }) => restVal),
    },
    translations: values.map(({ loc, value, id }) => ({
      value: value || "",
      oldValue: oldDict[id] || "",
      translations: loc,
    })),
  };
};
