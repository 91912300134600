import { ZAttribute } from "src/types/ZAttribute";
import { CommonNodeO2 } from "../Obj2Nodes";

/**
 * Согласно очередным изменениям, в дереве может присутствовать несколько отрибутов с одинаковым id
 */
export const findAttrById = (
  attrId: number,
  nodes: CommonNodeO2[],
): ZAttribute | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of nodes) {
    if (node.type === "attr" && node.attr.id === attrId) {
      return node.attr;
    }
    const { children } = node;
    if (children) {
      const res = findAttrById(attrId, children);
      if (res) {
        return res;
      }
    }
  }
  return undefined;
};
