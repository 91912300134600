import { observer } from "mobx-react-lite";
import * as React from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  InputRef,
  Modal,
  Space,
  notification,
} from "antd";
import { ModelEditorStore } from "../../ModelEditorStore";
import { CmdModelChange } from "../../commands/CmdModelChange";
import { ZModel } from "../../../ZModel";

interface PropsModalEditModel {
  store: ModelEditorStore;
}

type EditableFields = Pick<
  ZModel,
  "imgHeight" | "imgRef" | "imgWidth" | "name"
>;

export const ModalEditModel: React.FC<PropsModalEditModel> = observer(
  ({ store }: PropsModalEditModel) => {
    const open = store.state === "editModel";
    const { model } = store;
    const onCancel = () => store.setState("view");
    const ref1 = React.useRef<InputRef>(null);
    React.useEffect(() => {
      if (open) setTimeout(() => ref1.current?.focus(), 20);
    }, [open]);

    if (!model) return null;
    const { name, imgRef, imgHeight, imgWidth } = model;
    const initialValues: EditableFields = {
      name,
      imgRef,
      imgWidth,
      imgHeight,
    };
    const onSubmit = (values: EditableFields) => {
      if (JSON.stringify(values) === JSON.stringify(initialValues)) {
        notification.warning({ message: "Изменений нет" });
      } else {
        const newModel = {
          ...model,
          name: values.name,
          imgRef: values.imgRef,
          imgWidth: values.imgWidth,
          imgHeight: values.imgHeight,
        };
        store.execCmd(new CmdModelChange(store, newModel));
      }
      store.setState("view");
    };

    return (
      <Modal
        title="Свойства модели"
        open={open}
        footer={null}
        onCancel={onCancel}
        destroyOnClose
      >
        <Form<EditableFields>
          layout="vertical"
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <Form.Item name="name" label="Название" rules={[{ required: true }]}>
            <Input ref={ref1} allowClear />
          </Form.Item>
          <Form.Item
            name="imgRef"
            label="Ссылка на изображение"
            rules={[{ required: true }]}
          >
            <Input allowClear />
          </Form.Item>
          <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
            <Form.Item
              name="imgWidth"
              label="Ширина"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="imgHeight"
              label="Высота"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </div>
          <Space>
            <Button type="primary" htmlType="submit">
              Применить
            </Button>
            <Button htmlType="button" onClick={onCancel}>
              Отмена
            </Button>
          </Space>
        </Form>
      </Modal>
    );
  },
);
