import { z } from "zod";
import { zOverflow } from "./viewFormItems/Overflow";
import { zSeparator } from "./viewFormItems/Separator";

export const zAttrViewAppearance = z.object({
  column: z
    .object({
      group: z.object({
        separator: zSeparator.optional().nullable(),
        name: z.string().optional().nullable(),
      }),
    })
    .optional()
    .nullable(),
  view: z
    .object({
      separator: zSeparator.optional().nullable(),
      overflow: zOverflow.optional().nullable(),
    })
    .optional()
    .nullable(),
});
export type ZAttrViewAppearance = z.infer<typeof zAttrViewAppearance>;
export type AttrViewAppearanceNames = keyof ZAttrViewAppearance;
