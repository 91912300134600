// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__table--KgasN {
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__theader--nuT1b {
  display: grid;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__tbody--ZY0aT {
  display: grid;
  grid-auto-rows: auto;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__firstCol--ILAlf {
  border-left: thin solid rgba(0, 0, 0, 0.06);
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__th--cd19T {
  border-top: thin solid rgba(0, 0, 0, 0.06);
  border-right: thin solid rgba(0, 0, 0, 0.06);
  background-color: #FAFAFA;
  padding: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__td--P_9x2 {
  border-right: thin solid rgba(0, 0, 0, 0.06);
  border-top: thin solid rgba(0, 0, 0, 0.06);
  display: flex;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__td--P_9x2.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__fit--D2bLT > * {
  width: 100%;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__divider--dxxTQ {
  border-bottom: thin solid rgba(0, 0, 0, 0.06);
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SamplePoints/SamplesTable/SamplesTable.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACA;EACI,aAAA;AACJ;AACA;EACI,aAAA;EACA,oBAAA;AACJ;AAGA;EACI,2CAAA;AADJ;AAGA;EACI,0CAAA;EACA,4CAAA;EAEA,yBAAA;EACA,aAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAKA;EACI,4CAAA;EACA,0CAAA;EACA,aAAA;AAHJ;AAII;EACI,WAAA;AAFR;AAKA;EACI,6CAAA;AAHJ","sourcesContent":[".table {\n    display: flex;\n    flex-direction: column;\n}\n.theader {\n    display: grid;\n}\n.tbody {\n    display: grid;\n    grid-auto-rows: auto;\n}\n\n@border: thin solid rgba(0, 0, 0, 0.06);\n.firstCol {\n    border-left: @border;\n}\n.th {\n    border-top: @border;\n    border-right: @border;\n    // border-bottom: @border;\n    background-color: #FAFAFA;\n    padding: 12px;\n\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 160%; \n}\n.td {\n    border-right: @border;\n    border-top: @border;\n    display: flex;\n    &.fit > * {\n        width: 100%;\n    }\n}\n.divider {\n    border-bottom: @border;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__table--KgasN`,
	"theader": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__theader--nuT1b`,
	"tbody": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__tbody--ZY0aT`,
	"firstCol": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__firstCol--ILAlf`,
	"th": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__th--cd19T`,
	"td": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__td--P_9x2`,
	"fit": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__fit--D2bLT`,
	"divider": `src-businessServices-services-bsSample-SampleControl-SamplePoints-SamplesTable-SamplesTable-module__divider--dxxTQ`
};
export default ___CSS_LOADER_EXPORT___;
