import { rcUrl } from "../apiChat";
import { restChat } from "../restChat";
import { ZRCMessage, zRCMessageResp } from "./rocketChat.types";

/**
 * Schema defenition:
 * https://developer.rocket.chat/apidocs/get-channel-messages
 */
export type RCMessageLoaderParams = {
  count: number;
  offset: number;
};

export const rcMessageLoader = async (
  roomId: string,
  params: RCMessageLoaderParams,
): Promise<ZRCMessage[]> => {
  const resp = await restChat.get(rcUrl(`/api/v1/channels.messages`), {
    params: { ...params, roomId },
  });
  const { messages } = zRCMessageResp.parse(resp.data);
  return messages;
};
