import * as React from "react";
import { Radio, Spin } from "antd";
import { onError } from "src/common/onError";
import { IdLabel, getIdLabels } from "src/references/getIdNames";
import { ZIdName } from "src/types/ZIdName";
import { ZOption } from "src/types/ZOption";

/* eslint react/require-default-props: "off" */

interface PropsSelectGroupType {
  value?: ZIdName;
  onChange?(newValue?: ZIdName): void;
  disabled?: boolean;
}

export const SelectGroupType: React.FC<PropsSelectGroupType> = ({
  value,
  onChange,
  disabled,
}) => {
  const [wait, setWait] = React.useState(false);
  const [options, setOptions] = React.useState<ZOption[]>([]);
  // string id => name
  const [dict, setDict] = React.useState<Record<string, string>>({});
  const onLoad = async () => {
    try {
      setWait(true);
      const list: IdLabel[] = await getIdLabels("groupType", "groupType");
      setOptions(list.map(({ id, label }) => ({ value: String(id), label })));
      const newDict: Record<string, string> = {};
      list.forEach(({ id, name }) => {
        newDict[String(id)] = name;
      });
      setDict(newDict);
    } catch (e) {
      onError(e);
    } finally {
      setWait(false);
    }
  };
  React.useEffect(() => {
    onLoad();
  }, []);
  return (
    <Spin spinning={wait}>
      <Radio.Group
        optionType="button"
        options={options}
        value={value?.id?.toString()}
        disabled={disabled}
        onChange={(e) => {
          onChange?.(makeIdName(e.target.value, dict));
        }}
      />
    </Spin>
  );
};

SelectGroupType.defaultProps = {
  value: undefined,
  onChange: undefined,
};

const makeIdName = (
  curValue: unknown,
  dict: Record<string, string>,
): ZIdName | undefined =>
  typeof curValue === "string"
    ? { id: +curValue, name: dict[curValue] || curValue }
    : undefined;
