import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { PageLayout } from "src/components/PageLayout";
import { LoaderBox } from "src/components/LoaderBox";
import { AnchorItem } from "src/common/anchorItem";
import { userAccountPageStore as store } from "./userAccountPageStore";
import { EntityAnchor } from "../EntityCardPage/EntityFormWithAnchor/EntityAnchor";
import { EntityGroupInnerBlock } from "../EntityCardPage/EntityGroupBlock";
import { PasswordChangeBlock } from "./PasswordChangeBlock";
import styles from "./UserAccountPage.module.less";
import { UserEdit } from "./UserEdit";

export const UserAccountPage: React.FC = observer(() => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <PageLayout breadcrumb={[]} pageTitle={t("UserAccount")}>
      <LoaderBox remoteData={store.data} drawReady={() => <Inner />} />
    </PageLayout>
  );
});

const idFormsList = "FormsList";

const anchors = [
  // href должен быть = # + key, иначе не будет работать навигация
  { key: "form1", href: "#form1", title: t("General information") },
  { key: "form2", href: "#form2", title: t("Change password") },
] satisfies [AnchorItem, AnchorItem];

const Inner: React.FC = observer(() => (
  <div className={styles.pageContent}>
    <div className={styles.anchorsBlock}>
      <EntityAnchor anchors={anchors} containerSelector={`#${idFormsList}`} />
    </div>
    <div className={styles.formsList} id={idFormsList}>
      <EntityGroupInnerBlock
        title={anchors[0].title}
        id={anchors[0].key}
        monoColumn
      >
        <UserEdit store={store} />
      </EntityGroupInnerBlock>
      <EntityGroupInnerBlock
        title={anchors[1].title}
        id={anchors[1].key}
        monoColumn
      >
        <PasswordChangeBlock store={store} />
      </EntityGroupInnerBlock>
    </div>
  </div>
));
