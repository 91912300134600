import { z } from "zod";
import { ZAttrViewInfo } from "../zAttrViewInfo";

export const zFileList = z.object({
  view: z.literal("FileList"),
});
export type ZFileList = z.infer<typeof zFileList>;

export const makeFileListProps = (
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
) => ({
  values,
  viewInfo,
});
