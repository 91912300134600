import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Alert,
  AlertProps,
  Form,
  Modal,
  Spin,
  FormInstance,
  Button,
  notification,
} from "antd";
import { getErrorMessage } from "src/common/onError";
import { t } from "i18next";
import { FieldsWithTools } from "src/pages/ManagementPage/FieldsWithTools";
import { FormStatus, ModelessFormStore } from "./ModelessFormStore";
import styles from "./ModelessForm.module.less";

interface PropsModelessForm {
  store: ModelessFormStore;
  children?: React.ReactNode;
  formInstance?: FormInstance;
  submit?: string;
  name?: string;
  extraButtons?: React.ReactNode;
}

export const ModelessForm: React.FC<PropsModelessForm> = observer(
  (props: PropsModelessForm) => {
    const { store, children, formInstance, submit, name, extraButtons } = props;
    const form: FormInstance = formInstance ?? Form.useForm()[0];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [vals, setVals] = React.useState("");

    React.useEffect(() => {
      store.initForm(store.formStatus === "new" ? "new" : "ok");
      form.resetFields();
      form.setFieldsValue(store.initialValues);
    }, [store.initialValues]);

    let msg: React.ReactNode = statusMsg[store.formStatus];
    if (store.formStatus === "error" && store.formError) {
      const errMsg = getErrorMessage(store.formError);
      msg = errMsg.description || errMsg.message;
    }

    if (store.initialValues === null) return null;

    const validSubmitText = submit === undefined ? t("Submit") : submit;
    const submitButton = validSubmitText ? (
      <Button
        type="primary"
        disabled={!store.canSubmit}
        loading={store.formStatus === "saving"}
        onClick={() => form.submit()}
      >
        {submit}
      </Button>
    ) : null;
    return (
      <>
        {!submit && (
          <div className={styles.status}>
            {store.isFormBusy && <Spin size="small" />}
            <Alert type={statusDict[store.formStatus]} message={msg} showIcon />
          </div>
        )}
        <FieldsWithTools
          tools={
            <>
              {extraButtons}
              {submitButton}
            </>
          }
        >
          <Form
            layout="vertical"
            name={name}
            form={form}
            onValuesChange={(_, values) => {
              if (submit) {
                store.setFormStatus("changed");
              } else {
                store.tryToSave(values, form);
              }
              setVals(JSON.stringify(values, null, "  "));
            }}
            onFinish={(values) => store.submit(values)}
            onFinishFailed={({ errorFields }) => {
              const err = errorFields[0];
              if (err) {
                const message = err.errors[0];
                if (message) {
                  notification.error({
                    message,
                    description: err.name.join(" > "),
                  });
                }
              }
            }}
          >
            <div className={styles.formContent}>{children}</div>
          </Form>
          <ConfirmationTask store={store} />
        </FieldsWithTools>
      </>
    );
  },
);

const statusDict: Record<FormStatus, AlertProps["type"]> = {
  ok: "success",
  validating: "warning",
  invalid: "warning",
  saving: "warning",
  error: "error",
  new: "info",
  changed: "info",
};
const statusMsg: Record<FormStatus, string> = {
  ok: "Данные сохранены",
  validating: "Проверка...",
  invalid: "Данные заполнены некорректно",
  saving: "Сохранение...",
  error: "Ошибка при сохранении",
  new: "Новые данные",
  changed: "Внесены изменения",
};

interface PropsConfirmationTask {
  store: ModelessFormStore;
  text?: string;
}

const ConfirmationTask: React.FC<PropsConfirmationTask> = observer(
  ({ store, text }: PropsConfirmationTask) => {
    React.useEffect(() => {
      if (store.formStatus === "ok") store.confirmResolver?.resolve();
    }, [store.formStatus]);

    React.useEffect(() => {
      store.setUseExternalConfirm(true);
      return () => {
        store.setUseExternalConfirm(false);
      };
    }, []);
    if (!store.confirmResolver) return null;
    return (
      <Modal
        open={!!store.confirmResolver}
        onOk={() => store.confirmResolver?.resolve()}
        onCancel={() => store.confirmResolver?.reject()}
        okText="Продолжить"
        okButtonProps={{ danger: true }}
      >
        <div>
          {text ||
            "Форма содержит несохранённые данные. Продолжить с потерей данных?"}
        </div>
      </Modal>
    );
  },
);
