import { ZAttribute } from "src/types/ZAttribute";

export const fieldName = (attr: ZAttribute): string => String(attr.id);

export const fieldLabel = (attr: ZAttribute): string => attr.name;

export const fieldProps = (
  attr: ZAttribute,
): {
  name: string;
  label: string;
} => ({
  name: fieldName(attr),
  label: fieldLabel(attr),
});
