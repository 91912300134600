import { Dayjs } from "dayjs";
/* eslint no-plusplus: "off" */

export const minDate = (a: Dayjs, b: Dayjs | undefined): Dayjs => {
  if (!b) return a;
  return a.isBefore(b) ? a : b;
};

export const maxDate = (a: Dayjs, b: Dayjs | undefined): Dayjs => {
  if (!b) return a;
  return a.isBefore(b) ? b : a;
};

export const stdDateView = (date: Dayjs | undefined) =>
  date ? date.format("DD.MM.YYYY") : "";

export const minDateOpt = (
  a: Dayjs | undefined,
  b: Dayjs | undefined,
): Dayjs | undefined => (a ? minDate(a, b) : b);

export const maxDateOpt = (
  a: Dayjs | undefined,
  b: Dayjs | undefined,
): Dayjs | undefined => (a ? maxDate(a, b) : b);

export interface DayInfo {
  date: Dayjs;
  iso: string;
  holiday?: boolean;
}

export interface MonthInfo {
  index: number;
  year: number;
  key: string;
  name: string;
  daysCount: number;
}

export const buildMonths = (days: DayInfo[]): MonthInfo[] => {
  let curMonth: MonthInfo | undefined;
  const months: MonthInfo[] = [];
  days.forEach(({ date }) => {
    const jd = date.toDate();
    const index = jd.getMonth();
    const year = jd.getFullYear();
    if (!curMonth || curMonth.index !== index) {
      curMonth = {
        key: `${index}-${year}`,
        index,
        name: monthName[index] ?? "?",
        year,
        daysCount: 0,
      };
      months.push(curMonth);
    }
    curMonth!.daysCount++;
  });
  return months;
};

const monthName = [
  "Янв",
  "Фев",
  "Мар",
  "Апр",
  "Май",
  "Июнь",
  "Июль",
  "Авг",
  "Сен",
  "Окт",
  "Ноя",
  "Дек",
];
