// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ModelConstructorPage-ModelEditor-MEdToolbar-MEdToolbar-module__toolbar--nhlff {
  display: flex;
  flex-direction: row;
  padding: 0.3em;
  gap: 0.3em;
  background-color: #CCC;
}
.src-pages-ModelConstructorPage-ModelEditor-MEdToolbar-MEdToolbar-module__divider--zEgAS {
  border-right: solid thin #AAA;
  display: block;
  margin: 0 4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ModelConstructorPage/ModelEditor/MEdToolbar/MEdToolbar.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,sBAAA;AACJ;AACA;EACI,6BAAA;EACA,cAAA;EACA,aAAA;AACJ","sourcesContent":[".toolbar {\n    display: flex;\n    flex-direction: row;\n    padding: 0.3em;\n    gap: 0.3em;\n    background-color: #CCC;\n}\n.divider {\n    border-right: solid thin #AAA;\n    display: block;\n    margin: 0 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": `src-pages-ModelConstructorPage-ModelEditor-MEdToolbar-MEdToolbar-module__toolbar--nhlff`,
	"divider": `src-pages-ModelConstructorPage-ModelEditor-MEdToolbar-MEdToolbar-module__divider--zEgAS`
};
export default ___CSS_LOADER_EXPORT___;
