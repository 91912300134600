import { apiConfigUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { ZDashboardGroup, zDashboardGroup } from "./DasboardTypes";

const pathDashboard = "/dashboards";

export const loadDashboards = async (): Promise<ZDashboardGroup[]> => {
  const resp = await rest.get(apiConfigUrl(pathDashboard));
  return zDashboardGroup.array().parse(resp.data);
};

export const saveDashboards = async (list: ZDashboardGroup[]) => {
  await rest.post(apiConfigUrl(pathDashboard), list);
};
