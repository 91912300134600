import { NamePath } from "rc-field-form/lib/interface";
import { ExtendedName } from "../FormWithBlocks.types";

export const packName = (fullName: ExtendedName): string => fullName.join(".");

export const packNamePath = (namePath: NamePath): string =>
  Array.isArray(namePath) ? namePath.join(".") : String(namePath);

export const unpackName = (keyName: string): ExtendedName =>
  keyName.split(".").map((piece) => (/^\d+$/.test(piece) ? +piece : piece));
