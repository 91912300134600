import { z } from "zod";
import { onError } from "../onError";

// К сожалению, это оказался неудачный эксперимент - создавать подчинённые экземпляры в другой вкладке браузера

const zInitialEntityParams = z.record(z.string(), z.string().array());
// type ZInitialEntityParams = z.infer<typeof zInitialEntityParams>;

const queryKey = "initial";

// export const makeInintialEntityParams = (
//   url: string,
//   params: ZInitialEntityParams,
// ) => {
//   try {
//     const paramsPart = `${queryKey}=${encodeURIComponent(JSON.stringify(params))}`;
//     const hasParams = url.includes("?");
//     return `${url}${hasParams ? "&" : "?"}${paramsPart}`;
//   } catch (error) {
//     onError(error);
//     return url;
//   }
// };

/**
 * @deprecated Решено отказаться от создания подчиненных экземпляров в соседней вкладке браузера. Теперь это происходит на той же странице при помощи модалок
 */
export const parseInitialEntityParams = (params: URLSearchParams) => {
  try {
    const initial = zInitialEntityParams.parse(
      JSON.parse(decodeURIComponent(params.get(queryKey) || "{}")),
    );
    return initial;
  } catch (error) {
    onError(error);
    return {};
  }
};
