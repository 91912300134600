import {
  parseFormulaAtts,
  transformFormula4Save,
} from "src/common/attrEdit/components";
import { EdAttribute } from "../EdAttribute";
import { loadFormulaArguments } from "../forms/AttrForm2/MetaInfo/FormulaTemplateInput/loadFormulaArguments";

export const transformEdAttVals4Save = async (
  values: EdAttribute,
  objectId: number,
) => {
  const newVals = values;
  switch (newVals.editorInfo?.component?.editor) {
    case "Formula": {
      const argInfo = await loadFormulaArguments(objectId);
      newVals.editorInfo.component.formula = await transformFormula4Save(
        newVals.editorInfo.component.formula,
        argInfo.availableAttributes,
      );
      newVals.editorInfo.component.argumentAtts = parseFormulaAtts(
        newVals.editorInfo.component.formula,
        argInfo,
      );
      break;
    }
    default:
      break;
  }
  return newVals;
};
