import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { appStore, OverlayPage } from "src/appStore";
import { Alert, Button, Space, Spin } from "antd";
import { getErrorMessage, onError } from "src/common/onError";
import { SyncOutlined } from "@ant-design/icons";
import { rest } from "src/common/rest";
import { RegisterPage } from "src/pages/RegisterPage";
import { getCurrentRegisterParam } from "src/pages/RegisterPage/registerUrl";
import { LoginPage } from "./overlays/LoginPage";
import styles from "./AuthChecker.module.less";
import { PassRestorePage } from "./overlays/PassRestorePage";

interface PropsAuthChecker {
  children?: React.ReactNode;
}

const overlaysMap: Record<OverlayPage, React.ReactElement> = {
  login: <LoginPage />,
  passRestore: <PassRestorePage />,
};

export const AuthChecker: React.FC<PropsAuthChecker> = observer(
  ({ children }: PropsAuthChecker) => {
    // Костыль для регистрации в неавторизованном режиме
    const param = getCurrentRegisterParam();
    if (param) {
      return <RegisterPage param={param} />;
    }

    const { overlayType } = appStore;
    if (overlayType) {
      return (
        overlaysMap[overlayType] ?? (
          <div>
            Unknown overlay <b>{appStore.overlayType}</b>
          </div>
        )
      );
    }
    const { status } = appStore.userData;
    if (status === "error") {
      const params = getErrorMessage(appStore.userData.error);
      return (
        <div className={styles.vertBlock}>
          <h1>{t("Error when accessing the server")}</h1>
          <Alert type="error" showIcon {...params} />
          <Space>
            <Button
              type="primary"
              onClick={() => appStore.init()}
              icon={<SyncOutlined />}
            >
              {t("Repeat")}
            </Button>
            <Button
              onClick={() => rest.get("/api/agreements/search").catch(onError)}
            >
              Тестовый Запрос
            </Button>
          </Space>
        </div>
      );
    }
    if (status === "ready") {
      return children;
    }
    if (status === "wait") {
      return (
        <div className={styles.wait}>
          <Spin />
          <span>Загрузка информации пользователя...</span>
        </div>
      );
    }
    return <div>Invalid status: {status}</div>;
  },
);

AuthChecker.defaultProps = {
  children: null,
};
