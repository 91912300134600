import { z } from "zod";
import { ZAttribute } from "src/types/ZAttribute";
import { AttrLabelBuilderCtx } from "src/common/attributes/composeAttrLabel2";
import { ZAttrEditInfo } from "src/common/attrEdit";
import { ZAttrViewInfo } from "..";

export const zChildEntitiesCellInfo = z.object({
  view: z.literal("ChildEntitiesCellInfo"),
  labelAtts: z.string().array(),
});
export type ZChildEntitiesCellInfo = z.infer<typeof zChildEntitiesCellInfo>;
export const makeChildEntitiesCellInfoProps = (
  attribute: ZAttribute,
  viewInfo: ZAttrViewInfo | undefined,
  editorInfo: ZAttrEditInfo | undefined,
  ctx?: AttrLabelBuilderCtx,
) => ({ attribute, viewInfo, editorInfo, ctx });
export const makeChildEntitiesCellInfoProps2 = (
  attribute: ZAttribute,
  ctx?: AttrLabelBuilderCtx,
) => ({ attribute, ctx });
