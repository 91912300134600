import { makeAutoObservable } from "mobx";
import { notification } from "antd";
import { ZModel, ZModelAttr } from "../ZModel";
import { DrawProps } from "./DrawProps";
import { Point } from "../math";
import { Command } from "./commands/Command";
import { CmdMovePoint } from "./commands/CmdMovePoint";
import { CmdAttrInsert } from "./commands/CmdAttrInsert";

type MEdState =
  | "clone"
  | "editAttr"
  | "editModel"
  | "movePoint"
  | "newAttr"
  | "newPoint1"
  | "newPoint2"
  | "view";

export enum PointIndex {
  begin,
  end,
  middle,
}

export class ModelEditorStore {
  constructor() {
    this.drawProps = {} as DrawProps;
    makeAutoObservable(this);
  }

  zoom = 1;

  setZoom(newValue: number) {
    this.zoom = newValue;
  }

  zoomIn() {
    this.setZoom(this.zoom * 1.2);
  }

  zoomOut() {
    this.setZoom(this.zoom * 0.8);
  }

  state: MEdState = "view";

  setState(st: MEdState) {
    this.state = st;
  }

  get isClickWait(): boolean {
    // Если ожидается клик в рабочей области для создания точки
    return this.state === "newPoint1" || this.state === "newPoint2";
  }

  cvtPageToWorld(pageCoord: Point): Point | undefined {
    const parent = document.getElementById("MECanvas");
    const scroller = document.getElementById("MEScroller");
    if (!parent || !scroller) return undefined;
    const x =
      (pageCoord.x - parent.offsetLeft + scroller.scrollLeft) / this.zoom;
    const y = (pageCoord.y - parent.offsetTop + scroller.scrollTop) / this.zoom;
    return new Point(x, y);
  }

  curAttrIndex = -1;

  setCurAttrIndex(index: number) {
    this.curAttrIndex = index;
  }

  get curAttr(): ZModelAttr | null | undefined {
    return this.model?.attrs[this.curAttrIndex];
  }

  model: ZModel | null = null;

  setModel(m: ZModel | null) {
    this.model = m;
  }

  init(model: ZModel | null) {
    // сделать полную копию исходного объекта
    this.model = JSON.parse(JSON.stringify(model));
    this.setZoom(1);
    if (model) {
      // графические настройки
      const worldUnit = 3;
      this.setDrawProps({
        arrowL: 8 * worldUnit,
        arrowD: 3 * worldUnit,
      });
    }
    this.undoList.length = 0;
    this.redoList.length = 0;
    this.setPoint2(null);
    this.setPoint2(null);
    this.setCurAttrIndex(0);
    this.setState("view");
  }

  get canZoom(): boolean {
    return !!this.model;
  }

  get canEditModel(): boolean {
    return !!this.model && this.state === "view";
  }

  // Добавление атрибута
  get canAddAttr(): boolean {
    return !!this.model && this.state === "view";
  }

  onAddAttr() {
    this.setCurAttrIndex(-1);
    this.setState("newPoint1");
  }

  point1: Point | null = null;

  setPoint1(p: Point | null) {
    this.point1 = p;
  }

  point2: Point | null = null;

  setPoint2(p: Point | null) {
    this.point2 = p;
  }

  createNewAttr(attrName: string, isCurve: boolean) {
    const { point1, point2, model } = this;
    if (!point1 || !point2 || !model) return;
    const attr: ZModelAttr = {
      attrName,
      key: generateAttrKey(),
      xA: point1.x,
      yA: point1.y,
      xB: point2.x,
      yB: point2.y,
    };
    if (isCurve) {
      attr.xC = (point1.x + point2.x) / 2;
      attr.yC = (point1.y + point2.y) / 2;
    }
    const newIndex = model.attrs.length;
    model.attrs.push(attr);
    this.setCurAttrIndex(newIndex);
    this.setPoint1(null);
    this.setPoint2(null);
    this.setState("view");
    this.execCmd(new CmdAttrInsert(this, attr, newIndex, true));
  }

  get canEditAttr(): boolean {
    return !!this.curAttr && this.state === "view";
  }

  startEditAttr() {
    if (this.curAttr) this.setState("editAttr");
  }

  get canDeleteAttr(): boolean {
    return !!this.curAttr && this.state === "view";
  }

  drawProps: DrawProps;

  setDrawProps(props: DrawProps) {
    this.drawProps = props;
  }

  curPointIndex: PointIndex = PointIndex.begin;

  setCurPointIndex(i: PointIndex) {
    this.curPointIndex = i;
  }

  curPoint: Point | null = null;

  setCurPoint(p: Point | null) {
    this.curPoint = p;
  }

  updatePoint(attrIndex: number, pointIndex: PointIndex, wPos: Point) {
    const attr = this.model?.attrs[attrIndex];
    if (!attr) return;
    switch (pointIndex) {
      case PointIndex.begin:
        attr.xA = wPos.x;
        attr.yA = wPos.y;
        break;
      case PointIndex.end:
        attr.xB = wPos.x;
        attr.yB = wPos.y;
        break;
      case PointIndex.middle:
        attr.xC = wPos.x;
        attr.yC = wPos.y;
        break;
      default:
        break;
    }
  }

  getPoint(attrIndex: number, pointIndex: PointIndex): Point | null {
    const a = this.model?.attrs[attrIndex];
    if (!a) return null;
    switch (pointIndex) {
      case PointIndex.begin:
        return new Point(a.xA, a.yA);
      case PointIndex.end:
        return new Point(a.xB, a.yB);
      case PointIndex.middle:
        if (a.xC !== undefined && a.yC !== undefined)
          return new Point(a.xC, a.yC);
        break;
      default:
        break;
    }
    return null;
  }

  moveAttr(attrIndex: number, deltaPos: Point) {
    const attr = this.model?.attrs[attrIndex];
    if (attr) {
      attr.xA += deltaPos.x;
      attr.yA += deltaPos.y;
      attr.xB += deltaPos.x;
      attr.yB += deltaPos.y;
      if (attr.xC !== undefined) attr.xC += deltaPos.x;
      if (attr.yC !== undefined) attr.yC += deltaPos.y;
    }
  }

  onMouseMove(pagePos: Point) {
    if (this.state === "clone") {
      const { point1 } = this;
      const newPos = this.cvtPageToWorld(pagePos);
      const attr = this.model?.attrs.at(-1);
      if (point1 && newPos && attr) {
        const i = this.model!.attrs.length - 1;
        const delta = newPos.minus(point1);
        this.setPoint1(newPos);
        this.moveAttr(i, delta);
      }
    } else if (this.state === "movePoint" && this.curAttr) {
      const wp = this.cvtPageToWorld(pagePos);
      if (wp) {
        this.updatePoint(this.curAttrIndex, this.curPointIndex, wp);
      }
    }
  }

  onMouseUp(pagePos: Point) {
    if (this.state === "movePoint") {
      this.setState("view");
      const begin = this.curPoint;
      const end = this.getPoint(this.curAttrIndex, this.curPointIndex);
      if (begin && end) {
        this.execCmd(
          new CmdMovePoint(
            this,
            this.curAttrIndex,
            this.curPointIndex,
            begin,
            end,
            true,
          ),
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn("Invalid end position");
      }
    } else if (this.state === "newPoint1") {
      const wp = this.cvtPageToWorld(pagePos);
      if (wp) {
        this.setPoint1(wp);
        this.setState("newPoint2");
      }
    } else if (this.state === "newPoint2") {
      const wp = this.cvtPageToWorld(pagePos);
      if (wp) {
        this.setPoint2(wp);
        this.setState("newAttr");
      }
    } else if (this.state === "clone") {
      const attrs = this.model?.attrs;
      if (attrs) {
        const i = attrs.length - 1;
        const newAttr = attrs[i];
        if (newAttr) {
          this.execCmd(new CmdAttrInsert(this, newAttr, i, true));
          this.setCurAttrIndex(i);
        }
      }
      this.setState("view");
      this.setPoint1(null);
    }
  }

  onKey(code: string) {
    if (code === "Escape") {
      if (this.state === "newPoint1") {
        this.setState("view");
      } else if (this.state === "newPoint2") {
        this.setState("newPoint1");
        this.setPoint1(null);
      } else if (this.state === "clone") {
        this.model?.attrs.pop();
        this.setState("view");
      }
    } else if (code === "F2" && this.canAddAttr) {
      notification.info({ message: "Создание нового атрибута" });
      this.setPoint1(null);
      this.setState("newPoint1");
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startMovePoint(pointIndex: number, _pagePos: Point) {
    this.setCurPointIndex(pointIndex);
    this.setState("movePoint");
    this.setCurPoint(this.getPoint(this.curAttrIndex, pointIndex));
  }

  startClone(attrIndex: number, pagePos: Point) {
    const srcAttr = this.model?.attrs[attrIndex];
    const wp = this.cvtPageToWorld(pagePos);
    const { model } = this;
    if (!srcAttr || !wp || !model) return;
    const dstAttr = { ...srcAttr, key: generateAttrKey() };
    this.setPoint1(wp);
    this.setState("clone");
    model.attrs.push(dstAttr);
    // TODO: пока просто добавить, без перемещения
    // this.execCmd(new CmdAttrInsert(this, dstAttr, this.model!.attrs.length, false));
  }

  /// //////////////
  // commands
  execCmd(cmd: Command) {
    const undo = cmd.createUndo();
    this.undoList.unshift(undo);
    this.redoList.length = 0;
    cmd.exec();
  }

  undoList: Command[] = [];

  redoList: Command[] = [];

  get undoTitle(): string | undefined {
    return this.undoList[0]?.title;
  }

  get redoTitle(): string | undefined {
    return this.redoList[0]?.title;
  }

  get canUndo(): boolean {
    return !!this.undoTitle && this.state === "view";
  }

  get canRedo(): boolean {
    return !!this.redoTitle && this.state === "view";
  }

  execUndo() {
    const cmd = this.undoList.shift();
    if (!cmd) return;
    const redo = cmd.createUndo();
    this.redoList.unshift(redo);
    cmd.exec();
  }

  execRedo() {
    const cmd = this.redoList.shift();
    if (!cmd) return;
    this.undoList.unshift(cmd.createUndo());
    cmd.exec();
  }

  get statusMessage(): string {
    switch (this.state) {
      case "newPoint1":
        return "Кликните на начало линии или нажмите Esc";
      case "newPoint2":
        return "Кликните на конец линии или нажмите Esc";
      case "clone":
        return "Двигайте мышь для перемещения или Esc для отмены";
      default:
        break;
    }
    if (Math.abs(this.zoom - 1) > 0.01) {
      return `Масштаб: ${(this.zoom * 100).toFixed(0)}%`;
    }
    return "";
  }
}

let glbAttrKey = 0;
// eslint-disable-next-line no-plusplus
const generateAttrKey = (): number => ++glbAttrKey;
