import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { MCObjType } from "../MChartSettingsStore";
import { ZMesChartSettings } from "../../ZMesChartSettings";

interface PropsPointValueSection {
  store: FormObjectSectionsStore<MCObjType>;
}

export const PointValueSection: React.FC<PropsPointValueSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZMesChartSettings, MCObjType>
      store={store}
      objKey={["pointValueObjectId"]}
      objType="PointValue"
      objLabel="Point value object"
      title="Point value"
      attrs={[
        {
          attrKey: ["pointValueMcPointsAttrId"],
          attrLabel: "Point value Mc points",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["pointValueSizeAttrId"],
          attrLabel: "Point Value Size",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["pointValueVersionAttrId"],
          attrLabel: "Point Value Version",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: ["pointValueValueAttrId"],
          attrLabel: "Point Value Value",
          attrType: AttrTypeName.number,
        },
      ]}
    />
  ),
);
