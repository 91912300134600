import * as React from "react";
import { key2name } from "src/components/FormWithBlocks/items";
import {
  RenderResult,
  FormBlockItem,
  FormBlockItemExtParams,
  ItemProps,
  BlockContext,
} from "./FormWithBlocks.types";

/* eslint react/jsx-props-no-spreading: "off" */

export const blockItem = <Props extends object>(
  name: string,
  label: string,
  Comp: React.ComponentClass<Props> | React.FC<Props>,
  compProps: Props | ((ctx?: BlockContext) => Props),
  itemProps?: ItemProps,
  extParams?: FormBlockItemExtParams,
): FormBlockItem => ({
  ...extParams,
  key: name,
  render(ctx?: BlockContext): RenderResult {
    const nameInForm = key2name(name);
    const props: Props =
      typeof compProps === "function" ? compProps(ctx) : compProps;
    return <Comp {...props} name={nameInForm} />;
  },
  itemProps:
    typeof itemProps === "function"
      ? (ctx) => ({
          name,
          label,
          ...itemProps(ctx),
        })
      : {
          name,
          label,
          ...(itemProps ?? {}),
        },
});
