import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { SimpleKey } from "src/types/SimpleKey";
import { z } from "zod";

export const zTreeAttributeValue = z.object({
  ids: z.number().array(),
  attributeId: z.number(),
  value: z.string().nullable(),
});
export type ZTreeAttributeValue = z.infer<typeof zTreeAttributeValue>;

export const loadTreeAttrValues = async (
  attributeId: number,
  objectId: number,
  parentValueIds: SimpleKey[],
) => {
  const resp = await rest.post<ZTreeAttributeValue[]>(
    apiObjUrl("/values/search"),
    {
      objectId,
      attributeId,
      parentValueIds,
    },
  );
  return zTreeAttributeValue.array().parse(resp.data);
};
