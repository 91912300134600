import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Checkbox, Form, Input, InputRef } from "antd";
import { DeleteOutlined, SisternodeOutlined } from "@ant-design/icons";
import { RoleSelect } from "src/components/RoleSelect";
import { RolesTabStore } from "../RolesTabStore";
import { ObjRoleNode, newItemId } from "../nodes/RoleNode";
import { WidthLimitedFields } from "../../WidthLimitedFields";
import { ExtraTools } from "../../ExtraTools";

interface PropsRoleObjectField {
  store: RolesTabStore;
  node: ObjRoleNode;
}

export const RoleObjectField: React.FC<PropsRoleObjectField> = observer(
  ({ node, store }) => {
    const ref1 = React.createRef<InputRef>();
    React.useEffect(() => {
      setTimeout(() => ref1.current?.focus(), 10);
    }, [node.obj.id]);

    //   && !node.obj.permissions?.includes(Permission.objDelete)
    // но на момент разработки permissions=null, то есть неизвестно ничего о ролевой модели для данного случая
    return (
      <WidthLimitedFields>
        <Form.Item
          name="name"
          label="Название роли"
          rules={[{ required: true }]}
        >
          <Input ref={ref1} allowClear />
        </Form.Item>
        {store.isNewNode && (
          <>
            <Form.Item
              name="sourceRoleId"
              label="Роль, с которой копируются настроенные права"
            >
              <RoleSelect single roleGroups={store.roleGroups} />
            </Form.Item>
            <Form.Item name="isOnObjects" valuePropName="checked">
              <Checkbox>Тиражировать на права объектов для новой роли</Checkbox>
            </Form.Item>
            <Form.Item name="isOnAttributes" valuePropName="checked">
              <Checkbox>
                Тиражировать на права всех атрибутов объектов для новой роли
              </Checkbox>
            </Form.Item>
          </>
        )}
      </WidthLimitedFields>
    );
  },
);

export const RoleObjectButtons: React.FC<PropsRoleObjectField> = observer(
  ({ store, node }) => {
    const isNew = node.obj.id === newItemId;
    const canAddAttr = !isNew;
    const canDelete = true;
    return (
      <ExtraTools>
        <Button
          icon={<SisternodeOutlined />}
          onClick={() => store.safeAddAttr()}
          disabled={!canAddAttr}
          id="addAttrToRole"
        >
          Добавить атрибут
        </Button>
        <Button
          icon={<DeleteOutlined />}
          danger
          onClick={() => {
            store.safeDeleteCurNode();
          }}
          disabled={!canDelete}
        >
          Удалить роль
        </Button>
      </ExtraTools>
    );
  },
);
