import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Button } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { ScrollableTable } from "src/components/ScrollableTable";
import { TableStore } from "src/components/tables/TableStore";
import { AColumn } from "src/components/tables/AsyncTable";
import { viewDate } from "src/common/formats";
import { confirmDelete } from "src/components/ModalDelete";
import { onError } from "src/common/onError";
import { SampleRequestModal } from "./SampleRequestModal";
import { EdSampleRequest, ZSampleRequest } from "../ZSampleRequest";
import { SampleControlStore } from "../SampleControlStore";
import styles from "./SampleSummary.module.less";

interface PropsSampleSummary {
  store: SampleControlStore;
  tableStore: TableStore<ZSampleRequest, {}>;
}

type EditInfo = {
  info?: ZSampleRequest;
};

export const SampleSummary: React.FC<PropsSampleSummary> = observer(
  ({ store, tableStore }) => {
    const [editInfo, setEditInfo] = React.useState<EditInfo | null>(null);
    const { buzy } = store;
    const columns: AColumn<ZSampleRequest>[] = [
      {
        key: "nameRequest",
        title: "Request name",
        dataIndex: "nameRequest",
      },
      {
        key: "numberRequest",
        title: "Request number",
        dataIndex: "numberRequest",
      },
      {
        key: "color",
        title: "Color",
        render: (_, { color }) => color?.article,
      },
      {
        key: "supplier",
        title: "Supplier",
        render: (_, { supplier }) => supplier?.name,
      },
      {
        key: "sendDate",
        title: "Send date",
        render: (_, { sendDate }) => viewDate(sendDate),
      },
      {
        key: "receiveDate",
        title: "Receive date",
        render: (_, { receiveDate }) => viewDate(receiveDate),
      },
      {
        key: "requestType",
        title: "Request type",
        render: (_, { typeRequest }) => typeRequest?.value,
      },
      {
        key: "state",
        title: "State",
        render: (_, { state }) => state?.value,
      },
      {
        key: "comment",
        title: "Comment",
        dataIndex: "comment",
      },
      {
        key: "edit",
        title: " ",
        width: "64px",
        render: (_, info) => (
          <Button
            icon={<EditOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              setEditInfo({ info });
            }}
          />
        ),
      },
    ];
    const onDelete = () => {
      const { selected } = tableStore;
      if (selected.length > 0) {
        confirmDelete(t("Are you sure to delete selected positions?"), () => {
          store.deleteRequests(selected.map(({ id }) => id)).catch(onError);
        });
      }
    };
    const { entityInfo } = store;
    if (!entityInfo) return null;
    // TODO: Как-то плохо работает скроллирование в ScrollableTable. Быстро пофиксить не получилось.
    // Проблема в том, что при увеличении высоты вроде норм, а при уменьшении уже не работает.
    return (
      <div className={styles.box}>
        <div className={styles.toolbar}>
          <div className={styles.leftTool} />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={onDelete}
            disabled={tableStore.selected.length === 0 || !!buzy}
            loading={buzy === "deleteRequest"}
          >
            {t("Delete")}
          </Button>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => setEditInfo({})}
            disabled={!!buzy}
          >
            {t("Create request")}
          </Button>
        </div>
        <div className={styles.table}>
          <ScrollableTable
            store={tableStore}
            columns={columns}
            onRowClick={(row) => {
              store.setCurTab(String(row.id));
            }}
          />
        </div>
        <SampleRequestModal
          open={!!editInfo}
          initialValues={editInfo?.info}
          close={() => setEditInfo(null)}
          modelObjectId={entityInfo.object.id}
          modelId={entityInfo.entity?.id ?? ""}
          submit={async (values: EdSampleRequest) => {
            await store.createOrUpdateRequest(editInfo?.info?.id, values);
          }}
        />
      </div>
    );
  },
);
