import { Button, Form, Tooltip } from "antd";
import React, { useState } from "react";
import { ExportOutlined } from "@ant-design/icons";
import { onError } from "src/common/onError";
import { saveCopyOnAtts } from "./saveCopyOnAtts";
import { edAttrField } from "../../EdAttribute";
import { ZPermissions } from "../roleTypes";

interface PropsCopyOnAttsButton {
  stateName: string;
  stateId: number;
  objectName: string;
  attributeIds: number[];
}

export const CopyOnAttsButton: React.FC<PropsCopyOnAttsButton> = ({
  stateName,
  stateId,
  objectName,
  attributeIds,
}) => {
  const curPermissonsItem: ZPermissions | undefined = Form.useWatch([
    edAttrField.rolesMap,
    String(stateId),
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  if (!attributeIds.length || !curPermissonsItem) return null;

  const copyOnAttributes = async () => {
    setLoading(true);
    return saveCopyOnAtts(stateId, attributeIds, curPermissonsItem)
      .catch(onError)
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip
      title={`Перенести на состояние ${stateName} для всех атрибутов объекта ${objectName}`}
    >
      <Button
        size="small"
        type="text"
        icon={<ExportOutlined />}
        loading={loading}
        onClick={(event) => {
          event.stopPropagation();
          copyOnAttributes();
        }}
      />
    </Tooltip>
  );
};
