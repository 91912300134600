import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import { Bom2Settings } from "./Bom2Settings/Bom2Settings";
import { Bom2Control } from "./Bom2Control";
import { loadBom2SettingsOpt, saveBom2Settings } from "./apiBom2";
import { zBom2Settings } from "./Bom2Types";

export const bsBom2: BusinessServiceDescriptor = {
  key: "BOM",
  viewer(info, data) {
    return <Bom2Control info={info} entityData={data} />;
  },
  renderSettings(_info, store) {
    return <Bom2Settings typesMap={store.attrTypesDict} />;
  },
  async loadSettings(info) {
    return loadBom2SettingsOpt(info.id);
  },
  async saveSettings(info, values) {
    const data = {
      ...(values as object),
      objectServiceId: info.id,
    };
    await saveBom2Settings(zBom2Settings.parse(data));
  },
};
