import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { FormObjectSectionsStore } from "src/components/Forms/FormObjectSection";

export type TechpackObjType = "model" | "supplier" | "techpack";

export const techpackSettingsStore = makeAutoObservable({
  async init(attrTypesDict: Record<number, string>, objectId: number) {
    try {
      await this.formSectionsStore.init(attrTypesDict);
      await this.formSectionsStore.loadAttrs("model", objectId);
    } catch (e) {
      onError(e);
    }
  },
  formSectionsStore: new FormObjectSectionsStore<TechpackObjType>(),
});

export type TechpackSettingsStore = typeof techpackSettingsStore;
