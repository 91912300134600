import * as React from "react";
import styles from "./SampleMcPoints.module.less";

interface PropsSMcPointTextCell {
  children: React.ReactNode;
  align?: "center" | "end" | "start";
}

export const SMcPointTextCell: React.FC<PropsSMcPointTextCell> = ({
  children,
  align,
}) => (
  <div className={styles.textCell} style={{ justifyContent: align }}>
    {children}
  </div>
);
