import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsTask, ZSchedulingSettings } from "../../SchedulingTypes";
import { TaskTiesSection } from "./sections";
import { SchedulingObjType } from "../SchedulingSettingsStore";

interface PropsTaskFields {
  store: FormObjectSectionsStore<SchedulingObjType>;
}

const fieldName = (
  name: keyof ZSchedSettingsTask,
): ["taskSettings", keyof ZSchedSettingsTask] => ["taskSettings", name];

export const TaskFields: React.FC<PropsTaskFields> = observer(({ store }) => (
  <DrawTab>
    <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
      store={store}
      objKey={fieldName("templateObjectId")}
      objType="task"
      objLabel="Объект для формирования шаблона задачи"
      title="Список основных позиций"
      attrs={[
        {
          attrKey: fieldName("nameAttributeId"),
          attrLabel: "Укажите атрибут названия задачи",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("durationAttributeId"),
          attrLabel: "Укажите атрибут длительности задачи",
          attrType: AttrTypeName.int,
        },
        {
          attrKey: fieldName("groupAttributeId"),
          attrLabel: "Укажите атрибут принадлежности задачи к группе задач",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: fieldName("modelLinkAttributeId"),
          attrLabel: "Укажите атрибут принадлежности задачи к товарной позиции",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: fieldName("relationLinkAttributeId"),
          attrLabel: "Укажите атрибут принадлежности задачи к привязкам задачи",
          attrType: AttrTypeName.childEntities,
        },
        {
          attrKey: fieldName("supplierRelationAttributeId"),
          attrLabel: "Укажите атрибут принадлежности задачи к поставщику",
          attrType: AttrTypeName.object,
          required: false,
        },
        {
          attrKey: fieldName("divisionLinkAttributeId"),
          attrLabel: "Укажите атрибут принадлежности к подразделению",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: fieldName("performerLinkAttributeId"),
          attrLabel: "Укажите атрибут исполнителя",
          attrType: AttrTypeName.object,
        },
      ]}
    />
    <TaskTiesSection objType="taskTies" tabKey="taskSettings" store={store} />
    <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
      store={store}
      objKey={fieldName("templateObjectId")}
      objType="task"
      objLabel="Объект для формирования сроков задачи"
      title="Сроки"
      isObjSelect={false}
      attrs={[
        {
          attrKey: fieldName("plannedStartDateAttributeId"),
          attrLabel: "Укажите атрибут плановой даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: fieldName("plannedEndDateAttributeId"),
          attrLabel: "Укажите атрибут плановой даты окончания задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: fieldName("settlementStartDateAttributeId"),
          attrLabel: "Укажите атрибут расчетной даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: fieldName("settlementEndDateAttributeId"),
          attrLabel: "Укажите атрибут расчетной даты окончания задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: fieldName("actualStartDateAttributeId"),
          attrLabel: "Укажите атрибут фактической даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: fieldName("actualEndDateAttributeId"),
          attrLabel: "Укажите атрибут фактической даты окончания задачи",
          attrType: AttrTypeName.date,
        },
      ]}
    />
    <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
      store={store}
      objKey={fieldName("supplierObjectId")}
      objType="supplier"
      objLabel="Объект для формирования поставщика"
      title="Поставщик"
      required={false}
      attrs={[
        {
          attrKey: fieldName("supplierNameAttributeId"),
          attrLabel: "Укажите названия поставщика",
          attrType: AttrTypeName.string,
          required: false,
        },
      ]}
    />
    <FormObjectSection<ZSchedulingSettings, SchedulingObjType>
      store={store}
      objKey={fieldName("performerObjectId")}
      objType="performer"
      objLabel="Объект для формирования исполнителя"
      title="Исполнитель"
      attrs={[
        {
          attrKey: fieldName("performerLoadAttributeId"),
          attrLabel: "Укажите атрибут загрузки исполнителя на задачу в день",
          attrType: AttrTypeName.int,
        },
      ]}
    />
  </DrawTab>
));
