// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ModelConstructorPage-ModelEditor-MEdHeader-MEdHeader-module__box--FR9a9 {
  padding: 0.3em 0.6em;
  background-color: #EEE;
  display: flex;
  flex-direction: row;
}
.src-pages-ModelConstructorPage-ModelEditor-MEdHeader-MEdHeader-module__name--D_p5f {
  font-weight: bold;
}
.src-pages-ModelConstructorPage-ModelEditor-MEdHeader-MEdHeader-module__msg--K6af5 {
  flex: 1;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ModelConstructorPage/ModelEditor/MEdHeader/MEdHeader.module.less"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACA;EACI,iBAAA;AACJ;AACA;EACI,OAAA;EACA,iBAAA;AACJ","sourcesContent":[".box {\n    padding: 0.3em 0.6em;\n    background-color: #EEE;\n    display: flex;\n    flex-direction: row;\n}\n.name {\n    font-weight: bold;\n}\n.msg {\n    flex: 1;\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-ModelConstructorPage-ModelEditor-MEdHeader-MEdHeader-module__box--FR9a9`,
	"name": `src-pages-ModelConstructorPage-ModelEditor-MEdHeader-MEdHeader-module__name--D_p5f`,
	"msg": `src-pages-ModelConstructorPage-ModelEditor-MEdHeader-MEdHeader-module__msg--K6af5`
};
export default ___CSS_LOADER_EXPORT___;
