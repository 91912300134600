import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { t } from "i18next";
import { AuthTaskResult } from "src/appStore";
import {
  getAuthRCToken,
  getAuthRCUserId,
  rcUrl,
  setRCAuth,
  zRCAuthResp,
} from "./apiChat";

let authWait: Promise<Response> | null = null;

export const authRCTask = async <R extends AuthTaskResult>(
  task: () => Promise<R>,
): Promise<R> => {
  let resp: R;
  try {
    resp = await task();
  } catch (e) {
    if ("response" in e) {
      resp = e.response;
      if (resp.status !== 401) throw e;
    } else {
      throw e;
    }
  }
  if (resp.status === 401) {
    authWait =
      authWait ||
      fetch(rcUrl("/api/v1/login"), {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ resume: getAuthRCToken() }),
      });
    const resp1 = await authWait;
    authWait = null;
    if (resp1.status !== 200) {
      const error = new Error(t("Authorization required"));
      throw error;
    }
    const resp1Data = await resp1.clone().json();
    const data = zRCAuthResp.parse(resp1Data);
    setRCAuth(data.data);
    resp = await task();
  }
  return resp;
};

const addRCAuthHeaders = (config?: AxiosRequestConfig): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config?.headers,
    "X-User-Id": getAuthRCUserId(),
    "X-Auth-Token": getAuthRCToken(),
  },
});

export const restChat = {
  get<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return authRCTask(() => axios.get(url, addRCAuthHeaders(config)));
  },
  post<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return authRCTask(() => axios.post(url, data, addRCAuthHeaders(config)));
  },
  put<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return authRCTask(() => axios.put(url, data, addRCAuthHeaders(config)));
  },
  delete<T = void, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return authRCTask(() => axios.delete(url, addRCAuthHeaders(config)));
  },
};
