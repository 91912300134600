// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__state--KZomF {
  font-weight: 700;
}
.src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__timestamp--k1Q2b,
.src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__fio--wRFlB {
  color: #0000008C;
}
.src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__fio--wRFlB {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__timelineChild--JlgXd {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__changeInfo--PZRwL {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityHistory/EntityStatesHistory/EntityStatesHistory.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;;EACE,gBAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAAF;AAGA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAFF","sourcesContent":[".state {\n  font-weight: 700;\n}\n\n.timestamp, .fio {\n  color: #0000008C;\n}\n\n.fio {\n  display: flex;\n  align-items: center;\n  column-gap: 5px;\n}\n\n.timelineChild {\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n\n.changeInfo {\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"state": `src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__state--KZomF`,
	"timestamp": `src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__timestamp--k1Q2b`,
	"fio": `src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__fio--wRFlB`,
	"timelineChild": `src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__timelineChild--JlgXd`,
	"changeInfo": `src-pages-EntityCardPage-EntityHistory-EntityStatesHistory-EntityStatesHistory-module__changeInfo--PZRwL`
};
export default ___CSS_LOADER_EXPORT___;
