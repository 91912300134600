import * as React from "react";
import i18next, { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Button } from "antd";
import { RegisterPageStore } from "../RegisterPageStore";
import styles from "./RegFinish.module.less";
import iconOk from "./ok.svg";

interface PropsRegFinish {
  store: RegisterPageStore;
}

export const RegFinish: React.FC<PropsRegFinish> = observer(({ store }) => (
  <div className={styles.totalBox}>
    <img src={iconOk} alt="" className={styles.img} />
    {store.successMessage && (
      <div className={styles.title}>{store.successMessage}</div>
    )}
    <Button
      className={styles.toHome}
      href={`/${i18next.language}/home`}
      type="primary"
      size="large"
    >
      {t("To home")}
    </Button>
  </div>
));
