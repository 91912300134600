import { z } from "zod";

export const zAttrInputNumber = z.object({
  editor: z.literal("InputNumber"),
  min: z.number().nullable().optional(),
  max: z.number().nullable().optional(),
  precision: z.number().nullable().optional(),
  addonBefore: z.string().nullable().optional(),
  addonAfter: z.string().nullable().optional(),
});

export type ZAttrInputNumber = z.infer<typeof zAttrInputNumber>;

export const makeInputNumberProps = (src: ZAttrInputNumber) => ({
  min: src.min ?? undefined,
  max: src.max ?? undefined,
  precision: src.precision ?? undefined,
  addonBefore: src.addonBefore ?? undefined,
  addonAfter: src.addonAfter ?? undefined,
});
