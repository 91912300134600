import * as React from "react";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import { FormInstance, FormItemProps } from "antd";
import { ifDef } from "src/common/ifDef";
import { key2name } from "./items";
import { FormWithBlockStore } from "./FormWithBlockStore";

export type ExtendedName = (number | string)[];

export interface ContextCommon {
  store: FormWithBlockStore;
  prevName: ExtendedName;
}

export interface ArrayContext extends ContextCommon {
  field: FormListFieldData;
  operation: FormListOperation;
  index: number; // для remove
  form: FormInstance;
}
interface NestContext extends ContextCommon {
  name: string;
}

export type BlockContext = ArrayContext | ContextCommon | NestContext;

export const getContextName = (
  ctx?: BlockContext,
): number | string | undefined => {
  if (ctx) {
    if ("name" in ctx) {
      return (ctx as NestContext).name;
    }
    if ("field" in ctx) {
      return (ctx as ArrayContext).field.name;
    }
  }
  return undefined;
};

export const makeNameExt = (
  key: string,
  ctx: BlockContext | undefined,
): ExtendedName => {
  const name = key2name(key);
  return ifDef(getContextName(ctx), (ctxName) => [ctxName, ...name]) ?? name;
};

export const getContextField = (
  ctx?: BlockContext,
): FormListFieldData | undefined =>
  ctx && "field" in ctx ? (ctx as ArrayContext).field : undefined;

export const getArrayCtx = (ctx?: BlockContext): ArrayContext | undefined =>
  ctx && "field" in ctx ? (ctx as ArrayContext) : undefined;

export type RenderResult = React.ReactNode;
export type ItemProps = FormItemProps | ((ctx?: BlockContext) => FormItemProps);

export const applyItemProps = (
  props: ItemProps,
  ctx?: BlockContext,
): FormItemProps => (typeof props === "function" ? props(ctx) : props);

export const extendItemProps = (
  props: ItemProps,
  ext: FormItemProps,
): ItemProps =>
  typeof props === "function"
    ? (ctx?: BlockContext) => ({
        ...props(ctx),
        ...ext,
      })
    : { ...props, ...ext };

export interface FormBlockItem {
  readonly key: string;
  itemProps: ItemProps;
  render(ctx: BlockContext): RenderResult;
  isHidden?: (ctx: BlockContext) => boolean;
}
export type FormBlockItemExtParams = Omit<
  FormBlockItem,
  "itemProps" | "key" | "render"
>;

export const createNestContext = (
  parent: BlockContext,
  name: string,
): NestContext => ({
  store: parent.store,
  prevName: [...parent.prevName, name],
  name,
});

export const createArrayContext = (
  parent: BlockContext,
  field: FormListFieldData,
  operation: FormListOperation,
  index: number,
  form: FormInstance,
): ArrayContext => ({
  store: parent.store,
  prevName: [...parent.prevName, field.name],
  field,
  operation,
  index,
  form,
});

export interface FormBlockDef {
  readonly key: string;
  readonly title?: string;
  readonly subBlocks?: (FormBlockDef | undefined)[];
  readonly items?: (FormBlockItem | undefined)[];
  render(prevId: string, ctx: BlockContext): RenderResult;
}

export interface StoreWithExpand {
  expand(keyOrIndex: number | string): Promise<void>;
}

export interface StateManager {
  getBlockStore<T extends StoreWithExpand>(id: string, create: () => T): T;
}
