import { makeAutoObservable } from "mobx";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { filterDefined } from "src/common/filterDefined";
import { FormBlockDef, StoreWithExpand } from "./FormWithBlocks.types";

/* eslint no-plusplus: "off" */

export type UnwindItem = {
  key: string;
  fullKey?: string;
  block?: FormBlockDef;
  prev?: UnwindItem;
  store?: StoreWithExpand;
  storeKey?: number | string;
};

export class FormWithBlockStore {
  constructor() {
    makeAutoObservable(this);
  }

  saving = false;

  setSaving(newState: boolean) {
    this.saving = newState;
  }

  blockStores: Record<string, StoreWithExpand> = {};

  getBlockStore<T extends StoreWithExpand>(id: string, create: () => T): T {
    const existingStore = this.blockStores[id];
    if (existingStore) {
      return existingStore as T;
    }
    const newSore = create();
    this.blockStores[id] = newSore;
    return newSore;
  }

  externalErrors: Record<string, string> = {};

  setExternalErrors(newErrors: Record<string, string>) {
    this.externalErrors = newErrors;
  }

  clearError(packedName: string) {
    delete this.externalErrors[packedName];
  }

  /**
   * Попытка установить фокус на указанный элемент формы.
   * При этом осуществляется раскрытие (активация) всех родительских блоков
   * @param formName
   * @param path
   * @param root
   */
  async activate(formName: string, path: InternalNamePath, root: FormBlockDef) {
    const tryFocus = () => {
      const id = `${formName}_${path.join("_")}`;
      const elem = document.getElementById(id);
      elem?.focus?.();
    };

    const items = this.unwind(path, root);

    if (items) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of items) {
        const { store, storeKey } = item;
        if (store && storeKey !== undefined) {
          await store.expand(storeKey);
        }
      }
    }
    tryFocus();
  }

  /**
   * Построить цепочку с описанием прямого пути от корня до указанного элемента формы
   * @param path путь элемента формы
   * @param root
   */
  unwind(path: InternalNamePath, root: FormBlockDef): UnwindItem[] | undefined {
    const { blockStores } = this;
    let pos = 0;
    const keyMatch = (key: string): number => {
      const keys = key.split(".");
      let i = 0;
      for (; i < keys.length; i++) {
        if (keys[i] !== path[pos + i]) return 0;
      }
      return i;
    };
    const queue: UnwindItem[] = [{ key: root.key, block: root }];
    while (queue.length > 0) {
      const item = queue.shift();
      if (!item) break;
      const { key, block, prev } = item;
      const fullKey = `${prev?.fullKey || ""}.${key}`;
      item.fullKey = fullKey;
      item.store = blockStores[fullKey];
      const delta = keyMatch(key);
      if (delta) {
        queue.length = 0;
        pos += delta;
        if (typeof path[pos] === "number") {
          // Если встретился числовой сегмент названия, это элемент массива. Но среди имён блоков чисел нет.
          item.storeKey = path[pos];
          pos += 1;
        }
        if (pos >= path.length) {
          // Дошли до конца имени
          let level: UnwindItem | undefined = item;
          const result: UnwindItem[] = [];
          while (level) {
            if (level.store && level.storeKey === undefined && result[0]) {
              level.storeKey = result[0].key;
            }
            result.unshift(level);
            level = level.prev;
          }
          return result;
        }
      }
      if (block) {
        const { subBlocks, items } = block;
        if (subBlocks) {
          filterDefined(subBlocks).forEach((subBlock) =>
            queue.push({
              key: subBlock.key,
              block: subBlock,
              prev: item,
            }),
          );
        }
        if (items) {
          filterDefined(items).forEach((subItem) =>
            queue.push({ key: subItem.key, prev: item }),
          );
        }
      }
    }
    return undefined;
  }
}
