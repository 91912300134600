import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, Menu } from "antd";
import { LoaderBox } from "src/components/LoaderBox";
import { idLabelFieldNames, loaderIdLabels } from "src/references/getIdNames";
import { SelectFromReference } from "src/components/SelectFromReference";
import { t } from "i18next";
import { ModalDelete } from "src/components/ModalDelete";
import { MftTab2Store } from "./MftTab2Store";
import { ModelessForm } from "../../../components/ModelessForm";
import styles from "./MftTab2.module.less";

interface PropsMftTab2 {
  store: MftTab2Store;
}

export const MftTab2: React.FC<PropsMftTab2> = observer(({ store }) => {
  const Inner: React.FC = observer(() => (
    <div className={styles.common}>
      <div className={styles.left}>
        <div className={styles.leftTop}>
          <Button onClick={() => store.safeCreateNewItem()}>Добавить</Button>
          <Button
            onClick={() => store.startRemoveCurrentItem()}
            disabled={!store.canDelete}
          >
            {t("Delete")}
          </Button>
        </div>
        <div className={styles.leftList}>
          <Menu
            mode="inline"
            selectedKeys={store.selectedKeys}
            items={store.itemsList.map(({ id, name }) => ({
              key: id,
              label: name || <i>Новый элемент</i>,
            }))}
            onClick={({ key }) => store.safeSelectItem(key)}
          />
        </div>
      </div>
      <div>
        <ModelessForm store={store.formStore}>
          <Form.Item name="name" label="Название" rules={[{ required: true }]}>
            <Input allowClear />
          </Form.Item>
          <Form.Item name="type" label="Тип" rules={[{ required: true }]}>
            <SelectFromReference
              fieldNames={idLabelFieldNames}
              allowClear
              loader={loaderIdLabels("attrType", "attrType")}
            />
          </Form.Item>
          <Form.Item name="comment" label="Комментарий">
            <Input.TextArea allowClear />
          </Form.Item>
        </ModelessForm>
      </div>
      <ModalDelete
        open={store.removeStatus !== "none"}
        waiting={store.removeStatus === "wait"}
        onOk={() => store.removeCurrentItem()}
        onCancel={() => store.setRemoveStatus("none")}
        message="Удалить текущий элемент?"
      />
    </div>
  ));
  React.useEffect(() => {
    store.init();
  }, []);
  return <LoaderBox remoteData={store.data} drawReady={() => <Inner />} />;
});
