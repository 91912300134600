import * as React from "react";
import { PageLayout } from "src/components/PageLayout";
import { TimelineDemo } from "./TimelineDemo";
import { TimelineDemoStore } from "./TimelineDemo/TimelineDemoStore";
import { TimelineToolbar } from "./TimelineToolbar";

const timelineStore = new TimelineDemoStore();

export const TestPage: React.FC = () => (
  <PageLayout pageTitle="Test Header">
    <div style={{ padding: "8px 16px" }}>
      <TimelineToolbar store={timelineStore} />
      <TimelineDemo store={timelineStore} />
      {/* <QDemo /> */}
    </div>
  </PageLayout>
);
