import { rest } from "src/common/rest";
import { apiConfigUrl } from "src/common/apiUrl";
import { optionalLangParams } from "src/lang/langHdr";
import { ZMenuItem, zMenuItem } from "./ZMenuItem";

export const loadMenu = async (
  options: {
    translate?: boolean;
  } = {},
): Promise<ZMenuItem[]> => {
  const { translate } = options;
  const res = await rest.get(
    apiConfigUrl("/menu"),
    optionalLangParams(translate),
  );
  return zMenuItem.array().parse(res.data);
};
