/*
 * http://dev.plm.d4r.int/sample-service/swagger-ui/index.html#/
 */
import { gateway } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { zSampleSettings, ZSampleSettings } from "./SampleTypes";
import {
  zSampleColorsResponse,
  zSampleRecord,
  ZSampleRecord,
  zSampleRequest,
  ZSampleRequest,
  zSampleSuppliersResponse,
} from "./SampleControl/ZSampleRequest";
import { ZSampleData, zSampleData } from "./SampleControl/ZSampleData";
import { NewSampleData } from "./SampleControl/SamplePoints/SamplePointsTypes";

export const apiSample = (path: string) =>
  gateway(`/sample-service/api${path}`);

// ----- settings

export const loadSampleSettings = async (
  objectId: number,
): Promise<ZSampleSettings> => {
  const resp = await rest.get(apiSample("/settings"), { params: { objectId } });
  return zSampleSettings.parse(resp.data);
};

export const saveSampleSettings = async (
  objectId: number,
  objectServiceId: number,
  version: number,
  settings: ZSampleSettings,
) => {
  await rest.post(apiSample("/settings"), settings, {
    params: { objectId, serviceId: objectServiceId, version },
  });
};

// ------- summary

export const loadSampleSummary = async (
  modelEntityId: number | string,
  modelObjectId: number | string,
): Promise<ZSampleRequest[]> => {
  const resp = await rest.get(apiSample("/sample-request"), {
    params: { modelEntityId, modelObjectId },
  });
  return zSampleRequest.array().parse(resp.data);
};

export const createSampleRequest = async (
  modelObjectId: number | string,
  requestData: Omit<ZSampleRequest, "id">,
) => {
  await rest.post(apiSample("/sample-request"), requestData, {
    params: { modelObjectId },
  });
};

export const updateSampleRequest = async (
  modelObjectId: number | string,
  requestData: ZSampleRequest,
) => {
  await rest.put(apiSample("/sample-request"), requestData, {
    params: { modelObjectId },
  });
};

export const deleteSampleRequest = async (
  modelEntityId: number | string,
  ids: (number | string)[],
) => {
  await rest.delete(apiSample("/sample-request"), {
    params: { modelEntityId },
    data: ids,
  });
};

export const loadSampleSuppliers = async (params: {
  modelObjectId: number | string;
  page: number;
  size: number;
  query: string | undefined;
}) => {
  const resp = await rest.get(apiSample("/supplier"), { params });
  return zSampleSuppliersResponse.parse(resp.data);
};

export const loadSampleColors = async (params: {
  modelObjectId: number | string;
  modelId: number | string;
  page: number;
  size: number;
  query: string | undefined;
}) => {
  const resp = await rest.get(apiSample("/color"), { params });
  return zSampleColorsResponse.parse(resp.data);
};

export enum SampleDictionaryType {
  sampleRequestType = "SAMPLE_REQUEST_TYPE",
  fitStatus = "FIT_STATUS",
  requestType = "REQUEST_TYPE",
  requestStatus = "REQUEST_STATUS",
}

export const loadSampleDictionary = async (
  modelObjectId: number | string,
  name: SampleDictionaryType,
): Promise<ZSampleRecord[]> => {
  const resp = await rest.get(apiSample("/dictionary"), {
    params: { modelObjectId, name },
  });
  return zSampleRecord.array().parse(resp.data);
};

// -------- points

export const loadSampleData = async (
  sampleRequestId: number | string,
): Promise<ZSampleData[]> => {
  const resp = await rest.get(apiSample("/sample"), {
    params: { sampleRequestId },
  });
  return zSampleData.array().parse(resp.data);
};

export const createSampleData = async (
  sampleRequestId: number | string,
  data: NewSampleData,
) => {
  const { sizeId, versionId } = data;
  await rest.post(apiSample("/sample"), undefined, {
    params: { sampleRequestId, sizeId, versionId },
  });
};

export const saveSampleData = async (
  params: {
    sampleRequestId: number | string;
    sampleId: number | string;
    target: string;
  },
  row: ZSampleData,
) => {
  await rest.put(apiSample("/sample"), row, { params });
};

export const deleteSampleData = async (
  sampleRequestId: number | string,
  ids: (number | string)[],
) => {
  await rest.delete(apiSample("/sample"), {
    params: { sampleRequestId },
    data: ids,
  });
};
