import { z } from "zod";

// Система координат определяется imgWidth и imgHeight

export const zModelAttr = z.object({
  key: z.number(),
  attrName: z.string(),
  xA: z.number(),
  yA: z.number(),
  xB: z.number(),
  yB: z.number(),
  xC: z.number().optional(),
  yC: z.number().optional(),
});
export type ZModelAttr = z.infer<typeof zModelAttr>;

export const zModel = z.object({
  id: z.number(),
  name: z.string(),
  imgRef: z.string(),
  imgWidth: z.number(),
  imgHeight: z.number(),
  attrs: zModelAttr.array(),
});

export type ZModel = z.infer<typeof zModel>;
