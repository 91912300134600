// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__button--T2Yzv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  color: #343a41;
  padding-bottom: 13px;
}
.src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__iconWrapper--w2iSc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__listBox--H_BKF {
  overflow: auto;
}
.src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__listItem--Z5OHH {
  border-bottom: 1px solid #e2e2e2;
  list-style: none;
}
.src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__listItem--Z5OHH:first-child {
  border-top: 1px solid #e2e2e2;
}
.src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__listItemInner--Tfi17 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 16px;
  white-space: nowrap;
  column-gap: 8px;
}
.src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__controllButtons--XxrIq {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AttsHierarchy/AttsHierarchy.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAGA;EACE,cAAA;AADF;AAGA;EACE,gCAAA;EACA,gBAAA;AADF;AAEE;EACE,6BAAA;AAAJ;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AADF;AAIA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AAFF","sourcesContent":[".button {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 14px;\n  font-weight: 600;\n  color: #343a41;\n  padding-bottom: 13px;\n}\n\n.iconWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.listBox {\n  overflow: auto;\n}\n.listItem {\n  border-bottom: 1px solid rgba(226, 226, 226, 1);\n  list-style: none;\n  &:first-child {\n    border-top: 1px solid rgba(226, 226, 226, 1);\n  }\n}\n.listItemInner {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 15px 16px;\n  white-space: nowrap;\n  column-gap: 8px;\n}\n\n.controllButtons {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__button--T2Yzv`,
	"iconWrapper": `src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__iconWrapper--w2iSc`,
	"listBox": `src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__listBox--H_BKF`,
	"listItem": `src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__listItem--Z5OHH`,
	"listItemInner": `src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__listItemInner--Tfi17`,
	"controllButtons": `src-pages-EntityFiltersPage-AttsHierarchy-AttsHierarchy-module__controllButtons--XxrIq`
};
export default ___CSS_LOADER_EXPORT___;
