import * as React from "react";
import { Button, Form, notification } from "antd";
import { EntityCardStore } from "src/pages/EntityCardPage/EntityCardStore";
import { observer } from "mobx-react-lite";
import { EdCardValues } from "src/pages/EntityCardPage/apiEntityCard";
import { t } from "i18next";
import { restCache } from "src/common/restCache";
import { DeleteOutlined } from "@ant-design/icons";
import { TShapeForm } from "../TShapeForm/TShapeForm";
import { confirmDelete } from "../ModalDelete";

const entityCardFormName = "EntitySubForm";
const idEntityFormContainer = "EntitySubFormContainer";

interface PropsChildEntitiesEditor {
  store: EntityCardStore;
  onSuccess(): void;
  onDelete?(): void;
  deleting?: boolean;
  submitText: string;
}

export const msgMultiInstancesCreated = (count: number) =>
  t("New instances were created", { count });

export const ChildEntitiesEditor: React.FC<PropsChildEntitiesEditor> = observer(
  (props) => {
    const { store, onSuccess, submitText, onDelete, deleting } = props;
    const { rootBlock, formStore, entityName } = store;
    const [form] = Form.useForm();
    if (!rootBlock) return null;
    const onSubmit = async (values: EdCardValues) => {
      restCache.invalidate();
      if (store.entityId) {
        await store.save(values);
      } else {
        await store.create(values);
      }
    };
    const onSuccessInternal = (values: EdCardValues | EdCardValues[]) => {
      if (!store.entityId) {
        const message = Array.isArray(values)
          ? msgMultiInstancesCreated(values.length)
          : t("New instance has been created");
        notification.success({
          message,
          duration: 1,
        });
      }
      onSuccess();
    };
    const deleteHandler = () => {
      confirmDelete(t("Do you want to delete the current entry?"), () =>
        onDelete?.(),
      );
    };
    const extraButtons =
      store.canDelete && !!onDelete ? (
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={deleteHandler}
          loading={deleting}
        >
          {t("Delete")}
        </Button>
      ) : undefined;
    return (
      <TShapeForm
        title={entityName}
        form={form}
        formProps={{
          name: entityCardFormName,
          initialData: store.initialData,
          block: rootBlock,
          store: formStore,
          submit: onSubmit,
          submitText,
          onSuccess: onSuccessInternal,
          extraButtons,
          onValuesChange(changedValues, values) {
            store.onFormValuesChange(form, changedValues, values);
          },
        }}
        rightPartProps={{ id: idEntityFormContainer }}
      />
    );
  },
);
