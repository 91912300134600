// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__form--tiQFq {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__company--jKKA9 {
  padding: 23px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  align-items: baseline;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__restoreText--ZjOVV {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
}
.src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__infoText--baNeT {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__buttons--WiFXh {
  padding-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AuthChecker/overlays/PassRestorePage/PassRestorePage.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,qBAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAEA;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAEA;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAEA;EACI,iBAAA;AAAJ","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n}\n.company {\n    padding: 23px 0;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 12px;\n    align-items: baseline;\n\n    font-size: 21px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n}\n.restoreText {\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 120%;\n    text-align: center;\n}\n.infoText {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%;    \n}\n.buttons {\n    padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__form--tiQFq`,
	"company": `src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__company--jKKA9`,
	"restoreText": `src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__restoreText--ZjOVV`,
	"infoText": `src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__infoText--baNeT`,
	"buttons": `src-components-AuthChecker-overlays-PassRestorePage-PassRestorePage-module__buttons--WiFXh`
};
export default ___CSS_LOADER_EXPORT___;
