import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { BomObjType } from "../Bom2SettingsStore";
import { ZBom2ColorSettings, ZBom2Settings } from "../../Bom2Types";

interface PropsColorSection {
  store: FormObjectSectionsStore<BomObjType>;
}

const fieldName = (
  name: keyof ZBom2ColorSettings,
): ["colorAttributes", keyof ZBom2ColorSettings] => ["colorAttributes", name];

export const ColorSection: React.FC<PropsColorSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZBom2Settings, BomObjType>
      store={store}
      objKey={["colorObjectId"]}
      objType="color"
      objLabel="Color object"
      title="Color"
      attrs={[
        {
          attrKey: fieldName("linkToModelId"),
          attrLabel: "linkToModelId",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: fieldName("colorModelArticleId"),
          attrLabel: "colorModelArticleId",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: fieldName("linkToColorInternalId"),
          attrLabel: "linkToColorInternalId",
          attrType: AttrTypeName.object,
        },
      ]}
    />
  ),
);
