import { z } from "zod";

export const zSampleSettings = z.object({
  // Справочники
  dictSizeVersionId: z.number().nullable(),
  dictRequestTypeId: z.number().nullable(),
  dictRequestStatusId: z.number().nullable(),
  dictFitStatusId: z.number().nullable(),
  dictSampleRequestTypeId: z.number().nullable(),
  // Объекты
  objSampleRequest: z.number().nullable(),
  objSample: z.number().nullable(),
  objSampleComments: z.number().nullable(),
  objSampleMcPoint: z.number().nullable(),
  objSupplier: z.number().nullable(),
  objColor: z.number().nullable(),
  // Sample request
  sampleRequestModelAttrId: z.number().nullable(),
  sampleRequestNameAttrId: z.number().nullable(),
  sampleRequestNumberAttrId: z.number().nullable(),
  sampleRequestColorModelAttrId: z.number().nullable(),
  sampleRequestSupplierNameAttrId: z.number().nullable(),
  sampleRequestDateSendAttrId: z.number().nullable(),
  sampleRequestDateReceiveAttrId: z.number().nullable(),
  sampleRequestRequestTypeAttrId: z.number().nullable(),
  sampleRequestFitStatusAttrId: z.number().nullable(),
  sampleRequestGeneralCommentsAttrId: z.number().nullable(),
  sampleRequestSamplesAttrId: z.number().nullable(),
  // Sample
  sampleSampleRequestAttrId: z.number().nullable(),
  sampleNameAttrId: z.number().nullable(),
  sampleQuantityAttrId: z.number().nullable(),
  sampleSizeAttrId: z.number().nullable(),
  sampleSizeVersionAttrId: z.number().nullable(),
  sampleRequestTypeAttrId: z.number().nullable(),
  sampleReviewDateAttrId: z.number().nullable(),
  sampleRequestStatusAttrId: z.number().nullable(),
  sampleCommentsAttrId: z.number().nullable(),
  sampleMcPointsAttrId: z.number().nullable(),
  sampleSampleCommentsAttrId: z.number().nullable(),
  // Sample comments
  sampleCommentsSampleAttrId: z.number().nullable(),
  sampleCommentsImageAttrId: z.number().nullable(),
  sampleCommentsCommentsAttrId: z.number().nullable(),
  // Sample mc points
  sampleMcPointSampleAttrId: z.number().nullable(),
  sampleMcPointMcPointValueAttrId: z.number().nullable(),
  sampleMcPointMeasuredValueAttrId: z.number().nullable(),
  // Supplier
  supplierSupplierNameAttrId: z.number().nullable(),
  supplierSupplierShortNameAttrId: z.number().nullable(),
  supplierCountryAttrId: z.number().nullable(),
  supplierAddressAttrId: z.number().nullable(),
  // Color
  colorColorArticleAttrId: z.number().nullable(),
});
export type ZSampleSettings = z.infer<typeof zSampleSettings>;
