// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__frame--Nkvrk {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__title--YNqD0 {
  padding: 20px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.85);
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__subtitle--iQVjB {
  color: rgba(0, 0, 0, 0.55);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__tools--HY4c1 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__filters--t35Ds {
  flex: 1;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__image--j2ze2 {
  max-width: 100%;
  max-height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SamplePoints/Subsample/SamplePhotoComments/SamplePhotoComments.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACA;EACI,eAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;AACJ;AACA;EACI,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;AACJ;AACA;EACI,OAAA;AACJ;AAEA;EACI,eAAA;EACA,iBAAA;AAAJ","sourcesContent":[".frame {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n.title {\n    padding: 20px 0;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 150%;\n    color: rgba(0, 0, 0, 0.85);    \n}\n.subtitle {\n    color: rgba(0, 0, 0, 0.55);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%;    \n}\n.tools {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    align-items: center;\n}\n.filters {\n    flex: 1;\n}\n\n.image {\n    max-width: 100%;\n    max-height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__frame--Nkvrk`,
	"title": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__title--YNqD0`,
	"subtitle": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__subtitle--iQVjB`,
	"tools": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__tools--HY4c1`,
	"filters": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__filters--t35Ds`,
	"image": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SamplePhotoComments-SamplePhotoComments-module__image--j2ze2`
};
export default ___CSS_LOADER_EXPORT___;
