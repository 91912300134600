import React from "react";
import { ImgList } from "src/common/attrView/components";
import styles from "./ImgListInner.module.less";

type PropsImgListInner = { imgUuidList?: string[] };

export const ImgListInner: React.FC<PropsImgListInner> = ({ imgUuidList }) => (
  <div className={styles.box}>
    <ImgList values={imgUuidList} />
  </div>
);

ImgListInner.defaultProps = { imgUuidList: undefined };
