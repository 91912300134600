import { z } from "zod";
import { makeAutoObservable } from "mobx";
import { ZObjectItem } from "src/types/ZObjectItem";
import { TableStore } from "src/components/tables/TableStore";
import { rest } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import { onError } from "src/common/onError";
import { delay } from "src/common/delay";
import { ZRole } from "../../Obj2Tab/roles/roleTypes";
import { ZPersonEntity, zPersonEntity } from "../ZPersonEntity";

export type PersonRow = Pick<ZPersonEntity, "id" | "userLogin"> & {
  [key: number]: string[] | null;
};
type PersonFilters = {};

const zCustomPageEntity = z.object({
  content: zPersonEntity.array(),
  totalElements: z.number(),
});

interface ProgressData {
  count: number;
  current: number; // 1-based
  percent: number;
  status: "active" | "exception" | "normal" | "success";
}

export class PersonDetailsStore {
  tableStore: TableStore<PersonRow, PersonFilters>;

  constructor(
    public role: ZRole,
    public roleObject: ZObjectItem,
  ) {
    this.tableStore = new TableStore<PersonRow, PersonFilters>({
      rowKey: "id",
      fnLoad: async ({ page, pageSize }) => {
        const body = { objectId: this.roleObject.id, stateId: 1 };
        const params = { page, size: pageSize };
        const resp = await rest.post(apiObjUrl("/users/search"), body, {
          params,
        });
        const res = zCustomPageEntity.parse(resp.data);
        const rows: PersonRow[] = res.content.map(
          ({ id, userLogin, attributeValues }) => ({
            id,
            userLogin,
            ...attributeValues.reduce(
              (acc, { attributeId, values }) => ({
                ...acc,
                [attributeId]: values,
              }),
              {},
            ),
          }),
        );
        return {
          rows,
          totalItems: res.totalElements,
        };
      },
      selectionSettings: { selectionType: "checkbox" },
    });
    makeAutoObservable(this);
  }

  get countToDelete(): number {
    return this.tableStore.selected.length;
  }

  get deleting(): boolean {
    return !!this.delProgress;
  }

  delProgress: ProgressData | null = null;

  setDelProgress(progress: ProgressData | null) {
    this.delProgress = progress;
  }

  async doDelete() {
    const selected = [...this.tableStore.selected];
    try {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selected.length; i++) {
        this.setDelProgress({
          current: i + 1,
          count: selected.length,
          percent: Math.round((i * 100) / selected.length),
          status: "active",
        });
        const row = selected[i]!;
        // eslint-disable-next-line no-await-in-loop
        await rest.delete(apiObjUrl(`/users/${row.id}`));
      }
      this.setDelProgress({
        current: selected.length,
        count: selected.length,
        percent: 100,
        status: "success",
      });
    } catch (e) {
      onError(e);
      this.setDelProgress(
        this.delProgress && {
          ...this.delProgress,
          status: "exception",
        },
      );
    } finally {
      if (selected.length > 1) {
        await delay(500);
      }
      this.setDelProgress(null);
      this.tableStore.reload();
    }
  }
}
