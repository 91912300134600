/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

export type AttrViewDef<TCProps extends {}> = {
  Component: React.FC<TCProps>;
  cmpProps: TCProps;
};

export const defineAttrView = <TCProps extends {}>({
  Component,
  cmpProps,
}: AttrViewDef<TCProps>) => <Component {...cmpProps} />;
