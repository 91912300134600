// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-ManagementPage-module__header--NYWKU .ant-tabs-nav {
  margin-bottom: 0;
  padding: 0 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/ManagementPage.module.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;EACA,eAAA;AAAJ","sourcesContent":[".header {\n  :global(.ant-tabs-nav) {\n    margin-bottom: 0;\n    padding: 0 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `src-pages-ManagementPage-ManagementPage-module__header--NYWKU`
};
export default ___CSS_LOADER_EXPORT___;
