/**
 * Подчиненные для значения справочника
 */
import { z } from "zod";
import { zGroup } from "./ZGroup";
import { zAttribute } from "./ZAttribute";

export const zValueThings = z.object({
  groups: zGroup.array(),
  attributes: zAttribute.array(),
});

export type ZValueThings = z.infer<typeof zValueThings>;
