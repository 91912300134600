import { Flex, Form, Switch, SwitchProps } from "antd";
import React from "react";

type PropsSwitchFormItem = SwitchProps & {
  text: string;
  name: string;
};

export const SwitchFormItem: React.FC<PropsSwitchFormItem> = ({
  text,
  name,
  ...props
}) => (
  <Flex align="center" gap={12}>
    <Form.Item name={name} noStyle>
      <Switch {...props} />
    </Form.Item>
    <span>{text}</span>
  </Flex>
);
