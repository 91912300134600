import * as React from "react";
import { VerticalContent } from "src/components/VerticalContent";

interface PropsWidthLimitedFields {
  children?: React.ReactNode | React.ReactNode[];
}

export const WidthLimitedFields: React.FC<PropsWidthLimitedFields> = ({
  children,
}) => <VerticalContent maxWidth={900}>{children}</VerticalContent>;
WidthLimitedFields.defaultProps = { children: null };
