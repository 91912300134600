import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { ZOption } from "src/types/ZOption";
import { z } from "zod";

let cache: ZOption[] | null = null;

export const loadTemplatePlaceholders = async (): Promise<ZOption[]> => {
  if (cache) return cache;
  const resp = await rest.get(apiObjUrl("/message-template/placeholders"));
  const placeholders = z.string().array().parse(resp.data);
  const options: ZOption[] = placeholders.map((label) => ({
    label,
    value: `${label}%`,
  }));
  cache = options;
  return options;
};
