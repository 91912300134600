import React from "react";
import { Tooltip } from "antd";
import { Person } from "../Timeline.types";
import face1001 from "./face1001.jpg";
import face1002 from "./face1002.jpg";
import face1003 from "./face1003.jpg";
import face1004 from "./face1004.jpg";

interface PropsDrawFace {
  pers: Person;
}

const size = 20;

const imgMap: Record<number, string> = {
  1001: face1001,
  1002: face1002,
  1003: face1003,
  1004: face1004,
};

export const DrawFace: React.FC<PropsDrawFace> = ({ pers }) => {
  const src = imgMap[pers.id];
  return (
    <Tooltip title={pers.name}>
      <img
        src={src}
        style={{
          width: size,
          height: size,
          background: "#CCC",
          borderRadius: "50%",
        }}
        alt=""
      />
    </Tooltip>
  );
};
