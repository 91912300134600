import * as React from "react";
import { applyOrderStd, CtrlColumns } from "src/components/tables/TableStore";
import { Checkbox, Dropdown } from "antd";
import { observer } from "mobx-react-lite";
import { DragableIconStd, SettingsIconStd } from "src/common/icons";
import { useClickOutside } from "src/common/useClickOutside";
import { CloseOutlined } from "@ant-design/icons";
import { SortableList, SortableItemDef } from "src/components/SortableList";
import styles from "./ColumnsSettings.module.less";

interface PropsColumnsSettings {
  store: CtrlColumns;
}

export const ColumnsSettings: React.FC<PropsColumnsSettings> = observer(
  ({ store }: PropsColumnsSettings) => {
    const [open, setOpen] = React.useState(false);
    const ref = useClickOutside(() => {
      setOpen(false);
    });

    const sortedColumns = applyOrderStd(store, store.columns);

    const items: SortableItemDef[] = sortedColumns
      .filter(({ condition }) => !condition || condition())
      .filter(
        ({ title, visibility }) =>
          !(
            visibility === "alwaysVisible" &&
            typeof title === "string" &&
            title.trim() === ""
          ),
      )
      .map((col) => ({
        key: col.key,
        content: (
          <label key={col.key} className={styles.columnInfo}>
            <div className={styles.checkBoxWrapper}>
              <Checkbox
                checked={store.isColumnVisible(col.key)}
                onChange={(e) =>
                  store.setColumnVisible(col.key, e.target.checked)
                }
                disabled={!store.canColumnHide(col.key)}
              />
              <span>{col.title}</span>
            </div>
            <DragableIconStd />
          </label>
        ),
      }));

    const handleSortableChange = (newItems: SortableItemDef[]) => {
      const keys = newItems.map((item) => String(item.key));
      store.setColumnOrder(keys);
    };

    return (
      <Dropdown
        menu={{ className: styles.menu }}
        dropdownRender={() => (
          <div ref={ref} className={styles.sortableContainer}>
            <SortableList items={items} onChange={handleSortableChange} />
          </div>
        )}
        open={open}
        placement="bottomRight"
      >
        <div className={styles.trigger}>
          {open ? (
            <CloseOutlined onClick={() => setOpen(false)} />
          ) : (
            <div role="presentation" onClick={() => setOpen(true)}>
              <SettingsIconStd />
            </div>
          )}
        </div>
      </Dropdown>
    );
  },
);
