import * as React from "react";
import { Space, Tag, Typography } from "antd";
import { t } from "i18next";
import { PropsSelection } from "../types";
import styles from "./SingleSelection.module.less";

export const SingleSelection: React.FC<PropsSelection> = (props) => {
  const { placeholder = t("Selected"), options, onChange } = props;
  const [option] = options ?? [];
  const onClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onChange?.([], []);
  };
  return (
    <Space className={styles.container}>
      <Typography.Text type="secondary">{placeholder}:</Typography.Text>
      {option && (
        <Tag closeIcon onClose={onClose} bordered={false}>
          {option.label}
        </Tag>
      )}
    </Space>
  );
};
