/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const AvatarIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => (
  <svg viewBox="0 0 28 28" {...props} width="32" height="32">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 16.5C17.3137 16.5 20 13.8137 20 10.5C20 7.18629 17.3137 4.5 14 4.5C10.6863 4.5 8 7.18629 8 10.5C8 13.8137 10.6863 16.5 14 16.5ZM14 14.5C16.2091 14.5 18 12.7091 18 10.5C18 8.29086 16.2091 6.5 14 6.5C11.7909 6.5 10 8.29086 10 10.5C10 12.7091 11.7909 14.5 14 14.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM23.3235 21.5553C24.9971 19.4925 26 16.8633 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 16.8639 3.00322 19.4934 4.67739 21.5564C4.98039 21.2858 5.29403 21.0295 5.61727 20.7878C8.02337 18.9886 10.9612 18 14.0011 18C17.0409 18 19.9787 18.9886 22.3848 20.7878C22.7076 21.0292 23.0209 21.2851 23.3235 21.5553ZM21.9401 22.9976C19.7541 21.0697 16.9329 19.997 14.0011 19.997C11.0687 19.997 8.24706 21.07 6.06092 22.9986C8.17652 24.8665 10.9559 26 14 26C17.0446 26 19.8244 24.8661 21.9401 22.9976Z"
    />
  </svg>
);

export const AvatarIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={AvatarIconStdSvg} {...props} />;
