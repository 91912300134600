import { Dayjs } from "dayjs";
/**
 * Это финальные типы, которые используются для отрисовки.
 * Предполагается, что с бэка это будет передано в каком-то ином виде,
 * но в итоге скомпоновано именно так.
 */

export type StatusId = "closed" | "completed" | "inProgress" | "open";

export interface Person {
  id: number;
  name: string;
}
export interface Subtask {
  id: number;
  name: string;
  listIndex: string;
  status: StatusId;
  executor: Person[];
  start: Dayjs;
  finish: Dayjs;
  colorBack: string;
  colorComplete: string;
  progress?: number; // 0-100
}

export interface Task {
  id: number;
  name: string;
  listIndex: string;
  expanded: boolean;

  colorBack: string;
  colorComplete: string;
  colorText: string;

  start?: Dayjs;
  finish?: Dayjs;

  subtasks: Subtask[];
}

export const statusName: Record<StatusId, string> = {
  open: "Открыт",
  inProgress: "В работе",
  completed: "Выполнено",
  closed: "Закрыт",
};
