import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { getIdLabels } from "src/references/getIdNames";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";

/**
 * среди всех атрибутов находим только usersList и берем их уникальные значения
 */
export const getUniqUserValsFromAttsUL = async ({
  entity,
  attributes,
}: EntityCardData) => {
  const typesMap = makeDictNameById(await getIdLabels("attrType", "attrType"));
  const userListAttrs = attributes.filter(
    ({ valueType }) => typesMap[valueType] === AttrTypeName.usersList,
  );
  const ulaKeys = new Set(userListAttrs.map(({ id }) => id));
  const entULAList =
    entity?.attributeValues.filter((attr) => ulaKeys.has(attr.attributeId)) ||
    [];
  const vals = Array.from(
    new Set(entULAList.map(({ values }) => values).flat()),
  ).filter((v) => !!v) as string[];
  return vals;
};
