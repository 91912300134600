import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import { SchedulingSettings } from "./SchedulingSettings";
import {
  loadSchedulingSettings,
  saveSchedulingSettings,
} from "./apiScheduling";
import { zSchedulingSettings } from "./SchedulingTypes";
import { SchedulingControl } from "./SchedulingControl";

export const bsScheduling: BusinessServiceDescriptor = {
  key: "planning",
  viewer: (info, { entity }) => {
    if (!entity) return null;
    return <SchedulingControl entityId={entity.id} objectServiceId={info.id} />;
  },
  renderSettings: (_info, store) => (
    <SchedulingSettings typesMap={store.attrTypesDict} />
  ),
  loadSettings: async (info) => loadSchedulingSettings(info.id),
  async saveSettings(info, values) {
    const settings = zSchedulingSettings.parse(values);
    await saveSchedulingSettings(info.id, settings);
  },
};
