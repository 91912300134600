import * as React from "react";
import { t } from "i18next";
import { Button, Form, Input, Modal, Select, Space } from "antd";
import { ZCmpOp, ZRelativeCmpRule } from "src/common/attrEdit";
import { BulbOutlined, DeleteOutlined } from "@ant-design/icons";
import { ZAttribute } from "src/types/ZAttribute";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { onError } from "src/common/onError";
import { getTypeIcon } from "src/pages/ManagementPage/Obj2Tab/utils/typeIcons";
import { ifDef } from "src/common/ifDef";

interface PropsRelativeCmpEdit {
  typeName: string;
  typesMap: Record<number, string>;
  objectId: number;
  curAttrId: number;
  value?: ZRelativeCmpRule;
  onChange?(newValue?: ZRelativeCmpRule): void;
}

const fieldName = (name: keyof ZRelativeCmpRule) => name;

const opDict: Record<ZCmpOp, string> = {
  GT: "Текущее > Указанное",
  GE: "Текущее ≥ Указанное",
  LE: "Текущее ≤ Указанное",
  LT: "Текущее < Указанное",
  NE: "Текущее ≠ Указанное",
};
const opStrDict: Record<ZCmpOp, string> = {
  GT: "больше чем",
  GE: "не меньше чем",
  LE: "не больше чем",
  LT: "меньше чем",
  NE: "не равно",
};

export const RelativeCmpEdit: React.FC<PropsRelativeCmpEdit> = (props) => {
  const { typeName, value, onChange, objectId, curAttrId, typesMap } = props;
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState(false);
  const close = () => setOpen(false);
  const operations = React.useMemo(
    () => Object.entries(opDict).map(([key, label]) => ({ value: key, label })),
    [],
  );
  const onDelete = () => {
    onChange?.(undefined);
    close();
  };
  const [attrs, setAttrs] = React.useState<ZAttribute[]>([]);
  const [attrWait, setAttrWait] = React.useState(false);
  React.useEffect(() => {
    if (open) {
      setAttrWait(true);
      loadObjectAttrinbutesAll(objectId)
        .then((list) => setAttrs(list))
        .finally(() => setAttrWait(false))
        .catch(onError);
    }
  }, [open, objectId]);
  const attrOptions: { value: number; label: React.ReactNode }[] =
    React.useMemo(
      () =>
        attrs
          .filter(
            ({ id, valueType }) =>
              id !== curAttrId && typesMap[valueType] === typeName,
          )
          .map(({ id, name, valueType }) => ({
            value: id,
            label: (
              <Space>
                {getTypeIcon(valueType)} {name}
              </Space>
            ),
          })),
      [attrs, curAttrId],
    );

  const onSubmit = (values: ZRelativeCmpRule) => {
    onChange?.(values);
    close();
  };
  const onMsgGeneration = () => {
    const left = attrs.find(({ id }) => id === curAttrId);
    const op = opStrDict[form.getFieldValue(fieldName("opCode")) as ZCmpOp];
    const rightId = +form.getFieldValue(fieldName("otherAttrId"));
    const right = attrs.find(({ id }) => id === rightId);
    form.setFieldValue(
      fieldName("message"),
      `Значение "${left?.name}" должно быть ${op} "${right?.name}"`,
    );
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {ifDef(
          opStrDict[value?.opCode as ZCmpOp],
          (it) => `Текущее ${it} Указанное`,
        ) ?? "Нет"}
        ...
      </Button>
      <Modal
        title="Правило для сравнения с другим полем"
        open={open}
        onCancel={close}
        footer={null}
      >
        <Form
          name="RelativeCmpEdit"
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={value}
        >
          <Form.Item
            name={fieldName("otherAttrId")}
            label={`Поле для сравнения. Тип=${t(`attrType.${typeName}`)}`}
            rules={[{ required: true }]}
          >
            <Select loading={attrWait} options={attrOptions} allowClear />
          </Form.Item>
          <Form.Item
            name={fieldName("opCode")}
            label="Операция"
            rules={[{ required: true }]}
          >
            <Select options={operations} allowClear />
          </Form.Item>
          <Form.Item
            name={fieldName("message")}
            label="Сообщение"
            rules={[{ required: true }]}
          >
            <Input
              allowClear
              addonAfter={
                <Button
                  size="small"
                  type="text"
                  icon={<BulbOutlined />}
                  onClick={onMsgGeneration}
                  title="Сгенерировать сообщение"
                />
              }
            />
          </Form.Item>
          <Form.Item style={{ marginTop: 24 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Применить
              </Button>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={onDelete}
                title="Все поля будут очищены и окно закроется"
              >
                Удалить правило
              </Button>
              <Button onClick={close}>Отмена</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

RelativeCmpEdit.defaultProps = {
  value: undefined,
  onChange: undefined,
};
