// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ChildEntities-ChildEntitiesActions-ChildEntitiesActions-module__menuButton--FLh5b {
  height: 40px;
  width: 40px !important;
}
.src-components-ChildEntities-ChildEntitiesActions-ChildEntitiesActions-module__menuButton--FLh5b span {
  height: 100%;
}
.src-components-ChildEntities-ChildEntitiesActions-ChildEntitiesActions-module__menuButton--FLh5b svg {
  font-size: 24px;
}
.src-components-ChildEntities-ChildEntitiesActions-ChildEntitiesActions-module__icon--jZ7gt svg {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChildEntities/ChildEntitiesActions/ChildEntitiesActions.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,sBAAA;AACF;AAHA;EAKI,YAAA;AACJ;AANA;EAQI,eAAA;AACJ;AAGA;EAEI,eAAA;AAFJ","sourcesContent":[".menuButton {\n  height: 40px;\n  width: 40px !important;\n\n  span {\n    height: 100%;\n  }\n  svg {\n    font-size: 24px;\n  }\n}\n\n.icon {\n  svg {\n    font-size: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": `src-components-ChildEntities-ChildEntitiesActions-ChildEntitiesActions-module__menuButton--FLh5b`,
	"icon": `src-components-ChildEntities-ChildEntitiesActions-ChildEntitiesActions-module__icon--jZ7gt`
};
export default ___CSS_LOADER_EXPORT___;
