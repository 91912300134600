import { TreeLikeData } from "src/types/TreeLikeData";

// New method to walk through all nodes in the tree
export const walk = <TData extends {}>(
  callback: (node: TreeLikeData<TData>) => void,
  treeData: TreeLikeData<TData>[],
) => {
  treeData.forEach((node) => walkNode(node, callback));
};

// Helper method to recursively walk through each node and its children
const walkNode = <TData extends {}>(
  node: TreeLikeData<TData>,
  callback: (node: TreeLikeData<TData>) => void,
) => {
  callback(node); // Invoke the callback function for the current node
  // Recursively walk through children nodes
  if (node.children) {
    node.children.forEach((child) => walkNode(child, callback));
  }
};
