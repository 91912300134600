/* eslint-disable react/default-props-match-prop-types */
import * as React from "react";
import { Drawer, DrawerProps } from "antd";
import { classNames } from "src/common/classNames";
import styles from "./DrawerPlm.module.less";

export type PropsDrawerPlm = DrawerProps;

export const DrawerPlm: React.FC<PropsDrawerPlm> = (props) => {
  const { classNames: classNamesProp = {}, ...restProps } = props;

  return (
    <Drawer
      {...restProps}
      classNames={{
        content: classNames([styles.content, classNamesProp.content]),
        header: classNames([styles.header, classNamesProp.header]),
        body: classNames([styles.body, classNamesProp.body]),
        footer: classNames([styles.footer, classNamesProp.footer]),
      }}
    />
  );
};

DrawerPlm.defaultProps = {
  width: "75vw",
};
