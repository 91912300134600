import { FormBlockDef } from "src/components/FormWithBlocks";
import { ZObjectItem } from "src/types/ZObjectItem";
import { buildMnenoGroup } from "./buildMnemoGroup";
import { BuilderCtx } from "./BuilderCtx";

export const buildMainBlock = (
  object: ZObjectItem,
  ctx: BuilderCtx,
): FormBlockDef =>
  buildMnenoGroup(
    "mnemo-group",
    "",
    nonValued(object.attributes),
    nonValued(object.groups),
    { ...ctx, objectName: object.name },
  );

type Valued = {
  valueId?: number | null;
};
const nonValued = <T extends Valued>(list: T[] | null | undefined): T[] =>
  (list ?? []).filter(({ valueId }) => !valueId);
