import * as React from "react";
import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { delay } from "src/common/delay";
import { onError } from "src/common/onError";
import { ModelessFormStore } from "../../../components/ModelessForm/ModelessFormStore";

export type TObject2 = {
  id: number;
  name: string;
  type: number;
  comment?: string;
};

const newItemId = -1;

export class MftTab2Store {
  constructor() {
    this.formStore = new ModelessFormStore(
      (values) => this.saveItem(values),
      () => {
        // В случае подтверждения потери данных нужно удалить пустой элемент
        if (this.currentItemId === newItemId) {
          this.internalRemoveItem(newItemId);
          this.formStore.unsafeLoad(null);
        }
      },
    );
    makeAutoObservable(this);
  }

  async init() {
    try {
      this.setCurrentItemId(null);
      this.setData({ status: "wait" });
      const result = await loadObjects2();
      this.setData({ status: "ready", result });
      this.setCurrentItemId(result[0]?.id ?? null);
      this.formStore.safeLoad(this.currentItem);
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  formStore: ModelessFormStore;

  data: RemoteData<TObject2[]> = { status: "none" };

  setData(newData: RemoteData<TObject2[]>) {
    this.data = newData;
  }

  currentItemId: number | null = null;

  setCurrentItemId(id: number | null) {
    this.currentItemId = id;
  }

  getItembyId(itemId: number): TObject2 | null {
    return this.itemsList.find(({ id }) => itemId === id) ?? null;
  }

  get currentItem(): TObject2 | null {
    const { currentItemId } = this;
    return currentItemId ? this.getItembyId(currentItemId) : null;
  }

  get itemsList(): TObject2[] {
    return this.data.status === "ready" ? this.data.result : [];
  }

  get selectedKeys(): string[] {
    const { currentItem } = this;
    return currentItem ? [String(currentItem.id)] : [];
  }

  safeSelectItem(key: React.Key) {
    const item = this.itemsList.find(({ id }) => String(id) === String(key));
    if (item) {
      this.formStore.safeLoad(item, () => {
        this.setCurrentItemId(item.id);
        this.internalRemoveItem(newItemId);
      });
    }
  }

  startRemoveCurrentItem() {
    this.setRemoveStatus("confirm");
  }

  removeCurrentItem() {
    if (this.currentItemId) {
      this.setRemoveStatus("wait");
      this.removeItem(this.currentItemId)
        .then(() => {
          this.formStore.unsafeLoad(null);
          this.setRemoveStatus("none");
        })
        .catch(onError);
    }
  }

  get canDelete(): boolean {
    return !!this.currentItemId;
  }

  removeStatus: "confirm" | "none" | "wait" = "none";

  setRemoveStatus(status: "confirm" | "none" | "wait") {
    this.removeStatus = status;
  }

  async removeItem(itemId: number) {
    if (itemId !== newItemId) {
      await removeObject2(itemId);
    }
    this.internalRemoveItem(itemId);
  }

  internalRemoveItem(itemId: number) {
    if (this.data.status === "ready") {
      const pos = this.data.result.findIndex(({ id }) => id === itemId);
      if (pos >= 0) this.data.result.splice(pos, 1);
    }
  }

  async saveItem(values: unknown): Promise<void> {
    const partialItem = values as TObject2; // Здесь хорошо бы контролировать
    const { currentItem } = this;
    if (currentItem) {
      const dstItem = { ...currentItem, ...partialItem };
      if (currentItem.id === newItemId) {
        const newItem = await createObject2(dstItem);
        this.updateItem(newItem, dstItem.id);
      } else {
        await updateObject2(dstItem);
        this.updateItem(dstItem, dstItem.id);
      }
    } else {
      throw Error("Current item is empty");
    }
  }

  updateItem(item: TObject2, oldId: number) {
    if (this.data.status === "ready") {
      const { result } = this.data;
      const pos = result.findIndex(({ id }) => id === oldId);
      if (pos >= 0) result[pos] = { ...item };
    }
  }

  safeCreateNewItem() {
    this.formStore.safeAction(() => {
      this.unsafeCreateNewItem();
    });
  }

  unsafeCreateNewItem() {
    if (this.data.status === "ready") {
      const newItem: TObject2 = {
        id: newItemId,
        type: undefined as unknown as number,
        name: "",
      };
      this.data.result.push(newItem);
      this.formStore.safeLoad(
        newItem,
        () => this.setCurrentItemId(newItem.id),
        true,
      );
    }
  }
}

// ---- back-end

const loadObjects2 = async (): Promise<TObject2[]> => {
  await delay(2000);
  return [...srcObjects];
};

const updateObject2 = async (obj: TObject2): Promise<void> => {
  await delay(2000);
  if (obj.name.includes("#")) throw Error("Invalid name");
  const pos = srcObjects.findIndex(({ id }) => id === obj.id);
  if (pos >= 0) {
    srcObjects[pos] = { ...obj };
  }
};

const createObject2 = async (item: Omit<TObject2, "id">): Promise<TObject2> => {
  await delay(2000);
  const newId = 1 + srcObjects.reduce((acc, { id }) => Math.max(acc, id), 0);
  const newItem = { ...item, id: newId };
  srcObjects.push(newItem);
  return newItem;
};

const removeObject2 = async (itemId: number) => {
  await delay(2000);
  const pos = srcObjects.findIndex(({ id }) => id === itemId);
  if (pos >= 0) srcObjects.splice(pos, 1);
};

const srcObjects: TObject2[] = [
  { id: 1, name: "Glycine", type: 1 },
  { id: 2, name: "Alanine", comment: "α-Aminopropionic acid", type: 2 },
  {
    id: 3,
    name: "Leucine",
    comment: "2-Amino-4-methylpentanoic acid",
    type: 3,
  },
  {
    id: 4,
    name: "Tyrosine",
    type: 4,
    comment: "L-2-Amino-3-(4-hydroxyphenyl)propanoic acid",
  },
];
