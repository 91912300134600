import { z } from "zod";
import { AttrTypeName } from "./AttrType";

export const zArticleNumberMetaObjRef = z.object({
  valueTypeName: z.literal(AttrTypeName.object),
  attributeId: z.number(),
  refAttributeId: z.number(),
});
export const zArticleNumberMeta = z.discriminatedUnion("valueTypeName", [
  zArticleNumberMetaObjRef,
]);

export const zArticleNumberInfoAttr = z.object({
  linePart: z.string(),
  attributeId: z.number(),
  quantitySymbols: z.number(),
  replaceLine: z.string(),
});
export const zArticleNumberInfo = z.object({
  maskLine: z.string(),
  groupBy: z.object({
    attrIds: z.number().array(),
    quantitySymbols: z.number(),
    counterStart: z.number(),
  }),
  attributes: zArticleNumberInfoAttr.array(),
  attributeMetas: zArticleNumberMeta.array().nullable(),
});
export type ZArticleNumberMetaObjRef = z.infer<typeof zArticleNumberMetaObjRef>;
export type ZArticleNumberMeta = z.infer<typeof zArticleNumberMeta>;
export type ZArticleNumberInfoAttr = z.infer<typeof zArticleNumberInfoAttr>;
export type ZArticleNumberInfo = z.infer<typeof zArticleNumberInfo>;
