import * as React from "react";
import { Select, SelectProps } from "antd";
import { onError } from "src/common/onError";

/* eslint react/jsx-props-no-spreading: "off" */
/* eslint react/require-default-props: "off" */

type PropsSelectRecordFromRef<TRecord> = Omit<
  SelectProps,
  "onChange" | "options" | "value"
> & {
  loader: () => Promise<TRecord[]>;
  valueKey: keyof TRecord;
  makeLabel: (record: TRecord) => React.ReactNode;
  value?: TRecord; // TODO: пока только для единичного выбора
  onChange?: (newValue?: TRecord) => void;
};

/**
 * TRecord - Значение в виде объекта. Н.р. {id:number, name:string}
 * @param props
 * @returns
 */
export const SelectRecordFromRef = <TRecord,>(
  props: PropsSelectRecordFromRef<TRecord>,
): React.ReactElement => {
  const { loader, valueKey, makeLabel, value, onChange, ...commonProps } =
    props;
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = React.useState<TRecord[]>([]);
  const [internalValue, setInternalValue] = React.useState<
    string | undefined
  >();

  const options = React.useMemo(
    () =>
      records.map((rec) => ({
        value: String(rec[valueKey]),
        label: makeLabel(rec),
      })),
    [records],
  );

  React.useEffect(() => {
    setLoading(true);
    loader()
      .then((result) => setRecords(result))
      .finally(() => setLoading(false))
      .catch(onError);
  }, []);

  React.useEffect(() => {
    const needValue = value ? String(value[valueKey]) : undefined;
    const res = needValue
      ? options.find((op) => op.value === needValue)
      : undefined;
    setInternalValue(res?.value);
  }, [value, options]);

  const onInternalChange = (v: string) => {
    const res = records.find((record) => String(record[valueKey]) === v);
    onChange?.(res);
  };

  return (
    <Select
      {...commonProps}
      options={options}
      loading={loading}
      value={internalValue}
      onChange={onInternalChange}
    />
  );
};
