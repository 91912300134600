import { Form, Input, InputNumber, Select } from "antd";
import React, { useEffect } from "react";
import {
  getArticleAttrCells,
  hasAttrArticleCounter,
  validateArticle,
} from "src/common/attrEdit/components";
import { ZAttribute } from "src/types/ZAttribute";
import { observer } from "mobx-react-lite";
import styles from "./ArticleTemplateInput.module.less";
import { attsName, gbName, maskLinePartName } from "./names";

interface Store {
  attsDict: Record<number, ZAttribute>;
  attrTypesMap: Record<number, string>;
}

type PropsArticleTemplateInput = {
  store: Store;
  objectId: number;
  baseName: string;
};

export const ArticleTemplateInput: React.FC<PropsArticleTemplateInput> =
  observer(({ store, baseName }) => {
    const attsOptions = Object.values(store.attsDict)
      .map((v) => ({
        label: v.name,
        value: v.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const maskLineName = maskLinePartName(baseName);
    const form = Form.useFormInstance();
    const safeVal = String(Form.useWatch(maskLineName) || "");
    const hasCounter = hasAttrArticleCounter(safeVal);
    const cells = getArticleAttrCells(safeVal);
    const uniqCells = Array.from(
      new Map(cells.map((c) => [c.toLocaleUpperCase(), c])).values(),
    );

    useEffect(() => {
      uniqCells.forEach((v, i) =>
        form.setFieldValue(attsName(baseName, i, "linePart"), v),
      );
    }, [uniqCells]);

    return (
      <div className={styles.box}>
        <Form.Item
          name={maskLineName}
          label="Шаблон артикула"
          rules={[{ required: true }, validateArticle()]}
        >
          <Input />
        </Form.Item>

        {uniqCells.map((c, i) => (
          <div key={c} className={styles.row}>
            <Form.Item
              label="Составляющая"
              name={attsName(baseName, i, "linePart")}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Атрибут"
              name={attsName(baseName, i, "attributeId")}
              rules={[{ required: true }]}
            >
              <Select
                options={attsOptions}
                showSearch
                optionFilterProp="label"
                allowClear
              />
            </Form.Item>
            <Form.Item
              label="Количество символов"
              name={attsName(baseName, i, "quantitySymbols")}
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
            <Form.Item
              label="Cтрока замены"
              name={attsName(baseName, i, "replaceLine")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
        ))}

        {hasCounter && (
          <div className={styles.row}>
            <Form.Item
              label="Группировать счетчик"
              name={gbName(baseName, "attrIds")}
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                maxTagCount="responsive"
                options={attsOptions}
              />
            </Form.Item>
            <Form.Item
              label="Количество символов"
              name={gbName(baseName, "quantitySymbols")}
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
            <Form.Item
              label="Начало отсчета"
              name={gbName(baseName, "counterStart")}
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </div>
        )}
      </div>
    );
  });
