import { t } from "i18next";
import { ZMCPoint, ZMCRow } from "../ZMeasurementChart";

export const makeMcVersionKey = (
  rowId: number,
  szName: string,
  verName: string,
) => `${rowId}:${szName}:${verName}`;

export const parseMcVersionKey = (key: string): [number, string, string] => {
  const [strRowId, szName, verName] = key.split(":");
  if (!strRowId || !szName || !verName)
    throw Error(t("Invalid version key", { key }));
  const rowId = +strRowId;
  if (!rowId) throw Error(t("Invalid row id in version key", { key }));
  return [rowId, szName, verName];
};

export const makeMcPointKey = (row: ZMCRow, field: keyof ZMCPoint) =>
  `${row.mcPoint.id}:${field}`;
