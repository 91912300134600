import { ZTranslationMap } from "src/types/ZTranslation";
import { ZBsRegSettings } from "./bsRegistration.types";

export type EdRegSettings = ZBsRegSettings & {
  successLoc?: ZTranslationMap;
};

export const bsRegSettings2Edit = (
  regSettings: ZBsRegSettings,
  successLoc: ZTranslationMap | undefined,
): EdRegSettings => ({
  ...regSettings,
  successLoc,
});

export const edit2BsRegSettings = (
  edit: EdRegSettings,
): {
  regSettings: ZBsRegSettings;
  successLoc: ZTranslationMap | undefined;
} => {
  const { successLoc, ...regSettings } = edit;
  return { regSettings, successLoc };
};
