import { Button, Form, Input, InputRef, Modal, Space } from "antd";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { modalSupportStore as store } from "./ModalSupportStore";
import { PlmUploader } from "../PlmUploader";
import { ZSupportFields } from "./ModalSupport.types";
import styles from "./ModalSupport.module.less";

const { TextArea } = Input;

export const ModalSupport: React.FC = observer(() => {
  const [form] = Form.useForm<ZSupportFields>();
  const open = store.supportState !== "none";
  const work = store.supportState === "work";
  const success = store.supportState === "success";

  const ref1 = React.useRef<InputRef>(null);
  React.useEffect(() => {
    if (open) setTimeout(() => ref1.current?.focus(), 20);
  }, [open]);

  const [submittable, setSubmittable] = React.useState<boolean>(false);
  const formValues = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, formValues]);

  return (
    <Modal
      title={
        !success
          ? t("Contacting tech support")
          : t("Appeal to technical support is registered")
      }
      open={open}
      footer={null}
      onCancel={() => {
        if (success) form.resetFields();
        store.onCancel();
      }}
      className={styles.modal}
    >
      {!success ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={store.initialValues}
          onFinish={(values) => {
            store.onSubmit(values);
          }}
          size="middle"
          className={styles.form}
          disabled={work}
        >
          <Form.Item name="subject" label={t("Subject")}>
            <Input ref={ref1} allowClear placeholder={t("Specify the topic")} />
          </Form.Item>
          <Form.Item
            name="question"
            label={t("Question")}
            rules={[{ required: true }]}
          >
            <TextArea placeholder={t("Describe the question")} />
          </Form.Item>
          <Form.Item name="attachedFiles" label={t("Attached files")}>
            <PlmUploader isImage={false} maxCount={5} maxSize={10} multiple />
          </Form.Item>
          <Form.Item className={styles.buttonsBlock}>
            <Space>
              <Button
                htmlType="button"
                onClick={() => store.onCancel()}
                disabled={work}
              >
                {t("Cancel")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!submittable}
                loading={work}
              >
                {t("Submit")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <div className={styles.successContent}>
          <div>
            {t(
              "Your request has been registered by the technical support service",
            )}
          </div>
          <Button
            className={styles.closeButton}
            htmlType="button"
            onClick={() => {
              form.resetFields();
              store.onCancel();
            }}
          >
            {t("Close")}
          </Button>
        </div>
      )}
    </Modal>
  );
});
