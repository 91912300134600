import { Button, Form, Tooltip } from "antd";
import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { edAttrField } from "../../EdAttribute";
import { EdObjStates } from "../../EdObject";
import { PermissionsCopyType, ZPermissions } from "../roleTypes";
import { Obj2TabStore } from "../../Obj2TabStore";

interface PropsCopyOnStatesButton {
  stateName: string;
  stateId: number;
  copyType: PermissionsCopyType;
  store: Obj2TabStore;
}

export const CopyOnStatesButton: React.FC<PropsCopyOnStatesButton> = observer(
  ({ stateName, stateId, copyType, store }) => {
    const form = Form.useFormInstance();
    const rolesMap: EdObjStates | undefined = Form.useWatch(
      edAttrField.rolesMap,
    );
    const curPermissonsItem: ZPermissions | undefined = Form.useWatch([
      edAttrField.rolesMap,
      String(stateId),
    ]);
    if (!rolesMap || !curPermissonsItem) return null;

    const copyOnStates = () => {
      const newValue = Object.fromEntries(
        Object.entries(rolesMap).map(([key]) => [key, curPermissonsItem]),
      );
      form.setFieldValue(edAttrField.rolesMap, newValue);
      store.formStore.setFormStatus("changed");
    };

    return (
      <Tooltip
        title={`Скопировать права из состояния ${stateName} на все состояния ${copyType === PermissionsCopyType.attribute ? "атрибута" : "объекта"}`}
      >
        <Button
          size="small"
          type="text"
          icon={<CopyOutlined />}
          onClick={(event) => {
            event.stopPropagation();
            copyOnStates();
          }}
        />
      </Tooltip>
    );
  },
);
