import * as React from "react";
import {
  CalculatorOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentFilled,
  EnvironmentOutlined,
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FileImageOutlined,
  FileOutlined,
  FunctionOutlined,
  GlobalOutlined,
  LinkOutlined,
  TagOutlined,
  TagsOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import styles from "./typeIcons.module.less";

const icons = new Map<number, React.ReactNode>();

export const getTypeIcon = (typeId: number): React.ReactNode =>
  icons.get(typeId) ?? <EnvironmentOutlined />;

export const getAttrTypeIcon = (attr: ZAttribute): React.ReactNode => {
  const icon = getTypeIcon(attr.valueType);
  // Здесь имеет место довольно забавная ситуация.
  // Если использовать <span style={{color: "blue"}}... то всё падает при попытке выполнить node.icon = getAttrTypeIcon()
  // Потому что такое значение MobX принять не может.
  // Но если использовать className вместо style, то работает.
  return attr.systemAttributeType ? (
    <span className={styles.systemAttr}>{icon}</span>
  ) : (
    icon
  );
};

export const initTypeIcons = (typeNames: Record<number, string>) => {
  const iconByName: Partial<Record<AttrTypeName, React.ReactNode>> = {
    [AttrTypeName.dictSingle]: <TagOutlined />,
    [AttrTypeName.dictMulti]: <TagsOutlined />,
    [AttrTypeName.string]: <FieldStringOutlined />,
    [AttrTypeName.number]: <FieldBinaryOutlined />,
    [AttrTypeName.int]: <FieldNumberOutlined />,
    [AttrTypeName.date]: <CalendarOutlined />,
    [AttrTypeName.dateTime]: <CalendarOutlined />,
    [AttrTypeName.file]: <FileOutlined />,
    [AttrTypeName.image]: <FileImageOutlined />,
    [AttrTypeName.time]: <ClockCircleOutlined />,
    [AttrTypeName.boolean]: <CalculatorOutlined />,
    [AttrTypeName.childEntities]: <EnvironmentFilled />,
    [AttrTypeName.usersList]: <UsergroupAddOutlined />,
    [AttrTypeName.externalLink]: <LinkOutlined />,
    [AttrTypeName.linkedValue]: <LinkOutlined />,
    [AttrTypeName.formula]: <FunctionOutlined />,
    [AttrTypeName.contentLanguage]: <GlobalOutlined />,
    [AttrTypeName.article]: <TagOutlined />,
  };
  Object.entries(typeNames).forEach(([typeId, name]) => {
    const icon = iconByName[name as AttrTypeName];
    if (icon) icons.set(+typeId, icon);
  });
};
