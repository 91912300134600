import { makeAutoObservable } from "mobx";
import { TreeStore } from "./CateroryTree";
import { CategoryAttributesStore } from "./CategoryAttributes";

export const categoryRefStore = makeAutoObservable({
  treeStore: new TreeStore(),
  attrStore: new CategoryAttributesStore(),
  init() {
    this.treeStore.init();
  },
});
