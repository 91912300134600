import { zTranslationMap } from "src/types/ZTranslation";
import { z } from "zod";

export const defaultDashboardHeight = "900px";

export const zDashboardItem = z.object({
  itemKey: z.number(),
  url: z.string(),
  height: z.string().nullable().optional(), // В формате CSS. Если не указан, то defaultDashboardHeight
});
export type ZDashboardItem = z.infer<typeof zDashboardItem>;

export const zDashboardGroup = z.object({
  groupKey: z.number(),
  name: z.string(),
  nameLoc: zTranslationMap,
  roleIds: z.number().array(),
  items: zDashboardItem.array(),
});
export type ZDashboardGroup = z.infer<typeof zDashboardGroup>;

export const getDashboardCssHeight = (
  height: ZDashboardItem["height"],
): string => {
  if (!height) return defaultDashboardHeight;
  if (/^\d+$/.test(height)) return `${height}px`;
  return height;
};
