import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { rest } from "src/common/rest";
import { onError } from "src/common/onError";
import { apiConfigUrl, apiExtRegUrl } from "src/common/apiUrl";
import { ZExtraSettings, zExtraSettings } from "src/types/ZExtraSettings";
import { appStore } from "src/appStore";
import {
  zBsRegSettings,
  ZBsRegSettings,
} from "src/businessServices/services/bsRegistration/bsRegistration.types";
import { SelectProps } from "antd";

export class ExtraSettingsTabStore {
  constructor() {
    makeAutoObservable(this);
  }

  data: RemoteData<ZExtraSettings> = { status: "none" };

  setData(newData: RemoteData<ZExtraSettings>) {
    this.data = newData;
  }

  isLoading = false;

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  extRegData: ZBsRegSettings[] = [];

  setExtRegData(data: ZBsRegSettings[]) {
    this.extRegData = data;
  }

  get extRegOptions(): SelectProps["options"] {
    return this.extRegData.map(({ url }) => ({ label: `/${url}`, value: url }));
  }

  get initialValues(): ZExtraSettings | {} {
    return this.data.status === "ready" ? this.data.result : {};
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      const [extraSettings, regData] = await Promise.all([
        rest.get(apiConfigUrl("/ui-settings")),
        rest.get(apiExtRegUrl("/settings")),
      ]);
      this.setData({
        status: "ready",
        result: zExtraSettings.parse(extraSettings.data),
      });
      this.setExtRegData(zBsRegSettings.array().parse(regData.data));
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  async save(values: unknown) {
    try {
      this.setIsLoading(true);
      const validValues = zExtraSettings.parse(values);
      await rest.post(apiConfigUrl("/ui-settings"), validValues);
      this.setData({ status: "ready", result: validValues });
      appStore.setExtraSettings(validValues);
    } catch (error) {
      onError(error);
    } finally {
      this.setIsLoading(false);
    }
  }
}
