import * as React from "react";
import { onAntFormError } from "src/common/onAntFormError";
import { Modal, Button, Checkbox, Form, Input, Space } from "antd";
import { ZTreeCategoryNode } from "src/types/ZTreeCategoryNode";
import { appStore } from "src/appStore";
import { CatNode, TreeStore } from "../TreeStore";
import { CategoryTreeSelect } from "../CategoryTreeSelect";

interface PropsEditCategoryModal {
  open: boolean;
  store: TreeStore;
  close(): void;
}

interface EditCatSubmitParams {
  name: string;
  notSelectable: boolean;
  moveTo?: string;
}

const toEdit = (node: CatNode): EditCatSubmitParams => ({
  name: node.data.name,
  notSelectable: !node.data.selectable,
});

const fromEdit = (
  values: EditCatSubmitParams,
  node: CatNode,
): ZTreeCategoryNode => {
  const res = {
    ...node.data,
    name: values.name,
    selectable: !values.notSelectable,
  };
  if (values.moveTo) {
    res.parentId = +values.moveTo;
  }
  return res;
};

const formName = "EditCategory";

export const EditCategoryModal: React.FC<PropsEditCategoryModal> = (props) => {
  const { open, close, store } = props;
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (open) {
      form.resetFields();
      const { current } = store;
      if (current) form.setFieldsValue(toEdit(current));
    }
  }, [open]);
  const { current } = store;
  const [wait, setWait] = React.useState(false);
  const submit = (values: EditCatSubmitParams) => {
    setWait(true);
    store
      .editCategory(fromEdit(values, store.current!))
      .finally(() => setWait(false))
      .then(() => close())
      .catch((e) => onAntFormError(e, formName));
  };
  if (!current) return null;
  return (
    <Modal
      open={open}
      onCancel={close}
      destroyOnClose
      title="Редактирование категории"
      centered
      footer={null}
    >
      <Form<EditCatSubmitParams>
        form={form}
        layout="vertical"
        onFinish={submit}
        name="EditCategory"
      >
        <Form.Item
          label="Название категории"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="notSelectable" valuePropName="checked">
          <Checkbox>Недоступна для выбора</Checkbox>
        </Form.Item>
        <Form.Item name="moveTo" label="Перенести в другую категорияю">
          <CategoryTreeSelect
            treeData={store.treeData}
            allowClear
            companyId={appStore.currentCompanyId}
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button htmlType="submit" type="primary" loading={wait}>
              Сохранить
            </Button>
            <Button htmlType="button" onClick={close}>
              Отмена
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
