import {
  FormBlockItemExtParams,
  ItemProps,
  blockItem,
} from "src/components/FormWithBlocks";
import { InputNumberStd } from "src/components/InputNumberStd";
import { InputNumberProps } from "antd/lib/input-number";
import { ruleMin } from "src/common/validationRules/ruleMin";

export const stdNumber = (
  name: string,
  label: string,
  srcItemProps: ItemProps,
  compProps?: InputNumberProps,
  extParams?: FormBlockItemExtParams,
) => {
  let itemProps: ItemProps = srcItemProps;
  if (
    compProps &&
    typeof compProps.min === "number" &&
    typeof itemProps === "object"
  ) {
    const { rules } = itemProps;
    if (!rules?.find((rule) => "min" in rule)) {
      itemProps = {
        ...srcItemProps,
        rules: [...(rules || []), ruleMin(compProps.min)],
      };
    }
  }
  return blockItem(
    name,
    label,
    InputNumberStd,
    { style: { width: "100%" }, ...compProps },
    itemProps,
    extParams,
  );
};
