/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { ColumnsSettings } from "src/components/tables/ColumnsSettings";
import { AsyncTableView } from "../AsyncTableView";
import { ZEntityFilters, ZEntityRow } from "./types";
import styles from "./EntityList.module.less";
import { EntityFiltersPageStore } from "../EntityFiltersPageStore";
import { InputSearch } from "./InputSearch";
import { EntityActions } from "./EntityActions";

interface PropsEntityList {
  store: EntityFiltersPageStore;
  onRowClick?(row: ZEntityRow, index?: number): void;
}

export const EntityList: React.FC<PropsEntityList> = observer(
  ({ store, store: { tableStore }, onRowClick }) => {
    if (!tableStore) return null;
    return (
      <div className={styles.content}>
        <AsyncTableView<ZEntityRow, ZEntityFilters>
          headerExtra={
            <div className={styles.header}>
              <div className={styles.headerInner}>
                <InputSearch store={tableStore} />
                <Space>
                  <ColumnsSettings store={tableStore} />
                  <EntityActions store={store} />
                  {store.canCreate && (
                    <Link
                      to={makeUrl(PageUrl.entityNew, {
                        objectId: store.currObjId,
                      })}
                    >
                      <Button type="primary" size="large">
                        <PlusCircleOutlined /> {store.currObjName}
                      </Button>
                    </Link>
                  )}
                </Space>
              </div>
            </div>
          }
          onRowClick={onRowClick}
          store={tableStore}
          columns={tableStore.columns}
          useHeader
          usePagination
          useSelection
        />
      </div>
    );
  },
);
