/* eslint-disable react/jsx-props-no-spreading */
import { Tree, TreeProps } from "antd";
import React from "react";
import { observer } from "mobx-react-lite";
import { TreeLikeData } from "src/types/TreeLikeData";
import { TreeStdStore } from "./TreeStdStore";

export type PropsTreeStd<TData extends {}> = Omit<
  TreeProps<TData>,
  "treeData"
> & {
  store: TreeStdStore<TData>;
};

export const TreeStd = observer(
  <TData extends {}>({ store, ...props }: PropsTreeStd<TData>) => (
    <Tree<TreeLikeData<TData>> treeData={store.treeData} {...props} />
  ),
);
