import { ZAttrViewInfo } from "src/common/attrView";
import { ZAttribute } from "src/types/ZAttribute";
import { z } from "zod";

export const zObjectRefTable = z.object({
  editor: z.literal("ObjectRefTable"),
  multiple: z.boolean().optional(),
  labelAtts: z.string().array().optional().nullable(),
  isCreateButtonAvailable: z.boolean().optional(), // показать или нет унопку создать в моделях
});
export type ZObjectRefTable = z.infer<typeof zObjectRefTable>;

export const makeObjectRefTableProps = (
  attrInfo: ZAttribute,
  component: ZObjectRefTable,
  viewInfo: ZAttrViewInfo | undefined,
) => ({
  attrInfo,
  component,
  viewInfo,
});
