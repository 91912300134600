import axios from "axios";
import { apiFilesUrl } from "src/common/apiUrl";

export const imgSrc = (uid: string) => urlForDownload(true, uid);

export const urlForDownload = (isImage: boolean, uid: string): string =>
  axios.getUri({
    url: apiFilesUrl(""),
    params: {
      filePath: uid,
      fileType: isImage ? "IMAGE" : undefined,
    },
  });
