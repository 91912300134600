import * as React from "react";
import { Button } from "antd";
import iconArrow from "./arrow.svg";

interface PropsExpandButton {
  collapsed: boolean;
  onClick(): void;
}

/**
 * Элемент открытия/закрытия в виде стрелки > (закрыто) или v (открыто)
 */
export const ExpandButton: React.FC<PropsExpandButton> = ({
  collapsed,
  onClick,
}) => {
  const iconStyle: React.CSSProperties = {
    transition: "transform 0.2s ease-in-out",
    transform: collapsed ? "rotate(-90deg)" : undefined,
  };
  const icon = <img src={iconArrow} style={iconStyle} alt="" />;
  return <Button size="small" type="text" icon={icon} onClick={onClick} />;
};
