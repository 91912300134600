import * as React from "react";
import { classNames } from "src/common/classNames";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./SlideMenu.module.less";

export type PropsSlideMenu = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  headerExtra?: React.ReactNode;
  footer?: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
};

export const SlideMenu: React.FC<PropsSlideMenu> = ({
  children,
  title,
  headerExtra,
  open,
  footer,
  onClose,
}: PropsSlideMenu) => (
  <div className={classNames([[!!open, styles.open], styles.menu])}>
    <div className={styles.header}>
      <div className={styles.headerInner}>
        {title}
        <CloseOutlined
          onClick={() => {
            onClose?.();
          }}
        />
      </div>
      {headerExtra}
    </div>
    <div className={styles.content}>{children}</div>
    <div className={styles.footer}>{footer}</div>
  </div>
);

SlideMenu.defaultProps = {
  children: undefined,
  title: undefined,
  open: false,
  onClose: undefined,
  footer: undefined,
  headerExtra: undefined,
};
