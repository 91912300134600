import { Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { composeEntityLabel } from "src/common/entity/composeEntityLabel/composeEntityLabel";
import { onError } from "src/common/onError";
import { getEntityCachedRequest } from "src/common/entity/getEntityCachedRequest";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { SimpleText } from "../SimpleText/SimpleText";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import styles from "./ObjectRef.module.less";
import { seedSeparators } from "../../viewFormItems/Separator";
import { ZModeObjectRef, ZObectRef } from "../../componentsDefs";

type PropsObjectRef = {
  objectId: number | null;
  labelAtts: string[];
  viewProps: ZObectRef | undefined;
  values?: string[];
  viewInfo?: ZAttrViewInfo;
};

const createLabel = (
  viewProps: ZObectRef | undefined,
  content: React.JSX.Element | React.ReactNode[],
  entityId: string,
  viewInfo?: ZAttrViewInfo,
) => {
  const lablelMap: Partial<Record<ZModeObjectRef, React.ReactNode>> = {
    link: (
      <a
        href={makeUrl(PageUrl.entityCard, { id: entityId })}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
        rel="noreferrer"
        style={{ width: "100%" }}
      >
        {content}
      </a>
    ),
    tag: (
      <Tag
        color={viewProps?.modeMeta?.tagColor}
        style={{ ...viewInfo?.styles, maxWidth: "100%" }}
        className={styles[viewInfo?.appearance?.view?.overflow || ""]}
      >
        {content}
      </Tag>
    ),
    standart: content,
  };
  const mode = viewProps?.mode;
  return mode ? lablelMap[mode] : lablelMap.standart;
};

export const ObjectRef: React.FC<PropsObjectRef> = ({
  objectId,
  labelAtts,
  values,
  viewInfo,
  viewProps,
}) => {
  const [labelList, setLabelList] = useState<React.ReactNode[]>([]);
  const [loading, setLoading] = useState(false);
  const init = async () => {
    try {
      const entityIdList = values || [];
      if (!entityIdList || !viewInfo || !objectId) return;
      setLoading(true);

      const result = await Promise.all(
        entityIdList.map(async (id) => {
          const refEnt = await getEntityCachedRequest(Number(id));
          const labelsResp = await composeEntityLabel(
            refEnt,
            labelAtts || [],
            viewInfo,
          );
          return { labelsResp, id };
        }),
      );
      const labelDefResult = result.map(({ labelsResp, id }) => ({
        content: createLabel(viewProps, labelsResp, id, viewInfo),
        key: id,
      }));
      setLabelList(seedSeparators(labelDefResult, "newLine"));
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return loading ? (
    <Spin spinning={loading}>...</Spin>
  ) : (
    <SimpleText className={styles.box} values={labelList} viewInfo={viewInfo} />
  );
};

ObjectRef.defaultProps = { viewInfo: undefined, values: undefined };
