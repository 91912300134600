import { Input, InputProps } from "antd";
import { blockItem } from "../blockItem";
import { ItemProps } from "../FormWithBlocks.types";

export const stdInput = (
  name: string,
  label: string,
  itemProps: ItemProps,
  compProps?: InputProps,
) =>
  blockItem(name, label, Input, { allowClear: true, ...compProps }, itemProps);
