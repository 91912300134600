// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__common--ILsaY {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: "top top" "tools tools" "left main";
}
.src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__top--bY2qc {
  grid-area: top;
  background-color: #EEE;
}
.src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__tools--o4c9o {
  grid-area: tools;
}
.src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__left--E9ppP {
  width: 18vw;
  min-width: 200px;
  grid-area: left;
  background-color: bisque;
  overflow: auto;
}
.src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__main--oHxPJ {
  grid-area: main;
  background-color: white;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ModelConstructorPage/ModelEditor/ModelEditor.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,+BAAA;EACA,iCAAA;EACA,wDAAA;AACJ;AAIA;EACI,cAAA;EACA,sBAAA;AAFJ;AAIA;EACI,gBAAA;AAFJ;AAIA;EACI,WAAA;EACA,gBAAA;EACA,eAAA;EACA,wBAAA;EACA,cAAA;AAFJ;AAIA;EACI,eAAA;EACA,uBAAA;EACA,cAAA;AAFJ","sourcesContent":[".common {\n    width: 100%;\n    height: 100%;\n    display: grid;\n    grid-template-columns: auto 1fr;\n    grid-template-rows: auto auto 1fr;\n    grid-template-areas: \n    \"top top\" \n    \"tools tools\"\n    \"left main\";\n}\n.top {\n    grid-area: top;\n    background-color: #EEE;\n}\n.tools {\n    grid-area: tools;\n}\n.left {\n    width: 18vw;\n    min-width: 200px;\n    grid-area: left;\n    background-color: bisque;\n    overflow: auto;\n}\n.main {\n    grid-area: main;\n    background-color: white;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": `src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__common--ILsaY`,
	"top": `src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__top--bY2qc`,
	"tools": `src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__tools--o4c9o`,
	"left": `src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__left--E9ppP`,
	"main": `src-pages-ModelConstructorPage-ModelEditor-ModelEditor-module__main--oHxPJ`
};
export default ___CSS_LOADER_EXPORT___;
