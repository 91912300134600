export const makeDictionary = <
  T extends {},
  Key extends number | string = string,
>(
  list: T[],
  makeKey: (item: T) => Key,
): Record<Key, T> => {
  const dict = {} as Record<Key, T>;
  list.forEach((item) => {
    const key = makeKey(item);
    dict[key] = item;
  });
  return dict;
};
