import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Space } from "antd";
import { ZCatAttr } from "src/types/ZCatAttr";
import { t } from "i18next";
import { AttrLevelList } from "./AttrLevelList";
import { AddAttrModal } from "./modals/AddAttrModal";
import { CategoryAttributesStore } from "../CategoryAttributesStore";
import { DeleteAttrModal } from "./modals/DeleteAttrModal";
import { EditAttrModal } from "./modals/EditAttrModal";

/**
 * Блок просмотра/редактирования атрибутов для категории
 */

interface PropsAttrLevelPanel {
  attrs: ZCatAttr[];
  typeLabels: Record<string, string>;
  editable: boolean;
  attrStore: CategoryAttributesStore;
}

export const AttrLevelPanel: React.FC<PropsAttrLevelPanel> = observer(
  (props: PropsAttrLevelPanel) => {
    const { attrs, typeLabels, editable, attrStore } = props;
    type ModalMode = "add" | "delete" | "edit" | null;
    const [mode, setMode] = React.useState<ModalMode>(null);
    const close = () => setMode(null);

    const permissionWrite = true; // hasPermission("ATTRIBUTE_WRITE");

    const permissionDraftDelete = true; // hasPermission("ATTRIBUTE_DELETE");
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <AttrLevelList
          attrStore={attrStore}
          attrs={attrs}
          typeLabels={typeLabels}
          editable={editable}
        />
        {editable && (
          <Space style={{ marginTop: 10 }}>
            {permissionWrite && (
              <>
                <Button
                  type="primary"
                  disabled={!attrStore.canAdd}
                  onClick={() => setMode("add")}
                >
                  Добавить
                </Button>
                <Button
                  disabled={!attrStore.canEdit}
                  onClick={() => setMode("edit")}
                >
                  Редактировать
                </Button>
              </>
            )}
            {permissionDraftDelete && (
              <Button
                disabled={!attrStore.canDelete}
                onClick={() => setMode("delete")}
              >
                {t("Delete")}
              </Button>
            )}
          </Space>
        )}

        <AddAttrModal
          open={mode === "add"}
          close={close}
          attrStore={attrStore}
        />
        <EditAttrModal
          open={mode === "edit"}
          close={close}
          attrStore={attrStore}
        />
        <DeleteAttrModal
          open={mode === "delete"}
          close={close}
          attrStore={attrStore}
        />
      </div>
    );
  },
);
