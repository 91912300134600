import { Form, Radio } from "antd";
import React from "react";
import { z } from "zod";
import { obj2LabelValue } from "../obj2LabelValue";

export const zOverflow = z.enum(["elipsis", "breakLine"]);
export type ZOverflow = z.infer<typeof zOverflow>;

const labelMap: Record<ZOverflow, string> = {
  breakLine: "Перенос",
  elipsis: "Многоточие",
};

const options = obj2LabelValue(labelMap);

type PropsOverflow = { name: string[] };
export const Overflow: React.FC<PropsOverflow> = ({ name }) => (
  <Form.Item label="Перенос содержимого ячейки" name={name}>
    <Radio.Group>
      {options.map(({ label, value }) => (
        <Radio.Button key={value} value={value}>
          {label}
        </Radio.Button>
      ))}
    </Radio.Group>
  </Form.Item>
);
