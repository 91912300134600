import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { ZSampleSettings } from "../../SampleTypes";
import { SampleObjType } from "../SampleSettingsStore";

interface PropsCommentsSection {
  store: FormObjectSectionsStore<SampleObjType>;
}

export const CommentsSection: React.FC<PropsCommentsSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZSampleSettings, SampleObjType>
      store={store}
      objKey={["objSampleComments"]}
      objType="comments"
      objLabel="Sample comments object"
      title="Sample comments"
      attrs={[
        {
          attrKey: ["sampleCommentsSampleAttrId"],
          attrLabel: "Sample",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sampleCommentsImageAttrId"],
          attrLabel: "Image",
          attrType: AttrTypeName.image,
        },
        {
          attrKey: ["sampleCommentsCommentsAttrId"],
          attrLabel: "Comment",
          attrType: AttrTypeName.string,
        },
      ]}
    />
  ),
);
