import { FormRule } from "antd";
import { ZAttribute } from "src/types/ZAttribute";
import { AttributeO2, CommonNodeO2 } from "../../Obj2Nodes";
import { findNodeOwnerObject } from "../../utils/findNodeOwnerObject";

export const validatorOfId: FormRule = {
  pattern: /^[A-Z][A-Z\d]*$/i,
  message: "Недопустимые символы",
};

export const validatorUnique = (
  objects: CommonNodeO2[],
  attrNode: AttributeO2,
  test: (a: ZAttribute, value: unknown) => string | null,
): FormRule => ({
  validator: (_rule, value) => {
    if (value) {
      const obj = findNodeOwnerObject(attrNode.key, objects);
      if (obj) {
        const res = deepFind<string>([obj], (node) => {
          if (node.type !== "attr") return null;
          if (node.attr.id === attrNode.attr.id) return null;
          return test(node.attr, value);
        });
        if (res) return Promise.reject(Error(res));
      }
    }
    return Promise.resolve();
  },
});

const deepFind = <Res>(
  list: CommonNodeO2[],
  test: (node: CommonNodeO2) => Res | null,
): Res | null => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of list) {
    const res = test(node);
    if (res !== null) return res;
    if (node.children) {
      const res2 = deepFind(node.children, test);
      if (res2 !== null) return res2;
    }
  }
  return null;
};
