// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__item--ULqVo {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 2px 0px #00000014;
}
.src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__clickable--BhhEP:hover {
  background-color: #ececec;
}
.src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__header--iozmf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #00000014;
}
.src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__headerItem--rDttQ {
  padding: 16px 24px;
  border-left: 1px solid #00000014;
}
.src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__headerItem--rDttQ:first-child {
  flex-grow: 1;
  border-left: none;
}
.src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__headerItemTitle--B4f2B {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/atvTemplatesGrid/ATVRowGrid/ATVRowGrid.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,qCAAA;AACF;AAGE;EACE,yBAAA;AADJ;AAKA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,qCAAA;AAHF;AAKA;EACE,kBAAA;EACA,gCAAA;AAHF;AAIE;EACE,YAAA;EACA,iBAAA;AAFJ;AAKA;EACE,aAAA;EACA,8CAAA;EACA,mBAAA;EACA,gBAAA;AAHF","sourcesContent":[".item {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  box-shadow: 0px 1px 2px 0px #00000014;\n}\n\n.clickable {\n  &:hover {\n    background-color: rgb(236, 236, 236);\n  }\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  box-shadow: 0px 1px 2px 0px #00000014;\n}\n.headerItem {\n  padding: 16px 24px;\n  border-left: 1px solid #00000014;\n  &:first-child {\n    flex-grow: 1;\n    border-left: none;\n  }\n}\n.headerItemTitle {\n  display: grid;\n  grid-template-columns: max-content max-content;\n  align-items: center;\n  column-gap: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__item--ULqVo`,
	"clickable": `src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__clickable--BhhEP`,
	"header": `src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__header--iozmf`,
	"headerItem": `src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__headerItem--rDttQ`,
	"headerItemTitle": `src-pages-EntityFiltersPage-atvTemplatesGrid-ATVRowGrid-ATVRowGrid-module__headerItemTitle--B4f2B`
};
export default ___CSS_LOADER_EXPORT___;
