// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-tables-KeepSelected-SingleSelection-SingleSelection-module__container--ek02H {
  min-height: 32px;
}
.src-components-tables-KeepSelected-SingleSelection-SingleSelection-module__container--ek02H .ant-tag {
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;
}
.src-components-tables-KeepSelected-SingleSelection-SingleSelection-module__container--ek02H .ant-tag .ant-tag-close-icon {
  margin-inline-start: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/KeepSelected/SingleSelection/SingleSelection.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAFA;EAGI,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AARA;EAQM,wBAAA;AAGN","sourcesContent":[".container {\n  min-height: 32px;\n  :global(.ant-tag) {\n    font-size: 14px;\n    line-height: 30px;\n    display: flex;\n    align-items: center;\n    :global(.ant-tag-close-icon) {\n      margin-inline-start: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-tables-KeepSelected-SingleSelection-SingleSelection-module__container--ek02H`
};
export default ___CSS_LOADER_EXPORT___;
