// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MCInfoItem-module__infoItem--ncCxW {
  flex: 1;
  border: thin solid #E2E2E2;
  border-radius: 4px;
  padding: 4px 10px;
  color: #767C84;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MCInfoItem-module__infoItem--ncCxW span {
  flex: 1;
  color: #343A41;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/MCInfoItem.module.less"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,0BAAA;EACA,kBAAA;EACA,iBAAA;EAEA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,aAAA;EACA,mBAAA;EACA,SAAA;AADJ;AAbA;EAiBQ,OAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADR","sourcesContent":[".infoItem {\n    flex: 1;\n    border: thin solid #E2E2E2;\n    border-radius: 4px;\n    padding: 4px 10px;\n\n    color: #767C84;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%; \n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    span {\n        flex: 1;\n        color: #343A41;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 150%;         \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoItem": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MCInfoItem-module__infoItem--ncCxW`
};
export default ___CSS_LOADER_EXPORT___;
