import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, Select } from "antd";
import { filterOptionByName } from "src/common/filterOptionByName";
import { t } from "i18next";
import { NewMeasurementChartEntityData } from "../../../ZMeasurementChart";
import styles from "./NewMCEntityCreate.module.less";
import { MChartEntityStore } from "../../MChartEntityStore";

interface PropsNewMCEntityCreate {
  store: MChartEntityStore;
}

const fkey = (key: keyof NewMeasurementChartEntityData) => key;

export const NewMCEntityCreate: React.FC<PropsNewMCEntityCreate> = observer(
  ({ store }) => {
    const [form] = Form.useForm();
    const sizeId: number | null = Form.useWatch(fkey("sizeLineEntityId"), form);
    React.useEffect(() => {
      store.updateBaseSizeOptions(sizeId);
      form.resetFields([fkey("baseSizeEntityId")]);
    }, [sizeId]);
    return (
      <Form<NewMeasurementChartEntityData>
        form={form}
        className={styles.form}
        layout="vertical"
        onFinish={(values) => store.create(values)}
      >
        <Form.Item
          label={t("Size line")}
          name={fkey("sizeLineEntityId")}
          rules={[{ required: true }]}
        >
          <Select
            options={store.sizeLineOptions}
            allowClear
            showSearch
            filterOption={filterOptionByName}
          />
        </Form.Item>
        <Form.Item
          label={t("Base size")}
          name={fkey("baseSizeEntityId")}
          rules={[{ required: true }]}
        >
          <Select
            options={store.baseSizeOptions}
            allowClear
            showSearch
            filterOption={filterOptionByName}
            loading={store.loadingBaseSize}
          />
        </Form.Item>
        <Form.Item label={t("Name")} name={fkey("mcName")}>
          <Input allowClear />
        </Form.Item>
        <Form.Item label={t("Comment")} name={fkey("comment")}>
          <Input allowClear />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={store.buzy === "create"}
          >
            {t("Create")}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);
