import { z } from "zod";

export const zChatInfo = z.object({
  id: z.string(),
  name: z.string(),
  userIds: z.string().array(),
  ownerUserId: z.string(),
});

export type ZChatInfo = z.infer<typeof zChatInfo>;

export type RCNewChatFormData = {
  name: string;
  userIds: string[];
};
