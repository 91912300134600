import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tree as AntTree } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import RcTree from "rc-tree";
import { Obj2TabStore } from "./Obj2TabStore";
import { CommonNodeO2 } from "./Obj2Nodes";

interface PropsTreeViewO2 {
  store: Obj2TabStore;
}

export const TreeViewO2: React.FC<PropsTreeViewO2> = observer(({ store }) => {
  const ref = React.createRef<RcTree>();
  React.useEffect(() => {
    store.setTreeRef(ref.current);
    return () => {
      store.setTreeRef(null);
    };
  }, [ref.current]);
  const { data } = store;
  if (data.status !== "ready") return null;
  return (
    <AntTree<CommonNodeO2>
      ref={ref}
      className="idObjectsTree"
      treeData={data.result}
      showIcon
      selectedKeys={store.selectedKeys}
      onSelect={(keys) => store.safeSelect(keys[0] ?? null)}
      expandedKeys={store.expandedKeys}
      onExpand={(_keys, { node, expanded }) => {
        store.expand(node.key, expanded);
      }}
    />
  );
});
