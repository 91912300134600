// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsScheduling-SchedulingSettings-SchedulingSettings-module__tabs--BZbLr .ant-tabs-nav {
  padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsScheduling/SchedulingSettings/SchedulingSettings.module.less"],"names":[],"mappings":"AAAA;EAEI,qBAAA;AAAJ","sourcesContent":[".tabs {\n  :global(.ant-tabs-nav) {\n    padding: 0 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `src-businessServices-services-bsScheduling-SchedulingSettings-SchedulingSettings-module__tabs--BZbLr`
};
export default ___CSS_LOADER_EXPORT___;
