// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityCardPage-module__withLeftPad--IE6pb {
  padding-left: 24px;
}
.src-pages-EntityCardPage-EntityCardPage-module__pageContent--jpA_g {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-pages-EntityCardPage-EntityCardPage-module__tabs--ZOsVy {
  padding-left: 24px;
}
.src-pages-EntityCardPage-EntityCardPage-module__tabs--ZOsVy .ant-tabs-nav::before {
  border: none;
}
.src-pages-EntityCardPage-EntityCardPage-module__tabs--ZOsVy .ant-tabs-tab {
  padding: 12px 0;
}
.src-pages-EntityCardPage-EntityCardPage-module__tabContent--gq3nh {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-pages-EntityCardPage-EntityCardPage-module__entityContent--hIMWR {
  flex: 1;
  overflow: hidden;
  border-top: 1px solid #0000000F;
  background-color: #F5F5F5;
}
.src-pages-EntityCardPage-EntityCardPage-module__newEntityContent--ndwYJ {
  background-color: #F5F5F5;
  flex: 1;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityCardPage.module.less"],"names":[],"mappings":"AAKA;EACE,kBAAA;AAJF;AAOA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AALF;AAQA;EAVE,kBAAA;AAKF;AASI;EACE,YAAA;AAPN;AAEA;EAUI,eAAA;AATJ;AAaA;EACE,OAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AAXF;AAcA;EACE,OAAA;EACA,gBAAA;EACA,+BAAA;EACA,yBAAA;AAZF;AAeA;EACE,yBAAA;EACA,OAAA;EACA,gBAAA;AAbF","sourcesContent":["// Конечно, было бы проще сделать отступ для всего контейнера\n// Но дизайн предполагает наличие горизонтальных черт от края до края\n// Поэтому приходится указывать отступ для всех вложенных элементов индивидуально.\n@leftPad: 24px;\n\n.withLeftPad {\n  padding-left: @leftPad;\n}\n\n.pageContent {\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.tabs {\n  .withLeftPad;\n\n  :global(.ant-tabs-nav) {\n    &::before {\n      border: none;\n    }\n  }\n\n  :global(.ant-tabs-tab) {\n    padding: 12px 0;\n  }\n}\n\n.tabContent {\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.entityContent {\n  flex: 1;\n  overflow: hidden;\n  border-top: 1px solid #0000000F;\n  background-color: #F5F5F5;\n}\n\n.newEntityContent {\n  background-color: #F5F5F5;\n  flex: 1;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withLeftPad": `src-pages-EntityCardPage-EntityCardPage-module__withLeftPad--IE6pb`,
	"pageContent": `src-pages-EntityCardPage-EntityCardPage-module__pageContent--jpA_g`,
	"tabs": `src-pages-EntityCardPage-EntityCardPage-module__tabs--ZOsVy`,
	"tabContent": `src-pages-EntityCardPage-EntityCardPage-module__tabContent--gq3nh`,
	"entityContent": `src-pages-EntityCardPage-EntityCardPage-module__entityContent--hIMWR`,
	"newEntityContent": `src-pages-EntityCardPage-EntityCardPage-module__newEntityContent--ndwYJ`
};
export default ___CSS_LOADER_EXPORT___;
