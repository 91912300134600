// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__table--z9JC1 {
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__thead--vHfKB {
  display: grid;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__th--yi30Y {
  padding: 9px 8px 9px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  border-top: thin solid #E1E3E6;
  border-right: thin solid #E1E3E6;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__td--ntfHG {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  border-top: thin solid #E1E3E6;
  border-right: thin solid #E1E3E6;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__leftCol--lsQxW {
  border-left: thin solid #E1E3E6;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__lastRow--Ltv7r {
  border-bottom: thin solid #E1E3E6;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__emptyTable--Ofekd {
  border: thin solid #E1E3E6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__textCell--WxL_z {
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
}
.src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__editCell--FRMNJ {
  padding: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SamplePoints/Subsample/SampleMcPoints/SampleMcPoints.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACA;EACI,aAAA;AACJ;AAEA;EACI,yBAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,8BAAA;EACA,gCAAA;AAFJ;AAIA;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,8BAAA;EACA,gCAAA;AAHJ;AAKA;EACI,+BAAA;AAHJ;AAKA;EACI,iCAAA;AAHJ;AAKA;EACI,0BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAHJ;AAKA;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;AAHJ;AAKA;EACI,YAAA;AAHJ","sourcesContent":[".table {\n    display: flex;\n    flex-direction: column;\n}\n.thead {\n    display: grid;\n}\n@border: thin solid #E1E3E6;\n.th {\n    padding: 9px 8px 9px 16px;\n\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 160%;\n\n    border-top: @border;\n    border-right: @border;\n}\n.td {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 160%;     \n\n    border-top: @border;\n    border-right: @border;\n}\n.leftCol {\n    border-left: @border;\n}\n.lastRow {\n    border-bottom: @border;\n}\n.emptyTable {\n    border: @border;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.textCell {\n    padding: 6px 16px;\n    display: flex;\n    flex-direction: row;\n}\n.editCell {\n    padding: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__table--z9JC1`,
	"thead": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__thead--vHfKB`,
	"th": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__th--yi30Y`,
	"td": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__td--ntfHG`,
	"leftCol": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__leftCol--lsQxW`,
	"lastRow": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__lastRow--Ltv7r`,
	"emptyTable": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__emptyTable--Ofekd`,
	"textCell": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__textCell--WxL_z`,
	"editCell": `src-businessServices-services-bsSample-SampleControl-SamplePoints-Subsample-SampleMcPoints-SampleMcPoints-module__editCell--FRMNJ`
};
export default ___CSS_LOADER_EXPORT___;
