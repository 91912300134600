import { z } from "zod";
import { zAttribute } from "./ZAttribute";

export enum GroupType {
  Mnemonic = "Mnemonic",
  ByDictionary = "ByDictionary",
}
export const zGroupType = z.nativeEnum(GroupType);
export const zIdGroupType = z.object({
  id: z.number(),
  name: zGroupType,
});
export type ZIdGroupType = z.infer<typeof zIdGroupType>;

export const zIdGroupLabel = zIdGroupType.extend({
  label: z.string(),
});
export type ZIdGroupLabel = z.infer<typeof zIdGroupLabel>;

const zGroupBase = z.object({
  id: z.number(),
  name: z.string(),
  groupType: zIdGroupType,
  description: z.string().nullable().optional(),
  attributes: zAttribute.array().nullable().optional(),

  attributeId: z.number().nullable().optional(),
  valueId: z.number().nullable().optional(),
});

export type ZGroup = z.infer<typeof zGroupBase> & {
  groups?: ZGroup[];
};

export const zGroup = zGroupBase.extend({
  groups: z.lazy(() => zGroupBase.array()).optional(),
});

// Группы, которые используются для справочников. Их нельзя использовать совместно с другими группами.

export const zDictGroup = z.object({
  id: z.number(),
  name: z.string(),
  attributes: zAttribute.array().nullable().optional(),
  groupType: z.object({
    id: z.number(),
    name: z.literal("Dictionary"),
  }),
});

export type ZDictGroup = z.infer<typeof zDictGroup>;
