import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  makeObectRefProps,
  makeSimpleTextProps,
  makePersonCellInfoProps,
  makeDictRefProps2,
  makeImgListProps,
  makeLinkStdProps,
  makeFileListProps,
  makeTagProps,
  makeChildEntitiesCellInfoProps,
} from "src/common/attrView/componentsDefs";

import {
  ZAttrViewInfo,
  viewsByType,
  zAttrComponentView,
} from "src/common/attrView";

import { ZAttribute } from "src/types/ZAttribute";
import { AttrLinked } from "src/components/AttrLinked/AttrLinked";
import { AttrLabelBuilderCtx } from "src/common/attributes/composeAttrLabel2";

import { getEditorInfo } from "src/common/attrEdit";
import {
  SimpleText,
  ObjectRef,
  ImgList,
  LinkStd,
  FileList,
  Tag,
  LinkView,
  DictRef2,
  PersonCellInfo,
  ContentLangInfo,
} from "../components";

import { defineAttrView } from "./defineView";
import { seedSeparators } from "../viewFormItems/Separator";
import { composeLabelsWithEditConfig } from "./composeLabelWithConf";
import { ChildEntitiesCellInfo } from "../components/ChildEntitiesCellInfo/ChildEntitiesCellInfo";

export const createItemView = (
  attr: ZAttribute,
  typesMap: Record<number, string>,
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
  ctx?: AttrLabelBuilderCtx,
): React.ReactNode => {
  let component = viewInfo?.component;
  const configuredVals =
    composeLabelsWithEditConfig(attr, values, typesMap) || [];
  const editorInfo = getEditorInfo(attr.viewStyles);
  if (!component) {
    const typeName = typesMap[attr.valueType];
    const list = viewsByType[typeName as AttrTypeName];
    const view = list?.[0];
    if (view) {
      try {
        component = zAttrComponentView.parse({ view });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e); // для отладки
      }
    }
  }
  if (!component) return undefined;
  switch (component.view) {
    case "SimpleText": {
      const isTextArea = editorInfo?.component?.editor === "TextArea";
      const newValues = seedSeparators(
        (isTextArea && configuredVals[0]
          ? configuredVals[0].split("\n")
          : configuredVals
        ).map((v, i) => ({ content: v, key: `${i}:${v}` })),
        isTextArea ? "newLine" : viewInfo?.appearance?.view?.separator,
      );
      return defineAttrView({
        Component: SimpleText,
        cmpProps: makeSimpleTextProps(newValues, viewInfo),
      });
    }
    case "ObectRef":
      return defineAttrView({
        Component: ObjectRef,
        cmpProps: makeObectRefProps(attr, component, configuredVals, viewInfo),
      });
    case "ContentLangInfo":
      return defineAttrView({
        Component: ContentLangInfo,
        cmpProps: { values: configuredVals },
      });
    case "DictRef":
      return defineAttrView({
        Component: DictRef2,
        cmpProps: makeDictRefProps2(configuredVals, attr),
      });
    case "ImgList":
      return defineAttrView({
        Component: ImgList,
        cmpProps: makeImgListProps(configuredVals),
      });
    case "LinkStd":
      return defineAttrView({
        Component: LinkStd,
        cmpProps: makeLinkStdProps(configuredVals, viewInfo, attr),
      });
    case "LinkView":
      return defineAttrView({
        Component: LinkView,
        cmpProps: { values: configuredVals, viewInfo },
      });
    case "Tag":
      return defineAttrView({
        Component: Tag,
        cmpProps: makeTagProps(component, configuredVals, viewInfo),
      });
    case "PersonCellInfo":
      return defineAttrView({
        Component: PersonCellInfo,
        cmpProps: makePersonCellInfoProps(configuredVals, attr),
      });
    case "FileList":
      return defineAttrView({
        Component: FileList,
        cmpProps: makeFileListProps(configuredVals, viewInfo),
      });
    case "AttrLinked":
      return defineAttrView({
        Component: AttrLinked,
        cmpProps: { attr, entityId: ctx?.entityId },
      });
    case "ChildEntitiesCellInfo": {
      return defineAttrView({
        Component: ChildEntitiesCellInfo,
        cmpProps: makeChildEntitiesCellInfoProps(
          attr,
          viewInfo,
          editorInfo,
          ctx,
        ),
      });
    }
    case "AttrFormulaCell": {
      // в формуле приходят сразу просчитаные значения, поэтому отображаем их с настройками
      return configuredVals;
    }
    default:
      return values;
  }
};
