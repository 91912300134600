import React from "react";
import { SelectDictionaryStore } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectDictionary";
import { Form, Select } from "antd";
import { observer } from "mobx-react-lite";
import { filterOptionByName } from "src/common/filterOptionByName";
import { ZSampleSettings } from "../../../SampleTypes";

interface PropsSampleDictSelect {
  store: SelectDictionaryStore;
  name: keyof ZSampleSettings;
  label: string;
}

export const SampleDictSelect: React.FC<PropsSampleDictSelect> = observer(
  ({ store, name, label }) => (
    <Form.Item name={name} label={label} rules={[{ required: true }]}>
      <Select
        options={store.dictOptions}
        placeholder="Выбрать"
        showSearch
        filterOption={filterOptionByName}
      />
    </Form.Item>
  ),
);
