// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
div,
p,
h1,
h2,
h3,
h4,
ul,
li,
span,
form,
input,
select,
button {
  margin: 0;
  padding: 0;
}
body {
  color: #343a41;
  background: #fff;
  font-family: Geneva, Arial, Helvetica, sans-serif;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/style.less"],"names":[],"mappings":"AAEA;;;;;;;;;;;;;;;EAeE,SAAA;EACA,UAAA;AADF;AAIA;EACE,cAAA;EACA,gBAAA;EACA,iDAAA;EACA,eAAA;AAFF","sourcesContent":["@import \"./variables.less\";\n\nhtml,\nbody,\ndiv,\np,\nh1,\nh2,\nh3,\nh4,\nul,\nli,\nspan,\nform,\ninput,\nselect,\nbutton {\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  color: @text-main;\n  background: @fill-main;\n  font-family: Geneva, Arial, Helvetica, sans-serif;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
