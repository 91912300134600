import { FormItemProps } from "antd";
import { ifDef } from "src/common/ifDef";
import { ContextCommon } from "./FormWithBlocks.types";
import { packName } from "./items/nameUtils";

type ExtItemProps = Pick<FormItemProps, "help" | "validateStatus">;

export const externalErrorItemProps = (
  ctx: ContextCommon,
  key: string,
): ExtItemProps => {
  const packedName = packName([...ctx.prevName, key]);
  return (
    ifDef(ctx.store.externalErrors[packedName], (help) => ({
      help,
      validateStatus: "error",
    })) ?? {}
  );
};
