import * as React from "react";
import { CellBoxInput } from "src/components/Sheet/CellBoxInput";
import { CellBoxNumber } from "src/components/Sheet/CellBoxNumber";
import { CellBoxSelect } from "src/components/Sheet/CellBoxSelect";
import { CellBoxDate } from "src/components/Sheet/CellBoxDate";
import { ZSampleData } from "../../ZSampleData";
import { SampleColumn } from "./SampleColumn";
import { SamplePointsStore } from "../SamplePointsStore";
import { SampleAllSelector, SampleRowSelector } from "./SampleTableSelect";
import { SampleDetailsSwitcher } from "./SampleDetailsSwitcher";

const mkKey = (key: keyof ZSampleData, row: ZSampleData) => `${key}-${row.id}`;

export const buildSampleColumns = (
  store: SamplePointsStore,
): SampleColumn[] => [
  {
    key: "select",
    title: <SampleAllSelector store={store} />,
    minWidth: 40,
    align: "center",
    vertAlign: "center",
    cell: (row) => <SampleRowSelector row={row} store={store} />,
  },
  {
    key: "size", // Не редактируется, согласно требованиям.
    title: "Size",
    width: "1fr",
    minWidth: 80,
    vertAlign: "center",
    cell: (row) => (
      <SampleDetailsSwitcher store={store} row={row}>
        {row.size.name}
      </SampleDetailsSwitcher>
    ),
  },
  {
    key: "version",
    title: "Version",
    width: "1fr",
    minWidth: 100,
    vertAlign: "center",
    cell: (row) => row.version.name,
  },
  {
    key: "requestType",
    title: "Request Type",
    width: "1fr",
    minWidth: 100,
    align: "fit",
    cell: (row) => drawSelect("requestType", row, store),
  },
  {
    key: "quantity",
    title: "Quantity",
    width: "1fr",
    minWidth: 60,
    cell: (row: ZSampleData) => (
      <CellBoxNumber
        cellKey={mkKey("quantity", row)}
        store={store.sheetStore}
        value={row.quantity}
        save={async (value: number | null | undefined) =>
          store.saveCellValue("quantity", row, value ?? null)
        }
        onSuccess={(newRow) => {
          store.updateCell("quantity", row, newRow);
        }}
        numberProps={{ controls: false }}
      />
    ),
  },
  {
    key: "requestStatus",
    title: "Request Status",
    width: "1fr",
    minWidth: 100,
    align: "fit",
    cell: (row) => drawSelect("requestStatus", row, store),
  },
  {
    key: "reviewDate",
    title: "reviewDate",
    width: "1fr",
    minWidth: 160,
    align: "fit",
    cell: (row) => (
      <CellBoxDate
        cellKey={mkKey("reviewDate", row)}
        store={store.sheetStore}
        value={row.reviewDate}
        save={(value) => store.saveCellValue("reviewDate", row, value ?? null)}
        onSuccess={(newRow) => store.updateCell("reviewDate", row, newRow)}
      />
    ),
  },
  {
    key: "comment",
    title: "Comment",
    width: "2fr",
    minWidth: 200,
    align: "fit",
    cell: (row) => (
      <CellBoxInput
        cellKey={mkKey("comment", row)}
        store={store.sheetStore}
        value={row.comment}
        save={(value) => store.saveCellValue("comment", row, value ?? null)}
        onSuccess={(newRow) => store.updateCell("comment", row, newRow)}
      />
    ),
  },
];

const drawSelect = <TField extends "requestStatus" | "requestType">(
  field: TField,
  row: ZSampleData,
  store: SamplePointsStore,
): React.ReactNode => (
  <CellBoxSelect
    cellKey={mkKey(field, row)}
    store={store.sheetStore}
    value={row[field].id}
    selectProps={{
      options: row[field].options ?? [],
      fieldNames: { value: "id", label: "name" },
      allowClear: true,
      style: { width: "100%" },
    }}
    save={(value: number | string | null | undefined) =>
      store.saveCell(field, {
        ...row,
        [field]: row[field].options?.find(({ id }) => id === value) ?? null,
      })
    }
    onSuccess={(newRow) => {
      store.updateSelectCell(field, row, newRow);
    }}
  />
);
