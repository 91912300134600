// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__empty--bm7ZA {
  margin: 0 !important;
  text-align: center;
  width: 100%;
}
.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__attrsBlock--xzKWA {
  display: flex;
  flex-direction: column;
}
.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__editable--egeb4 {
  padding: 0;
}
.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__row--KwwJL {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 4px 6px;
  cursor: pointer;
  font-weight: 500;
}
.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__row--KwwJL:hover {
  background: #fafafa;
}
.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__row--KwwJL.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__active--MK7Wn {
  background: #e6f7ff;
}
.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__name--cffB9 {
  flex: 1;
  color: #858585;
}
.src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__type--AZ_1D {
  flex: 1;
  color: #2B2B2B;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryRefPage/CategoryAttributes/AttrLevelPanel/AttrLevelList.module.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,WAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;AAAF;AAEA;EACE,UAAA;AAAF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AAAF;AACE;EACE,mBAAA;AACJ;AACE;EACE,mBAAA;AACJ;AAEA;EACE,OAAA;EACA,cAAA;AAAF;AAEA;EACE,OAAA;EACA,cAAA;AAAF","sourcesContent":[".empty {\n  margin: 0 !important;\n  text-align: center;\n  width: 100%;\n}\n\n.attrsBlock {\n  display: flex;\n  flex-direction: column;\n}\n.editable {\n  padding: 0;\n}\n.row {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n  padding: 4px 6px;\n  cursor: pointer;\n  font-weight: 500;\n  &:hover {\n    background: #fafafa;\n  }\n  &.active {\n    background: #e6f7ff;\n  }\n}\n.name {\n  flex: 1;\n  color: #858585;\n}\n.type {\n  flex: 1;\n  color: #2B2B2B;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__empty--bm7ZA`,
	"attrsBlock": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__attrsBlock--xzKWA`,
	"editable": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__editable--egeb4`,
	"row": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__row--KwwJL`,
	"active": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__active--MK7Wn`,
	"name": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__name--cffB9`,
	"type": `src-pages-CategoryRefPage-CategoryAttributes-AttrLevelPanel-AttrLevelList-module__type--AZ_1D`
};
export default ___CSS_LOADER_EXPORT___;
