// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-SortableList-SortableList-module__item--RN3qk {
  cursor: move;
}
`, "",{"version":3,"sources":["webpack://./src/components/SortableList/SortableList.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":[".item {\n  cursor: move;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `src-components-SortableList-SortableList-module__item--RN3qk`
};
export default ___CSS_LOADER_EXPORT___;
