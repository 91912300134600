import * as React from "react";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { LoaderBox } from "src/components/LoaderBox";
import { SubsampleStore } from "./SubsampleStore";
import { SamplePhotoComments } from "./SamplePhotoComments/SamplePhotoComments";
import { SampleMcPoints } from "./SampleMcPoints";

interface PropsSubsample {
  store: SubsampleStore;
}

export const Subsample: React.FC<PropsSubsample> = observer(({ store }) => {
  React.useEffect(() => {
    store.init().catch(onError);
  }, []);
  return (
    <div>
      <LoaderBox
        remoteData={store.mcPoints}
        drawReady={() => <SampleMcPoints store={store} />}
      />
      <SamplePhotoComments store={store} />
    </div>
  );
});
