import { Button, Form, Tooltip } from "antd";
import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { edAttrField } from "../../EdAttribute";
import { EdObjStates } from "../../EdObject";
import { ZPermissions } from "../roleTypes";
import { createCopyOnRoleMap } from "./createCopyOnRoleMap";
import { Obj2TabStore } from "../../Obj2TabStore";

interface PropsCopyOnRoleButton {
  stateId: number;
  roleName: string;
  groupId: number;
  roleId: number;
  store: Obj2TabStore;
}

export const CopyOnRoleButton: React.FC<PropsCopyOnRoleButton> = observer(
  ({ stateId, roleName, roleId, groupId, store }) => {
    const form = Form.useFormInstance();
    const rolesMap: EdObjStates | undefined = Form.useWatch(
      edAttrField.rolesMap,
    );
    const curPermissonsItem: ZPermissions | undefined = Form.useWatch([
      edAttrField.rolesMap,
      String(stateId),
    ]);
    if (!rolesMap || !curPermissonsItem) return null;

    const copyOnRole = (curGroupId: number, curRoleId: number) => {
      const newValue = createCopyOnRoleMap({
        curGroupId,
        curRoleId,
        curPermissonsItem,
        rolesMap,
      });
      if (!newValue) return;
      form.setFieldValue(edAttrField.rolesMap, newValue);
      store.formStore.setFormStatus("changed");
    };

    return (
      <Tooltip
        title={`Скопировать на роль ${roleName} для всех состояний атрибута`}
      >
        <Button
          size="small"
          type="text"
          icon={<CopyOutlined />}
          onClick={() => copyOnRole(groupId, roleId)}
        />
      </Tooltip>
    );
  },
);
