import * as React from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined, SisternodeOutlined } from "@ant-design/icons";
import { GroupType } from "src/types/ZGroup";
import { iconGroupDict, iconGroupMnemonic } from "../../utils/itemIcons";
import { Obj2TabStore } from "../../Obj2TabStore";
import { ValueO2 } from "../../Obj2Nodes";
import { AddExistingAttribute } from "../AddExistingAttribute";

interface PropsAddValueItem {
  store: Obj2TabStore;
  valueNode: ValueO2;
  disabled?: boolean;
  buttonId?: string;
}

export const AddValueItem: React.FC<PropsAddValueItem> = (props) => {
  const { store, disabled, buttonId, valueNode } = props;

  const menuProps: MenuProps = {
    items: [
      {
        key: "attr",
        label: "Добавить атрибут",
        icon: <SisternodeOutlined />,
        onClick: () => store.safeAddAttr(),
        disabled,
      },
      {
        key: "existingAttr",
        label: <AddExistingAttribute store={store} valueNode={valueNode} />,
      },
      {
        type: "divider",
      },
      {
        key: "groups",
        type: "group",
        label: "Добавить группу атрибутов",
        children: [
          {
            key: "mnenoGroup",
            label: "Мнемоническую",
            icon: iconGroupMnemonic,
            onClick: () => store.safeAddGroup(GroupType.Mnemonic),
            disabled,
          },
          {
            key: "dictiGroup",
            label: "На основе справочника",
            icon: iconGroupDict,
            onClick: () => store.safeAddGroup(GroupType.ByDictionary),
            disabled,
          },
        ],
      },
    ],
  };

  return (
    <Dropdown menu={menuProps}>
      <Button id={buttonId}>
        <Space>
          Добавить
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

AddValueItem.defaultProps = {
  disabled: false,
  buttonId: undefined,
};
