import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Flex, Spin } from "antd";
import { FormObjectSection } from "src/components/Forms/FormObjectSection";
import { bom2SettingsStore, BomObjType } from "./Bom2SettingsStore";
import { ZBom2Settings } from "../Bom2Types";
import {
  ColorModelSection,
  ColorSection,
  PositionSection,
  SupplierSection,
} from "./sections";

interface PropsBom2Settings {
  typesMap: Record<number, string>;
}

export const Bom2Settings: React.FC<PropsBom2Settings> = observer(
  ({ typesMap }) => {
    useEffect(() => {
      bom2SettingsStore.init(typesMap);
    }, []);
    const sectionsStore = bom2SettingsStore.formSectionsStore;
    const { loading } = sectionsStore;
    return (
      <Spin spinning={loading}>
        <Flex vertical gap={24}>
          <FormObjectSection<ZBom2Settings, BomObjType>
            store={sectionsStore}
            objKey={["bomObjectId"]}
            objType="bom"
            objLabel="BOM object"
            title="BOM"
          />
          <FormObjectSection<ZBom2Settings, BomObjType>
            store={sectionsStore}
            objKey={["materialObjectId"]}
            objType="material"
            objLabel="Material object"
            title="Material"
          />
          <PositionSection store={sectionsStore} />
          <SupplierSection store={sectionsStore} />
          <ColorSection store={sectionsStore} />
          <ColorModelSection store={sectionsStore} />
        </Flex>
      </Spin>
    );
  },
);
