import { z } from "zod";

export const zObjectService = z.object({
  id: z.number(),
  serviceId: z.number(),
  objectId: z.number(),
  name: z.string(),
});

export type ZObjectService = z.infer<typeof zObjectService>;

export type ObjectServiceExt = ZObjectService & {
  serviceKey: string;
};
