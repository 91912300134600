import React from "react";
import { loadFileInfo } from "src/common/apiFiles";
import { onError } from "src/common/onError";
import { Spin } from "antd";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import { SimpleText } from "../SimpleText/SimpleText";
import { seedSeparators } from "../../viewFormItems/Separator";

type PropsFileList = {
  values?: string[];
  viewInfo?: ZAttrViewInfo;
};

export const FileList: React.FC<PropsFileList> = ({ values, viewInfo }) => {
  const [labelList, setLabelList] = React.useState<React.ReactNode[]>([]);
  const [loading, setLoading] = React.useState(false);

  const init = async () => {
    try {
      if (!values || !viewInfo) return;
      setLoading(true);
      const result = await Promise.all(
        values.map(async (uid) => {
          const fileResp = await loadFileInfo(uid);
          return { fileResp, uid };
        }),
      );
      const labelDefResult = result.map(({ fileResp, uid }) => ({
        content: fileResp.fileName,
        key: uid,
      }));
      setLabelList(
        seedSeparators(labelDefResult, viewInfo.appearance?.view?.separator),
      );
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return loading ? (
    <Spin spinning={loading}>...</Spin>
  ) : (
    <SimpleText values={labelList} viewInfo={viewInfo} />
  );
};

FileList.defaultProps = { viewInfo: undefined, values: undefined };
