import { z } from "zod";

export const zAttrImageCarousel = z.object({
  editor: z.literal("ImageCarousel"),
  accept: z.string().nullable().optional(),
  multiple: z.boolean().optional(),
  maxCount: z.number().nullable().optional(),
  maxSize: z.number().nullable().optional(),
  imageHeight: z.number().nullable().optional(),
});

export type ZAttrImageCarousel = z.infer<typeof zAttrImageCarousel>;

export type AttrImageCarouselProps = {
  accept?: string;
  multiple?: boolean;
  maxCount?: number;
  maxSize?: number;
  imageHeight?: number;
};

export const makeImageCarouselProps = (
  src: ZAttrImageCarousel,
): AttrImageCarouselProps => ({
  accept: src.accept ?? undefined,
  multiple: src.multiple ?? undefined,
  maxCount: src.maxCount ?? undefined,
  maxSize: src.maxSize ?? undefined,
  imageHeight: src.imageHeight ?? undefined,
});
