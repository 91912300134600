import { ZTranslationMap } from "src/types/ZTranslation";
import { ZLightGroup } from "./Obj2Nodes";

export type EdGroup = ZLightGroup & {
  translations: ZTranslationMap;
};

export const group2ed = ({
  group,
  translations,
}: {
  group: ZLightGroup;
  translations: ZTranslationMap;
}): EdGroup => ({
  ...group,
  translations,
});

export const ed2group = (
  ed: EdGroup,
): {
  group: ZLightGroup;
  translations: ZTranslationMap;
} => {
  const { translations, ...group } = ed;
  return { translations, group };
};
