import * as React from "react";
import { RemoteData } from "src/common/RemoteData";
import { Alert, Spin } from "antd";
import { getErrorMessage } from "src/common/onError";
import { SpinSize } from "antd/lib/spin";
import styles from "./LoaderBox.module.less";

interface PropsLoaderBox<T> {
  remoteData: RemoteData<T>;
  drawReady(data: T): React.ReactNode;
  size?: SpinSize;
}

/* eslint-disable react/jsx-props-no-spreading */

export const LoaderBox = <T,>({
  remoteData,
  drawReady,
  size,
}: PropsLoaderBox<T>) => (
  <>
    {remoteData.status === "wait" && !remoteData.result && (
      <div className={styles.wait}>
        <Spin size={size || "large"} />
      </div>
    )}
    {remoteData.status === "wait" && !!remoteData.result && (
      <Spin spinning size="large">
        {drawReady(remoteData.result)}
      </Spin>
    )}
    {remoteData.status === "error" && (
      <Alert
        type="error"
        {...getErrorMessage(remoteData.error || Error("Ошибка"))}
        showIcon
      />
    )}
    {remoteData.status === "ready" && drawReady(remoteData.result)}
  </>
);
LoaderBox.defaultProps = {
  size: undefined,
};
