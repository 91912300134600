import React from "react";
import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { TreeProps } from "antd";
import { flattenItems } from "src/common/flattenItems";
import { TableStore } from "src/components/tables/TableStore";
import { findNodeByKey } from "src/components/TreeStd";
import { makeCopyTreeMetaSetting } from "./utils/makeChildAttsTree";
import {
  copyEntityAttributes,
  copyEntityBs,
  loadChildAttsAndBss,
} from "./apiEntityCopy";
import { ZEntityFilters, ZEntityRow } from "../types";
import {
  EntCopyTreeNode,
  getEntCopyAttrNodes,
  getEntCopyBSNodes,
  ZEntCopyDataResp,
} from "./types";

export class EntityCopyButtonStore {
  constructor(
    public readonly objId: number,
    public readonly tableStore: TableStore<ZEntityRow, ZEntityFilters>,
  ) {
    makeAutoObservable(this);
  }

  data: RemoteData<ZEntCopyDataResp> = { status: "none" };

  setData(newData: RemoteData<ZEntCopyDataResp>) {
    this.data = newData;
  }

  get treeData(): EntCopyTreeNode[] | undefined {
    return this.data.status === "ready"
      ? makeCopyTreeMetaSetting(this.data.result)
      : undefined;
  }

  async init() {
    this.setData({ status: "wait" });
    return loadChildAttsAndBss(this.objId, { translate: true })
      .then((result) => {
        this.setData({ status: "ready", result });
        this.setExpanded(this.defaultExpanded ?? []);
        return result;
      })
      .catch((error) => {
        this.setData({ status: "error", error });
        return null;
      });
  }

  copyEntityWithBSs = async () => {
    const entityId = this.tableStore.selected[0]?.id;
    if (!entityId) return;
    const attsNodes = getEntCopyAttrNodes(this.checkedNodes);
    const bsNodes = getEntCopyBSNodes(this.checkedNodes);
    const attsIds = attsNodes.map((n) => n.data.attributeId);
    const newEnt = await copyEntityAttributes(entityId, attsIds);
    await Promise.allSettled([
      bsNodes.map((bs) => copyEntityBs(entityId, newEnt.id, bs.data.url)),
    ]);
    this.tableStore.reload();
  };

  /*
   * Задача требует чтобы выбирая родителя - не выбирались все его дети,
   * но при этом выбирая ребенка - выбирались все родители,
   * поэтому пришлось написать кастомную логику
   */

  checkedItems = new Set<React.Key>();

  setCheckedItems(items: React.Key[]) {
    this.checkedItems = new Set(items);
  }

  get checkedKeys(): React.Key[] {
    return Array.from(this.checkedItems);
  }

  get checkedNodes() {
    return this.checkedKeys
      .map((k) => findNodeByKey(k, this.treeData || []))
      .filter(Boolean) as EntCopyTreeNode[];
  }

  get defaultChecked(): React.Key[] | null {
    const { data } = this;
    return data.status === "ready"
      ? flattenItems(this.treeData, "children")?.map(({ key }) => key) ?? null
      : null;
  }

  onCheck: TreeProps["onCheck"] = (_checkedKeysValue, info) => {
    const { node, checked } = info;
    const { checkedItems } = this;

    const getParents = (
      search: React.Key,
      tree?: EntCopyTreeNode[],
    ): React.Key[] | undefined => {
      const guess = tree?.find((n) => n.key === search);
      if (guess) return [guess.key];
      const guess2 = tree
        ?.map((n) => {
          const list = getParents(search, n.children);
          return list ? [n.key, ...list] : undefined;
        })
        .filter(Boolean)
        .pop();
      return guess2;
    };

    if (checked) {
      checkedItems.add(node.key);
      const parents = getParents(node.key, this.treeData);
      parents?.forEach((parentKey) => checkedItems.add(parentKey));
    } else {
      checkedItems.delete(node.key);
      if (node.children) {
        const childKeys = flattenItems(node.children, "children")?.map(
          ({ key }) => key,
        );
        childKeys?.forEach((childKey) => checkedItems.delete(childKey));
      }
    }
  };

  // expand

  expanded = new Set<React.Key>();

  setExpanded(list: React.Key[]) {
    this.expanded = new Set(list);
  }

  get expandedKeys(): React.Key[] {
    return Array.from(this.expanded);
  }

  get defaultExpanded() {
    return flattenItems(this.treeData, "children")?.flatMap(
      ({ children, key }) => (children ? [key] : []),
    );
  }

  onExpand: TreeProps["onExpand"] = (keys) => {
    this.setExpanded(keys);
  };
}
