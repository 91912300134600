import { DefaultOptionType } from "antd/es/select";
import { apiLoadPersonList } from "src/common/apiPerson";
import {
  getUsers2LabelsDict,
  labelLoaderUsersList,
} from "src/common/attributes/composeAttrLabel2/labelLoaders";
import { composeLabelsWithEditConfig } from "src/common/attrView/createItemView/composeLabelWithConf";
import { getTypesMap } from "src/references/getTypesMap";
import { ZAttribute } from "src/types/ZAttribute";

export const loadPersonsOptions = async (
  attr: ZAttribute,
  userIds?: string[],
): Promise<DefaultOptionType[]> => {
  const roleIds = attr.roleIds ?? [];
  const persons = await apiLoadPersonList({
    roleIds,
    userIds: userIds ?? [],
  });

  const treeData = await labelLoaderUsersList(
    persons.map(({ userId }) => userId) || [],
    attr,
  );
  if (!treeData) return [];
  const typesMap = await getTypesMap();
  const userLabelDict = getUsers2LabelsDict(treeData);

  return Object.entries(userLabelDict).map(([key, value]) => {
    const searchTextFilter =
      value
        ?.map((d) => d.labels)
        .filter(Boolean)
        .join(" ") || "";
    const label = value
      .map((v) => composeLabelsWithEditConfig(v.attr, v.labels, typesMap))
      .flat()
      .filter(Boolean)
      .join();
    return {
      label,
      value: key,
      searchText: searchTextFilter.toLowerCase(),
    };
  });
};
