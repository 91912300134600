// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityCardPage-EntityFormWithAnchor-EntityFormWithAnchor2-module__leftPartCustom--QSvBo {
  gap: 12px !important;
}
.src-pages-EntityCardPage-EntityFormWithAnchor-EntityFormWithAnchor2-module__leftPartCustom--QSvBo > div {
  padding-bottom: 12px;
  border-bottom: thin solid rgba(0, 0, 0, 0.06);
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityCardPage/EntityFormWithAnchor/EntityFormWithAnchor2.module.less"],"names":[],"mappings":"AACA;EACI,oBAAA;AAAJ;AACI;EACI,oBAAA;EACA,6CAAA;AACR","sourcesContent":["// На макете в левой части под каждым элементом присутствует горизонтальная черта\n.leftPartCustom {\n    gap: 12px !important;\n    & > div {\n        padding-bottom: 12px;\n        border-bottom: thin solid rgba(0, 0, 0, 0.06);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftPartCustom": `src-pages-EntityCardPage-EntityFormWithAnchor-EntityFormWithAnchor2-module__leftPartCustom--QSvBo`
};
export default ___CSS_LOADER_EXPORT___;
