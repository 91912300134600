import * as React from "react";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { Button, notification } from "antd";
import { t } from "i18next";
import { MChartEntityStore } from "../../MChartEntityStore";
import styles from "./MCEntityCopyFrom.module.less";

interface PropsMCEntityCopyFrom {
  store: MChartEntityStore;
}

export const MCEntityCopyFrom: React.FC<PropsMCEntityCopyFrom> = observer(
  ({ store }) => {
    const { table } = store;
    React.useEffect(() => {
      store.initTable();
    }, []);
    return (
      <LoaderBox
        remoteData={table}
        drawReady={(tableStore) => (
          <Inner mcStore={store} tableStore={tableStore} />
        )}
      />
    );
  },
);

interface PropsInner {
  mcStore: MChartEntityStore;
  tableStore: EntityFiltersPageStore;
}
const Inner: React.FC<PropsInner> = observer(({ mcStore, tableStore }) => {
  const onCopy = () => {
    const models = tableStore.tableStore?.selected ?? [];
    const model = models[0];
    if (!model) return;
    if (model.id === mcStore.modelId) {
      notification.error({
        message: t("Cannot select the current model to copy"),
      });
      return;
    }
    mcStore.copyFrom(model.id);
  };
  return (
    <div className={styles.tableBox}>
      <div className={styles.toolbar}>
        <div className={styles.prompt}>
          {t("Please, select a source model and click the Copy button.")}
        </div>
        <Button
          type="primary"
          disabled={!tableStore.tableStore?.selected.length}
          loading={mcStore.buzy === "create"}
          onClick={onCopy}
        >
          {t("Copy")}
        </Button>
      </div>
      <div className={styles.tableContent}>
        <EntityFiltersTable store={tableStore} />
      </div>
    </div>
  );
});
