// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-LayoutWithSider-AddButton-AddButton-module__addButton--xfQ8H {
  width: 100%;
  background-color: #595959;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/LayoutWithSider/AddButton/AddButton.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,YAAA;AACF","sourcesContent":[".addButton {\n  width: 100%;\n  background-color: #595959;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": `src-components-LayoutWithSider-AddButton-AddButton-module__addButton--xfQ8H`
};
export default ___CSS_LOADER_EXPORT___;
