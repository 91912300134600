import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AttrTypeName } from "src/types/AttrType";
import { ZSampleSettings } from "../../SampleTypes";
import { SampleObjType } from "../SampleSettingsStore";

interface PropsRequestSection {
  store: FormObjectSectionsStore<SampleObjType>;
}

export const RequestSection: React.FC<PropsRequestSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZSampleSettings, SampleObjType>
      store={store}
      objKey={["objSampleRequest"]}
      objType="request"
      objLabel="Sample request object"
      title="Sample request"
      attrs={[
        {
          attrKey: ["sampleRequestModelAttrId"],
          attrLabel: "Model",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sampleRequestNameAttrId"],
          attrLabel: "Name",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["sampleRequestNumberAttrId"],
          attrLabel: "Number",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["sampleRequestColorModelAttrId"],
          attrLabel: "Color model",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sampleRequestSupplierNameAttrId"],
          attrLabel: "Supplier name",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: ["sampleRequestDateSendAttrId"],
          attrLabel: "Date (send)",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: ["sampleRequestDateReceiveAttrId"],
          attrLabel: "Date (receipt)",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: ["sampleRequestRequestTypeAttrId"],
          attrLabel: "Sample request type",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: ["sampleRequestFitStatusAttrId"],
          attrLabel: "Fit status",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: ["sampleRequestGeneralCommentsAttrId"],
          attrLabel: "General comments",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: ["sampleRequestSamplesAttrId"],
          attrLabel: "Samples",
          attrType: AttrTypeName.childEntities,
        },
      ]}
    />
  ),
);
