import * as React from "react";
import { Button, Checkbox, Form, Input, Space, Modal } from "antd";
import { onAntFormError } from "src/common/onAntFormError";
import { TreeStore, AddCatSubmitParams } from "../index";
import { CategoryTreeSelect } from "../CategoryTreeSelect";

interface AddCatEditParams {
  parentId: string;
  name: string;
  key: string;
  notSelectable: boolean;
}
const cvtEditToSubmit = (src: AddCatEditParams): AddCatSubmitParams => ({
  parentId: +src.parentId,
  name: src.name,
  key: src.key,
  selectable: !src.notSelectable,
});

interface PropsAddCategoryModal {
  open: boolean;
  store: TreeStore;
  close(): void;
}

const formName = "AddCategory";

export const AddCategoryModal: React.FC<PropsAddCategoryModal> = (
  props: PropsAddCategoryModal,
) => {
  const { open, close, store } = props;
  const [form] = Form.useForm<AddCatEditParams>();
  const [saving, setSaving] = React.useState(false);
  React.useEffect(() => {
    const { current } = store;
    if (form && open) {
      form.resetFields();
      form.setFieldsValue({ parentId: current?.id });
    }
  }, [open, form]);
  const submit = (values: AddCatEditParams) => {
    setSaving(true);
    store
      .addCategory(cvtEditToSubmit(values))
      .finally(() => setSaving(false))
      .then(close)
      .catch((e) => onAntFormError(e, formName));
  };
  return (
    <Modal
      open={open}
      title="Добавление категории"
      onCancel={close}
      footer={null}
      destroyOnClose
    >
      <Form<AddCatEditParams>
        layout="vertical"
        onFinish={submit}
        form={form}
        name={formName}
      >
        <Form.Item
          name="parentId"
          label="Родительская категория"
          rules={[{ required: true }]}
        >
          <CategoryTreeSelect treeData={store.treeData} allowClear />
        </Form.Item>
        <Form.Item
          name="name"
          label="Название новой категории"
          rules={[{ required: true }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="key"
          label="Ключ для новой категории"
          rules={[{ required: true }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item name="notSelectable" valuePropName="checked">
          <Checkbox>Недоступна для выбора</Checkbox>
        </Form.Item>
        <Space>
          <Button htmlType="submit" type="primary" loading={saving}>
            Создать
          </Button>
          <Button htmlType="button" onClick={close}>
            Отмена
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};
