import * as React from "react";
import { t } from "i18next";
import { Button, FormInstance, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { FormWithBlocks, PropsFormWithBlocks } from "../FormWithBlocks";
import styles from "./TShapeForm.module.less";

interface PropsTShapeForm<TData> {
  form: FormInstance<TData>;
  formProps: Omit<PropsFormWithBlocks<TData>, "form">;
  leftPart?: React.ReactNode;
  leftPartClass?: string;
  title?: React.ReactNode;
  extraTitle?: React.ReactNode;
  rightPartProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const TShapeForm = observer(
  <TData extends {}>(props: PropsTShapeForm<TData>): React.ReactElement => {
    const {
      formProps,
      leftPart,
      leftPartClass,
      rightPartProps,
      title,
      extraTitle,
      form,
    } = props;
    const { extraButtons, submitText, ...restFormProps } = formProps;
    const { store } = restFormProps;
    const validSubmitText = submitText === undefined ? t("Submit") : submitText;
    const submitButton = validSubmitText ? (
      <Tooltip title={t("Press Ctrl+Enter/Cmd+Enter to apply")}>
        <Button
          type="primary"
          htmlType="submit"
          loading={store.saving}
          onClick={() => form.submit()}
        >
          {submitText}
        </Button>
      </Tooltip>
    ) : null;

    return (
      <div className={styles.frame}>
        <div className={styles.header}>
          {title && <div className={styles.title}>{title}</div>}
          {extraTitle}
          <div className={styles.buttons}>
            {extraButtons}
            {submitButton}
          </div>
        </div>
        <div className={styles.mainPart}>
          {leftPart && (
            <div className={classNames([styles.leftPart, leftPartClass])}>
              {leftPart}
            </div>
          )}
          <div className={styles.rightPart} {...rightPartProps}>
            <FormWithBlocks form={form} {...restFormProps} submitText="" />
          </div>
        </div>
      </div>
    );
  },
);
