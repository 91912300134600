import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";

type ByAttrValTypeAction<T> = () => Promise<T>;

export const byAttrValType = <T>(
  attribute: ZAttribute,
  attrTypeMap: Record<number, string>,
  actions: Partial<Record<AttrTypeName, ByAttrValTypeAction<T>>>,
  defaultAction: ByAttrValTypeAction<T>,
) => {
  const valueType = attrTypeMap[attribute.valueType];
  const action = actions[valueType as AttrTypeName];
  return (action || defaultAction)();
};
