export const flattenItems = <T>(
  items: T[] | null | undefined,
  childrenProp: keyof T,
): T[] | undefined =>
  items?.flatMap((item: T) => {
    const children = item[childrenProp] as T[] | undefined;
    const flattened = children
      ? flattenItems(children, childrenProp) ?? []
      : [];
    return [item, ...flattened];
  });
