import { what } from "src/common/what";
import { ZCatAttrNew } from "src/types/ZCatAttr";
import { AttrType } from "./AttrType";

// В запросе data используется для передачи информации для разных типов
// Но в процессе редактирования их надо держать отдельными полями
export interface EditParams
  extends Omit<ZCatAttrNew, "categoryId" | "data" | "required"> {
  dictionary?: string;
  required?: boolean;
}

export const cvtEditToSubmit = (
  { type, dictionary, required, defaultValue, ...rest }: EditParams,
  categoryId: number,
): ZCatAttrNew => ({
  type,
  categoryId,
  ...rest,
  required: !!required,
  data: what(
    type,
    {
      [AttrType.select]: dictionary,
      [AttrType.multiSelect]: dictionary,
    },
    null,
  ),
  defaultValue: defaultValue ?? null,
});

export const catAttrToEdit = (attr: ZCatAttrNew) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, categoryId, ...rest } = attr;
  const res: EditParams = { ...rest };
  if (attr.type === AttrType.select || AttrType.multiSelect) {
    res.dictionary = data || undefined;
  }
  return res;
};
