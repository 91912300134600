import { Select } from "antd";
import * as React from "react";
import { PropsSelection } from "./types";

export const MultipleSelection: React.FC<PropsSelection> = (props) => {
  const { options, placeholder, onChange } = props;
  return (
    <Select
      allowClear
      placeholder={placeholder}
      mode="multiple"
      maxTagCount="responsive"
      options={options}
      value={options?.map((option) => option.value)}
      onChange={onChange}
      showSearch={false}
    />
  );
};
