import React from "react";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import styles from "./ImgList.module.less";

type PropsImgList = {
  values?: string[];
};

export const ImgList: React.FC<PropsImgList> = ({ values }) => (
  <div className={styles.box}>
    {values?.map((uuid) => (
      <img key={uuid} className={styles.img} src={imgSrc(uuid)} alt="" />
    ))}
  </div>
);

ImgList.defaultProps = {
  values: undefined,
};
