import * as React from "react";
import { Menu, MenuProps } from "antd";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ZMenuItem, ZViewType } from "./ZMenuItem";
import { leftMenuStore as store } from "./leftMenuStore";
import styles from "./LeftMenu.module.less";
import { MenuItemLabel } from "./MenuItemLabel";
import { findActiveItem } from "./findActiveItem";
import { makeMenuItemKey } from "./makeMenuItemKey";

export type MenuItem = Required<MenuProps>["items"][number] & { id: string };

type PropsMenuItemsList = {
  list: ZMenuItem[];
};

const viewTypeMap: Record<ZViewType, string> = {
  bigGroup: styles.bigGroup,
  smallGroup: styles.smallGroup,
  bigLink: styles.bigLink,
  smallLink: styles.smallLink,
};

export const MenuItemsList: React.FC<PropsMenuItemsList> = observer(
  ({ list }) => {
    const location = useLocation();

    React.useEffect(() => {
      const activePart = findActiveItem(list, location.pathname)?.key;
      store.setActiveMenuElement(activePart || "");
    }, [location.pathname, list]);

    const transformMenuList = (items: ZMenuItem[]): MenuItem[] =>
      items.map((item: ZMenuItem) => {
        const menuItem: MenuItem = {
          key: makeMenuItemKey(item.id),
          className: viewTypeMap[item.viewType],
          label: <MenuItemLabel item={item} />,
          id: makeMenuItemKey(item.id),
        };

        return item.elements?.length
          ? {
              ...menuItem,
              children: transformMenuList(item.elements),
            }
          : menuItem;
      });

    return (
      <Menu
        mode="inline"
        className={styles.menu}
        items={transformMenuList(list)}
        selectedKeys={[store.activeMenuElement]}
        openKeys={store.menuState.openedItems ?? []}
        onOpenChange={(openKeys) => store.onOpenMenuItems(openKeys)}
      />
    );
  },
);
