import { number, z } from "zod";

export const zMesChartSettings = z.object({
  mcServiceId: z.number(),
  objectId: z.number(),

  mcObjectId: z.number().nullable(),
  mcNameAttrId: z.number().nullable(),
  mcModelAttrId: z.number().nullable(),
  mcSizeScaleAttrId: z.number().nullable(),
  mcBaseSizeAttrId: z.number().nullable(),
  mcCommentAttrId: z.number().nullable(),
  mcMcPointAttrId: z.number().nullable(),
  mcImageAttrId: z.number().nullable(),

  sizeLinesObjectId: z.number().nullable(),
  sizeLinesNameAttrId: z.number().nullable(),
  sizeLinesSizeLineSizeAttrId: z.number().nullable(),

  sizeLineSizeObjectId: z.number().nullable(),
  sizeLineAttrId: z.number().nullable(),
  sizeLineSizeNameAttrId: z.number().nullable(),
  sizeLineSizePreviousSizeAttrId: number().nullable(),

  msPointsObjectId: z.number().nullable(),
  msPointsMcAttrId: z.number().nullable(),
  msPointsPointAttrId: z.number().nullable(),
  msPointsDescriptionAttrId: z.number().nullable(),
  msPointsQcAttrId: z.number().nullable(),
  msPointsTolMinusAttrId: z.number().nullable(),
  msPointsTolPlusAttrId: z.number().nullable(),
  msPointsPointValueAttrId: z.number().nullable(),
  msPointsResizeValueMinusAttrId: z.number().nullable(),
  msPointsResizeValuePlusAttrId: z.number().nullable(),
  mcPointCategoryAttrId: z.number().nullable(),

  pointObjectId: z.number().nullable(),
  pointPointNameAttrId: z.number().nullable(),
  pointDescriptionAttrId: z.number().nullable(),

  sizeNameObjectId: z.number().nullable(),
  sizeNameNameAttrId: z.number().nullable(),
  sizeNameSynonymsAttrId: z.number().nullable(),

  // Point value settings
  pointValueObjectId: z.number().nullable(),
  pointValueMcPointsAttrId: z.number().nullable(),
  pointValueSizeAttrId: z.number().nullable(),
  pointValueVersionAttrId: z.number().nullable(),
  pointValueValueAttrId: z.number().nullable(),

  // Version
  versionDictionaryNameAttrId: z.number().nullable(),

  templateObjectId: z.number().nullable(),
});

export type ZMesChartSettings = z.infer<typeof zMesChartSettings>;
