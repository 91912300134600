/**
 * Форма редактирования узла на вкладке Объектов
 * Верхнеуровневый компонент, который в зависимости от типа подставляет уже нужные подробности
 */
import * as React from "react";
import { ModelessForm } from "src/components/ModelessForm";
import { observer } from "mobx-react-lite";
import { Obj2TabStore } from "./Obj2TabStore";
import { CommonNodeO2, visitO2 } from "./Obj2Nodes";
import { ObjectForm2, ObjectFormButtons } from "./forms/ObjectForm2";
import { AttrForm2, AttrFormButtons } from "./forms/AttrForm2/AttrForm2";
import { GroupForm2, GroupFormButtons } from "./forms/GroupForm2/GroupForm2";
import { ValueForm2, ValueFormButtons } from "./forms/ValueForm2";
import styles from "./Obj2Tab.module.less";

interface PropsObjNodeEditor {
  curNode: CommonNodeO2;
  store: Obj2TabStore;
}

export const ObjNodeEditor: React.FC<PropsObjNodeEditor> = observer((props) => {
  const { store, curNode } = props;
  const content = visitO2(curNode, {
    obj: (it) => ({
      fields: <ObjectForm2 store={store} objectNode={it} />,
      extraButtons: <ObjectFormButtons store={store} objectNode={it} />,
    }),
    attr: (it) => ({
      fields: <AttrForm2 store={store} attrNode={it} />,
      extraButtons: <AttrFormButtons store={store} attrNode={it} />,
    }),
    group: (it) => ({
      fields: <GroupForm2 store={store} groupNode={it} />,
      extraButtons: <GroupFormButtons store={store} groupNode={it} />,
    }),
    value: (it) => ({
      fields: <ValueForm2 store={store} valueNode={it} />,
      extraButtons: <ValueFormButtons store={store} valueNode={it} />,
    }),
  });

  return (
    <div className={styles.layout}>
      <ModelessForm
        store={store.formStore}
        submit="Сохранить"
        name="curItem"
        extraButtons={content.extraButtons}
      >
        {content.fields}
      </ModelessForm>
    </div>
  );
});
