import * as React from "react";
import { Button, Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Obj2TabStore } from "../Obj2TabStore";

interface PropsUpToObject {
  store: Obj2TabStore;
}

export const UpToObject: React.FC<PropsUpToObject> = ({ store }) => (
  <Tooltip title="Перейти к объекту-владельцу">
    <Button
      icon={<ArrowLeftOutlined />}
      onClick={() => store.safeUpToObject()}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        padding: 0,
        color: "#E43D00",
      }}
    >
      К объекту
    </Button>
  </Tooltip>
);
