import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { ChildEntitiesStore } from "src/components/ChildEntities/ChildEntitiesStore";
import { ZChildEntities } from "src/common/attrEdit/components/ZChildEntities";
import { ChildEntsActionType } from "src/components/ChildEntities/ChildEntities.types";
import { AttrTypeName } from "src/types/AttrType";
import { t } from "i18next";
import { ZTechpackSettings } from "../TechpackTypes";
import { createTechpackInstance, loadTechpackSettings } from "../apiTechpack";

export const techpackControlStore = makeAutoObservable({
  settings: { status: "none" } as RemoteData<ZTechpackSettings>,
  setSettings(data: RemoteData<ZTechpackSettings>) {
    this.settings = data;
  },

  linkAttrId: null as string | null,
  setLinkAttrId(id: string) {
    this.linkAttrId = id;
  },

  childEntsStore: null as ChildEntitiesStore | null,
  setChildEntsStore(store: ChildEntitiesStore) {
    this.childEntsStore = store;
  },

  get editorInfo(): ZChildEntities | undefined {
    return this.linkAttrId
      ? {
          attrId: this.linkAttrId,
          editor: "ChildEntities",
        }
      : undefined;
  },

  async init(
    objectServiceId: number,
    objectId: number,
    typesMap: Record<number, string>,
  ) {
    try {
      this.setSettings({ status: "wait" });
      const settings = await loadTechpackSettings(objectServiceId);
      if (!settings.techpackObjectId)
        throw Error(t("The service has not been configured"));

      const parentLinkAttributeId = (
        await loadObjectAttrinbutesAll(settings.techpackObjectId)
      ).find(
        ({ referenceId, valueType }) =>
          referenceId === objectId &&
          typesMap[valueType] === AttrTypeName.object,
      )?.id;
      if (!parentLinkAttributeId)
        throw Error(
          t(
            "Communication between objects is not configured. Contact your administrator.",
          ),
        );

      this.setLinkAttrId(String(parentLinkAttributeId));
      this.setSettings({ status: "ready", result: settings });
      this.setChildEntsStore(
        new ChildEntitiesStore(
          new Set([ChildEntsActionType.create]),
          (entity) => createTechpackInstance(entity, objectServiceId),
        ),
      );
    } catch (error) {
      this.setSettings({ status: "error", error });
    }
  },
});
