import { makeAutoObservable } from "mobx";
import { MftTab1Store } from "./MftTab1";
import { ModelessFormStore } from "../../components/ModelessForm/ModelessFormStore";
import { MftTab2Store } from "./MftTab2";

export type MFTKey = "tab1" | "tab2";

export const modelessFormTestStore = makeAutoObservable({
  curTab: <MFTKey>"tab2",
  setCurTab(tab: MFTKey) {
    this.curTab = tab;
  },
  safeSetCurTab(tab: MFTKey) {
    ModelessFormStore.safeMultiAction(this.formStores, () =>
      this.setCurTab(tab),
    );
  },
  storeTab1: new MftTab1Store(),
  storeTab2: new MftTab2Store(),
  get formStores(): ModelessFormStore[] {
    return [this.storeTab1.formStore, this.storeTab2.formStore];
  },
});
