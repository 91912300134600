import { apiTechpack } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { zEntity, ZEntity } from "src/types/ZEntity";
import { zTechpackSettings, ZTechpackSettings } from "./TechpackTypes";

export const loadTechpackSettings = async (
  id: number,
): Promise<ZTechpackSettings> => {
  const resp = await rest.get(apiTechpack(`/settings/${id}`));
  return zTechpackSettings.parse(resp.data);
};

export const saveTechpackSettings = async (
  id: number,
  settings: ZTechpackSettings,
) => {
  await rest.post(apiTechpack(`/settings/${id}`), settings);
};

export const createTechpackInstance = async (
  entity: ZEntity,
  objectServiceId: number,
): Promise<ZEntity> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...other } = entity;
  const resp = await rest.post(
    apiTechpack(`/techpack/${objectServiceId}`),
    other,
  );
  return zEntity.parse(resp.data);
};
