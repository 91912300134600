import React, { DependencyList, useEffect, useState } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import {
  LabelDef,
  seedSeparators,
} from "src/common/attrView/viewFormItems/Separator";
import { onError } from "src/common/onError";
import { getViewInfo } from "src/common/attrView";
import { AttrLabelTreeData } from "./AttrLabelTreeData";
import { AttrLabelBuilderCtx } from "./types";
import { loadAttrLabelData } from "./loadAttrLabelData";

export const useAttrLabels = (
  createLabels: (data: AttrLabelTreeData) => LabelDef[],
  values: string[],
  attribute: ZAttribute,
  ctx?: AttrLabelBuilderCtx,
  deps?: DependencyList,
) => {
  const [labels, setLabels] = useState<React.ReactNode[]>([]);
  const [wait, setWait] = useState(false);
  useEffect(() => {
    setWait(true);
    const viewInfo = getViewInfo(attribute.viewType);
    loadAttrLabelData(values, attribute, { ctx })
      .then((data) => {
        if (data) {
          setLabels(
            seedSeparators(
              createLabels(data),
              viewInfo?.appearance?.view?.separator,
            ),
          );
        }
      })
      .finally(() => setWait(false))
      .catch(onError);
  }, [...(deps || [])]);
  return [labels, wait];
};
