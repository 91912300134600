import * as React from "react";
import { observer } from "mobx-react-lite";
import { AddButton, LayoutWithSider } from "src/components/LayoutWithSider";
import { LoaderBox } from "src/components/LoaderBox";
import { VerticalLayout } from "src/components/VerticalLayout";
import { ModelessForm } from "src/components/ModelessForm";
import { ModalDelete } from "src/components/ModalDelete";
import { Empty, Spin } from "antd";
import { RolesTabStore } from "./RolesTabStore";
import { RolesTree } from "./RolesTree";
import { visitRoleNode } from "./nodes/visitRoleNode";
import { RoleGroupButtons, RoleGroupFields } from "./forms/RoleGroupFields";
import { RoleObjectButtons, RoleObjectField } from "./forms/RoleObjectField";
import { RoleAttrButtons, RoleAttrField } from "./forms/RoleAttrField";

interface PropsRolesTab {
  store: RolesTabStore;
}

export const RolesTab: React.FC<PropsRolesTab> = observer(({ store }) => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <LayoutWithSider
      siderHeader={
        <AddButton
          text="Добавить группу"
          id="addRoleGroup"
          onClick={() => store.safeAddGroup()}
        />
      }
      siderContent={<RolesTree store={store} />}
    >
      <VerticalLayout>
        <LoaderBox
          remoteData={store.data}
          drawReady={() => <MainPart store={store} />}
        />
      </VerticalLayout>
    </LayoutWithSider>
  );
});

const MainPart: React.FC<PropsRolesTab> = observer(({ store }) => {
  const { curNode } = store;

  if (!curNode)
    return (
      <Empty
        description="Ничего не выбрано"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );

  const content = visitRoleNode(curNode, {
    roleGroup: (it) => ({
      fields: <RoleGroupFields node={it} store={store} />,
      extraButtons: <RoleGroupButtons node={it} store={store} />,
    }),
    roleObject: (it) => ({
      fields: <RoleObjectField node={it} store={store} />,
      extraButtons: <RoleObjectButtons node={it} store={store} />,
    }),
    attr: (it) => ({
      fields: <RoleAttrField node={it} store={store} />,
      extraButtons: <RoleAttrButtons node={it} store={store} />,
    }),
    default: () => ({
      fields: <div>Неизвестный тип</div>,
      extraButtons: <div />,
    }),
  });

  return (
    <>
      <Spin spinning={store.loadingNode}>
        <ModelessForm
          store={store.formStore}
          submit="Сохранить"
          name="curItem"
          extraButtons={content.extraButtons}
        >
          {content.fields}
        </ModelessForm>
      </Spin>
      <ModalDelete
        open={!!store.msgDelete}
        message={store.msgDelete}
        waiting={store.deletingWait}
        onOk={() => store.unsafeDeleteCurNode()}
        onCancel={() => store.setMsgDelete("")}
      />
    </>
  );
});
