import { z } from "zod";

export const zAttrInput = z.object({
  editor: z.literal("Input"),
  maxLength: z.number().nullable().optional(),
  addonBefore: z.string().nullable().optional(),
  addonAfter: z.string().nullable().optional(),
});

export type ZAttrInput = z.infer<typeof zAttrInput>;

export const makeInputProps = (src: ZAttrInput) => ({
  maxLength: src.maxLength ?? undefined,
  addonBefore: src.addonBefore ?? undefined,
  addonAfter: src.addonAfter ?? undefined,
});
