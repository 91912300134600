/**
 * Минимальная часть описания колонок, не зависящая от реализации компонента таблицы.
 */
import * as React from "react";

type ColumnVisibilityMode = "alwaysVisible" | "defaultHidden";
export type ColumnKey = string;

export interface ColumnPoor {
  key: ColumnKey;
  title?: React.ReactNode;
  visibility?: ColumnVisibilityMode;
  width?: number | string;
  condition?(): boolean;
}

export const canBeHidden = ({ title, visibility }: ColumnPoor): boolean =>
  !!title && visibility !== "alwaysVisible";
