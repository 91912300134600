// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-AttrLinked-AttrLinked-module__linkList--O5a2R {
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background-color: #FAFAFA;
  padding: 12px;
  min-height: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AttrLinked/AttrLinked.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;AACF","sourcesContent":[".linkList {\n  border: 1px solid #D9D9D9;\n  border-radius: 4px;\n  background-color: #FAFAFA;\n  padding: 12px;\n  min-height: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkList": `src-components-AttrLinked-AttrLinked-module__linkList--O5a2R`
};
export default ___CSS_LOADER_EXPORT___;
