import * as React from "react";
import { Form, Input, Select } from "antd";
import { observer } from "mobx-react-lite";
import { filterOptionByName } from "src/common/filterOptionByName";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { MultiLangFields } from "src/components/MultiLangFields";
import { RoleSelect } from "src/components/RoleSelect";
import { Obj2TabStore } from "src/pages/ManagementPage/Obj2Tab";
import {
  registerParamPattern,
  registerParamPatternMsg,
  registerUrlPrompt,
} from "src/pages/RegisterPage/registerUrl";
import { ZBsRegSettings } from "../bsRegistration.types";

interface PropsBsRegSettings {
  store: Obj2TabStore;
}

const fieldKey = (key: keyof ZBsRegSettings) => key;

export const BsRegSettings: React.FC<PropsBsRegSettings> = observer((props) => {
  const { store } = props;

  return (
    <>
      <Form.Item
        label="Адрес"
        name={fieldKey("url")}
        rules={[
          trimRequired(),
          { pattern: registerParamPattern, message: registerParamPatternMsg() },
        ]}
      >
        <Input addonBefore={registerUrlPrompt()} allowClear />
      </Form.Item>
      <Form.Item
        label="Права какой роли должны действовать на экземпляр"
        name={fieldKey("roleId")}
        rules={[{ required: true }]}
      >
        <RoleSelect roleGroups={store.roleGroups} single />
      </Form.Item>
      <Form.Item
        label="Начальный статус"
        tooltip="Статус, в который должен попадать экземпляр после заполнения неавторизованным пользователем"
        name={fieldKey("stateId")}
        rules={[{ required: true }]}
      >
        <Select
          options={store.stateOptions ?? []}
          placeholder="Выбрать"
          filterOption={filterOptionByName}
          showSearch
        />
      </Form.Item>
      <Form.Item
        label="Сообщение по окончанию заполения формы"
        name={fieldKey("successMessage")}
        rules={[{ required: true }]}
      >
        <Input placeholder="Системное" allowClear />
      </Form.Item>
      <MultiLangFields basePath={["successLoc"]} />
      {/* <Form.Item
        className={styles.createSwitch}
        label="Создавать пользователя после заполнения"
        name={fieldKey("isAutoCreate")}
      >
        <Switch />
      </Form.Item> */}
      <Form.Item
        label="Роль, под которой должен создаваться пользователь"
        name={fieldKey("createUserRoleId")}
        rules={[{ required: true }]}
      >
        <RoleSelect roleGroups={store.roleGroups} single />
      </Form.Item>
    </>
  );
});
