import { ZOption } from "src/types/ZOption";

export type RefName = "fieldMetadataDictionaryLIst" | "fieldMetadataType";

// const refUrl = (refName: RefName) =>
//   `/srm-service/api/dictionary/${refName}/linear/search`;

// export const ZRefStatus = z.enum(["ACTIVE", "ARCHIVE"]);

// export const ZRefItem = z.object({
//   id: z.string(),
//   value: z.string(),
//   status: ZRefStatus,
//   key: z.string().optional(),
// });

// type Options = {
//   params?: Record<string, unknown>;
//   noSort?: boolean;
// };

// export const loadFlatRefOptions = async (
//   url: string,
//   options?: Options
// ): Promise<ZOption[]> => {
//   const resp = await rest.get(url, { params: options?.params });
//   const rawList = ZRefItem.array().parse(resp.data.content);
//   const list = rawList.map(({ id, value, status, key }) => ({
//     value: id,
//     label: value,
//     disabled: status === "ARCHIVE",
//     className: status === "ARCHIVE" ? "archive-option" : undefined,
//     key,
//   }));
//   if (!options?.noSort) {
//     list.sort((a, b) => {
//       if (a.disabled && !b.disabled) return 1;
//       if (b.disabled && !a.disabled) return -1;
//       return a.label.localeCompare(b.label);
//     });
//   }
//   return list;
// };

// // Подходит для использования в CompanyWatcher
// export const flatRefLoader =
//   (refName: RefName, options?: Options) => (companyId: number | string) =>
//     loadFlatRefOptions(refUrl(refName), {
//       ...options,
//       params: { companyId },
//     });

// /**
//  * Получение значений справочника в виде списка для использования в Select
//  * Раньше использовалось кеширование для loadFlatRefOptions.
//  * После добавления companyId кеширование пришлось отменить.
//  * Но пока решено не удалять эту функцию, т.к. возможно потом будет использован какой-то иной механизм кеширования.
//  * @param refName
//  * @param companyId
//  */
// export const getFlatRefOptions = async (
//   refName: RefName,
//   companyId?: number | string
// ): Promise<ZOption[]> =>
//   loadFlatRefOptions(`/srm-service/api/dictionary/${refName}/linear/search`, {
//     params: { companyId },
//   });

// export type FlatRefDict = Record<string, string>;

// const dictDict: Partial<Record<RefName, FlatRefDict>> = {};

// export const dictFromOptions = (options: ZOption[]): Record<string, string> => {
//   const newDict: FlatRefDict = {};
//   options.forEach(({ value, label }) => {
//     newDict[value] = label;
//   });
//   return newDict;
// };

// /**
//  * Получение значений справочника для отображения названий в колонках таблиц
//  * @param refName
//  * @param companyId
//  */
// export const getFlatRefDict = async (
//   refName: RefName,
//   companyId?: number | string
// ): Promise<FlatRefDict> => {
//   const cachedDict = dictDict[refName];
//   if (cachedDict) return cachedDict;
//   const options = await getFlatRefOptions(refName, companyId);
//   const newDict = dictFromOptions(options);
//   dictDict[refName] = newDict;
//   return newDict;
// };

// /**
//  * Получение названия элемента справочника
//  * @param dict
//  * @param key
//  */
// export const flatRefItemLabel = (
//   dict: FlatRefDict,
//   key: string
// ): string | undefined => dict[key];

// /**
//  * Получение значения элемента справочника по ключу
//  * @param options
//  * @param key
//  */
// export const getOptionByKey = (options: ZOption[], key: string) =>
//   options.find((e) => e.key === key);

const fieldMetadataDictionaryOptions: ZOption[] = [
  { label: "First", value: "1" },
  { label: "Second", value: "2" },
];

/**
 * Список типов атрибутов
 */
export const fieldMetadataTypes: ZOption[] = [
  {
    label: "DATETIME",
    value: "Дата и время",
  },
  {
    label: "NUMBER",
    value: "Дробное число",
  },
  {
    label: "DICTIONARY",
    value: "Справочник",
  },
  {
    label: "DICTIONARY_MULTI",
    value: "Справочник (Мультивыбор)",
  },
  {
    label: "STRING",
    value: "Строка",
  },
  {
    label: "TEXTAREA",
    value: "Текст",
  },
  {
    label: "LONG",
    value: "Целое число",
  },
  {
    label: "CHECKBOX",
    value: "Чекбокс (да/нет)",
  },
  {
    label: "DATE",
    value: "Дата",
  },
];

export const getFlatRefOptions = async (
  refName: RefName,
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  companyId?: number | string,
): Promise<ZOption[]> => {
  if (refName === "fieldMetadataType") return fieldMetadataTypes;
  if (refName === "fieldMetadataDictionaryLIst")
    return fieldMetadataDictionaryOptions;
  throw Error(`Unsupported dictionary ${refName}`);
};
