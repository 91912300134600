import { ZModelAttr } from "../../ZModel";
import { Command, generateCmdKey } from "./Command";
import { ModelEditorStore } from "../ModelEditorStore";
import { CmdAttrDelete } from "./CmdAttrDelete";

export class CmdAttrInsert implements Command {
  readonly key: number;

  constructor(
    private store: ModelEditorStore,
    private attr: ZModelAttr,
    private index: number,
    private already: boolean,
  ) {
    this.key = generateCmdKey();
  }

  readonly title = "Вставка атрибута";

  createUndo(): Command {
    return new CmdAttrDelete(this.store, this.index);
  }

  exec(): void {
    const { model } = this.store;
    if (model && !this.already) {
      model.attrs.splice(this.index, 0, this.attr);
    }
  }
}
