import { apiObjUrl, gateway } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { optionalLangParams } from "src/lang/langHdr";
import { zEntity } from "src/types/ZEntity";
import {
  zEntCopyDataAttr,
  ZEntCopyDataAttr,
  zEntCopyDataResp,
  ZEntCopyDataResp,
} from "./types";

export const loadChildAttributes = async (
  objId: number,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZEntCopyDataAttr[]> => {
  const { translate } = options;
  const res = await rest.get(
    apiObjUrl(`/objects/${objId}/child-attributes`),
    optionalLangParams(translate),
  );
  return zEntCopyDataAttr.array().parse(res.data);
};

/**
 * Копировать теперь нужно не только атрибуты, но и бизнес-сервисы.
 * Загружаем из кэша.
 */
export const loadChildAttsAndBss = async (
  objectId: number,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZEntCopyDataResp> => {
  const { translate } = options;
  const res = await rest.get(
    apiObjUrl(`/objects/business-services-and-child-attributes`),
    { ...optionalLangParams(translate), params: { objectId } },
  );
  return zEntCopyDataResp.parse(res.data);
};

export const copyEntityAttributes = async (
  entityId: number,
  childAtts?: number[],
) =>
  rest
    .post(apiObjUrl("/entities/copy"), {
      entityId,
      attributes: childAtts,
    })
    .then((resp) => zEntity.parse(resp.data));

export const copyEntityBs = (
  sourceEntityId: number,
  targetEntityId: number,
  bsApiUrlBase: string,
) =>
  rest.put(gateway(`${bsApiUrlBase}copy`), undefined, {
    params: { sourceEntityId, targetEntityId },
  });
