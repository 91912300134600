import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tree } from "antd";
import { Dict2TabStore } from "../Dict2TabStore";
import { GroupsTreeItem } from "./GroupsTreeItem";

interface PropsGroupsTreeView {
  store: Dict2TabStore;
}

export const GroupsTreeView: React.FC<PropsGroupsTreeView> = observer(
  ({ store }) => (
    <Tree<GroupsTreeItem>
      treeData={store.treeData}
      showIcon
      selectedKeys={store.selectedKeys}
      onSelect={(keys, { node }) => store.onSelect(keys, node)}
      onExpand={(keys) => store.onExpand(keys)}
      expandedKeys={store.expandedKeys}
    />
  ),
);
