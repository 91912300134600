import { ColorPicker, Form, Radio } from "antd";
import React from "react";
import { AttrViewCompName } from "src/common/attrView";
import { ZModePersonCell } from "src/common/attrView/componentsDefs";
import { obj2LabelValue } from "src/common/attrView/obj2LabelValue";

const labelMap: Record<ZModePersonCell, string> = {
  standart: "Обычный",
  tag: "Тэги",
};

export const options = obj2LabelValue(labelMap);

type PropsPersonCellViewInfo = {
  curView: AttrViewCompName | undefined;
  stylesNameFn: (name: string) => string[];
};

export const PersonCellViewInfo: React.FC<PropsPersonCellViewInfo> = ({
  curView,
  stylesNameFn,
}) => {
  const mode: ZModePersonCell = Form.useWatch(stylesNameFn("mode"));
  const isPersonCell = curView === "PersonCellInfo";

  if (!isPersonCell) return null;

  return (
    <>
      <Form.Item label="Мод" name={stylesNameFn("mode")}>
        <Radio.Group>
          {options.map(({ label, value }) => (
            <Radio.Button key={value} value={value}>
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      {mode === "tag" && (
        <Form.Item
          label="цвет тэга"
          name={[...stylesNameFn("modeMeta"), "tagColor"]}
          getValueFromEvent={(e) => `#${e.toHex()}`}
        >
          <ColorPicker format="hex" />
        </Form.Item>
      )}
    </>
  );
};
