import React from "react";
import { CommonNodeO2 } from "../Obj2Nodes";

// Тип соответствует формату запроса

type IdRec = {
  id: number;
  isMoved?: boolean;
};

export type ResSplitOrders = {
  attributes: IdRec[];
  groups: IdRec[];
};

export const splitOrders = (
  nodes: CommonNodeO2[],
  movedKey?: React.Key,
): ResSplitOrders => {
  const res: ResSplitOrders = {
    attributes: [],
    groups: [],
  };
  nodes.forEach((node) => {
    const isMoved = movedKey === node.key;
    if (node.type === "attr") {
      res.attributes.push({ id: node.attr.id, isMoved });
    } else if (node.type === "group") {
      res.groups.push({ id: node.group.id, isMoved });
    } else {
      throw Error(`Неподходящий для сортировки тип узла ${node.type}`);
    }
  });
  return res;
};
