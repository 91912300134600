import * as React from "react";
import { observer } from "mobx-react-lite";
import { DefaultOptionType } from "antd/es/select";
import { CtrlSelection } from "../TableStore";
import { makeSelection } from "./makeSelection";

// Есть похожий компонент SelectedTags, но там нет общей очистки. И при большом количестве тегов будет выглядеть не очень.

interface PropsKeepSelected<TRow extends {}> {
  store: CtrlSelection<TRow>;
  makeTag(row: TRow): React.ReactNode;
  placeholder?: React.ReactNode;
}

export const KeepSelected = observer(
  <TRow extends {}>(props: PropsKeepSelected<TRow>) => {
    const { store, makeTag, placeholder } = props;
    const {
      rowKey,
      selectionSettings: { selectionType },
    } = store;
    const options: DefaultOptionType[] = store.selected.map((row) => ({
      value: row[rowKey] as DefaultOptionType["value"],
      label: makeTag(row),
    }));
    const onChange = (keys: DefaultOptionType["value"][]) => {
      const dstRows: TRow[] = [];
      keys.forEach((dstKey) => {
        const dstRow = store.selected.find(
          (srcRow) => srcRow[rowKey] === dstKey,
        );
        if (dstRow) dstRows.push(dstRow);
      });
      store.setSelected(dstRows);
    };
    return makeSelection(selectionType, {
      placeholder,
      options,
      onChange,
    });
  },
);
