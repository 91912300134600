import * as React from "react";
import { observer } from "mobx-react-lite";
import { Spin, Tree } from "antd";
import { RolesTabStore } from "./RolesTabStore";

interface PropsRolesTree {
  store: RolesTabStore;
}

export const RolesTree: React.FC<PropsRolesTree> = observer(({ store }) => (
  <Spin spinning={store.data.status === "wait"}>
    <Tree
      className="idRolesTree"
      treeData={store.treeData}
      showIcon
      selectedKeys={store.selectedKeys}
      onSelect={(keys) => store.safeSelect(keys)}
      expandedKeys={store.expandedKeys}
      onExpand={(_keys, { node, expanded }) => {
        store.expand(node.key, expanded);
      }}
    />
  </Spin>
));
