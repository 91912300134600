// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ImageCarousel-ImageCarousel-module__imageCarousel--RNmZX {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  width: 100%;
}
.src-components-ImageCarousel-ImageCarousel-module__imageUploadModal--FE02V .ant-modal-body {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-components-ImageCarousel-ImageCarousel-module__modalFooter--Smhbq {
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-components-ImageCarousel-ImageCarousel-module__finishButton--QO5Kg {
  width: 100%;
}
.src-components-ImageCarousel-ImageCarousel-module__imageUploadButton--xerL4 {
  width: 100%;
  color: #FFFFFF;
  background-color: #595959;
}
.src-components-ImageCarousel-ImageCarousel-module__uploadSingle--Fk_ih {
  width: 100%;
}
.src-components-ImageCarousel-ImageCarousel-module__uploadSingle--Fk_ih .ant-upload-select {
  width: 100%;
}
.src-components-ImageCarousel-ImageCarousel-module__uploadSingle--Fk_ih .ant-upload-list {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageCarousel/ImageCarousel.module.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AAAF;AAGA;EAEI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AAFJ;AAMA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAJF;AAOA;EACE,WAAA;AALF;AAQA;EACE,WAAA;EACA,cAAA;EACA,yBAAA;AANF;AASA;EACE,WAAA;AAPF;AAMA;EAII,WAAA;AAPJ;AAGA;EAQI,aAAA;AARJ","sourcesContent":["\n.imageCarousel {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 8px;\n  width: 100%;\n}\n\n.imageUploadModal {\n  :global(.ant-modal-body) {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.modalFooter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.finishButton {\n  width: 100%;\n}\n\n.imageUploadButton {\n  width: 100%;\n  color: #FFFFFF;\n  background-color: #595959;\n}\n\n.uploadSingle {\n  width: 100%;\n\n  :global(.ant-upload-select) {\n    width: 100%;\n  }\n  \n  :global(.ant-upload-list) {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageCarousel": `src-components-ImageCarousel-ImageCarousel-module__imageCarousel--RNmZX`,
	"imageUploadModal": `src-components-ImageCarousel-ImageCarousel-module__imageUploadModal--FE02V`,
	"modalFooter": `src-components-ImageCarousel-ImageCarousel-module__modalFooter--Smhbq`,
	"finishButton": `src-components-ImageCarousel-ImageCarousel-module__finishButton--QO5Kg`,
	"imageUploadButton": `src-components-ImageCarousel-ImageCarousel-module__imageUploadButton--xerL4`,
	"uploadSingle": `src-components-ImageCarousel-ImageCarousel-module__uploadSingle--Fk_ih`
};
export default ___CSS_LOADER_EXPORT___;
