import * as React from "react";
import { Form, Input } from "antd";
import { onError } from "src/common/onError";
import { ImageCarousel } from "src/components/ImageCarousel";
import { ifDef } from "src/common/ifDef";
import { TableStore } from "src/components/tables/TableStore";
import { observer } from "mobx-react-lite";
import { AColumn } from "src/components/tables/AsyncTable";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { FilterFieldsDict } from "src/components/tables/FiltersForm";
import { filterMonoSearch } from "src/components/tables/FiltersForm/filterMonoSearch";
import { KeepSelected } from "src/components/tables/KeepSelected";
import { DrawerPlmWithConfirmation } from "src/components/DrawerPlmWithConfirmation";
import { ScrollableTable } from "src/components/ScrollableTable";
import { t } from "i18next";
import { FilterBomColors, loadColors, ZBomColorRow } from "./apiBomColor";
import { EdBomColorModel } from "../../ZBom2Position";
import styles from "./BomColorModal.module.less";

export type BomColorInfo = {
  colorManual: string | null;
  colorReference: string[];
  comment: string | null;
  color: ZBomColorRow | null;
};

interface PropsBomColorModal {
  open: boolean;
  close(): void;
  initialData: EdBomColorModel;
  save(data: BomColorInfo): Promise<void>;
}

export const BomColorModal: React.FC<PropsBomColorModal> = (props) => {
  const { open, close, initialData, save } = props;
  const [form] = Form.useForm<BomColorInfo>();
  const [saving, setSaving] = React.useState(false);
  const [tableStore, setTableStore] = React.useState<TableStore<
    ZBomColorRow,
    FilterBomColors
  > | null>(null);
  React.useEffect(() => {
    if (open) {
      form.resetFields();
      const { bomPositionColorModel: cm } = initialData;
      form.setFieldsValue({
        colorManual: cm.colorManual.value,
        colorReference: cm.colorReference.value
          ? [cm.colorReference.value]
          : [],
        comment: cm.comment.value,
        color: ifDef(cm.color.id, (id) => ({
          id,
          name: cm.color.name.value,
          article: cm.color.article.value,
          image: cm.color.image.value,
        })),
      });
      const store = new TableStore<ZBomColorRow, FilterBomColors>({
        rowKey: "id",
        fnLoad: async ({ page, pageSize, filters }) => {
          const { content, totalElements } = await loadColors(
            page,
            pageSize,
            filters?.query,
          );
          return { rows: content, totalItems: totalElements };
        },
        selectionSettings: {
          selectionType: "radio",
          keepSelected: true,
        },
      });
      setTableStore(store);
    }
  }, [open]);
  const onSubmit = (values: BomColorInfo) => {
    setSaving(true);
    save(values)
      .then(close)
      .finally(() => setSaving(false))
      .catch(onError);
  };

  return (
    <DrawerPlmWithConfirmation
      drawerProps={{
        open,
        title: `${t("Color model")}: ${initialData.name}`,
        onClose: close,
      }}
      confirmationProps={{
        okText: t("Select"),
        okButtonProps: {
          onClick: form.submit,
          htmlType: "submit",
          loading: saving,
        },
        cancelButtonProps: {
          onClick: close,
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        className={styles.form}
      >
        <div className={styles.firstPart}>
          <div className={styles.firstLeft}>
            <Form.Item name="colorManual" label={t("Color manual")}>
              <Input.TextArea className={styles.textarea} />
            </Form.Item>
            <Form.Item name="comment" label={t("Comment")}>
              <Input.TextArea className={styles.textarea} />
            </Form.Item>
          </div>
          <div className={styles.firstRight}>
            <Form.Item name="colorReference" label={t("Color reference")}>
              <ImageCarousel multiple={false} imageHeight={90} />
            </Form.Item>
          </div>
        </div>
        {!!tableStore && (
          <Form.Item
            name="color"
            label={t("Color")}
            className={styles.colorFormItem}
          >
            <ColorSelect tableStore={tableStore} />
          </Form.Item>
        )}
      </Form>
    </DrawerPlmWithConfirmation>
  );
};

interface PropsColorSelect {
  value?: ZBomColorRow | null;
  onChange?(newValue?: ZBomColorRow | null): void;
  tableStore: TableStore<ZBomColorRow, FilterBomColors>;
}
const ColorSelect: React.FC<PropsColorSelect> = observer((props) => {
  const { value, onChange, tableStore } = props;
  React.useEffect(() => {
    tableStore.safeSelect(ifDef(value, (v) => [v]) ?? []);
  }, [value]);
  React.useEffect(() => {
    onChange?.(tableStore.selected[0]);
  }, [tableStore.selected]);
  const columns = React.useMemo(
    () =>
      [
        {
          key: "image",
          title: "Image",
          render: (_, { image }) =>
            image ? (
              <img
                src={imgSrc(image)}
                alt=""
                style={{ maxWidth: 80, maxHeight: 40 }}
              />
            ) : null,
        },
        { key: "name", title: "Name", dataIndex: "name" },
        { key: "article", title: "Article", dataIndex: "article" },
      ] satisfies AColumn<ZBomColorRow>[],
    [],
  );
  const filter: FilterFieldsDict<FilterBomColors> = {
    query: filterMonoSearch(),
  };
  return (
    <div className={styles.tableBox}>
      <KeepSelected store={tableStore} makeTag={({ name }) => name} />
      <ScrollableTable
        store={tableStore}
        columns={columns}
        filterItems={filter}
        onRowClick={(row) => {
          selectOnRowClick(row, tableStore);
        }}
      />
    </div>
  );
});
