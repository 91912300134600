import * as React from "react";
import { Button, Checkbox, Dropdown } from "antd";
import { FunnelPlotOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { FilterSettingsStore } from "./FilterSettingsStore";
import { FilterFieldsDict } from "../FiltersForm";
import { FiltersFormItem } from "../FiltersFormItem";
import styles from "./FilterSettingsButton.module.less";

/* eslint jsx-a11y/label-has-associated-control: "off" */

interface PropsFilterSettingsButton {
  store: FilterSettingsStore | null;
  filterItems?: FilterFieldsDict<{}>;
  onChange: () => void;
}

export const FilterSettingsButton: React.FC<PropsFilterSettingsButton> =
  observer((props: PropsFilterSettingsButton) => {
    const { store, filterItems, onChange } = props;
    React.useEffect(() => {
      if (filterItems && store) store.init(filterItems);
    }, []);
    if (!store || !filterItems) return null;
    const draw = (
      <div className={styles.box}>
        <h3 className={styles.title}>Настройка фильтров</h3>
        {Object.entries<FiltersFormItem>(filterItems).map(
          ([key, { label }]) => (
            <label className={styles.itemInfo}>
              <Checkbox
                checked={store.isVisible(key)}
                onClick={() => {
                  store.changeVisible(key);
                  onChange();
                }}
              />
              <span>{label ?? key}</span>
            </label>
          ),
        )}
      </div>
    );
    return (
      <Dropdown overlay={draw} placement="bottomRight">
        <Button icon={<FunnelPlotOutlined />} />
      </Dropdown>
    );
  });
