import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { zCbvLinkAttr } from "./CopyByValueEdit.types";

export const loadCbvLinkAttributes = async (
  objectId: number,
  valueType: number,
) => {
  const resp = await rest.get(apiObjUrl("/objects/info-for-copy-by-value"), {
    params: { objectId, valueType },
  });
  return zCbvLinkAttr.array().parse(resp.data);
};
