import React, { useMemo } from "react";
import { Button, Drawer, Flex, Space, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { CopyOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { TableStore } from "src/components/tables/TableStore";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";
import { ZChildEntities } from "src/common/attrEdit/components/ZChildEntities";
import { DropdownChildButton } from "src/components/DropdownChildButton";
import { CopyFromStore } from "./CopyFromStore";
import styles from "./CopyFrom.module.less";
import { ZEntityFiltersChild } from "../../ChildEntitiesStore";
import { ChildEntities } from "../../ChildEntities";

interface PropsCopyFrom {
  tableStore: TableStore<ZEntityRow, ZEntityFiltersChild>;
  onLoad?: (flag: boolean) => void;
  entityId: number;
  parentObjId: number;
  editorInfo?: ZChildEntities;
}

export const CopyFrom: React.FC<PropsCopyFrom> = observer(
  ({ tableStore, entityId, parentObjId, editorInfo, onLoad }) => {
    const copyFromStore = useMemo(
      () => new CopyFromStore(entityId, tableStore, parentObjId, onLoad),
      [],
    );
    const { isDrawerOpen, loading, pageStore, forCopyStore } = copyFromStore;
    const fromEntityId = pageStore?.tableStore?.selected[0]?.id;
    const isForCopyEnts = forCopyStore.tableStore?.selected.length;
    return (
      <>
        <DropdownChildButton
          icon={<CopyOutlined />}
          onClick={() => copyFromStore.onClick()}
        >
          {t("Copy from")}
        </DropdownChildButton>
        {pageStore && (
          <Drawer
            width="100vw"
            open={isDrawerOpen}
            onClose={() => copyFromStore.onClose()}
            className={styles.drawer}
            title={t("Copy from another instance to the current instance")}
            footer={
              <Space>
                <Button
                  loading={loading}
                  disabled={!fromEntityId || !isForCopyEnts}
                  type="primary"
                  onClick={() => copyFromStore.save()}
                >
                  {t("Copy")}
                </Button>
                <Button onClick={() => copyFromStore.onClose()}>
                  {t("Cancel")}
                </Button>
              </Space>
            }
          >
            <div className={styles.entitiesTableBlock}>
              <Flex gap={12} align="center">
                {t("Copy source")}
                <Tooltip
                  title={t(
                    "Select the instance from which you want to copy the instances of the linked object with all the relationships",
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Flex>
              <EntityFiltersTable store={pageStore} />
            </div>
            <div className={styles.childTableBlock}>
              <Flex gap={12} align="center">
                {t("Copied instances")}
                <Tooltip
                  title={t(
                    "Select the instances of the linked object that you want to copy to the current instance",
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Flex>
              <div className={styles.childTable}>
                {editorInfo && (
                  <ChildEntities
                    parentObjId={parentObjId}
                    editorInfo={{ ...editorInfo, summaryAttrs: [] }}
                    entityId={fromEntityId}
                    disableResize
                    outerStore={forCopyStore}
                  />
                )}
              </div>
            </div>
          </Drawer>
        )}
      </>
    );
  },
);
