import * as React from "react";
import { SubsampleStore } from "../SubsampleStore";
import { SampleMcPointColumn } from "./SampleMcPointColumn";
import { SMcPointTextCell } from "./SMcPointTextCell";
import { BoolView } from "./BoolView/BoolView";
import { SMcPointEditCell } from "./SMcPointEditCell";
import { SampleCellEdit } from "./SampleCellEdit";

export const buildSampleMcPointColumns = (
  store: SubsampleStore,
): SampleMcPointColumn[] => {
  const { pointColumn } = store;
  const columns: SampleMcPointColumn[] = [
    {
      key: "point",
      title: "Point",
      subTitle: "",
      width: "1fr",
      minWidth: 100,
      cell: (row) => <SMcPointTextCell>{row.point.name}</SMcPointTextCell>,
    },
    {
      key: "description",
      title: "Description",
      subTitle: "",
      width: "2fr",
      minWidth: 160,
      cell: (row) => <SMcPointTextCell>{row.description}</SMcPointTextCell>,
    },
    {
      key: "qc",
      title: "QC",
      subTitle: "",
      minWidth: 56,
      cell: (row) => (
        <SMcPointTextCell>
          <BoolView value={row.qc} />
        </SMcPointTextCell>
      ),
    },
    {
      key: "tol",
      title: "Tol - / Tol +",
      subTitle: "",
      width: "1fr",
      minWidth: 100,
      cell: (row) => (
        <SMcPointTextCell align="end">
          {numWrap(row.tolMinus)} / {numWrap(row.tolPlus)}
        </SMcPointTextCell>
      ),
    },
  ];
  if (pointColumn) {
    columns.push({
      key: "sizeVersion",
      title: pointColumn.title,
      colSpan: 2,
      subTitle: pointColumn.subTitle,
      width: "1fr",
      minWidth: 80,
      cell: (row) => (
        <SMcPointTextCell align="end">
          {row.sizes[0]?.pointValues[0]?.value}
        </SMcPointTextCell>
      ),
    });
    columns.push({
      key: "sizeSample",
      title: null,
      subTitle: "Sample",
      width: "1fr",
      minWidth: 80,
      cell: (row) => (
        <SMcPointEditCell>
          <SampleCellEdit store={store} row={row} />
        </SMcPointEditCell>
      ),
    });
  }
  return columns;
};

const numWrap = (n: number | null | undefined): string => {
  if (typeof n !== "number") return "";
  return String(n);
};
