export interface GlobalParams {
  title: string;
  copyright: string;
  supportEmail: string;
}

export const getGlobalParams = (): GlobalParams => ({
  title: "Omnidata.PLM",
  copyright: "© 2021 Omnidata.plm",
  supportEmail: "support@omnidata.ru",
});
