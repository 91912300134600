import { ZAttribute } from "src/types/ZAttribute";

export interface BuilderCtx {
  typesMap: Record<number, string>;
  canUpdate:
    | boolean
    | ((attr: ZAttribute, typesMap: Record<number, string>) => boolean);
  stateId?: number | null;
  objectName?: string;
  entityId?: number;
}

export const canUpdateAttr = (ctx: BuilderCtx, attr: ZAttribute): boolean => {
  const { canUpdate, typesMap } = ctx;
  if (typeof canUpdate === "function") {
    return canUpdate(attr, typesMap);
  }
  return canUpdate;
};
