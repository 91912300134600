import * as React from "react";
import { Form } from "antd";
import { Separator } from "src/common/attrView/viewFormItems/Separator";
import { observer } from "mobx-react-lite";
import { edAttrField } from "../../../../EdAttribute";
import { SelectGroupFromAttribute } from "./SelectGroupFromAttribute";

const root = edAttrField.viewInfo;

const appearanceColField = (name: string) => [
  root,
  "appearance",
  "column",
  "group",
  name,
];

interface PropsTableSettingsInfo {
  readonly currentObjectId: number | null;
}

export const TableSettingsInfo: React.FC<PropsTableSettingsInfo> = observer(
  ({ currentObjectId }) => {
    if (!currentObjectId) return null;
    return (
      <>
        <Form.Item
          name={appearanceColField("name")}
          label="Добавить колонку для группировки атрибутов"
        >
          <SelectGroupFromAttribute objectId={String(currentObjectId)} />
        </Form.Item>
        <Separator name={appearanceColField("separator")} />
      </>
    );
  },
);
