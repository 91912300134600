import * as React from "react";
import { appStore } from "src/appStore";
import { getLangName } from "src/types/ZLanguageProps";

type PropsContentLangInfo = {
  values: string[] | undefined;
};

export const ContentLangInfo: React.FC<PropsContentLangInfo> = ({ values }) => {
  const code = values?.[0] ?? "";
  const lang = appStore.contentLangMap[code];
  return lang ? getLangName(lang) : code;
};
