import React from "react";
import { Radio } from "antd";
import { observer } from "mobx-react-lite";
import { CtrlSelection } from "src/components/tables/TableStore";
import { RadioButtonProps } from "antd/es/radio/radioButton";
import { CloseCircleOutlined } from "@ant-design/icons";
import styles from "./TableSelection.module.less";

type PropsTableSelectionRadio<TRow extends {}> = {
  store: CtrlSelection<TRow>;
  row: TRow;
};

export const TableSelectionRadio = observer(
  <TRow extends {}>({ store, row }: PropsTableSelectionRadio<TRow>) => {
    const { onSelect } = store.selectionSettings;
    const { rowKey } = store;
    const handleChange = (checked: boolean) => {
      store.safeSelect(checked ? [row] : []);
      onSelect?.(store.selected, [row], checked);
    };
    const rowChecked = !!store.selected.find(
      (item) => item[rowKey] === row?.[rowKey],
    );
    const cmpProps: RadioButtonProps = {
      checked: rowChecked,
    };
    return (
      <div className={styles.radioSelection}>
        <Radio
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...cmpProps}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(checked) => {
            handleChange(checked.target.checked);
          }}
          className={styles.selection}
        />
        {rowChecked && (
          <div className={styles.radioCancel}>
            <CloseCircleOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleChange(false);
              }}
            />
          </div>
        )}
      </div>
    );
  },
);
