import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Flex, Input, Select } from "antd";
import { DefaultOptionType, SelectProps } from "antd/es/select";
import React, { useEffect, useState } from "react";
import { getViewInfo } from "src/common/attrView";
import { onError } from "src/common/onError";
import { VerticalContent } from "src/components/VerticalContent";
import { loadObjectAttrinbutesReduced } from "src/pages/ManagementPage/objectsApi";

type PropsSelectGroupFromAttribute = SelectProps & { objectId?: string };

export const SelectGroupFromAttribute: React.FC<
  PropsSelectGroupFromAttribute
> = ({ objectId, ...props }) => {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [name, setName] = useState("");

  const addNewGroup = () => {
    try {
      const exist = options.find((opt) => opt.value === name);
      if (!name) throw new Error("Заполните название группы");
      if (exist) throw new Error("Уже существует");
      setOptions([{ label: name, value: name }, ...options]);
    } catch (error) {
      onError(error);
    }
  };

  const init = async () => {
    if (!objectId) return;
    try {
      const atts = await loadObjectAttrinbutesReduced(objectId);
      const viewInfoList = atts.map((attr) => getViewInfo(attr.viewType));
      const groupNameMap = viewInfoList.reduce((acc, info) => {
        const key = info?.appearance?.column?.group.name;
        if (key && !acc.has(key)) acc.add(key);
        return acc;
      }, new Set<string>());

      setOptions(
        Array.from(groupNameMap).map((value) => ({ label: value, value })),
      );
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    init();
  }, [objectId]);

  return (
    <VerticalContent>
      <VerticalContent gap={8}>
        <Input
          placeholder="Название группы"
          onChange={(e) => setName(e.target.value)}
        />

        <Button
          style={{ maxWidth: "max-content" }}
          onClick={addNewGroup}
          icon={<PlusCircleFilled />}
        >
          Добавить колонку
        </Button>
      </VerticalContent>

      <Flex vertical gap={8}>
        <span style={{ color: "#0000008C" }}>
          Выбрать колонку для группировки атрибута
        </span>
        <Select {...props} options={options} placeholder="Выбрать" allowClear />
      </Flex>
    </VerticalContent>
  );
};

SelectGroupFromAttribute.defaultProps = { objectId: undefined };
