// import { zAttrLinkedValueView } from "src/common/attrView/viewFormItems/LinkedAttrValueView";
import { zSeparator } from "src/common/attrView/viewFormItems/Separator";
import { z } from "zod";

export const zAttrLinkedValue = z.object({
  editor: z.literal("LinkedValue"),
  separator: zSeparator,
  // в доработке на след. спринт
  // view: zAttrLinkedValueView,
});
