import * as React from "react";
import { AColumn, AsyncTable } from "src/components/tables/AsyncTable";
import {
  FilterFieldsDict,
  FiltersForm,
} from "src/components/tables/FiltersForm";
import { filterMonoSearch } from "src/components/tables/FiltersForm/filterMonoSearch";
import { Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { confirmDelete } from "src/components/ModalDelete";
import { observer } from "mobx-react-lite";
import { SubsampleStore } from "../SubsampleStore";
import { PhotoCommentModal } from "./PhotoCommentModal";
import { FilterPhotoComments, ZPhotoComment } from "../ZPhotoComment";
import styles from "./SamplePhotoComments.module.less";

interface PropsSamplePhotoComments {
  store: SubsampleStore;
}

export const SamplePhotoComments: React.FC<PropsSamplePhotoComments> = observer(
  (props) => {
    const { store } = props;
    const tableStore = store.photoCommTable;
    const [open, setOpen] = React.useState(false);
    const columns = React.useMemo(
      () =>
        [
          {
            key: "image",
            title: "Image",
            width: "20%",
            render: (_, { image }) =>
              image && (
                <img src={imgSrc(image)} className={styles.image} alt="" />
              ),
          },
          {
            key: "comment",
            title: "Comment",
            dataIndex: "comment",
          },
        ] as AColumn<ZPhotoComment>[],
      [],
    );
    const filterItems: FilterFieldsDict<FilterPhotoComments> = {
      query: filterMonoSearch(),
    };
    const onDelete = () => {
      confirmDelete("Delete?", () => store.deleteComments());
    };
    return (
      <div className={styles.frame}>
        <div className={styles.title}>Фотокомментарий</div>
        <div className={styles.subtitle}>Модели</div>
        <div className={styles.tools}>
          <div className={styles.filters}>
            <FiltersForm store={tableStore} items={filterItems} />
          </div>
          <Button
            danger
            icon={<DeleteOutlined />}
            disabled={!store.canDeleteComment}
            onClick={onDelete}
          >
            Delete comments
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setOpen(true);
            }}
          >
            Фотокомментарий
          </Button>
        </div>
        <AsyncTable store={tableStore} columns={columns} usePagination />
        <PhotoCommentModal
          open={open}
          close={() => setOpen(false)}
          onSuccess={(values) => store.createComment(values)}
        />
      </div>
    );
  },
);
