import * as React from "react";
import { observer } from "mobx-react-lite";
import { Badge, Tooltip } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { notificationsStore } from "src/common/notificationsStore";
import { BellIconStd } from "src/common/icons";
import { classNames } from "src/common/classNames";
import { ExtNotoficationsPopup } from "../ExtNotoficationsPopup";
import styles from "./Bell.module.less";
/* eslint jsx-a11y/control-has-associated-label: "off" */

type PropsBell = {
  active: Boolean;
};

export const Bell: React.FC<PropsBell> = observer(({ active }) => {
  const { unreadedCount } = notificationsStore;
  const [popupOpen, setPopupOpen] = React.useState(false);

  return (
    <div className={classNames([styles.bell, [active, styles.bellActive]])}>
      {notificationsStore.status === "connecting" && (
        <Tooltip title="Соединение с центром уведомлений">
          <SyncOutlined
            spin
            className={classNames([styles.icon, styles.spin])}
          />
        </Tooltip>
      )}
      {notificationsStore.status === "none" && (
        <BellIconStd className={[styles.icon, styles.error].join(" ")} />
      )}
      {notificationsStore.status === "ready" && (
        <button
          type="button"
          className={styles.bellButton}
          onClick={(e) => {
            e.stopPropagation();
            e.currentTarget?.blur();
            setPopupOpen(true);
          }}
        >
          <Badge
            className={classNames([
              styles.badge,
              [unreadedCount > 99, styles.badgeSmallText],
            ])}
            count={unreadedCount}
          >
            <BellIconStd className={styles.icon} />
          </Badge>
        </button>
      )}
      <ExtNotoficationsPopup
        open={popupOpen}
        close={() => setPopupOpen(false)}
      />
    </div>
  );
});
