import React from "react";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const ExportIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => (
  <svg
    viewBox="0 0 18 18"
    {...props}
    width="18"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.63357 0.5H16.3671C16.7367 0.5 17.036 0.787277 17.034 1.14286V10.2395V10.4643V11.1055H7.23242V15.7683H1.63357C1.26393 15.7683 0.9646 15.481 0.9646 15.1255V10.4643V10.2395V1.14286C0.9646 0.787277 1.26393 0.5 1.63357 0.5ZM2.41103 9.66072H5.94674V6.44643H2.41103V9.66072ZM2.41103 5.16071H5.94674V1.94643H2.41103V5.16071ZM7.23246 9.66072H10.7682V6.44643H7.23246V9.66072ZM7.23246 5.16071H10.7682V1.94643H7.23246V5.16071ZM12.0539 9.66072H15.5896V6.44643H12.0539V9.66072ZM12.0539 5.16071H15.5896V1.94643H12.0539V5.16071ZM5.94674 11.1076H2.41103V14.3219H5.94674V11.1076ZM17.9837 15.0947C17.9731 15.1166 17.9575 15.1358 17.9383 15.1508L15.0897 17.4008C14.9852 17.4832 14.8305 17.4089 14.8305 17.2743V15.7897H9.69734C9.60894 15.7897 9.53662 15.7173 9.53662 15.629V14.4236C9.53662 14.3352 9.60894 14.2629 9.69734 14.2629H14.8325V12.7743C14.8325 12.6397 14.9872 12.5653 15.0917 12.6477L17.9383 14.8977C17.9575 14.9127 17.9731 14.9319 17.9837 14.9539C17.9944 14.9758 18 14.9999 18 15.0243C18 15.0487 17.9944 15.0727 17.9837 15.0947Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);
/**
 * используется для экспорта данных таблиц, вместо SaveOutlined
 */
export const ExportIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={ExportIconStdSvg} {...props} />;
