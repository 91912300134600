import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tabs, TabsProps } from "antd";
import { t } from "i18next";
import { MChartEntityStore } from "../MChartEntityStore";
import { NewMCEntityCreate } from "./NewMCEntityCreate";
import { MCEntityCopyFrom } from "./MCEntityCopyFrom/MCEntityCopyFrom";
import styles from "./NewMCEntity.module.less";
import { MCTemplate } from "./MCTemplate";

interface PropsNewMCEntity {
  store: MChartEntityStore;
}

export const NewMCEntity: React.FC<PropsNewMCEntity> = observer(({ store }) => {
  const items: TabsProps["items"] = [
    {
      key: "new",
      label: t("Create"),
    },
    {
      key: "copyFrom",
      label: t("Copy from model"),
    },
    {
      key: "template",
      label: t("Copy from template"),
    },
  ];
  const [activeKey, setActiveKey] = React.useState("new");
  return (
    <div className={styles.box}>
      <Tabs
        items={items}
        activeKey={activeKey}
        onChange={(newKey) => setActiveKey(newKey)}
      />
      <div className={styles.content}>
        {activeKey === "new" && <NewMCEntityCreate store={store} />}
        {activeKey === "copyFrom" && <MCEntityCopyFrom store={store} />}
        {activeKey === "template" && <MCTemplate store={store} />}
      </div>
    </div>
  );
});
